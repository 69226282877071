import CustomFieldSelect from 'admin/components/CustomFieldSelect'
import CustomField from 'components/CustomField'
import FormGrid from 'components/FormGrid'
import VerticalSpacer from 'components/VerticalSpacer'
import { isEmpty } from 'ramda'
import React, { Component } from 'react'
import { Field } from 'react-final-form'
import { injectIntl } from 'react-intl'
import { Addresses } from 'store/api'
import * as forms from 'utils/forms'
import { countries } from '../../../mocks/countries'

class FormAddress extends Component {
  state = {
    loading: false,
    blockStreetField: this.props.values && this.props.values.rua,
    country: (this.props.intl.locale === 'pt' ? 'BR' : 'US')
  }

  clean (){
    ['pais','estado','cidade','rua'].forEach(i=>
      this.props.form.change(i, '')
    )
  }

  handleCode = (ddi)=>{
    if(!ddi)
      return 'BR';
    let uf = ddi;
    return uf.replace(uf.replace(/(\[\w{2}\])/g,''),'').replace(/\[|\]/g,'');
  };

  handleZipcodeTolltip = (postalcode)=>{
    return{
      label: postalcode.label || 'Zip Code',
      mask: postalcode.mask || '99999'
    }
  };


  sortable(a, b) {
    const nameA = a.label.toUpperCase(); 
    const nameB = b.label.toUpperCase(); 
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  fillAddress (address) {
    this.props.form.batch(() => {
      this.props.form.change('pais', address.pais)
      this.props.form.change('estado', address.estado)
      this.props.form.change('cidade', address.cidade)
      this.props.form.change('rua', address.rua)
     // if (this.state.blockStreetField !== !!address.rua) {
        this.setState({
          blockStreetField: !!address.rua,
          loading: false,
          country : address.pais
        })
     // }
    })
  }

  queryCep (cep) {
    this.setState({ loading: true }, async () => {
      try {
        const response = await Addresses.fetchOne(cep)

        if (response && response.data && !this.unmounted) {
          const { data: address } = response
          if (address && !isEmpty(address)) {
            this.fillAddress(address)
          } else {
            this.fillAddress({})
          }
        }
      } catch (err) {
        window.alert('CEP não encontrado')
        //this.fillAddress({})
        this.setState({ loading: false })
      }
    })
  }

  componentDidMount () {
    const { cep: currentCep = ''} = this.props.values

    this.setState({country:this.handleCode(this.props.values.ddi)});
    
    if( this.handleCode(this.props.values.ddi) !== 'BR'){
      //this.clean()
      return ;
    }
    if (this.props.loadByCep && currentCep && currentCep.length === 8) {
      this.queryCep(currentCep)
    }

    if(currentCep !== null && (currentCep !== '' && currentCep.length < 8) ){
      delete this.props.values.cep
    }
  }

  componentDidUpdate ({ values: { cep: previousCep } }) {
    const { cep: currentCep = ''} = this.props.values

    if( this.state.country !== this.handleCode(this.props.values.ddi))
      this.setState({country:this.handleCode(this.props.values.ddi)});
      
    if( this.handleCode(this.props.values.ddi) !== 'BR'){
      this.clean();
      return ;
    }
    if (currentCep && currentCep.length === 8 && currentCep !== previousCep) {
      delete this.props.errors.cep ;
      this.queryCep(currentCep)
    }

    if(currentCep !== null && (currentCep !== '' && currentCep.length < 8)){
      this.props.errors.cep = 'cep inválido';
    }
  }

  componentWillUnmount () {
    this.unmounted = true
  }

  render () {
    const { loading: isLoadingAddress } = this.state
    const { intl: { formatMessage, locale }, values:{ pais, ddi}, readOnly, disabled } = this.props
    return (
      <div>
        <VerticalSpacer>
          
            {this.handleCode(ddi) === 'BR' ? (
              <React.Fragment>
                <FormGrid>
                  <Field
                    name='cep'
                    label={formatMessage({ id: 'admin.common.cep' })}
                    id='form_address_cep'
                    tooltip={formatMessage({ id: 'admin.common.cepTooltip' })}
                    component={CustomField}
                    disabled={disabled || isLoadingAddress}
                    readOnly={readOnly}
                    autoFocus={this.props.autoFocus}
                    {...forms.cep}              
                  />

                  <Field
                    name='pais'
                    label={formatMessage({ id: 'admin.common.country' })}
                    id='form_address_country'
                    disabled={true}
                    readOnly={true}
                    component={CustomFieldSelect}
                    customSelectProps={{
                      options: pais ? [pais] : [],
                      labelKey: 'nome',
                      valueKey: 'id' ,
                      isLoading: isLoadingAddress,
                      placeholder: formatMessage({ id: 'admin.common.country' }),
                    }}                 
                  />  
                </FormGrid>
                <FormGrid>
                  <Field
                    name='estado'
                    label={formatMessage({ id: 'admin.common.state' })}
                    id='form_address_state'
                    disabled={disabled}
                    readOnly={readOnly}
                    component={CustomFieldSelect}
                    customSelectProps={{
                      options: this.props.values.estado
                        ? [ this.props.values.estado ]
                        : [],
                      labelKey: 'nome',
                      valueKey: 'id',
                      isLoading: isLoadingAddress,
                      placeholder: formatMessage({ id: 'admin.common.state' })
                    }}                  
                  />
                  <Field
                    name='cidade'
                    label={formatMessage({ id: 'admin.common.city' })}
                    id='form_patient_city'
                    disabled={disabled}
                    readOnly={readOnly}
                    component={CustomFieldSelect}
                    customSelectProps={{
                      options: this.props.values.cidade
                        ? [ this.props.values.cidade ]
                        : [],
                      labelKey: 'nome',
                      valueKey: 'id',
                      isLoading: isLoadingAddress,
                      placeholder: formatMessage({ id: 'admin.common.city' })
                    }}
                  />
                </FormGrid>
                <Field
                  name='rua'
                  component={CustomField}
                  label={formatMessage({ id: 'admin.common.address' })}
                  disabled={isLoadingAddress || this.state.blockStreetField}
                  readOnly={readOnly}
                />
              </React.Fragment>
            ):(
              <React.Fragment>
                <FormGrid>
                  <Field
                    name="cep"
                    label={((ddi && countries[locale][this.handleCode(ddi)].label) || 'Zip Code')} 
                    id="form_address_cep"
                    tooltip={formatMessage({ id: 'admin.common.zipcode.Tooltip' }, this.handleZipcodeTolltip(countries.en[this.handleCode(ddi)])) }
                    component={CustomField}
                    disabled={isLoadingAddress}
                    //readOnly={readOnly}
                    autoFocus={this.props.autoFocus}
                    {...forms.zipcode({code:this.handleCode(ddi)})}
                  />
                  <Field
                    name='pais'
                    label={formatMessage({ id: 'admin.common.country' })}
                    id='form_address_country'
                    disabled={true}
                    readOnly={true}
                    component={CustomFieldSelect}
                    customSelectProps={{
                      options:  Object.values(countries[locale]).sort(this.sortable),
                      value:(this.state.country),
                      labelKey:'country',
                      valueKey:'code',
                      isLoading: isLoadingAddress,
                      placeholder: formatMessage({ id: 'admin.common.country' }),
                      onChange : (value) => {
                        this.setState({ country : value})
                        this.props.form.batch(() =>{
                          this.props.form.change('pais', value)                    
                        })
                      }
                    }}                 
                  />  
                </FormGrid>
                <FormGrid>
                  <Field
                    name='_estado'
                    label={formatMessage({ id: 'admin.common.state' })}
                    id='form_address_state'
                    disabled={disabled}
                    readOnly={readOnly}
                    component={CustomField}                   
                  />
                  <Field
                    name='_cidade'
                    label={formatMessage({ id: 'admin.common.city' })}
                    id='form_patient_city'
                    disabled={disabled}
                    readOnly={readOnly}
                    component={CustomField}
                  />
                </FormGrid>
                <Field
                  name='_rua'
                  component={CustomField}
                  label={formatMessage({ id: 'admin.common.address' })}
                  disabled={disabled}
                  readOnly={readOnly}
                />
              </React.Fragment>
            )}    

          <FormGrid>
            <Field
              name='numero'
              component={CustomField}
              disabled={disabled}
              readOnly={readOnly}
              label={formatMessage({ id: 'admin.common.addressNumber' })}             
            />
            <Field
              name='complemento'
              component={CustomField}
              disabled={disabled}
              readOnly={readOnly}
              label={formatMessage({ id: 'admin.common.addressComplement' })}
            />
          </FormGrid>
        </VerticalSpacer>
      </div>
    )
  }
}

export default injectIntl(FormAddress)
