import styled from 'styled-components';

export const Container = styled.div`
  .section_input_password {
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: block;
  }
  .input_password {
    width: 100%;
  }
  .icon_password {
    position: absolute;
    top: 42px;
    right: 9px;
    z-index: 2;
    width: 15px;
  }
  @media (min-width: 1366px) and (max-width: 2050px) {
    .icon_password {
      top: 39px !important;
    }
  }
  .button_register_google {
    background: #1c8cc7 !important;
    color: #ffffff !important;
    cursor: pointer;
    height: 49px;
    width: 25px;
    border-radius: 4px !important;
    border-bottom: none !important;
    width: 100%;
    max-width: 285px;
    margin-top: 10px;
    font-family: 'Poppins', sans-serif !important;
  }
  .button_register_google > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40%;
    padding: 8px !important;
    margin-left: 7px;
    margin-right: 20px !important;
  }
  .button_register_google > span {
    padding: 0 !important;
  }

  @media (max-width: 800px) {
  .button_register_google > div {
      margin-right: 5px !important;
    }
  }
`;
export const ContainerCaptcha = styled.div`
  label {
    padding: 20px 85px 20px 15px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0 4px -1px rgb(0 0 0 / 30%);
    position: relative;

    p {
      font-size: 14px;
    }

    &:before {
      content: 'reCAPTCHA';
      background: url('https://www.gstatic.com/recaptcha/api2/logo_48.png');
      background-size: 32px;
      background-repeat: no-repeat;
      background-position: center top;
      position: absolute;
      width: 56px;
      display: flex;
      align-items: end;
      height: 46px;
      right: 10px;
      cursor: default;
      font-family: Roboto, helvetica, arial, sans-serif;
      font-size: 10px;
      color: #555;
    }
  }
`;

export const ActiveDashboard = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;

  input {
    transform: scale(2);
  }
  .active-dashboard {
    margin-left: 10px;
    font-size: 16px;
    color: #0b4e71 !important;
    font-family: 'Poppins', sans-serif !important;
  }
  #form_active_dashboard {
    display: none;
    margin: 0 !important;
    padding: 0 !important;
  }
  .switch {
    position: relative;
    background-color: #c1c1c1;
    width: 50px;
    height: 13px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    padding: 5px;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
  }
  .slider {
    position: absolute;
    background-color: white;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    transition: all 0.5s ease-in-out;
  }
  #form_active_dashboard:checked ~ .switch {
    background-color: #39acb5;
  }
  #form_active_dashboard:checked ~ .switch .slider {
    transform: translateX(35px);
  }
`;

export const ContainerLoginAccout = styled.div``;

export const ButtonMicrosoft = styled.button`
  display: inline-flex;
  color: #fff;
  background: #2f2f2f;
  margin-bottom: 5px;
  align-items: center;
  display: flex;
  padding: 9px;
  cursor: pointer;
  height: 52px;
  border-radius: 4px;
  border: none;
  width: 100%;
  font-weight: 500;
  max-width: 285px;
  box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
  font-family: 'Poppins', sans-serif !important;

  &:hover {
    background: #404040;
  }
  p {
    align-items: center;
  }
  .image_button_microsoft {
    width: 25px;
    position: relative;
    align-items: flex-start;
    margin-right: 20px;
    justify-content: flex-start;
  }

  @media (max-width: 800px) {
    .image_button_microsoft {
      margin-right: 8px !important;
    }
  }
`;
