import React from 'react'
import Spinner from 'components/Spinner'
import { MEDICAL_HISTORY_IDS } from 'config/constants'
import Resource from 'containers/Resource'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CardDiagnostic from 'users/components/CardDiagnostic'
import CardDiagnosticHM from 'users/components/CardDiagnosticHM'
import mediaQuery from 'utils/mediaQuery'
import Tour from '../Tour/Tour'

const Container = styled.div`
  width: 600px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 15px;
  padding-bottom: 20px;
  grid-template-areas:
        "a b"
        "a c"
        "d e";

  > div:nth-child(n+3){
    margin-top: 15px
  }

  ${mediaQuery.lessThan('large')`
    max-width: 280px;
    grid-template-columns: 1fr;
    grid-template-areas:
      "a"
      "a"
      "b"
      "c"
      "d"
      "e";

    > div:nth-child(2){
      margin-top: 15px
    }
  `}
`

export default function AvailableDiagnostics({ quantity, ...rest }){
  let runTour = false;

  return (
    <Resource resource='UserAnamnese' autoFetch>
      {props => {
        if (props.isFetching) {
          return <Spinner />
        }
        return (
          <Container>
            {quantity > 2 && props.records.filter(row => MEDICAL_HISTORY_IDS.includes(row.value)).map((record, key) => {
              runTour = !record.hasMedicalHistoryFilled;
              return (
                <CardDiagnosticHM
                  key={record.value}
                  id={record.value}
                  image={record.icon_url}
                  title={record.originalTitle}
                  legenda={record.diagnostic_description}
                  description={record.diagnostic_description}
                  isActive={record.hasMedicalHistoryFilled || MEDICAL_HISTORY_IDS.includes(record.value)}
                  needFillMedicalHistoryAgain={record.needFillMedicalHistoryAgain}
                  hasFilledCurrentDiagnostic={record.hasFilledCurrentDiagnostic || record.hasMedicalHistoryFilled}
                  color={record.diagnostic_color}
                />
              )
            })}
              {props.records.filter(row => !MEDICAL_HISTORY_IDS.includes(row.value)).map((record, key) => {
                if(rest.selectedDiagnostic && rest.selectedDiagnostic === record.value){
                  return ''
                }

                if(key > quantity){
                  return ''
                }

                return <CardDiagnostic
                  key={record.value}
                  id={record.value}
                  image={record.icon_url}
                  title={record.originalTitle}
                  legenda={record.diagnostic_description}
                  description={record.diagnostic_description}
                  isActive={record.hasMedicalHistoryFilled || MEDICAL_HISTORY_IDS.includes(record.value)}
                  needFillMedicalHistoryAgain={record.needFillMedicalHistoryAgain}
                  hasFilledCurrentDiagnostic={record.hasFilledCurrentDiagnostic}
                  color={record.diagnostic_color}
                  coming_soon={record.coming_soon}
                />
              })}
              
            <Tour runTour={runTour}/>
          </Container>
        ) 
      }}
    </Resource>
  )
}

AvailableDiagnostics.propTypes = {
  quantity: PropTypes.number.isRequired,
}

AvailableDiagnostics.defaultProps = {
  quantity: 1000
}
