/**
 * Module dependencies.
 */

import updateObj from 'immutability-helper'
import createReducer from 'store/utils/createReducer'
import createActionTypes from 'store/utils/createActionTypes'
import { UserQuestionnaire } from 'store/api'
import { TYPE_QUESTIONS } from 'config/constants'
import { isAuthenticated, getAuthType } from 'store/ducks/auth'

/**
 * Action Types.
 */

export const actionTypes = createActionTypes('userQuestionnaire', [
  'FETCH_DATA',
  'FETCH_DATA_PENDING',
  'FETCH_DATA_REJECTED',
  'FETCH_DATA_FULFILLED',

  'UPDATE_PATIENT',
  'UPDATE_PATIENT_PENDING',
  'UPDATE_PATIENT_REJECTED',
  'UPDATE_PATIENT_FULFILLED',

  'ANSWER_QUESTION',
  'ANSWER_QUESTION_PENDING',
  'ANSWER_QUESTION_FULFILLED',
  'ANSWER_QUESTION_REJECTED',

  'COMPLETE_ANAMNESE',
  'COMPLETE_ANAMNESE_PENDING',
  'COMPLETE_ANAMNESE_FULFILLED',
  'COMPLETE_ANAMNESE_REJECTED',

  'FETCH_RESULT',
  'FETCH_RESULT_PENDING',
  'FETCH_RESULT_FULFILLED',
  'FETCH_RESULT_REJECTED'
])

/**
 * Initial State.
 */

const initialState = {
  isFetching: false,
  isSubmitting: false,
  isCompletingAnamnese: false,
  anamneseCompleted: false,
  diagnosticResult: {},
  data: {},
  result: {},
  error: {},
  auth: {
    anamneseID: '',
    patient: '', // identifica o paciente
    script: '', // hash que identifica o roteiro
    session: '' // hash que identifica a versão do roteiro
  }
}

/**
 * Reducer.
 */

export default createReducer(initialState, {
  /**
   * Fetch Data.
   */

  [actionTypes.FETCH_DATA_PENDING] (state, action) {
    return updateObj(state, {
      isFetching: { $set: true },
      isSubmitting: { $set: false },
      isCompletingAnamnese: { $set: false },
      anamneseCompleted: { $set: false }
    })
  },

  [actionTypes.FETCH_DATA_FULFILLED] (state, { payload, meta }) {
    return updateObj(state, {
      isFetching: { $set: false },
      data: { $set: payload.data },
      auth: { $set: meta.auth },
      error: { $set: {} }
    })
  },

  [actionTypes.FETCH_DATA_REJECTED] (state, { payload }) {
    return updateObj(state, {
      isFetching: { $set: false },
      data: { $set: {} },
      error: { $set: payload }
    })
  },

  /**
   * Update Patient.
   */

  [actionTypes.UPDATE_PATIENT_PENDING] (state, action) {
    return updateObj(state, {
      isSubmitting: { $set: true }
    })
  },

  [actionTypes.UPDATE_PATIENT_FULFILLED] (state, { payload }) {
    return updateObj(state, {
      isSubmitting: { $set: false },
      data: {
        paciente: { $set: payload.data }
      },
      error: { $set: {} }
    })
  },

  [actionTypes.UPDATE_PATIENT_REJECTED] (state, { payload }) {
    return updateObj(state, {
      isSubmitting: { $set: false },
      error: { $set: payload }
    })
  },

  /**
   * Answer Question.
   */

  [actionTypes.ANSWER_QUESTION_PENDING] (state) {
    return updateObj(state, {
      isSubmitting: { $set: true }
    })
  },

  [actionTypes.ANSWER_QUESTION_FULFILLED] (state) {
    return updateObj(state, {
      isSubmitting: { $set: false },
    })
  },

  [actionTypes.ANSWER_QUESTION_REJECTED] (state, { payload }) {
    return updateObj(state, {
      isSubmitting: { $set: false },
      error: { $set: payload }
    })
  },

  /**
   * Completing Anamnese
   */

  [actionTypes.COMPLETE_ANAMNESE_PENDING] (state) {
    return updateObj(state, {
      isSubmitting: { $set: true },
      isCompletingAnamnese: { $set: true }
    })
  },

  [actionTypes.COMPLETE_ANAMNESE_FULFILLED] (state, { payload }) {
    return updateObj(state, {
      isSubmitting: { $set: false },
      isCompletingAnamnese: { $set: false },
      anamneseCompleted: { $set: true },
      diagnosticResult : { $set: payload.data }
    })
  },

  [actionTypes.COMPLETE_ANAMNESE_REJECTED] (state, { payload }) {
    return updateObj(state, {
      isCompletingAnamnese: { $set: false },
      isSubmitting: { $set: false },
      error: { $set: payload }
    })
  },

  /**
   * Fetch Result.
   */

  [actionTypes.FETCH_RESULT_PENDING] (state) {
    return updateObj(state, {
      isSubmitting: { $set: true }
    })
  },

  [actionTypes.FETCH_RESULT_FULFILLED] (state, { payload }) {
    return updateObj(state, {
      isSubmitting: { $set: false },
      result: { $set: payload.data },
      error: { $set: {} }
    })
  },

  [actionTypes.FETCH_RESULT_REJECTED] (state, { payload }) {
    return updateObj(state, {
      isSubmitting: { $set: false },
      error: { $set: payload }
    })
  }
})

/**
 * Action Creators.
 */

const isAuth = state =>
  isAuthenticated(state) && getAuthType(state) === 'usersAdmWeb'

export const fetchData = ({ anamneseID }) => (dispatch, getState) => dispatch({
  type: actionTypes.FETCH_DATA,
  payload: UserQuestionnaire.fetchData({ anamneseID }, isAuth(getState())),
  meta: {
    auth: {
      anamneseID
    },
    notifications: {
      success: false,
      errors: true
    }
  }
})

export const updatePatient = args => (dispatch, getState) => dispatch({
  type: actionTypes.UPDATE_PATIENT,
  payload: UserQuestionnaire.updatePatient(args, isAuth(getState())),
  meta: {
    notifications: {
      success: false,
      errors: true
    }
  }
})

export const answerQuestion = args => (dispatch, getState) => dispatch({
  type: actionTypes.ANSWER_QUESTION,
  payload: UserQuestionnaire.answerQuestion(args, isAuth(getState())),
  meta: {
    notifications: {
      errors: true
    }
  }
})

export const completeAnamnese = args => (dispatch, getState) => dispatch({
  type: actionTypes.COMPLETE_ANAMNESE,
  payload: UserQuestionnaire.completeAnamnese(args, isAuth(getState())),
  meta: {
    notifications: {
      errors: true
    }
  }
})

export const fetchResult = args => (dispatch, getState) => dispatch({
  type: actionTypes.FETCH_RESULT,
  payload: UserQuestionnaire.fetchResult(args, isAuth(getState())),
  meta: {
    notifications: {
      errors: true
    }
  }
})

/**
 * Selectors.
 */

export const isFetching = state =>
  state.userQuestionnaire.isFetching

export const isSubmitting = state =>
  state.userQuestionnaire.isSubmitting

export const getData = state =>
  state.userQuestionnaire.data

export const getCategories = state => {
  const data = getData(state)
  const categories = data.categorias
  const perguntasRespondidas = data.paciente.perguntas_respondidas
  const respostas = perguntasRespondidas.reduce((memo, answer) => {
    memo[answer.id] = answer
    return memo
  }, {})

  return categories.map(c => {
    c.progresso = c.script.reduce((memo, question) => {
      if (question.typeQuestion === TYPE_QUESTIONS.GROUP.id) {
        return !question.children.find(q => !respostas.hasOwnProperty(q.id))
          ? memo + 1
          : memo
      }

      return respostas.hasOwnProperty(question.id)
        ? memo + 1
        : memo
    }, 0) / c.script.length
    return c
  })
}

export const getCategory = (state) => {
  const data = getData(state)
  return data
}

export const getError = state =>
  state.userQuestionnaire.error

export const getAuth = state =>
  state.userQuestionnaire.auth

export const getResult = state =>
  state.userQuestionnaire.result

export const isCompletingAnamnese = state =>
  state.userQuestionnaire.isCompletingAnamnese

export const anamneseCompleted = state =>
  state.userQuestionnaire.anamneseCompleted

export const getDiagnosticResult = state => 
  state.userQuestionnaire.diagnosticResult
