import Icon from '@fortawesome/react-fontawesome';
import CustomFieldSelect from 'admin/components/CustomFieldSelect';
import SubmissionContainer from 'admin/components/SubmissionContainer';
import WithModal from 'admin/components/WithModal';
import ButtonSubmit from 'components/ButtonSubmit';
import CustomField from 'components/CustomField';
import FormGrid from 'components/FormGrid';
import Link from 'components/Link';
import VerticalSpacer from 'components/VerticalSpacer';
import { PATIENTS_ETNIAS } from 'config/constants';
import React from 'react';
import Notifications from 'containers/Notifications';
import { Field, Form } from 'react-final-form';
import { injectIntl } from 'react-intl';
import * as forms from 'utils/forms';

function FormInformationRegister({
  intl,
  hideForgetPasswordLink,
  company,
  formData,
  setFormData,
  setPage,
  terms,
  isFetching,
  isSubmitting,
  ...props
}) {
  //função do froala
  const handleFormatFroala = content => {
    content = content
      .replace('Powered by ', '')
      .replaceAll('Froala Editor', '');
    return content;
  };

  //função que válida antes de enviar os dados do formulário
  const formSubmit = (e, props, callback) => {
    e.preventDefault();

    //state e setState e mudança das pages
    if (company.company_id !== 139) {
      setFormData({ ...props.values });
      sessionStorage.clear();

      callback();
    } else {
      setFormData({
        ...formData,
        ...props.values,
      });
      setPage(currPage => currPage + 1);
    }
  };

  //função que volta das pages
  const handleBackPage = e => {
    e.preventDefault();

    sessionStorage.clear();
    setPage(currPage => currPage - 1);
  };

  return (
    <Form
      {...props}
      initialValues={{
        ...formData,
        step: 'form_info_validate',
        index: 2,
      }}
    >
      {({ handleSubmit, hasValidationErrors, ...formProps }) => (
        <form onSubmit={e => formSubmit(e, formProps, handleSubmit)}>
          <VerticalSpacer>
            <Notifications />
            <FormGrid>
              <Field
                name="genero_biologico"
                label={intl.formatMessage({
                  id: 'admin.common.gender.biologic',
                })}
                id="form_patient_gen_bio"
                component={CustomFieldSelect}
                placeholder={intl.formatMessage({ id: 'admin.comon.select' })}
                customSelectProps={{
                  options: [
                    {
                      label: intl.formatMessage({
                        id: 'admin.common.genderMale',
                      }),
                      value: 'Masculino',
                    },
                    {
                      label: intl.formatMessage({
                        id: 'admin.common.genderFemale',
                      }),
                      value: 'Feminino',
                    },
                  ],
                }}
                showMandatory
                {...forms.required}
              ></Field>
            </FormGrid>

            <FormGrid>
              <Field
                name="etnia"
                label={intl.formatMessage({ id: 'q.ethnicity.question' })}
                id="form_patient_etnia"
                component={CustomFieldSelect}
                placeholder={intl.formatMessage({ id: 'admin.comon.select' })}
                customSelectProps={{
                  options: [
                    {
                      label: intl.formatMessage({
                        id: `admin.common.ethnicity.${PATIENTS_ETNIAS.white.label}`,
                      }),
                      value: PATIENTS_ETNIAS.white.value,
                    },
                    {
                      label: intl.formatMessage({
                        id: `admin.common.ethnicity.${PATIENTS_ETNIAS.black.label}`,
                      }),
                      value: PATIENTS_ETNIAS.black.value,
                    },
                    {
                      label: intl.formatMessage({
                        id: `admin.common.ethnicity.${PATIENTS_ETNIAS.brown.label}`,
                      }),
                      value: PATIENTS_ETNIAS.brown.value,
                    },
                    {
                      label: intl.formatMessage({
                        id: `admin.common.ethnicity.${PATIENTS_ETNIAS.yellow.label}`,
                      }),
                      value: PATIENTS_ETNIAS.yellow.value,
                    },
                    {
                      label: intl.formatMessage({
                        id: `admin.common.ethnicity.${PATIENTS_ETNIAS.indigenous.label}`,
                      }),
                      value: PATIENTS_ETNIAS.indigenous.value,
                    },
                  ],
                }}
                showMandatory
                {...forms.required}
              />
            </FormGrid>

            <FormGrid>
              <Field
                name="dt_nascimento"
                label={intl.formatMessage({ id: 'admin.common.dateOfBirth' })}
                id="form_patient_birthday"
                component={CustomField}
                placeholder={intl.formatMessage({
                  id: 'admin.common.birthDatePlaceHolder',
                })}
                showMandatory
                {...forms.dtNascimentoRequired}
              />
            </FormGrid>

            <WithModal modal={handleFormatFroala(terms)}>
              {({ toggleModal }) => (
                <div>
                  <Field
                    name="termos_uso"
                    component={CustomField.Option}
                    type="checkbox"
                    validate={v =>
                      !v && intl.formatMessage({ id: 'q.acceptRequired' })
                    }
                  >
                    {intl.formatMessage({ id: 'q.termsAccept' })}{' '}
                    <Link
                      style={{
                        color: '#34b5ae',
                        fontWeight: 'bold',
                        textAlign: '-webkit-match-parent',
                      }}
                      component="button"
                      onClick={e => {
                        e.preventDefault();
                        toggleModal();
                      }}
                    >
                      {intl.formatMessage({ id: 'q.termsOfService' })}
                    </Link>
                    <span style={{ color: 'red' }}> *</span>
                  </Field>
                </div>
              )}
            </WithModal>

            {/* <FormGrid>
              <Field
                name="contato_telefonico"
                component={CustomField.Option}
                type="checkbox"
                // validate={v =>
                //   !v && intl.formatMessage({ id: 'q.acceptRequired' })
                // }
              >
                {intl.formatMessage({ id: 'q.accept.receive' })}{' '}
                {intl.formatMessage({ id: 'q.additional.information' })}
              </Field>
            </FormGrid> */}

            <FormGrid>
              <Field
                name="contato_previneo_whatsapp"
                component={CustomField.Option}
                type="checkbox"
              >
                {intl.formatMessage({ id: 'q.accept.receive' })}{' '}
                {intl.formatMessage({
                  id: 'q.additional.information.whatsapp',
                })}
              </Field>
            </FormGrid>

            {company.company_id !== 139 ? (
              <SubmissionContainer>
                <Link to={''} onClick={e => handleBackPage(e)}>
                  <Icon
                    className="icon_arrow"
                    style={{ marginRight: '10px', width: 15 }}
                    icon={require('@fortawesome/fontawesome-free-solid/faArrowLeft')}
                  />
                  {intl.formatMessage({ id: 'pages.register.code.return' })}
                </Link>

                <ButtonSubmit
                  color="primary"
                  textColor="white"
                  isSubmitting={isSubmitting}
                  disabled={isSubmitting || hasValidationErrors}
                  label={intl.formatMessage({
                    id: 'pages.finalize.registration',
                  })}
                />
              </SubmissionContainer>
            ) : (
              <SubmissionContainer>
                <Link to={''} onClick={e => handleBackPage(e)}>
                  <Icon
                    className="icon_arrow"
                    style={{ marginRight: '10px', width: 15 }}
                    icon={require('@fortawesome/fontawesome-free-solid/faArrowLeft')}
                  />
                  {intl.formatMessage({ id: 'pages.register.code.return' })}
                </Link>

                <ButtonSubmit
                  color="primary"
                  textColor="white"
                  isSubmitting={isFetching}
                  disabled={isFetching || hasValidationErrors}
                  label={intl.formatMessage({
                    id: 'pages.register.code.continue',
                  })}
                />
              </SubmissionContainer>
            )}
          </VerticalSpacer>
        </form>
      )}
    </Form>
  );
}

export default injectIntl(FormInformationRegister);
