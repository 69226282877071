import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import Icon from 'components/Icon'
import { componentType } from 'types'
import { Container, InputStyled, Helper } from './styles'

export default function Input ({ tooltip, ...props }) {
  return (
    <Container>
      <InputStyled {...props} />
      {tooltip && (
        <Helper>
          <Icon icon='info-circle' data-tip={tooltip} />
          <ReactTooltip />
        </Helper>
      )}
    </Container>
  )
}

Input.propTypes = {
  /** Componente */
  component: componentType,

  /** Tooltip helper */
  tooltip: PropTypes.string
}

Input.defaultProps = {
  component: 'input'
}

Input.Textarea = props =>
  <Input component='textarea' {...props} />
