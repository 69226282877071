import React from 'react'
import { injectIntl } from 'react-intl'
import Screen from 'components/Screen'
import PageContent from 'components/PageContent'
import Container from 'components/Container'
import Text from 'components/Text'

function NotFoundScreen ({ intl }) {
  return (
    <Screen title={intl.formatMessage({ id: 'site.notFound.title' })}>
      <PageContent>
        <Container size='small'>
          <Text align='center'>
            {intl.formatMessage({ id: 'site.notFound.message' })}
          </Text>
        </Container>
      </PageContent>
    </Screen>
  )
}

export default injectIntl(NotFoundScreen)
