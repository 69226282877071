import React, { Fragment } from "react";
import { Bar } from "react-chartjs-2";
import { injectIntl } from "react-intl";
import { Container, FlexBox } from "./styles.js";

const ChartFem = ({ intl, dataset }) => {
  
  const handleFormatDataset = (dataset = []) => {
     return dataset.length ? dataset.map(item => (
       {
        label: intl.formatMessage({ id: item.name }),
        data: item.data,
        backgroundColor: item.color || "#de9c9dA6",
        borderColor: item.border || "#de9c9d",
        borderWidth: 1
       }
     )) : []
  }
  return (
    <Fragment>
      <FlexBox>
        <Container>
          <Bar
            data={{
              labels: ["< 25", "25-40", "> 40"],
              datasets: handleFormatDataset(dataset)
            }}
            height={300}
            width={"100%"}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              onResize: 200,
              aspectRatio: 2,
              resizeDelay: 5,
              scales: {
                y: {
                  beginAtZero: true
                }
              },
              plugins: {
                title: {
                  display: true,
                  text: intl.formatMessage({ id: "admin.female.age.group" }),
                  color: "#d68485",
                  align: "start",
                  font: {
                    size: 15
                  }
                },
                subtitle: {
                  display: true,
                  align: "start",
                  text: intl.formatMessage({
                    id: "admin.common.qtd.age"
                  }),
                  padding: {
                    bottom: 28,
                    top: -4
                  }
                },
                legend: {
                  display: true,
                  position: "bottom"
                }
              }
            }}
          />
        </Container>
      </FlexBox>
    </Fragment>
  );
};

export default injectIntl(ChartFem);
