import React, { useState } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'ramda'
import QuestionnaireScreen from 'users/questionnaire/components/QuestionnaireScreen'
import CategoryWizard from 'users/questionnaire/containers/CategoryWizard'
import { getAuth, getData } from 'store/ducks/userQuestionnaire'
import Text from 'components/Text'
import { injectIntl, FormattedMessage } from 'react-intl'



function CategoryScreen ({ data, auth, readOnly, intl}) {
  const { settings, fields, questionaire_id } = data
  const [subcategory, setSubcategory] = useState('')

  if(!settings.diagnostic_id || isEmpty(fields)) {
    return (
      <Text color="warning">
        <FormattedMessage id='users.anamneses.notfound' />
      </Text>
    )
  } 

  return (
    <QuestionnaireScreen
      title={ intl.formatMessage({id: settings.diagnostic_name}) }
      subcategory={subcategory}
      color={settings.diagnostic_color}
      icon={settings.icon_url}
    >
      <CategoryWizard questionaire_id={questionaire_id} fields={fields} color={settings.diagnostic_color} readOnly={readOnly} setSubcategory={setSubcategory} />
    </QuestionnaireScreen>
  )
}

const mapStateToProps = (state) => ({
  data: getData(state),
  auth: getAuth(state),
  readOnly: state.userQuestionnaire.data.paciente.anamnese_finalizada
})

export default connect(
  mapStateToProps
)(injectIntl(CategoryScreen))
