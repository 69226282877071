import React from 'react'
import styled from 'styled-components'
import Base from 'components/Base'
import { componentType } from 'types'
import { omit } from 'ramda'

const filterProps = omit([
  'arrow',
  'right'
])

const BoxStyled = styled(props => <Base {...filterProps(props)} />)`
  background-color: ${props => props.theme.colors.secondaryYellow};
  border: 1px solid #e5e5e5;
  padding: 20px 35px;
  box-sizing: border-box;
  position: relative;
  /*overflow: hidden;*/
  border-radius: 10px;
  ${props => props.width && `
    width: ${props.width};
  `}
  ${props => props.arrow && `
    &::before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 0 5px 10px;
      border-color: transparent transparent transparent #000;
      position: absolute;
      top: 25px;
      
      ${props.right ? `right: 0;` : `left: 0;`}

      ${props.color && `
        border-color: transparent transparent transparent ${props.theme.colors[props.color]};
      `}
      
      ${props.right && `
        border-width: 5px 10px 5px 0;
        border-color: transparent ${props.theme.colors[props.color]} transparent;
      `}
    }
  `}
`

export default function Box (props) {
  return <BoxStyled {...props} />
}

Box.propTypes = {
  /** Componente */
  component: componentType
}

Box.defaultProps = {
  component: 'div'
}
