import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Helmet from 'react-helmet'

class Screen extends Component {
  static propTypes = {
    /** Título da página */
    title: PropTypes.string.isRequired,

    /** Descrição da página */
    description: PropTypes.string,

    /** Conteúdo da screen */
    children: PropTypes.any.isRequired
  }

  componentDidMount () {
    window.scrollTo(0, 0)
  }

  componentDidUpdate ({ location }) {
    if (location !== this.props.location) {
      this.componentDidMount()
    }
  }

  render () {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.props.title}</title>
          {this.props.description && (
            <meta name='description' content={this.props.description} />
          )}
        </Helmet>
        {this.props.children}
      </React.Fragment>
    )
  }
}

export default withRouter(Screen)
