import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { injectGlobal, ThemeProvider } from 'styled-components'
import LocalizationProvider from 'containers/LocalizationProvider'
import theme from 'theme'
import configureStore from 'store'
import { APP_URL } from 'config/constants'

import AthelasRegular from '../../fonts/Athelas-Regular.woff'
import AthelasItalic from '../../fonts/Athelas-Italic.woff'
import AthelasBold from '../../fonts/Athelas-Bold.woff'
import AthelasBoldItalic from '../../fonts/Athelas-BoldItalic.woff'

injectGlobal`
  @import url('https://fonts.googleapis.com/css?family=Barlow+Condensed:400,500,700|Fira+Sans:400,400i,500,700');
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

  @font-face {
    font-family: 'Athelas';
    font-style: normal;
    font-weight: 400;
    src: local('Athelas'), url('${AthelasRegular}') format('woff');
  }

  @font-face {
    font-family: 'Athelas';
    font-style: normal;
    font-weight: 500;
    src: local('Athelas'), url('${AthelasItalic}') format('woff');
  }

  @font-face {
    font-family: 'Athelas';
    font-style: normal;
    font-weight: 700;
    src: local('Athelas'), url('${AthelasBold}') format('woff');
  }

  @font-face {
    font-family: 'Athelas';
    font-style: normal;
    font-weight: 800;
    src: local('Athelas'), url('${AthelasBoldItalic}') format('woff');
  }

  * {
    outline: 0;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: ${theme.fonts.primary};
  }
`

const store = configureStore()

export default function Wrapper (props) {
  return (
    <Provider store={store}>
      <LocalizationProvider>
        <Router basename={APP_URL}>
          <ThemeProvider theme={theme}>
            <div {...props} />
          </ThemeProvider>
        </Router>
      </LocalizationProvider>
    </Provider>
  )
}
