import Button from 'components/Button'
import React, { useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import routes from 'routes'
import Alert from '../../../components/Alert/Alert'
import FormModalEmail from '../../forms/FormModalEmail/FormModalEmail'
import { Container, FlexDiv, FlexModal } from './styles'

function DiagnosticResult({ intl, initialValues, ...props }){
  const [ state, setState ] = useState({
    Modal: ()=> <div></div>
  })

  console.log(intl.locale)

  const handleShowModal = ()=>{
    setState({
      Modal: ()=>
        <FlexModal>
          <FormModalEmail
            onSubmit={handleDiagnosticShare}
            handleDestructModal={handleDestructModal}
          />
        </FlexModal>      
    })
  }

  const handleAlertModal = ()=>{
    setState({
      Modal: ()=> 
        <FlexModal>
          <Alert
            type='success'
            message="Email enviado com sucesso"
            timer={2000}
          />
        </FlexModal>      
    })

    setTimeout( handleDestructModal , 3000  )
  }

  const handleDestructModal = ()=>{
    setState( { Modal:()=> <div></div> } )
  }

  const handleDiagnosticShare = data =>{
    const mail = {
      email : data.email,
      content : initialValues.result,
      subject : initialValues.subject,
    }
   
    fetch(process.env.REACT_APP_API_URL+'/api/mail/share',{
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      method: 'POST',
      body: JSON.stringify(mail)
    }).then(res =>{
      handleAlertModal()
    })
  }
  return (
    <Container>
      <div>
          {initialValues.risk && (
            <div>
              <div dangerouslySetInnerHTML={{ __html: initialValues.riskMailBody }} />
            </div>
          )}
      </div>

      <FlexDiv>

        <Button
          component={Link}
          to={routes.users.index}
          color={initialValues.diagnostic.diagnostic_color}
          style={{ marginTop: 50, alignSelf: 'start'}}
        >
          <FormattedMessage id='admin.common.start' />
        </Button>

        <Button         
          onClick={handleShowModal}
          color={'#4890e2'}
          style={{ marginTop: 50, alignSelf: 'start'}}
        >
          <FormattedMessage id='admin.common.share' />
        </Button>

        <state.Modal />

      </FlexDiv>
    </Container>
  )
}

export default injectIntl(DiagnosticResult)
