import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl' 
import { Container, CardTypeTitle, DiagnosticTitle, DiagnosticTitleLink, LinkStyled, LinkDisabledStyled } from './styles'
import { MEDICAL_HISTORY_IDS } from 'config/constants'
import routes from 'routes'
import getRoute from 'utils/getRoute'
import ReactTooltip from 'react-tooltip'

function CardDiagnostic({ title, image, legenda, id, color, intl, isActive, needFillMedicalHistoryAgain, hasFilledCurrentDiagnostic, coming_soon, ...props }){
  const isMedicalHistory = MEDICAL_HISTORY_IDS.includes(id)
  const url = hasFilledCurrentDiagnostic 
            ? getRoute(routes.users.diagnosticResults.fewDetails, { anamneseID: id})
            : getRoute( (isMedicalHistory || !needFillMedicalHistoryAgain ? routes.users.diagnostic.startAnamnese : routes.users.diagnostic.beforeStartAnamnese), { anamneseID: id})

  const ContainerLink = coming_soon !== 'Y' && isActive 
    ? elProps => <LinkStyled to={url} color={color} active={isActive.toString()} {...elProps} />
    : elProps => <LinkDisabledStyled to={routes.users.index} active={isActive.toString()} {...elProps} />
    
  let tooltipOptions = [];
  tooltipOptions['data-tip'] = intl.formatMessage({ id: 'admin.anamnesis.coming_soon' })
  tooltipOptions['data-for'] = 'comingsoon_anamnese_tooltip'

  return (
    <Container 
      color={color} 
      image={image} 
      className={`${coming_soon !== 'Y' && isActive ? '' : 'notActive'} card_diagnostics`}
      {...(coming_soon === 'Y' && tooltipOptions)}
    >
      <ReactTooltip id='comingsoon_anamnese_tooltip' />
      <ContainerLink>
        <CardTypeTitle>{intl.formatMessage({ id: 'users.dashboard.card.type' })}</CardTypeTitle>
        <DiagnosticTitle>{ intl.formatMessage({ id: title }) }</DiagnosticTitle>
        <DiagnosticTitleLink>{hasFilledCurrentDiagnostic ? intl.formatMessage({ id: 'users.results.card.seeResult' }) : intl.formatMessage({ id: 'users.results.card.fillText' }) }</DiagnosticTitleLink>
      </ContainerLink>
    </Container>
  )
}

CardDiagnostic.propTypes = {
  /** ID */
  id: PropTypes.any.isRequired,

  /** Imagem */
  image: PropTypes.string.isRequired,

  /** Título */
  title: PropTypes.string.isRequired,

  /** Descrição */
  description: PropTypes.string.isRequired,

  /** Cor do hover */
  color: PropTypes.string.isRequired,

  /** Diagnostico Ativo */
  isActive: PropTypes.bool.isRequired,

  /** Precisa preencher novamente o Historico Medico */
  needFillMedicalHistoryAgain: PropTypes.bool.isRequired,

  /** Já preencheu este diagnostico */
  hasFilledCurrentDiagnostic: PropTypes.bool.isRequired
}

export default withRouter(injectIntl(CardDiagnostic))
