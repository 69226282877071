import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import Section from "admin/components/Section";
import { Form, Field } from "react-final-form";
import VerticalSpacer from "components/VerticalSpacer";
import Text from "components/Text";
import CustomField from "components/CustomField";
import SubmissionContainer from "admin/components/SubmissionContainer";
import ButtonSubmit from "components/ButtonSubmit";
import Link from "components/Link";
import * as forms from "utils/forms";
import routes from "routes";
import { FormattedMessage } from "react-intl";
import Languages from "components/Languages/Languages";

function FormPasswordRecovery({ isSubmitting, intl, ...props }) {
  return (
    <Languages>
      <Section
        title={intl.formatMessage({ id: "admin.forms.passwordRecovery.title" })}
        style={{ marginTop: 50 }}
      >
        <Form {...props} initialValues={{ provider: "userAdmWeb" }}>
          {({ handleSubmit, pristine }) => (
            <form onSubmit={handleSubmit}>
              <VerticalSpacer>
                <Text>
                  <FormattedMessage id="After completing the form below, you will receive an email link to reset your password." />
                </Text>
                <Field
                  name="email"
                  id="form_password_recovery_email"
                  label={intl.formatMessage({ id: "admin.forms.login" })}
                  component={CustomField}
                  {...forms.loginRequired}
                />
                <SubmissionContainer>
                  <ButtonSubmit
                    label={intl.formatMessage({
                      id: "admin.forms.passwordRecovery.button"
                    })}
                    isSubmitting={isSubmitting}
                    disabled={pristine}
                  />
                  <Link to={routes.users.login}>
                    {intl.formatMessage({ id: "admin.common.back" })}
                  </Link>
                </SubmissionContainer>
              </VerticalSpacer>
            </form>
          )}
        </Form>
      </Section>
    </Languages>
  );
}

FormPasswordRecovery.propTypes = {
  /** Callback para o form submit */
  onSubmit: PropTypes.func.isRequired,

  /** Form sendo processado */
  isSubmitting: PropTypes.bool
};

export default injectIntl(FormPasswordRecovery);
