import Request from 'modules/Request'

export default {
  fetchAll (data) {
    return Request.auth({
      method: 'POST',
      url: `/api/dashboard`,
      body:data,
    }).then(res => res.body);
  }
}
