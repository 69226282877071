import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import AdminWrapper from 'admin/components/AdminWrapper';
import GuestScreen from 'admin/components/GuestScreen';
import FormRegister from 'users/forms/FormRegister';
import Cupom from 'users/containers/Cupom';
import PageContent from 'components/PageContent';
import Container from 'components/Container';
import Section from 'admin/components/Section';
import FormValidCupom from 'users/forms/FormValidCupom';
import Alert from 'components/Alert/Alert';
import Languages from 'components/Languages/Languages';
import Session from 'modules/Session';
import Resource from '../../../containers/Resource/Resource';

function RegisterScreen({ intl }) {
  const ticket = Session.get('ticket') || null;
  const [state, setState] = useState({ company_code: ticket });

  let cupom = 0;
  let implicityValidate = props => {
    if (cupom > 0) {
      return;
    }

    if (ticket) {
      props.validateCupom(ticket, window.recaptchaValue);
      cupom++;
    }
  };

  return (
    <AdminWrapper>
      <GuestScreen
        title={`${intl.formatMessage({
          id: 'pages.register.register'
        })} - PreviNEO`}
      >
        <PageContent>
          <Cupom>
            {props =>
              props.isValidCode ? (
                <div>
                  {props.registerSuccess ? (
                    <Container size="xsmall">
                      <Section title={''}>
                        <Alert type="success" message="Redirecionando..." />
                      </Section>
                    </Container>
                  ) : (
                    <Container size="xsmall" style={{ marginTop: 50 }}>
                      <Languages>
                          <Resource resource={'Register'}>
                            {({isSubmitting, ...reg_props})  => {
                              return (
                                <FormValidCupom
                                  onSubmit={data => props.register(data)}
                                  isSubmitting={props.isSubmitting}
                                  terms={props.termsOfService}
                                  company={props.company}
                                  {...state}
                                  {...reg_props}
                                >
                                </FormValidCupom>
                              );
                            }}
                          </Resource>
                      </Languages>
                    </Container>
                  )}
                </div>
              ) : (
                <Container size="xsmall" style={{ marginTop: 50 }}>
                  <Languages>
                    <Section
                      title={intl.formatMessage({
                        id: 'pages.register.register'
                      })}
                    >
                      <FormRegister
                        defaultValue={ticket}
                        onSubmit={data => {
                          setState({ company_code: data.company_code });
                          return props.validateCupom(
                            data.company_code,
                            window.recaptchaValue,
                          );
                        }}
                        isSubmitting={props.isSubmitting}
                      />
                      {implicityValidate(props)}
                    </Section>
                  </Languages>
                </Container>
              )
            }
          </Cupom>
        </PageContent>
      </GuestScreen>
    </AdminWrapper>
  );
}

export default injectIntl(RegisterScreen);
