import React from "react";
import Text from "components/Text";
import VerticalSpacer from "components/VerticalSpacer";
import { withTheme } from "styled-components";
import { injectIntl, FormattedMessage } from "react-intl";
import withAuth from "store/utils/withAuth";
import { GENDER } from "config/constants";
import Video from "users/components/Video";
import FlexRow from "components/FlexRow";
import { HtmlRender } from "../HtmlRender/HtmlRender";

function WelcomeDashboardHeader({ auth, ...props }) {
  const empresa = auth.user.empresa;
  return (
    <React.Fragment>
      <Text size="54px" sizeMobile="37px" color="textNew" lineHeight={1.1}>
        <FormattedMessage id="users.dashboard.title.one" />
      </Text>
    
      <FlexRow>
        {empresa.text_initial_client && <HtmlRender content={empresa.text_initial_client} />}
        {empresa.link_video && <Video link={empresa.link_video} />}
      </FlexRow>

      <VerticalSpacer space={25}>
        <div></div>
        <Text
          size="22px"
          color="textNew"
          weight="bold"
          fontFamily={props.theme.fonts.tertiary}
        >
          {auth.user.userData.genero_biologico === GENDER.male.id ? (
            <FormattedMessage
              id="users.dashboard.welcome.male"
              values={{ name: auth.user.userData.nome }}
            />
          ) : (
            <FormattedMessage
              id="users.dashboard.welcome.female"
              values={{ name: auth.user.userData.nome }}
            />
          )}
        </Text>
        <div></div>
      </VerticalSpacer>
    </React.Fragment>
  );
}

export default withTheme(withAuth(injectIntl(WelcomeDashboardHeader)));
