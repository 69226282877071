/*export { default as Auth } from './Auth'
export { default as Users } from './Users'
export { default as UsersRestore } from './UsersRestore'
export { default as Roles } from './Roles'
export { default as Companies } from './Companies'
export { default as HealthInsurances } from './HealthInsurances'
export { default as HealthInsurancesCompany } from './HealthInsurancesCompany'
export { default as IndicationCompany } from './IndicationCompany'
export { default as Media } from './Media'
export { default as Customers } from './Customers'
export { default as CustomersSite } from './CustomersSite'
export { default as Testimonies } from './Testimonies'
export { default as TestimoniesSite } from './TestimoniesSite'
export { default as Specialities } from './Specialities'
export { default as SpecialitiesSite } from './SpecialitiesSite'
export { default as TypesCancer } from './TypesCancer'
export { default as Team } from './Team'
export { default as Patients } from './Patients'
export { default as PatientsImporting } from './PatientsImporting'
export { default as PatientsExporting } from './PatientsExporting'
export { default as PatientsRestore } from './PatientsRestore'
export { default as PatientsHistory } from './PatientsHistory'
export { default as PatientsHistoryCurrent } from './PatientsHistoryCurrent'
export { default as PatientsHistoryLogs } from './PatientsHistoryLogs'
export { default as PatientsHistoryActions } from './PatientsHistoryActions'
export { default as PatientsHistoryEmail } from './PatientsHistoryEmail'
export { default as PatientLogs } from './PatientLogs'
export { default as ContactReason } from './ContactReason'
export { default as ContactStatus } from './ContactStatus'
export { default as Permissions } from './Permissions'
export { default as Tags } from './Tags'
export { default as BlogCategories } from './BlogCategories'
export { default as BlogCategoriesSite } from './BlogCategoriesSite'
export { default as Posts } from './Posts'
export { default as PostsSite } from './PostsSite'
export { default as RelatedPosts } from './RelatedPosts'
export { default as RelatedPostsSite } from './RelatedPostsSite'
export { default as Questions } from './Questions'
export { default as Scripts } from './Scripts'
export { default as Languages } from './Languages'
export { default as Genders } from './Genders'
export { default as Risks } from './Risks'
export { default as Contact } from './Contact'
export { default as Categories } from './Categories'
export { default as ContactInfos } from './ContactInfos'
export { default as Countries } from './Countries'
export { default as States } from './States'
export { default as Cities } from './Cities'
export { default as ScriptSend } from './ScriptSend'
export { default as Questionnaire } from './Questionnaire'
export { default as FormContact } from './FormContact'
export { default as Emails } from './Emails'
export { default as Layouts } from './Layouts'
export { default as MedicalRecords } from './MedicalRecords'
export { default as Strategies } from './Strategies'
export { default as RecomendedExams } from './RecomendedExams'
export { default as Charts } from './Charts'
export { default as Addresses } from './Addresses'
export { default as AnamneseCompany } from './AnamneseCompany'
export { default as DataExports } from './DataExports'
export { default as ScriptQuestions } from './ScriptQuestions'
export { default as Reports } from './Reports'
export { default as ReportsCompany } from './ReportsCompany'
export { default as AnamneseConfirmation } from './AnamneseConfirmation'
export { default as Justifications } from './Justifications'
export { default as ResultSite } from './ResultSite'
export { default as Register } from './Register'
export { default as UserPatients } from './UserPatients'
export { default as UserCompanies } from './UserCompanies'
export { default as UserQuestionnaire } from './UserQuestionnaire'
export { default as Anamneses } from './Anamneses'
export { default as UserAnamnese } from './UserAnamnese'
export { default as ProcessedResults } from './ProcessedResults'*/


export { default as Auth } from './Auth'
export { default as Companies } from './Companies'
export { default as HealthInsurances } from './HealthInsurances'
export { default as ReportsCompany } from './ReportsCompany'
export { default as HealthInsurancesCompany } from './HealthInsurancesCompany'
export { default as IndicationCompany } from './IndicationCompany'
export { default as Questionnaire } from './Questionnaire'
export { default as Addresses } from './Addresses'
export { default as Register } from './Register'
export { default as UserPatients } from './UserPatients'
export { default as UserCompanies } from './UserCompanies'
export { default as UserQuestionnaire } from './UserQuestionnaire'
export { default as ProcessedResults } from './ProcessedResults'
export { default as Anamneses } from './Anamneses'
export { default as UserAnamnese } from './UserAnamnese'
export { default as Media } from './Media'
export { default as PasswordToken } from './PasswordToken'
export { default as ReportsMedicalRisk } from './ReportsMedicalRisk'
export { default as ReportsMedicalHistory } from './ReportsMedicalHistory'
export { default as ReportsPersonalData } from './ReportsPersonalData'
export { default as ReportsMentalRisk } from './ReportsMentalRisk'
export { default as ReportsMentalHistorical } from './ReportsMentalHistorical'
export { default as ReportsApiCompany} from './ReportsApiCompany'
export { default as Cities} from './Cities'
export { default as States} from './States'
export { default as Dashboard} from './Dashboard'
