import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import theme from 'theme'
import Media from 'react-media'
import Logo from 'components/Logo'
import Button from 'components/Button'
import routes from 'routes'
import media from 'utils/mediaQuery'

const DashboardHeaderStyled = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  box-sizing: border-box;

  ${media.greaterThan('large')`
    padding: 0 20px;
    z-index: 999;
  `}
`

export default function DashboardHeader ({ onMenuButtonClick, intl, type, ...props }) {
  return (
    <DashboardHeaderStyled {...props}>
      <Logo to={routes.users.index} height={22} small />
      <Media query={`(max-width: ${theme.breakpoints.large})`}>
        {matches => matches && (
            <Button
              type='button'
              onClick={onMenuButtonClick}
              style={{ marginLeft: 30 }}
              className={`menu-button`}
            >
              Menu
            </Button>
          )
        }
      </Media>
    </DashboardHeaderStyled>
  )
}

DashboardHeader.propTypes = {
  /** Callback do botão de menu */
  onMenuButtonClick: PropTypes.func.isRequired
}
