import React from 'react';
import styled from 'styled-components';

const StrechStyle = styled.div`
  width: 100%;
  @media (min-width: 320px) {
    /* do celular para maiores */
    ${prop => prop.sm}
  }
  @media (min-width: 920px) {
    /* do tablet para maiores */
    ${prop => prop.md}
  }
`;
export const Strech = ({ sm, md, children }) => (
  <StrechStyle sm={sm} md={md}>
    {children}
  </StrechStyle>
);

const FlexStyle = styled.div`
  display: flex;
  @media (min-width: 320px) {
    /* do celular para maiores */
    flex-direction: ${prop =>
      prop.direction && prop.direction.sm
        ? prop.direction.sm
        : prop.row
        ? 'row'
        : prop.column
        ? 'column'
        : 'row'};
  }
  @media (min-width: 920px) {
    /* do tablet para maiores */
    flex-direction: ${prop =>
      prop.direction && prop.direction.md
        ? prop.direction.md
        : prop.row
        ? 'row'
        : prop.column
        ? 'column'
        : 'row'};
  }
  & > * {
    @media (min-width: 320px) {
      /* do celular para maiores */
      margin-bottom: ${prop => prop.space || 5}px;
    }
    @media (min-width: 920px) {
      /* do tablet para maiores */
      margin-right: ${prop => prop.space || 5}px;
    }
  }
`;
export const Flex = ({
  direction = '',
  space = 0,
  row = '',
  column = '',
  children,
}) => (
  <FlexStyle direction={direction} space={space} row={row} column={column}>
    {children}
  </FlexStyle>
);
