import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/symbol';
import 'core-js/modules/_array-includes';
import 'core-js/modules/es6.array.find';
import 'core-js/modules/es6.array.find-index';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import App from 'containers/App';
import registerServiceWorker from './registerServiceWorker';
import { Container } from './Syles';
import 'assets/css/datepicker.css';
import 'assets/css/joyrider-tooltip.css';

console.error = idx => '';

ReactDOM.render(
  <Container>
    <App />
  </Container>,
  document.getElementById('root'),
);

registerServiceWorker();
