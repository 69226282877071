import React from 'react'
import PropTypes from 'prop-types'
import SectionTitle from 'components/SectionTitle'
import Button from 'components/Button'
import VerticalSpacer from 'components/VerticalSpacer'
import Text from 'components/Text'
import Notifications from 'containers/Notifications'
import SubmissionContainer from 'admin/components/SubmissionContainer'
import { FormattedMessage } from 'react-intl'

export default function ConfirmModal ({ title, text, info, onConfirm, onCancel, isProcessing, notificationsId }) {
  return (
    <section>
      {notificationsId && (
        <Notifications notificationsId={notificationsId} />
      )}
      <VerticalSpacer space={25}>
        <VerticalSpacer space={15}>
          <SectionTitle size='35px'>{title}</SectionTitle>
          <Text>
            {text}
          </Text>
          {info && <Text size='12px'>{info}</Text>}
        </VerticalSpacer>
        <SubmissionContainer>
          <Button type='button' color='success' onClick={onConfirm} disabled={isProcessing}>
            {!isProcessing ? (
              <FormattedMessage id='admin.common.confirm' />
            ) : (
              <FormattedMessage id='admin.common.processing' />
            )}
          </Button>
          <Button type='button' color='danger' onClick={onCancel} disabled={isProcessing}>
            <FormattedMessage id='admin.common.cancel' />
          </Button>
        </SubmissionContainer>
      </VerticalSpacer>
    </section>
  )
}

ConfirmModal.propTypes = {
  /** Título do modal */
  title: PropTypes.string,

  /** Callback para confirmação */
  onConfirm: PropTypes.func.isRequired,

  /** Callback para cancelação */
  onCancel: PropTypes.func.isRequired,

  /** Texto */
  text: PropTypes.any,

  /** Sub texto */
  info: PropTypes.string,

  /** Ação sendo processada */
  isProcessing: PropTypes.bool,

  /** ID das notificações */
  notificationsId: PropTypes.string
}

ConfirmModal.defaultProps = {
  title: 'Deseja mesmo realizar esta ação?',
  text: 'Selecione uma opção abaixo:',
  isProcessing: false
}
