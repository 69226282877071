import Request from 'modules/Request'
import createApi from 'store/utils/createApi'

export default createApi('/api/user/patient', {
  updatePassword (data) {
    return Request.auth({
      method: 'PUT',
      url: `/api/user/updatepassword`,
      body: data
    }).then(res => res.body)
  },

  updateLang (data, calback) {
    return Request.auth({
      method: 'PUT',
      url: `/api/user/updateLang`,
      body: {language: data}
    }).then(res =>{
      calback(data)
      return res.body;
    })
  }
})
