import Image from 'components/Image'
import logoSmall from 'images/logo-small.png'
import logoWhite from 'images/logo-white.svg'
import logo from 'images/logo.svg'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

function handleUrlLogo() {
   window.location = 'https://www.previneo.com.br'
}

const LogoLink = styled(Link)`
  display: block;
  margin-right: 10px;
  &:hover {
    opacity: 0.7;
  }
`

const LogoRefer = type => {
  return type === 'small'
  ? logoSmall
  : type
    ? logoWhite : logo
}
    
export default function Logo ({ white, ...props }) {
  return (
    <LogoLink onClick={handleUrlLogo} to={'#'} title="Home">
      <Image
        src={LogoRefer(props.small ? 'small' : white)}
        style={{
          width: '80%',
          maxWidth: 170,
          height: 'auto',
          backgroundSize: 'cover',
        }}
        alt={'Home'}
        {...props}
      />
    </LogoLink>
  );
}

Logo.propTypes = {
  /** Usa a versão branca do logo */
  white: PropTypes.bool
}
