export default {
  'pages.home.title': 'PreviNEO - Revolución en la prevención y lucha contra el cáncer',
  'pages.home.description': 'PreviNEO reúne tecnología y conocimiento científico en el diagnóstico precoz y la prevención de los principales tipos de cáncer encontrados en Brasil.',
  'pages.about.title': 'Quienes somos - PreviNEO',
  'pages.about.description': 'Trabajamos para prevenir, diagnosticar y reducir los riesgos de incidencia de los principales tipos de cáncer que se encuentran en Brasil, a través de un programa de oncología y tecnología de punta.',
  'pages.specialities.title': 'Especialidades - PreviNEO',
  'pages.specialities.description': 'En PreviNEO realizamos una labor especializada en la prevención, diagnóstico y reducción del riesgo de incidencia en el cáncer de Próstata, Mama, Pulmón, Colon y Cérvix.',
  'pages.specialities.titleDescription': 'Con el fin de crear conciencia sobre la prevención del cáncer, a continuación encontrará más información sobre este tipo de cáncer.<br />',
  'pages.customers.title': 'Clientes - PreviNEO',
  'pages.customers.description': 'En PreviNEO contamos con empresas colaboradoras que ponen nuestros beneficios al alcance de los colaboradores y sus familias. ¡Accede y conoce quién está con nosotros en esta lucha!',
  'pages.blog.title': 'Blog - PreviNEO',
  'pages.blog.description': 'La información es un factor decisivo para el diagnóstico precoz del cáncer y su prevención eficaz. Nuestro Blog pone a tu disposición datos y noticias sobre nuestra causa, ¡visítanos!',
  'pages.contact.title': 'Datos de contacto - PreviNEO',
  'pages.contact.description': 'Para resolver dudas, enviar sugerencias o unirse a nosotros y convertirse en socio, envíenos un mensaje. ¡Nuestro equipo estará encantado de atenderle!',
  'pages.register.register': 'Registrarse',
  'pages.register.first.access': 'Mi primer inicio de sesión',
  'pages.register.code.continue': 'Seguir',
  'pages.finalize.registration' : 'Finalizar registro',
  'pages.register.code.return': 'Volver',
  'pages.login.title': 'Iniciar sesión - PreviNEO',
  'pages.login.description': 'Tela de inicio de sesión',
  'pages.register.code': 'Nombre de la empresa',
  
  /* Títulos dos links do menu */
  'site.menu.home': 'Home',
  'site.menu.about': 'Quienes somos',
  'site.menu.specialities': 'Especialidades',
  'site.menu.customers': 'Clientes',
  'site.menu.blog': 'Blog',
  'site.menu.contact': 'Dato de contacto',


  /* Autenticação e cadastro de pacientes */
  'site.authenticated.login': 'Login',
  'site.authenticated.password': 'Contraseña',
  'site.authenticated.password.redefine' : 'Redefinir contraseña',
  'site.authenticated.password.redefine.text': 'Restablezca su contraseña en los campos a continuación:',
  'site.authenticated.password.confirm' : 'Confirmar Contraseña',
  'site.authenticated.password.validate' : 'Las contraseñas deben ser las mismas',
  'site.authenticated.password.redefine.create' : '¡Se le ha enviado un correo electrónico con el enlace para restablecer su contraseña!',
  'site.authenticated.password.redefine.success' : 'Su contraseña ha sido actualizada satisfactoriamente!',

  /* 404 */
  'site.notFound.title': 'Página no encontrada',
  'site.notFound.message': '404: ¡Página no encontrada!',

  /* Login */
  'admin.forms.login.title': 'Login',
  'admin.forms.login': 'Correo electrónico o ID',
  'admin.forms.login.password': 'Contraseña',
  'admin.forms.login.button': 'Login',
  'admin.forms.login.passwordRecovery': 'Olvide mi contraseña',

  /* Password recovery */
  'admin.forms.passwordRecovery.title': 'Recuperación de Contraseña',
  'admin.forms.passwordRecovery.button': 'Recuperar',

  /* Admin Usuarios */
  'admin.users.title': 'Usuario',
  'admin.users.title.plural': 'Usuarios',
  'admin.users.form.sendPasswordEmail': 'Enviar contraseña por correo electrónico',
  'admin.users.searchForUsers': 'Buscar por usuario',
  'admin.users.deletedUsers': 'Usuarios eliminados',
  'admin.common.restore': 'Recuperar',
  'admin.common.restoreSelected': 'Recover selected',

  /* Names comuns admin */
  'admin.common.name': 'Nombre',
  'admin.common.full.name': 'Nombre completo',
  'admin.common.email': 'Correo electrónico',
  'admin.common.emails': 'Correos electrónicos',
  'admin.common.emailLayout': 'Diseño de correo electrónico',
  'admin.common.emailLayouts': 'Diseños de correo electrónico',
  'admin.common.emailContent': 'Contenido de correo electrónico',
  'admin.common.emailContents': 'Contenido del correo electrónico',
  'admin.common.password': 'Contraseña',
  'admin.common.confirmPassword': 'Confirmar Contraseña',
  'admin.common.oldPassword': 'Contraseña anterior',
  'admin.common.company': 'Empresa',
  'admin.common.accessLevels': 'Niveles de acceso',
  'admin.common.save': 'Guardar',
  'admin.common.send': 'Enviar',
  'admin.common.listOf': 'Lista de',
  'admin.common.register': 'Registrarse',
  'admin.common.edit': 'Editar',
  'admin.common.details': 'Detalles',
  'admin.common.remove': 'Borrar',
  'admin.common.removePermanently': 'Remove permanently',
  'admin.common.back': 'Volver',
  'admin.common.actions': 'Acciones',
  'admin.common.warningMessage': '¿Seguro que desea borrar este registro?',
  'admin.common.AreYouSureABoutIt': '¿Está seguro?',
  'admin.common.confirm': 'Confirmar',
  'admin.common.cancel': 'Cancelar',
  'admin.common.processing': 'Procesando...',
  'admin.common.previous': 'Anterior',
  'admin.common.next': 'Próximo',
  'admin.common.loading': 'Cargando...',
  'admin.common.loadMore': 'Carga más',
  'admin.common.noResultsFound': 'No se han encontrado resultados',
  'admin.common.page': 'Página',
  'admin.common.Of': 'de',
  'admin.common.results': 'Resultados',
  'admin.common.idNumber': 'Registro',
  'admin.common.gender': 'Género',
  'admin.common.genders': 'Géneros',
  'admin.common.gender.male': 'Masculino',
  'admin.common.gender.female': 'Feminino',
  'admin.common.gender.unisex': 'Unisex',
  'admin.common.genderMale': 'Masculino',
  'admin.common.genderFemale': 'Feminino',
  'admin.common.dateOfBirth': 'Fecha de nacimiento',
  'admin.common.companyPlaceHolder': 'Buscar Empresa',
  'admin.common.birthDatePlaceHolder': 'DD/MM/YYYY',
  'admin.common.filterData': 'Filtrar datos...',
  'admin.common.mostRecent': 'Más recientes',
  'admin.common.history': 'Historial completo',
  'admin.common.cpf': 'ID',
  'admin.common.weight': 'Peso (kg)',
  'admin.common.height': 'Altura (cm)',
  'admin.common.phone': 'Teléfono',
  'admin.common.cellphone': 'Móvil',
  'admin.common.acceptContact': 'Acepta recibir información adicional',
  'admin.common.yes': 'Si',
  'admin.common.no': 'No',
  'admin.common.cep': 'Código Postal',
  'admin.common.cepTooltip': 'Introduzca su código postal (Ej: 73015-132)',
  'admin.common.codeTooltip': 'Ingrese su Código postal (Ej: 73015)',
  'admin.common.country': 'País',
  'admin.common.state': 'Estado',
  'admin.common.city': 'Ciudad',
  'admin.common.address': 'Dirección',
  'admin.common.addressNumber': 'Número',
  'admin.common.addressComplement': 'Complemento',
  'admin.common.editCompany': 'Editar Empresa',
  'admin.common.registerCompany': 'Registrar Empresa',
  'admin.common.removeMessage': '¿Realmente desea eliminar esta empresa?',
  'admin.common.rootNohNotFound': '¡Nodo raíz no encontrado!',
  'admin.common.companyName': 'Nombre de la empresa',
  'admin.common.cnpj': 'ID de la compañía',
  'admin.common.parentCompany': 'Empresa Matriz',
  'admin.common.expirationDate': 'Fecha de Caducidad',
  'admin.common.logo': 'Logo',
  'admin.common.logoTopRight': 'Logotipo superior derecho',
  'admin.common.logoTopLeft': 'Logotipo superior izquierdo',
  'admin.common.logoBottomRight': 'Logotipo inferior derecho',
  'admin.common.logoBottomLeft': 'Logotipo inferior izquierdo',
  'admin.common.openURL': 'URL abierto',
  'admin.common.restrictedURL': 'URL con inicio de sesión',
  'admin.common.slugURL': 'Slug (nombre después de la URL)',
  'admin.common.select': 'Selecciona',
  'admin.common.typeSomethingToSearch': 'Escriba algo para buscar',
  'admin.common.dateInsert': 'Insertar una fecha',
  'admin.common.createdAt': 'Creado en',
  'admin.common.fileImport': 'Importar archivo',
  'admin.common.downloadModel': 'Descargar plantilla',
  'admin.common.import': 'Importar',
  'admin.common.export': 'Exportar',
  'admin.common.reportQueue': 'Report queue',
  'admin.common.title': 'Nombre',
  'admin.common.date': 'Fecha',
  'admin.common.hour': 'Hora',
  'admin.common.record_type': 'Record_type',
  'admin.common.e-mail': 'Correo electrónico',
  'admin.common.telephone': 'Teléfono',
  'admin.common.surname': 'Apellido',
  'admin.common.social_name': 'Nombre social',
  'admin.common.road': 'La carretera',
  'admin.common.number': 'Número',
  'admin.common.complement': 'Complemento',
  'admin.common.birth_date': 'Fecha de nacimiento',
  'admin.common.biological_genus': 'Género Biológico',
  'admin.common.social_gender': 'Género Social',
  'admin.common.ethnicity': 'Etnia',
  'admin.common.contact_via_phone': 'Contacto por Teléfono',
  'admin.common.health_insurance': 'Plan de salud',
  'admin.common.search': 'Buscar',
  'admin.common.searchForCompany': 'Buscar Empresa',
  'admin.common.men': 'Hombres',
  'admin.common.women': 'Mujeres',
  'admin.common.script': 'Guion',
  'admin.common.version': 'Versión',
  'admin.common.risk': 'Riesgo',
  'admin.common.risks': 'Riesgos',
  'admin.common.question': 'Pregunta',
  'admin.common.answer': 'Responder',
  'admin.common.calculation': 'Cálculo',
  'admin.common.interval': 'Intervalo',
  'admin.common.start': 'Início',
  'admin.common.end': 'Final',
  'admin.common.selectPatient': 'Seleccionar pacientes manualmente',
  'admin.common.high': 'Alto',
  'admin.common.medium': 'Medio',
  'admin.common.low': 'Bajo',
  'admin.common.description': 'Descripción',
  'admin.common.patientID': 'ID del paciente',
  'admin.common.userID': 'ID de usuario',
  'admin.common.anamnese': 'Historial de casos',
  'admin.common.attention_level': 'Nivel de atención',
  'admin.common.exams_up_to_date': 'Examenes al dia',
  'admin.common.symptoms': 'Síntomas',
  'admin.common.justifications': 'Justificaciones',
  'admin.common.assessment_date': 'Fecha de evaluación',
  'admin.common.id': 'ID',
  'admin.common.accept_Contact': '¿Aceptas Contacto?',
  'admin.common.age': 'Años',
  'admin.common.genre': 'Género',
  'admin.common.unit': 'Unidad',
  'admin.common.registration_date': 'Fecha de Registro',
  'admin.common.response_date': 'Fecha de respuesta',
  'admin.common.weigh': 'Cuánto pesas ? (en kg)',
  'admin.common.use_of_medications': 'uso de medicamentos',
  'admin.common.what_remedies': '¿Qué remedios?',
  'admin.common.specify_the_remedys': 'Especificar el remedio',
  'admin.common.smoke_cigars': '¿Fuma o ha fumado puros alguna vez?',
  'admin.common.how_many_cigars': 'En el tiempo que fumó, ¿cuántos puros al día?',
  'admin.common.currently_smoke_cigars': '¿Actualmente fuma puros?',
  'admin.common.smoke_how_old': 'Si dejó de fumar cigarros, ¿hace cuántos años?',
  'admin.common.ever_smoked_cigarette': '¿Fuma o ha fumado cigarrillos alguna vez?',
  'admin.common.years_smoked_cigarettes': '¿Cuántos años ha fumado cigarrillos?',
  'admin.common.cigarettes_daily': 'En el tiempo que fumó, ¿cuántos cigarrillos al día?',
  'admin.common.currently_smoke_cigarettes': '¿Actualmente fuma cigarrillos?',
  'admin.common.stopped_smoking_cigarettes': 'Si dejó de fumar cigarrillos, ¿hace cuántos años?',
  'admin.common.practice_physical_activity': '¿Practicas alguna actividad física?',
  'admin.common.minutes_week': '¿Cuántos minutos a la semana en promedio?',
  'admin.common.performed_genetic_exam': '¿Ya realizó BRCA1 o BRCA2?',
  'admin.common.mutation_search': 'Su respuesta a la prueba de mutación BRCA fue positiva. ¿Es correcto?',
  'admin.common.already_had_cancer': '¿Alguna vez has tenido cáncer?',
  'admin.common.type_of_cancer': '¿Qué tipo de cáncer?',
  'admin.common.specify_type_of_cancer': 'Especificar el tipo de cáncer',
  'admin.common.family_cancer_report': 'Seleccione alternativas sobre su historial familiar de cáncer',
  'admin.common.alcohol_consumption': '¿Cuál es su consumo diario de alcohol?',
  'admin.common.had_heart_problem': '¿Alguna vez ha tenido un problema cardíaco?',
  'admin.common.like_heart_trouble': '¿Qué tipo de problema cardíaco?',
  'admin.common.specify_heart_problem': 'Especificar el problema del corazón',
  'admin.common.history_emotional_problems': '¿Tiene antecedentes de problemas emocionales?',
  'admin.common.like_mental_problem': '¿Qué tipo de problema emocional?',
  'admin.common.specify_emotional_problem': 'Especificar el problema emocional',
  'admin.common.chest x-ray': '¿Alguna vez ha recibido radioterapia torácica para tratar un linfoma?',
  'admin.common.radiotherapy_affirmation': '¿Dijiste que te sometiste a radioterapia? ¿Es esto correcto?',
  'admin.common.first_menstruation_age': '¿Qué edad tenías cuando tuviste tu primer período?',
  'admin.common.age_first_child': '¿A qué edad tuvo el primer hijo (biológico) vivo?',
  'admin.common.degree_relatives_breast_cancer': '¿Cuántos familiares de primer grado han tenido cáncer de mama?',
  'admin.common.confirms_relative_breast_cancer': 'Usted respondió que un familiar de primer grado ya ha tenido cáncer de mama. ¿Correcto?',
  'admin.common.did_breast_biopsy': '¿Te han hecho una biopsia de mama?',
  'admin.common.how_many_biopsies_done': '¿Cuántas biopsias se hicieron?',
  'admin.common.atypical_alteration_biopsies': '¿Cambió alguna biopsia con atipia?',
  'admin.common.last_mammogram': '¿Cuándo te hiciste tu última mamografía?',
  'admin.common.observe_palpable_lesions': '¿Notas alguna lesión palpable?',
  'admin.common.spontaneous_papilla_secretion': '¿Tiene una secreción espontánea en las papilas?',
  'admin.common.change_shape_breast': '¿Notó algún cambio de forma en el seno?',
  'admin.common.had_sexual_intercourse': '¿Alguna vez has tenido relaciones sexuales?',
  'admin.common.uterus_removal_surgery': '¿Alguna vez ha tenido una cirugía para extirpar completamente el útero (histerectomía)?',
  'admin.common.cervical_preventive_exam': '¿Se ha realizado algún examen preventivo del cuello uterino (Papanicolau)?',
  'admin.common.changes_pap_smear': '¿Ha cambiado alguna vez NIC I, NIC II, NIC III HSIL o LSIL?',
  'admin.common.doses_vacina_hpv': '¿Ha recibido 3 dosis de la vacuna contra el VPH?',
  'admin.common.regular_use_condoms': '¿Durante su vida, hizo uso regular y constante de condones o diafragmas?',
  'admin.common.pain_during_intercourse': '¿Experimenta dolor intenso durante las relaciones sexuales?',
  'admin.common.bleeding_during_intercourse': '¿Alguna vez ha notado sangrado durante o después del coito?',
  'admin.common.menstrual_period_bleedinge': '¿Tiene algún sangrado fuera del período menstrual o después de la menopausia?',
  'admin.common.lived_with_smokers': '¿Ha vivido con fumadores durante más de la mitad de su vida?',
  'admin.common.worked_asbestos_protection': '¿Ha trabajado alguna vez con amianto sin la protección adecuada?',
  'admin.common.work_without_protection': 'Actividades relacionadas con las que ha trabajado sin la debida protección',
  'admin.common.has_difficulty_breathing': '¿Tiene falta de aliento o dificultad para respirar?',
  'admin.common.presents_continuous_cough': '¿Tiene tos continua o progresiva?',
  'admin.common.relative_bowel_cancer': '¿Algún familiar de primer grado ha tenido cáncer de intestino?',
  'admin.common.had_bowel_disease': '¿Alguna vez ha tenido alguna enfermedad intestinal que haya tenido que extirpar un pólipo (por colonoscopia)?',
  'admin.common.performed_exams': '¿Alguna vez se ha realizado las siguientes pruebas: prueba de sangre oculta en heces, sigmoidoscopia o colonoscopia?',
  'admin.common.suffer_from_constipation': '¿Sufre de estreñimiento (intestino obstruido) o diarrea frecuente?',
  'admin.common.presence_blood_feces': '¿Alguna vez has notado la presencia de sangre en las heces?',
  'admin.common.relative_prostate_cancer': '¿Algún familiar de primer grado ha tenido cáncer de próstata?',
  'admin.common.had_bowel_cancer': 'Usted respondió que un familiar de primer grado ha tenido cáncer de intestino. ¿Derecha?',
  'admin.common.renal_touch_exam': '¿Alguna vez te has hecho un examen rectal digital o PSA?',
  'admin.common.difficulty_urinating': '¿Experimenta dificultad para orinar o nota que el chorro de orina es más débil?',
  'admin.common.blood_output_urine': '¿Notaste sangre en la orina?',
  'admin.common.empty_bladder_complete': 'Al orinar, ¿tiene la sensación de que no puede vaciar completamente la vejiga?',
  'admin.common.copy': 'Copiar',
  'admin.common.status': 'Status',
  'admin.common.createIn': 'Crear en',
  'admin.common.update': 'Última actualización',
  'admin.common.download': 'Descargar',
  'admin.common.riskSelect': 'Seleccione un riesgo',
  'admin.common.observations': 'Observaciones',
  'admin.common.all': 'Todos',
  'admin.common.icon': 'Icon',
  'admin.common.categoryColor': 'Color de categoría',
  'admin.common.tuss': 'TUSS',
  'admin.common.today': 'Hoy',
  'admin.common.visible': 'Visible',
  'admin.common.subtitle': 'Subtítulo',
  'admin.common.specialtyColor': 'Color de especialidad',
  'admin.common.image': 'Image',
  'admin.common.requiredFields': 'Campos obligatorios',
  'admin.common.optionalFields': 'Campos opcionales',
  'admin.common.minimumAge': 'Edad mínima',
  'admin.common.maximumAge': 'Edad máxima',
  'admin.common.active': 'Activo',
  'admin.common.inactive': 'Inactivo',
  'admin.common.draft': 'Versión preliminar',
  'admin.common.language': 'Idioma',
  'admin.common.link': 'Enlace',
  'admin.common.subject': 'Tema',
  'admin.common.content': 'Contenido',
  'admin.common.staff': 'Equipo',
  'admin.common.customer': 'Cliente',
  'admin.common.customers': 'Clientes',
  'admin.common.value': 'Valor',
  'admin.common.contactInfo': 'Información de datos de contacto',
  'admin.common.contactInfos': 'Información de datos de contacto',
  'admin.common.site': 'Site',
  'admin.common.blog': 'Blog',
  'admin.common.post': 'Correo',
  'admin.common.posts': 'Publicaciones',
  'admin.common.filter': 'Filtrar',
  'admin.common.continueReading': 'Lee mas',
  'admin.common.permalink': 'Enlace Permanente',
  'admin.common.category': 'Categoría',
  'admin.common.categories': 'Categorías',
  'admin.common.blogCategory': 'Categoría de blog',
  'admin.common.blogCategories': 'Categorías de blogs',
  'admin.common.tag': 'Etiqueta',
  'admin.common.tags': 'Etiquetas',
  'admin.common.myAccount': 'Mi cuenta',
  'admin.common.signOut': 'Salir',
  'admin.common.personalData': 'Datos Personales',
  'admin.filter': 'Filtro de Pacientes',
  'admin.filterTable': 'Filtros',
  'admin.filterReportsTable': 'Filtros',
  'admin.filterReports': 'Filtros',
  'admin.common.editPassword': 'Cambiar Contraseña',
  'admin.common.report': 'Informe',
  'admin.common.reports': 'Informes',
  'admin.common.report.medicalHistory': 'Informe de Historial Médico',
  'admin.common.report.risk': 'Informe de Riesgo',
  'admin.common.report.personalData': 'Informe de Datos Personales',
  'admin.common.report.mentalHistorical': 'Informe de Historial Mental',
  'admin.common.report.mentalRisk': 'Informe de Riesgo Mental',
  'admin.common.reportsCompany': 'Informes Empresa',
  'admin.report.personal_data': 'Informe (Datos Personales)',
  'admin.report.risk': 'Informe (Riesgo)',
  'admin.report.medical.history': 'Informe (Historial Médico)',
  'admin.report.mental': 'Informe (Mental)',
  'admin.history.mental' : 'Informe (Historial Mental)',
  'admin.common.patientsFilter': 'Filtro de paciente',
  'admin.common.unavailable': 'Indisponible',
  'admin.common.queue': 'Cola',
  'admin.common.personalInformations': 'Informaciones personales',
  'admin.common.continue': 'Continue',
  'admin.common.dontKnow': 'No sé',
  'admin.common.didNotDoIt': 'Yo no lo hice',
  'admin.common.ethnicity.white': 'Blanco',
  'admin.common.ethnicity.black': 'Descendiente africano',
  'admin.common.ethnicity.brown': 'Raza mixta',
  'admin.common.ethnicity.yellow': 'Oriental',
  'admin.common.ethnicity.indigenous': 'Indio nativo americano',
  'admin.common.others': 'Otros',
  'admin.common.clickHere': 'Haga clic aquí',
  'admin.common.result': 'Resultado',
  'admin.common.recommendations': 'Recomendaciones',
  'admin.common.recommendedExams': 'Exámenes Recomendados',
  'admin.common.max': 'Máximo',
  'admin.common.min': 'Mínimo',
  'admin.common.format': 'Formato',
  'admin.common.integer': 'Número entero',
  'admin.common.none': 'Ningún',
  'admin.common.displayBeforeAnamnesis': 'Pantalla antes de la anamnesis',
  'admin.common.group': 'Grupo',
  'admin.common.author': 'Autor',
  'admin.common.selectRiskForEmails': 'Seleccione el nivel de riesgo deseado para activar la alerta',
  'admin.common.requiredHistoryMedicalCategories': 'Las categorías que ha seleccionado requieren todas las categorías de registros médicos',
  'admin.common.emailSent': 'Email enviado',
  'admin.common.menstrual_period_bleeding': '¿Tiene algún sangrado fuera de su período menstrual o después de la menopausia?',
  'admin.common.permission.page': 'Usted no tiene permiso para acceder a esta página.',
  'admin.comon.select' : 'Seleccione...',
  'admin.common.nationality': 'Nacionalidad',
  'admin.common.document': 'Documento de identificación',
  'admin.enter.company.name' : 'Introduzca el nombre de su empresa',
  'admin.filter.company.title' : 'Filtrar empresa',
  'admin.login.with' : 'Iniciar con',
  'admin.or.register' : 'ou',
  'admin.sign.in.microsoft' : 'Iniciar sesión con Microsoft',
  'admin.sign.in.google' : 'Iniciar sesión con Google',
  'admin.write.with.microsoft': 'Regístrese con Microsoft',
  'admin.write.with.google': 'Regístrese con Google',
  'admin.common.whatsApp.receptive': 'WhatsApp - Receptivo',
  'admin.common.whatsApp.active': 'WhatsApp - Activo',

  /* Companys */
  'admin.company.title': 'Empresa',
  'admin.company.title.plural': 'Empresas',

  /* Plano de Saúde */
  'admin.healthInsurance.title': 'Plan de Salud',
  'admin.healthInsurance.title.plural': 'Plans de Salud',

  /* Indicação de Empresa*/
   'admin.recommendation.title': 'Indicación',

  /* Perfil de Acesso */
  'admin.roles.title': 'Perfil de Acceso',
  'admin.roles.title.plural': 'Perfiles de acceso',
  'admin.formRole.permissions': 'Permisos',

  /* Idiomas */
  'admin.languages.title': 'Idioma',
  'admin.languages.title.plural': 'Idiomas',
  'admin.languages.slug': 'Slug',

  /* Patients */
  'admin.patients.title': 'Paciente',
  'admin.patients.title.plural': 'Pacientes',
  'admin.patients.listOfPatients': 'Lista de pacientes',
  'admin.patients.importPatients': 'Importar pacientes',
  'admin.patients.exportPatients': 'Exportar pacientes',
  'admin.patients.exportText': 'Seleccione los filtros de su preferencia y haga clic en \'Exportar\' para solicitar informes de pacientes. El tiempo de preparación del documento depende de la cola en el sistema y de los criterios de exportación aplicados.',
  'admin.patients.fields.gender': 'M o F',
  'admin.patients.fields.cpf': 'Solo números (05376483649) o formateados (768.884.253-04)',
  'admin.patients.fields.phone': 'Solo Números (41993241556) o formateados ((41) 99324-1556)',
  'admin.patients.fields.birthday': 'YYYY-MM-DD (Example 1990-06-20)',
  'admin.patients.fields.cep': 'Solo números (93530280) o formateados (93530-280)',

  /* Tipos de Câncer */
  'admin.cancerTypes.title': 'Tipo de cáncer',
  'admin.cancerTypes.title.plural': 'Tipos de cáncer',

  /* Estratégias */
  'admin.strategies.title': 'Estrategia',
  'admin.strategies.title.plural': 'Estrategias',

  /* Exames Recomendados */
  'admin.exams.title': 'Examen',
  'admin.exams.title.plural': 'Exámenes Recomendados',

  /* Site */
  'admin.site.title': 'Site',
  'admin.testimonies.title': 'Examen',
  'admin.testimonies.title.plural': 'Testimonios',
  'admin.testimonies.author': 'Autor del testimonio',
  'admin.specialities.title': 'Especialidad',
  'admin.specialities.title.plural': 'Especialidades',

  /* Anamnesis, */
  'admin.anamnesis.title': 'Historial de casos',
  'admin.anamnesis.title.plural': 'Historial de casos',

  /* Categories */
  'admin.categories.title': 'Category',
  'admin.categories.title.plural': 'Categories',

   /* Link Youtube*/
  'admin.common.linkVideo' : 'Link de YouTube',

  /* Link Agendamento Exame*/
  'admin.common.link.agendamento.exame': 'Enlace de programación de exámenes',

  /* ValidActions */
  'validations.required': 'Campo Obligatorio',
  'validations.integer': 'Campo debe ser un número entero',
  'validations.integerBetween': 'Introduzca un número entre {min} y {max}',
  'validations.email': 'Correo electrónico no válido',
  'validations.link': 'Enlace inválido',
  'validations.phone': 'Teléfono no válido',
  'validations.cpf': 'Número de ID no válido',
  'validations.cnpj': 'Registro de Contribuyente Fiscal (Empresa)',
  'validations.cep': 'Código Postal no válido',
  'validations.date': 'Fecha no válida',
  'validations.password': 'Debe contener al menos 8 caracteres con números, letras mayúsculas y minúsculas',
  'validations.passwordConfirm': 'Las contraseñas deben ser iguales',
  'validations.fileType': 'Tipo de archivo no soportado',
  'validations.enter.full.name': 'Escriba su nombre y apellido',
  'validations.enter.not.special.character': 'El nombre no puede contener caracteres especiales',
  'validations.required.age': 'Para continuar con el registro debes ser mayor de 18 años.',

  /* Feedback */
  'feedback.success': 'Acción realizada con éxito!',
  'feedback.error': 'Oops! ¡Ha habido algún problema!',

  /* Field Hints */
  'hints.cpf': 'Introduzca su ID con o sin puntuación',
  'hints.cpfNumberOnly': 'Ingrese su CPF (Número de Identificación del Contribuyente Brasileño) sin puntuación (solo números)',
  'hints.number': 'Solo números',
  'hints.email': 'Introduzca su correo electrónico (Ex: example@example.com)',
  'hints.height': 'Informa tu altura en centímetros (cm)',
  'hints.weight': 'Informa tu peso em kilogramos (kg)',
  'hints.dateOfBirth': 'La fecha debe estar en el formato DD/MM/AAAA (ejemplo: 25/10/1950)',

  /* Anamnesis, */
  'q.startPrevention': 'Iniciar Relleno',
  'q.backToAnamnese': 'Volver al historial de casos',
  'q.initialText' : 'Texto inicial del cliente',
  'q.wizardStep': 'Paso {progress} de {count}',
  'q.acceptContact': 'Acepto contacto por teléfono o correo electrónico',
  'q.termsAccept': 'Acepto los',
  'q.accept.receive': 'Acepto recibir',
  'q.additional.information.whatsapp' : 'Información adicional de Previneo vía WhatsApp',
  'q.termsOfService': 'Términos de Consentimiento',
  'q.termsAndConditions': 'Términos y Condiciones',
  'q.termsAndConditionsUnimed': 'TÉRMINOS DE CONSENTIMIENTO Y DE AUTORIZACIÓN',
  'q.acceptRequired': 'Es necesario aceptar los términos',
  'q.brca.question': '¿Alguna vez se ha realizado un examen genético (conocido como BRCA1 o BRCA) para saber si existe un cambio/mutación que pueda aumentar su riesgo de desarrollar cáncer y, de ser así, si el resultado fue positivo?',
  'q.brca.description': 'La mutación en los genes BRCA1 o BRCA2 aumenta significativamente el riesgo de algunos tipos de cáncer (mama, ovario, próstata, colon o páncreas). Este examen genético se puede realizar a través de muestras de sangre o saliva para saber si la persona tiene esta mutación. Esta mutación generalmente ocurre en personas con antecedentes de cáncer en familiares cercanos. Se estima que aproximadamente del 5% al ​​10% de todos los casos de cáncer de mama y de ovario son causados ​​por esta mutación',
  'q.ethnicity.question': 'Carrera',
  'q.ethnicity.description': 'Se sabe que las personas de etnia negra tienen un mayor riesgo de desarrollar cáncer de próstata',
  'q.cancerHistory.question': '¿Tiene antecedentes personales de cáncer?',
  'q.cancerHistory.description': 'Se sabe que las personas con antecedentes de cáncer tienen un alto riesgo de recaída o eventualmente un nuevo tumor del mismo tipo. Por eso, a los efectos de calcular su riesgo, es importante establecer si ha tenido esta enfermedad antes en su vida.',
  'q.cancerHistory.whatCancer': '¿Qué tipo de cáncer?',
  'q.cancerHistory.specifyCancer': 'Especificar el tipo de cáncer',
  'q.helloPatient': 'Hola, {name}',
  'q.patientIntroText': 'Es hora de que le conozcamos a usted y a su historial médico. Rellene los campos de manera precisa, para que su evaluación de riesgo se haga de la mejor manera. Vamos?',
  'q.startAvaliation': 'Iniciar Evaluación',
  'q.profileUnmatch': 'Las anamnesis de esta campaña no encajan con su perfil. ¡Gracias por ayudarnos hasta aquí!',
  'q.finishAvaliation': 'Finalizar la evaluación',
  'q.toUpdateYourData': 'para actualizar sus datos personales',
  'q.stepFinished': '¡Un paso más concluido!',
  'q.youAreDoingRight': 'Estás rellenando correctamente los datos de la anamnesis. ¡Seguid así!',
  'q.continueAvaliation': 'Continuar evaluación',
  'q.toAnswerTheQuestions': 'para responder a las preguntas',
  'q.thanks': '¡Gracias por realizar la evaluación!',
  'q.sentForEmail': 'El resultado ha sido enviado a su correo electrónico.',
  'q.welcomeUser': 'Bienvenido, {name}',

  'admin.dashboard.chart.foreseen': 'Previsto',
  'admin.dashboard.chart.cumplido': 'Listo',
  'termsUnimed': `<p>Consiento y autorizo ​​expresamente la recopilación de mis datos personales a través de un cuestionario de salud que estará disponible a través de un enlace por parte de la empresa
  <b>PROGRAMAS PREVINEO DE SAÚDE S.A.</b>
  , que corre bajo el nombre de
  <b>PREVENCIÓN</b>
  , persona jurídica de derecho privado, inscrita en el CNPJ/MF bajo el nº de recogida, tratamiento y almacenamiento de dichos datos, así como aceptar, tras recibir el resultado de mi clasificación de riesgo, el contacto, en horario laboral, de un profesional de la salud de la empresa
  <b>PREVENCIÓN</b>
  el cual brindará orientación en la prevención de las enfermedades objeto de estudio. Además, consiento y autorizo ​​expresamente el intercambio de mis datos personales recopilados con
  <b>UNIMED CURITIBA – SOCIEDADE COOPERATIVA DE MÉDICOS</b>
  ,Cooperativa de 1° grado debidamente constituida, inscrita en la OCEPAR - Organización de Cooperativas del Estado de Paraná con el n.° 30470-1, inscrita en la Junta de Comercio de Paraná con el n.° 4140000865-7 e inscrita en el CNPJ/MF con el n.° 75.055 .772/0001-20, con sede en Avenida Affonso Penna, nº 297, Bairro Tarumã, Curitiba/PR, CEP 82530 -280, teléfono (41) 3021-9100, para que promueva estudios relacionados con la estratificación de riesgos de cáncer. </p>
   <p>Así, en pleno cumplimiento de la Ley General de Protección de Datos (Ley N° 13.709/2018), me expreso libre, informado e inequívocamente a fin de autorizar la
   <b>UNIMED CURITIBA</b> e a 
   <b>PREVINEO</b> 
   a:</p> 
  <p>a) tratar todos mis datos personales y datos personales sensibles de forma confidencial y confidencial, protegiéndolos y restringiendo su uso a los fines establecidos en este Término, previniendo e impidiendo que dichos datos sean utilizados para cualquier otro fin o revelados a terceros no involucrados en el objeto del estudio;</p>
   <p>b) compartir información sobre mi estado de salud, durante el desarrollo del estudio, a terceros contratados involucrados en el proceso;</p>
   <p>c) poner a disposición los datos clínicos de mi seguimiento, con la garantía de la preservación de mi identificación, para fines estadísticos y de investigación en salud con el objetivo de contribuir al desarrollo de mejores prácticas en salud, aunque mi condición de beneficiario de <b>UNIMED CURITIBA</b> dejará de existir en el futuro.</p>`,
  'terms': '' +
    '<p>' +
    '  <strong>Política de Privacidad y Autorización de Uso</strong>' +
    '</p>' +
    '<p>' +
    '  Somos una empresa dedicada a la promoción de información médica, dirigida a la prevención de algunos tipos de cáncer en Brasil. Hacemos esto porque creemos que cuanta más información, mayores son las posibilidades de un diagnóstico temprano, que es fundamental para la prevención del cáncer.' +
    '</p>' +
    '<p>' +
    '   La forma en que divulgamos la información es una forma preformateada, basada en literatura médica y métodos reconocidos nacional e internacionalmente para verificar la presencia de uno o más factores de riesgo que aumentan el riesgo estimado de desarrollar una patología. Para ello, requerimos algunos datos personales suyos, incluidos sus datos de identificación, antecedentes familiares de patologías, así como datos relacionados con su vida diaria.' +
    '</p>' +
    '<p>' +
    '  Por ejemplo, necesitaremos saber si fuma o ha fumado alguna vez, si ha tenido relaciones sexuales y si ya se ha realizado algún tipo de prueba preventiva. Estos y otros datos son fundamentales para lograr una correcta estimación del riesgo de desarrollar los tipos de cáncer aplicables a las formas.' +
    '</p>' +
    '<p>' +
    '  <strong>¿Cómo se utilizarán sus datos?</strong>' +
    '</p>' +
    '<p>' +
    '  Todos los datos personales que proporcione se mantendrán en absoluto secreto y se utilizarán únicamente con el fin de obtener el resultado sobre su riesgo de desarrollar las patologías probadas. Esto se aplica tanto a los datos de identificación (como su nombre, dirección de correo electrónico, edad, etc.) como a las respuestas a los formularios, es decir, su historial médico o datos de la vida diaria. Utilizaremos su correo electrónico exclusivamente con el fin de enviar los resultados y no pasaremos esta información a terceros ni la utilizaremos para ningún otro fin.' +
    '</p>' +
    '<p>' +
    '   <strong>¿Quién tiene acceso a mis datos?</strong>' +
    '</p>' +
    '<p>' +
    '  Para la obtención de los resultados, sus respuestas serán analizadas automáticamente y revisadas manualmente por empleados de Previneo, vinculados al área de salud. La herramienta fue desarrollada por médicos, pero el análisis de los datos no lo hacen ellos, ya que no brindamos servicios de salud, sino solo divulgación de información. Nuestros empleados están comprometidos con la confidencialidad de los datos y nuestra política de privacidad.        ' +
    '</p>' +
    '<p>' +
    '  Si participa en esta evaluación como parte del programa de salud de su empleador, los médicos responsables (y solo los médicos) de su empleador pueden tener acceso a los resultados con total y absoluto respeto al Código de Ética Médica.' +
    '</p>' +
    '<p>' +
    '  <strong>¿Qué sucede si autorizo ​​el uso de los datos en forma de estadísticas?</strong>' +
    '</p>' +
    '<p>' +
    '  Al completar nuestro formulario, le solicitamos que además de aceptar nuestros términos de uso, también acepte que los resultados respectivos pueden constituir una base de datos para el establecimiento de políticas de prevención. Después de todo, además de proporcionar información adecuada, nuestra herramienta también puede servir para identificar fallas o correcciones necesarias en los métodos de diagnóstico de cáncer en Brasil.' +
    '</p>' +
    '<p>' +
    '   En este caso, si usted está de acuerdo, los resultados obtenidos al completar su formulario pueden ser utilizados para formar estadísticas sin identificación personal o datos sensibles. Mantendremos absoluto secreto en relación con estos datos.</p>' +
    '<p>' +
    '  Por ejemplo, si tiene 20 años, no fuma y vive en Curitiba-PR, su riesgo de desarrollar cáncer de pulmón se considerará normal para su edad y condición. En este caso, su resultado compondrá la estadística como persona de riesgo normal para el desarrollo de esta patología, sin ningún tipo de identificación, únicamente vinculado a la región donde reside.' +
    '</p>' +
    '<p>' +
    '  Cualquier duda o aclaración puede ser resuelta previamente a través de nuestro correo electrónico <strong>contato@previneo.com.br</strong>.        ' +
    '</p>',
  
  'admin.dashboard.chart.not.fulfilled': 'No hecho',
  'admin.patientsHistory.currentSituation': 'Situación actual',
  'admin.common.nextContact': 'Siguiente contacto',
  'admin.common.contactReason': 'Motivo de contacto',
  'admin.common.typesCancer': 'Tipos de cáncer',
  'admin.common.patientHistoryExams': 'Exámenes Realizados',
  'admin.common.saveContact': 'Guardar contacto',
  'admin.patientsHistory.status.pending': 'Iniciar',
  'admin.patientsHistory.status.inProgress': 'Pausar',
  'admin.patientsHistory.status.done': 'Finalizar',
  'admin.common.contactMade': 'Contacted?',
  'admin.common.contactMadeOk': 'Si',
  'admin.common.contactMadeNo': 'No',
  'admin.common.contactStatus': 'Status',
  'admin.common.contactStatuses': 'Status',
  'admin.common.managePatientHistory': 'Contacto',
  'admin.common.patientInfo': 'Datos del paciente',
  'admin.patients.deletedPatients': 'Pacientes retirados',
  'admin.common.motivo': 'Razón',
  'admin.common.hidePatientInfo': 'Ocultar información de contacto',
  'admin.common.displayPatientInfo': 'Mostrar información de contacto',
  'admin.common.noPermission': 'No tienes permiso para acceder a esta página',
  'admin.patientsHistory.titlePlural': 'Registros médicos',
  'admin.patientsHistory.contact': 'Contacto',
  'admin.patientsHistory.contactReasonPlural': 'Motivos de contacto',
  'admin.common.registerNew': 'Registro',
  'admin.common.logoRight': 'Logotipo derecha',
  'admin.common.logoLeft': 'Logotipo a la izquierda',
  'admin.common.justifications.title': 'Justificaciones',
  'admin.common.justifications.title.plural': 'Justificaciones',
  'admin.common.file': 'Expediente',
  'admin.common.fileFront': 'Archivo frontal',
  'admin.common.roles': 'Perfiles de acceso',
  'admin.common.ageRange': 'Grupo de edad',
  'admin.permissions.title.plural': 'Permisos',
  'admin.permissions.title': 'Permiso',
  'admin.justifications.title': 'Justificación',
  'admin.justifications.title.plural': 'Justificaciones',
  'admin.faixasimc.abaixo': 'por debajo del peso',
  'admin.faixasimc.normal': 'Peso normal',
  'admin.faixasimc.acima': 'Por encima del peso',
  'admin.faixasimc.obesidadeI': 'Obesidad I',
  'admin.faixasimc.obesidadeII': 'Obesidad II',
  'admin.faixasimc.obesidadeIII': 'Obesidad III',
  'admin.common.lastAnamnesis': 'Fecha de la anamnesis',
  'admin.common.reSendEmail': 'Reenviar correo',
  'admin.common.normal': 'Normal',
  'admin.common.entity': 'Entidad',
  'admin.common.entities': 'Entidades',
  'admin.common.numberOfEmployees': 'Cantidad de empleados',
  'admin.common.confirmation': 'Confirmación',
  'admin.common.confirmationNotFound': 'Registro no encontrado, haga clic en el botón de abajo para recibir un nuevo enlace.',
  'admin.common.editUser': 'Editar usuario',
  'admin.common.processingAnamnese': 'Estamos procesando los resultados',
  'admin.script.questionTypes.multipleChoice': 'Opción múltiple',
  'admin.script.questionTypes.choiceList': 'Lista de opciones',
  'admin.dataTable.label.report': "Informe",
  'admin.common.report.Mental_Risk': 'Riesgo Mental',
  'admin.common.report.Mental_Historical': 'Historial Mental',
  'admin.common.report.Personal_Data': 'Datos Personales',
  'admin.common.report.Medical_History' : 'Historial Médico',
  'admin.anamnesis.medicalHistory': 'Datos de Historial Médico',
  'admin.common.report.Medical_Risk' : 'Riesgo',
  'admin.common.gender.biologic': 'Sexo biológico',
  'admin.anamnesis.medicalHistory.button.update': 'Actualizar datos', 
  'admin.anamnesis.medicalHistory.button.correct': 'Está correcto',
  'admin.common.share': 'Compartir',
  'admin.common.Share_Diagnosis': 'Compartir resultado',
  'admin.common.put address' : 'Escribe una dirección de correo electrónico a continuación:',
  'admin.common.initialDate': 'Fecha de Inicio',
  'admin.common.password.new': 'Nueva contraseña',
  'admin.common.gender.social': 'Género social',
  'admin.common.qtd_colaboradores': 'Cantidad de Colaboradores',
  'admin.anamnesis.medicalHistory.description': 'A continuación, los dados que ha rellenado sobre el historial médico',

  'users.admin.companies.title': 'Empresa',
  'users.forms.passwordConfirm.title': 'Registrar contraseña',
  'users.delete.data.cantrollback.title': '¿Está seguro?',
  'users.admin.companies.title.plural': 'Companies',
  'users.delete.data.button': 'Salir del sistema',
  'users.anamneses.notfound': 'Anamnesis no encontrado o no disponible',
  'users.delete.data.title': 'Salir del sistema',
  'users.dashboard.title.one': 'Prevenir es Vivir Más y Mejor.',
  'users.dashboard.welcome.female': 'Bienvenido, {name}!',
  'users.dashboard.welcome.male': 'Bienvenida, {name}!',
  'users.dashboard.card.type': 'Entrevista Digital',
  'users.login.welcome': 'Bienvenido! ¡Acaba de dar el primer paso para vivir más y mejor!',
  'users.dashboard.card.typeWithDiagnosticName': 'Entrevista digital: {diagnosticName}',
  'users.results.refillWarning': 'Hemos comprobado que su historial médico ha sido actualizado.',
  'users.results.refillWarningRec': 'Le recomendamos que vuelva a realizar la entrevista digital.',
  'users.results.smallHeaderMessage': '{name}, este es su resumen:',
  'users.results.hello.mental': 'Hello {name},',
  'users.results.smallHeaderMessage.mental': 'Primero, ¡enhorabuena! Si está leyendo esto, significa que ha completado todas las preguntas de nuestro examen de salud mental. Estás tratando activamente de aprender más sobre ti mismo.',
  'users.results.card.assessedRisk': 'Riesgo evaluado',
  'users.results.card.levelOfAttention': 'Nivel de atención',
  'users.results.card.examsPendents': 'Exámenes o consultas pendientes',
  'users.results.openDetails': 'Abrir detalles de la entrevista digital',
  'users.results.expertRecommendations': "No olvide de revisar las recomendaciones de nuestros expertos",
  'users.results.expertRecommendations.mental': 'Las condiciones de salud del comportamiento siempre se pueden mejorar, y la mayoría de las personas piensan que pueden hacer cambios positivos en sus vidas con ayuda profesional y el apoyo de familiares y amigos.',
  'users.results.othersDiagnosticsAvailable': 'Otras Entrevistas Digitales Disponibles',
  'users.results.fillAgain': 'Rellenar una vez más',
  'users.login.header.message': 'Inicie sesión con su contraseña',
  'users.results.card.seeResult': 'Revisar Resultados',
  'users.results.card.fillText': 'Iniciar Relleno',
  'users.with.deleted': 'Con quitado',
  'users.not.deleted': 'No removed',
  'users.login.button.enter': 'Entrar',
  'users.results.diagnostic.title': 'Resultado del Diagnóstico',
  'users.results.diagnostic.title.plural': 'Resultados de los Diagnósticos',
  'users.results.everHadSymptoms': 'Tiene síntomas',
  'users.results.diagnosticType': 'Tipo de diagnóstico',
  'users.delete.data.description': 'Esto hará que todos sus datos personales (incluyendo nombre, correo electrónico, teléfono y similares) sean eliminados permanentemente de nuestra base de datos',
  'users.delete.data.cantrollback.description': 'Esta acción no se puede deshacer.',
  'users.dashboard.roche.longtext': 'Si usted es colaborador de Roche y tiene alguna pregunta después de completar el relleno, usted podrá buscar la Medicina Ocupacional a través del correo: {email}. Si usted es dependiente podrá consultar al médico de su preferencia.',

  /*New Screen Dashbord */
  'admin.common.registered' : 'Registrado',
  'admin.common.total' : ' Total',
  'admin.common.historic' : 'Historial médico',
  'admin.common.accomplished' : 'Logrado',
  'admin.common.no-accomplished' : 'No realizado',
  'admin.age': 'Rango de edad',
  'admin.female.age.group' : 'Grupo de edad femenino',
  'admin.male.age.group' : 'Grupo de edad masculino',
  'admin.common.masc' : 'Masculino',
  'admin.common.fem' : 'Feminino',
  'admin.common.summary' : 'Resumen',
  'admin.common.risk.assessment' : 'Evaluación de riesgos',
  'admin.common.number.of.people' : 'Número de Personas identificadas como de Alto Riesgo.',
  'admin.common.low.risk' : '(Riesgo bajo)',
  'admin.common.smoker' : 'Síntomas',
  'admin.common.exams.are.missing' : 'Faltan examenes',
  'admin.common.high.risk' : '(Alto riesgo)',
  'admin.common.exams.missing' : 'Exámenes Pendientes',
  'admin.common.one.year' : '+1 año',
  'admin.common.late' : 'Tarde',
  'admin.common.in.day' : 'En día',
  'admin.common.never' : 'Nunca',
  'admin.common.qtd' : 'Número de personas identificadas en {risk}.',
  'admin.common.mama' : 'Mama',
  'admin.common.uterine' : 'Uterino',
  'admin.common.lung' : 'Pulmón',
  'admin.common.colon' : 'Colon',
  'admin.common.prostate' : 'Próstata',
  'admin.high.qtd' : '- Número de {risk} identificados por especialidad.',
  'admin.common.consolidated' : 'Consolidated',
  'admin.common.qtd.age' : 'Número de Personas identificadas por Edad.',
  'admin.common.qtd.exams.missing.1': 'Número de personas identificadas con los Exámenes',
  'admin.common.qtd.exams.missing.2': 'Pendiente (seguimiento retrasado).',
  'admin.common.amount' : 'Cantidad',
  'admin.common.cardio' : 'Cardio',
  'admin.common.mental' : 'Mental',
  'admin.interviews' : 'Entrevistas',
  'admin.common.monday' : 'Lunes',
  'admin.common.tuesday' : 'Martes',
  'admin.common.wednesday' : 'Miércoles',
  'admin.common.thursday' : 'Jueves',
  'admin.common.friday' : 'Viernes',
  'admin.filling.interviews' : 'Llenar las entrevistas',
  'admin.common.qtd.filling.interviews' : 'Cantidad de entrevistas realizadas en el período.',
  'admin.common.qtd.users' : 'Numero de usuarios',
  'admin.common.funnel': 'Usar embudo',
  'admin.common.data.found' : '¡Datos no encontrados!',
  'admin.common.only.air': 'Solo aire',
  'admin.common.exams.only' : 'Solo examenes',
  'admin.common.only.symptom' : 'Único síntoma',
  'admin.common.exam.air' : 'Aire + Examen',
  'admin.common.air.symptom' : 'Aire + Síntoma',
  'admin.common.exam.symptom' : 'Aire + Examen + Síntoma',
  'admin.common.air.exam.symptom' : 'Aire + Examen + Síntoma',
  'admin.common.deleted.users': 'Usuarios eliminados',
  'admin.high.qtd.segment': '- Cantidad de {risk} identificado por seguimientos.',
  'admin.common.information' : 'Informaciones adicionales',

   /*Dashboard Api*/
   'registered' : 'Registrado',
   'medical history' : 'Historial médico',
   'interviews' : 'Entrevistas',
   'male' : 'Masculino',
   'female' : 'Feminino',
   'all interviews' : 'Todas las entrevistas',
   'mental' : 'Mental',
   'cardio' : 'Cardio',
   'mama' : 'Mama',
   'prostate' : 'Próstata',
   'lung' : 'Pulmón',
   'uterine' : 'Uterino',
   'colon' : 'Colon',
   'only air' : 'Solo aire',
   'exams only' : 'Solo exámenes',
   'only symptom' : 'Único síntoma',
   'exam air' : 'Aire + Examen',
   'air symptom' : 'Aire + Síntoma',
   'exam symptom' : 'Examen + Síntoma',
   'air exam symptom' : 'Aire + Examen + Síntoma',
   'normal risk' : 'Riesgo Normal',
   'admin.prostate': 'Cancer de prostata',
   'admin.uterine': 'Cáncer uterino',
   'admin.medical.male': 'Historial médico (hombre)',
   'admin.medical.female': 'Historial médico (mujer)',
   'admin.breast.cancer': 'Cáncer de mama',
   'admin.lung.cancer': 'Cáncer de pulmón',
   'admin.colon.cancer': 'Cáncer de colon',
   'admin.mental': 'Mental',
   'admin.cardio': 'Cardio',
   'admin.all.risks': 'Todos los riesgos',
   'admin.high.risk' : 'Alto riesgo',
   'admin.medium.risk': 'Riesgo medio',
   'admin.low.risk' : 'Riesgo bajo',
   'admin.common.contact_us' : 'Contacta con nosotros',
   'admin.common.how_about' : '¿Qué tal programar una cita para hablar sobre sus resultados o actualizar sus exámenes?',
  
  /* Interviews BD*/
  'Você já fez exame de toque retal ou PSA?': '¿Alguna vez ha tenido un tacto rectal o un examen de PSA?',
  'Câncer de Útero': 'Cáncer de Útero',
  'Histórico Médico' : 'Historial médico',
  'Utilizaremos esse dado para o cálculo do IMC, que interfere na avaliação de risco de alguns tipos de câncer.' : 'Utilizaremos estos datos para calcular el IMC, que interfiere en la evaluación del riesgo de algunos tipos de cáncer.',
  'Qual é a sua altura ? (em CM)' : '¿Qué altura tiene?',
  'Qual é o seu peso ? (em KG)' : '¿Cuál es su peso?',
  'Você faz uso de algum medicamento regular?' : '¿Utiliza algún medicamento regular?',
  'Sim' : 'Sí',
  'Não' : 'No',
  'Você fuma ou já fumou charutos?' : '¿Fuma o ha fumado cigarrillo?',
  'O tabagismo (cigarro ou charuto) está diretamente ligado ao risco de desenvolvimento de vários tipos de câncer. Utilizaremos esses dados para avaliar qual estratégia é recomendada para a redução do seu risco de desenvolver câncer de pulmão.' : 'Fumar (cigarrillos o puros) está directamente relacionado con el riesgo de desarrollar varios tipos de cáncer. Utilizaremos estos datos para evaluar qué estrategia se recomienda para reducir su riesgo de desarrollar cáncer de pulmón.',
  'Você fuma ou já fumou cigarros?' : '¿Fuma o ha fumado cigarrillos alguna vez?',
  'Pratica alguma atividade física?' : '¿Haces ejercicio regularmente o practicas alguna actividad física?',
  'Atividade física, juntamente com outros hábitos de vida saudáveis, reduz a chance de desenvolvimento de vários tipos de câncer.' : 'La actividad física, junto con otros hábitos de vida saludables, reduce la posibilidad de desarrollar varios tipos de cáncer.',
  'Você já realizou um exame genético (conhecido como BRCA1 ou BRCA2) para saber se possui uma alteração/mutação que aumenta seu risco de desenvolver câncer, e o resultado foi positivo?' : '¿Alguna vez te has hecho una prueba genética (conocida como BRCA1 o BRCA2) para saber si tienes una alteración/mutación que aumenta tu riesgo de desarrollar cáncer y el resultado fue positivo?',
  'A mutação nos genes BRCA1 ou BRCA2 aumenta significativamente o risco para alguns tipos de câncer (mama, ovário, próstata, cólon e pâncreas). Este é um exame genético que pode ser realizado através do sangue ou da saliva para que a pessoa saiba se possui a mutação.' : 'La mutación en los genes BRCA1 o BRCA2 aumenta significativamente el riesgo de padecer algunos tipos de cáncer (mama, ovario, próstata, colon y páncreas). Se trata de una prueba genética que se puede realizar utilizando sangre o saliva para informar a la persona si tiene la mutación.',
  'Você respondeu que o teste para pesquisa de mutação BRCA foi positivo. Está correto?' : 'Usted respondió que la prueba para la investigación de mutación BRCA fue positiva. ¿Está correcto?',
  'Você já teve câncer?' : '¿Alguna vez has tenido cáncer?',
  'Sabe-se que quem já foi acometido por câncer apresenta um risco elevado de recidiva ou até mesmo de desenvolver um novo tumor do mesmo tipo. Por isso, é importante, para o cálculo de seu risco, saber se você já foi afetado por essa doença em sua vida.' : "Se sabe que cualquier persona que ya haya sido afectada por el cáncer tiene un alto riesgo de recurrencia o incluso de desarrollar un nuevo tumor del mismo tipo. Por eso, es importante, para calcular tu riesgo, saber si ya has sido afectado por esta enfermedad en tu vida.",
  'Selecione todas as alternativas sobre o histórico de câncer em sua família:' : "Seleccione todas las alternativas sobre el historial de cáncer en su familia:",
  'Avaliaremos essa informação para determinar o seu risco de portar alguma mutação genética familiar que aumenta o risco de desenvolver alguns tipos de câncer.' : 'Evaluaremos esta información para determinar su riesgo de portar una mutación genética familiar que aumenta el riesgo de desarrollar algunos tipos de cáncer.',
  'Qual o seu consumo diário de bebida alcoólica?' : '¿Cuál es su consumo diario de alcohol?',
  '1 drink = 350ml de cerveja/150ml de vinho/45ml de bebida destilada. O consumo de bebida alcoólica está associado ao aumento de risco de alguns tipos de câncer.' : '1 dosis = 350 ml de cerveza/150 ml de vino/45 ml de bebida destilada. El consumo de alcohol está asociado al aumento del riesgo de algunos tipos de cáncer.',
  'Você já teve algum problema cardíaco?' : '¿Alguna vez ha tenido un problema cardíaco?',
  'Você tem histórico de problemas emocionais?' : '¿Tiene antecedentes de problemas emocionales?',
  'Resultado Positivo' : 'Resultado Positivo',
  'Resultado Negativo' : 'Resultado Negativo',
  'Não sei se fiz o exame' : "No sé si hice el examen",
  'Não fiz o exame' : "No hice el examen",
  'Sem histórico familiar' : 'Sin historial familiar',
  'Algum dos seus parentes de 1 grau (mãe, pai, irmã, irmão, filha ou filho) tiveram câncer de mama ou ovário' : 'Alguno de sus parientes de 1er grado (madre, padre, hermana, hermano, hija o hijo) han tenido cáncer de mama u ovario',
  'Qualquer parente seu já teve câncer de mama bilateral' : 'Cualquier pariente suyo ha tenido cáncer de mama bilateral',
  'Algum homem da sua família teve câncer de mama' : 'Algún hombre de su familia tuvo cáncer de mama',
  'Alguma mulher em sua família teve câncer de mama e de ovário' : 'Alguna mujer en su familia tuvo cáncer de mama y de ovario',
  'Alguma mulher em sua família teve câncer de mama antes dos 50 anos de idade' : 'Alguna mujer en su familia tuvo cáncer de mama antes de los 50 años de edad',
  'Você tem 2 ou mais parentes com câncer de mama e/ou ovário' : 'Tiene 2 o más parientes con cáncer de mama y/o ovario',
  'Você tem 2 ou mais parentes com câncer de mama e/ou intestino' : 'Tiene 2 o más parientes con cáncer de mama y/o intestino',
  'Não tenho hábito de ingerir bebidas' : "No acostumbro a beber",
  'Menos de um drink por dia' : 'Menos de una bebida al día',
  'Entre 1 e 2 drinks por dia' : 'Entre 1 y 2 dosis al día',
  'Entre 2 e 3 drinks por dia' : 'Entre 2 y 3 dosis al día',
  'Entre 3 e 4 drinks por dia' : 'Entre 2 y 3 dosis al día',
  'Mais de 4 drinks por dia': 'Más de 4 dosis al día',
  'After completing the form below, you will receive an email link to reset your password.': 'Después de completar el siguiente formulario, recibirá un enlace por correo electrónico para restablecer su contraseña.',
  'Quais remédios?': '¿Cuáles estás tomando actualmente?',
  'CONTROLE DA PRESSAO ARTERIAL': 'CONTROL DE PRESIÓN ARTERIAL',
  'CONTROLE DO COLESTEROL E/OU TRIGLICERIDEOS' : 'CONTROL DE COLESTEROL Y/O TRIGLICÉRIDOS',
  'TRATAMENTO DO DIABETES' : 'TRATAMIENTO DE DIABETES',
  'ANTIALERGICOS' : 'ANTIHISTAMÍNICOS',
  'CORTICOIDES' : 'CORTICOIDES',
  'MEDICAMENTOS PARA ASMA E/OU BRONQUITE' : 'MEDICAMENTOS PARA EL ASMA Y/O LA BRONQUITIS',
  'ASPIRINA' : 'ASPIRINA',
  'CONTROLE DO COLESTEROL E / OU TRIGLICERIDEOS' : 'CONTROL DEL COLESTEROL Y/ O TRIGLICÉRIDOS',
  'ANALGESICOS' : 'ANALGESICOS',
  'MEDICAMENTOS PARA DORES DE CABECA' : 'MEDICAMENTOS PARA EL DOLOR DE CABEZA',
  'ANTI INFLAMATORIOS' : 'ANTI-INFLAMATÓRIOS',
  'TRATAMENTO DE DOENCAS DA TIREOIDE' : 'TRATAMIENTO DE LAS ENFERMEDADES TIROIDEAS',
  'TRATAMENTO DE GASTRITE, ÚLCERA OU REFLUXO' : 'TRATAMIENTO DE GASTRITIS, ÚLCERA O REFLUJO',
  'DOENCAS REUMATOLOGICAS OU DAS ARTICULACOES ' : 'ENFERMEDADES REUMATOLÓGICAS O ARTICULARES',
  'COLIRIOS' : 'COLIRIOS',
  'DOENCAS DO CORACAO - RITMO CARDIACO' : 'ENFERMEDADES DEL CORAZÓN - RITMO CARDIACO',
  'DOENCAS DO CORACAO - INSUFICIENCIA CARDIACA' : 'ENFERMEDADES DEL CORAZÓN - INSUFICIENCIA CARDIACA',
  'ANTICONCEPCIONAIS' : 'ANTICONCEPTIVOS',
  'MEDICAMENTO PARA IMPOTENCIA SEXUAL' : 'MEDICAMENTO PARA LA IMPOTENCIA SEXUAL',
  'OUTROS TRATAMENTOS HORMONAIS' : 'OTROS TRATAMIENTOS HORMONALES',
  'DOENCAS DA PROSTATA' : 'ENFERMEDADES DE LA PRÓSTATA',
  'DOENCAS INFLAMATORIAS DO INTESTINO - DOENCA DE CROHN OU RETOCOLITE' : "ENFERMEDADES INFLAMATORIAS INTESTINALES - ENFERMEDAD DE CROHN O COLITIS",
  'ANTIBIOTICOS' : 'ANTIBIÓTICOS',
  'DOENCAS DA BEXIGA E DO APARELHO URINARIO' : 'ENFERMEDADES DE LA VEJIGA Y DEL TRACTO URINARIO',
  'Outros' : 'Otros',
  'Durante quantos anos você fumou charutos?' : '¿Cuántos años ha fumado puros?',
  'ano ' : 'año',
  'anos ' : 'años',
  'No tempo que fumou, quantos charutos por dia?' : 'En el tiempo que fumó, ¿cuántos cigarros al día?',
  'Fuma charutos atualmente?' : '¿Fuma cigarros actualmente?',
  'Durante quantos anos você fumou cigarros?' : '¿Cuántos años lleva fumando cigarrillos?',
  'No tempo que fumou, quantos cigarros por dia?' : 'En el tiempo que fumó, ¿cuántos cigarrillos al día?',
  'Fuma cigarros atualmente?' : '¿Fuma cigarrillos actualmente?',
  'Quantos minutos por semana em média?' : '¿Cuántos minutos por semana de media?',
  'De 10 a 30 minutos por semana' : '10 a 30 minutos por semana',
  'De 30 a 150 minutos por semana' : '30 a 150 minutos por semana',
  'De 150 a 500 minutos por semana' : '150 a 500 minutos por semana',
  'Mais de 500 minutos por semana' : 'Más de 500 minutos por semana',
  'Qual tipo de câncer?' : '¿Qué tipo de cáncer?',
  'Cólon' : 'Colon',
  'Pulmão' : 'Pulmón',
  'Prostata' : 'Próstata',
  'Útero' : 'Útero',
  'Mama' : 'Mama',
  'Qual tipo de problema cardíaco?' : '¿Qué tipo de problema cardíaco?',
  'Infarto' : 'Infarto de miocardio',
  'Angina por obstrução das artérias do coração' : 'Angina por obstrucción de las arterias del corazón.',
  'Derrame' : 'Fuga',
  'Entupimento de artérias da perna' : 'Obstrucción de las arterias de las piernas.',
  'Dilatação (aneurisma) de Aorta' : 'Dilatación (aneurisma) de la aorta.',
  'Qual tipo de problema emocional?' : '¿Qué tipo de problema emocional?',
  'Ansiedade' : 'Ansiedad',
  'Estresse' : 'Estrés',
  'Depressão' : 'Depresión',
  'Burnout' : 'Burnout',
  'Com qual idade veio sua primeira menstruação?' : '¿A qué edad tuvo su primera menstruación?',
  'Câncer de Mama' : 'Cáncer de Mama',
  'Você já fez radioterapia no tórax para tratar linfoma?' : '¿Ha hecho radioterapia de tórax para tratar linfoma?',
  'Mulheres que realizaram esse tipo de tratamento antes dos 30 anos de idade apresentam grande risco de desenvolver câncer de mama durante a vida.' : 'Las mujeres que realizaron este tipo de tratamiento antes de los 30 años de edad presentan un gran riesgo de desarrollar cáncer de mama durante la vida.',
  'Você afirmou que já fez radioterapia? Está correto?' : '¿Dijo que había hecho radioterapia? ¿Está correcto?',
  'Sabe-se que quanto mais cedo uma mulher tem sua primeira menstruação, maior é o seu risco de desenvolver câncer de mama.' : 'Se sabe que cuanto antes tenga la primera regla una mujer, mayor será su riesgo de desarrollar cáncer de mama.',
  'De 7 a 11' : 'De 7 a 11',
  'De 12 a 13' : 'De 12 a 13',
  '14 ou mais' : '14 o más',
  'Não Sei' : 'No sé',
  'Com qual idade teve o primeiro filho (biológico) vivo?' : '¿A qué edad tuvo el primer hijo (biológico) vivo?',
  'Mulheres que tem o primeiro filho após os 30 anos de idade ou não tem filhos apresentam um risco maior de desenvolver câncer de mama quando comparadas com mulheres que tem filho antes dos 30 anos de idade.' : 'Las mujeres que tienen el primer hijo después de los 30 años de edad o no tienen hijos presentan un mayor riesgo de desarrollar cáncer de mama en comparación con las mujeres que tienen hijos antes de los 30 años de edad.',
  'Não sei' : 'No sé',
  'Não tenho filhos' : 'No tengo hijos',
  'Menos de 20' : 'Menos de 20',
  'Entre 20 e 24' : 'Entre 20 y 24',
  'Entre 25 e 29' : 'Entre 25 y 29',
  'Mais de 30' : 'Más de 30',
  'Quantos parentes de 1° grau (mãe, pai, irmã, irmão, filha ou filho) tiveram câncer de mama?' : '¿Cuántos parientes de 1er grado (madre, padre, hermana, hermano, hija o hijo) tuvieron cáncer de mama?',
  'Pessoas que apresentam câncer de mama em parentes de 1º grau e principalmente se esse parente teve o câncer antes dos 60 anos de idade, apresentam um risco maior de desenvolver câncer de mama durante a vida.' : 'Las personas que presentan cáncer de mama en parientes de 1er grado y principalmente si ese pariente tuvo cáncer antes de los 60 años de edad, presentan un riesgo mayor de desarrollar cáncer de mama durante la vida.',
  'Nenhum' : 'Ningún',
  'Mais de 1': 'Más de 1',
  'Você já realizou uma cirurgia para retirada total do útero (histerectomia)?' : '¿Alguna vez ha realizado una cirugía para una extracción completa del útero (histerectomía)?',
  'Você respondeu que um parente de 1º grau (mãe, pai, irmã, irmão, filha ou filho) já teve câncer de mama. Está correto?' : 'Usted respondió que un pariente de 1er grado (madre, padre, hermana, hermano, hija o hijo) ya tuvo cáncer de mama. ¿Está correcto?',
  'Você já tomou as 3 doses de vacina para o HPV?' : '¿Ha tomado las tres dosis de vacuna para el VPH?',
  'Já fez alguma biópsia de mama?': '¿Ha hecho una biopsia de mama?',
  'Pessoas que já tiveram certos tipos de doenças na mama têm um risco maior de desenvolver câncer de mama, especialmente se essas doenças apresentaram uma alteração chamada atipia.' : 'Las personas que han tenido ciertos tipos de enfermedades mamarias tienen un mayor riesgo de desarrollar cáncer de mama, especialmente si estas enfermedades tienen un cambio llamado atipia.',
  'Quantas biópsias foram feitas?' : '¿Cuántas biopsias se han hecho?',
  'Alguma biópsia deu alteração com atipias?' : '¿Alguna biopsia dio alteración con atipias?',
  'Atipias são anormalidades nas células ou tecidos que não parecem saudáveis sob um microscópio. Elas podem indicar um risco aumentado de câncer, mas nem sempre se transformam em câncer. O acompanhamento médico é essencial para determinar a necessidade de tratamento ou monitoramento.' : 'La atipia son anomalías en células o tejidos que parecen no saludables bajo el microscopio. Pueden indicar un mayor riesgo de cáncer, pero no siempre se convierten en cáncer. El seguimiento médico es fundamental para determinar la necesidad de tratamiento o seguimiento.',
  'Quando você realizou sua última mamografia?': '¿Cuándo realizó su última mamografía?',
  'Menos de 1 ano' : 'Menos de 1 año',
  'Mais de 1 ano' : 'Más de 1 año',
  'Nunca realizei' : 'Nunca he realizado',
  'Você observa alguma lesão palpável?' : '¿Observa alguna lesión palpable?',
  'Sente nódulos ou "caroços" na mama.' : 'Siente nódulos o "bulbos" en la mama.',
  'Possui secreção nas papilas de forma espontânea?' : '¿Tiene secreción espontánea por pezon?',
  'Saída de secreção pelos mamilos.' : 'Salida de secreción por los pezones.',
  'Observou alguma mudança de forma na mama?' : '¿Ha observado algún cambio de forma en la mama?',
  'Câncer de Pulmão' : 'Cáncer de Pulmón',
  'Você viveu com fumantes por mais da metade da sua vida?' : '¿Ha vivido con fumadores más de la mitad de su vida?',
  'O fumo passivo também aumenta o risco para desenvolvimento de câncer de pulmão.' : 'El tabaquismo pasivo también aumenta el riesgo para el desarrollo de cáncer de pulmón.',
  'Você já trabalhou com amianto, sem a devida proteção?' : '¿Alguna vez ha trabajado con amianto sin la protección adecuada?',
  'Existe uma associação entre o trabalho com amianto e um risco maior de desenvolvimento de câncer do pulmão.' : 'Existe una asociación entre el trabajo con amianto y un mayor riesgo de desarrollo de cáncer de pulmón.',
  'Selecione todas as atividades abaixo, com as quais você tenha trabalhado sem a devida proteção:' : 'Seleccione todas las actividades con las que ha trabajado sin la debida protección.',
  'Exaustão de motores a diesel' : 'Escape de motores diésel',
  'Pó ou cristal de sílica' : 'Polvo o cristal de sílice',
  'Compostos de níquel' : 'Compuestos de níquel',
  'Compostos de cromo (VI)' : 'Compuestos de cromo (VI)',
  'Arsênio e compostos inorgânicos de arsênio' : 'Arsénico y compuestos inorgánicos de arsénico',
  'Radiações X e Gama' : 'Radiaciones X y Gamma',
  'Compostos de cádmio' : 'Compuestos de cadmio',
  'Compostos de berílio': 'Compuestos de berilio',
  'Essas atividades quando feitas por longo períodos e sem a devida proteção, podem aumentar o risco de desenvolvimento de alguns tipos de câncer.' : 'Estas actividades, cuando se realizan a largo plazo y sin la debida protección, pueden aumentar el riesgo de desarrollo de algunos tipos de cáncer.',
  'Pintura' : 'Pintura',
  'Fundição de alumínio' : 'Fundición de aluminio',
  'Fundição de ferro ou aço' : 'Fundición de hierro o acero',
  'Fabricação de borracha' : 'Fabricación de caucho',
  'Fornos de coque' : 'Hornos de coque',
  'Gaseificação de carvão mineral' : 'Gasificación de carbón mineral',
  'Você sente falta de ar ou dificuldade para respirar?' : '¿Le falta el aire o le cuesta respirar?',
  'Tem percebido isso de maneira progressiva nos últimos tempos?' : '¿Lo ha percibido de manera progresiva en los últimos tiempos?',
  'Apresenta tosse contínua ou progressiva?' : '¿Tiene tos continua o progresiva?',
  'A tosse te acompanha por umArsênio e compostos inorgânicos de arsênio período de tempo e está aumentando em frequência?' : '¿La tos le acompaña durante un período de tiempo y está aumentando en frecuencia?',
  'Não trabalhei' : 'No trabajé',
  'Nunca trabalhei' : 'Nunca trabajé',
  'Câncer de Cólon' : 'Cáncer de Colon',
  'Algum parente de 1° grau (mãe, pai, irmã, irmão, filha ou filho) já teve câncer de intestino?' : '¿Algún pariente de 1er grado (madre, padre, hermana, hermano, hija o hijo) ha tenido cáncer de intestino?',
  'Pacientes que apresentam um familiar de 1º grau ou mais familiares com câncer de intestino apresentam maior risco de desenvolver câncer de intestino durante a vida.' : 'Los pacientes que presentan un familiar de 1er grado o más familiares con cáncer de intestino presentan mayor riesgo de desarrollar cáncer de intestino durante la vida.',
  'Você respondeu que um parente de 1º grau (mãe, pai, irmã, irmão, filha ou filho) já teve câncer de intestino. Está correto?' : 'Usted respondió que un pariente de 1er grado (madre, padre, hermana, hermano, hija o hijo) ya tuvo cáncer de intestino. ¿Está correcto?',
  'Essas lesões são conhecidas como lesões pré-cancerosas e pessoas que apresentam isso correm mais risco de desenvolver câncer de intestino durante a vida.' : 'Estas lesiones se conocen como lesiones precancerosas y las personas que presentan esto tienen más riesgo de desarrollar cáncer de intestino durante su vida.',
  'Você teve uma condição conhecida como doença inflamatória intestinal crônica (ou DII) por 10 anos ou mais? Isso inclui a doença de Crohn e a colite ulcerosa, mas não a síndrome do intestino irritável (SII).' : "Ha tenido una condición conocida como enfermedad inflamatoria intestinal crónica (o DII) durante 10 años o más? Esto incluye la enfermedad de Crohn y la colitis ulcerosa, pero no el síndrome del intestino irritable (SII).",
  'Já realizou alguma vez os seguintes exames: pesquisa de sangue oculto nas fezes, retossigmoidoscopia ou conoloscopia?' : '¿Ha realizado alguna vez los siguientes exámenes: investigación de sangre oculta en las heces, endoscopia gastrointestinal o colonoscopia?',
  'Exame recomendado para pessoas sadias e de risco normal após os 45 anos de idade.' : 'Examen recomendado para personas sanas y de riesgo normal después de los 45 años de edad.',
  'Quando foi a última vez?': '¿Cuándo fue la última vez?',
  'Sente dor intensa durante a relação sexual?': '¿Siente dolor intenso durante el coito?',
  'Alguma vez notou sangramento durante ou após a relação sexual?': '¿Alguna vez notó sangrado durante o después del coito?',
  'Apresenta algum sangramento fora do período menstrual ou após a menopausa?' : '¿Presenta algún sangrado fuera del período menstrual o después de la menopausia?',
  'Mais de 2 anos' : 'Más de 2 años',
  'Mais de 3 anos' : 'Más de 3 años',
  'Você sofre de obstipação intestinal (intestino preso) ou diarreia frequente?' : '¿Sufre de estreñimiento intestinal (intestino atrapado) o diarrea frecuente?',
  'Alterações progressivas e persistentes do hábito intestinal demandam uma avaliação médica mais aprofundada.' : 'Los cambios progresivos y persistentes en el hábito intestinal requieren una evaluación médica más profunda.',
  'Já observou presença de sangue nas fezes?' : '¿Ha observado sangre en las heces?',
  'Isso pode ser um sinal de doenças do trato gastrointestinal.' : 'Esto puede ser un signo de enfermedades del tracto gastrointestinal.',
  'Você já teve alguma doença intestinal em que teve que retirar um pólipo (por colonoscopia), e, seu médico lhe falou que este pólipo era de alto risco e poderia virar câncer? ' : '¿Ha tenido alguna enfermedad intestinal en la que tuvo que extirpar un pólipo (por colonoscopia), y su médico le dijo que este pólipo era de alto riesgo y podría convertirse en cáncer?',
  'Há menos de um ano ' : 'Hace menos de un año',
  'Há mais de um ano' : 'Hace más de un año',
  'Nunca' : 'Nunca',
  'Poucas vezes' : 'Pocas veces',
  'Às vezes' : 'A veces',
  'Frequentemente' : 'Frecuentemente',
  'Sempre' : 'Siempre',
  'Cardio' : 'Cardio',
  'Qual a sua pressão arterial sistólica?' : '¿Cuál es su presión arterial sistólica?',
  'Você tem diabetes?' : '¿Tiene diabetes?',
  'Sua diabetes é do tipo 1 ou 2?' : '¿Tiene diabetes tipo 1 o 2?',
  'tipo 1' : 'tipo 1',
  'tipo 2' : 'tipo 2',
  'Há quantos anos tem diabetes?' : '¿Hace cuántos años que tiene diabetes?',
  'Há quantos anos toma insulina?' : '¿Cuántos años lleva tomando insulina?',
  'Você tem os resultados do seu último exame de colesterol?' : '¿Tiene los resultados de su último examen de colesterol?',
  'Quanto tempo faz que fez os exames?' : '¿Cuánto hace que hizo los exámenes?',
  '6  meses' : '6 meses',
  'Menos de 6 meses' : 'Menos de 6 meses',
  'Entre 6 meses e 1 ano' : 'Menos de 6 meses',
  'Entre 1 e 2 anos' : 'Entre 1 y 2 años',
  '1 ano' : '1 año',
  '2 anos' : '2 años',
  '3 anos' : '3 años',
  '4 anos' : '4 años',
  '5 anos' : '5 años',
  '6 anos' : '6 años',
  '7 anos' : '7 años',
  '8 anos' : '8 años',
  '9 anos' : '9 años',
  '10 anos' : '10 años',
  '11 anos' : '11 años',
  '12 anos' : '12 años',
  '13 anos' : '13 años',
  '14 anos' : '14 años',
  '15 anos' : '15 años',
  '16 anos' : '16 años',
  '17 anos' : '17 años',
  '18 anos' : '18 años',
  '19 anos' : '19 años',
  '20 anos' : '20 años',
  '21 anos' : '21 años',
  '22 anos' : '22 años',
  '23 anos' : '23 años',
  '24 anos' : '24 años',
  '25 anos' : '25 años',
  '26 anos' : '26 años',
  '27 anos' : '27 años',
  '28 anos' : '28 años',
  '29 anos' : '29 años',
  '30 anos' : '30 años',
  '31 anos' : '31 años',
  '32 anos' : '32 años',
  '33 anos' : '33 años',
  '34 anos' : '34 años',
  '35 anos' : '35 años',
  '36 anos' : '36 años',
  '37 anos' : '37 años',
  '38 anos' : '38 años',
  '39 anos' : '39 años',
  '40 anos' : '40 años',
  '41 anos' : '41 años',
  '42 anos' : '42 años',
  '43 anos' : '43 años',
  '44 anos' : '44 años',
  '45 anos' : '45 años',
  '46 anos' : '46 años',
  '47 anos' : '47 años',
  '48 anos' : '48 años',
  '49 anos' : '49 años',
  '50 anos' : '50 años',
  '51 anos' : '51 años',
  '52 anos' : '52 años',
  '53 anos' : '53 años',
  '54 anos' : '54 años',
  '55 anos' : '55 años',
  '56 anos' : '56 años',
  '57 anos' : '57 años',
  '58 anos' : '58 años',
  '59 anos' : '59 años',
  '60 anos' : '60 años',
  '61 anos' : '61 años',
  '62 anos' : '62 años',
  '63 anos' : '63 años',
  '64 anos' : '64 años',
  '65 anos' : '65 años',
  '66 anos' : '66 años',
  '67 anos' : '67 años',
  '68 anos' : '68 años',
  '69 anos' : '69 años',
  '70 anos' : '70 años',
  '71 anos' : '71 años',
  '72 anos' : '72 años',
  '73 anos' : '73 años',
  '74 anos' : '74 años',
  '75 anos' : '75 años',
  '76 anos' : '76 años',
  '77 anos' : '77 años',
  '78 anos' : '78 años',
  '79 anos' : '79 años',
  '80 anos' : '80 años',
  '81 anos' : '81 años',
  '82 anos' : '82 años',
  '83 anos' : '83 años',
  '84 anos' : '84 años',
  '85 anos' : '85 años',
  '86 anos' : '86 años',
  '87 anos' : '87 años',
  '88 anos' : '88 años',
  '89 anos' : '89 años',
  '90 anos' : '90 años',
  '91 anos' : '91 años',
  '92 anos' : '92 años',
  '93 anos' : '93 años',
  '94 anos' : '94 años',
  '95 anos' : '95 años',
  '96 anos' : '96 años',
  '97 anos' : '97 años',
  '98 anos' : '98 años',
  '99 anos' : '99 años',
  '100 anos' : '100 años',
  '+ de 2 anos' : '+ 2 years',
  'Qual o valor do coleterol Total' : '¿Cuál es el valor del colesterol Total?',
  'Qual o valor do coleterol LDL' : '¿Cuál es el valor del colesterol LDL?',
  'Qual o valor do coleterol HDL' : '¿Cuál es el valor del colesterol HDL?',
  'Qual o valor de triglicerídeos?' : '¿Cuál es el valor del colesterol Trigliceridos?',
  '1 charuto' : '1 cigarro',
  '2 charutos' : '2 puros',
  '3 charutos' : '3 puros',
  '4 charutos' : '4 puros',
  '5 charutos' : '5 puros',
  '6 charutos' : '6 puros',
  '7 charutos' : '7 puros',
  '8 charutos' : '8 puros',
  '9 charutos' : '9 puros',
  '10 charutos' : '10 puros',
  '11 charutos' : '11 puros',
  '12 charutos' : '12 puros',
  '13 charutos' : '13 puros',
  '14 charutos' : '14 puros',
  '15 charutos' : '15 puros',
  '16 charutos' : '16 puros',
  '17 charutos' : '17 puros',
  '18 charutos' : '18 puros',
  '19 charutos' : '19 puros',
  '20 charutos' : '20 puros',
  '21 charutos' : '21 puros',
  '22 charutos' : '22 puros',
  '23 charutos' : '23 puros',
  '24 charutos' : '24 puros',
  '25 charutos' : '25 puros',
  '26 charutos' : '26  puros',
  '28 charutos' : '28 puros',
  '29 charutos' : '29 puros',
  '30 charutos' : '30 puros',
  '31 charutos' : '31 puros',
  '32 charutos' : '32 puros',
  '33 charutos' : '33 puros',
  '34 charutos' : '34 puros',
  '35 charutos' : '35 puros',
  '36 charutos' : '36 puros',
  '37 charutos' : '37 puros',
  '38 charutos' : '38 puros',
  '39 charutos' : '39 puros',
  '40 charutos' : '40 puros',
  '41 charutos' : '41 puros',
  '42 charutos' : '42 puros',
  '43 charutos' : '43 puros',
  '44 charutos' : '44 puros',
  '45 charutos' : '45 puros',
  '46 charutos' : '46 puros',
  '47 charutos' : '47 puros',
  '48 charutos' : '48 puros',
  '49 charutos' : '49 puros',
  '50 charutos' : '50 puros',
  '51 charutos' : '51 puros',
  '52 charutos' : '52 puros',
  '53 charutos' : '53 puros',
  '54 charutos' : '54 puros',
  '55 charutos' : '55 puros',
  '56 charutos' : '56 puros',
  '57 charutos' : '57 puros',
  '58 charutos' : '58 puros',
  '59 charutos' : '59 puros',
  '60 charutos' : '60 puros',
  '61 charutos' : '61 puros',
  '62 charutos' : '62 puros',
  '63 charutos' : '63 puros',
  '64 charutos' : '64 puros',
  '65 charutos' : '65 puros',
  '66 charutos' : '66 puros',
  '67 charutos' : '67 puros',
  '68 charutos' : '68 puros',
  '69 charutos' : '69 puros',
  '70 charutos' : '70 puros',
  '71 charutos' : '71 puros',
  '72 charutos' : '72 puros',
  '73 charutos' : '73 puros',
  '74 charutos' : '74 puros',
  '75 charutos' : '75 puros',
  '76 charutos' : '76 puros',
  '77 charutos' : '77 puros',
  '78 charutos' : '78 puros',
  '79 charutos' : '79 puros',
  '80 charutos' : '80 puros',
  '81 charutos' : '81 puros',
  '82 charutos' : '82 puros',
  '83 charutos' : '83 puros',
  '84 charutos' : '84 puros',
  '85 charutos' : '85 puros',
  '86 charutos' : '86 puros',
  '87 charutos' : '87 puros',
  '88 charutos' : '88 puros',
  '89 charutos' : '89 puros',
  '90 charutos' : '90 puros',
  '91 charutos' : '91 puros',
  '92 charutos' : '92 puros',
  '93 charutos' : '93 puros',
  '94 charutos' : '94 puros',
  '95 charutos' : '95 puros',
  '96 charutos' : '96 puros',
  '97 charutos' : '97 puros',
  '98 charutos' : '98 puros',
  '99 charutos' : '99 puros',
  '100 charutos': '100 puros',
  'Mental' : 'Mental',
  'Eu penso em mudar minhas condições atuais de trabalho (por exemplo, meu turno, o local de trabalho, etc)' : 'Pienso en cambiar mis condiciones de trabajo actuales (por ejemplo, mi turno o lugar de trabajo, etc)',
  'Nos últimos 6 meses,me senti muito ansioso e/ou preocupado com o trabalho.' : 'En los últimos 6 meses, me sentí muy ansioso y/o preocupado con el trabajo.',
  'Minha empresa me oferece um ambiente de trabalho seguro e acolhedor' : 'Mi empresa me ofrece un ambiente de trabajo seguro y acogedor',
  'Sinto-me reconhecido no trabalho.' : 'Me siento reconocido en el trabajo.',
  'Meu estresse atual no local de trabalho afeta negativamente meu humor, pensamento e comportamento' : 'Mi estrés actual en el lugar de trabajo afecta negativamente mi estado de ánimo, pensamiento y comportamiento',
  'Sinto-me distraído ou tenho dificuldade em me concentrar devido ao meu ambiente de trabalho.' : 'Me siento distraído o tengo dificultades para concentrarme debido a mi entorno de trabajo.',
  'Sinto-me exausto quando estou no trabalho.' : 'Me siento agotado cuando estoy en el trabajo.',
  'Sinto-me emocionalmente esgotado pelo meu trabalho.' : 'Me siento emocionalmente agotado por mi trabajo.',
  'Meu ambiente de trabalho me faz sentir inútil ou culpado.' : 'Mi ambiente de trabajo me hace sentir inútil o culpable.',
  'Meu humor e meus sentimentos em relação ao meu trabalho facilitam meu desempenho em minha vida pessoal, social ou profissional.' : 'Mi buen humor y mis sentimientos por mi trabajo facilitan mi desempeño en mi vida personal, social o profesional.',
  'Nos últimos 6 meses, senti tontura ou minha cabeça estava girando' : 'En los últimos 6 meses, sentí mareos o como mi cabeza estuviese girando',
  'Nos últimos 6 meses, tive dores no peito, quase como se estivesse tendo um ataque cardíaco.': 'Durante los últimos seis meses, he tenido dolores en el pecho, casi como si estuviera teniendo un ataque al corazón.',
  'Nos últimos 6 meses, senti-me mal do estômago, quase como se fosse vomitar.' : 'En los últimos seis meses, me sentí mal del estómago, casi como si fuera a vomitar.',
  '1 cigarro' : '1 cigarrillo',
  '2 cigarros' : '2 cigarrillos',
  '3 cigarros' : '3 cigarrillos',
  '4 cigarros' : '4 cigarrillos',
  '5 cigarros' : '5 cigarrillos',
  '6 cigarros' : '6 cigarrillos',
  '7 cigarros' : '7 cigarrillos',
  '8 cigarros' : '8 cigarrillos',
  '9 cigarros' : '9 cigarrillos',
  '10 cigarros' : '10 cigarrillos',
  '11 cigarros' : '11 cigarrillos',
  '12 cigarros' : '12 cigarrillos',
  '13 cigarros' : '13 cigarrillos',
  '14 cigarros' : '14 cigarrillos',
  '15 cigarros' : '15 cigarrillos',
  '16 cigarros' : '16 cigarrillos',
  '17 cigarros' : '17 cigarrillos',
  '18 cigarros' : '18 cigarrillos',
  '19 cigarros' : '19 cigarrillos',
  '20 cigarros' : '20 cigarrillos',
  '21 cigarros' : '21 cigarrillos',
  '22 cigarros' : '22 cigarrillos',
  '23 cigarros' : '23 cigarrillos',
  '24 cigarros' : '24 cigarrillos',
  '25 cigarros' : '25 cigarrillos',
  '26 cigarros' : '26 cigarrillos',
  '27 cigarros' : '27 cigarrillos',
  '28 cigarros' : '28 cigarrillos',
  '29 cigarros' : '29 cigarrillos',
  '30 cigarros' : '30 cigarrillos',
  '31 cigarros' : '31 cigarrillos',
  '32 cigarros' : '32 cigarrillos',
  '33 cigarros' : '33 cigarrillos',
  '34 cigarros' : '34 cigarrillos',
  '35 cigarros' : '35 cigarrillos',
  '36 cigarros' : '36 cigarrillos',
  '37 cigarros' : '37 cigarrillos',
  '38 cigarros' : '38 cigarrillos',
  '39 cigarros' : '39 cigarrillos',
  '40 cigarros' : '40 cigarrillos',
  '41 cigarros' : '41 cigarrillos',
  '42 cigarros' : '42 cigarrillos',
  '43 cigarros' : '43 cigarrillos',
  '44 cigarros' : '44 cigarrillos',
  '45 cigarros' : '45 cigarrillos',
  '46 cigarros' : '46 cigarrillos',
  '47 cigarros' : '47 cigarrillos',
  '48 cigarros' : '48 cigarrillos',
  '49 cigarros' : '49 cigarrillos',
  '50 cigarros' : '50 cigarrillos',
  '51 cigarros' : '51 cigarrillos',
  '52 cigarros' : '52 cigarrillos',
  '53 cigarros' : '53 cigarrillos',
  '54 cigarros' : '54 cigarrillos',
  '55 cigarros' : '55 cigarrillos',
  '56 cigarros' : '56 cigarrillos',
  '57 cigarros' : '57 cigarrillos',
  '58 cigarros' : '58 cigarrillos',
  '59 cigarros' : '59 cigarrillos',
  '60 cigarros' : '60 cigarrillos',
  '61 cigarros' : '61 cigarrillos',
  '62 cigarros' : '62 cigarrillos',
  '63 cigarros' : '63 cigarrillos',
  '64 cigarros' : '64 cigarrillos',
  '65 cigarros' : '65 cigarrillos',
  '66 cigarros' : '66 cigarrillos',
  '67 cigarros' : '67 cigarrillos',
  '68 cigarros' : '68 cigarrillos',
  '69 cigarros' : '69 cigarrillos',
  '70 cigarros' : '70 cigarrillos',
  '71 cigarros' : '71 cigarrillos',
  '72 cigarros' : '72 cigarrillos',
  '73 cigarros' : '73 cigarrillos',
  '74 cigarros' : '74 cigarrillos',
  '75 cigarros' : '75 cigarrillos',
  '76 cigarros' : '76 cigarrillos',
  '77 cigarros' : '77 cigarrillos',
  '78 cigarros' : '78 cigarrillos',
  '79 cigarros' : '79 cigarrillos',
  '80 cigarros' : '80 cigarrillos',
  '81 cigarros' : '81 cigarrillos',
  '82 cigarros' : '82 cigarrillos',
  '83 cigarros' : '83 cigarrillos',
  '84 cigarros' : '84 cigarrillos',
  '85 cigarros' : '85 cigarrillos',
  '86 cigarros' : '86 cigarrillos',
  '87 cigarros' : '87 cigarrillos',
  '88 cigarros' : '88 cigarrillos',
  '89 cigarros' : '89 cigarrillos',
  '90 cigarros' : '90 cigarrillos',
  '91 cigarros' : '91 cigarrillos',
  '92 cigarros' : '92 cigarrillos',
  '93 cigarros' : '93 cigarrillos',
  '94 cigarros' : '94 cigarrillos',
  '95 cigarros' : '95 cigarrillos',
  '96 cigarros' : '96 cigarrillos',
  '97 cigarros' : '97 cigarrillos',
  '98 cigarros' : '98 cigarrillos',
  '99 cigarros' : '99 cigarrillos',
  '100 cigarros': '100 cigarrillos',
  'Eu sei quais recursos posso usar se sentir que preciso de apoio (por exemplo, benefícios de saúde mental, programas de assistência aos funcionários, suporte virtual ou no local).' : 'Sé qué recursos puedo usar si siento que necesito apoyo (por ejemplo, beneficios para la salud mental, programas de asistencia al personal, soporte virtual o in situ).',
  'Sinto-me contente, alegre e consigo me conectar facilmente com as pessoas no trabalho.' : 'Me siento contento, de buen humor y puedo conectarme fácilmente con la gente en el trabajo.',
  'Interajo regularmente com meus colegas de trabalho, fazendo-me sentir que pertenço a um grupo, que estou seguro e protegido.': 'Interactúo regularmente con mis compañeros de trabajo, sintiendo que pertenezco a un grupo, que estoy seguro y protegido.',
  'Se já parou de fumar charutos, há quantos anos?': 'Si dejó de fumar cigarros, ¿hace cuántos años?',
  'Durante quanto anos você fumou cigarros?': '¿Cuántos años ha fumado cigarrillos?',
  'Se já parou de fumar cigarros, há quantos anos?': 'Si dejó de fumar cigarrillos, ¿hace cuántos años?',
  'Você já realizou um exame genético (conhecido como BRCA1 ou BRCA) para saber se tem uma alteração/mutação que aumenta seu risco para desenvolver câncer e este resultado foi positivo?': '¿Alguna vez te has hecho el examen de genotipo (conocido como BRCA1 o BRCA) para saber si tienes una alteración/mutación que aumenta tu riesgo de desarrollar cáncer y este resultado fue positivo?',
  'Vc respondeu que o teste para pesquisa de mutação BRCA e ele foi positivo . Está correto?': 'Usted respondió que el resultado del examen BRCA fue positivo. ¿Es eso correcto?',
  'Voce já teve Câncer?': '¿Ha tenido cáncer alguna vez?',
  'Selecione todas alternativas sobre o histórico de câncer em sua família': "Seleccione todas las alternativas sobre el historial de cáncer de su familia",
  'Qual o seu consumo diário de bebida alcólica?': '¿Cuál es su consumo diario de alcohol?',
  'Digite acima o tipo de problema cardíaco.': 'Introduzca el tipo de problema cardíaco.',
  'Você tem histórico de problemas emocionais ?': '¿Tiene antecedentes de problemas emocionales?',
  'Qual tipo de problema emoncional?': '¿Qué tipo de problema emocional?',
  'Com qual idade veio sua primeira Menstruação?': '¿Qué edad tenías cuando tuviste tu primer período?',
  'Quantos parentes de 1 grau (mãe, pai, irmã, irmão, filha ou filho) tiveram câncer de Mama?': '¿Algún familiar de primer grado (padre, madre, hermano, hermana, hijo y/o hija) ha tenido cáncer de mama?',
  'Vc respondeu que um parente de 1º grau (mãe, pai, irmã, iramão, filha ou filho) tiveram cancer de mama. Está correto?': 'Usted respondió que un familiar de primer grado (padre, madre, hermano, hermana, hijo y/o hija) tuvo cáncer de mama. ¿Es esto correcto?',
  'Já fez alguma biopsia de mama?': '¿Alguna vez te han hecho una biopsia de mama?',
  'Você já teve relação sexual?': '¿Ha tenido relaciones sexuales?',
  'O câncer de colo de útero é ocasionado por um vírus chamado HPV que, na maioria das vezes, é transmitido pela relação sexual. As pessoas que já tiveram relações apresentam um risco de ter adquirido o vírus e, consequentemente, têm mais chance.': 'El cáncer de cuello uterino es ocasionado por un virus llamado VPH que, la mayoría de las veces, es transmitido por la relación sexual. Las personas que ya han tenido relaciones presentan un riesgo de haber adquirido el virus y, consecuentemente, tienen más chance.',
  'Você já realizou uma cirurgia para retirada total do útero (histerectomia)': '¿Alguna vez se ha sometido a una cirugía para extirpar el útero por completo (histerectomía)?',
  'Pacientes que retiraram o útero conjuntamente com o colo uterino não apresentam mais risco de desenvolver câncer de colo uterino.': 'Pacientes que han extraído el útero conjuntamente con el cuello uterino ya no tienen riesgo de desarrollar cáncer de cuello uterino.',
  'Já realizou algum exame preventivo de colo uterino (papanicolau)?': '¿Ha realizado alguna vez un examen preventivo del cuello uterino (papanicolau)?',
  'Exame recomendado após os 21 anos de idade para todas as mulheres sadias e de risco normal que já tiveram relação sexual.': 'Examen recomendado después de los 21 años de edad para todas las mujeres sanas y de riesgo normal que ya tuvieron relaciones sexuales.',
  'Quando foi a ultima vez?': '¿Cuándo fue la última vez?',
  'Alguma vez deu alteração dos tipos NIC I, NIC II , NIC III HSIL ou LSIL?': '¿Alguna vez tuvo un resultado de Pap con CIN I, CIN II o III, LSIL o HSIL?',
  'São alterações identificadas no exame preventivo (Papanicolaou) ou na colposcopia que podem estar associados a um risco aumentado de desenvolver câncer de colo uterino.': 'Estos resultados obtenidos en el Pap o en la colpo pueden estar asociados a un mayor riesgo de desarrollar cáncer de cuello uterino.',
  'Você já tomou as 3 doses de vacina para o HPV': '¿Ha tomado las 3 dosis de la vacuna contra el VPH?',
  'HPV é um vírus, geralmente de transmissão sexual, que pode fazer lesões imperceptíveis no colo de útero e posteriormente gerar o câncer desse órgão.': 'El VPH es un virus, generalmente de transmisión sexual, que puede causar lesiones imperceptibles en el cuello uterino y posteriormente generar el cáncer de ese órgano.',
  'Durante sua vida, fez uso regular e constante de preservativos ou diafrágmas?': '¿Durante su vida, hizo uso regular y constante de condones o diafragmas?',
  'A utilização desses métodos de barreira diminuem o risco de transmissão de um vírus que tem o potencial de ocasionar o câncer de colo uterino.': 'La utilización de estos métodos de barrera reduce el riesgo de transmisión de un virus que tiene el potencial de ocasionar el cáncer de cuello uterino',
  'Sente dor intensa durante a relação sexual': '¿Sientes dolor intenso durante el coito?',
  'Não é considerado normal sentir dor durante as relações sexuais.': 'No se considera normal sentir dolor durante las relaciones sexuales.',
  'Sangramentos vaginais irregulares ou após a menopausa necessitam ser melhor avaliados.': 'Sangrados vaginales irregulares o después de la menopausia necesitan ser mejor evaluados.',
  'Selecione todas as atividades abaixo com as quais você tenha trabalhado sem a devida proteção': '¿Ha trabajado con alguna de estas sustancias sin la debida protección? Por favor seleccione todas las respuestas válidas.',
  'Você sente falta de ar ou dificulade para respirar?': '¿Sientes falta de aire o dificultad para respirar?',
  'Algum parentes de 1 grau (mãe, pai, irmã, irmão, filha ou filho) já teve câncer de intestino?': '¿Algún familiar de primer grado (padre, madre, hermano, hermana, hijo y/o hija) ha tenido cáncer de colon?',
  'Vc respondeu que um parente de 1º grau (mãe, pai, irmã, iramão, filha ou filho) já teve câncer de intestino. Está correto?': 'Usted respondió que un familiar de primer grado (padre, madre, hermano, hermana, hijo y/o hija) tuvo cáncer de colon. ¿Es esto correcto?',
  'Você já teve alguma doença intestinal em que teve que retirar um pólipo (por colonoscopia) e seu médico lhe falou que este pólipo era de alto risco epoderia virar câncer? ': '¿Alguna vez ha tenido una enfermedad intestinal en la que tuvo que extirparse un pólipo (por colonoscopia), y su médico le dijo que este pólipo era de alto riesgo y podría convertirse en cáncer?',
  'Quando foi a última vez ?': '¿Cuándo fue el más reciente?',
  'Algum parentes de 1 grau (pai, irmão ou filho) já teve câncer de próstata?': '¿Algún pariente de 1er grado (padre, hermano o hijo) ha tenido cáncer de próstata?',
  'Pessoas que apresentam um parente de 1º grau ou mais de 1 parente com câncer de próstata apresentam risco aumentado para desenvolvimento do câncer de próstata.': 'Las personas que tienen un pariente de 1er grado o más de 1 pariente con cáncer de próstata tienen un mayor riesgo de desarrollar cáncer de próstata.',
  'Vc respondeu que um parente de 1º grau (pai, irmão ou filho) já teve câncer de Próstata. Está correto?': 'Usted respondió que un familiar de primer grado (padre, madre, hermano, hermana, hijo y/o hija) tuvo cáncer de próstata. ¿Es esto correcto?',
  'O esforço para urinar está mais intenso do que habitualmente?': '¿El esfuerzo para orinar es más intenso de lo habitual?',
  'A perda de sangue pela urina não é normal e deve ser melhor avaliada em uma consulta médica.': 'La pérdida de sangre por la orina no es normal y debe evaluarse mejor en una consulta médica.',
  'O não esvaziamento completo da bexiga após urinar requer uma avaliação mais aprofundada desse sintoma.': 'El no vaciamiento completo de la vejiga después de orinar requiere una evaluación más profunda de este síntoma.',
  'Estou satisfeito com minhas atuais condições de trabalho': 'Estoy satisfecho con mis condiciones de trabajo actuales (entorno físico, condiciones de oficina en casa, mis asignaciones y equipo de trabajo).',
  'Os meus colegas me dão apoio emocional para me ajudar-mea gerir o meu stress no trabalho.': 'Mis compañeros me dan apoyo emocional para ayudarme a manejar mi estrés en el trabajo.',
  'Os meus colegas perguntam como eu estou frequentemente.': 'Mis colegas me preguntan cómo estoy a menudo.',
  'Eu realmente me importo com o que acontece com meus colegas ou clientes no meu trabalho.': 'Realmente me importa lo que sucede con mis colegas o clientes en mi trabajo.',
  'Sinto-me mais insensível com as pessoas desde que aceitei este trabalho': 'Me siento más insensible con la gente desde que empecé este trabajo',
  'O meu supervisor fornece o apoio de que necessito para gerir o meu trabalho': 'Mi supervisor proporciona el apoyo que necesito para administrar mi trabajo',
  'O meu supervisor dá-me apoio emocional para me ajudar a gerir o meu stress.': 'Mi supervisor me da apoyo emocional para ayudarme a manejar mi estrés.',
  'O meu supervisor pergunta frequentemente como estou, fazendo-me sentir confiante e seguro no trabalho.': 'Mi supervisor a menudo me pregunta cómo estoy, haciéndome sentir seguro y confiado en el trabajo.',
  'Sinto-me à vontade para conversar com meu supervisor para mudar coisas estressantes sobre meu trabalho (por exemplo, carga de trabalho, folgas, mudança de tarefas/responsabilidades).': 'Me siento libre para hablar con mi supervisor para cambiar cosas estresantes sobre mi trabajo (carga de trabajo, días libres, cambio de tareas/ responsabilidades).',
  'Sinto que realizei muitas coisas valiosas no meu trabalho.': 'Siento que he realizado muchas acciones valiosas en mi trabajo.',
  'Sinto que tenho um papel e responsabilidades claramente definidos e que garantem que meu dia corra bem.': 'Siento que tengo un papel y responsabilidades claramente definidas y que garantizan que mi rutina diaria vaya bien.',
  'Sinto orgulho de que as pessoas entendam o valor do meu papel e da minha contribuição para a organização.': 'Me siento orgulloso cuando la gente entiende el valor de mi papel y mi contribución a la organización.',
  'Sinto que estou no controle de minhas prioridades, definindo o faço e quando faço.': 'Siento que estoy en control de mis prioridades, definiendo lo que hago y cuando lo hago.',
  'Minha carga de trabalho aumentou, distraindo meu foco da tarefa pela qual sou responsável.': 'Mi carga de trabajo ha aumentado, distrayendo mi enfoque de la tarea de la que soy responsable.',
  'Sinto que minha carga de trabalho está aumentando muito além do que posso lidar.': 'Siento que mi carga de trabajo está aumentando mucho más de lo que puedo manejar.',
  'Eu gasto tempo procurando uma nova posição no trabalho ou em outra empresa.': 'Paso tiempo buscando una nueva vacante en el trabajo o en otra empresa.',
  'Sinto que meu equilíbrio entre trabalho e vida pessoal está sob controle.': 'Siento que mi equilibrio entre el trabajo y la vida personal está bajo control.',
  'Nos últimos 6 meses, sinto que alcancei resultados no meu trabalho e minhas relações pessoais estão ótimas.': 'En los últimos 6 meses, siento que he logrado resultados en mi trabajo y mis relaciones personales están bien.',
  'Durante os últimos 30 dias, com que frequência você se sentiu cansado sem uma boa razão para isso?': 'Durante los últimos 30 días, ¿con qué frecuencia se ha sentido cansado sin una buena razón para ello?',
  'Durante os últimos 30 dias, com que frequência você se sentiu nervoso?': 'Durante los últimos 30 días, ¿con qué frecuencia se ha sentido nervioso?',
  'Durante os últimos 30 dias, com que frequência você se sentiu tão nervoso que nada conseguiu acalmá-lo?': 'Durante los últimos 30 días, ¿con qué frecuencia se ha sentido tan nervioso que nada le ha calmado?',
  'Durante os últimos 30 dias, com que frequência você se sentiu sem esperança?': 'Durante los últimos 30 días, ¿con qué frecuencia se ha sentido sin esperanza?',
  'Durante os últimos 30 dias, com que frequência você se sentiu inquieto?': 'Durante los últimos 30 días, ¿con qué frecuencia se ha sentido inquieto?',
  'Durante os últimos 30 dias, com que frequência você se sentiu tão inquieto que não conseguia ficar parado?': 'Durante los últimos 30 días, ¿con qué frecuencia se ha sentido más triste de lo normal?',
  'Durante os últimos 30 dias, com que frequência você se sentiu mais triste do que o normal?': 'Durante los últimos 30 días, ¿con qué frecuencia se ha sentido más triste de lo habitual?',
  'Durante os últimos 30 dias, com que frequência você sentiu que qualquer coisa era um esforço?': 'Durante los últimos 30 días, ¿con qué frecuencia sintió que cualquier acción era un esfuerzo?',
  'Durante os últimos 30 dias, com que frequência você se sentiu tão triste que nada poderia animá-lo?': 'Durante los últimos 30 días, ¿con qué frecuencia se ha sentido tan triste que nada podía animarle?',
  'Durante os últimos 30 dias, com que frequência você parou de fazer coisas que gosta, como esportes ou reuniões sociais?': 'Durante los últimos 30 días, ¿con qué frecuencia ha dejado de hacer cosas que le gustan, como deportes o reuniones sociales?',
  'During the past 30 days, how often have you stopped doing things you enjoy, such as sports or social gatherings? ': 'En los últimos 6 meses, perdí o gané peso sin intentarlo, o mi apetito cambió.',
  'Nos últimos 6 meses, parei de me divertir fazendo coisas que costumava gostar.': 'En los últimos seis meses, dejé de divertirme haciendo cosas que me gustaban.',
  'Nos últimos 6 meses, você evitou propositalmente entrar em situações nas quais pudesse sentir estresse ou ataque de pânico?': 'En los últimos 6 meses, ¿evitó deliberadamente entrar en situaciones en las que pudiera sentir estrés o ataques de pánico?',
  'Eu ganho o suficiente para que a minha renda não seja uma preocupação na minha vida pessoal.': 'Gano lo suficiente para que mis ingresos no sean una preocupación en mi vida personal.',
  'Eu ganho o suficiente para economizar 3 meses de despesas para uma emergência.': 'Gano lo suficiente para ahorrar 3 meses de gastos para una emergencia.',
  'Eu me preocupo em não ter dinheiro suficiente para pagar minhas despesas pessoais.': 'Me preocupa no tener suficiente dinero para pagar mis gastos personales.',
  'Nos últimos 6 meses, perdi ou ganhei peso sem tentar, ou meu apetite mudou.': 'En los últimos seis meses, he perdido o aumentado de peso sin intentarlo, o mi apetito ha cambiado.',
  'Câncer de Próstata': 'Cáncer de Próstata',
  'Algum parente de 1° grau (pai, irmão ou filho) já teve câncer de próstata?': '¿Algún pariente de 1er grado (padre, hermano o hijo) ha tenido cáncer de próstata?',
  'Você respondeu que um parente de 1º grau (pai, irmão ou filho) já teve câncer de próstata. Está correto?' : 'Usted respondió que un pariente de 1er grado (padre, hermano o hijo) ya tuvo cáncer de próstata. ¿Está correcto?',
  'Sente dificuldade para urinar ou percebeu que o jato de urina está mais fraco?': '¿Tiene problemas para orinar o notó que el chorro de orina está más débil?', 
  'Percebeu saída de sangue na urina?': '¿Notó sangre en la orina?',
  'Ao urinar, tem a sensação que não consegue esvaziar a bexiga por completo?': 'Al orinar, ¿siente que no puede vaciar completamente su vejiga?',
  'Ás vezes': 'Algunas veces',
  'Compartilhar Diagnóstico': 'Compartir Diagnóstico',
  'Especifique o problema cardíaco:' : 'Especifique el problema cardíaco:',
  'Em geral, indivíduos com Diabetes tipo 1 manifestam o quadro nas duas primeiras décadas de vida, e precisam fazer uso de insulina. O Diabetes tipo 2 surge mais tarde, normalmente associado ao excesso de peso. Embora possa ser necessário uso de insulina, o' : 'En general, las personas con diabetes tipo 1 manifiestan la afección en las dos primeras décadas de vida y necesitan usar insulina. La diabetes tipo 2 viene después, generalmente asociada con el sobrepeso. Aunque puede ser necesario el uso de insulina, el control generalmente se realiza con medicamentos orales.',
  'Sistólica é a mais alta. Exemplo: se a sua pressão é 12/8, então é a 12. Nesse caso, preencha 120. Se for 12,5, preencha 125.' : "La sistólica es la más alta. Ejemplo: si su presión es 12/8, entonces es 12. En ese caso, complete 120. Si es 12.5, complete 125",

  'Não Sou um robô': 'No soy un robot',
  'Alto risco' : 'Alto riesgo',
  'Baixo risco' : 'Bajo riesgo',
  'Baixo Risco' : 'Bajo riesgo',
  'Médio risco': 'Medio riesgo',
  'Médio Risco': 'Medio riesgo',
  'Sem Risco': 'Sin riesgos',
  'Risco normal' : "Riesgo normal",
  'Alto Risco': 'Alto riesgo',
  'Risco abaixo da média': 'Riesgo por debajo de la media',
  'Copiado para a área de transferência' : 'Copiado en el área de descarga',

  "variable.WORK.Well.INDIVIDUAL.Well" : "Sus respuestas indican que no experimenta síntomas relacionados con la ansiedad o el estrés en el lugar de trabajo. A nivel personal, tus respuestas revelan que estás sano, sin síntomas de estrés o ansiedad general.",
  "variable.WORK.Well.INDIVIDUAL.Low" : "Tus respuestas indican que no experimentas síntomas relacionados con la ansiedad o el estrés en el trabajo. A nivel personal, tus respuestas revelan que estás sano, con muy pocos síntomas de estrés o ansiedad general.",
  "variable.WORK.Low.INDIVIDUAL.Well" : "Sus respuestas indican que no experimenta síntomas importantes relacionados con la ansiedad o el estrés en el trabajo. A nivel Personal, tus respuestas revelan que estás sano, sin síntomas de estrés o ansiedad general.",
  "variable.WORK.Well.INDIVIDUAL.Medium" : "Sus respuestas indican que no experimenta síntomas relacionados con la ansiedad o el estrés en el lugar de trabajo. A nivel Personal, tus resultados indican que estás experimentando algunos síntomas moderados relacionados con el estrés o la ansiedad general.",
  "variable.WORK.Low.INDIVIDUAL.Low" : "Tus respuestas indican que no experimentas síntomas importantes relacionados con la ansiedad o el estrés en el trabajo. A nivel personal, tus respuestas revelan que estás sano, con muy pocos síntomas de estrés o ansiedad general.",
  "variable.WORK.Medium.INDIVIDUAL.Well" : "Tus respuestas indican que estás preocupado y crees que puedes necesitar apoyo laboral, por lo que te recomendamos hablar con tu empleador o consultar a un profesional de la salud mental para una evaluación completa. A nivel Personal, tus respuestas revelan que estás sano, sin síntomas de estrés o ansiedad general.",
  "variable.WORK.Well.INDIVIDUAL.High" : "Sus respuestas indican que no experimenta síntomas relacionados con la ansiedad o el estrés en el lugar de trabajo. Personalmente, parece que el estrés está afectando tu vida. La mayoría de las personas se sienten estresadas en algún momento y sus síntomas son experiencias comunes para la mayoría de la población.",
  "variable.WORK.Low.INDIVIDUAL.Medium" : "Sus respuestas indican que no experimenta síntomas importantes relacionados con la ansiedad o el estrés en el trabajo. A nivel Personal, tus resultados indican que estás experimentando algunos síntomas moderados relacionados con el estrés o la ansiedad general.",
  "variable.WORK.Medium.INDIVIDUAL.Low" : "Tus respuestas indican que estás preocupado y crees que puedes necesitar apoyo laboral, por lo que te recomendamos hablar con tu empleador o consultar a un profesional de la salud mental para una valoración completa. A nivel personal, tus respuestas revelan que estás sano, con muy pocos síntomas de estrés o ansiedad general.",
  "variable.WORK.High.INDIVIDUAL.Well" : "El lugar de trabajo puede ser muy desafiante. Sus respuestas indican que es posible que necesite apoyo. La buena noticia es que siempre hay una manera. Eres más fuerte que cualquier cosa en tu camino, y existen algunas estrategias que puedes utilizar para ayudarte a controlar tu ansiedad por el trabajo. A nivel personal, tus respuestas revelan que estás saludable, sin síntomas de estrés o ansiedad general.",
  "variable.WORK.Low.INDIVIDUAL.High" : "Sus respuestas indican que no experimenta síntomas importantes relacionados con la ansiedad o el estrés en el trabajo. Personalmente, parece que el estrés está afectando tu vida. La mayoría de las personas se sienten estresadas en algún momento y sus síntomas son experiencias comunes para la mayoría de la población.",
  "variable.WORK.Medium.INDIVIDUAL.Medium" : "Tus respuestas indican que estás preocupado y crees que puedes necesitar apoyo laboral, por lo que te recomendamos hablar con tu empleador o consultar a un profesional de la salud mental para una evaluación completa. A nivel Personal, tus resultados indican que estás experimentando algunos síntomas moderados relacionados con el estrés o la ansiedad general.",
  "variable.WORK.High.INDIVIDUAL.Low" : "El lugar de trabajo puede ser muy desafiante. Sus respuestas indican que es posible que necesite apoyo. La buena noticia es que siempre hay una manera. Eres más fuerte que cualquier cosa en tu camino y hay algunas estrategias que puedes usar para ayudarte a controlar tu ansiedad por el trabajo. A nivel personal, tus respuestas revelan que estás saludable, con muy pocos síntomas de estrés o ansiedad general.",
  "variable.WORK.Medium.INDIVIDUAL.High" : "Tus respuestas indican que estás preocupado y crees que puedes necesitar apoyo laboral, por lo que te recomendamos hablar con tu empleador o consultar a un profesional de la salud mental para una evaluación completa. Personalmente, parece que el estrés está afectando tu vida. La mayoría de las personas se sienten estresadas en algún momento y sus síntomas son experiencias comunes para la mayoría de la población.",
  "variable.WORK.High.INDIVIDUAL.Medium" : "El lugar de trabajo puede ser muy desafiante. Sus respuestas indican que es posible que necesite apoyo. La buena noticia es que siempre hay una manera. Eres más fuerte que cualquier cosa en tu camino y hay algunas estrategias que puedes usar para ayudarte a controlar tu ansiedad por el trabajo. A nivel personal, tus resultados indican que estás experimentando algunos síntomas moderados relacionados con el estrés o la ansiedad general.",
  "variable.WORK.High.INDIVIDUAL.High" : "El lugar de trabajo puede ser muy desafiante. Sus respuestas indican que es posible que necesite apoyo. La buena noticia es que siempre hay una manera. Eres más fuerte que cualquier cosa en tu camino y hay algunas estrategias que puedes usar para ayudarte a controlar tu ansiedad por el trabajo. Personalmente, parece que el estrés está afectando tu vida. La mayoría de las personas se sienten estresadas en algún momento y sus síntomas son experiencias comunes para la mayoría de la población.",

  'Câncer de Próstata (Masculino)' : 'Cáncer de próstata (masculino)',
  'Câncer de Útero (Feminino)' : 'Cáncer de útero (femenino)',
  'Histórico Médico (Masculino)' : 'Historial médico (masculino)',
  'Histórico Médico (Feminino)' : 'Historial médico (femenino)',
  'Câncer de Mama (Feminino)' : 'Cáncer de mama (femenino)',
  'Câncer de Pulmão (Unissex)' : 'Cáncer de pulmón (unisex)',
  'Câncer de Cólon (Unissex)' : 'Cáncer de colon (unisex)',
  'Mental (Unissex)' : 'Mental (unisex)',
  'Cardio (Unissex)': 'Cardio (unisex)', 
  
    /* SUBDIAGNOSTICS */
  'Control': 'Control',
  'Demands': 'Demandas',
  'Support': 'Suporte',
  'Relationship': 'Relación',
  'Role': 'Reglas',
  'Change': 'Cambios',
  'K10': 'K10',

  //Tour
  "tour.greetings.title": "¿Prevenimos juntos?",
  "tour.greetings": "¡Estamos encantados de tenerle aquí!",
  "tour.greetings.message": "¡El sistema Previneo te ayuda a dar el primer paso hacia la prevención de forma rápida y sin salir de casa!",
  "tour.first.step": "El primer paso es rellenar la historia clínica. Esta información será importante para su resultado.",
  "tour.estimated.time": "Tiempo estimado de finalización: 1 a 2 min",
  "tour.second.step": "Ahora solo completa tu entrevista digital. Si tienes otras entrevistas, sigue los mismos pasos.",
  "tour.third.step": "En el menú encontrarás las opciones: volver a la pantalla de inicio, cerrar sesión en el sistema y acceder a tu cuenta.",
  "tour.last.step.title": "¿llenamos?",
  "tour.last.step": "No olvides que tus resultados están dentro de cada entrevista digital, y puedes acceder a más información en \"Detalles de la Entrevista Digital\".",
  "tour.finished": "Comienzo",
  "tour.start": "Vamos a empezar",
  "tour.skip": "Omitir recorrido",
  "tour.next.step": "Próximo",

  'Todos os Riscos' : 'Todos los riesgos',
  'Masculino' : 'Masculino',
  'Feminino' : 'Feminino',
  'Somente Exames':'Solo examenes',
  'Somente Sintoma':'Solo sintomas',
  'Alto Risco + Exame':'Alto Riesgo + Examen',
  'Alto Risco + Sintoma':'Alto Riesgo + Síntoma',
  'Exame + Sintoma':'Examen + Síntoma',
  'Alto Risco + Exame + Sintoma': 'Alto Riesgo + Examen + Síntoma',
  'plugin.OverrideMultiSlect':{
    "allItemsAreSelected": "Todos",
    "clearSearch": "Borrar búsqueda",
    "clearSelected": "Borrar seleccionado",
    "noOptions": "Sin opciones",
    "search": "Búsqueda",
    "selectAll": "Seleccionar todo",
    "selectAllFiltered": "Seleccionar todo (filtrado)",
    "selectSomeItems": "Seleccione...",
    "create": "Crear",
  }
}
