import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import QuestionnaireScreen from 'questionnaire/components/QuestionnaireScreen'
import FormLogin from 'admin/forms/FormLogin'
import Auth from 'admin/containers/Auth'
import getRoute from 'utils/getRoute'
import routes from 'routes'
import { getAuth, getData } from 'store/ducks/questionnaire'

function LoginScreen ({ auth, data }) {
  return (
    <QuestionnaireScreen title='Login' personal guest>
      <Auth>
        {props => {
          if ((props.isAuthenticated && props.type === 'patients') || !data.roteiro.url_restrita) {
            const to = getRoute(routes.questionnaire.categories, auth)
            return <Redirect to={to} />
          }

          return (
            <FormLogin
              onSubmit={data => props.login(data.email, data.password, 'patients', null)}
              isSubmitting={props.isSubmitting}
              patient
              hideForgetPasswordLink
              hideNotifications
            />
          )
        }}
      </Auth>
    </QuestionnaireScreen>
  )
}

const mapStateToProps = state => ({
  auth: getAuth(state),
  data: getData(state)
})

export default connect(
  mapStateToProps
)(LoginScreen)
