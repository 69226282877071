/**
 * Dependencies.
 */

import update from 'immutability-helper'
import { isEmpty } from 'ramda'
import { Auth } from 'store/api'
import createActionTypes from 'store/utils/createActionTypes'
import createReducer from 'store/utils/createReducer'
import Session from 'modules/Session'
import { getTokenKey } from 'store/api/Auth'

/**
 * Action Types.
 */

export const actionTypes = createActionTypes('auth', [
  'LOGIN',
  'LOGIN_PENDING',
  'LOGIN_FULFILLED',
  'LOGIN_REJECTED',

  'LOGOUT',
  'LOGOUT_FULFILLED'
])

/**
 * Initial State.
 */

export const initialState = {
  isSubmitting: false,
  user: {},
  permissions: [],
  isSuperAdmin: false,
  type: '',
  error: {}
}

/**
 * Reducer.
 */

export default createReducer(initialState, {
  [actionTypes.LOGIN_PENDING] (state) {
    return update(state, {
      isSubmitting: { $set: true }
    })
  },

  [actionTypes.LOGIN_FULFILLED] (state, { payload: { data }, meta: { type } }) {
    return update(state, {
      isSubmitting: { $set: false },
      user: { $set: data },
      permissions: { $set: data.permissions.map(p => p.nome) },
      isSuperAdmin: { $set: data.admin },
      type: { $set: type },
      error: { $set: {} }
    })
  },

  [actionTypes.LOGIN_REJECTED] (state, { payload }) {
    return update(state, {
      isSubmitting: { $set: false },
      error: { $set: payload }
    })
  },

  [actionTypes.LOGOUT] (state) {
    const { type } = state
    Session.remove('authType')
    Session.remove(getTokenKey(type))
    Session.remove('finishedTour')
    return { ...initialState, type: type }
  },
  
  [actionTypes.LOGOUT_FULFILLED] (state) {
    const { type } = state
    Session.remove('authType')
    Session.remove(getTokenKey(type))
    Session.remove('finishedTour')
    return { ...initialState, type: type }
  }
})

/**
 * Action Creators.
 */

export const login = (email, password, type, captcha, method) => (dispatch, getState) => {
  if (isAuthenticated(getState())) return

  dispatch({
    type: actionTypes.LOGIN,
    payload: Auth.login(email, password, type, captcha, method),
    meta: {
      type,
      notifications: {
        errors: true
      }
    }
  })
}

export const logout = type => {
  Session.remove(getTokenKey(type))
  return {
    type: actionTypes.LOGOUT
  }
}

export const deleteData = () => (dispatch, getState) => {
  if (!isAuthenticated(getState())) return
  const { auth: { type }} = getState()
  
  dispatch({
    type: actionTypes.LOGOUT,
    payload: Auth.deleteData(type)
  })
}

/**
 * Selectors.
 */

export const getAuthenticatedUser = state =>
  state.auth.user

export const isAuthenticated = state =>
  !isEmpty(getAuthenticatedUser(state))

export const isSubmitting = state =>
  state.auth.isSubmitting

export const getError = state =>
  state.auth.error

export const getAuthType = state =>
  state.auth.type
