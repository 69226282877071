import Spinner from 'components/Spinner'
import Request from 'modules/Request'
import React, { Component } from 'react'
import routes from 'routes'
import withAuth from 'store/utils/withAuth'

class Logout extends Component {
  state = {
    isLoading: true
  }

  componentDidMount(){
    Request.auth({
      method: 'GET',
      url: '/api/logoff'
    }).then(
      res => {
        this.setState({
          isLoading: false
        })
        return this.props.auth.logout(this.props.type)
      }
      ).catch(
        res => {
        this.setState({
          isLoading: false
        })
        return this.props.auth.logout(this.props.type)
      }
    );
  }

  render () {
    if(this.state.isLoading){
      return <Spinner />
    }
    window.location.replace('https://www.previneo.com.br')
    return <div></div>
  }

  static defaultProps = {
    type: 'usersAdmWeb',
    redirectTo: routes.site.home
  }
}

export default withAuth(Logout)
