import { createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import promiseMiddleware from 'redux-promise-middleware'
import feedbackMiddleware from 'store/utils/feedbackMiddleware'
import rootReducer from './reducer'

export default function configureStore () {
  return createStore(
    rootReducer,
    compose(
      applyMiddleware(thunk),
      applyMiddleware(promiseMiddleware()),
      applyMiddleware(feedbackMiddleware),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : v => v
    )
  )
}
