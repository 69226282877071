import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setLang } from 'store/ducks/localization'

function Localization ({ children, ...props }) {
  return children(props)
}

Localization.propTypes = {
  /** Lang atual */
  lang: PropTypes.string.isRequired,

  /** Action creator para alterar o lang */
  setLang: PropTypes.func.isRequired,

  /** Children render function */
  children: PropTypes.func.isRequired
}

export default connect(
  ({ localization }) => ({ lang: localization.lang }),
  { setLang }
)(Localization)
