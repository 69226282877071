/**
 * Dependencies.
 */

import update from 'immutability-helper'
import { Register, Auth } from 'store/api'
import createActionTypes from 'store/utils/createActionTypes'
import createReducer from 'store/utils/createReducer'
import { actionTypes as authActionTypes } from 'store/ducks/auth'

/**
 * Action Types.
 */

export const actionTypes = createActionTypes('register', [
  'CUPOM',
  'CUPOM_PENDING',
  'CUPOM_FULFILLED',
  'CUPOM_REJECTED',

  'REGISTER',
  'REGISTER_PENDING',
  'REGISTER_FULFILLED',
  'REGISTER_REJECTED',
])

/**
 * Initial State.
 */

export const initialState = {
  isSubmitting: false,
  isValidCode: false,
  termsOfService: null,
  registerSuccess: false,
  companyCode: null,
  company: {},
  type: '',
  error: {}
}

/**
 * Reducer.
 */

export default createReducer(initialState, {
  [actionTypes.CUPOM_PENDING] (state, { meta: { companyCode }}) {
    return update(state, {
      isSubmitting: { $set: true },
      companyCode: { $set: companyCode },
    })
  },

  [actionTypes.CUPOM_REJECTED] (state, { payload: { data }, meta: { type } }) {
    return update(state, {
      isSubmitting: { $set: false },
      isValidCode: { $set: false },
      type: { $set: type },
      error: { $set: {} }
    })
  },

  [actionTypes.CUPOM_FULFILLED] (state, { payload: { response }, meta: { type, companyCode }}) {
    return update(state, {
      isSubmitting: { $set: false },
      isValidCode: { $set: true },
      type: { $set: type },
      companyCode: { $set: companyCode },
      termsOfService: { $set: response.company.termos_uso },
      company: { $set: response.company },
      error: { $set: {} }
    })
  },

  [actionTypes.REGISTER_PENDING] (state) {
    return update(state, {
      isSubmitting: { $set: true }
    })
  },

  [actionTypes.REGISTER_REJECTED] (state, { payload: { data }, meta: { type } }) {
    return update(state, {
      isSubmitting: { $set: false },
      registerSuccess: { $set: false }
    })
  },

  [actionTypes.REGISTER_FULFILLED] (state, { payload, meta: { type } }) {
    return update(state, {
      isSubmitting: { $set: false },
      isValidCode: { $set: true },
      registerSuccess: { $set: true },
      type: { $set: type },
      error: { $set: {} }
    })
  },
})

/**
 * Action Creators.
 */

export const validateCupom = (company_code, captcha) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.CUPOM,
    payload: Register.validate_company_code(company_code, captcha),
    meta: {
      notifications: {
        errors: true
      },
      companyCode: company_code
    }
  })
}

export const register = (formData) => (dispatch, getState) => {
  const { register: { companyCode } } = getState()
  dispatch({
    type: actionTypes.REGISTER,
    payload: Register.register({ ...formData, company_id: companyCode}),
    meta: {
      notifications: {
        errors: true
      },
      formData: formData
    }
  }).then(data => {
    if(data.action.type === actionTypes.REGISTER_FULFILLED){
      const { email, password, method, g_token, ms_token } = formData
      const type = "usersAdmWeb"

      let _password = g_token ? g_token : (ms_token ? ms_token : password);

      dispatch({
        type: authActionTypes.LOGIN,
        payload: Auth.login(email, _password, type, null, method),
        meta: {
          type,
          notifications: {
            errors: true
          }
        }
      })
    }
  })
}

/**
 * Selectors.
 */

export const isSubmitting = state => 
  state.register.isSubmitting

export const isValidCode = state => 
  state.register.isValidCode

export const getError = state =>
  state.register.error


