import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import mediaQuery from 'utils/mediaQuery'

const FormGridStyled = styled.div`
  ${mediaQuery.greaterThan('medium')`
    display: flex;
    align-items: ${props => props.alignItems};
    margin: 0 -10px;

    > * {
      width: 100%;
      margin: 0 10px;
    }
  `}

  ${mediaQuery.lessThan('medium')`
    > * + * {
      margin-top: 25px;
    }
  `}
`

export default function FormGrid (props) {
  return <FormGridStyled className={'FormGrid'} {...props} />
}

FormGrid.propTypes = {
  /** Alinhamento vertical */
  alignItems: PropTypes.oneOf([
    'flex-start', 'center', 'flex-end'
  ])
}

FormGrid.defaultProps = {
  alignItems: 'flex-start'
}
