import Request from 'modules/Request'
import createApi from 'store/utils/createApi'

export default createApi('/api/empresas', methods => ({
  fetchNodes (id) {
    return Request.auth({
      method: 'GET',
      url: `/api/empresas/${id}/filiais`
    })
      .then(({ body: { data } }) => {
        return data.map(node => ({
          id: node.id,
          name: node.nome,
          children: [],
          data: node
        }))
      })
  },

  create: data => methods.create(data)
    .then(({ data }) => {
      return {
        id: data.id,
        name: data.nome,
        data
      }
    }),

  update: (id, data) => methods.update(id, data)
    .then(({ data }) => {
      return {
        id: data.id,
        name: data.nome,
        data
      }
    })
}))
