import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Text from 'components/Text'
import { lighten, darken } from 'polished'

const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.theme.colors.formBorder};
  height: 50px;
  color: ${props => props.color !== props.theme.colors.hm ? darken(0.40, props.color) : lighten(0.10, props.color)};

  ${props => props.readOnly && css`
    opacity: .6;
    cursor: not-allowed;
  `}

  ${props => props.checked && css`
    background-color: ${props => props.theme.colors[props.color] !== undefined ? props.theme.colors[props.color] : props.color};
    color: ${props => props.color !== props.theme.colors.hm ? darken(0.40, props.color) : lighten(0.10, props.color)};
  `}
`

const Container = styled.label`
  position: relative;
  width: 100%;
  cursor: pointer;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
  }

  & + & ${Option} {
    border-left: none;
  }

  &:first-child ${Option} {
    border-radius: 5px 0 0 5px;
  }

  &:last-child ${Option} {
    border-radius: 0 5px 5px 0;
  }
`

export default function LargeOption ({ label, value, checked, onChange, color, readOnly = false }) {
  return (
    <Container>
      <input
        type='radio'
        name='option'
        value={value}
        checked={checked}
        onChange={() => onChange(value)}
        disabled={readOnly}
      />
      <Option checked={checked} color={color} readOnly={readOnly}>
        <Text component='span' color='inherit' size='18px'>{label}</Text>
      </Option>
    </Container>
  )
}

LargeOption.propTypes = {
  /** Label */
  label: PropTypes.string.isRequired,

  /** Valor */
  value: PropTypes.any.isRequired,

  /** Input está checado? */
  checked: PropTypes.bool.isRequired,

  /** Handler quando o input for marcado */
  onChange: PropTypes.func.isRequired,

  /** Modo somente leitura */
  readOnly: PropTypes.bool
}
