import objectPath from 'object-path'
import { add as push } from 'store/ducks/notifications'

export default function feedback (store) {
  return next => action => {
    const notifications = objectPath.get(action, 'meta.notifications')

    if (!notifications || !action.payload) {
      return next(action)
    }

    if(action.payload.status === 401){
      store.dispatch({
        type: 'auth/LOGOUT',
      })
    }

    if (notifications.errors && action.error) {
      const text = objectPath.get(action.payload, 'res.body.message', { id: 'feedback.error' })
      const errors = objectPath.get(action.payload, 'res.body.errors')
      store.dispatch(push({
        type: 'error',
        text,
        items: errors ? Object.values(errors) : undefined,
        notificationsId: notifications.notificationsId
      }))
    }

    if (notifications.success && !action.error) {
      const defaultMessage = notifications.defaultSuccessMessage || { id: 'feedback.success' }
      const text = objectPath.get(action.payload, 'res.body.message', defaultMessage)
      store.dispatch(push({
        type: 'success',
        text,
        notificationsId: notifications.notificationsId
      }))
    }

    return next(action)
  }
}
