import React from 'react';
import { injectIntl } from 'react-intl';
import Resource from 'containers/Resource';
import Auth from '../../../admin/containers/Auth/Auth';
import Text from 'components/Text';
import DashbordCompanies from '../../page/DashbordCompanies/DashbordCompanies';

function DashbordAnamneseScreen({ intl, ...props }) {
  return (
    <Auth>
      {props =>
        props.permited(props, 'access_dashboard') ? (
          <Resource resource="Dashboard">
            {props => (
              <DashbordCompanies
                onSubmit={async value => {
                  await props.fetchAll(value);
                }}
                intl={intl}
                {...props}
              />
            )}
          </Resource>
        ) : (
          <Text>
            {intl.formatMessage({ id: 'admin.common.permission.page' })}
          </Text>
        )
      }
    </Auth>
  );
}

export default injectIntl(DashbordAnamneseScreen);
