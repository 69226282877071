/**
 * Dependencies.
 */

import nanoid from 'nanoid'
import update from 'immutability-helper'
import createActionTypes from 'store/utils/createActionTypes'
import createReducer from 'store/utils/createReducer'

/**
 * Action Types.
 */

export const actionTypes = createActionTypes('notifications', [
  'ADD',
  'REMOVE',
  'CLEAR',
  'CLEAR_ALL'
])

/**
 * Initial State.
 */

export const initialState = {
  ALL: []
}

/**
 * Reducer.
 */

export default createReducer(initialState, {
  [actionTypes.ADD] (state, { payload }) {
    return update(state, {
      [payload.notificationsId]: {
        $apply: values => Array.isArray(values)
          ? values.concat(payload.message)
          : [ payload.message ]
      }
    })
  },

  [actionTypes.REMOVE] (state, { payload: { id, notificationsId } }) {
    return update(state, {
      [notificationsId]: {
        $apply: values => Array.isArray(values) && values.length > 0
          ? values.filter(message => message.id !== id)
          : []
      }
    })
  },

  [actionTypes.CLEAR] (state, { payload: { notificationsId } }) {
    return update(state, {
      [notificationsId]: { $set: [] }
    })
  },

  [actionTypes.CLEAR_ALL] (state) {
    return {
      ALL: []
    }
  }
})

/**
 * Action Creators.
 */

export const add = ({ type, text, items, notificationsId = 'ALL' }) => ({
  type: actionTypes.ADD,
  payload: {
    message: {
      id: nanoid(),
      type,
      text,
      items
    },
    notificationsId
  }
})

export const remove = ({ id, notificationsId = 'ALL' }) => ({
  type: actionTypes.REMOVE,
  payload: { id, notificationsId }
})

export const clear = notificationsId => ({
  type: actionTypes.CLEAR,
  payload: { notificationsId }
})

export const clearAll = () => ({
  type: actionTypes.CLEAR_ALL
})

/**
 * Selectors.
 */

export const getMessages = (state, notificationsId) =>
  state.notifications[notificationsId] || []
