import { format as formatDate, parse } from 'date-fns'
import pt from 'date-fns/locale/pt'

export function format (date, formatStr = 'DD/MM/YYYY') {
  return formatDate(date, formatStr, { locale: pt })
}

export function parseDate (value) {
  if (value) {
    const match = value.match(/^(\d{2})\/(\d{2})\/(\d{4})$/)
    return match
      ? parse(`${match[3]}-${match[2]}-${match[1]}`)
      : null
  }
}
