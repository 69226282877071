import Request from 'modules/Request'
import { form } from 'popsicle'

export default {
  create (file, params = {}) {
    return Request.auth({
      method: 'POST',
      url: '/api/media',
      body: form({ file }),
      query: params
    }).then(res => res.body)
  },

  remove (medianame, params) {
    console.log(medianame)
    return Request.auth({
      method: 'DELETE',
      url: '/api/media',
      body: {medianame},
      query: params
    }).then(res => res.body)
  }
}
