import Request from 'modules/Request';
import createApi from '../utils/createApi';

export default createApi( '/api/register/validate', methods => ({
  async validate_company_code (company_code, captcha) {
    return Request.api({
      method: 'POST',
      url: '/api/register/validate/company_code',
      body: {
        company_code: company_code,
        vrecaptcha: captcha
      }
    })
      .then(res => {
        const response = res.body;
        return { response, companyCode: company_code}
      })
      .catch(err => {
        throw err
      })
  },
  async register(formData, route ='/api/register/user'){
    return Request.api({
      method: 'POST',
      url: route,
      body: formData
    })
      .then(res => res.body)
      .catch(err => {
        throw err
      })
  },

  fetchApi (params) {
    return Request.api({
      method: 'POST',
      url: '/api/register/validate',
      body: params
    }).then(res => res.body)
      .catch(err => {
        console.log(err)
        throw err
      })
  },
}))
