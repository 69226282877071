import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import VerticalSpacer from 'components/VerticalSpacer'
import Alert from 'components/Alert'
import { remove, getMessages, clear } from 'store/ducks/notifications'

class Notifications extends Component {
  componentDidUpdate (prevProps) {
    if (this.props.messages.length > prevProps.messages.length && this.props.scrollToTop) {
      window.scrollTo(0, 0)
    }

    if (this.props.location !== prevProps.location) {
      this.props.clear(this.props.notificationsId)
    }
  }

  componentWillUnmount () {
    this.props.clear(this.props.notificationsId)
  }

  render () {
    if (!this.props.messages.length) {
      return null
    }

    return (
      <VerticalSpacer space={15}>
        {this.props.messages.map(message => (
          <Alert
            key={message.id}
            type={message.type}
            message={message.text}
            items={message.items}
            onRemoveClick={() => this.props.remove({ id: message.id, notificationsId: this.props.notificationsId })}
          />
        ))}
      </VerticalSpacer>
    )
  }

  static propTypes = {
    /** ID das notificações para serem exibidas */
    notificationsId: PropTypes.string,

    /** Scrolla para o topo quando houver uma mensagem nova */
    scrollToTop: PropTypes.bool.isRequired,

    /** Mensagens das notificações */
    messages: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
      ]).isRequired
    })),

    /** Action para limpar as notificações de terminado ID */
    clear: PropTypes.func.isRequired,

    /** Callback para remover a mensagem */
    remove: PropTypes.func.isRequired
  }

  static defaultProps = {
    notificationsId: 'ALL',
    scrollToTop: true
  }
}

const mapStateToProps = (state, { notificationsId = 'ALL' }) => ({
  messages: getMessages(state, notificationsId)
})

export default withRouter(
  connect(
    mapStateToProps,
    { remove, clear }
  )(Notifications)
)
