import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import media from 'utils/mediaQuery'

const generateBaseStyles = spaceValue => css`
  > *:not(:first-child) {
    margin-top: ${spaceValue}px;
  }
`

const generateStyles = space => {
  return Object.keys(space).map(breakpoint => media.greaterThan(breakpoint)`
    ${generateBaseStyles(space[breakpoint])}
  `).join('\n').replace(/,/g, '')
}

const StyledVerticalSpacer = styled.div`
  ${props => isNaN(props.space) ? generateStyles(props.space) : generateBaseStyles(props.space)}
`

export default function VerticalSpacer (props) {
  return <StyledVerticalSpacer {...props} />
}

VerticalSpacer.propTypes = {
  /** Valor do espaçamento entre os componentes filhos. Você pode passar um número fixo ou um objeto com os breakpoints como chave e o space como valor */
  space: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object
  ]).isRequired
}

VerticalSpacer.defaultProps = {
  space: 25
}
