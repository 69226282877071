import React from "react";
import Button from "components/Button";
import { injectIntl } from 'react-intl';
import swal from "sweetalert";
import "./style.css";

function ButtonCopy({ intl, copyTable, ...props }) {
  const handleButtonClick = _copyValues => {
    // Copiar  elemento
    try {
      const headers = Object.keys(_copyValues[0]).join("  ,  ");
      const columns = _copyValues.map(item =>
        Object.values(item).join("  ,  ")
      );
      const textFormat = headers + "\n" + columns.join("\n");

      if (navigator.clipboard) {
        navigator.clipboard.writeText(textFormat).then(
          function() {
            swal({
              title: intl.formatMessage({ id: 'Copiado para a área de transferência' }),
              button: "Ok",
              timer: "1000"
            });
          },
          function(err) {
            console.error("Async: Could not copy text: ", err);
          }
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Button
      className="button_copy"
      color="secondaryGreen"
      textColor="text"
      blockAtBreakpoint="small"
      onClick={() => handleButtonClick(copyTable)}
    >
      {intl.formatMessage({ id: 'admin.common.copy' })}
    </Button>
  );
}

export default injectIntl(ButtonCopy);
