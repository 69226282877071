import styled from 'styled-components';

export const Container = styled.div`
  z-index: 9;

  .Select--multi {
    .Select-multi-value-wrapper {
      /* max-height: 65px;
            min-height: 38px;
            overflow-y: auto;
            margin-bottom: -8px;*/
    }
  }
`;

export const ContainerInd = styled.div`
  z-index: 8;
`;

export const ContainerDisplayFlex = styled.div`
  display: flex;

  @media (max-width: 800px) {
    display: block;
  }
`;

export const WhatsAppInputReceptive = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  input {
    transform: scale(2);
  }
  label {
    margin-right: 10px;
    font-size: 16px;
    color: #0b4e71;
    font-family: 'Poppins', sans-serif !important;

    @media (max-width: 800px) {
      margin-right: 0px !important;
    }
  }
  #chk_receptive {
    display: none;
    margin: 0 !important;
    padding: 0 !important;
  }
  .switch_receptive {
    position: relative;
    background-color: #c1c1c1;
    width: 50px;
    height: 13px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    padding: 5px;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
    margin-right: 40px;

    @media (max-width: 800px) {
      margin-right: 0px !important;
      margin-bottom: 20px;
    }
  }
  .slider_receptive {
    position: absolute;
    background-color: white;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    transition: all 0.5s ease-in-out;
  }
  #chk_receptive:checked ~ .switch_receptive {
    background-color: #39acb5;
  }
  #chk_receptive:checked ~ .switch_receptive .slider_receptive {
    transform: translateX(35px);
  }
`;

export const WhatsAppInputActive = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  input {
    transform: scale(2);
  }
  label {
    margin-right: 10px;
    font-size: 16px;
    color: #0b4e71;
    font-family: 'Poppins', sans-serif !important;

    @media (max-width: 800px) {
      margin-right: 0px !important;
    }
  }
  #chk_activo {
    display: none;
    margin: 0 !important;
    padding: 0 !important;
  }
  .switch_activo {
    position: relative;
    background-color: #c1c1c1;
    width: 50px;
    height: 13px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    padding: 5px;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
  }
  .slider_activo {
    position: absolute;
    background-color: white;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    transition: all 0.5s ease-in-out;
  }
  #chk_activo:checked ~ .switch_activo {
    background-color: #39acb5;
  }
  #chk_activo:checked ~ .switch_activo .slider_activo {
    transform: translateX(35px);
  }
`;
