import Container from 'components/Container'
import SectionTitle from 'components/SectionTitle'
import { darken } from 'polished'
import React from 'react'
import { injectIntl } from 'react-intl'
import styled, { css, withTheme } from 'styled-components'
import mediaQuery from 'utils/mediaQuery'

export const Subtitle = styled.div`
${props =>  
  css`
    padding: 5px 20px;
    background: ${props.color}3d;
    margin-left: 10px;
    border-radius: 25px;
  `
}`;

const HeaderStyled = styled.header`
  width: 100%;
  margin: 0 auto;
  position: relative;
  
  > div > div {
    justify-content: center
  }

  ${mediaQuery.lessThan('large')`
    width: 76%;
  `}
}
`

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  height: 120px;
  position: relative;
  ${mediaQuery.lessThan('large')`
    > h2 {
      text-align: center;
    }
  `}

  ${mediaQuery.greaterThan('small')`
    ${props => props.icon && css`
      &::after {
        content: " ";
        position: absolute;
        top: 0;
        right: 0;
        width: 300px;
        height: 100%;
        background: url(${props.icon}) center right no-repeat;
        filter: brightness(0) invert(1);
      }
    `}
  `}
`

function Header ({ title, icon, intl, ...props }) {
  const color = props.color
    ? props.color !== props.theme.colors.hm 
      ? darken(0.40, props.color)
      : props.color
    :  props.theme.colors.defaultHM;
    
  return (
    <HeaderStyled {...props}>
      <Container size='small'>
        <TitleContainer icon={icon}>
          <SectionTitle color={color} font={props.theme.fonts.tertiary}>{title}</SectionTitle>    
          {/* {props.subtitle && (
            <Subtitle {...props} >
              { intl.formatMessage({id: SUBDIAGNOSTIC[props.subtitle]}) }
            </Subtitle>
          )}                */}
        </TitleContainer>        
      </Container>
    </HeaderStyled>
  )
}

export default withTheme(injectIntl(Header))
