import React, { Fragment } from "react";
import { Bar } from "react-chartjs-2";
import { injectIntl } from "react-intl";
import { Container, FlexBox } from "./styles.js";

const CardRisk = ({ intl, dataset, risk_name }) => {
  
  const handleFormatDataset = (dataset = []) => {
    return dataset.length ? dataset.map(item => (
      {
        label: intl.formatMessage({
          id: item.name
        }),
        data: item.data,
        backgroundColor: item.color,
        borderColor: item.border,
        borderWidth: 1
      }
    )) : []
  }
  return (
    <Fragment>
      <FlexBox>
        <Container>
          <Bar
            data={{
              labels: [
                intl.formatMessage({ id: risk_name }),
                intl.formatMessage({ id: "users.results.everHadSymptoms" }),
                intl.formatMessage({ id: "admin.common.exams.are.missing" })
              ],
              datasets: handleFormatDataset(dataset)
            }}
            height={300}
            width={"100%"}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                y: {
                  beginAtZero: true
                }
              },
              plugins: {
                title: {
                  display: true,
                  text: intl.formatMessage({
                    id: "admin.common.risk.assessment"
                  }),
                  color: "#34495E",
                  align: "start",
                  font: {
                    size: 15,
                    weight: "bold"
                  }
                },
                subtitle: {
                  display: true,
                  text: intl.formatMessage({
                    id: "admin.common.qtd"
                  },{ risk: intl.formatMessage({ id: risk_name }) }),
                  color: "#34495E",
                  align: "start",
                  font: {
                    size: 13,
                    weight: 400
                  },
                  padding: {
                    bottom: 28,
                    top: -4
                  }
                },
                legend: {
                  display: true,
                  position: "bottom"
                }
              }
            }}
          />
        </Container>
      </FlexBox>
    </Fragment>
  );
};

export default injectIntl(CardRisk);
