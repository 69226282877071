import React from 'react'
import PropTypes from 'prop-types'
import Section from 'admin/components/Section'
import DataTable from 'admin/components/DataTable'
import { DateColumn } from 'admin/columns'
import { injectIntl } from 'react-intl'

function Logs ({ logs, intl }) {
  return (
    <Section title='Logs'>
      <DataTable
        data={logs}
        filterable={false}
        showPaginationBottom={false}
        columns={[
          { accessor: 'descricao', Header: intl.formatMessage({ id: 'admin.common.description' }) },
          { accessor: 'autor.name', Header: intl.formatMessage({ id: 'admin.common.author' }), width: 200 },
          DateColumn({ accessor: 'data', message: 'admin.common.date' })
        ]}
      />
    </Section>
  )
}

Logs.propTypes = {
  /** Lista de log do resource */
  logs: PropTypes.array.isRequired
}
export default injectIntl(Logs)
