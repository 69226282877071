import React from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import Image from "components/Image";
import { ModalContentWrapper, ModalContent, ButtonClose } from "./styles";
import parse from "html-react-parser";

export default function Modal({
  isOpened,
  onRequestClose,
  children,
  padded,
  width
}) {
  return (
    <ReactModal
      isOpen={isOpened}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          paddingTop: 20,
          backgroundColor: "rgba(11, 11, 11, 0.8)",
          zIndex: 1000,
          overflow: "auto"
        },
        content: {
          border: "none",
          padding: 0,
          position: "static"
        }
      }}
    >
      <ModalContentWrapper width={width}>
        <ButtonClose
          type="button"
          onClick={onRequestClose}
          title="Fechar Modal"
        >
          <Image
            src={require("images/close.svg")}
            width={20}
            alt="Fechar Modal"
          />
        </ButtonClose>
        <ModalContent padded={padded}>
          {typeof children === 'string' ? parse(`<div>${children}</div>`) : children}
        </ModalContent>
      </ModalContentWrapper>
    </ReactModal>
  );
}

Modal.propTypes = {
  /** Determina se o Modal está aberto */
  isOpened: PropTypes.bool,

  /** Callback para fechar o modal */
  onRequestClose: PropTypes.func.isRequired,

  /** Deixa o modal com padded default */
  padded: PropTypes.bool,

  /** Largura do Modal */
  width: PropTypes.number
};

Modal.defaultProps = {
  isOpened: false,
  padded: true,
  width: 500
};
