import React, { Fragment } from 'react';
import Chart from 'react-apexcharts';
import { injectIntl } from 'react-intl';
import { Container, FlexBox } from './styles.js';

const DoughnutChart = ({ intl, serie }) => {
  const COLORS = {
    Masculino: '#17a2b8E0',
    Feminino: '#d68485E0',
  };
  const handleFormatArraySeries = (serie = []) => {
    return serie.length ? serie.map(item => item.value) : [];
  };

  const handleFormatLabelSeries = (serie = []) => {
    return serie.length
      ? serie.map(item =>
          intl.formatMessage({
            id: item.type,
          }),
        )
      : [];
  };

  const handleFormaColorSeries = (serie = []) => {
    return serie.length ? serie.map(item => COLORS[item.type]) : [];
  };

  return (
    <Fragment>
      <FlexBox>
        <Container>
          {Object.keys(serie).length ? (
            <Fragment>
              <Chart
                type="donut"
                height={300}
                width="100%"
                series={handleFormatArraySeries(serie)}
                options={{
                  labels: handleFormatLabelSeries(serie),
                  title: {
                    text: intl.formatMessage({
                      id: 'admin.common.summary',
                    }),
                    style: {
                      fontSize: 15,
                      color: '#34495E',
                      fontWeight: 700,
                      align: 'start',
                    },
                  },
                  colors: handleFormaColorSeries(serie),

                  plotOptions: {
                    pie: {
                      donut: {
                        labels: {
                          show: true,
                          total: {
                            show: true,
                            color: '#34495E',
                            fontWeight: 'bold',
                            label: intl.formatMessage({
                              id: 'Total',
                            }),
                            fontSize: 15,
                          },
                        },
                      },
                    },
                  },

                  dataLabels: {
                    enabled: true,
                  },
                  legend: {
                    position: 'bottom',
                  },
                  responsive: [
                    {
                      breakpoint: 1000,
                      options: {
                        plotOptions: {
                          bar: {
                            horizontal: false,
                          },
                        },
                        legend: {
                          position: 'bottom',
                        },
                      },
                    },
                  ],
                }}
              />
            </Fragment>
          ) : (
            <Fragment>
              <Container>
                <h2 className="title_none_data">
                  {intl.formatMessage({
                    id: 'admin.common.summary',
                  })}
                </h2>
                <center>
                  <img
                    src={require('../../../images/ban-solid.svg')}
                    alt="Emoji"
                    className="emoji_none_data"
                  />
                  <h3 className="title_none_data">
                    {intl.formatMessage({
                      id: 'admin.common.data.found',
                    })}
                  </h3>
                </center>
              </Container>
            </Fragment>
          )}
        </Container>
      </FlexBox>
    </Fragment>
  );
};

export default injectIntl(DoughnutChart);
