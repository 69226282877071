export const API_URL = process.env.REACT_APP_API_URL || window.location.origin
export const APP_URL = process.env.REACT_APP_URL || '/app'

export const CLIENT_ID = 2
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET
export const GRANT_TYPE = 'password'

export const CLIENT_ID_MICROSOFT = process.env.REACT_APP_CLIENT_ID_MICROSOFT //'3d49e8ce-fb81-4ce7-854e-b84fce2c54e7';
export const CLIENT_ID_GOOGLE = process.env.REACT_APP_CLIENT_ID_GOOGLE// '794941850214-oaihmjkpekup1iod2djp99ip4flu7pjq.apps.googleusercontent.com';

export const SCRIPT_NODE_TYPES = {
  QUESTION: 'question',
  ANSWER: 'answer',
  CATEGORY: 'category'
}

export const LANGUAGES = {
  pt: 'pt',
  en: 'en',
  es: 'es',
  fr: 'fr'
}

export const WEB_ANSWER_ID = 75

export const MEDICAL_HISTORY_IDS = [7, 8]

export const SEXUALLY_ACTIVE_QUESTION = 44;
export const SURGICAL_HYSTERECTOMY = 45;

export const NEGATIVE_ANSWER = 2;

export const TYPE_QUESTIONS = {
  OBJETIVA: { id: 'Objetiva', name: 'admin.script.questionTypes.objective' },
  DISCURSIVA: { id: 'Discursiva', name: 'admin.script.questionTypes.discursive' },
  GROUP: { id: 'Group', name: 'admin.common.group' },
  MULTIPLE_CHOICE: { id: 'multiple_choice', name: 'admin.script.questionTypes.multipleChoice' },
  CHOICE_LIST: { id: 'choice_list', name: 'admin.script.questionTypes.choiceList' },
}

export const QUESTIONS_FORMATS = {
  none: { id: '', name: 'admin.common.none' },
  integer: { id: 'integer', name: 'admin.common.integer' }
}

export const PATIENTS_ETNIAS = {
  white: { label: 'white', value: 'Branco' },
  black: { label: 'black', value: 'Negro' },
  brown: { label: 'brown', value: 'Pardo' },
  yellow: { label: 'yellow', value: 'Amarela' },
  indigenous: { label: 'indigenous', value: 'Indígena' }
}

export const CANCER_RISKS = {
  HIGH: { id: 'alto', name: 'Alto', color: 'danger' },
  MEDIUM: { id: 'medio', name: 'Médio', color: 'warning' },
  LOW: { id: 'baixo', name: 'Baixo', color: 'success' }
}

export const CONTACT_STATUS = {
  PENDING: { status: 'aguardando', action: 'start', label: 'admin.patientsHistory.status.pending', color: 'primary' },
  IN_PROGRESS: { status: 'em_atendimento', action: 'hold', label: 'admin.patientsHistory.status.inProgress', color: 'warning' },
  DONE: { status: 'finalizado', action: 'finish', label: 'admin.patientsHistory.status.done', color: 'success' }
}

export const GENDERS = {
  male: { name: 'admin.common.gender.male', id: 'M' },
  female: { name: 'admin.common.gender.female', id: 'F' },
  unisex: { name: 'admin.common.gender.unisex', id: 'U' }
}

export const GENDER = {
  male: { name: 'admin.common.gender.male', id: 'Masculino' },
  female: { name: 'admin.common.gender.female', id: 'Feminino' },
  unissex: { name: 'admin.common.gender.unisex', id: 'Unissex' }
}

export const COLORS = [
  '#66bbc3',
  '#f299b3',
  '#9f7f8b',
  '#d47b7a',
  '#e2d3b0',
  '#2ecc71',
  '#9b59b6'
]

export const SUBDIAGNOSTIC = [ 
  false,
	'Demands',
	'Control',
	'Support',
	'Relationship',
	'Role',
	'Change',
	'K10',
]

export const ANAMNESES = {
  'Prostata'  : 2,
  'Utero'     : 6,
  'Medical History (male)'  : 7,
  'Medical History (female)': 8,
  'Mama'      : 10,
  'Pulmao'    : 11,
  'Colon'     : 12,
  'Mental'    : 13,
  'Cardio'    : 14 
}

export const ENABLE_MULTILANG = true
