import styled from 'styled-components';

export const Container = styled.div`
  .section_ddi_telefone {
    display: flex !important;
  }
  .sc-bxivhb {
    width: 100% !important;
  }

  @media (max-width: 800px) {
    .section_ddi_telefone {
      font-size: 14px;
    }
  }
`;

export const ActiveDashboard = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;

  input {
    transform: scale(2);
  }
  .active-dashboard {
    margin-left: 10px;
    font-size: 16px;
    color: #0b4e71 !important;
    font-family: 'Poppins', sans-serif !important;
  }
  #form_active_dashboard {
    display: none;
    margin: 0 !important;
    padding: 0 !important;
  }
  .switch {
    position: relative;
    background-color: #c1c1c1;
    width: 50px;
    height: 13px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    padding: 5px;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
  }
  .slider {
    position: absolute;
    background-color: white;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    transition: all 0.5s ease-in-out;
  }
  #form_active_dashboard:checked ~ .switch {
    background-color: #39acb5;
  }
  #form_active_dashboard:checked ~ .switch .slider {
    transform: translateX(35px);
  }
`;

export const ContainerCaptcha = styled.div`
  label {
    padding: 20px 85px 20px 15px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0 4px -1px rgb(0 0 0 / 30%);
    position: relative;

    p {
      font-size: 14px;
    }

    &:before {
      content: 'reCAPTCHA';
      background: url('https://www.gstatic.com/recaptcha/api2/logo_48.png');
      background-size: 32px;
      background-repeat: no-repeat;
      background-position: center top;
      position: absolute;
      width: 56px;
      display: flex;
      align-items: end;
      height: 46px;
      right: 10px;
      cursor: default;
      font-family: Roboto, helvetica, arial, sans-serif;
      font-size: 10px;
      color: #555;
    }
  }
`;

export const SelectStyles = styled.div`
  width: 37%;
  max-width: 190px;
  margin-right: 4px;
`;
