import React from "react";
import { injectIntl } from "react-intl";
import FormReportsMedicalHistory from "../../forms/FormReportsMedicalHistory/FormReportsMedicalHistory";
import Resource from "containers/Resource";
import Auth from "../../../admin/containers/Auth/Auth";
import Text from "components/Text";

function ReportsMedicalHistoryScreen({ intl, ...props }) {
  return (
    <Auth>
      {props =>
        props.permited(props, "userAdmin") ? (
          <Resource resource="ReportsMedicalHistory" autoFetch = {false}>
            {props => (
              <FormReportsMedicalHistory
                onSubmit={async value => {
                  await props.fetchAll(value);
                }}
                intl={intl}
                {...props}
              />
            )}
          </Resource>
        ) : (
          <Text>
            {intl.formatMessage({ id: "admin.common.permission.page" })}
          </Text>
        )
      }
    </Auth>
  );
}

export default injectIntl(ReportsMedicalHistoryScreen);
