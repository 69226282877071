import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import routes from 'routes'
import getRoute from 'utils/getRoute'
import { Container, DiagnosticTitle, DiagnosticTitleLink, LinkDisabledStyled, LinkStyled } from './styles'

function CardDiagnosticHM({ title, image, legenda, id, color, intl, isActive, needFillMedicalHistoryAgain, hasFilledCurrentDiagnostic, ...props }){

  const ContainerLink = isActive 
    ? elProps => <LinkStyled to={getRoute(needFillMedicalHistoryAgain || hasFilledCurrentDiagnostic ? routes.users.diagnostic.beforeStartAnamnese : routes.users.diagnostic.startAnamnese, { anamneseID: id})} color={color} active={isActive.toString()} {...elProps} />
    : elProps => <LinkDisabledStyled to={routes.users.index} active={isActive.toString()} {...elProps} />
     
  return (
    <Container color={color} image={image} className="card-medical-historic">
      <ContainerLink>
        <DiagnosticTitle>{ intl.formatMessage({ id: title }) }</DiagnosticTitle>
        <DiagnosticTitleLink>{hasFilledCurrentDiagnostic ? intl.formatMessage({ id: 'users.results.card.seeResult' }) : intl.formatMessage({ id: 'users.results.card.fillText' }) }</DiagnosticTitleLink>
      </ContainerLink>
    </Container>
  )
}

CardDiagnosticHM.propTypes = {
  /** ID */
  id: PropTypes.any.isRequired,

  /** Imagem */
  image: PropTypes.string.isRequired,

  /** Título */
  title: PropTypes.string.isRequired,

  /** Descrição */
  description: PropTypes.string.isRequired,

  /** Cor do hover */
  color: PropTypes.string.isRequired,

  /** Diagnostico Ativo */
  isActive: PropTypes.bool.isRequired,

  /** Precisa preencher novamente o Historico Medico */
  needFillMedicalHistoryAgain: PropTypes.bool.isRequired,

  /** Já preencheu este diagnostico */
  hasFilledCurrentDiagnostic: PropTypes.bool.isRequired
}

export default injectIntl(CardDiagnosticHM)
