import Request from '../../modules/Request'

export default {
  fetchAll (params) {
    return Request.auth({
      method: 'GET',
      url: `/api/user/processedResults`,
      query: params
    }).then(res => res.body)
  },
  fetchOne (id, params) {
    return Request.auth({
      method: 'GET',
      url: `/api/user/processedResults/${id}`,
      query: params
    }).then(res => res.body)
  }
}

