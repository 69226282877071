import styled from 'styled-components'
import mediaQuery from 'utils/mediaQuery'

export const AlertStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${props => props.theme.fonts.tertiary};
  color: #fff;
  background-color: ${props => props.type === 'success'
    ? props.theme.colors.success
    : props.theme.colors.danger
};
  padding: 15px;
  border-radius: 5px;

  ${mediaQuery.lessThan('small')`
    padding: 7px;
  `}
`

export const CloseButtonStyled = styled.button`
  flex-shrink: 0;
  background: none;
  border: none;
  color: #fff;
  font-size: 30px;
  padding: 0;
  line-height: 0;
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-left: 10px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`

export const List = styled.ul`
  margin: 0;
  margin-top: 10px;
  font-size: 14px;
`
