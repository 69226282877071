import styled from 'styled-components';

export const TextHTML = styled.div`
      font-size: 16px;
      color: #0b4e71;
      font-weight: bold;
      overflow-wrap: anywhere;

      & *{
            font-family: 'Poppins',sans-serif !important;
            font-size:18px !important;
            color: #0b4e71!important;
            font-weight:bold!important;
      }

      & a{
            text-decoration:none;
            &:hover{
                  text-decoration:underline;
            }
      }
      [data-f-id=pbf]{
            display: none;
      }
`;