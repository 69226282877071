const fallbackStorage = {}

export default {
  get (key, fallbackValue) {
    try {
      const data = window.sessionStorage.getItem(key)
      return data
        ? JSON.parse(data)
        : fallbackValue
    } catch (e) {
      console.error(e)
      return fallbackStorage[key] || fallbackValue
    }
  },

  set (key, value) {
    try {
      const data = JSON.stringify(value)
      window.sessionStorage.setItem(key, data)
    } catch (e) {
      fallbackStorage[key] = value
      console.error(e)
    }

    return value
  },

  remove (key) {
    try {
      window.sessionStorage.removeItem(key)
    } catch (e) {
      delete fallbackStorage[key]
      console.error(e)
    }
  }
}
