import React, { Fragment } from "react";
import { Line } from "react-chartjs-2";
import { injectIntl } from "react-intl";
import { Container, FlexBox } from "./styles.js";

const ChartInterviews = ({ intl , dataset, colors}) => {

  const handleFormatDataset = (dataset=[]) =>{
    let data = [];
     Object.keys(dataset).forEach( key  =>{ 
      if( key === 'date')
        return;
      let item = dataset[key]
      data.push({
        label: intl.formatMessage({ id: key }),
        data: item,
        borderColor: colors[key] || ["#68ad76A6"],
        backgroundColor: colors[key] || ["#68ad76A6"],
        pointStyle: "rectRounded",
        pointRadius: 10,
        pointHoverRadius: 15
      })
    });

    return {
      labels: dataset.date,
      datasets : data
    }
  }

  return (
    <Fragment>
      <FlexBox>
        <Container>
          <Line
            data={ handleFormatDataset(dataset) }
            height={300}
            width={"100%"}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              onResize: 200,
              aspectRatio: 2,
              resizeDelay: 5,
              scales: {
                y: {
                  beginAtZero: true
                }
              },
              plugins: {
                title: {
                  display: true,
                  text: intl.formatMessage({ id: "admin.filling.interviews" }),
                  color: "#34495E",
                  align: "start",
                  font: {
                    size: 15
                  }
                },
                subtitle: {
                  display: true,
                  align: "start",
                  text: intl.formatMessage({
                    id: "admin.common.qtd.filling.interviews"
                  }),
                  padding: {
                    bottom: 28,
                    top: -4
                  }
                },
                legend: {
                  display: true,
                  position: "bottom"
                }
              }
            }}
          />
        </Container>
      </FlexBox>
    </Fragment>
  );
};

export default injectIntl(ChartInterviews);
