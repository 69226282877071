import styled from 'styled-components'
import mediaQuery from 'utils/mediaQuery'

export const FieldsContainer = styled.div`
  min-height: 300px;
  margin-bottom: 25px;
`

export const Bottom = styled.div`
  ${mediaQuery.greaterThan('small')`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  `}

  ${mediaQuery.lessThan('small')`
    > * + * {
      margin-top: 15px;
    }
  `}
`
