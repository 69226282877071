import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import Section from 'admin/components/Section'
import { Form, Field } from 'react-final-form'
import VerticalSpacer from 'components/VerticalSpacer'
import Text from 'components/Text'
import CustomField from 'components/CustomField'
import SubmissionContainer from 'admin/components/SubmissionContainer'
import ButtonSubmit from 'components/ButtonSubmit'
import Link from 'components/Link'
import * as forms from 'utils/forms'
import { equalTo } from 'utils/validators'
import routes from 'routes'

function FormPasswordReset ({ isSubmitting, intl, ...props }) {
  return (
    <Section title='Definir senha'>
      <Form {...props}>
        {({ handleSubmit, pristine }) => (
          <form onSubmit={handleSubmit}>
            <VerticalSpacer>
              <Text>
                Defina sua senha de acesso abaixo:
              </Text>
              <Field
                name='password'
                type="password"
                label='Senha'
                id='form_user_password'
                component={CustomField}
                {...forms.passwordRequired}
              />
              <Field
                name='password_confirmation'
                type="password"
                label='Confirmar Senha'
                id='form_user_password_confirmation'
                component={CustomField}
                {...forms.password}
                validate={equalTo({ field: 'password', message: 'As senhas precisam ser iguais' })}
              />
              <SubmissionContainer>
                <ButtonSubmit
                  label='Definir senha'
                  isSubmitting={isSubmitting}
                  disabled={pristine}
                />
                <Link to={routes.users.login}>
                  {intl.formatMessage({ id: 'site.authenticated.login' })}
                </Link>
              </SubmissionContainer>
            </VerticalSpacer>
          </form>
        )}
      </Form>
    </Section>
  )
}

FormPasswordReset.propTypes = {
  /** Callback para o form submit */
  onSubmit: PropTypes.func.isRequired,

  /** Form sendo processado */
  isSubmitting: PropTypes.bool
}

export default injectIntl(FormPasswordReset)
