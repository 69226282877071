import Section from 'admin/components/Section'
import BackLink from 'components/BackLink/BackLink'
import Resource from 'containers/Resource'
import PropTypes from 'prop-types'
import React from 'react'
import { Route } from 'react-router-dom'
import handleFormSubmission from 'utils/handleFormSubmission'

export default function ResourceNew ({ resource, routeBase, title, Form, style }) {

  return (
    <Resource
      resource={resource}
      redirectToAfterSuccess={routeBase.index}
    >
      {props => (
        <Section style={style} title={title} side={<BackLink to={routeBase.index} />}>
          <Route>
            {({ location }) => {
              //const params = location.search && qs.parse(location.search)
              // const initialValues = params && params.data
              //   ? JSON.parse(params.data)
              //   : null

              return (
                <Form
                  initialValues={{}}
                  onSubmit={values => handleFormSubmission(props.create(values))}
                  isSubmitting={props.isSubmitting}
                  title={title}
                  action={"new"}
                />
              )
            }}
          </Route>
        </Section>
      )}
    </Resource>
  )
}

ResourceNew.propTypes = {
  /** Recurso */
  resource: PropTypes.string.isRequired,

  /** Rota base */
  routeBase: PropTypes.object.isRequired,

  /** Título */
  title: PropTypes.string.isRequired,

  /** Form */
  Form: PropTypes.func.isRequired
}
