import Request from 'modules/Request'

const request = (isAuthenticated, config) =>
  Request[isAuthenticated ? 'auth' : 'api'](config)

export default {
  fetchData ({ anamneseID }, isAuthenticated) {
    return request(isAuthenticated, {
      method: 'GET',
      url: `/api/user/roteiros/${anamneseID}/pacientes`
    }).then(res => res.body)
  },

  updatePatient ({ anamneseID, data }, isAuthenticated) {
    return request(isAuthenticated, {
      method: 'PUT',
      url: `/api/user/roteiros/${anamneseID}/pacientes-anamneses`,
      body: data
    }).then(res => res.body)
  },

  answerQuestion ({ anamneseID, questions }, isAuthenticated) {
     return request(isAuthenticated, {
      method: 'POST',
      url: `/api/user/anamnese/${anamneseID}/fill`,
      body: { questions }
    }).then(res => res.body)
  },

  completeAnamnese ({ anamneseID, questionaire_id }, isAuthenticated) {
     return request(isAuthenticated, {
      method: 'POST',
      url: `/api/user/roteiros/${anamneseID}/pacientes/complete`,
      body: { questionaire_id }
    }).then(res => res.body)
  },

  fetchResult ({ anamneseID }, isAuthenticated) {
    return request(isAuthenticated, {
      method: 'POST',
      url: `/api/user/roteiros/${anamneseID}/pacientes/resultado`
    }).then(res => res.body)
  },

  loadMedicalHistory() {
    return request(true, {
      method: 'GET',
      url: `/api/user/roteiros/medicalHistory`
    }).then(res => res.body)
  },

  okMedicalHistory() {
    return request(true, {
      method: 'GET',
      url: `/api/user/roteiros/medicalHistory/ok`
    }).then(res => res.body)
  }
}
