import Container from 'components/Container'
import PageContent from 'components/PageContent'
import VerticalSpacer from 'components/VerticalSpacer'
import Notifications from 'containers/Notifications'
import Resource from 'containers/Resource'
import qs from 'querystringify'
import React from 'react'
import { injectIntl } from 'react-intl'
import FormPasswordRecovery from 'users/forms/FormPasswordRecovery'
import FormPasswordReset from 'users/forms/FormPasswordReset'

function PasswordRecovery ({intl, location }) {
  const query = qs.parse(location.search)
  return (
    <PageContent>
      <Container size='xsmall'>
        <VerticalSpacer>
          <Notifications />
          <Resource
            resource='PasswordToken'
            notifications={{
              create: {
                success: true,
                errors: true,
                defaultSuccessMessage: intl.formatMessage({ id:'site.authenticated.password.redefine.create'})             
              },
              update: {
                success: true,
                errors: true,
                defaultSuccessMessage: intl.formatMessage({ id:'site.authenticated.password.redefine.success'})
              }              
            }}
          >
            {props => {
              if (query.hasOwnProperty('token')) {
                return (
                  <FormPasswordReset
                    onSubmit={data => props.update(query.token, data)}
                    isSubmitting={props.isSubmitting}
                    {...props}
                  />
                )
              }

              return (
                <FormPasswordRecovery
                  onSubmit={props.create}
                  isSubmitting={props.isSubmitting}
                />
              )
            }}
          </Resource>
        </VerticalSpacer>
      </Container>
    </PageContent>
  )
}

export default injectIntl(PasswordRecovery);