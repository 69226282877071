import React from 'react'
import { FormattedMessage } from 'react-intl'
import { format } from 'modules/Date'

export default (props = { dateAccessor: 'created_at', message: 'admin.common.createdAt' }) => ({
  Header: <FormattedMessage id={props.message} />,
  accessor: props.dateAccessor,
  Cell: cellProps => cellProps.value ? format(cellProps.value, props.dateFormat) : cellProps.value,
  filterable: false,
  width: 175,
  ...props
})
