import React from "react";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import Resource from "containers/Resource";
import { SectionTable } from "./styles";
import ResourceTableFilter from "admin/containers/ResourceTableFilter";

const DataTableReports = ({ intl, logs, resource, report, autoFetch = true }) => {
  return (
    <SectionTable>
      <Resource resource={resource} autoFetch = {autoFetch}>
        {props => (
          <ResourceTableFilter
            {...intl}
            {...logs}
            resource={props.resource}
            dataTableProps={props.dataTableProps}
            report={report}
          />
        )}
      </Resource>
    </SectionTable>
  );
};

export default injectIntl(DataTableReports);

DataTableReports.propTypes = {
  /** Props para o DataTable */
  dataTableProps: PropTypes.object
};
