import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import Card from '../Card/Card';
import ContainerDashbord from '../ContainerDashbord/ContainerDashbord';
import {
  BodyCard, Context, SectionTitleCard,
  TitleCard
} from './styles.js';

const CardFollowUp = ({ intl, data }) => {
  return (
    <Fragment>
      <Context>
        <SectionTitleCard>
          <h3 className="card-subtitle">
            {intl.formatMessage({
              id: data.risk_name,
            })}
          </h3>
          <span className="card-span">
            &nbsp;
            {intl.formatMessage(
              {
                id: 'admin.high.qtd.segment',
              },
              { risk: intl.formatMessage({ id: data.risk_name }) },
            )}
          </span>
        </SectionTitleCard>

        {Object.keys(data.cards).length ? (
          <ContainerDashbord>
            {data.cards.map((item, idx) => (
              <Card
                key={idx}
                container={1}
                box="none"
                background={'#96b4becc'}
                color={'#fff'}
                className="cards"
                style={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  textAlign: 'center',
                  margin: '0 7px',
                  padding: '10px 0',
                }}
              >
                <TitleCard>
                  {intl.formatMessage({
                    id: item.name,
                  })}
                </TitleCard>
                <div style={{margin:0}}>
                  <BodyCard>{item.value}</BodyCard>
                  <span className="span-info">{item.percent} %</span>
                </div>
              </Card>
            ))}
          </ContainerDashbord>
        ) : (
          <Fragment>
            <ContainerDashbord>
              <center>
                <img
                  src={require('../../../images/ban-solid.svg')}
                  alt="Emoji"
                  className="emoji_none_data"
                />
                <h4 className="title_none_data">
                  {intl.formatMessage({
                    id: 'admin.common.data.found',
                  })}
                </h4>
              </center>
            </ContainerDashbord>
          </Fragment>
        )}
      </Context>
    </Fragment>
  );
};

export default injectIntl(CardFollowUp);
