import React from 'react'
import { Switch, Route } from 'react-router-dom'
import routes from 'routes'
import { LoginScreen, RegisterScreen, PasswordRecoveryScreen, PasswordConfirmScreen } from 'users/screens'
import Dashboard from 'users/components/Dashboard'
import AuthLogin from 'components/AuthLogin'

export default function Start () {
  return (
    <AuthLogin type="usersAdmWeb">
      <Switch>
        <Route exact path={routes.users.login} component={LoginScreen} />
        <Route exact path={routes.users.register} component={RegisterScreen} />
        <Route exact path={routes.users.passwordRecovery} component={PasswordRecoveryScreen} />
        <Route exact path={routes.users.passwordConfirm} component={PasswordConfirmScreen} />
        <Route path={routes.users.index} component={Dashboard} />
      </Switch>
    </AuthLogin>
  )
}
 