import { API_URL } from 'config/constants'
import Session from 'modules/Session'
import Storage from 'modules/Storage'
import * as popsicle from 'popsicle'
import prefix from 'popsicle-prefix'
import status from 'popsicle-status'
import { getTokenKey } from 'store/api/Auth'

const lang = (self, next) => {
  const lang = Storage.get('lang')
  if (lang) {
    self.set('Accept-Language', localStorage.getItem('lang'))
  }
  return next()
}

const auth = (self, next) => {
  const type = Session.get('authType')
  const token = Session.get(getTokenKey(type))
  if (token) {
    self.set('Authorization', `Bearer ${token}`)
  }
  return next()
}

const request = popsicle.defaults({
  headers: {
    Accept: 'application/json'
  },
  use: [
    status(),
    popsicle.plugins.stringify(),
    popsicle.plugins.headers(),
    popsicle.plugins.parse('json'),
    lang
  ]
})

request.api = popsicle.defaults({
  headers: {
    Accept: 'application/json'
  },
  use: [
    status(),
    popsicle.plugins.stringify(),
    popsicle.plugins.headers(),
    popsicle.plugins.parse('json'),
    prefix(API_URL),
    lang
  ]
})

request.auth = popsicle.defaults({
  headers: {
    Accept: 'application/json'
  },
  use: [
    status(),
    popsicle.plugins.stringify(),
    popsicle.plugins.headers(),
    popsicle.plugins.parse('json'),
    prefix(API_URL),
    lang,
    auth
  ]
})

export default request
