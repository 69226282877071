import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 800px) {
    display: block;
    justify-content: flex-start;
  }
`;

export const Context = styled.div`
  display: block;
  justify-content: flex-start;

  .box-row {
    display: flex;
    align-items: center;
  }

  .label-box {
    color: '#34495E'
    font-weight: bold;
    justify-content: flex-start;
  }
  .label-two {
    font-weight: 400;
    justify-content: flex-start;
    applywhite-space: nowrap;
  }
  .label-two-box {
    color: '#34495E'
    font-weight: bold;
    justify-content: flex-start;
    applywhite-space: nowrap;
  }
  .span-box {
    font-weight: bold;
    justify-content: flex-start;
    font-size: 40px;
    top: 0;
  }
  .span{
    font-weight: 200;
    justify-content: flex-end;
    font-size: 20px;
  }

  .img-icon {
    width: 12px;
    margin-right: 10px;
  }

  .box-footer {
    display: flex;
    justify-content: space-between !important; 
    align-items: center;
  }

  @media (max-width: 912px) {
    padding-left: 20px;
    padding-right: 20px;
    .label-box {
      padding-Top: 20px;
    }

    .span-box {
      margin-right: 30px;
    }
  }
`;
