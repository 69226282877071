import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { lighten } from 'polished'

export const Container = styled.div`
  font-family: ${props => props.theme.fonts.tertiary};
  font-weight: 400;
  max-width: 310px;
  padding: 15px 20px;
  color: ${props => props.theme.colors.textNew};
  border-radius: 10px;
  text-align: left;
  background-color: ${props => props.color};
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: 80px;
  background-position: calc(100% - 15px) calc(100% - 5px);

  &:hover {
    background-color: ${props => lighten(0.05, props.color)};
    cursor: pointer;
  }

  &.notActive {
    opacity: 0.4;
    filter: alpha(opacity=40);
  }
`

export const CardTypeTitle = styled.span`
  font-size: 12px;
  margin-top: 10px;
`

export const DiagnosticTitle = styled.p`
  font-size: 26px;
  margin-top: 10px;
  max-width: 59%;
  margin-bottom: 10px;
  font-weight: 700;
  font-family: ${props => props.theme.fonts.tertiary}
`

export const DiagnosticTitleLink = styled(CardTypeTitle)`
  font-size: 14px;
`

export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: inherit
`

export const LinkDisabledStyled = styled(LinkStyled)`

`
