import Input from "components/Input";
import React,{ useState } from "react";
import { injectIntl } from "react-intl";
import './styles.css';

const InputTableFilter = ({intl, setFilter, data}) => {
    const [value, setValue] = useState('')
    const handleFilter = value => {
        if(value === '')
            setFilter(value , data.response);

        let filtered = [];
        data.response.forEach(line=>{
            for(let item in line){
                if(
                    line[item] !== null 
                    && line[item].toString().toLocaleLowerCase().indexOf(value.toString().toLocaleLowerCase()) > -1
                )
                    return filtered.push(line);
            }
        })

        setFilter(value , filtered);
        setValue(value)
    }
    return (
        <Input
            style={{
                width: 260,
                position: "relative",
                marginLeft: "auto",
                marginTop: -30,
                marginBottom: 40
            }}
            className="input-search"
            type="search"
            value={value}
            placeholder={
                intl.formatMessage({ id: "admin.common.search" }) + "..."
            }
            onChange={e => handleFilter(e.target.value)}
        />
    )
}

export default injectIntl(InputTableFilter);
