export default {
  /* Páginas */
  'pages.home.title': 'PreviNEO - Revolução na prevenção e combate ao câncer',
  'pages.about.description': 'Trabalhamos para prevenir, diagnosticar e reduzir os riscos de incidência dos principais tipos de câncer que ocorrem no Brasil, através de um programa de oncologia e tecnologia de ponta.',
  'pages.specialities.title': 'Especialidades - PreviNEO',
  'pages.specialities.titleDescription': 'Abaixo, você encontra mais informações em torno das causas e sintomas deste tipo de câncer, com o objetivo de conscientizar sobre a prevenção dessa doença.<br />',
  'pages.specialities.description': 'Na PreviNEO trabalhamos de forma especializada para prevenir, diagnosticar e reduzir os riscos de incidência dos cânceres de Próstata, Mama, Pulmão, Cólon e Útero.',
  'pages.customers.title': 'Clientes - PreviNEO',
  'pages.customers.description': 'Na PreviNEO contamos com empresas parceiras que colocam nossos benefícios à disposição de colaboradores e familiares. Acesse e conheça quem está conosco nessa luta!',
  'pages.blog.title': 'Blog - PreviNEO',
  'pages.blog.description': 'A informação é fator decisivo na prevenção e diagnóstico precoce do câncer. Através do nosso Blog são disponibilizados dados e notícias a respeito da nossa causa, acesse!',
  'pages.contact.title': 'Contato - PreviNEO',
  'pages.contact.description': 'Para sanar dúvidas, enviar sugestões ou se tornar um parceiro, envie-nos uma mensagem. Nossa equipe terá o maior prazer em atendê-lo!',

  /* Novas paginas */
  'pages.login.title': 'Login - PreviNEO',
  'pages.login.description': 'Tela de Login',
  'pages.register.register': 'Cadastrar-se',
  'pages.register.first.access': 'Meu primeiro acesso',
  'pages.register.code': 'Nome da empresa',
  'pages.register.code.continue': 'Continuar',
  'pages.finalize.registration' : 'Finalizar Cadastro',
  'pages.register.code.return': 'Voltar',
  'pages.register.code.name': 'Nome do paciente',
  'pages.register.code.age': 'Idade do paciente',

  'admin.common.qtd_colaboradores': 'Qtd. de Colaboradores',
  'admin.common.multiselect.available': 'Disponíveis',
  'admin.common.multiselect.selected': 'Selecionados',
  'admin.common.multiselect.filter': 'Filtrar...',
  'admin.common.initialDate': 'Data de início',
  'admin.common.genderTrans': 'Transgênero',
  'admin.common.gender.biologic': 'Gênero Biológico',
  'admin.common.gender.social': 'Gênero Social',
  'admin.common.processingAnamnese': 'Estamos processando os resultados',
  'admin.anamnesis.coming_soon': 'Diagnóstico disponível em breve',
  'admin.anamnesis.medicalHistory': 'Dados de Histórico Médico',
  'admin.anamnesis.medicalHistory.description': 'Você preencheu os dados abaixo sobre histórico médico',
  'admin.anamnesis.medicalHistory.button.correct': 'Está correto',
  'admin.anamnesis.medicalHistory.button.update': 'Atualizar dados',
  'admin.script.questionTypes.multipleChoice': 'Múltipla Escolha',
  'admin.script.questionTypes.choiceList': 'Lista de opções',
  'admin.common.logo': 'Logo',
  'admin.common.password.new': 'Nova senha',

  'users.results.title': 'Resultados',
  'users.results.diagnostic.title': 'Resultado do Diagnóstico',
  'users.results.diagnostic.title.plural': 'Resultados de Diagnósticos',
  'users.results.everHadSymptoms': 'Possui sintomas',
  'users.results.diagnosticType': 'Tipo de diagnóstico',
  'users.results.risk': 'Risco',
  'users.results.justificatives': 'Justificativas',
  'users.dashboard.title.one': 'Prevenir é Viver Mais e Melhor.',
  'users.dashboard.roche.longtext': 'Se você é colaborador da Roche e ficar com alguma dúvida após concluir o preenchimento, você poderá procurar a Medicina Ocupacional através do email: {email}. Se você é dependente poderá consultar o médico de sua preferência.',
  'users.dashboard.welcome.male': 'Bem-vindo, {name}!',
  'users.dashboard.welcome.female': 'Bem-vinda, {name}!',
  'users.dashboard.card.type': 'Entrevista Digital',
  'users.results.card.fillText': 'Iniciar Preenchimento',
  'users.results.card.seeResult': 'Revisar Resultados',
  'users.dashboard.card.typeWithDiagnosticName': 'Entrevista Digital: {diagnosticName}',
  'users.results.smallHeaderMessage': '{name}, esse é o seu resumo:',
  'users.results.hello.mental': 'Olá {name},',
  'users.results.smallHeaderMessage.mental': 'Em primeiro lugar, parabéns! Se você está lendo isso, significa que você completou todas as questões em nossa triagem de Saúde Mental. Você está ativamente tentando aprender mais sobre si mesmo.',
  'users.results.card.assessedRisk': 'Risco avaliado',
  'users.results.card.levelOfAttention': 'Nível de atenção',
  'users.results.card.examsPendents': 'Exames ou consultas pendentes',
  'users.results.othersDiagnosticsAvailable': 'Outras Entrevistas Digitais Disponíveis',
  'users.results.refillWarning': 'Verificamos que seu histórico médico foi atualizado.',
  'users.results.refillWarningRec': 'Recomendamos que realize a entrevista digital novamente.',
  'users.results.expertRecommendations': 'Não esqueça de revisar as recomendações dos nossos especialistas',
  'users.results.expertRecommendations.mental': 'As condições de saúde comportamental podem sempre ser melhoradas, e a maioria das pessoas acha que pode fazer mudanças positivas em suas vidas com ajuda profissional e o apoio da família e amigos.',
  'users.results.openDetails': 'Abrir Detalhes da Entrevista Digital',
  'users.results.fillAgain': 'Preencher novamente',
  'users.with.deleted': 'Com removidos',
  'users.not.deleted': 'Sem removidos',
  'users.forms.passwordConfirm.title': 'Cadastrar senha',
  'users.delete.data.button': 'Sair do sistema',
  'users.delete.data.title': 'Sair do sistema',
  'users.delete.data.description': 'Isso fará todos seus dados pessoais (incluindo nome, email, telefone e afins) serem excluídos permanentemente do nosso banco',
  'users.delete.data.cantrollback.title': 'Tem certeza?',
  'users.delete.data.cantrollback.description': 'Essa ação não pode ser desfeita.',
  'users.anamneses.notfound': 'Anamnese não encontrada ou indisponível',
  'users.admin.companies.title': 'Empresa',
  'users.admin.companies.title.plural': 'Empresas',
  /* Novas paginas */

  /* Títulos dos links do menu */
  'site.menu.home': 'Home',
  'site.menu.about': 'Quem Somos',
  'site.menu.specialities': 'Especialidades',
  'site.menu.customers': 'Clientes',
  'site.menu.blog': 'Blog',
  'site.menu.contact': 'Contato',

  /* Autenticação e cadastro de pacientes */
  'site.authenticated.login': 'Login',
  'site.authenticated.password': 'Senha',
  'site.authenticated.password.redefine': 'Redefinir Senha',
  'site.authenticated.password.redefine.text': 'Redefina sua senha nos campos abaixo:',
  'site.authenticated.password.confirm': 'Confirmar Senha',
  'site.authenticated.password.validate': 'As senhas precisam ser iguais',
  'site.authenticated.password.redefine.create': 'Um e-mail com o link para redefinir sua senha foi enviado para você!',
  'site.authenticated.password.redefine.success': 'Sua senha foi atualizada com sucesso!',

  /* 404 */
  'site.notFound.title': 'Página não encontrada',
  'site.notFound.message': '404: Página não encontrada!',

  /* Login */
  'admin.forms.login.title': 'Login',
  'admin.forms.login.button': 'Login',
  'admin.forms.login.password': 'Senha',
  'users.login.button.enter': 'Entrar',
  'users.login.header.message': 'Entre com seu login e senha',
  'users.login.welcome': 'Bem-vindo! Você acaba de dar o primeiro passo para viver mais e melhor!',
  'admin.forms.login.passwordRecovery': 'Esqueci minha senha',

  /* Recuperar senha */
  'admin.forms.login': 'Email ou CPF',
  'admin.patientsHistory.currentSituation': 'Situação Atual',
  'admin.common.nextContact': 'Próximo Contato',
  'admin.common.contactReason': 'Motivos do Contato',
  'admin.common.typesCancer': 'Tipos de Câncer',
  'admin.common.patientHistoryExams': 'Exames Realizados',
  'admin.common.saveContact': 'Salvar Contato',
  'admin.patientsHistory.status.pending': 'Iniciar',
  'admin.patientsHistory.status.inProgress': 'Pausar',
  'admin.patientsHistory.status.done': 'Finalizar',
  'admin.patientsHistory.titlePlural': 'Prontuários',
  'admin.patientsHistory.contact': 'Atendimento',
  'admin.patientsHistory.contactReasonPlural': 'Motivos de Contato',
  'admin.common.contactMade': 'Contatado?',
  'admin.common.contactMadeOk': 'Sim',
  'admin.common.contactMadeNo': 'Não',
  'admin.common.contactStatus': 'Status',
  'admin.common.contactStatuses': 'Status',
  'admin.common.managePatientHistory': 'Atendimento',
  'admin.common.ethnicity': 'Etnia',
  'admin.common.patientInfo': 'Dados do Paciente',

  /* Recuperação de Senha */
  'admin.forms.passwordRecovery.title': 'Recuperação de Senha',
  'admin.forms.passwordRecovery.button': 'Recuperar',

  /* Hero Home */
  'section.home.hero.title': 'Revolução na <strong>prevenção</strong><br /> e <strong>combate</strong> ao câncer.',
  'section.home.hero.button': 'Saiba Mais',

  /* Section Services Home */
  'section.home.services.title': 'Tecnologia e conhecimento científico em prol de um bem maior: <strong>a vida.</strong>',
  'section.home.services.description': '<p>A PreviNEO atua através da prevenção e da <strong>realização do diagnóstico precoce</strong> dos 5 principais tipos de câncer que ocorrem no Brasil, e que correspondem a um volume expressivo dos casos: mama, próstata, pulmão, cólon e colo de útero.</p><p> <strong>Colocamos o ser humano em primeiro lugar</strong>, oferecemos soluções centradas em cada paciente com um objetivo fundamental: promover a saúde para cada vez mais pessoas.</p>',

  /* Section Steps Home */
  'section.home.steps.title': 'O trabalho da <strong>PreviNEO</strong> é realizado nas seguintes etapas:',
  'section.home.steps.stepone.step': 'Etapa 1',
  'section.home.steps.stepone.title': 'Identificação',
  'section.home.steps.stepone.description': 'Anamnese online ou presencial.',
  'section.home.steps.steptwo.step': 'Etapa 2',
  'section.home.steps.steptwo.title': 'Rastreamento',
  'section.home.steps.steptwo.description': 'Utilização de algoritmos para estratificação da população de risco.',
  'section.home.steps.stepthree.step': 'Etapa 3',
  'section.home.steps.stepthree.title': 'Orientação',
  'section.home.steps.stepthree.description': 'Contato e orientação.',
  'section.home.steps.stepfour.step': 'Etapa 4',
  'section.home.steps.stepfour.title': 'Monitoramento',
  'section.home.steps.stepfour.description': 'Elaboração de estratégias para redução de risco.',

  /* Section Clientes Home */
  'section.home.customers.title': 'Clientes',
  'section.home.customers.description': 'Nossos programas de prevenção já fazem a diferença no dia a dia de diversas empresas que, assim como nós, colocam a saúde e bem estar das pessoas em primeiro lugar.',
  'section.home.customers.button': 'Veja todos',

  /* Section Depoimentos Home */
  'section.home.testmonies.title': 'Nossos clientes falam melhor que a gente',
  'section.home.testmonies.internal.title': 'Nada fala mais alto do que a <strong>aprovação</strong> de quem já conhece.',
  'section.home.testmonies.internal.subtitle': 'Ao lado, você encontra os depoimentos de <strong>nossos parceiros:</strong>',

  /* Section Blog Home */
  'section.home.blog.title': 'Nosso Blog',
  'section.home.blog.button': 'Veja tudo',

  /* Section Parceiros */
  'section.partner.title': 'Seja um parceiro PreviNEO',
  'section.partner.description': 'Vamos unir forças? Juntos, podemos reduzir cada vez mais a mortalidade por câncer no Brasil.',
  'section.partner.button': 'Fale conosco',

  /* Hero About */
  'section.about.hero.title': 'Um programa de oncologia para <strong>prevenir</strong> e <strong>diagnosticar</strong>.',
  'section.about.hero.description': 'Na PreviNEO, trabalhamos para prevenir, diagnosticar e reduzir os riscos de incidência dos principais tipos de câncer que ocorrem no Brasil, através de um programa de oncologia e tecnologia de ponta. Nossa missão é simples: promover saúde e reduzir a incidência e mortalidade de câncer no país. Para isso, atuamos com os tipos de câncer mais incidentes no Brasil, a fim de impactar positivamente e profundamente na qualidade de vida nacional.',
  'section.about.hero.button': 'Saiba mais',

  /* Section Steps About */
  'section.about.steps.title': 'Como o programa de <strong>oncologia</strong> trabalha?',
  'section.about.steps.description': 'Nosso método de atuação consiste em três etapas principais: análise de dados, cálculo de risco e orientações. Assim, você terá acesso a um atendimento e um diagnóstico completo e totalmente personalizado. <strong>Confira o passo a passo:</strong>',
  'section.about.steps.stepone.step': 'Etapa 1',
  'section.about.steps.stepone.description': 'Preenchimento da anamnese presencial ou online.',
  'section.about.steps.steptwo.step': 'Etapa 2',
  'section.about.steps.steptwo.description': 'Estratificação de população de risco através do programa de oncologia.',
  'section.about.steps.stepthree.step': 'Etapa 3',
  'section.about.steps.stepthree.description': 'Contato e orientação para criação de estratégias de redução de riscos.',
  'section.about.steps.stepfour.step': 'Etapa 4',
  'section.about.steps.stepfour.description': 'Agendamento de exames e consultas.',
  'section.about.steps.stepfive.step': 'Etapa 5',
  'section.about.steps.stepfive.description': 'Monitoramento da estratégia de redução de riscos.',

  /* Section Map About */
  'section.about.map.title': 'A <strong>primeira</strong> e <strong>única</strong> do Brasil!',
  'section.about.map.description': '<p>Fundada em <strong>2015</strong>, a PreviNEO é a <strong>primeira</strong> e <strong>única</strong> empresa brasileira a trabalhar com um programa de oncologia para a prevenção e diagnóstico de câncer. Ao longo de nossa trajetória, atendemos mais de 48 mil pessoas em 22 empresas.</p><p> Entre as pessoas alcançadas através de nossos parceiros, identificamos mais de <strong>5 mil casos de pacientes em risco.</strong> Queremos continuar crescendo e levando informação e qualidade de vida para <strong>a maior quantidade de pessoas possível.</strong></p>',

  /* Section Frase About */
  'section.phrase.title': 'Unidos para <strong>reduzir a <br />mortalidade</strong> por câncer no Brasil.',

  /* Hero Specialities */
  'section.specialities.hero.title': '<strong>Prevenção</strong><br />que salva vidas!',
  'section.specialities.hero.description': '<p>Para combater o câncer, trabalhamos principalmente com a prevenção e o <strong>diagnóstico precoce do câncer, resultando em estratégias personalizadas para diminuir a incidência, o diagnóstico tardio e a mortalidade para essa doença.</strong></p><p>O objetivo é sempre o mesmo: promover saúde em todo o Brasil. Por conta disso, atuamos nos cinco principais tipos de câncer presentes nos <strong>brasileiros para diminuir a mortalidade e o custo com esses diagnósticos no Brasil.<strong></p>',

  /* Section Specialities */
  'section.specialities.types.title': 'Os 5 principais tipos de câncer',
  'section.specialities.types.subtitle': 'Na PreviNEO, trabalhamos de forma especializada com câncer de Próstata, Mama, Pulmão, Cólon e Útero.',

  /* Hero Customers */
  'section.customers.hero.title': '<strong>Não</strong> estamos <br /><strong>sozinhos!</strong>',
  'section.customers.hero.description': 'Para levar os benefícios da prevenção e do <strong>diagnóstico precoce</strong> do câncer, nós contamos com empresas parceiras que colocam nossos benefícios à disposição de colaboradores e familiares. Assim, a tranquilidade e a saúde chegam cada vez mais longe, fortalecendo a luta pela vida!',

  /* Section Clientes */
  'section.customers.list.title': 'Conheça nossos <strong>clientes</strong>',

  /* Contato */
  'section.contact.title': 'Entre em contato conosco',
  'section.contact.subtitle': 'Para sanar dúvidas, enviar sugestões ou elogios e se tornar um parceiro, envie uma mensagem',

  /* Form Contato */
  'form.contact.name': 'Nome',
  'form.contact.phone': 'Telefone',
  'form.contact.email': 'E-mail',
  'form.contact.subject': 'Assunto',
  'form.contact.message': 'Mensagem',

  /* Admin Usuarios */
  'admin.users.title': 'Usuário',
  'admin.users.title.plural': 'Usuários',
  'admin.users.form.sendPasswordEmail': 'Enviar senha por email',
  'admin.users.searchForUsers': 'Pesquisar por usuário',
  'admin.users.deletedUsers': 'Usuários Removidos',
  'admin.common.restore': 'Recuperar',
  'admin.common.restoreSelected': 'Recuperar selecionados',

  /* Nomes comuns admin */
  'admin.common.name': 'Nome',
  'admin.common.surname': 'Sobrenome',
  'admin.common.full.name': 'Nome completo',
  'admin.common.email': 'E-mail',
  'admin.common.emails': 'E-mails',
  'admin.common.emailLayout': 'Layout de E-mail',
  'admin.common.emailLayouts': 'Layouts de E-mail',
  'admin.common.emailContent': 'Conteúdo de E-mail',
  'admin.common.emailContents': 'Conteúdos de E-mail',
  'admin.common.password': 'Senha',
  'admin.common.confirmPassword': 'Confirmar Senha',
  'admin.common.oldPassword': 'Senha antiga',
  'admin.common.accessLevels': 'Níveis de Acesso',
  'admin.common.save': 'Salvar',
  'admin.common.send': 'Enviar',
  'admin.common.listOf': 'Lista de',
  'admin.common.register': 'Cadastrar-se',
  'admin.common.edit': 'Editar',
  'admin.common.details': 'Detalhes',
  'admin.common.remove': 'Remover',
  'admin.common.removePermanently': 'Remover permanentemente',
  'admin.common.back': 'Voltar',
  'admin.common.actions': 'Ações',
  'admin.common.warningMessage': 'Deseja mesmo remover este registro?',
  'admin.common.AreYouSureABoutIt': 'Está certo disso?',
  'admin.common.confirm': 'Confirmar',
  'admin.common.cancel': 'Cancelar',
  'admin.common.processing': 'Processando...',
  'admin.common.previous': 'Anterior',
  'admin.common.next': 'Próximo',
  'admin.common.loading': 'Carregando...',
  'admin.common.loadMore': 'Carregar mais',
  'admin.common.noResultsFound': 'Nenhum resultado encontrado',
  'admin.common.page': 'Página',
  'admin.common.Of': 'de',
  'admin.common.results': 'Resultados',
  'admin.common.idNumber': 'Matrícula',
  'admin.common.gender': 'Gênero',
  'admin.common.genders': 'Gêneros',
  'admin.common.gender.male': 'Masculino',
  'admin.common.gender.female': 'Feminino',
  'admin.common.gender.unisex': 'Unissex',
  'admin.common.genderMale': 'Masculino',
  'admin.common.genderFemale': 'Feminino',
  'admin.common.dateOfBirth': 'Data de nascimento',
  'admin.common.companyPlaceHolder': 'Pesquisar Empresa',
  'admin.common.birthDatePlaceHolder': 'DD/MM/AAAA',
  'admin.common.filterData': 'Filtrar dados...',
  'admin.common.mostRecent': 'Mais recentes',
  'admin.common.history': 'Histórico completo',
  'admin.common.cpf': 'CPF',
  'admin.common.weight': 'Peso (kg)',
  'admin.common.height': 'Altura (cm)',
  'admin.common.phone': 'Telefone',
  'admin.common.cellphone': 'Celular',
  'admin.common.acceptContact': 'Aceita receber informações complementares',
  'admin.common.yes': 'Sim',
  'admin.common.no': 'Não',
  'admin.common.cep': 'CEP',
  'admin.common.cepTooltip': 'Insira seu Cep (Ex: 73015-132)',
  'admin.common.zipcode.Tooltip': 'Insira seu {label} (Ex: {mask})',
  'admin.common.country': 'País',
  'admin.common.state': 'Estado',
  'admin.common.city': 'Cidade',
  'admin.common.address': 'Endereço',
  'admin.common.addressNumber': 'Número',
  'admin.common.addressComplement': 'Complemento',
  'admin.common.editCompany': 'Editar Empresa',
  'admin.common.registerCompany': 'Cadastrar Empresa',
  'admin.common.removeMessage': 'Deseja mesmo remover esta empresa?',
  'admin.common.rootNohNotFound': 'Nó raiz não encontrado!',
  'admin.common.companyName': 'Nome da empresa',
  'admin.common.cnpj': 'CNPJ',
  'admin.common.parentCompany': 'Empresa Pai',
  'admin.common.expirationDate': 'Data de Expiração',
  'admin.common.logoTopRight': 'Logo superior direita',
  'admin.common.logoTopLeft': 'Logo superior esquerda',
  'admin.common.logoBottomRight': 'Logo inferior direita',
  'admin.common.logoBottomLeft': 'Logo inferior esquerda',
  'admin.common.openURL': 'URL Aberta',
  'admin.common.restrictedURL': 'URL com login',
  'admin.common.slugURL': 'Slug (nome após URL)',
  'admin.common.select': 'Selecione',
  'admin.common.typeSomethingToSearch': 'Digite algo para pesquisar',
  'admin.common.dateInsert': 'Insira uma data',
  'admin.common.createdAt': 'Criado em',
  'admin.common.fileImport': 'Arquivo de importação',
  'admin.common.downloadModel': 'Baixar modelo',
  'admin.common.import': 'Importar',
  'admin.common.export': 'Exportar',
  'admin.common.reportQueue': 'Fila de Relatórios',
  'admin.common.patientID': 'ID Paciente',
  'admin.common.userID': 'Id do Usuário',
  'admin.common.anamnese': 'Anamnese',
  'admin.common.attention_level': 'Nível de Atenção',
  'admin.common.exams_up_to_date': 'Exames em Dia',
  'admin.common.symptoms': 'Sintomas',
  'admin.common.justifications': 'Justificativas',
  'admin.common.assessment_date': 'Data da Avaliação',
  'admin.common.description': 'Descrição',
  'admin.common.id': 'ID',
  'admin.common.accept_Contact': 'Aceita Contato?',
  'admin.common.age': 'Idade',
  'admin.common.genre': 'Gênero',
  'admin.common.unit': 'Unidade',
  'admin.common.registration_date': 'Data de Cadastro',
  'admin.common.response_date': 'Data de Resposta',
  'admin.common.weigh': 'Qual é o seu peso ? (em KG)',
  'admin.common.use_of_medications': 'Uso de medicamentos',
  'admin.common.what_remedies': 'Quais remédios?',
  'admin.common.specify_the_remedys': 'Especifique o remédio',
  'admin.common.smoke_cigars': 'Você fuma ou já fumou charutos?',
  'admin.common.how_old': 'Durante quantos anos você fumou charutos?',
  'admin.common.how_many_cigars': 'No tempo que fumou, quantos charutos por dia?',
  'admin.common.currently_smoke_cigars': 'Fuma charutos atualmente?',
  'admin.common.smoke_how_old': 'Se já parou de fumar charutos, há quantos anos?',
  'admin.common.ever_smoked_cigarette': 'Você fuma ou já fumou cigarros?',
  'admin.common.years_smoked_cigarettes': 'Durante quantos anos você fumou cigarros?',
  'admin.common.cigarettes_daily': 'No tempo que fumou, quantos cigarros por dia?',
  'admin.common.currently_smoke_cigarettes': 'Fuma cigarros atualmente?',
  'admin.common.stopped_smoking_cigarettes': 'Se já parou de fumar cigarros, há quantos anos?',
  'admin.common.practice_physical_activity': 'Pratica alguma atividade física?',
  'admin.common.minutes_week': 'Quantos minutos por semana em média?',
  'admin.common.performed_genetic_exam': 'Já realizou BRCA1 ou BRCA2?',
  'admin.common.mutation_search': 'Sua resposta do teste para pesquisa de mutação BRCA foi positivo. Está correto?',
  'admin.common.already_had_cancer': 'Você já teve câncer?',
  'admin.common.type_of_cancer': 'Qual tipo de câncer?',
  'admin.common.specify_type_of_cancer': 'Especifique o tipo de câncer',
  'admin.common.family_cancer_report': 'Selecione as alternativas sobre o histórico de câncer em sua família',
  'admin.common.alcohol_consumption': 'Qual o seu consumo diário de bebida alcoólica?',
  'admin.common.had_heart_problem': 'Você já teve algum problema cardíaco?',
  'admin.common.like_heart_trouble': 'Qual tipo de problema cardíaco?',
  'admin.common.specify_heart_problem': 'Especifique o problema cardíaco',
  'admin.common.history_emotional_problems': 'Você tem histórico de problemas emocionais?',
  'admin.common.like_mental_problem': 'Qual tipo de problema emocional?',
  'admin.common.specify_emotional_problem': 'Especifique o problema emocional',
  'admin.common.chest x-ray': 'Você já fez radioterapia no tórax para tratar linfoma?',
  'admin.common.radiotherapy_affirmation': 'Você afirmou que já fez radioterapia? Está correto?',
  'admin.common.first_menstruation_age': 'Com qual idade veio sua primeira menstruação?',
  'admin.common.age_first_child': 'Com qual idade teve o primeiro filho (biológico) vivo?',
  'admin.common.degree_relatives_breast_cancer': 'Quantos parentes de 1° grau tiveram câncer de mama?',
  'admin.common.confirms_relative_breast_cancer': 'Você respondeu que um parente de 1º grau já teve câncer de mama. Correto?',
  'admin.common.had_bowel_cancer': 'Você respondeu que um parente de 1º grau já teve câncer de intestino. Correto?',
  'admin.common.did_breast_biopsy': 'Já fez alguma biópsia de mama?',
  'admin.common.how_many_biopsies_done': 'Quantas biópsias foram feitas?',
  'admin.common.atypical_alteration_biopsies': 'Alguma biópsia deu alteração com atipias?',
  'admin.common.last_mammogram': 'Quando você realizou sua última mamografia?',
  'admin.common.observe_palpable_lesions': 'Você observa alguma lesão palpável?',
  'admin.common.spontaneous_papilla_secretion': 'Possui secreção nas papilas de forma espontânea?',
  'admin.common.change_shape_breast': 'Observou alguma mudança de forma na mama?',
  'admin.common.had_sexual_intercourse': 'Você já teve relação sexual?',
  'admin.common.uterus_removal_surgery': 'Já realizou uma cirurgia para retirada total do útero (histerectomia)?',
  'admin.common.cervical_preventive_exam': 'Já realizou algum exame preventivo de colo uterino (papanicolau)?',
  'admin.common.changes_pap_smear': 'Alguma vez deu alteração dos tipos NIC I, NIC II , NIC III HSIL ou LSIL?',
  'admin.common.doses_vacina_hpv': 'Você já tomou as 3 doses de vacina para o HPV?',
  'admin.common.regular_use_condoms': 'Durante sua vida, fez uso regular e constante de preservativos ou diafrágmas?',
  'pain_during_intercourse': 'Sente dor intensa durante a relação sexual?',
  'admin.common.pain_during_intercourse': 'Sente dor intensa durante a relação sexual?',
  'admin.common.bleeding_during_intercourse': 'Alguma vez notou sangramento durante ou após a relação sexual?',
  'admin.common.menstrual_period_bleeding': 'Apresenta algum sangramento fora do período menstrual ou após a menopausa?',
  'admin.common.lived_with_smokers': 'Você viveu com fumantes por mais da metade da sua vida?',
  'admin.common.worked_asbestos_protection': 'Você já trabalhou com amianto, sem a devida proteção?',
  'admin.common.work_without_protection': 'Atividades relacionadas com as quais você tenha trabalhado sem a devida proteção',
  'admin.common.has_difficulty_breathing': 'Você sente falta de ar ou dificuldade para respirar?',
  'admin.common.presents_continuous_cough': 'Apresenta tosse contínua ou progressiva?',
  'admin.common.relative_bowel_cancer': 'Algum parente de 1° grau já teve câncer de intestino?',
  'admin.common.had_bowel_disease': 'Já teve alguma doença intestinal que teve que retirar um pólipo (por colonoscopia)?',
  'admin.common.performed_exams': 'Já realizou alguma vez os seguintes exames: pesquisa de sangue oculto nas fezes, retossigmoidoscopia ou conoloscopia?',
  'admin.common.suffer_from_constipation': 'Você sofre de obstipação intestinal (intestino preso) ou diarreia frequente?',
  'admin.common.presence_blood_feces': 'Já observou presença de sangue nas fezes?',
  'admin.common.relative_prostate_cancer': 'Algum parente de 1° grau já teve câncer de próstata?',
  'admin.common.renal_touch_exam': 'Você já fez exame de toque retal ou PSA?',
  'admin.common.difficulty_urinating': 'Sente dificultade para urinar ou percebeu que o jato de urina está mais fraco?',
  'admin.common.blood_output_urine': 'Percebeu saída de sangue na urina?',
  'admin.common.empty_bladder_complete': 'Ao urinar, tem a sensação que não consegue esvaziar a bexiga por completo?',
  'admin.common.copy': 'Copiar',
  'admin.common.date': 'Data',
  'admin.common.lastUpdate': 'Última atualização',
  'admin.common.download': 'Baixar',
  'admin.common.searchForCompany': 'Pesquisar empresa',
  'admin.common.men': 'Homens',
  'admin.common.women': 'Mulheres',
  'admin.common.script': 'Roteiro',
  'admin.common.version': 'Versão',
  'admin.common.risk': 'Risco',
  'admin.common.risks': 'Riscos',
  'admin.common.question': 'Pergunta',
  'admin.common.answer': 'Resposta',
  'admin.common.calculation': 'Cálculo',
  'admin.common.interval': 'Intervalo',
  'admin.common.start': 'Início',
  'admin.common.share': 'Compartilhar',
  'admin.common.Share_Diagnosis': 'Compartilhar Resultado',
  'admin.common.put address': 'Coloque um endereço de email abaixo:',
  'admin.common.end': 'Fim',
  'admin.common.selectPatient': 'Selecionar pacientes manualmente',
  'admin.common.high': 'Alto',
  'admin.common.medium': 'Médio',
  'admin.common.low': 'Baixo',
  'admin.common.title': 'Título',
  'admin.common.hour': 'Hora',
  'admin.common.record_type': 'Tipo_registro',
  'admin.common.e-mail': 'E-mail',
  'admin.common.telephone': 'Telefone',
  'admin.common.social_name': 'Nome Social',
  'admin.common.road': 'Rua',
  'admin.common.number': 'Número',
  'admin.common.complement': 'Complemento',
  'admin.common.birth_date': 'Data de Nascimento',
  'admin.common.biological_genus': 'Gênero biológico',
  'admin.common.social_gender': 'Gênero social',
  'admin.common.contact_via_phone': 'Contato via Telefone',
  'admin.common.health_insurance': 'Plano de Saúde',
  'admin.common.company': 'Empresa',
  'admin.common.search': 'Pesquisar',
  'admin.common.riskSelect': 'Selecione um risco',
  'admin.common.observations': 'Observações',
  'admin.common.all': 'Todos',
  'admin.common.icon': 'Ícone',
  'admin.common.categoryColor': 'Cor da categoria',
  'admin.common.tuss': 'TUSS',
  'admin.common.today': 'Hoje',
  'admin.common.visible': 'Visível',
  'admin.common.subtitle': 'Legenda',
  'admin.common.specialtyColor': 'Cor da especilidade',
  'admin.common.image': 'Imagem',
  'admin.common.requiredFields': 'Campos obrigatórios',
  'admin.common.optionalFields': 'Campos opcionais',
  'admin.common.minimumAge': 'Idade mínima',
  'admin.common.maximumAge': 'Idade máxima',
  'admin.common.active': 'Ativo',
  'admin.common.inactive': 'Inativo',
  'admin.common.draft': 'Rascunho',
  'admin.common.language': 'Idioma',
  'admin.common.link': 'Link',
  'admin.common.subject': 'Assunto',
  'admin.common.content': 'Conteúdo',
  'admin.common.staff': 'Equipe',
  'admin.common.customer': 'Cliente',
  'admin.common.customers': 'Clientes',
  'admin.common.value': 'Valor',
  'admin.common.contactInfo': 'Informação de Contato',
  'admin.common.contactInfos': 'Informações de Contato',
  'admin.common.site': 'Site',
  'admin.common.blog': 'Blog',
  'admin.common.post': 'Post',
  'admin.common.posts': 'Posts',
  'admin.common.filter': 'Filtrar',
  'admin.common.continueReading': 'Leia mais',
  'admin.common.permalink': 'Link Permanente',
  'admin.common.category': 'Categoria',
  'admin.common.categories': 'Categorias',
  'admin.common.blogCategory': 'Categoria do Blog',
  'admin.common.blogCategories': 'Categorias do Blog',
  'admin.common.tag': 'Tag',
  'admin.common.tags': 'Tags',
  'admin.common.myAccount': 'Minha conta',
  'admin.common.signOut': 'Sair',
  'admin.common.personalData': 'Dados Pessoais',
  'admin.filter': 'Filtro de Pacientes',
  'admin.filterTable': 'Filtros',
  'admin.filterReportsTable': 'Filtros',
  'admin.filterReports': 'Filtros',
  'admin.common.editPassword': 'Alterar Senha',
  'admin.common.report': 'Relatório',
  'admin.common.reports': 'Relatórios',
  'admin.common.report.medicalHistory': 'Relatório de Histórico Médico',
  'admin.common.report.risk': 'Relatório de Risco',
  'admin.common.report.personalData': 'Relatório de Dados Pessoais',
  'admin.common.report.mentalHistorical': 'Relatório de Histórico Mental',
  'admin.common.report.mentalRisk': 'Relatório de Risco Mental',
  'admin.common.reportsCompany': 'Relatórios Empresa',
  'admin.report.personal_data': 'Relatório (Dados Pessoais)',
  'admin.report.risk': 'Relatório (Risco)',
  'admin.report.medical.history': 'Relatório (Histórico Médico)',
  'admin.report.mental': 'Relatório (Mental)',
  'admin.history.mental': 'Relatório (Histórico Mental)',
  'admin.common.patientsFilter': 'Filtro de Pacientes',
  'admin.common.unavailable': 'Indisponível',
  'admin.common.queue': 'Fila',
  'admin.common.personalInformations': 'Informações pessoais',
  'admin.common.continue': 'Continuar',
  'admin.common.dontKnow': 'Não sei',
  'admin.common.didNotDoIt': 'Não fiz',
  'admin.common.ethnicity.white': 'Branca',
  'admin.common.ethnicity.black': 'Negra',
  'admin.common.ethnicity.brown': 'Parda',
  'admin.common.ethnicity.yellow': 'Amarela',
  'admin.common.ethnicity.indigenous': 'Indígena',
  'admin.common.others': 'Outros',
  'admin.common.clickHere': 'Clique aqui',
  'admin.common.result': 'Resultado',
  'admin.common.recommendations': 'Recomendações',
  'admin.common.recommendedExams': 'Exames Recomendados',
  'admin.common.max': 'Máximo',
  'admin.common.min': 'Mínimo',
  'admin.common.format': 'Formato',
  'admin.common.integer': 'Número inteiro',
  'admin.common.none': 'Nenhum',
  'admin.common.displayBeforeAnamnesis': 'Exibir antes da anamnese',
  'admin.common.group': 'Grupo',
  'admin.common.author': 'Autor',
  'admin.common.selectRiskForEmails': 'Selecione o grau de risco que deseja alertar',
  'admin.common.requiredHistoryMedicalCategories': 'As categorias que você selecionou requerem todas as categorias de histórico médico',
  'admin.common.emailSent': 'E-mail enviado',
  'admin.common.motivo': 'Motivo',
  'admin.common.hidePatientInfo': 'Ocultar Informações de Contato',
  'admin.common.displayPatientInfo': 'Exibir Informações de Contato',
  'admin.common.noPermission': 'Você não tem permissão para acessar essa página',
  'admin.common.registerNew': 'Cadastrar',
  'admin.common.permission.page': 'Você não tem permissão para acessar essa página.',
  'admin.comon.select' : 'Selecionar...',
  'admin.enter.company.name' : 'Dígite o nome da sua empresa',
  'admin.filter.company.title' : 'Filtrar empresa',
  'admin.login.with' : 'Logar com',
  'admin.or.register' : 'ou',
  'admin.sign.in.microsoft' : 'Fazer Login com a Microsoft',
  'admin.sign.in.google' : 'Fazer Login com o Google',
  'admin.write.with.microsoft': 'Cadastrar-se com a Microsoft',
  'admin.write.with.google': 'Cadastrar-se com o Google',
  'admin.common.whatsApp.receptive': 'WhatsApp - Receptivo',
  'admin.common.whatsApp.active': 'WhatsApp - Ativo',

  /* Dashboard */
  'admin.dashboard.charts.total': 'Total',
  'admin.dashboard.charts.ofTotal': 'do total',
  'admin.dashboard.charts.historyCancer': 'usuários que possuem histórico de casos de câncer',
  'admin.dashboard.charts.patientsNeedContact': 'usuários necessitam contato imediato',
  'admin.dashboard.charts.anamnesisFulfillments': 'Quantidade total de preenchimentos de anamnese',
  'admin.dashboard.charts.answersByAgeAndGender': 'Volume de Respondentes por Idade e Gênero',
  'admin.dashboard.charts.highRiskByCancerType': 'Volume de Alto Risco por Tipo de Câncer',
  'admin.dashboard.charts.bmi': 'IMC',
  'admin.dashboard.charts.examsPending': 'Faltam Exames',
  'admin.dashboard.charts.riskAssessment': 'Avaliação de Risco',
  'admin.dashboard.charts.riskAssessmentSufix': 'Quantidade de Pessoas Identificadas com Alto Risco',
  'admin.dashboard.charts.highRisk': 'Alto Risco',
  'admin.dashboard.charts.normalRisk': 'Risco Normal',
  'admin.dashboard.charts.summary': 'Sumário',
  'admin.dashboard.charts.totalFilled': 'Quantidade de Preenchimento de Anamnese',
  'admin.dashboard.charts.totalFilledByGender': 'Quantidade de Preenchimento de Anamnese por Gênero',
  'admin.dashboard.charts.totalFilledByAge': 'Quantidade Realizada por Faixa Etária',
  'admin.dashboard.charts.totalDone': 'Quantidade Realizada',
  'admin.dashboard.charts.totalForeseen': 'Quantidade Prevista',
  'admin.dashboard.charts.highRiskChart': 'ALTO RISCO',
  'admin.dashboard.charts.normalRiskChart': 'RISCO NORMAL',
  'admin.dashboard.charts.highRiskSummary': 'Quantidade de Altos Riscos Identificados, por Tipo de Câncer',
  'admin.dashboard.charts.smoker': 'Tabagista',
  'admin.high.qtd': '- Quantidade de {risk} identificados por especialidade.',

  /* Empresas */
  'admin.company.title': 'Empresa',
  'admin.company.title.plural': 'Empresas',

  /* Plano de Saúde */
  'admin.healthInsurance.title': 'Plano de Saúde',
  'admin.healthInsurance.title.plural': 'Planos de Saúde',

  /* Indicação de Empresa*/
  'admin.recommendation.title': 'Indicação',

  /* Perfil de Acesso */
  'admin.roles.title': 'Perfis de Acesso',
  'admin.roles.title.plural': 'Perfis de Acesso',
  'admin.formRole.permissions': 'Permissões',

  /* Idiomas */
  'admin.languages.title': 'Idioma',
  'admin.languages.title.plural': 'Idiomas',
  'admin.languages.slug': 'Slug',

  /* Pacientes */
  'admin.patients.title': 'Paciente',
  'admin.patients.title.plural': 'Pacientes',
  'admin.patients.listOfPatients': 'Lista de Pacientes',
  'admin.patients.importPatients': 'Importar Pacientes',
  'admin.patients.exportPatients': 'Exportar Pacientes',
  'admin.patients.deletedPatients': 'Pacientes Removidos',
  'admin.patients.exportText': 'Selecione os filtros de sua preferência e clique em "Exportar" para solicitar o relatório de pacientes. O tempo para o documento ficar pronto depende da fila no sistema e os critérios de exportação usados.',
  'admin.patients.fields.gender': 'M ou F',
  'admin.patients.fields.cpf': 'Somente números (05376483649) ou formatado (768.884.253-04)',
  'admin.patients.fields.phone': 'Somente números (41993241556) ou formatado ((41) 99324-1556)',
  'admin.patients.fields.birthday': 'AAAA-MM-DD (Exemplo: 1990-06-20)',
  'admin.patients.fields.cep': 'Somente números (93530280) ou formatado (93530-280)',

  /* Prontuário */
  'admin.medicalRecords.title': 'Prontuário',
  'admin.medicalRecords.title.plural': 'Prontuários',
  'admin.medicalRecords.open': 'Abrir prontuário',
  'admin.medicalRecords.close': 'Fechar prontuário',
  'admin.medicalRecords.noInformation': 'Nenhuma informação disponível',
  'admin.medicalRecords.gail.risk1': 'Risco Estimado da Paciente de desenvolver Câncer de Mama nos próximos 05 anos',
  'admin.medicalRecords.gail.risk2': 'Risco de desenvolvimento de Câncer de Mama da População Geral nos próximos 05 anos',
  'admin.medicalRecords.gail.risk3': 'Risco Médio da Paciente de desenvolver Câncer de Mama até os 90 anos',
  'admin.medicalRecords.gail.risk4': 'Risco Médio da População Geral de desenvolver Câncer de Mama até os 90 anos',
  'admin.medicalRecords.madeContact': 'Contato Realizado',
  'admin.medicalRecords.noMoreContact': 'Não Requer Novo Contato',
  'admin.medicalRecords.noMoreContactQuestion': 'Requer Novo Contato?',
  'admin.medicalRecords.threeContactsMessage': '3 Tentativas de Contato',

  /* Tipos de Câncer */
  'admin.cancerTypes.title': 'Tipo de Câncer',
  'admin.cancerTypes.title.plural': 'Tipos de Câncer',

  /* Estratégias */
  'admin.strategies.title': 'Estratégia',
  'admin.strategies.title.plural': 'Estratégias',

  /* Exames Recomendados */
  'admin.exams.title': 'Exame',
  'admin.exams.title.plural': 'Exames Recomendados',

  /* Site */
  'admin.site.title': 'Site',
  'admin.testimonies.title': 'Depoimento',
  'admin.testimonies.title.plural': 'Depoimentos',
  'admin.testimonies.author': 'Autor do depoimento',
  'admin.specialities.title': 'Especialidade',
  'admin.specialities.title.plural': 'Especialidades',

  /* Anamnese */
  'admin.anamnesis.title': 'Anamnese',
  'admin.anamnesis.title.plural': 'Anamneses',

  /* Categorias */
  'admin.categories.title': 'Categoria',
  'admin.categories.title.plural': 'Categorias',

  /* Script */
  'admin.script.title': 'Roteiro',
  'admin.script.title.plural': 'Roteiros',
  'admin.script.categoryAlreadyAdded': 'Categoria já cadastrada',
  'admin.script.addCategory': 'Adicionar Categoria',
  'admin.script.addAnswer': 'Adicionar Resposta',
  'admin.script.addQuestion': 'Adicionar Pergunta',
  'admin.script.editQuestion': 'Editar Pergunta',
  'admin.script.editAnswer': 'Editar Resposta',
  'admin.script.valueAnswer': 'Valor da resposta',
  'admin.script.questionType': 'Tipo de pergunta',
  'admin.script.selectTheQuestionType': 'Selecione o tipo de pergunta',
  'admin.script.questionTitle': 'Título da pergunta',
  'admin.script.questionDescription': 'Descrição da pergunta',
  'admin.script.questionTypes.objective': 'Objetiva',
  'admin.script.questionTypes.discursive': 'Discursiva',
  'admin.script.answer': 'Resposta',
  'admin.script.question': 'Pergunta',
  'admin.script.category': 'Categoria',
  'admin.script.status': 'Status do Roteiro',
  'admin.script.requiredRegistration': 'Matrícula obrigatória',
  'admin.script.requiredPhone': 'Telefone obrigatório',
  'admin.script.requiredCellphone': 'Celular obrigatório',
  'admin.script.requiredHealthcareInsurance': 'Plano de saúde obrigatório',
  'admin.script.minimumPeriod': 'Período mínimo para responder novamente este roteiro (em meses)',
  'admin.script.displayResultPageWithRisks': 'Mostrar página de resultado com riscos',
  'admin.script.sendResultForEmail': 'Enviar resultado por e-mail',
  'admin.script.sendResultForEmailPeriod': 'Prazo para envio dos resultados (em dias)',
  'admin.script.sendReminderEmail': 'Enviar e-mail de lembrete',
  'admin.script.sendReminderEmailPeriod': 'Prazo para envio (em dias)',
  'admin.script.sendReminderEmailQuantity': 'Quantidade de envios',
  'admin.script.resultPageContent': 'Conteúdo da página de resultado',
  'admin.script.initialPageContent': 'Conteúdo página inicial',
  'admin.script.displayFooter': 'Mostrar rodapé',
  'admin.script.questionGroupError': 'Você não pode cadastrar um grupo dentro de outro grupo',
  'admin.script.additionalInfoPageContent': 'Informações Adicionais',
  'admin.script.displayAdditionalInfoPageContent': 'Mostrar Informações Adicionais',

  /* Disparo de Roteiro */
  'admin.scriptSend.title': 'Disparo de Roteiro',
  'admin.scriptSend.exportCsvFile': 'Baixar arquivo CSV',
  'admin.scriptSend.sendEmail': 'Enviar e-mail',
  'admin.scriptSend.sentScripts': 'Roteiros Disparados',

  /* Link Youtube*/
  'admin.common.linkVideo': 'Link do YouTube',

  /* Link Agendamento Exame*/
  'admin.common.link.agendamento.exame': 'Link de agendamento de exame',

  /* Membros da Equipe */
  'admin.team.title': 'Membro da Equipe',
  'admin.team.title.plural': 'Membros da Equipe',

  /* Validações */
  'validations.required': 'Campo Obrigatório',
  'validations.integer': 'Campo precisa ser um número inteiro',
  'validations.integerBetween': 'Insira um número entre {min} e {max}',
  'validations.email': 'E-mail inválido',
  'validations.phone': 'Telefone inválido',
  'validations.link': 'Link inválido',
  'validations.cpf': 'CPF inválido',
  'validations.cnpj': 'CNPJ inválido',
  'validations.cep': 'CEP inválido',
  'validations.date': 'Data inválida',
  'validations.password': 'Precisa conter pelo menos 8 caracteres com números, letras maiúsculas e minúsculas',
  'validations.passwordConfirm': 'As senhas precisam ser iguais',
  'validations.fileType': 'Tipo de arquivo não suportado',
  'validations.enter.full.name': 'Insira seu nome e sobrenome',
  'validations.enter.not.special.character': 'O nome não pode conter caracteres especiais',
  'validations.required.age': 'Para continuar com o cadastro é necessário possuir mais de 18 anos.',

  /* Feedback */
  'feedback.success': 'Ação realizada com sucesso!',
  'feedback.error': 'Opa! Aconteceu algum problema!',

  /* Field Hints */
  'hints.cpf': 'Insira seu CPF com ou sem pontuação',
  'hints.cpfNumberOnly': 'Insira seu CPF sem pontuação (somente números)',
  'hints.number': 'Somente números',
  'hints.email': 'Insira seu e-mail (Ex: example@example.com)',
  'hints.height': 'Insira sua altura em centímetros (cm)',
  'hints.weight': 'Insira seu peso em kilogramas (kg)',
  'hints.dateOfBirth': 'A data deve estar no formado DD/MM/AAAA (exemplo: 25/10/1950)',

  /* Anamnese */
  'q.startPrevention': 'Iniciar Preenchimento',
  'q.backToAnamnese': 'Voltar à anamnese',
  'q.wizardStep': 'Etapa {progress} de {count}',
  'q.acceptContact': 'Eu aceito contato via telefone ou e-mail',
  'q.termsAccept': 'Eu aceito os',
  'q.accept.receive': 'Eu aceito receber',
  'q.additional.information.whatsapp' : 'informações complementares da Previneo via WhatsApp',
  'q.initialText': 'Texto Inicial do Cliente',
  'q.termsOfService': 'Termos de Consentimento',
  'q.termsAndConditions': 'Termos e Condições',
  'q.termsAndConditionsUnimed': 'TERMO DE CONSENTIMENTO E AUTORIZAÇÃO',
  'q.acceptRequired': 'É necessário aceitar os termos',
  'q.brca.question': 'Você já realizou um exame genético (conhecido como BRCA1 ou BRCA) para saber se tem uma alteração/mutação que aumenta seu risco para desenvolver câncer e este resultado foi positivo?',
  'q.brca.description': 'A mutação nos genes BRCA1 ou BRCA2 aumentam significativamente o risco para alguns tipos de câncer (mama, ovário, próstata, cólon e pâncreas). Esse é um exame genético que pode ser feito pelo sangue ou pela saliva para a pessoa saber se apresenta a mutação. Essa mutação ocorre geralmente em pessoas que têm vários familiares próximos acometidos por câncer. Estima-se que aproximadamente 5% a 10% dos casos de câncer de mama e ovários são ocasionados por essa mutação',
  'q.ethnicity.question': 'Raça',
  'q.ethnicity.description': 'Sabe-se que pessoas de etnia negra apresentam risco aumentado para desenvolver câncer de próstata',
  'q.cancerHistory.question': 'Possui histórico pessoal de câncer?',
  'q.cancerHistory.description': 'Sabe-se que quem já foi acometido por câncer apresenta um risco elevado de recidiva ou até mesmo de desenvolver um novo tumor do mesmo tipo. Por isso, é importante, para o cálculo de seu risco, saber se você já foi afetado por essa doença em sua vida.',
  'q.cancerHistory.whatCancer': 'Qual o tipo de câncer?',
  'q.cancerHistory.specifyCancer': 'Especifique o tipo de câncer:',
  'q.helloPatient': 'Olá, {name}',
  'q.patientIntroText': 'Chegou o momento de conhecermos você e um pouco do seu histórico médico. Preencha os campos de maneira precisa, para que a sua avaliação de risco seja feita da melhor forma. Vamos lá?',
  'q.startAvaliation': 'Iniciar Avaliação',
  'q.profileUnmatch': 'As anamneses desta campanha não se adequam ao seu perfil. Obrigado por nos ajudar até aqui!',
  'q.finishAvaliation': 'Finalizar avaliação',
  'q.toUpdateYourData': 'para atualizar seus dados pessoais',
  'q.stepFinished': 'Mais uma etapa concluída!',
  'q.youAreDoingRight': 'Você está preenchendo os dados da anamnese de maneira correta. Continue assim!',
  'q.continueAvaliation': 'Continuar Avaliação',
  'q.toAnswerTheQuestions': 'para responder às questões',
  'q.thanks': 'Obrigado por realizar a avaliação!',
  'q.sentForEmail': 'O resultado foi enviado para seu e-mail.',
  'q.welcomeUser': 'Bem-vindo(a), {name}',
  'termsUnimed': `<p>Consinto e autorizo expressamente a coleta dos meus dados pessoais através de questionário de saúde a ser disponibilizado via link pela empresa 
  <b>PREVINEO PROGRAMAS DE SAÚDE S.A.</b>
  , que gira sob a denominação fantasia de 
  <b>PREVINEO</b>
  , pessoa jurídica de direito privado, inscrita no CNPJ/MF sob no 22.177.298/0001-42, com sede na Rua Petit Carneiro, no 1122, sala 405, Bairro Água Verde, Curitiba/PR, CEP 80240-050, responsável pela coleta, tratamento e armazenamento dos referidos dados, bem como aceito, após o recebimento do resultado da minha classificação de risco, o contato, em horário comercial, de profissional da saúde da empresa 
  <b>PREVINEO</b> 
  que fornecerá orientações sobre prevenção das doenças objeto do estudo. Ainda, consinto e autorizo expressamente o compartilhamento dos meus dados pessoais coletados com a 
  <b>UNIMED CURITIBA – SOCIEDADE COOPERATIVA DE MÉDICOS</b>
  , cooperativa de 1o grau devidamente constituída, registrada na OCEPAR - Organização das Cooperativas do Estado do Paraná sob no 80 - 29/12/72, atuando como Operadora de Planos Privados de Assistência à Saúde, registrada na ANS - Agência Nacional de Saúde Suplementar sob no 30470-1, registrada na Junta Comercial do Paraná sob no 4140000865-7 e cadastrada no CNPJ/MF sob no 75.055.772/0001-20, com sede na Avenida Affonso Penna, no 297, Bairro Tarumã, Curitiba/PR, CEP 82530-280, telefone (41) 3021-9100, a fim de que esta possa promover estudos relacionados à estratificação de riscos oncológicos.</p>
   <p>Assim, em total observância à Lei Geral de Proteção de Dados (Lei no 13.709/2018), manifesto-me livre, informada e inequivocadamente no sentido de autorizar a 
   <b>UNIMED CURITIBA</b> e a 
   <b>PREVINEO</b> 
   a:</p> 
   <p>a) tratar todos os meus dados pessoais e dados pessoais sensíveis como sigilosos e confidenciais, protegendo-os e restringindo a sua utilização aos fins estabelecidos no presente Termo, prevenindo e evitando que tais dados sejam usados para quaisquer outros fins ou divulgados a terceiros não envolvidos no objeto do estudo;</p> 
   <p>b) compartilhar as informações sobre o meu estado de saúde, durante o desenvolvimento do estudo, a terceiros contratados envolvidos no processo;</p> 
   <p>c) disponibilizar os dados clínicos de meu acompanhamento, com a garantia da preservação de minha identificação, para fins estatísticos e de pesquisas em saúde com o objetivo de contribuir para o desenvolvimento de melhores práticas em saúde, ainda que a minha condição de beneficiário(a) da <b>UNIMED CURITIBA</b> venha a não mais existir futuramente.</p>`,
  'terms': `<p>
          <strong>Antes de começar: quem somos e o que fazemos</strong>
        </p>
        <p>
          Somos uma empresa dedicada à promoção de informações médicas, visando a prevenção de alguns tipos de câncer no Brasil. Fazemos isso porque acreditamos que quanto maior a informação, maiores as chances de um diagnóstico precoce, o que é fundamental quando se fala de prevenção de câncer.</strong>
        </p>
        <p>
          A forma pela qual coletamos as informações é um formulário pré-formatado, fundamentado na literatura médica e em métodos reconhecidos nacional e internacionalmente para verificação da presença de algum ou alguns fatores de risco que aumentem a estimativa do risco de desenvolvimento de uma patologia. Para isso, precisaremos de algumas informações pessoais, no que se incluem seus dados de identificação, histórico familiar de patologia bem como dados de sua vida cotidiana.
        </p>
        <p>
          Por exemplo, precisaremos saber se você fuma ou já fumou, se já teve relações sexuais e se já se submeteu a alguns tipos de exames preventivos. Estas e outras informações são essenciais para traçar um resultado correto da estimativa de risco de desenvolvimento dos tipos de câncer aplicados nos formulários.
        </p>
        <p>
          <strong>Como seus dados serão utilizados?</strong>
        </p>
        <p>
          Todos os dados pessoais preenchidos por você serão utilizados com a finalidade de obter o resultado referente ao seu risco de desenvolvimento das patologias testadas. Isso vale tanto para dados de identificação (tais como seunome, endereço eletrônico, idade, etc.) como também em relação às respostas aos formulários, ou seja, aos seus dados médicos ou cotidianos. Utilizaremos seu endereço eletrônico para envio dos resultados.
        </p>
        <p>
          <strong>Quem tem acesso a meus dados?</strong>
        </p>
        <p>
          Para a obtenção do resultado, suas respostas serão analisadas automaticamente e conferidas manualmente por funcionários da Previneo, vinculados à área de saúde. A ferramenta foi desenvolvida por médicos, mas a análise dos dados não é feita por eles, já que não prestamos serviço de assistência à saúde, mas apenas de tratamento de informação. Nossos funcionários estão comprometidos com o sigilo dos dados e com nossa política de privacidade.
        </p>
        <p>
          Se você está participando desta avaliação como parte de um programa de saúde do seu empregador, os médicos (somente os médicos) responsáveis do seu empregador poderão ter acesso aos resultados, em total e absoluto respeito ao Código de Ética Médica.
        </p>
        <p>
          Assim, em total observância à Lei Geral de Proteção de Dados (Lei no 13.709/2018), pedimos a sua livre, informada e inequívoca autorização para que a PREVINEO:
        </p>
        <p>
          a) trate todos os seus dados pessoais e dados pessoais sensíveis como estabelecidos no presente Termo;
        </p>
        <p>
          b) compartilhe as minhas informações pessoais e sobre o meu estado de promover melhores condições para minha saúde, com terceiros que tenham objetivo de oferecer produtos e serviços para promover melhores condições para minha saúde;
        </p>
        <p>
          c) disponibilizar meus dados clínicos, com a garantia da preservação de minha identificação, para fins estatísticos e de pesquisas em saúde com o objetivo de contribuir para o desenvolvimento de melhores práticas em saúde. Por exemplo, se você tem 20 anos, não fuma e mora em Curitiba-PR, seu risco de desenvolvimento de câncer de pulmão será considerado normal para sua idade e condição. Neste caso, o seu resultado comporá a estatística como uma pessoa de risco normal para desenvolvimento desta patologia, sem qualquer forma de identificação, apenas vinculado à região onde você reside.
        </p>
        <p>
          Quaisquer dúvidas ou esclarecimentos com relação a estes termos e condições podem ser previamente sanados por nosso endereço eletrônico <strong>contato@previneo.com.br</strong>.
        </p>`,

  'admin.dashboard.chart.foreseen': 'Previsto',
  'admin.dashboard.chart.fulfilled': 'Realizado',
  'admin.dashboard.chart.not.fulfilled': 'Não Realizado',
  'admin.dataTable.label.report': 'Relatório',
  'admin.common.report.Mental_Risk': 'Risco Mental',
  'admin.common.report.Mental_Historical': 'Histórico Mental',
  'admin.common.report.Personal_Data': 'Dados Pessoais',
  'admin.common.report.Medical_History': 'Histórico Médico',
  'admin.common.report.Medical_Risk': 'Risco',

  'admin.common.logoRight': 'Logo direita',
  'admin.common.logoLeft': 'Logo esquerda',
  'admin.common.justifications.title': 'Justificativa',
  'admin.common.justifications.title.plural': 'Justificativas',
  'admin.common.file': 'Arquivo',
  'admin.common.fileFront': 'Arquivo front',
  'admin.common.roles': 'Perfis de acesso',
  'admin.common.ageRange': 'Faixa Etária',
  'admin.permissions.title': 'Permissão',
  'admin.permissions.title.plural': 'Permissões',
  'admin.justifications.title': 'Justificativa',
  'admin.justifications.title.plural': 'Justificativas',
  'admin.faixasimc.abaixo': 'Abaixo do Peso',
  'admin.faixasimc.normal': 'Peso Normal',
  'admin.faixasimc.acima': 'Acima do Peso',
  'admin.faixasimc.obesidadeI': 'Obesidade I',
  'admin.faixasimc.obesidadeII': 'Obesidade II',
  'admin.faixasimc.obesidadeIII': 'Obesidade III',
  'admin.common.lastAnamnesis': 'Data da Anamnese',
  'admin.common.reSendEmail': 'Reenviar E-mail',
  'admin.common.normal': 'Normal',
  'admin.common.entity': 'Entidade',
  'admin.common.entities': 'Entidades',
  'admin.common.numberOfEmployees': 'Quantidade de Colaboradores',
  'admin.common.confirmation': 'Confirmação',
  'admin.common.confirmationNotFound': 'Registro não encontrado, clique no botão abaixo para receber um novo link.',
  'admin.common.editUser': 'Editar Usuário',

  /*New Screen Dashbord */
  'admin.common.registered': 'Cadastrados',
  'admin.common.total': ' Total',
  'admin.common.historic': 'Histórico Médico',
  'admin.common.accomplished': 'Realizado',
  'admin.common.no-accomplished': 'Não Realizado',
  'admin.age': 'Faixa de idade',
  'admin.female.age.group': 'Faixa Etária Feminino',
  'admin.male.age.group': 'Faixa Etária Masculino',
  'admin.common.masc': 'Masculino',
  'admin.common.fem': 'Feminino',
  'admin.common.summary': 'Resumo',
  'admin.common.risk.assessment': 'Avaliação de Risco',
  'admin.common.number.of.people': 'Quantidade de Pessoas identificadas com Alto Risco.',
  'admin.common.low.risk': '(Baixo Risco)',
  'admin.common.smoker': 'Sintomas',
  'admin.common.exams.are.missing': 'Faltam Exames',
  'admin.common.high.risk': '(Alto Risco)',
  'admin.common.exams.missing': 'Exames Pendentes',
  'admin.common.one.year': '+1 ano',
  'admin.common.late': 'Atrasado',
  'admin.common.in.day': 'Em dia',
  'admin.common.never': 'Nunca',
  'admin.common.qtd': 'Quantidade de Pessoas identificadas em {risk}.',
  'admin.common.mama': 'Mama',
  'admin.common.uterine': 'Útero',
  'admin.common.lung': 'Pulmão',
  'admin.common.colon': 'Cólon',
  'admin.common.prostate': 'Próstata',
  'admin.common.consolidated': 'Consolidado',
  'admin.common.qtd.age': 'Quantidade de Pessoas identificadas por Idade.',
  'admin.common.qtd.exams.missing.1': 'Quantidade de Pessoas identificadas com Exames',
  'admin.common.qtd.exams.missing.2': 'Pendentes (rastreamento atrasado).',
  'admin.common.amount': 'Quantidade',
  'admin.common.cardio': 'Cardio',
  'admin.common.mental': 'Mental',
  'admin.interviews': 'Entrevistas',
  'admin.common.monday': 'Segunda-feira',
  'admin.common.tuesday': 'Terça-feira',
  'admin.common.wednesday': 'Quarta-feira',
  'admin.common.thursday': 'Quinta-feira',
  'admin.common.friday': 'Sexta-feira',
  'admin.filling.interviews': 'Preenchimento das Entrevistas',
  'admin.common.qtd.filling.interviews': 'Quantidade de entrevistas preenchidas no período.',
  'admin.common.qtd.users': 'Quantidade de Usuários',
  'admin.common.funnel': 'Funil de Utilização',
  'admin.common.data.found': 'Nenhum dado encontrado!',
  'admin.common.only.air': 'Somente Ar',
  'admin.common.exams.only': 'Somente Exames',
  'admin.common.only.symptom': 'Somente Sintoma',
  'admin.common.exam.air': 'Ar + Exame',
  'admin.common.air.symptom': 'Ar + Sintoma',
  'admin.common.exam.symptom': 'Exame + Sintoma',
  'admin.common.air.exam.symptom': 'AR + Exame + Sintoma',
  'admin.common.deleted.users': 'Usuários Removidos',
  'admin.common.contact_us': 'Fale Conosco',
  'admin.common.how_about': 'Que tal agendar uma consulta para falar sobre seus resultados ou atualizar seus exames',
  'admin.common.nationality': 'Nacionalidade',
  'admin.common.document': 'Documento de Identificação',
  'admin.common.information': 'Informações',
  'admin.common.additional.information': 'Informações adicionais',

  'admin.high.qtd.segment': '- Quantidade de {risk} identificados por seguimentos.',
  'admin.common.active.dash': 'Habilitar dashboard',
  'admin.common.how_about' : 'Que tal agendar uma consulta para falar sobre seus resultados ou atualizar seus exames',
  'admin.high.qtd.segment': '- Quantidade de {risk} identificados por seguimentos.',
  'admin.prostate': 'Câncer de Próstata',
  'admin.uterine': 'Câncer de Útero',
  'admin.medical.male': 'Histórico Médico (Masculino)',
  'admin.medical.female': 'Histórico Médico (Feminino)',
  'admin.breast.cancer': 'Câncer de Mama',
  'admin.lung.cancer': 'Câncer de Pulmão',
  'admin.colon.cancer': 'Câncer de Cólon',
  'admin.mental': 'Mental',
  'admin.cardio': 'Cardio',
  'admin.all.risks': 'Todos os Riscos',
  'admin.high.risk': 'Alto Risco',
  'admin.medium.risk': 'Médio Risco',
  'admin.low.risk': 'Baixo Risco',
  'admin.common.active.dash' : 'Habilitar dashboard',

  /*Dashboard Api*/
  'registered': 'Cadastrados',
  'medical history': 'Histórico Médico',
  'interviews': 'Entrevistas',
  'male': 'Masculino',
  'female': 'Feminino',
  'all interviews': 'Todas entrevistas',
  'mental': 'Mental',
  'cardio': 'Cardio',
  'mama': 'Mama',
  'prostate': 'Próstata',
  'lung': 'Pulmão',
  'uterine': 'Útero',
  'colon': 'Cólon',
  'only air': 'Somente Ar',
  'exams only': 'Somente Exames',
  'only symptom': 'Somente Sintoma',
  'exam air': 'Ar + Exame',
  'air symptom': 'Ar + Sintoma',
  'exam symptom': 'Exame + Sintoma',
  'air exam symptom': 'AR + Exame + Sintoma',
  'normal risk': 'Risco Normal',

  /* Interviews BD*/
  'Qual é a sua altura ? (em CM)': 'Qual é a sua altura?',
  'Qual é o seu peso ? (em KG)': 'Qual é o seu peso?',
  'Infarto': 'Infarto',
  'Angina por obstrução das artérias do coração': 'Angina devido à obstrução das artérias do coração',
  'Derrame': 'Derrame',
  'Entupimento de artérias da perna': 'Obstrução das artérias da perna',
  'Dilatação (aneurisma) de Aorta': 'Dilatação (aneurisma) da aorta',
  'Mental': 'Saúde Mental',
  'Cardio': 'Cardiovascular',
  'Selecione todas as atividades abaixo, com as quais você tenha trabalhado sem a devida proteção:': 'Selecione todas as atividades abaixo com as quais você tenha trabalhado sem a devida proteção:',
  'Estou satisfeito com minhas atuais condições de trabalho': 'Estou satisfeito com minhas atuais condições de trabalho (ambiente físico, condições do home office, minhas atribuições e equipe de trabalho).',
  'Eu penso em mudar minhas condições atuais de trabalho (por exemplo, meu turno, o local de trabalho, etc)': 'Eu penso em mudar minhas condições atuais de trabalho (meu turno ou local de trabalho)',
  'Meu ambiente de trabalho me faz sentir inútil ou culpado.': 'Meu ambiente de trabalho me faz sentir incapaz ou culpado.',
  'Meu humor e meus sentimentos em relação ao meu trabalho facilitam meu desempenho em minha vida pessoal, social ou profissional.': 'Meu bom humor e meus sentimentos saudáveis em relação ao meu trabalho facilitam meu desempenho em minha vida pessoal, social ou profissional.',
  'Nos últimos 6 meses, senti tontura ou minha cabeça estava girando': 'Nos últimos 6 meses, senti tontura ou apagões.',
  'Nos últimos 6 meses, senti-me mal do estômago, quase como se fosse vomitar.': 'Nos últimos 6 meses, me senti enjoado, como se fosse vomitar.',
  'Eu sei quais recursos posso usar se sentir que preciso de apoio (por exemplo, benefícios de saúde mental, programas de assistência aos funcionários, suporte virtual ou no local).': 'Tenho conhecimento de quais recursos posso usar se sentir que preciso de apoio (por exemplo, benefícios de saúde mental, programas de assistência aos funcionários, suporte virtual ou no local).',
  'Sinto-me contente, alegre e consigo me conectar facilmente com as pessoas no trabalho.': 'Sinto-me contente, bem-humorado e consigo me conectar facilmente com as pessoas no trabalho.',
  'Interajo regularmente com meus colegas de trabalho, fazendo-me sentir que pertenço a um grupo, que estou seguro e protegido.': 'Interajo regularmente com meus colegas de trabalho, sentindo que pertenço a um grupo, que estou seguro e protegido.',
  'Sinto-me mais insensível com as pessoas desde que aceitei este trabalho': 'Sinto-me mais insensível com as pessoas desde que iniciei este trabalho',
  'Sinto-me à vontade para conversar com meu supervisor para mudar coisas estressantes sobre meu trabalho (por exemplo, carga de trabalho, folgas, mudança de tarefas/responsabilidades).': 'Sinto-me à vontade para conversar com meu supervisor para mudar coisas estressantes sobre meu trabalho (carga de trabalho, folgas, mudança de tarefas/responsabilidades).',
  'Sinto que realizei muitas coisas valiosas no meu trabalho.': 'Sinto que realizei muitas ações valiosas no meu trabalho.',
  'Sinto que tenho um papel e responsabilidades claramente definidos e que garantem que meu dia corra bem.': 'Sinto que tenho um papel e responsabilidades claramente definidas e que garantem que minha rotina diária corra bem.',
  'Sinto orgulho de que as pessoas entendam o valor do meu papel e da minha contribuição para a organização.': 'Sinto orgulho quando as pessoas entendem o valor do meu papel e da minha contribuição para a organização.',
  'Sinto que estou no controle de minhas prioridades, definindo o faço e quando faço.': 'Sinto que estou no controle das minhas prioridades, definindo o faço e quando faço.',
  'Nos últimos 6 meses, sinto que alcancei resultados no meu trabalho e minhas relações pessoais estão ótimas.': 'Nos últimos 6 meses, sinto que alcancei resultados no meu trabalho e minhas relações pessoais estão boas.',
  'Durante os últimos 30 dias, com que frequência você sentiu que qualquer coisa era um esforço?': 'Durante os últimos 30 dias, com que frequência você sentiu que qualquer ação era um esforço?',
  'O meu supervisor fornece o apoio de que necessito para gerir o meu trabalho': 'O meu supervisor fornece o apoio do qual necessito para gerir o meu trabalho',
  'Em geral, indivíduos com Diabetes tipo 1 manifestam o quadro nas duas primeiras décadas de vida, e precisam fazer uso de insulina. O Diabetes tipo 2 surge mais tarde, normalmente associado ao excesso de peso. Embora possa ser necessário uso de insulina, o' : 'Em geral, indivíduos com Diabetes tipo 1 manifestam o quadro nas duas primeiras décadas de vida, e precisam fazer uso de insulina. O Diabetes tipo 2 surge mais tarde, normalmente associado ao excesso de peso. Embora possa ser necessário uso de insulina, o controle habitualmente se faz com medicações via oral.',
  'After completing the form below, you will receive an email link to reset your password.' : 'Após completar o formulário abaixo, você receberá por email um link para redefinir sua senha.',

  'Câncer de Próstata (Masculino)': 'Câncer de próstata (masculino)',
  'Câncer de Útero (Feminino)': 'Câncer de útero (feminino)',
  'Histórico Médico (Masculino)': 'Histórico médico (masculino)',
  'Histórico Médico (Feminino)': 'Histórico médico (feminino)',
  'Câncer de Mama (Feminino)': 'Câncer de mama (feminino)',
  'Câncer de Pulmão (Unissex)': 'Câncer de pulmão (unissex)',
  'Câncer de Cólon (Unissex)': 'Câncer de cólon (unissex)',
  'Mental (Unissex)': 'Mental (unissex)',
  'Cardio (Unissex)': 'Cardio (unissex)',

  /* SUBDIAGNOSTICS */
  'Control': 'Controle',
  'Demands': 'Demandas',
  'Support': 'Suporte',
  'Relationship': 'Relação',
  'Role': 'Regras',
  'Change': 'Mudanças',
  'K10': 'K10',
  'Não Sou um robô': 'Não Sou um robô',
  'plugin.daterangepicker.settings': {
    'format': 'DD/MM/YYYY',
    'separator': ' - ',
    'applyLabel': 'Aplicar',
    'cancelLabel': 'Cancelar',
    'fromLabel': 'De',
    'toLabel': 'Para',
    'customRangeLabel': 'Custom',
    'weekLabel': 'W',
    'daysOfWeek': ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    'monthNames': [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    'firstDay': 1,
  },

  'plugin.OverrideMultiSlect': {
    'allItemsAreSelected': 'Todos',
    'clearSearch': 'Limpar Busca',
    'clearSelected': 'Limpar Seleção',
    'noOptions': 'Sem opções',
    'search': 'Buscar',
    'selectAll': 'Selecionar Todos',
    'selectAllFiltered': 'Selecionar tudo (Filtrado)',
    'selectSomeItems': 'Selecionados...',
    'create': 'Criar',
  },

  'variable.WORK.Well.INDIVIDUAL.Well': 'Suas respostas indicam que você não está experimentando sintomas relacionados à ansiedade ou estresse no local de trabalho. Em nível pessoal, suas respostas revelam que você está saudável, sem sintomas de estresse ou ansiedade geral.',
  'variable.WORK.Well.INDIVIDUAL.Low': 'Suas respostas indicam que você não está experimentando sintomas relacionados à ansiedade ou estresse no local de trabalho. Em nível pessoal, suas respostas revelam que você está saudável, com pouquíssimos sintomas de estresse ou ansiedade geral.',
  'variable.WORK.Low.INDIVIDUAL.Well': 'Suas respostas indicam que você não está experimentando sintomas importantes relacionados à ansiedade ou estresse no trabalho. Em nível pessoal, suas respostas revelam que você está saudável, sem sintomas de estresse ou ansiedade geral.',
  'variable.WORK.Well.INDIVIDUAL.Medium': 'Suas respostas indicam que você não está experimentando sintomas relacionados à ansiedade ou estresse no local de trabalho. Em nível pessoal, seus resultados indicam que você está experimentando alguns sintomas moderados relacionados ao estresse ou ansiedade geral.',
  'variable.WORK.Low.INDIVIDUAL.Low': 'Suas respostas indicam que você não está experimentando sintomas importantes relacionados à ansiedade ou estresse no trabalho. Em nível pessoal, suas respostas revelam que você está saudável, com pouquíssimos sintomas de estresse ou ansiedade geral.',
  'variable.WORK.Medium.INDIVIDUAL.Well': 'Suas respostas indicam que você está preocupado e acha que pode precisar de apoio no trabalho, por isso recomendamos que você fale com seu empregador ou consulte um profissional de saúde mental para uma avaliação completa.Em nível pessoal, suas respostas revelam que você está saudável, sem sintomas de estresse ou ansiedade geral.',
  'variable.WORK.Well.INDIVIDUAL.High': 'Suas respostas indicam que você não está experimentando sintomas relacionados à ansiedade ou estresse no local de trabalho. A nível pessoal, parece que o estresse está afetando sua vida. A maioria das pessoas se sente estressada em algum momento e seus sintomas são experiências comuns para a maioria da população.',
  'variable.WORK.Low.INDIVIDUAL.Medium': 'Suas respostas indicam que você não está experimentando sintomas importantes relacionados à ansiedade ou estresse no trabalho. Em nível pessoal, seus resultados indicam que você está experimentando alguns sintomas moderados relacionados ao estresse ou ansiedade geral.',
  'variable.WORK.Medium.INDIVIDUAL.Low': 'Suas respostas indicam que você está preocupado e acha que pode precisar de apoio no trabalho, por isso recomendamos que você fale com seu empregador ou consulte um profissional de saúde mental para uma avaliação completa. Em nível pessoal, suas respostas revelam que você está saudável, com pouquíssimos sintomas de estresse ou ansiedade geral.',
  'variable.WORK.High.INDIVIDUAL.Well': 'O local de trabalho pode ser muito desafiador. Suas respostas indicam que você pode precisar de apoio. A boa notícia é que sempre há um caminho. Você é mais forte do que qualquer coisa em seu caminho e há algumas estratégias que você pode usar para ajudar a gerenciar sua ansiedade sobre o trabalho. Em nível pessoal, suas respostas revelam que você está saudável, sem sintomas de estresse ou ansiedade geral',
  'variable.WORK.Low.INDIVIDUAL.High': 'Suas respostas indicam que você não está experimentando sintomas importantes relacionados à ansiedade ou estresse no trabalho. A nível pessoal, parece que o estresse está afetando sua vida. A maioria das pessoas se sente estressada em algum momento e seus sintomas são experiências comuns para a maioria da população.',
  'variable.WORK.Medium.INDIVIDUAL.Medium': 'Suas respostas indicam que você está preocupado e acha que pode precisar de apoio no trabalho, por isso recomendamos que você fale com seu empregador ou consulte um profissional de saúde mental para uma avaliação completa. Em nível pessoal, seus resultados indicam que você está experimentando alguns sintomas moderados relacionados ao estresse ou ansiedade geral.',
  'variable.WORK.High.INDIVIDUAL.Low': 'O local de trabalho pode ser muito desafiador. Suas respostas indicam que você pode precisar de apoio. A boa notícia é que sempre há um caminho. Você é mais forte do que qualquer coisa em seu caminho e há algumas estratégias que você pode usar para ajudar a gerenciar sua ansiedade sobre o trabalho. Em nível pessoal, suas respostas revelam que você está saudável, com pouquíssimos sintomas de estresse ou ansiedade geral.',
  'variable.WORK.Medium.INDIVIDUAL.High': 'Suas respostas indicam que você está preocupado e acha que pode precisar de apoio no trabalho, por isso recomendamos que você fale com seu empregador ou consulte um profissional de saúde mental para uma avaliação completa. A nível pessoal, parece que o estresse está afetando sua vida. A maioria das pessoas se sente estressada em algum momento e seus sintomas são experiências comuns para a maioria da população.',
  'variable.WORK.High.INDIVIDUAL.Medium': 'O local de trabalho pode ser muito desafiador. Suas respostas indicam que você pode precisar de apoio. A boa notícia é que sempre há um caminho. Você é mais forte do que qualquer coisa em seu caminho e há algumas estratégias que você pode usar para ajudar a gerenciar sua ansiedade sobre o trabalho. Em nível pessoal, seus resultados indicam que você está experimentando alguns sintomas moderados relacionados ao estresse ou ansiedade geral.',
  'variable.WORK.High.INDIVIDUAL.High': 'O local de trabalho pode ser muito desafiador. Suas respostas indicam que você pode precisar de apoio. A boa notícia é que sempre há um caminho. Você é mais forte do que qualquer coisa em seu caminho e há algumas estratégias que você pode usar para ajudar a gerenciar sua ansiedade sobre o trabalho. A nível pessoal, parece que o estresse está afetando sua vida. A maioria das pessoas se sente estressada em algum momento e seus sintomas são experiências comuns para a maioria da população.',

  //Tour
  "tour.greetings.title": "Vamos prevenir juntos?",
  "tour.greetings": "Estamos felizes em ter você aqui!",
  "tour.greetings.message": "O sistema Previneo te ajuda a dar o primeiro passo para a prevenção de maneira rápida e sem sair de casa!",
  "tour.first.step": "O primeiro passo é preencher o histórico médico. Essas informações serão importantes para o seu resultado.",
  "tour.estimated.time": "Tempo estimado de preenchimento: de 1 à 2 min",
  "tour.second.step": "Agora é só preencher a sua entrevista digital. Caso você tenha outras entrevistas, siga os mesmos passos.",
  "tour.third.step": "No menu, você irá encontrar as opções de: voltar para a tela inicial, sair do sistema e acesso à sua conta.",
  "tour.last.step.title": "Vamos preencher?",
  "tour.last.step": "Não esqueça que seus resultados estão dentro de cada entrevista digital, e você pode acessar mais informações em \"Detalhes da entrevista digital\".",
  "tour.finished": "Iniciar",
  "tour.start": "Vamos começar",
  "tour.skip": "Pular tour",
  "tour.next.step": "Próximo"
};
