import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { omit } from 'ramda'
import Base from 'components/Base'
import { hoverColor } from 'utils/colors'
import { componentType } from 'types'
import mediaQuery from 'utils/mediaQuery'
import Icon from '@fortawesome/react-fontawesome'

const filterProps = omit([
  'color',
  'textColor',
  'arrow',
  'block',
  'blockAtBreakpoint',
  'size'
])

const ButtonStyled = styled(props => <Base {...filterProps(props)} />)`
  display: inline-flex;
  width: ${props => props.block ? '100%' : 'auto'};
  padding: 15px 30px;
  font-family: ${props => props.theme.fonts.tertiary};
  font-weight: bold;
  font-size: 20px;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors[props.color] !== undefined ? props.theme.colors[props.color] : props.color};
  border: none;
  border-radius: 3px;
  color: ${props => props.theme.colors[props.textColor]};
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;

  ${props => props.size === 'small' && css`
    font-size: 12px;
    padding: 5px 10px;
    height: auto;
  `}

  &:not([disabled]):hover,
  &:not([disabled]):active {
    background-color: ${props => hoverColor(props.theme.colors[props.color] !== undefined ? props.theme.colors[props.color] : props.color)}
  }

  &:not([disabled]):active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125)
  }

  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  ${props => props.size !== 'small' && mediaQuery.lessThan('large')`
    font-size: 16px;
    padding: 10px 20px;
  `}

  ${props => props.blockAtBreakpoint && mediaQuery.lessThan(props.blockAtBreakpoint)`
    display: block;
    width: 100%;
  `}
`

const Arrow = styled.span`
  margin-left: 20px;
  opacity: 0.5;
`

export default function Button ({ children, arrow, icon, ...props }) {
  return (
    <ButtonStyled {...props}>
      {icon && <Icon icon={icon} />}
      {children}
      {arrow && (
        <Arrow aria-hidden>
          →
        </Arrow>
      )}
    </ButtonStyled>
  )
}

Button.propTypes = {
  /** Componente */
  component: componentType,

  /** Cor do botão */
  color: PropTypes.string,

  /** Cor do texto do botão */
  textColor: PropTypes.string,

  /** Inclui a seta */
  arrow: PropTypes.bool,

  /** Incluir ícone */
  icon: PropTypes.object,

  /** Deixa como display block */
  block: PropTypes.bool,

  /** Deixa como block a partir de tal breakpoint */
  blockAtBreakpoint: PropTypes.string,

  /** Tamanho do botão */
  size: PropTypes.string
}

Button.defaultProps = {
  component: 'button',
  color: 'primary',
  textColor: 'white',
  arrow: false,
  block: false
}
