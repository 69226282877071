/**
 * Dependencies.
 */

import updateObj from 'immutability-helper'
import objectPath from 'object-path'
import createActionTypes from 'store/utils/createActionTypes'
import createReducer from 'store/utils/createReducer'

/**
 * Action Types.
 */

export const actionTypes = createActionTypes('RESOURCES', [
  'REGISTER_RESOURCE',

  'RESET',

  'FETCH_ALL',
  'FETCH_ALL_PENDING',
  'FETCH_ALL_FULFILLED',
  'FETCH_ALL_REJECTED',

  'FETCH_ONE',
  'FETCH_ONE_PENDING',
  'FETCH_ONE_FULFILLED',
  'FETCH_ONE_REJECTED',

  'FETCH_API',
  'FETCH_API_PENDING',
  'FETCH_API_FULFILLED',
  'FETCH_API_REJECTED',

  'CREATE',
  'CREATE_PENDING',
  'CREATE_FULFILLED',
  'CREATE_REJECTED',

  'UPDATE',
  'UPDATE_PENDING',
  'UPDATE_FULFILLED',
  'UPDATE_REJECTED',

  'REMOVE',
  'REMOVE_PENDING',
  'REMOVE_FULFILLED',
  'REMOVE_REJECTED'
])

/**
 * Initial State.
 */

const resourceState = {
  records: [],
  detailedRecords: {},
  data: {},
  logs: [],
  createdRecord: {},
  removedRecord: {},
  pagination: {},
  removingRecords: [],
  isSubmitting: false,
  isFetching: false,
  isRemoving: false,
  error: {}
}

/**
 * Reducer.
 */

export default createReducer({}, {
  [actionTypes.REGISTER_RESOURCE] (state, { payload: { resource } }) {
    return updateObj(state, {
      [resource]: { $set: { ...resourceState } }
    })
  },

  /**
   * Reset.
   */

  [actionTypes.RESET] (state, { payload: { resource } }) {
    return updateObj(state, {
      [resource]: { $set: { ...resourceState } }
    })
  },

  /**
   * Fetch All.
   */

  [actionTypes.FETCH_ALL_PENDING] (state, { meta: { resource } }) {
    return updateObj(state, {
      [resource]: {
        isFetching: { $set: true }
      }
    })
  },

  [actionTypes.FETCH_ALL_FULFILLED] (state, { payload: { data, meta: pagination }, meta: { resource, paginated } }) {
    return updateObj(state, {
      [resource]: {
        isFetching: { $set: false },
        records: { $apply: records => paginated ? records.concat(data) : data },
        pagination: { $set: pagination },
        error: { $set: {} }
      }
    })
  },

  [actionTypes.FETCH_ALL_REJECTED] (state, { payload, meta: { resource } }) {
    return updateObj(state, {
      [resource]: {
        isFetching: { $set: false },
        error: { $set: payload }
      }
    })
  },

  /**
   * Fetch One.
   */

  [actionTypes.FETCH_ONE_PENDING] (state, { meta: { resource } }) {
    return updateObj(state, {
      [resource]: {
        isFetching: { $set: true }
      }
    })
  },

  [actionTypes.FETCH_ONE_FULFILLED] (state, { payload: { data, log }, meta: { resource, id } }) {
    return updateObj(state, {
      [resource]: {
        isFetching: { $set: false },
        detailedRecords: {
          [id]: { $set: data }
        },
        logs: { $set: log },
        error: { $set: {} }
      }
    })
  },

  [actionTypes.FETCH_ONE_REJECTED] (state, { payload, meta: { resource } }) {
    return updateObj(state, {
      [resource]: {
        isFetching: { $set: false },
        error: { $set: {} }
      }
    })
  },

  /**
   * FETCH_API
   */

   [actionTypes.FETCH_API_PENDING] (state, { meta: { resource } }) {
    return updateObj(state, {
      [resource]: {
        isFetching: { $set: true }
      }
    })
  },

  [actionTypes.FETCH_API_FULFILLED] (state, { payload: { data, log }, meta: { resource } }) {
    return updateObj(state, {
      [resource]: {
        isFetching: { $set: false },
        data: { $set: data },
        logs: { $set: log },
        error: { $set: {} }
      }
    })
  },

  [actionTypes.FETCH_API_REJECTED] (state, { payload, meta: { resource } }) {
    return updateObj(state, {
      [resource]: {
        data: { $set: {} },
        isFetching: { $set: false },
        error: { $set: {} }
      }
    })
  },

  /**
   * Create.
   */

  [actionTypes.CREATE_PENDING] (state, { meta: { resource } }) {
    return updateObj(state, {
      [resource]: {
        isSubmitting: { $set: true }
      }
    })
  },

  [actionTypes.CREATE_FULFILLED] (state, { payload: { data }, meta: { resource } }) {
    if (!data) {
      return updateObj(state, {
        [resource]: {
          isSubmitting: { $set: false },
          createdRecord: { $set: { data } },
          error: { $set: {} }
        }
      })
    }

    return updateObj(state, {
      [resource]: {
        isSubmitting: { $set: false },
        records: { $unshift: [ data ] },
        detailedRecords: { [data.id]: { $set: data } },
        createdRecord: { $set: { data } },
        error: { $set: {} }
      }
    })
  },

  [actionTypes.CREATE_REJECTED] (state, { payload, meta: { resource } }) {
    return updateObj(state, {
      [resource]: {
        isSubmitting: { $set: false },
        error: { $set: payload }
      }
    })
  },

  /**
   * Update.
   */

  [actionTypes.UPDATE_PENDING] (state, { meta: { resource } }) {
    return updateObj(state, {
      [resource]: {
        isSubmitting: { $set: true }
      }
    })
  },

  [actionTypes.UPDATE_FULFILLED] (state, { payload: { data }, meta: { resource } }) {
    return updateObj(state, {
      [resource]: {
        isSubmitting: { $set: false },
        detailedRecords: { [data.id]: { $set: data } },
        error: { $set: {} },
        records: {
          $apply: records => records.map(r => {
            if (r.id === data.id) {
              return data
            } else {
              return r
            }
          })
        }
      }
    })
  },

  [actionTypes.UPDATE_REJECTED] (state, { payload, meta: { resource } }) {
    return updateObj(state, {
      [resource]: {
        isSubmitting: { $set: false },
        error: { $set: payload }
      }
    })
  },

  /**
   * Remove.
   */

  [actionTypes.REMOVE_PENDING] (state, { meta: { resource, id } }) {
    return updateObj(state, {
      [resource]: {
        removingRecords: { $push: [ id ] },
        isRemoving: { $set: true }
      }
    })
  },

  [actionTypes.REMOVE_FULFILLED] (state, { payload: { data }, meta: { resource, id } }) {
    return updateObj(state, {
      [resource]: {
        removingRecords: { $apply: records => records.filter(r => r !== id) },
        records: { $apply: records => records.filter(r => r.id !== id) },
        isRemoving: { $set: false },
        removedRecord: { $set: { data } },
        error: { $set: {} }
      }
    })
  },

  [actionTypes.REMOVE_REJECTED] (state, { payload, meta: { resource, id } }) {
    return updateObj(state, {
      [resource]: {
        removingRecords: { $apply: records => records.filter(r => r !== id) },
        isRemoving: { $set: false },
        error: { $set: payload }
      }
    })
  }
})

/**
 * Action Creators.
 */

export const registerResource = resource => (dispatch, getState) => {
  const state = getState()
  if (state.resources.hasOwnProperty(resource)) return
  return dispatch({
    type: actionTypes.REGISTER_RESOURCE,
    payload: { resource }
  })
}

export const reset = resource => ({
  type: actionTypes.RESET,
  payload: { resource }
})

export const fetchAll = (config, params) => (dispatch, getState) => {
  if (config.paginated && params) {
    if (!params.page || params.page === 1) {
      dispatch(reset(config.resource))
    }
  }

  return dispatch({
    type: actionTypes.FETCH_ALL,
    payload: config.api(params),
    meta: {
      resource: config.resource,
      paginated: config.paginated,
      notifications: config.notifications
    }
  })
}

export const fetchOne = (config, id, params) => ({
  type: actionTypes.FETCH_ONE,
  payload: config.api(id, params),
  meta: {
    resource: config.resource,
    id,
    notifications: config.notifications
  }
})

export const fetchApi = (config, params) => ({
  type: actionTypes.FETCH_API,
  payload: config.api(params),
  meta: {
    resource: config.resource,
    notifications: config.notifications
  }
})

export const create = (config, data, params) => {
  return ({
    type: actionTypes.CREATE,
    payload: config.api(data, params),
    meta: {
      resource: config.resource,
      notifications: config.notifications
    }
  })
}

export const update = (config, id, data, params) => ({
  type: actionTypes.UPDATE,
  payload: config.api(id, data, params),
  meta: {
    resource: config.resource,
    id,
    notifications: config.notifications
  }
})

export const updateStatus = update

export const updatePassword = update

export const updateLang = update

export const remove = (config, id, params) => ({
  type: actionTypes.REMOVE,
  payload: config.api(id, params),
  meta: {
    resource: config.resource,
    id,
    notifications: config.notifications
  }
})

/**
 * Selectors.
 */

export const getRecords = (resource, state) =>
  objectPath.get(state.resources, `${resource}.records`, [])

export const getData = (resource, state) =>
  objectPath.get(state.resources, `${resource}.data`, {})

export const getDetailedRecords = (resource, state) =>
  objectPath.get(state.resources, `${resource}.detailedRecords`, {})

export const getDetailedRecord = (resource, id, state) =>
  getDetailedRecords(resource, state)[id] || {}

export const getError = (resource, state) =>
  objectPath.get(state.resources, `${resource}.error`, {})

export const getPagination = (resource, state) =>
  objectPath.get(state.resources, `${resource}.pagination`, {})

export const getRemovingRecords = (resource, state) =>
  objectPath.get(state.resources, `${resource}.removingRecords`, [])

export const isSubmitting = (resource, state) =>
  objectPath.get(state.resources, `${resource}.isSubmitting`, false)

export const isFetching = (resource, state) =>
  objectPath.get(state.resources, `${resource}.isFetching`, false)

export const isRemoving = (resource, state) =>
  objectPath.get(state.resources, `${resource}.isRemoving`, false)

export const getCreatedRecord = (resource, state) =>
  objectPath.get(state.resources, `${resource}.createdRecord`, {})

export const getRemovedRecord = (resource, state) =>
  objectPath.get(state.resources, `${resource}.removedRecord`, {})

export const getLogs = (resource, state) =>
  objectPath.get(state.resources, `${resource}.logs`, [])
