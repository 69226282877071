import { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

class Toggle extends Component {
  static propTypes = {
    /** Valor inicial do Toggle */
    defaultValue: PropTypes.any,

    /** Reseta o valor quando houver alteração de rota */
    resetOnRouteChange: PropTypes.bool,

    /** Children render function */
    children: PropTypes.func.isRequired
  }

  static defaultProps = {
    defaultValue: false,
    resetOnRouteChange: false
  }

  state = {
    value: this.props.defaultValue
  }

  toggle = () => {
    this.setState({
      value: !this.state.value
    })
  }

  componentDidUpdate ({ location }) {
    if (location !== this.props.location && this.props.resetOnRouteChange) {
      this.setState({ value: this.props.defaultValue })
    }
  }

  render () {
    return this.props.children({
      toggle: this.toggle,
      value: this.state.value
    })
  }
}

export default withRouter(Toggle)
