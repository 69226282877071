export default {
  'pages.home.title': 'PreviNEO - Revolution in preventing and fighting cancer',
  'pages.home.description':
    'PreviNEO brings together technology and scientific knowledge in early diagnosis and prevention of the main types of cancer found in Brazil.',
  'pages.about.title': 'Who we are - PreviNEO',
  'pages.about.description':
    'We work to prevent, diagnose and reduce the risks of incidence of the main types of cancer found in Brazil, by way of an oncology program and state of the art technology.',
  'pages.specialities.title': 'Specialities - PreviNEO',
  'pages.specialities.description':
    'At PreviNEO, we undertake specialist work in preventing, diagnosing and reducing the incidence risk in Prostate, Breast, Lung, Colon and Cervix cancer.',
  'pages.specialities.titleDescription':
    'In order to raise awareness about cancer prevention, you will find more information regarding this type of cancer below.<br />',
  'pages.customers.title': 'Customers - PreviNEO',
  'pages.customers.description':
    'At PreviNEO, we count on partner companies that make our benefits available to employees and their families. Access and meet who is with us in this fight!',
  'pages.blog.title': 'Blog - PreviNEO',
  'pages.blog.description':
    'Information is a decisive factor in early cancer diagnostic and effective prevention . Our Blog makes available data and news about our cause, do visit!',
  'pages.contact.title': 'Contact data - PreviNEO',
  'pages.contact.description': 'In order to settle doubts, send in suggestions or join us and become a partner, send us a message. Our team will be pleased to address you!',
  'pages.register.register': 'Register',
  'pages.register.first.access': 'My first login',
  'pages.register.code.continue': 'Continue',
  'pages.finalize.registration' : 'Finalize Registration',
  'pages.register.code.return': 'Return',
  'pages.login.title': 'Login - PreviNEO',
  'pages.login.description': 'Tela de Login',
  'pages.register.code': 'Company Name',

  /* Títulos dos links do menu */
  'site.menu.home': 'Home',
  'site.menu.about': 'Who we are',
  'site.menu.specialities': 'Specialities',
  'site.menu.customers': 'Customers',
  'site.menu.blog': 'Blog',
  'site.menu.contact': 'Contact data',

  /* Autenticação e cadastro de pacientes */
  'site.authenticated.login': 'Login',
  'site.authenticated.password': 'Password',
  'site.authenticated.password.redefine': 'Redefine password',
  'site.authenticated.password.redefine.text':
    'Reset your password in the fields below:',
  'site.authenticated.password.confirm': 'Confirm password',
  'site.authenticated.password.validate': 'Passwords must be the same',
  'site.authenticated.password.redefine.create':
    'An email with the link to reset your password has been sent to you!',
  'site.authenticated.password.redefine.success':
    'Your password has been successfully updated!',

  /* 404 */
  'site.notFound.title': 'Page not found',
  'site.notFound.message': '404 Page not found!',

  /* Login */
  'admin.forms.login.title': 'Login',
  'admin.forms.login': 'Email or ID Number',
  'admin.forms.login.password': 'Password',
  'admin.forms.login.button': 'Login',
  'admin.forms.login.passwordRecovery': 'I forgot my password',

  /* Password recovery */
  'admin.forms.passwordRecovery.title': 'Password recovery',
  'admin.forms.passwordRecovery.button': 'Recover',

  /* Hero Home */
  'section.home.hero.title':
    'Revolution in <strong> preventing </strong> and <strong> fighting </strong> cancer.',
  'section.home.hero.button': 'Learn more',

  /* Section Services Home */
  'section.home.services.title':
    'Technology and scientific knowledge in favor of a greater asset - <strong> life. </strong>',
  'section.home.services.description':
    '<p>PreviNEO acts through prevention and <strong> execution of early diagnostic </strong> for the 5 main types of cancer occurring in Brazil, and that correspond to a significant volume of breast, prostate, lung, colon and cervix cancer cases.</p><p><strong> For us, people come first</strong>, we offer solutions focused in each patient with the fundamental objective of promoting health for a growing number of people.</p>',

  /* Section Steps Home */
  'section.home.steps.title':
    "<strong>PreviNEO's</strong> work is done in the following steps",
  'section.home.steps.stepone.step': 'Step 1',
  'section.home.steps.stepone.title': 'Identification',
  'section.home.steps.stepone.description': 'Online or on site anamnesis.',
  'section.home.steps.steptwo.step': 'Step 2',
  'section.home.steps.steptwo.title': 'Tracking',
  'section.home.steps.steptwo.description':
    'Use of algorithms in stratifying risk populations.',
  'section.home.steps.stepthree.step': 'Step 3',
  'section.home.steps.stepthree.title': 'Guidance',
  'section.home.steps.stepthree.description': 'Getting in touch and guidance.',
  'section.home.steps.stepfour.step': 'Step 4',
  'section.home.steps.stepfour.title': 'Monitoring',
  'section.home.steps.stepfour.description':
    'Drafting of risk reduction strategies.',

  /* Section customers Home */
  'section.home.customers.title': 'Customers',
  'section.home.customers.description':
    "Our prevention programs already make a difference on the day to day of companies that, like us, place people's health and well-being in first place.",
  'section.home.customers.button': 'Check out which',

  /* Section Depoimentos Home */
  'section.home.testmonies.title': 'Our customers say it better than we do. ',
  'section.home.testmonies.internal.title':
    'Nothing speaks louder than the <strong> approval </strong> of people who already know',
  'section.home.testmonies.internal.subtitle':
    'Next to this, there are testimonials from <strong> our partners </strong>.',

  /* Section Blog Home */
  'section.home.blog.title': 'Our Blog',
  'section.home.blog.button': 'Check it all out',

  /* Section Parceiros */
  'section.partner.title': 'Be a PreviNEO partner',
  'section.partner.description':
    "Let's join forces? Together, we can continually reduce mortality from cancer in Brazil.",
  'section.partner.button': 'Speak to us',

  /* Hero About */
  'section.about.hero.title':
    'An oncology program to <strong> prevent </strong> and <strong> diagnose </strong>.',
  'section.about.hero.description':
    'At PreviNEO, we work in preventing, diagnosing and reducing risks of occurrence of the main types of cancer occurring in Brazil, through an oncology program and cutting edge technology. Our mission is a simple one: promote health and reduce the incidence and cancer related mortality in the country. To this end, we act on the types of cancer with higher incidence in Brazil, with a view to achieving a positive and deep impact on the national quality of life.',
  'section.about.hero.button': 'Learn more',

  /* Section Steps About */
  'section.about.steps.title':
    'How does the <strong> oncology </strong> program work?',
  'section.about.steps.description':
    'Our action method consists in three main steps: data analysis, risk calculation and guidance. Thus, you will be able to access a fully personalized, complete service and diagnosis range. <strong> Check out the step by step </strong>.',
  'section.about.steps.stepone.step': 'Step 1',
  'section.about.steps.stepone.description':
    'On site or off site execution of the anamnesis.',
  'section.about.steps.steptwo.step': 'Step 2',
  'section.about.steps.steptwo.description':
    'Stratification of the risk population through the oncology program.',
  'section.about.steps.stepthree.step': 'Step 3',
  'section.about.steps.stepthree.description':
    'Contact and guidance in establishing risk reduction strategies.',
  'section.about.steps.stepfour.step': 'Step 4',
  'section.about.steps.stepfour.description':
    'Scheduling exams and appointments with clinicians.',
  'section.about.steps.stepfive.step': 'Step 5',
  'section.about.steps.stepfive.description':
    'Monitoring of the risk reduction strategy.',

  /* Section Map About */
  'section.about.map.title': 'The <strong> first </strong> and only in Brazil!',
  'section.about.map.description':
    "<p>Established in <strong>2015</strong>, PreviNEO is Brazil's <strong>first</strong> and only company to work with a prevention program in oncology for prevention and diagnosis of cancer. Throughout our trajectory, we address over 48 thousand people in 22 companies.</p><p> Among the people reached through our partners, we have identified over <strong>5 thousand cases of patients at risk</strong>. We want to continue growing and taking information and quality of life to as <strong>many people as possible</strong>.</p>",

  /* Section Frase About */
  'section.phrase.title':
    'Together in <strong>reducing cancer-related <br />deaths</strong> in Brazil.',

  /* Hero Specialities */
  'section.specialities.hero.title':
    '<strong>Prevention</strong> that saves lives!',
  'section.specialities.hero.description':
    '<p>In fighting cancer, we work mainly with prevention and <strong>early diagnostic, resulting in customized strategies to reduce the incidence, late diagnosis and death from the disease.</strong></p><p> The objective is always the same: promote health throughout Brazil. For this reason, we act in the five main types of cancer present in Brazilians with a view to <strong>reducing mortality and costs through these diagnostics in Brazil.</strong></p>',

  /* Section Specialities */
  'section.specialities.types.title': 'The 5 main types of cancer',
  'section.specialities.types.subtitle':
    'At PreviNEO, we specialize in working with Prostate, Breast, Lung, Colon and Uterus cancer.',

  /* Hero Customers */
  'section.customers.hero.title': 'We are not alone!',
  'section.customers.hero.description':
    'In order to take advantage of the benefits of prevention and early diagnostic of cancer, we count on our partner companies that place our benefits at the disposal of their staff and family members. Thus, peace of mind and health reach increasingly farther, reinforcing the battle for life!',

  /* Section customers */
  'section.customers.list.title': 'Meet our <strong>customers</strong>!',

  /* Contact data */
  'section.contact.title': 'Contact us',
  'section.contact.subtitle':
    'In order to clarify doubts, send suggestions or praise, and become a partner, send us a message',

  /* Form Contact data */
  'form.contact.name': 'Name',
  'form.contact.phone': 'Telephone',
  'form.contact.email': 'Email',
  'form.contact.subject': 'Topic',
  'form.contact.message': 'Message',

  /* Admin Usuarios */
  'admin.users.title': 'User',
  'admin.users.title.plural': 'Users',
  'admin.users.form.sendPasswordEmail': 'Send Password by email',
  'admin.users.searchForUsers': 'Search by User',
  'admin.users.deletedUsers': 'Users deleted',
  'admin.common.restore': 'Recover',
  'admin.common.restoreSelected': 'Recover selected',

  /* Names comuns admin */
  'admin.common.name': 'Name',
  'admin.common.full.name': 'Full name',
  'admin.common.email': 'E-mail',
  'admin.common.emails': 'Emails',
  'admin.common.emailLayout': 'Email Layout',
  'admin.common.emailLayouts': 'Email Layouts',
  'admin.common.emailContent': 'Email content',
  'admin.common.emailContents': 'Email contents',
  'admin.common.password': 'Password',
  'admin.common.confirmPassword': 'Confirm Password',
  'admin.common.oldPassword': 'Old password',
  'admin.common.company': 'Company',
  'admin.common.accessLevels': 'Access Levels',
  'admin.common.save': 'Save',
  'admin.common.send': 'Send',
  'admin.common.listOf': 'List of',
  'admin.common.register': 'Register',
  'admin.common.edit': 'Edit',
  'admin.common.details': 'Details',
  'admin.common.remove': 'Remove',
  'admin.common.removePermanently': 'Remove permanently',
  'admin.common.back': 'Return',
  'admin.common.actions': 'Actions',
  'admin.common.warningMessage': 'Do you really want to remove this record?',
  'admin.common.AreYouSureABoutIt': 'Are you sure?',
  'admin.common.confirm': 'Confirm',
  'admin.common.cancel': 'Cancel',
  'admin.common.processing': 'Processing...',
  'admin.common.previous': 'Previous',
  'admin.common.next': 'Next',
  'admin.common.loading': 'Loading...',
  'admin.common.loadMore': 'Load More',
  'admin.common.noResultsFound': 'No results found',
  'admin.common.page': 'Page',
  'admin.common.Of': 'of',
  'admin.common.results': 'Results',
  'admin.common.idNumber': 'Registration',
  'admin.common.gender': 'Gender',
  'admin.common.genders': 'Genders',
  'admin.common.gender.male': 'Male',
  'admin.common.gender.female': 'Female',
  'admin.common.gender.unisex': 'Unisex',
  'admin.common.genderMale': 'Male',
  'admin.common.genderFemale': 'Female',
  'admin.common.dateOfBirth': 'Date of birth',
  'admin.common.companyPlaceHolder': 'Search Company',
  'admin.common.birthDatePlaceHolder': 'DD/MM/YYYY',
  'admin.common.filterData': 'Filter data...',
  'admin.common.mostRecent': 'Most recent',
  'admin.common.history': 'Full history',
  'admin.common.cpf': 'ID Number',
  'admin.common.weight': 'Weight (kg)',
  'admin.common.height': 'Height (cm)',
  'admin.common.phone': 'Telephone',
  'admin.common.cellphone': 'Cell',
  'admin.common.acceptContact': 'Accepts receiving additional information',
  'admin.common.yes': 'Yes',
  'admin.common.no': 'No',
  'admin.common.cep': 'Zip Code',
  'admin.common.cepTooltip': 'Enter your zip code (Ex: 73015-132)',
  'admin.common.codeTooltip': 'Enter your zip code (Ex: 73015)',
  'admin.common.zipcode.Tooltip': 'Insert your {label} (Ex: {mask})',
  'admin.common.country': 'Country',
  'admin.common.state': 'State',
  'admin.common.city': 'City',
  'admin.common.address': 'Address',
  'admin.common.addressNumber': 'Number',
  'admin.common.addressComplement': 'Complement',
  'admin.common.editCompany': 'Edit Company',
  'admin.common.registerCompany': 'Register Company',
  'admin.common.removeMessage': 'Do you really want to remove this company?',
  'admin.common.rootNohNotFound': 'Root node not found!',
  'admin.common.companyName': 'Company Name',
  'admin.common.cnpj': 'Company ID',
  'admin.common.parentCompany': 'Parent Company',
  'admin.common.expirationDate': 'Expiration Date',
  'admin.common.logo': 'Logo',
  'admin.common.logoTopRight': 'Logo upper right',
  'admin.common.logoTopLeft': 'Logo upper left',
  'admin.common.logoBottomRight': 'Logo lower right',
  'admin.common.logoBottomLeft': 'Logo lower left',
  'admin.common.openURL': 'URL Open',
  'admin.common.restrictedURL': 'URL with login',
  'admin.common.slugURL': 'Slug (Name after URL)',
  'admin.common.select': 'Select',
  'admin.common.typeSomethingToSearch': 'Type something to search',
  'admin.common.dateInsert': 'Insert a date',
  'admin.common.createdAt': 'Created on',
  'admin.common.fileImport': 'Import file',
  'admin.common.downloadModel': 'Download template',
  'admin.common.import': 'Import',
  'admin.common.export': 'Export',
  'admin.common.reportQueue': 'Report queue',
  'admin.common.title': 'Name',
  'admin.common.date': 'Date',
  'admin.common.hour': 'Hour',
  'admin.common.record_type': 'Record_type',
  'admin.common.e-mail': 'E-mail',
  'admin.common.telephone': 'Telephone',
  'admin.common.surname': 'Last Name',
  'admin.common.social_name': 'Social Name',
  'admin.common.road': 'Road',
  'admin.common.number': 'Number',
  'admin.common.complement': 'Complement',
  'admin.common.birth_date': 'Birth date',
  'admin.common.biological_genus': 'biological genus',
  'admin.common.social_gender': 'Social gender',
  'admin.common.ethnicity': 'Ethnicity',
  'admin.common.contact_via_phone': 'Contact via Phone',
  'admin.common.health_insurance': 'Health Plan',
  'admin.common.search': 'Search',
  'admin.common.searchForCompany': 'Search Company',
  'admin.common.men': 'Men',
  'admin.common.women': 'Women',
  'admin.common.script': 'Script',
  'admin.common.version': 'Version',
  'admin.common.risk': 'Risk',
  'admin.common.risks': 'Risks',
  'admin.common.question': 'Question',
  'admin.common.answer': 'Answer',
  'admin.common.calculation': 'Calculation',
  'admin.common.interval': 'Interval',
  'admin.common.start': 'Start',
  'admin.common.end': 'End',
  'admin.common.selectPatient': 'Select patients manually',
  'admin.common.high': 'High',
  'admin.common.medium': 'Medium',
  'admin.common.low': 'Low',
  'admin.common.description': 'Description',
  'admin.common.patientID': 'Patient ID',
  'admin.common.userID': 'User ID',
  'admin.common.anamnese': 'Anamnese',
  'admin.common.attention_level': 'Attention Level',
  'admin.common.exams_up_to_date': 'Exams up to date',
  'admin.common.symptoms': 'Symptoms',
  'admin.common.justifications': 'Justifications',
  'admin.common.assessment_date': 'Assessment Date',
  'admin.common.id': 'ID',
  'admin.common.accept_Contact': 'Do you accept Contact?',
  'admin.common.age': 'Age',
  'admin.common.genre': 'Genre',
  'admin.common.unit': 'Unit',
  'admin.common.registration_date': 'Registration date',
  'admin.common.response_date': 'Response Date',
  'admin.common.weigh': 'How much do you weigh ? (in KG)',
  'admin.common.use_of_medications': 'Use of medications',
  'admin.common.what_remedies': 'What remedies?',
  'admin.common.specify_the_remedys': 'Specify the remedy',
  'admin.common.smoke_cigars': 'Do you smoke or have you ever smoked cigars?',
  'admin.common.how_many_cigars':
    'In the time you smoked, how many cigars a day?',
  'admin.common.currently_smoke_cigars': 'Do you currently smoke cigars?',
  'admin.common.smoke_how_old':
    'If you stopped smoking cigars, how many years ago?',
  'admin.common.ever_smoked_cigarette':
    'Do you smoke or have you ever smoked cigarettes?',
  'admin.common.years_smoked_cigarettes':
    'How many years have you smoked cigarettes?',
  'admin.common.cigarettes_daily':
    'In the time you smoked, how many cigarettes a day?',
  'admin.common.currently_smoke_cigarettes':
    'Do you currently smoke cigarettes?',
  'admin.common.stopped_smoking_cigarettes':
    'If you stopped smoking cigarettes, how many years ago?',
  'admin.common.practice_physical_activity':
    'Do you practice any physical activity?',
  'admin.common.minutes_week': 'How many minutes a week on average?',
  'admin.common.performed_genetic_exam':
    'Has already performed BRCA1 or BRCA2?',
  'admin.common.mutation_search':
    'His response to the BRCA mutation test was positive. Is correct?',
  'admin.common.already_had_cancer': 'Have you ever had cancer?',
  'admin.common.type_of_cancer': 'What type of cancer?',
  'admin.common.specify_type_of_cancer': 'Specify the type of cancer',
  'admin.common.family_cancer_report':
    'Select alternatives about your family cancer history',
  'admin.common.alcohol_consumption': 'What is your daily alcohol consumption?',
  'admin.common.had_heart_problem': 'Have you ever had a heart problem?',
  'admin.common.like_heart_trouble': 'What kind of heart problem?',
  'admin.common.specify_heart_problem': 'Specify the heart problem',
  'admin.common.history_emotional_problems':
    'Do you have a history of emotional problems?',
  'admin.common.like_mental_problem': 'What kind of emotional problem?',
  'admin.common.specify_emotional_problem': 'Specify the emotional problem',
  'admin.common.chest x-ray': 'Have you ever had chest radiotherapy to treat lymphoma?',
  'admin.common.radiotherapy_affirmation': 'Did you say you had radiotherapy? Is this correct?',
  'admin.common.first_menstruation_age': 'How old were you when you had your first period?',
  'admin.common.age_first_child': 'At what age did you have the first (biological) child alive?',
  'admin.common.degree_relatives_breast_cancer': 'How many 1st degree relatives have had breast cancer?',
  'admin.common.confirms_relative_breast_cancer': 'You replied that a 1st degree relative has already had breast cancer. Correct?',
  'admin.common.did_breast_biopsy': 'Have you had a breast biopsy?',
  'admin.common.how_many_biopsies_done': 'How many biopsies were done?',
  'admin.common.atypical_alteration_biopsies':
    'Did any biopsy change with atypia?',
  'admin.common.last_mammogram': 'When did you have your last mammogram?',
  'admin.common.observe_palpable_lesions':
    'Do you notice any palpable lesions?',
  'admin.common.spontaneous_papilla_secretion':
    'Does it have a spontaneous secretion in the papillae?',
  'admin.common.change_shape_breast':
    'Did you notice any shape change in the breast?',
  'admin.common.had_sexual_intercourse':
    'Have you ever had sexual intercourse?',
  'admin.common.uterus_removal_surgery':
    'Have you ever had surgery to remove the uterus completely (hysterectomy)?',
  'admin.common.cervical_preventive_exam':
    'Have you had any preventive examination of the uterine cervix (pap)?',
  'admin.common.changes_pap_smear':
    'Have you ever changed NIC I, NIC II, NIC III HSIL or LSIL?',
  'admin.common.doses_vacina_hpv': 'Have you had 3 doses of HPV vaccine?',
  'admin.common.regular_use_condoms': 'During your life, did you make regular and constant use of condoms or diaphragms?',
  'admin.common.pain_during_intercourse': 'Do you experience intense pain during sexual intercourse?',
  'admin.common.bleeding_during_intercourse': 'Have you ever noticed bleeding during or after intercourse?',
  'admin.common.menstrual_period_bleedinge': 'Do you have any bleeding outside the menstrual period or after menopause?',
  'admin.common.lived_with_smokers': 'Have you lived with smokers for more than half your life?',
  'admin.common.worked_asbestos_protection': 'Have you ever worked with asbestos without proper protection?',
  'admin.common.work_without_protection': 'Activities related to which you have worked without proper protection',
  'admin.common.has_difficulty_breathing': 'Do you have shortness of breath or difficulty breathing?',
  'admin.common.presents_continuous_cough': 'Do you have a continuous or progressive cough?',
  'admin.common.relative_bowel_cancer': 'Have any 1st degree relatives ever had bowel cancer?',
  'admin.common.had_bowel_disease': 'Have you ever had any intestinal disease that had to remove a polyp (by colonoscopy)?',
  'admin.common.performed_exams': 'Have you ever performed the following tests: fecal occult blood test, sigmoidoscopy or colonoscopy?',
  'admin.common.suffer_from_constipation': 'Do you suffer from constipation (clogged bowel) or frequent diarrhea?',
  'admin.common.presence_blood_feces': 'Have you ever noticed the presence of blood in the stool?',
  'admin.common.relative_prostate_cancer': 'Have any 1st degree relatives ever had prostate cancer?',
  'admin.common.had_bowel_cancer': 'You replied that a 1st degree relative has had bowel cancer. Right?',
  'admin.common.renal_touch_exam': 'Have you ever had a digital rectal exam or PSA?',
  'admin.common.difficulty_urinating': 'Do you experience difficulty urinating or notice that the stream of urine is weaker',
  'admin.common.blood_output_urine': 'Did you notice blood in the urine?',
  'admin.common.empty_bladder_complete':
    'While urinating, Do you have the feeling that you cannot empty your bladder fully?',
  'admin.common.copy': 'Copy',
  'admin.common.status': 'Status',
  'admin.common.createIn': 'Create in',
  'admin.common.update': 'Last update',
  'admin.common.download': 'Download',
  'admin.common.riskSelect': 'Select a risk',
  'admin.common.observations': 'Observations',
  'admin.common.all': 'All',
  'admin.common.icon': 'Icon',
  'admin.common.categoryColor': 'Category color',
  'admin.common.tuss': 'TUSS',
  'admin.common.today': 'Today',
  'admin.common.visible': 'Visible',
  'admin.common.subtitle': 'Caption',
  'admin.common.specialtyColor': 'Color of specialty',
  'admin.common.image': 'Image',
  'admin.common.requiredFields': 'Mandatory fields',
  'admin.common.optionalFields': 'Optional fields',
  'admin.common.minimumAge': 'Minimum age',
  'admin.common.maximumAge': 'Maximum age',
  'admin.common.active': 'Active',
  'admin.common.inactive': 'Inactive',
  'admin.common.draft': 'Draft version',
  'admin.common.language': 'Language',
  'admin.common.link': 'Link',
  'admin.common.subject': 'Topic',
  'admin.common.content': 'Content',
  'admin.common.staff': 'Team',
  'admin.common.customer': 'Customer',
  'admin.common.customers': 'Customers',
  'admin.common.value': 'Value',
  'admin.common.contactInfo': 'Contact data information',
  'admin.common.contactInfos': 'Contact data information',
  'admin.common.site': 'Site',
  'admin.common.blog': 'Blog',
  'admin.common.post': 'Post',
  'admin.common.posts': 'Posts',
  'admin.common.filter': 'Filter',
  'admin.common.continueReading': 'Read more',
  'admin.common.permalink': 'Permanent Link ',
  'admin.common.category': 'Category',
  'admin.common.categories': 'Categories',
  'admin.common.blogCategory': 'Blog category',
  'admin.common.blogCategories': 'Blog categories',
  'admin.common.tag': 'Tag',
  'admin.common.tags': 'Tags',
  'admin.common.myAccount': 'My account',
  'admin.common.signOut': 'Log out',
  'admin.common.personalData': 'Personal data',
  'admin.filter': 'Patient Filter',
  'admin.filterTable': 'Filters',
  'admin.filterReportsTable': 'Filters',
  'admin.filterReports': 'Filters',
  'admin.common.editPassword': 'Change Password',
  'admin.common.report': 'Report',
  'admin.common.reports': 'Reports',
  'admin.common.report.medicalHistory': 'Medical History Report',
  'admin.common.report.risk': 'Risk Report',
  'admin.common.report.personalData': 'Personal Data Report',
  'admin.common.report.mentalHistorical': 'Mental History Report',
  'admin.common.report.mentalRisk': 'Mental Risk Report',
  'admin.common.reportsCompany': 'Company Reports',
  'admin.report.personal_data': 'Report (Personal Data)',
  'admin.report.risk': 'Report (Risk)',
  'admin.report.medical.history': 'Report (Medical History)',
  'admin.report.mental': 'Report (Mental)',
  'admin.history.mental': 'Report (Mental History)',
  'admin.common.patientsFilter': 'Patient Filter',
  'admin.common.unavailable': 'Unavailable',
  'admin.common.queue': 'Queue',
  'admin.common.personalInformations': 'Personal information',
  'admin.common.continue': 'Continue',
  'admin.common.dontKnow': "I don't know",
  'admin.common.didNotDoIt': 'I did not do it',
  'admin.common.ethnicity.white': 'White',
  'admin.common.ethnicity.black': 'African descendant',
  'admin.common.ethnicity.brown': 'Mixed race',
  'admin.common.ethnicity.yellow': 'Oriental',
  'admin.common.ethnicity.indigenous': 'Native American indian',
  'admin.common.others': 'Others',
  'admin.common.clickHere': 'Click here',
  'admin.common.result': 'Result',
  'admin.common.recommendations': 'Recommendations',
  'admin.common.recommendedExams': 'Recommended exams',
  'admin.common.max': 'Maximum',
  'admin.common.min': 'Minimum',
  'admin.common.format': 'Format',
  'admin.common.integer': 'Whole number',
  'admin.common.none': 'None',
  'admin.common.displayBeforeAnamnesis': 'Display before anamnesis',
  'admin.common.group': 'Group',
  'admin.common.author': 'Author',
  'admin.common.selectRiskForEmails':
    'Select level of risk desired to trigger alert',
  'admin.common.requiredHistoryMedicalCategories':
    'The categories you have selected require all medical records categories',
  'admin.common.emailSent': 'Email sent',
  'admin.common.menstrual_period_bleeding':
    'Do you have any bleeding outside of your menstrual period or after menopause?',
  'users.results.card.seeResult': 'Review Results',
  'users.results.card.fillText': 'Start Filling',
  'users.with.deleted': 'With removed',
  'users.not.deleted': 'No removed',
  'admin.common.permission.page':
    'You do not have permission to access this page.',

  /* Dashboard */
  'admin.dashboard.charts.total': 'Total',
  'admin.dashboard.charts.ofTotal': 'of the total',
  'admin.dashboard.charts.historyCancer': 'Users with history of cancer events',
  'admin.dashboard.charts.patientsNeedContact': 'Users requiring contact data',
  'admin.dashboard.charts.anamnesisFulfillments':
    'Number of anamnesis completed',
  'admin.dashboard.charts.answersByAgeAndGender':
    'Volume of respondents by age and gender',
  'admin.dashboard.charts.highRiskByCancerType':
    'Volume of High Risk by Type of Cancer',
  'admin.dashboard.charts.bmi': 'BMI',
  'admin.dashboard.charts.examsPending': 'Exams missing',
  'admin.dashboard.charts.riskAssessment': 'Risk Assessment',
  'admin.dashboard.charts.riskAssessmentSufix':
    'Number of people identified with high risk',
  'admin.dashboard.charts.highRiskSummary': 'Number of high risks identified',
  'admin.dashboard.charts.highRisk': 'High Risk',
  'admin.dashboard.charts.normalRisk': 'Normal Risk',
  'admin.dashboard.charts.summary': 'Summary',
  'admin.dashboard.charts.totalFilled': 'Number of anamnesis completed',
  'admin.dashboard.charts.totalFilledByGender':
    'Number of anamnesis completed by gender',
  'admin.dashboard.charts.totalFilledByAge': 'Number performed by age bracket',
  'admin.dashboard.charts.totalDone': 'Number completed',
  'admin.dashboard.charts.totalForeseen': 'Foreseen',
  'admin.dashboard.charts.highRiskChart': 'HIGH RISK',
  'admin.dashboard.charts.normalRiskChart': 'NORMAL RISK',
  'admin.dashboard.charts.smoker': 'Smoker',

  /* Companys */
  'admin.company.title': 'Company',
  'admin.company.title.plural': 'Companies',

  /* Plano de Saúde */
  'admin.healthInsurance.title': 'Health Plan',
  'admin.healthInsurance.title.plural': 'Health insurance',

  /* Indicação de Empresa*/
   'admin.recommendation.title': 'Recommandation',

  /* Perfil de Acesso */
  'admin.roles.title': 'Access profile',
  'admin.roles.title.plural': 'Access profiles',
  'admin.formRole.permissions': 'Permissions',

  /* Idiomas */
  'admin.languages.title': 'Language',
  'admin.languages.title.plural': 'Languages',
  'admin.languages.slug': 'Slug',

  /* Patients */
  'admin.patients.title': 'Patient',
  'admin.patients.title.plural': 'Patients',
  'admin.patients.listOfPatients': 'List of Patients',
  'admin.patients.importPatients': 'Import Patients',
  'admin.patients.exportPatients': 'Export Patients',
  'admin.patients.exportText':
    "Select the filters of your preference and click on 'Export' to request Patient reports. The time for the document to be ready depends on the queue in the system and the export criteria applied.",
  'admin.patients.fields.gender': 'M or F',
  'admin.patients.fields.cpf':
    'Only numbers (05376483649) or formatted (768.884.253-04)',
  'admin.patients.fields.phone':
    'Only Numbers (41993241556) or formatted ((41) 99324-1556)',
  'admin.patients.fields.birthday': 'YYYY-MM-DD (Example 1990-06-20)',
  'admin.patients.fields.cep':
    'Only numbers (93530280) or formatted (93530-280)',

  /* Prontuário */
  'admin.medicalRecords.title': 'Medical records',
  'admin.medicalRecords.title.plural': 'Medical records',
  'admin.medicalRecords.open': 'Open patient records',
  'admin.medicalRecords.close': 'Close patient records',
  'admin.medicalRecords.noInformation': 'No information available',
  'admin.medicalRecords.gail.risk1':
    'Estimated risk of patient developing breast cancer in the next 5 years.',
  'admin.medicalRecords.gail.risk2':
    'Risk of general population developing breast cancer in the next 5 years',
  'admin.medicalRecords.gail.risk3':
    'Mean risk of patient developing breast cancer by the age of 90 years old',
  'admin.medicalRecords.gail.risk4':
    'Mean risk of population developing breast cancer by the age of 90 years old',
  'admin.medicalRecords.madeContact': 'Contact successfully established',
  'admin.medicalRecords.noMoreContact': 'Does not require new contact',
  'admin.medicalRecords.noMoreContactQuestion': 'Requires new contact?',
  'admin.medicalRecords.threeContactsMessage': '3 contact attempts',

  /* Tipos de Câncer */
  'admin.cancerTypes.title': 'Type of Cancer',
  'admin.cancerTypes.title.plural': 'Types of Cancer',

  /* Estratégias */
  'admin.strategies.title': 'Strategy',
  'admin.strategies.title.plural': 'Strategies',

  /* Exames Recomendados */
  'admin.exams.title': 'Exam',
  'admin.exams.title.plural': 'Recommended Exams',

  /* Site */
  'admin.site.title': 'Site',
  'admin.testimonies.title': 'Testimonial',
  'admin.testimonies.title.plural': 'Testimonials',
  'admin.testimonies.author': 'Author of testimonial',
  'admin.specialities.title': 'Specialty',
  'admin.specialities.title.plural': 'Specialties',

  /* Anamnesis, */
  'admin.anamnesis.title': 'Anamnesis',
  'admin.anamnesis.title.plural': 'Anamnesis',

  /* Categories */
  'admin.categories.title': 'Category',
  'admin.categories.title.plural': 'Categories',

  /* Script */
  'admin.script.title': 'Script',
  'admin.script.title.plural': 'Scripts',
  'admin.script.categoryAlreadyAdded': 'Category already registered',
  'admin.script.addCategory': 'Add Category',
  'admin.script.addAnswer': 'Add Answer',
  'admin.script.addQuestion': 'Add Question',
  'admin.script.editQuestion': 'Edit Question',
  'admin.script.editAnswer': 'Edit Answer',
  'admin.script.valueAnswer': 'Value of Answer',
  'admin.script.questionType': 'Type of Question',
  'admin.script.selectTheQuestionType': 'Select type of Question',
  'admin.script.questionTitle': 'Question name',
  'admin.script.questionDescription': 'Description of Question',
  'admin.script.questionTypes.objective': 'Objective',
  'admin.script.questionTypes.discursive': 'Discursive',
  'admin.script.answer': 'Answer',
  'admin.script.question': 'Question',
  'admin.script.category': 'Category',
  'admin.script.status': 'Status of Script',
  'admin.script.requiredRegistration': 'Mandatory registration number',
  'admin.script.requiredPhone': 'Mandatory telephone number',
  'admin.script.requiredCellphone': 'Mandatory mobile number',
  'admin.script.requiredHealthcareInsurance':
    'Mandatory health insurance information',
  'admin.script.minimumPeriod':
    'Minimum períod to answer this script again (in months)',
  'admin.script.displayResultPageWithRisks': 'Show page with risk results',
  'admin.script.sendResultForEmail': 'Send result by email',
  'admin.script.sendResultForEmailPeriod':
    'Timeframe to send results (in days)',
  'admin.script.sendReminderEmail': 'Send reminder by email',
  'admin.script.sendReminderEmailPeriod': 'Timeframe for sending (in days)',
  'admin.script.sendReminderEmailQuantity': 'Number of sends',
  'admin.script.resultPageContent': 'Content of result page',
  'admin.script.initialPageContent': 'Content of landing page',
  'admin.script.displayFooter': 'Show footnotes',
  'admin.script.questionGroupError':
    'One group cannot be registered inside other groups',
  'admin.script.additionalInfoPageContent': 'Additional information',
  'admin.script.displayAdditionalInfoPageContent': 'Show additional information',
  'admin.anamnesis.coming_soon': 'Diagnostics available soon',

  /* Disparo de Script */
  'admin.scriptSend.title': 'Trigger Script',
  'admin.scriptSend.exportCsvFile': 'Download CSV file',
  'admin.scriptSend.sendEmail': 'Send email',
  'admin.scriptSend.sentScripts': 'Scripts Triggered',

  /* Membros da Equipe */
  'admin.team.title': 'Team member',
  'admin.team.title.plural': 'Team members',

  /* Link Youtube*/
  'admin.common.linkVideo': 'YouTube link',

   /* Link Agendamento Exame*/
   'admin.common.link.agendamento.exame': 'Exam scheduling link',

  /* ValidActions */
  'validations.required': 'Required field',
  'validations.integer': 'Field must be a whole number',
  'validations.integerBetween': 'Enter a number between {min} and {max}',
  'validations.email': 'Invalid email',
  'validations.link': 'Invalid link',
  'validations.phone': 'Invalid telephone',
  'validations.cpf': 'Invalid ID NUMBER',
  'validations.cnpj': 'Invalid CNPJ',
  'validations.cep': 'Invalid Zip Code',
  'validations.date': 'Invalid date',
  'validations.password': 'Must contain at least 8 characters with numbers, uppercase and lowercase letters',
  'validations.passwordConfirm': 'Passwords must be the same',
  'validations.fileType': 'Unsupported file type',
  'validations.enter.full.name': 'Enter your first and last name',
  'validations.enter.not.special.character': 'The name cannot contain special characters',
  'validations.required.age': 'To continue with the registration you must be over 18 years old.',

  /* Feedback */
  'feedback.success': 'Action successfully executed!',
  'feedback.error': 'Oops! There was a problem!',

  /* Field Hints */
  'hints.cpf': 'Enter your ID NUMBER with or without punctuation',
  'hints.cpfNumberOnly': 'Insert your CPF (Brazilian Taxpayer ID)  without punctuation (numbers only)',
  'hints.number': 'Only numbers',
  'hints.email': 'Insert your Email (Ex: example@example.com)',
  'hints.height': 'Inform your height in centimeters (cm)',
  'hints.weight': 'Insert your weight em kilograms (kg)',
  'hints.dateOfBirth': 'The date must be in MM/DD/YYY format (example: 10/25/1950)',

  /* Anamnesis, */
  'q.startPrevention': 'Start Filling',
  'q.backToAnamnese': 'Back to anamnesis',
  'q.initialText': 'Customer initial Text',
  'q.wizardStep': '{progress} step of {count}',
  'q.acceptContact': 'I accept contact via phone or email',
  'q.termsAccept': '	I accept the',
  'q.termsOfService': 'Consent Terms',
  'q.accept.receive': 'I accept to receive',
  'q.additional.information.whatsapp' : 'additional information from Previneo via WhatsApp',
  'q.termsAndConditions': 'Terms and conditions',
  'q.termsAndConditionsUnimed': 'TERM OF CONSENT AND AUTHORIZATION',
  'q.acceptRequired': 'You must accept the terms',
  'q.brca.question': 'Have you ever done a genetic exam (known as BRCA1 or BRCA) to know whether there a change/mutation that might increase your risk of developing cancer and, if so, was the result positive?',
  'q.brca.description': 'Mutation in genes BRCA1 or BRCA2 significatly increase the risk for some types of cancer (breast, ovary, prostate, colon or pancreas). This genetic exam may be performed through blood or saliva samples to find out whether the person has this mutation. This mutation usually occurs in people with cancer history in close family members. It is estimated that approximately 5% to 10% of all breast and ovary cancer cases are caused by this mutation',
  'q.ethnicity.question': 'Race',
  'q.ethnicity.description': 'People of Black ethnicity are known to be at increased risk of developing prostate cancer',
  'q.cancerHistory.question': 'Do you have a personal history of cancer?',
  'q.cancerHistory.description':
    'It is known that people with prior cancer events have a high risk of relapse or eventually a new tumor of the same type. Because of this, for the purpose of calculating your risk, it is important to establish whether you have had this disease before in your life.',
  'q.cancerHistory.whatCancer': 'What type of cancer?',
  'q.cancerHistory.specifyCancer': 'Specify the type of cancer',
  'q.helloPatient': 'Hello, {name}',
  'q.patientIntroText': 'The time has come for us to get to know you and some of your medical history. Fill in the fields accurately, so that your risk assessment is done in the best way. Shall we begin?',
  'q.startAvaliation': 'Start Evaluation',
  'q.profileUnmatch':
    'The anamnesis of this campaign do not suit your profile. Thanks for helping us this far!',
  'q.finishAvaliation': 'End evaluation',
  'q.toUpdateYourData': 'to update your personal data',
  'q.stepFinished': 'One more step concluded!',
  'q.youAreDoingRight':
    'You are correctly filling in the anamnesis data. Keep it up!',
  'q.continueAvaliation': 'Continue assessment',
  'q.toAnswerTheQuestions': 'to answer the questions',
  'q.thanks': 'Thank you for carrying out the review!',
  'q.sentForEmail': 'The result has been sent to your email.',
  'q.welcomeUser': 'Welcome, {name}',

  'admin.dashboard.chart.foreseen': 'Foreseen',
  'admin.dashboard.chart.fulfilled': 'Done',
  'termsUnimed': `<p>Consinto e autorizo expressamente a coleta dos meus dados pessoais através de questionário de saúde a ser disponibilizado via link pela empresa 
  <b>PREVINEO PROGRAMAS DE SAÚDE S.A.</b>
  , que gira sob a denominação fantasia de 
  <b>PREVINEO</b>
  , pessoa jurídica de direito privado, inscrita no CNPJ/MF sob no 22.177.298/0001-42, com sede na Rua Petit Carneiro, no 1122, sala 405, Bairro Água Verde, Curitiba/PR, CEP 80240-050, responsável pela coleta, tratamento e armazenamento dos referidos dados, bem como aceito, após o recebimento do resultado da minha classificação de risco, o contato, em horário comercial, de profissional da saúde da empresa 
  <b>PREVINEO</b> 
  que fornecerá orientações sobre prevenção das doenças objeto do estudo. Ainda, consinto e autorizo expressamente o compartilhamento dos meus dados pessoais coletados com a 
  <b>UNIMED CURITIBA – SOCIEDADE COOPERATIVA DE MÉDICOS</b>
  , cooperativa de 1o grau devidamente constituída, registrada na OCEPAR - Organização das Cooperativas do Estado do Paraná sob no 80 - 29/12/72, atuando como Operadora de Planos Privados de Assistência à Saúde, registrada na ANS - Agência Nacional de Saúde Suplementar sob no 30470-1, registrada na Junta Comercial do Paraná sob no 4140000865-7 e cadastrada no CNPJ/MF sob no 75.055.772/0001-20, com sede na Avenida Affonso Penna, no 297, Bairro Tarumã, Curitiba/PR, CEP 82530-280, telefone (41) 3021-9100, a fim de que esta possa promover estudos relacionados à estratificação de riscos oncológicos.</p>
   <p>Assim, em total observância à Lei Geral de Proteção de Dados (Lei no 13.709/2018), manifesto-me livre, informada e inequivocadamente no sentido de autorizar a 
   <b>UNIMED CURITIBA</b> e a 
   <b>PREVINEO</b> 
   a:</p> 
   <p>a) tratar todos os meus dados pessoais e dados pessoais sensíveis como sigilosos e confidenciais, protegendo-os e restringindo a sua utilização aos fins estabelecidos no presente Termo, prevenindo e evitando que tais dados sejam usados para quaisquer outros fins ou divulgados a terceiros não envolvidos no objeto do estudo;</p> 
   <p>b) compartilhar as informações sobre o meu estado de saúde, durante o desenvolvimento do estudo, a terceiros contratados envolvidos no processo;</p> 
   <p>c) disponibilizar os dados clínicos de meu acompanhamento, com a garantia da preservação de minha identificação, para fins estatísticos e de pesquisas em saúde com o objetivo de contribuir para o desenvolvimento de melhores práticas em saúde, ainda que a minha condição de beneficiário(a) da <b>UNIMED CURITIBA</b> venha a não mais existir futuramente.</p>`,
  'terms':
    '' +
    '<p>' +
    '   <strong>Privacy Policy and Use Authorization</strong>' +
    '</p>' +
    '<p>' +
    '   We are a company dedicated to the promotion of medical information, targeted at the prevention of some types of cancer in Brazil. We do this because we believe that the more information, the greater the chances of an early diagnosis, which is fundamental for cancer prevention.' +
    '</p>' +
    '<p>' +
    '   The form in which we disclose the information is a preformatted form, based on medical literature and nationally and internationally recognized methods to verify the presence of one or more risk factors that increase the estimated risk of developing a pathology. To this end, we require some personal information from you, including your identification data, family history of pathologies as well as data in connection with your daily life.' +
    '</p>' +
    '<p>' +
    '   For example, we will need to know if you smoke or have ever smoked, if you have had sexual relations, and if you have already had any type of preventative testing. These and other information are essential to achieve a correct estimate of the risk of developing the types of cancer applicable to the forms.' +
    '</p>' +
    '<p>' +
    '   <strong>How will your data be used?</strong>' +
    '</p>' +
    '<p>' +
    '   All personal data you provide will be kept in absolute secrecy and will be used only for the purpose of obtaining the result regarding your risk of developing the pathologies tested. This applies both to identification data (such as your name, email address, age, etc.) as well as to the answers to the forms, i.e. your medical records or daily life data. We will use your email exclusively for the purpose of sending the results and will not pass this information on to any third parties or use it for any other purpose.' +
    '</p>' +
    '<p>' +
    '   <strong>Who has access to my data?</strong>' +
    '</p>' +
    '<p>' +
    '   In order to obtain the results, your answers will be analyzed automatically and checked manually by employees of Previneo, linked to the healthcare area. The tool was developed by doctors, but the analysis of the data is not done by them, since we do not provide healthcare services, but only disclosure of information. Our employees are committed to the confidentiality of the data and our privacy policy.        ' +
    '</p>' +
    '<p>' +
    "   If you are participating in this evaluation as part of your employer's health program, the responsible physicians (and only the physicians) of your employer may have access to the results in full and absolute respect to the Code of Medical Ethics." +
    '</p>' +
    '<p>' +
    '   <strong>What if I authorize the use of the data in the form of statistics?</strong>' +
    '</p>' +
    '<p>' +
    '   By completing our form, we ask that in addition to accepting our terms of use, you also agree that the respective results may constitute a database for the establishment of prevention policies. After all, in addition to providing adequate information, our tool can also serve to identify flaws or necessary corrections in cancer diagnosis methods in Brazil.' +
    '</p>' +
    '<p>' +
    '   In this case, if you agree, the results obtained in completing your form can be used to comprise statistics without any personal identification or sensitive data. We will maintain absolute secrecy in relation to these data.</p>' +
    '<p>' +
    '   For example, if you are 20 years old, do not smoke and live in Curitiba-PR, your risk of developing lung cancer will be considered normal for your age and condition. In this case, your result will compose the statistic as a normal risk person for the development of this pathology, without any form of identification, only linked to the region where you reside.' +
    '</p>' +
    '<p>' +
    '   Any doubts or clarifications can be previously settled through our email <strong>contato@previneo.com.br</strong>.        ' +
    '</p>',

  'admin.dashboard.chart.not.fulfilled': 'Not done',
  'admin.patientsHistory.currentSituation': 'Current Situation',
  'admin.common.nextContact': 'Next Contact',
  'admin.common.contactReason': 'Contact Reason',
  'admin.common.typesCancer': 'Types of Cancer',
  'admin.common.patientHistoryExams': 'Exams Performed',
  'admin.common.saveContact': 'Save Contact',
  'admin.patientsHistory.status.pending': 'Start',
  'admin.patientsHistory.status.inProgress': 'Pause',
  'admin.patientsHistory.status.done': 'End',
  'admin.common.contactMade': 'Contacted?',
  'admin.common.contactMadeOk': 'Yes',
  'admin.common.contactMadeNo': 'No',
  'admin.common.contactStatus': 'Status',
  'admin.common.contactStatuses': 'Status',
  'admin.common.managePatientHistory': 'Contact',
  'admin.common.patientInfo': 'Patient data',
  'admin.patients.deletedPatients': 'Patients removed',
  'admin.common.motivo': 'Reason',
  'admin.common.hidePatientInfo': 'Hide Contact Information',
  'admin.common.displayPatientInfo': 'Display Contact Information',
  'admin.common.noPermission': "You don't have permission to access this page",
  'admin.patientsHistory.titlePlural': 'Medical Records',
  'admin.patientsHistory.contact': 'Contact',
  'admin.patientsHistory.contactReasonPlural': 'Reasons for contact',
  'admin.common.registerNew': 'Register',
  'admin.common.logoRight': 'Logo right',
  'admin.common.logoLeft': 'Logo left',
  'admin.common.justifications.title': 'Justification',
  'admin.common.justifications.title.plural': 'Justifications',
  'admin.common.file': 'File',
  'admin.common.fileFront': 'Front file',
  'admin.common.roles': 'Access profiles',
  'admin.common.ageRange': 'Age Bracket',
  'admin.permissions.title.plural': 'Permissions',
  'admin.permissions.title': 'Permission',
  'admin.justifications.title': 'Justification',
  'admin.justifications.title.plural': 'Justifications',
  'admin.faixasimc.abaixo': 'Below weight',
  'admin.faixasimc.normal': 'Normal weight',
  'admin.faixasimc.acima': 'Above weight',
  'admin.faixasimc.obesidadeI': 'Obesity I',
  'admin.faixasimc.obesidadeII': 'Obesity II',
  'admin.faixasimc.obesidadeIII': 'Obesity III',
  'admin.common.lastAnamnesis': 'Date of anamnesis',
  'admin.common.reSendEmail': 'Resend Email',
  'admin.common.normal': 'Normal',
  'admin.common.entity': 'Entity',
  'admin.common.entities': 'Entities',
  'admin.common.numberOfEmployees': 'Qty of Employees',
  'admin.common.confirmation': 'Confirmation',
  'admin.common.confirmationNotFound':
    'Registration not found, click the button below to receive a new link.',
  'admin.common.editUser': 'Edit User',
  'admin.common.processingAnamnese': 'We are processing the results',
  'admin.script.questionTypes.multipleChoice': 'Multiple Choice',
  'admin.script.questionTypes.choiceList': 'List of options',
  'admin.dataTable.label.report': 'Report',
  'admin.common.report.Mental_Risk': 'Mental Risk',
  'admin.common.report.Mental_Historical': 'Mental History',
  'admin.common.report.Personal_Data': 'Personal Data',
  'admin.common.report.Medical_History': 'Medical History',
  'admin.anamnesis.medicalHistory': 'Medical History Data',
  'admin.common.report.Medical_Risk': 'Risk',
  'admin.common.gender.biologic': 'Biological sex',
  'admin.anamnesis.medicalHistory.button.update': 'Update information',
  'admin.anamnesis.medicalHistory.button.correct': 'It is correct',
  'admin.common.share': 'Share',
  'admin.common.Share_Diagnosis': 'Share Result',
  'admin.common.put address': 'Enter an email address below:',
  'admin.common.initialDate': 'Start date',
  'admin.common.password.new': 'New Password',
  'admin.common.gender.social': 'Social Gender',
  'admin.common.qtd_colaboradores': 'Qty of Employees',
  'admin.anamnesis.medicalHistory.description':
    'You fill in the data below about medical history',
  'admin.comon.select': 'Select...',
  'admin.enter.company.name' : 'Enter your company name',
  'admin.login.with' : 'Login with',
  'admin.or.register' : 'or',
  'admin.sign.in.microsoft' : 'Sign in with Microsoft',
  'admin.sign.in.google' : 'Sign in with Google',
  'admin.write.with.microsoft': 'Register with Microsoft',
  'admin.write.with.google': 'Register with Google',
  'admin.common.whatsApp.receptive': 'WhatsApp - Receptive',
  'admin.common.whatsApp.active': 'WhatsApp - Active',

  'users.admin.companies.title': 'Company',
  'users.forms.passwordConfirm.title': 'Register Password',
  'users.delete.data.cantrollback.title': 'Are you sure you want to continue?',
  'users.admin.companies.title.plural': 'Companies',
  'users.delete.data.button': 'Log out',
  'users.anamneses.notfound': 'Anamnesis not found or unavailable',
  'users.delete.data.title': 'Log out',
  'users.dashboard.title.one': 'Prevention is Living More and Better.',
  'users.dashboard.welcome.female': 'Welcome, {name}!',
  'users.dashboard.welcome.male': 'Welcome, {name}!',
  'users.dashboard.card.type': 'Digital Interview',
  'users.login.welcome': 'Welcome! You have just taken the first step towards living longer and better!',
  'users.dashboard.card.typeWithDiagnosticName': 'Digital Interview: {diagnosticName}',
  'users.results.refillWarning': 'We have verified that your medical history has been updated.',
  'users.results.refillWarningRec': 'We recommend that you perform the digital interview again.',
  'users.results.smallHeaderMessage': '{name}, this is your summary:',
  'users.results.hello.mental': 'Hello {name},',
  'users.results.smallHeaderMessage.mental': 'First, congratulations! If you are reading this, it means that you have completed all the questions in our mental health screening. You are actively trying to learn more about yourself.',
  'users.results.card.assessedRisk': 'Assessed risk',
  'users.results.card.levelOfAttention': 'Attention level',
  'users.results.card.examsPendents': 'Pending exams or consultations',
  'users.results.openDetails': 'Open Digital Interview Details',
  'users.results.expertRecommendations': "Don't forget to review the recommendations of our experts",
  'users.results.expertRecommendations.mental': 'Behavioral health conditions can always be improved, and most people think they can make positive changes in their lives with professional help and the support of family and friends.',
  'users.results.othersDiagnosticsAvailable': 'Other available Digital Interviews',
  'users.results.fillAgain': 'Fill in again',
  'users.login.header.message': 'Enter your login and password',
  'users.login.button.enter': 'Login in',
  'users.results.diagnostic.title': 'Diagnostic Result',
  'users.results.diagnostic.title.plural': 'Diagnostic Results',
  'users.results.everHadSymptoms': 'Has symptoms',
  'users.results.diagnosticType': 'Type of diagnosis',
  'users.delete.data.description':
    'This will make your personal (including name, email, related data) be excluded exclusively from our database.',
  'users.delete.data.cantrollback.description': 'This action cannot be undone.',
  'users.dashboard.roche.longtext':
    'If you are a Roche employee and you have any questions after completing the form, you can contact Occupational Medicine via email: {email}. If you are a dependent, you can consult the doctor of your choice.',

  /*New Screen Dashbord */
  'admin.common.registered': 'Registered',
  'admin.common.total': ' Total',
  'admin.common.historic': 'Medical history',
  'admin.common.accomplished': 'Accomplished',
  'admin.common.no-accomplished': 'Unrealized',
  'admin.age': 'Age range',
  'admin.female.age.group' : 'Female Age Group',
  'admin.male.age.group' : 'Male Age Group',
  'admin.common.masc' : 'Male',
  'admin.common.fem' : 'Feminine',
  'admin.common.summary' : 'Summary',
  'admin.common.risk.assessment' : 'Risk assessment',
  'admin.common.number.of.people' : 'Number of People identified as High Risk.',
  'admin.common.low.risk' : '(Low risk)',
  'admin.common.smoker' : 'Symptoms',
  'admin.common.exams.are.missing' : 'Exams are missing',
  'admin.common.high.risk' : '(High risk)',
  'admin.common.exams.missing' : 'Missing Exams',
  'admin.common.one.year' : '+1 year',
  'admin.common.late' : 'Late',
  'admin.common.in.day' : 'In day',
  'admin.common.never' : 'Never',
  'admin.common.qtd' : 'Number of People identified in {risk}.',
  'admin.common.mama' : 'Breast',
  'admin.common.uterine' : 'Uterine',
  'admin.common.lung' : 'Lung',
  'admin.common.colon' : 'Colon',
  'admin.common.prostate' : 'Prostate',
  'admin.high.qtd' : '- Number of {risk} identified by specialty.',
  'admin.common.consolidated' : 'Consolidated',
  'admin.common.qtd.age' : 'Number of People identified by Age.',
  'admin.common.qtd.exams.missing.1': 'Number of persons identified with the exams',
  'admin.common.qtd.exams.missing.2': 'Pending (following delayed).',
  'admin.common.amount' : 'Quantity',
  'admin.common.cardio' : 'Cardio',
  'admin.common.mental' : 'Mental',
  'admin.interviews' : 'Interviews',
  'admin.common.monday' : 'Monday',
  'admin.common.tuesday' : 'Tuesday',
  'admin.common.wednesday' : 'Wednesday',
  'admin.common.thursday' : 'Thursday',
  'admin.common.friday' : 'Friday',
  'admin.filling.interviews' : 'Filling in the Interviews',
  'admin.common.qtd.filling.interviews' : 'Number of interviews completed in the period.',
  'admin.common.qtd.users' : 'Number of Users',
  'admin.common.funnel': 'Use Funnel',
  'admin.common.data.found': 'No data found!',
  'admin.common.only.air': 'Only Air',
  'admin.common.exams.only': 'Exams only',
  'admin.common.only.symptom': 'Only Symptom',
  'admin.common.exam.air': 'Air + Exam',
  'admin.common.air.symptom': 'Air + Symptom',
  'admin.common.exam.symptom': 'Exam + Symptom',
  'admin.common.air.exam.symptom': 'Air + Exam + Symptom',
  'admin.common.deleted.users': 'Removed Users',
  'admin.common.active.dash': 'Enable dashboard',
  'admin.high.qtd.segment': '- Amount of {risk} identified by follow-ups.',
  'admin.common.information': 'Information',
  'admin.common.additional.information': 'Additional Information',

   /*Dashboard Api*/
   'registered' : 'Registered',
   'medical history' : 'Medical history',
   'interviews' : 'Interviews',
   'male' : 'Male',
   'female' : 'Female',
   'all interviews' : 'All interviews',
   'mental' : 'Mental',
   'cardio' : 'Cardio',
   'mama' : 'Breast',
   'prostate' : 'Prostate',
   'lung' : 'Lung',
   'uterine' : 'Uterine',
   'colon' : 'Colon',
   'only air' : 'Only Air',
   'exams only' : 'Exams Only',
   'only symptom' : 'Only Symptom',
   'exam air' : 'Air + Exam',
   'air symptom' : 'Air + Symptom',
   'exam symptom' : 'Exam + Symptom',
   'air exam symptom' : 'Air + Exam + Symptom',
   'normal risk' : 'Normal Risk',
   'admin.prostate': 'Prostate cancer',
   'admin.uterine': 'Cervical cancer',
   'admin.medical.male': 'Medical History (Male)',
   'admin.medical.female': 'Medical History (Female)',
   'admin.breast.cancer': 'Breast cancer',
   'admin.lung.cancer': 'Lung cancer',
   'admin.colon.cancer': 'Colon Cancer',
   'admin.mental': 'Mental',
   'admin.cardio': 'Cardio',
   'admin.all.risks': 'All risks',
   'admin.high.risk' : 'High risk',
   'admin.medium.risk': 'Medium Risk',
   'admin.low.risk' : 'Low risk',
   'admin.common.contact_us' : 'Contact us',
   'admin.common.how_about' : 'How about scheduling an appointment to talk about your results or update your exams',
   'admin.common.nationality' : 'Nationality',
   'admin.common.document' : 'Identification Document',
   'admin.filter.company.title' : 'Filter company',
   
  /* Interviews BD*/
  'Você já fez exame de toque retal ou PSA?': 'Have you ever had a rectal touch or PSA exam?',
  'Câncer de Útero': 'Cervical Cancer ',
  'Histórico Médico' : 'Medical History',
  'Utilizaremos esse dado para o cálculo do IMC, que interfere na avaliação de risco de alguns tipos de câncer.' : 'We will use this data to calculate BMI, which interferes in the risk assessment of some types of cancer.',
  'Qual é a sua altura ? (em CM)' : 'What is your height?',
  'Qual é o seu peso ? (em KG)' : 'What is your weight?',
  'Você faz uso de algum medicamento regular?' : 'Do you take any regular medication?',
  'Sim' : 'Yes',
  'Não' : 'No',
  'Você fuma ou já fumou charutos?' : 'Do you smoke or have you ever smoked, Cigars?',
  'O tabagismo (cigarro ou charuto) está diretamente ligado ao risco de desenvolvimento de vários tipos de câncer. Utilizaremos esses dados para avaliar qual estratégia é recomendada para a redução do seu risco de desenvolver câncer de pulmão.' : 'Smoking (cigarettes or cigars) is directly linked to the risk of developing several types of cancer. We will use this data to evaluate which strategy is recommended for reducing your risk of developing lung cancer.',
  'Você fuma ou já fumou cigarros?' : 'Do you smoke or have you ever smoked cigarettes?',
  'Pratica alguma atividade física?' : 'Do you exercize regularly or practice any physical activity?',
  'Atividade física, juntamente com outros hábitos de vida saudáveis, reduz a chance de desenvolvimento de vários tipos de câncer.' : 'Physical activity, along with other healthy lifestyle habits, reduces the chance of developing several types of cancer.',
  'Você já realizou um exame genético (conhecido como BRCA1 ou BRCA2) para saber se possui uma alteração/mutação que aumenta seu risco de desenvolver câncer, e o resultado foi positivo?' : 'Have you ever had a genetic test (known as BRCA1 or BRCA2) to find out if you have an alteration/mutation that increases your risk of developing cancer, and the result was positive?',
  'A mutação nos genes BRCA1 ou BRCA2 aumenta significativamente o risco para alguns tipos de câncer (mama, ovário, próstata, cólon e pâncreas). Este é um exame genético que pode ser realizado através do sangue ou da saliva para que a pessoa saiba se possui a mutação.' : 'Mutation in the BRCA1 or BRCA2 genes significantly increases the risk of some types of cancer (breast, ovarian, prostate, colon and pancreas). This is a genetic test that can be carried out using blood or saliva to let the person know if they have the mutation.',
  'Você respondeu que o teste para pesquisa de mutação BRCA foi positivo. Está correto?' : 'You replied that the test for BRCA mutation was positive. Is this correct?',
  'Você já teve câncer?' : 'Have you ever had cancer?',
  'Sabe-se que quem já foi acometido por câncer apresenta um risco elevado de recidiva ou até mesmo de desenvolver um novo tumor do mesmo tipo. Por isso, é importante, para o cálculo de seu risco, saber se você já foi afetado por essa doença em sua vida.' : "It is known that anyone who has already been affected by cancer has a high risk of recurrence or even developing a new tumor of the same type. Therefore, it is important, to calculate your risk, to know whether you have already been affected by this disease in your life.",
  'Selecione todas as alternativas sobre o histórico de câncer em sua família:' : "Select all alternatives about your family's history of cancer:",
  'Avaliaremos essa informação para determinar o seu risco de portar alguma mutação genética familiar que aumenta o risco de desenvolver alguns tipos de câncer.' : 'We will evaluate this information to determine your risk of carrying a family genetic mutation that increases the risk of developing some types of cancer.',
  'Qual o seu consumo diário de bebida alcoólica?' : 'What is your daily alcohol consumption?',
  '1 drink = 350ml de cerveja/150ml de vinho/45ml de bebida destilada. O consumo de bebida alcoólica está associado ao aumento de risco de alguns tipos de câncer.' : '1 drink = 350ml of beer/150ml of wine/45ml of distilled beverage. Alcohol consumption is associated with an increased risk of some types of cancer.',
  'Você já teve algum problema cardíaco?' : 'Have you ever had a heart problem?',
  'Você tem histórico de problemas emocionais?' : 'Do you have a history of emotional problems?',
  'Resultado Positivo' : 'Positive result',
  'Resultado Negativo' : 'Negative Result',
  'Não sei se fiz o exame' : "I don't know if I took the exam",
  'Não fiz o exame' : "I didn’t take the exam",
  'Sem histórico familiar' : 'No family history',
  'Algum dos seus parentes de 1 grau (mãe, pai, irmã, irmão, filha ou filho) tiveram câncer de mama ou ovário' : 'Have any of your 1st degree relatives (mother, father, sister, brother, daughter or son) had breast or ovarian cancer',
  'Qualquer parente seu já teve câncer de mama bilateral' : 'Any relative of yours has had bilateral breast cancer',
  'Algum homem da sua família teve câncer de mama' : 'Has any man in your family had breast cancer?',
  'Alguma mulher em sua família teve câncer de mama e de ovário' : 'Has any woman in your family had breast and ovarian cancer?',
  'Alguma mulher em sua família teve câncer de mama antes dos 50 anos de idade' : 'Has any woman in your family had breast cancer before age 50?',
  'Você tem 2 ou mais parentes com câncer de mama e/ou ovário' : 'You have 2 or more relatives with breast and/or ovarian cancer',
  'Você tem 2 ou mais parentes com câncer de mama e/ou intestino' : 'You have 2 or more relatives with breast and/or bowel cancer',
  'Não tenho hábito de ingerir bebidas' : "I'm not used to drinking",
  'Menos de um drink por dia' : 'Less than one drink a day',
  'Entre 1 e 2 drinks por dia' : 'Between 1 and 2 drinks per day',
  'Entre 2 e 3 drinks por dia' : 'Between 2 and 3 drinks per day',
  'Entre 3 e 4 drinks por dia' : 'Between 3 and 4 drinks per day',
  'Mais de 4 drinks por dia': 'More than 4 drinks per day',
  'After completing the form below, you will receive an email link to reset your password.':
    'After completing the form below, you will receive an email link to reset your password.',
  'Quais remédios?': 'Which ones are you currentlly taking ?',
  'CONTROLE DA PRESSAO ARTERIAL': 'BLOOD PRESSURE CONTROL',
  'CONTROLE DO COLESTEROL E/OU TRIGLICERIDEOS' : 'CHOLESTEROL AND/OR TRIGLYCERIDES CONTROL',
  'TRATAMENTO DO DIABETES' : 'DIABETES TREATMENT',
  'ANTIALERGICOS' : 'ANTI-ALLERGIC',
  'CORTICOIDES' : 'CORTICOIDS',
  'MEDICAMENTOS PARA ASMA E/OU BRONQUITE' : 'MEDICATIONS FOR ASTHMA AND/OR BRONCHITIS',
  'ASPIRINA' : 'ASPIRIN',
  'CONTROLE DO COLESTEROL E / OU TRIGLICERIDEOS' : 'CHOLESTEROL AND/OR TRIGLYCERIDES CONTROL',
  'ANALGESICOS' : 'ANALGESICS',
  'MEDICAMENTOS PARA DORES DE CABECA' : 'MEDICATION FOR HEADACHES',
  'ANTI INFLAMATORIOS' : 'ANTI-INFLAMMATORY',
  'TRATAMENTO DE DOENCAS DA TIREOIDE' : 'TREATMENT OF THYROID DISEASES',
  'TRATAMENTO DE GASTRITE, ÚLCERA OU REFLUXO' : 'TREATMENT OF GASTRITIS, ULCER OR REFLUX',
  'DOENCAS REUMATOLOGICAS OU DAS ARTICULACOES ' : 'RHEUMATOLOGICAL OR JOINT DISEASES',
  'COLIRIOS' : 'EYE DROPS',
  'DOENCAS DO CORACAO - RITMO CARDIACO' : 'HEART DISEASES - HEART RHYTHM',
  'DOENCAS DO CORACAO - INSUFICIENCIA CARDIACA' : 'HEART DISEASES - HEART FAILURE',
  'ANTICONCEPCIONAIS' : 'CONTRACEPTIVES',
  'MEDICAMENTO PARA IMPOTENCIA SEXUAL' : 'DRUG FOR SEXUAL IMPOTENCY',
  'OUTROS TRATAMENTOS HORMONAIS' : 'OTHER HORMONAL TREATMENTS',
  'DOENCAS DA PROSTATA' : 'PROSTATE DISEASES',
  'DOENCAS INFLAMATORIAS DO INTESTINO - DOENCA DE CROHN OU RETOCOLITE' : "INFLAMMATORY BOWEL DISEASES - CROHN'S DISEASE OR RETOCOLITIS",
  'ANTIBIOTICOS' : 'ANTIBIOTICS',
  'DOENCAS DA BEXIGA E DO APARELHO URINARIO' : 'DISEASES OF THE BLADDER AND URINARY SYSTEM',
  'Outros' : 'Others',
  'Durante quantos anos você fumou charutos?' : 'For how many years have you smoked Cigars?',
  'ano ' : 'year',
  'anos ' : 'years',
  'No tempo que fumou, quantos charutos por dia?' : 'During the time you smoked, how many cigars a day?',
  'Fuma charutos atualmente?' : 'Do you currently smoke cigars?',
  'Durante quantos anos você fumou cigarros?' : 'How many years have you smoked cigarettes?',
  'No tempo que fumou, quantos cigarros por dia?' : 'During the time you smoked, how many cigarettes a day?',
  'Fuma cigarros atualmente?' : 'Do you currently smoke cigarettes?',
  'Quantos minutos por semana em média?' : 'How many minutes per week on average?',
  'De 10 a 30 minutos por semana' : '10 to 30 minutes per week',
  'De 30 a 150 minutos por semana' : '30 to 150 minutes per week',
  'De 150 a 500 minutos por semana' : '150 to 500 minutes per week',
  'Mais de 500 minutos por semana' : 'More than 500 minutes per week',
  'Qual tipo de câncer?' : 'What kind of cancer?',
  'Cólon' : 'Colon',
  'Pulmão' : 'Lung',
  'Prostata' : 'Prostate',
  'Útero' : 'Uterus',
  'Mama' : 'Breast',
  'Qual tipo de problema cardíaco?' : 'What kind of heart problem?',
  'Infarto' : 'Heart attack',
  'Angina por obstrução das artérias do coração' : "Angina due to obstruction of the heart arteries",
  'Derrame' : 'Leakage',
  'Entupimento de artérias da perna' : 'Obstruction of the leg arteries',
  'Dilatação (aneurisma) de Aorta' : 'Dilatation (aneurysm) of the aorta',
  'Qual tipo de problema emocional?' : 'What kind of emotional problem?',
  'Ansiedade' : 'Anxiety',
  'Estresse' : 'Stress',
  'Depressão' : 'Depression',
  'Burnout' : 'Burnout',
  'Com qual idade veio sua primeira menstruação?' : 'At what age did you have your first period?',
  'Câncer de Mama' : 'Breast Cancer',
  'Você já fez radioterapia no tórax para tratar linfoma?' : 'Have you had chest radiotherapy to treat a lymphoma?',
  'Mulheres que realizaram esse tipo de tratamento antes dos 30 anos de idade apresentam grande risco de desenvolver câncer de mama durante a vida.' : 'Women who underwent this type of treatment before the age of 30 have a high risk of developing breast cancer during their lifetime.',
  'Você afirmou que já fez radioterapia? Está correto?' : 'You answered thta you had chest radiotherapy to treat a lymphoma. Is this correct?',
  'Sabe-se que quanto mais cedo uma mulher tem sua primeira menstruação, maior é o seu risco de desenvolver câncer de mama.' : 'It is known that the earlier a woman has her first period, the greater her risk of developing breast cancer.',
  'De 7 a 11' : 'from 7 to 11',
  'De 12 a 13' : 'from 12 to 13',
  '14 ou mais' : '14 or more',
  'Não Sei' : 'I don’t know',
  'Com qual idade teve o primeiro filho (biológico) vivo?' : 'At what age did you have your first living (biological) child?',
  'Mulheres que tem o primeiro filho após os 30 anos de idade ou não tem filhos apresentam um risco maior de desenvolver câncer de mama quando comparadas com mulheres que tem filho antes dos 30 anos de idade.' : 'Women who have their first child after age 30 or have no children have a higher risk of developing breast cancer compared to women who have a child before age 30.',
  'Não sei' : 'I do not know',
  'Não tenho filhos' : 'I don’t have children',
  'Menos de 20' : 'less than 20',
  'Entre 20 e 24' : 'Between 20 and 24',
  'Entre 25 e 29' : 'Between 25 and 29',
  'Mais de 30' : 'More than 30',
  'Quantos parentes de 1° grau (mãe, pai, irmã, irmão, filha ou filho) tiveram câncer de mama?' : 'How many 1st degree relatives (mother, father, sister, brother, daughter or son) have had breast cancer?',
  'Pessoas que apresentam câncer de mama em parentes de 1º grau e principalmente se esse parente teve o câncer antes dos 60 anos de idade, apresentam um risco maior de desenvolver câncer de mama durante a vida.' : 'People who have breast cancer in 1st-degree relatives, and especially if that relative had the cancer before age 60, have a higher lifetime risk of developing breast cancer.',
  'Nenhum' : 'None',
  'Mais de 1': 'More than 1',
  'Você já realizou uma cirurgia para retirada total do útero (histerectomia)?' : 'Have you ever had surgery to remove your uterus completely (hysterectomy)',
  'Você respondeu que um parente de 1º grau (mãe, pai, irmã, irmão, filha ou filho) já teve câncer de mama. Está correto?' : 'You answered that a 1st degree relative (mother, father, sister, brother, daughter or son) has had bowel cancer. Is this correct?',
  'Você já tomou as 3 doses de vacina para o HPV?' : 'Have you had the 3 doses of the HPV vaccine?',
  'Já fez alguma biópsia de mama?': 'Have you ever had a breast biopsy?',
  'Pessoas que já tiveram certos tipos de doenças na mama têm um risco maior de desenvolver câncer de mama, especialmente se essas doenças apresentaram uma alteração chamada atipia.' : 'People who have had certain types of breast diseases have a higher risk of developing breast cancer, especially if these diseases have a change called atypia.',
  'Quantas biópsias foram feitas?' : 'How many biopsies were done?',
  'Alguma biópsia deu alteração com atipias?': 'Did any biopsy show changes with atypia?',
  'Atipias são anormalidades nas células ou tecidos que não parecem saudáveis sob um microscópio. Elas podem indicar um risco aumentado de câncer, mas nem sempre se transformam em câncer. O acompanhamento médico é essencial para determinar a necessidade de tratamento ou monitoramento.' : 'Atypia is abnormalities in cells or tissues that appear unhealthy under a microscope. They may indicate an increased risk of cancer, but they do not always turn into cancer. Medical follow-up is essential to determine the need for treatment or monitoring.',
  'Quando você realizou sua última mamografia?' : 'When did you have your most recent mammogram?',
  'Menos de 1 ano' : 'Less than 1 year',
  'Mais de 1 ano' : 'More than 1 year',
  'Nunca realizei' : 'I have never done it',
  'Você observa alguma lesão palpável?' : 'Do you observe any palpable lesions?',
  'Sente nódulos ou "caroços" na mama.' : 'You feel lumps in your breast.',
  'Possui secreção nas papilas de forma espontânea?' : 'Do you have secretion in the nipple spontaneously?',
  'Saída de secreção pelos mamilos.' : 'Secretion out of the nipples.',
  'Observou alguma mudança de forma na mama?' : 'Have you noticed any changes in the shape of the breast?',
  'Câncer de Pulmão' : 'Lung Cancer',
  'Você viveu com fumantes por mais da metade da sua vida?' : 'Have you lived with smokers for more than half of your life?',
  'O fumo passivo também aumenta o risco para desenvolvimento de câncer de pulmão.' : 'Secondhand smoke also increases the risk of developing lung cancer.',
  'Você já trabalhou com amianto, sem a devida proteção?' : 'Have you ever worked with asbestos without proper protection?',
  'Existe uma associação entre o trabalho com amianto e um risco maior de desenvolvimento de câncer do pulmão.' : 'There is an association between working with asbestos and an increased risk of developing lung cancer.',
  'Selecione todas as atividades abaixo, com as quais você tenha trabalhado sem a devida proteção:' : 'Please select all activities below that you have worked with without proper protection.',
  'Exaustão de motores a diesel' : 'Diesel engine exhaust',
  'Pó ou cristal de sílica' : 'Silica powder or crystal',
  'Compostos de níquel' : 'Nickel compounds',
  'Compostos de cromo (VI)' : 'Chromium (VI) compounds',
  'Arsênio e compostos inorgânicos de arsênio' : 'Arsenic and Inorganic Arsenic Compounds',
  'Radiações X e Gama' : 'Radiaciones X y Gamma',
  'Compostos de cádmio' : 'Cadmium and cadmium compounds',
  'Compostos de berílio' : 'Beryllium compounds',
  'Essas atividades quando feitas por longo períodos e sem a devida proteção, podem aumentar o risco de desenvolvimento de alguns tipos de câncer.' : 'These activities, when done for long periods and without proper protection, can increase the risk of developing some types of cancer.',
  'Pintura' : 'Painting',
  'Fundição de alumínio' : 'Aluminum smelting',
  'Fundição de ferro ou aço' : 'Iron or steel casting',
  'Fabricação de borracha' : 'Rubber manufacturing',
  'Fornos de coque' : 'Coke oven',
  'Gaseificação de carvão mineral' : 'Mineral coal gasification',
  'Você sente falta de ar ou dificuldade para respirar?' : 'Do you feel short of breath or difficulty breathing?',
  'Tem percebido isso de maneira progressiva nos últimos tempos?' : 'Have you noticed this progressively lately?',
  'Apresenta tosse contínua ou progressiva?' : 'Do you have a continuous or progressive cough?',
  'A tosse te acompanha por umArsênio e compostos inorgânicos de arsênio período de tempo e está aumentando em frequência?' : 'Does the cough accompany you for a period of time and is it increasing in frequency?',
  'Não trabalhei' : 'I did not work',
  'Nunca trabalhei' : 'I never worked',
  'Câncer de Cólon' : 'Colon Cancer',
  'Algum parente de 1° grau (mãe, pai, irmã, irmão, filha ou filho) já teve câncer de intestino?' : 'Has any 1st degree relative (mother, father, sister, brother, daughter or son) ever had bowel cancer?',
  'Pacientes que apresentam um familiar de 1º grau ou mais familiares com câncer de intestino apresentam maior risco de desenvolver câncer de intestino durante a vida.' : 'Patients who have a 1st-degree relative or more family members with bowel cancer are at increased risk of developing bowel cancer during their lifetime.',
  'Você respondeu que um parente de 1º grau (mãe, pai, irmã, irmão, filha ou filho) já teve câncer de intestino. Está correto?' : 'You answered that a first-degree relative (father, mother, brother, sister, son and / or daughter) had bowel cancer. Is this correct?',
  'Essas lesões são conhecidas como lesões pré-cancerosas e pessoas que apresentam isso correm mais risco de desenvolver câncer de intestino durante a vida.' : 'These lesions are known as precancerous lesions and people who have these are at a higher risk of developing bowel cancer during their lifetime.',
  'Você teve uma condição conhecida como doença inflamatória intestinal crônica (ou DII) por 10 anos ou mais? Isso inclui a doença de Crohn e a colite ulcerosa, mas não a síndrome do intestino irritável (SII).' : "Have you had a condition known as chronic inflammatory bowel disease (or IBD) for 10 years or more? This includes Crohn's disease and ulcerative colitis, but not irritable bowel syndrome (IBS).",
  'Já realizou alguma vez os seguintes exames: pesquisa de sangue oculto nas fezes, retossigmoidoscopia ou conoloscopia?' : 'Have you ever had any of the following exams: search for hidden blood in stool, rectal-sigmoidoscopy or colonoscopy?',
  'Exame recomendado para pessoas sadias e de risco normal após os 45 anos de idade.' : 'Recommended exam for healthy people and normal risk after 45 years of age.',
  'Quando foi a última vez?': 'When was the last time?',
  'Sente dor intensa durante a relação sexual?': 'Do you feel intense pain during sexual intercourse?',
  'Alguma vez notou sangramento durante ou após a relação sexual?': 'Have you ever noticed bleeding during or after intercourse?',
  'Apresenta algum sangramento fora do período menstrual ou após a menopausa?' : 'Do you have any bleeding outside of your menstrual period or after menopause?',
  'Mais de 2 anos' : 'More than 2 years ',
  'Mais de 3 anos' : 'More than 3 years',
  'Você sofre de obstipação intestinal (intestino preso) ou diarreia frequente?' : 'Do you suffer from constipation (constipation) or frequent diarrhea?',
  'Alterações progressivas e persistentes do hábito intestinal demandam uma avaliação médica mais aprofundada.' : 'Progressive and persistent changes in bowel habits require further medical evaluation.',
  'Já observou presença de sangue nas fezes?' : 'Have you ever noticed blood on your feces?',
  'Isso pode ser um sinal de doenças do trato gastrointestinal.' : 'This could be a sign of diseases of the gastrointestinal tract.',
  'Você já teve alguma doença intestinal em que teve que retirar um pólipo (por colonoscopia), e, seu médico lhe falou que este pólipo era de alto risco e poderia virar câncer? ' : 'Have you had any bowel disorder that required removal of polyps (by colonoscopy) and your attending physician told you this was a high-risk polyp and that it could become a cancer?',
  'Há menos de um ano ' : 'less than a year ago',
  'Há mais de um ano' : 'More than a year ago',
  'Nunca' : 'Never',
  'Poucas vezes' : 'Few times',
  'Às vezes' : 'Sometimes',
  'Frequentemente' : 'Frequently',
  'Sempre' : 'Always',
  'Cardio' : 'Cardiovascular',
  'Qual a sua pressão arterial sistólica?' : 'What is your systolic blood pressure?',
  'Você tem diabetes?' : 'Do you have diabetes?',
  'Sua diabetes é do tipo 1 ou 2?' : 'Is your diabetes type 1 or 2?',
  'tipo 1' : 'type 1',
  'tipo 2' : 'type 2',
  'Há quantos anos tem diabetes?' : 'How many years have you had diabetes?',
  'Há quantos anos toma insulina?' : 'How many years have you been taking insulin?',
  'Você tem os resultados do seu último exame de colesterol?' : 'Do you have the results of your last cholesterol test?',
  'Quanto tempo faz que fez os exames?' : 'How long ago did you take the exams?',
  '6  meses' : '6 months',
  'Menos de 6 meses' : 'Less than 6 months',
  'Entre 6 meses e 1 ano' : 'Less than 6 months',
  'Entre 1 e 2 anos' : 'Between 1 and 2 years',
  '1 ano' : '1 year',
  '2 anos' : '2 years',
  '3 anos' : '3 years',
  '4 anos' : '4 years',
  '5 anos' : '5 years',
  '6 anos' : '6 years',
  '7 anos' : '7 years',
  '8 anos' : '8 years',
  '9 anos' : '9 years',
  '10 anos' : '10 years',
  '11 anos' : '11 years',
  '12 anos' : '12 years',
  '13 anos' : '13 years',
  '14 anos' : '14 years',
  '15 anos' : '15 years',
  '16 anos' : '16 years',
  '17 anos' : '17 years',
  '18 anos' : '18 years',
  '19 anos' : '19 years',
  '20 anos' : '20 years',
  '21 anos' : '21 years',
  '22 anos' : '22 years',
  '23 anos' : '23 years',
  '24 anos' : '24 years',
  '25 anos' : '25 years',
  '26 anos' : '26 years',
  '27 anos' : '27 years',
  '28 anos' : '28 years',
  '29 anos' : '29 years',
  '30 anos' : '30 years',
  '31 anos' : '31 years',
  '32 anos' : '32 years',
  '33 anos' : '33 years',
  '34 anos' : '34 years',
  '35 anos' : '35 years',
  '36 anos' : '36 years',
  '37 anos' : '37 years',
  '38 anos' : '38 years',
  '39 anos' : '39 years',
  '40 anos' : '40 years',
  '41 anos' : '41 years',
  '42 anos' : '42 years',
  '43 anos' : '43 years',
  '44 anos' : '44 years',
  '45 anos' : '45 years',
  '46 anos' : '46 years',
  '47 anos' : '47 years',
  '48 anos' : '48 years',
  '49 anos' : '49 years',
  '50 anos' : '50 years',
  '51 anos' : '51 years',
  '52 anos' : '52 years',
  '53 anos' : '53 years',
  '54 anos' : '54 years',
  '55 anos' : '55 years',
  '56 anos' : '56 years',
  '57 anos' : '57 years',
  '58 anos' : '58 years',
  '59 anos' : '59 years',
  '60 anos' : '60 years',
  '61 anos' : '61 years',
  '62 anos' : '62 years',
  '63 anos' : '63 years',
  '64 anos' : '64 years',
  '65 anos' : '65 years',
  '66 anos' : '66 years',
  '67 anos' : '67 years',
  '68 anos' : '68 years',
  '69 anos' : '69 years',
  '70 anos' : '70 years',
  '71 anos' : '71 years',
  '72 anos' : '72 years',
  '73 anos' : '73 years',
  '74 anos' : '74 years',
  '75 anos' : '75 years',
  '76 anos' : '76 years',
  '77 anos' : '77 years',
  '78 anos' : '78 years',
  '79 anos' : '79 years',
  '80 anos' : '80 years',
  '81 anos' : '81 years',
  '82 anos' : '82 years',
  '83 anos' : '83 years',
  '84 anos' : '84 years',
  '85 anos' : '85 years',
  '86 anos' : '86 years',
  '87 anos' : '87 years',
  '88 anos' : '88 years',
  '89 anos' : '89 years',
  '90 anos' : '90 years',
  '91 anos' : '91 years',
  '92 anos' : '92 years',
  '93 anos' : '93 years',
  '94 anos' : '94 years',
  '95 anos' : '95 years',
  '96 anos' : '96 years',
  '97 anos' : '97 years',
  '98 anos' : '98 years',
  '99 anos' : '99 years',
  '100 anos' : '100 years',
  '+ de 2 anos' : '+ 2 years',
  'Qual o valor do coleterol Total' : 'What is the Total value of cholesterol',
  'Qual o valor do coleterol LDL' : 'What is the value of LDL cholesterol',
  'Qual o valor do coleterol HDL' : 'What is the value of HDL cholesterol',
  'Qual o valor de triglicerídeos?' : 'What is the value of triglycerides?',
  '1 charuto' : '1 cigar',
  '2 charutos' : '2 cigars',
  '3 charutos' : '3 cigars',
  '4 charutos' : '4 cigars',
  '5 charutos' : '5 cigars',
  '6 charutos' : '6 cigars',
  '7 charutos' : '7 cigars',
  '8 charutos' : '8 cigars',
  '9 charutos' : '9 cigars',
  '10 charutos' : '10 cigars',
  '11 charutos' : '11 cigars',
  '12 charutos' : '12 cigars',
  '13 charutos' : '13 cigars',
  '14 charutos' : '14 cigars',
  '15 charutos' : '15 cigars',
  '16 charutos' : '16 cigars',
  '17 charutos' : '17 cigars',
  '18 charutos' : '18 cigars',
  '19 charutos' : '19 cigars',
  '20 charutos' : '20 cigars',
  '21 charutos' : '21 cigars',
  '22 charutos' : '22 cigars',
  '23 charutos' : '23 cigars',
  '24 charutos' : '24 cigars',
  '25 charutos' : '25 cigars',
  '26 charutos' : '26 cigars',
  '27 charutos' : '27 cigars',
  '28 charutos' : '28 cigars',
  '29 charutos' : '29 cigars',
  '30 charutos' : '30 cigars',
  '31 charutos' : '31 cigars',
  '32 charutos' : '32 cigars',
  '33 charutos' : '33 cigars',
  '34 charutos' : '34 cigars',
  '35 charutos' : '35 cigars',
  '36 charutos' : '36 cigars',
  '37 charutos' : '37 cigars',
  '38 charutos' : '38 cigars',
  '39 charutos' : '39 cigars',
  '40 charutos' : '40 cigars',
  '41 charutos' : '41 cigars',
  '42 charutos' : '42 cigars',
  '43 charutos' : '43 cigars',
  '44 charutos' : '44 cigars',
  '45 charutos' : '45 cigars',
  '46 charutos' : '46 cigars',
  '47 charutos' : '47 cigars',
  '48 charutos' : '48 cigars',
  '49 charutos' : '49 cigars',
  '50 charutos' : '50 cigars',
  '51 charutos' : '51 cigars',
  '52 charutos' : '52 cigars',
  '53 charutos' : '53 cigars',
  '54 charutos' : '54 cigars',
  '55 charutos' : '55 cigars',
  '56 charutos' : '56 cigars',
  '57 charutos' : '57 cigars',
  '58 charutos' : '58 cigars',
  '59 charutos' : '59 cigars',
  '60 charutos' : '60 cigars',
  '61 charutos' : '61 cigars',
  '62 charutos' : '62 cigars',
  '63 charutos' : '63 cigars',
  '64 charutos' : '64 cigars',
  '65 charutos' : '65 cigars',
  '66 charutos' : '66 cigars',
  '67 charutos' : '67 cigars',
  '68 charutos' : '68 cigars',
  '69 charutos' : '69 cigars',
  '70 charutos' : '70 cigars',
  '71 charutos' : '71 cigars',
  '72 charutos' : '72 cigars',
  '73 charutos' : '73 cigars',
  '74 charutos' : '74 cigars',
  '75 charutos' : '75 cigars',
  '76 charutos' : '76 cigars',
  '77 charutos' : '77 cigars',
  '78 charutos' : '78 cigars',
  '79 charutos' : '79 cigars',
  '80 charutos' : '80 cigars',
  '81 charutos' : '81 cigars',
  '82 charutos' : '82 cigars',
  '83 charutos' : '83 cigars',
  '84 charutos' : '84 cigars',
  '85 charutos' : '85 cigars',
  '86 charutos' : '86 cigars',
  '87 charutos' : '87 cigars',
  '88 charutos' : '88 cigars',
  '89 charutos' : '89 cigars',
  '90 charutos' : '90 cigars',
  '91 charutos' : '91 cigars',
  '92 charutos' : '92 cigars',
  '93 charutos' : '93 cigars',
  '94 charutos' : '94 cigars',
  '95 charutos' : '95 cigars',
  '96 charutos' : '96 cigars',
  '97 charutos' : '97 cigars',
  '98 charutos' : '98 cigars',
  '99 charutos' : '99 cigars',
  '100 charutos': '100 cigars',
  'Mental' : 'Mental Health',
  'Eu penso em mudar minhas condições atuais de trabalho (por exemplo, meu turno, o local de trabalho, etc)' : 'I think about changing my current working conditions (e.g. my shift, the place of work, etc.)',
  'Nos últimos 6 meses,me senti muito ansioso e/ou preocupado com o trabalho.' : 'In the last 6 months, I have felt very anxious and/or worried about work.',
  'Minha empresa me oferece um ambiente de trabalho seguro e acolhedor' : 'My company offers me a safe and welcoming work environment',
  'Sinto-me reconhecido no trabalho.' : 'I feel recognized at work.',
  'Meu estresse atual no local de trabalho afeta negativamente meu humor, pensamento e comportamento' : 'My current workplace stress negatively affects my mood, thinking and behavior',
  'Sinto-me distraído ou tenho dificuldade em me concentrar devido ao meu ambiente de trabalho.' : 'I feel distracted or have trouble concentrating because of my work environment.',
  'Sinto-me exausto quando estou no trabalho.' : 'I feel exhausted when I am at work.',
  'Sinto-me emocionalmente esgotado pelo meu trabalho.' : 'I feel emotionally drained by my work.',
  'Meu ambiente de trabalho me faz sentir inútil ou culpado.' : 'My work environment makes me feel worthless or guilty.',
  'Meu humor e meus sentimentos em relação ao meu trabalho facilitam meu desempenho em minha vida pessoal, social ou profissional.' : 'My mood and feelings about my work facilitate my performance in my personal, social or professional life.',
  'Nos últimos 6 meses, senti tontura ou minha cabeça estava girando' : 'In the last 6 months, I felt dizzy, or my head was spinning',
  'Nos últimos 6 meses, tive dores no peito, quase como se estivesse tendo um ataque cardíaco.' : 'For the last 6 months I have had chest pains, almost like I was having a heart attack.',
  'Nos últimos 6 meses, senti-me mal do estômago, quase como se fosse vomitar.' : 'For the past 6 months I have felt sick to my stomach, almost like I was going to throw up.',
  '1 cigarro' : '1 cigarette',
  '2 cigarros' : '2 cigarettes',
  '3 cigarros' : '3 cigarettes',
  '4 cigarros' : '4 cigarettes',
  '5 cigarros' : '5 cigarettes',
  '6 cigarros' : '6 cigarettes',
  '7 cigarros' : '7 cigarettes',
  '8 cigarros' : '8 cigarettes',
  '9 cigarros' : '9 cigarettes',
  '10 cigarros' : '10 cigarettes',
  '11 cigarros' : '11 cigarettes',
  '12 cigarros' : '12 cigarettes',
  '13 cigarros' : '13 cigarettes',
  '14 cigarros' : '14 cigarettes',
  '15 cigarros' : '15 cigarettes',
  '16 cigarros' : '16 cigarettes',
  '17 cigarros' : '17 cigarettes',
  '18 cigarros' : '18 cigarettes',
  '19 cigarros' : '19 cigarettes',
  '20 cigarros' : '20 cigarettes',
  '21 cigarros' : '21 cigarettes',
  '22 cigarros' : '22 cigarettes',
  '23 cigarros' : '23 cigarettes',
  '24 cigarros' : '24 cigarettes',
  '25 cigarros' : '25 cigarettes',
  '26 cigarros' : '26 cigarettes',
  '27 cigarros' : '27 cigarettes',
  '28 cigarros' : '28 cigarettes',
  '29 cigarros' : '29 cigarettes',
  '30 cigarros' : '30 cigarettes',
  '31 cigarros' : '31 cigarettes',
  '32 cigarros' : '32 cigarettes',
  '33 cigarros' : '33 cigarettes',
  '34 cigarros' : '34 cigarettes',
  '35 cigarros' : '35 cigarettes',
  '36 cigarros' : '36 cigarettes',
  '37 cigarros' : '37 cigarettes',
  '38 cigarros' : '38 cigarettes',
  '39 cigarros' : '39 cigarettes',
  '40 cigarros' : '40 cigarettes',
  '41 cigarros' : '41 cigarettes',
  '42 cigarros' : '42 cigarettes',
  '43 cigarros' : '43 cigarettes',
  '44 cigarros' : '44 cigarettes',
  '45 cigarros' : '45 cigarettes',
  '46 cigarros' : '46 cigarettes',
  '47 cigarros' : '47 cigarettes',
  '48 cigarros' : '48 cigarettes',
  '49 cigarros' : '49 cigarettes',
  '50 cigarros' : '50 cigarettes',
  '51 cigarros' : '51 cigarettes',
  '52 cigarros' : '52 cigarettes',
  '53 cigarros' : '53 cigarettes',
  '54 cigarros' : '54 cigarettes',
  '55 cigarros' : '55 cigarettes',
  '56 cigarros' : '56 cigarettes',
  '57 cigarros' : '57 cigarettes',
  '58 cigarros' : '58 cigarettes',
  '59 cigarros' : '59 cigarettes',
  '60 cigarros' : '60 cigarettes',
  '61 cigarros' : '61 cigarettes',
  '62 cigarros' : '62 cigarettes',
  '63 cigarros' : '63 cigarettes',
  '64 cigarros' : '64 cigarettes',
  '65 cigarros' : '65 cigarettes',
  '66 cigarros' : '66 cigarettes',
  '67 cigarros' : '67 cigarettes',
  '68 cigarros' : '68 cigarettes',
  '69 cigarros' : '69 cigarettes',
  '70 cigarros' : '70 cigarettes',
  '71 cigarros' : '71 cigarettes',
  '72 cigarros' : '72 cigarettes',
  '73 cigarros' : '73 cigarettes',
  '74 cigarros' : '74 cigarettes',
  '75 cigarros' : '75 cigarettes',
  '76 cigarros' : '76 cigarettes',
  '77 cigarros' : '77 cigarettes',
  '78 cigarros' : '78 cigarettes',
  '79 cigarros' : '79 cigarettes',
  '80 cigarros' : '80 cigarettes',
  '81 cigarros' : '81 cigarettes',
  '82 cigarros' : '82 cigarettes',
  '83 cigarros' : '83 cigarettes',
  '84 cigarros' : '84 cigarettes',
  '85 cigarros' : '85 cigarettes',
  '86 cigarros' : '86 cigarettes',
  '87 cigarros' : '87 cigarettes',
  '88 cigarros' : '88 cigarettes',
  '89 cigarros' : '89 cigarettes',
  '90 cigarros' : '90 cigarettes',
  '91 cigarros' : '91 cigarettes',
  '92 cigarros' : '92 cigarettes',
  '93 cigarros' : '93 cigarettes',
  '94 cigarros' : '94 cigarettes',
  '95 cigarros' : '95 cigarettes',
  '96 cigarros' : '96 cigarettes',
  '97 cigarros' : '97 cigarettes',
  '98 cigarros' : '98 cigarettes',
  '99 cigarros' : '99 cigarettes',
  '100 cigarros': '100 cigarettes',
  'Eu sei quais recursos posso usar se sentir que preciso de apoio (por exemplo, benefícios de saúde mental, programas de assistência aos funcionários, suporte virtual ou no local).' : 'I know what resources I can use if I feel like I need support (e.g. mental health benefits, employee assistance programs, virtual and/or onsite supports).',
  'Sinto-me contente, alegre e consigo me conectar facilmente com as pessoas no trabalho.' : 'I feel content, happy, and I can easily connect with people at work.',
  'Interajo regularmente com meus colegas de trabalho, fazendo-me sentir que pertenço a um grupo, que estou seguro e protegido.': 'I interact regularly with my co-workers, making me feel that I belong in a group, that I am safe and secure.',
  'Se já parou de fumar charutos, há quantos anos?': 'If you stopped smoking cigars, how many years ago?',
  'Durante quanto anos você fumou cigarros?': 'For how many years have you smoked cigarettes?',
  'Se já parou de fumar cigarros, há quantos anos?': 'If you have stopped smoking cigarettes, how many years ago?',
  'Você já realizou um exame genético (conhecido como BRCA1 ou BRCA) para saber se tem uma alteração/mutação que aumenta seu risco para desenvolver câncer e este resultado foi positivo?': 'Have you ever had the genotype exam (known as BRCA1 or BRCA) to find out whether you have an alteration/mutation that increases your risk of developing cancer and this result was positive?',
  'Vc respondeu que o teste para pesquisa de mutação BRCA e ele foi positivo . Está correto?': 'You answered that result for the BRCA exam was positive. Is that correct?',
  'Voce já teve Câncer?': 'Have you ever had Cancer?',
  'Selecione todas alternativas sobre o histórico de câncer em sua família': "Select all alternatives about your family's history of cancer",
  'Qual o seu consumo diário de bebida alcólica?': 'What is your daily alcohol consumption?',
  'Digite acima o tipo de problema cardíaco.': 'Enter the type of heart problem above.',
  'Você tem histórico de problemas emocionais ?': 'Do you have a history of emotional problems?',
  'Qual tipo de problema emoncional?': 'What kind of emotional problem?',
  'Com qual idade veio sua primeira Menstruação?':
    'How old were you when you had your first period?',
  'Quantos parentes de 1 grau (mãe, pai, irmã, irmão, filha ou filho) tiveram câncer de Mama?':
    'Have any first-degree relatives (father, mother, brother, sister, son and / or daughter) had breast cancer?',
  'Vc respondeu que um parente de 1º grau (mãe, pai, irmã, iramão, filha ou filho) tiveram cancer de mama. Está correto?':
    'You answered that a first-degree relative (father, mother, brother, sister, son and / or daughter) had breast cancer. Is this correct?',
  'Já fez alguma biopsia de mama?': 'Have you ever had a breast biopsy?',
  'Você já teve relação sexual?': 'Have you had sexual relations?',
  'O câncer de colo de útero é ocasionado por um vírus chamado HPV que, na maioria das vezes, é transmitido pela relação sexual. As pessoas que já tiveram relações apresentam um risco de ter adquirido o vírus e, consequentemente, têm mais chance.': 'Cervical cancer is caused by a virus called HPV, which is most often transmitted through sexual intercourse. People who have already had sex are at risk of having acquired the virus and are therefore more likely to have it.',
  'Você já realizou uma cirurgia para retirada total do útero (histerectomia)': 'Have you ever had surgery to remove your uterus completely (hysterectomy)',
  'Pacientes que retiraram o útero conjuntamente com o colo uterino não apresentam mais risco de desenvolver câncer de colo uterino.': 'Patients who have the uterus removed together with the cervix are no longer at risk of developing cervical cancer.',
  'Já realizou algum exame preventivo de colo uterino (papanicolau)?': 'Have you had any preventive cervical exam (pap smear)?',
  'Exame recomendado após os 21 anos de idade para todas as mulheres sadias e de risco normal que já tiveram relação sexual.': 'Recommended examination after age 21 for all healthy women of normal risk who have ever had sexual intercourse.',
  'Quando foi a ultima vez?': 'When was the most recent?',
  'Alguma vez deu alteração dos tipos NIC I, NIC II , NIC III HSIL ou LSIL?': 'Have you ever changed the CIN I, CIN II, CIN III, HSIL or LSIL types?',
  'São alterações identificadas no exame preventivo (Papanicolaou) ou na colposcopia que podem estar associados a um risco aumentado de desenvolver câncer de colo uterino.': 'These are changes identified in the Pap smear or colposcopy that may be associated with an increased risk of developing cervical cancer.',
  'Você já tomou as 3 doses de vacina para o HPV': 'Have you taken the 3 doses of the HPV vaccine?',
  'HPV é um vírus, geralmente de transmissão sexual, que pode fazer lesões imperceptíveis no colo de útero e posteriormente gerar o câncer desse órgão.': 'HPV is a virus, usually sexually transmitted, that can make imperceptible lesions on the cervix and later generate cancer of that organ.',
  'Durante sua vida, fez uso regular e constante de preservativos ou diafrágmas?': 'During your life, did you make regular and constant use of condoms or diaphragms?',
  'A utilização desses métodos de barreira diminuem o risco de transmissão de um vírus que tem o potencial de ocasionar o câncer de colo uterino.': 'Using these barrier methods reduces the risk of transmitting a virus that has the potential to cause cervical cancer.',
  'Sente dor intensa durante a relação sexual': 'Do you feel intense pain during intercourse?',
  'Não é considerado normal sentir dor durante as relações sexuais.': 'It is not considered normal to feel pain during sexual intercourse.',
  'Sangramentos vaginais irregulares ou após a menopausa necessitam ser melhor avaliados.': 'Irregular vaginal bleeding or bleeding after menopause needs to be further evaluated.',
  'Selecione todas as atividades abaixo com as quais você tenha trabalhado sem a devida proteção': 'Have you worked with any of these substances without proper protection? Please select all that apply.',
  'Você sente falta de ar ou dificulade para respirar?': 'Do you feel short of breath or difficulty breathing?',
  'Algum parentes de 1 grau (mãe, pai, irmã, irmão, filha ou filho) já teve câncer de intestino?': 'Have any first-degree relatives (father, mother, brother, sister, son and / or daughter) had bowel cancer?',
  'Vc respondeu que um parente de 1º grau (mãe, pai, irmã, iramão, filha ou filho) já teve câncer de intestino. Está correto?': 'You answered that a first-degree relative (father, mother, brother, sister, son and / or daughter) had bowel cancer. Is this correct?',
  'Você já teve alguma doença intestinal em que teve que retirar um pólipo (por colonoscopia) e seu médico lhe falou que este pólipo era de alto risco epoderia virar câncer? ': 'Have you ever had a bowel disease where you had to have a polyp removed (by colonoscopy), and your doctor told you that this polyp was high risk and could turn into cancer?',
  'Quando foi a última vez ?': 'When was the most recent?',
  'Algum parentes de 1 grau (pai, irmão ou filho) já teve câncer de próstata?': 'Have any 1st degree relatives (father, brother or son) ever had prostate cancer?',
  'Pessoas que apresentam um parente de 1º grau ou mais de 1 parente com câncer de próstata apresentam risco aumentado para desenvolvimento do câncer de próstata.': 'People who have a 1st-degree relative or more than 1 relative with prostate cancer are at increased risk for developing prostate cancer.',
  'Vc respondeu que um parente de 1º grau (pai, irmão ou filho) já teve câncer de Próstata. Está correto?': 'You answered that a first-degree relative (father, mother, brother, sister, son and / or daughter) had prostate cancer. Is this correct?',
  'O esforço para urinar está mais intenso do que habitualmente?': 'Is the effort to urinate more intense than usual?',
  'A perda de sangue pela urina não é normal e deve ser melhor avaliada em uma consulta médica.': 'Blood loss through urine is not normal and should be better evaluated in a medical consultation.',
  'O não esvaziamento completo da bexiga após urinar requer uma avaliação mais aprofundada desse sintoma.': 'Failure to completely empty the bladder after urinating requires further evaluation of this symptom.',
  'Estou satisfeito com minhas atuais condições de trabalho': 'I am satisfied with my current working conditions (physical environment, home office conditions, my assignments and work team).',
  'Os meus colegas me dão apoio emocional para me ajudar-mea gerir o meu stress no trabalho.': 'My colleagues give me emotional support to help me manage my stress at work.',
  'Os meus colegas perguntam como eu estou frequentemente.': 'My colleagues ask how I am often.',
  'Eu realmente me importo com o que acontece com meus colegas ou clientes no meu trabalho.': 'I really care what happens to my colleagues or clients at work.',
  'Sinto-me mais insensível com as pessoas desde que aceitei este trabalho': 'I feel more callous to people since I took this job',
  'O meu supervisor fornece o apoio de que necessito para gerir o meu trabalho': 'My supervisor provides the support I need to manage my work',
  'O meu supervisor dá-me apoio emocional para me ajudar a gerir o meu stress.': 'My supervisor gives me emotional support to help me manage my stress.',
  'O meu supervisor pergunta frequentemente como estou, fazendo-me sentir confiante e seguro no trabalho.': 'My supervisor often asks how I am doing, making me feel confident and secure at work.',
  'Sinto-me à vontade para conversar com meu supervisor para mudar coisas estressantes sobre meu trabalho (por exemplo, carga de trabalho, folgas, mudança de tarefas/responsabilidades).': 'I feel comfortable talking to my supervisor about changing stressful things about my job (e.g. workload, time off, changing tasks/responsibilities).',
  'Sinto que realizei muitas coisas valiosas no meu trabalho.': 'I feel that I have accomplished many valuable things in my work.',
  'Sinto que tenho um papel e responsabilidades claramente definidos e que garantem que meu dia corra bem.': 'I feel like I have a clearly defined role and responsibilities that ensure my day runs smoothly.',
  'Sinto orgulho de que as pessoas entendam o valor do meu papel e da minha contribuição para a organização.': 'I am proud that people understand the value of my role and my contribution to the organization.',
  'Sinto que estou no controle de minhas prioridades, definindo o faço e quando faço.': 'I feel like I’m in control of my priorities, defining what I do and when I do it',
  'Minha carga de trabalho aumentou, distraindo meu foco da tarefa pela qual sou responsável.': 'My workload has increased, distracting my focus from the task I am responsible for.',
  'Sinto que minha carga de trabalho está aumentando muito além do que posso lidar.': 'I feel like my workload is increasing far beyond what I can handle.',
  'Eu gasto tempo procurando uma nova posição no trabalho ou em outra empresa.': 'I spend time looking for a new position at work or at another company.',
  'Sinto que meu equilíbrio entre trabalho e vida pessoal está sob controle.': 'I feel my work-life balance is under control.',
  'Nos últimos 6 meses, sinto que alcancei resultados no meu trabalho e minhas relações pessoais estão ótimas.': 'In the last 6 months, I feel that I have achieved results in my work and my personal relationships are great.',
  'Durante os últimos 30 dias, com que frequência você se sentiu cansado sem uma boa razão para isso?': 'During the past 30 days, how often have you felt tired for no good reason?',
  'Durante os últimos 30 dias, com que frequência você se sentiu nervoso?': 'During the past 30 days, how often have you felt nervous?',
  'Durante os últimos 30 dias, com que frequência você se sentiu tão nervoso que nada conseguiu acalmá-lo?': 'During the past 30 days, how often have you felt so nervous that nothing could calm you down?',
  'Durante os últimos 30 dias, com que frequência você se sentiu sem esperança?': 'During the past 30 days, how often have you felt hopeless?',
  'Durante os últimos 30 dias, com que frequência você se sentiu inquieto?': 'During the past 30 days, how often have you felt restless?',
  'Durante os últimos 30 dias, com que frequência você se sentiu tão inquieto que não conseguia ficar parado?': 'During the past 30 days, how often have you felt so restless that you couldn’t sit still?',
  'Durante os últimos 30 dias, com que frequência você se sentiu mais triste do que o normal?': 'During the past 30 days, how often have you felt sadder than usual?',
  'Durante os últimos 30 dias, com que frequência você sentiu que qualquer coisa era um esforço?': 'During the past 30 days, how often did you feel that anything was an effort?',
  'Durante os últimos 30 dias, com que frequência você se sentiu tão triste que nada poderia animá-lo?': 'During the past 30 days, how often have you felt so sad that nothing could cheer you up?',
  'Durante os últimos 30 dias, com que frequência você parou de fazer coisas que gosta, como esportes ou reuniões sociais?': 'During the past 30 days, how often have you stopped doing things you enjoy, such as sports or social gatherings?',
  'During the past 30 days, how often have you stopped doing things you enjoy, such as sports or social gatherings? ': 'In the last 6 months, I lost or gained weight without trying to, or my appetite changed.',
  'Nos últimos 6 meses, parei de me divertir fazendo coisas que costumava gostar.': 'In the last 6 months, I stopped having fun doing things I used to enjoy.',
  'Nos últimos 6 meses, você evitou propositalmente entrar em situações nas quais pudesse sentir estresse ou ataque de pânico?': 'In the past 6 months, have you purposefully avoided getting into situations where you might experience stress or a panic attack?',
  'Eu ganho o suficiente para que a minha renda não seja uma preocupação na minha vida pessoal.': 'I earn enough that my income is not a concern in my personal life.',
  'Eu ganho o suficiente para economizar 3 meses de despesas para uma emergência.': 'I earn enough to save 3 months of expenses for an emergency.',
  'Eu me preocupo em não ter dinheiro suficiente para pagar minhas despesas pessoais.': 'I worry about not having enough money to pay my living expenses.',
  'Nos últimos 6 meses, perdi ou ganhei peso sem tentar, ou meu apetite mudou.': 'In the last 6 months, I have lost or gained weight without trying, or my appetite has changed.',
  'Câncer de Próstata': 'Prostate Cancer',
  'Algum parente de 1° grau (pai, irmão ou filho) já teve câncer de próstata?': 'Has any 1st degree relative (father, brother or son) ever had prostate cancer?',
  'Você respondeu que um parente de 1º grau (pai, irmão ou filho) já teve câncer de próstata. Está correto?' : 'You responded that a 1st degree relative (father, brother or son) has had prostate cancer. Is this correct?',
  'Sente dificuldade para urinar ou percebeu que o jato de urina está mais fraco?': 'Do you have difficulty urinating, or have you noticed that the urine stream is weaker?', 
  'Percebeu saída de sangue na urina?': 'Did you notice blood in the urine?',
  'Ao urinar, tem a sensação que não consegue esvaziar a bexiga por completo?': 'When urinating, do you feel like you can’t empty your bladder completely?',
  'Ás vezes': 'Sometimes',
  'Compartilhar Diagnóstico': 'Share Diagnosis',
  'Especifique o problema cardíaco:': 'Specify the heart problem:',
  'Em geral, indivíduos com Diabetes tipo 1 manifestam o quadro nas duas primeiras décadas de vida, e precisam fazer uso de insulina. O Diabetes tipo 2 surge mais tarde, normalmente associado ao excesso de peso. Embora possa ser necessário uso de insulina, o':
    'In general, individuals with type 1 diabetes manifest the condition in the first two decades of life and need to use insulin. Type 2 diabetes comes later, usually associated with being overweight. Although insulin use may be required, control is usually done with oral medications.',
  'Sistólica é a mais alta. Exemplo: se a sua pressão é 12/8, então é a 12. Nesse caso, preencha 120. Se for 12,5, preencha 125.':
    "Systolic is the highest. Example: if your pressure is 12/8, then it's 12. In that case, fill in 120. If it's 12.5, fill in 125",

  'Não Sou um robô': 'I am not a robot',
  'Alto risco' : 'High risk',
  'Baixo risco' : 'Low risk',
  'Baixo Risco' : 'Low risk',
  'Médio risco': 'Mediun risk',
  'Médio Risco': 'Mediun risk',
  'Sem Risco': 'No risk',
  'Risco normal': 'Normal risk',
  'Alto Risco': 'High risk',
  'Risco abaixo da média': 'Below average risk',
  'Copiado para a área de transferência': 'Copied to the download area',

  'Câncer de Próstata (Masculino)' : 'Prostate cancer (male)',
  'Câncer de Útero (Feminino)' : 'Cancer of the uterus (female)',
  'Histórico Médico (Masculino)' : 'Medical history (male)',
  'Histórico Médico (Feminino)' : 'Medical history (female)',
  'Câncer de Mama (Feminino)' : 'Breast cancer (female)',
  'Câncer de Pulmão (Unissex)' : 'Lung cancer (unisex)',
  'Câncer de Cólon (Unissex)' : 'Colon cancer (unisex)',
  'Mental (Unissex)' : 'Mental (unisex)',
  'Cardio (Unissex)': 'Cardio (unisex)', 

  'variable.WORK.Well.INDIVIDUAL.Well':
    'Your answers indicate that you are not experiencing symptoms related to anxiety or stress in the workplace. At a personal level, your answers reveal that you are healthy, without symptoms of stress or general anxiety.',
  'variable.WORK.Well.INDIVIDUAL.Low':
    'Your answers indicate that you are not experiencing symptoms related to anxiety or stress in the workplace.At a personal level, your answers reveal that you are healthy, with very few symptoms of stress or general anxiety.',
  'variable.WORK.Low.INDIVIDUAL.Well':
    'Your answers indicate that you are not experiencing important symptoms related to anxiety or stress at work. At a Personal level, your answers reveal that you are healthy, without symptoms of stress or general anxiety.',
  'variable.WORK.Well.INDIVIDUAL.Medium':
    'Your answers indicate that you are not experiencing symptoms related to anxiety or stress in the workplace. At a Personal level, your results indicate that you are experiencing some moderate symptoms related to stress or general anxiety.',
  'variable.WORK.Low.INDIVIDUAL.Low':
    'Your answers indicate that you are not experiencing important symptoms related to anxiety or stress at work.At a personal level, your answers reveal that you are healthy, with very few symptoms of stress or general anxiety.',
  'variable.WORK.Medium.INDIVIDUAL.Well':
    'Your answers indicate that you are concerned and think you may need work support, so we recommend you talk to your employer or consult a mental health professional for a complete assessment. At a Personal level, your answers reveal that you are healthy, without symptoms of stress or general anxiety.',
  'variable.WORK.Well.INDIVIDUAL.High':
    'Your answers indicate that you are not experiencing symptoms related to anxiety or stress in the workplace. Personally, it seems that stress is affecting your life. Most people feel stressed at some point and your symptoms are common experiences for the majority of the population.',
  'variable.WORK.Low.INDIVIDUAL.Medium':
    'Your answers indicate that you are not experiencing important symptoms related to anxiety or stress at work. At a Personal level, your results indicate that you are experiencing some moderate symptoms related to stress or general anxiety.',
  'variable.WORK.Medium.INDIVIDUAL.Low':
    'Your answers indicate that you are concerned and think you may need work support, so we recommend you talk to your employer or consult a mental health professional for a complete assessment.At a personal level, your answers reveal that you are healthy, with very few symptoms of stress or general anxiety.',
  'variable.WORK.High.INDIVIDUAL.Well':
    'The workplace can be very challenging. Your answers indicate that you may need support. The good news is that there is always a way. You are stronger than anything on your way, and there are some strategies you can use to help manage your anxiety about work.At a Personal level, your answers reveal that you are healthy, without symptoms of stress or general anxiety.',
  'variable.WORK.Low.INDIVIDUAL.High':
    'Your answers indicate that you are not experiencing important symptoms related to anxiety or stress at work. Personally, it seems that stress is affecting your life. Most people feel stressed at some point and your symptoms are common experiences for the majority of the population.',
  'variable.WORK.Medium.INDIVIDUAL.Medium':
    'Your answers indicate that you are concerned and think you may need work support, so we recommend you talk to your employer or consult a mental health professional for a complete assessment. At a Personal level, your results indicate that you are experiencing some moderate symptoms related to stress or general anxiety.',
  'variable.WORK.High.INDIVIDUAL.Low':
    'The workplace can be very challenging. Your answers indicate that you may need support. The good news is that there is always a way. You are stronger than anything on your way, and there are some strategies you can use to help manage your anxiety about work.At a personal level, your answers reveal that you are healthy, with very few symptoms of stress or general anxiety.',
  'variable.WORK.Medium.INDIVIDUAL.High':
    'Your answers indicate that you are concerned and think you may need work support, so we recommend you talk to your employer or consult a mental health professional for a complete assessment. Personally, it seems that stress is affecting your life. Most people feel stressed at some point and your symptoms are common experiences for the majority of the population.',
  'variable.WORK.High.INDIVIDUAL.Medium':
    'The workplace can be very challenging. Your answers indicate that you may need support. The good news is that there is always a way. You are stronger than anything on your way, and there are some strategies you can use to help manage your anxiety about work. At a personal level, your results indicate that you are experiencing some moderate symptoms related to stress or general anxiety.',
  'variable.WORK.High.INDIVIDUAL.High':
    'The workplace can be very challenging. Your answers indicate that you may need support. The good news is that there is always a way. You are stronger than anything on your way, and there are some strategies you can use to help manage your anxiety about work. Personally, it seems that stress is affecting your life. Most people feel stressed at some point and your symptoms are common experiences for the majority of the population.',

  'Todos os Riscos' : 'All risks' ,
  'Masculino' : 'Male',
  'Feminino' : 'Female',
  'Somente Exames':'Exams only',
  'Somente Sintoma':'Only symptoms',
  'Alto Risco + Exame':'High Risk + Exam',
  'Alto Risco + Sintoma':'High Risk + Symptom',
  'Exame + Sintoma':'Exam + Symptom',
  'Alto Risco + Exame + Sintoma': 'High Risk + Exam + Symptom',
  'plugin.OverrideMultiSlect':{
    "allItemsAreSelected": "All",
    "clearSearch": "Clear Search",
    "clearSelected": "Clear Selected",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectAllFiltered": "Select All (Filtered)",
    "selectSomeItems": "Select...",
    "create": "Create",
  },

  //Tour
  "tour.greetings.title": "Shall we prevent together?",
  "tour.greetings": "We are happy to have you here!",
  "tour.greetings.message": "The Previneo system helps you take the first step towards prevention quickly and without leaving home!",
  "tour.first.step": "The first step is to fill in the medical history. This information will be important to your result.",
  "tour.estimated.time": "Estimated completion time: 1 to 2 min",
  "tour.second.step": "Now just fill out your digital interview. If you have other interviews, follow the same steps.",
  "tour.third.step": "In the menu, you will find the options: return to the home screen, log out of the system and access your account.",
  "tour.last.step.title": "Shall we fill?",
  "tour.last.step": "Don't forget that your results are inside each digital interview, and you can access more information in \"Digital Interview Details\".",
  "tour.finished": "Start",
  "tour.start": "Let's start",
  "tour.skip": "Skip tour",
  "tour.next.step": "Next"
  
}
