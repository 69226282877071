import styled from 'styled-components';

export const ContainerFilter = styled.div`
  display: flex;
  align-items: start;
  &.isFetching {
    opacity: 0.5;
  }

  .Select {
    width: 200px;
    heigth: 10px;

    .Select-control {
      border: none;
    }

    @media (max-width: 912px) {
      width: 100% !important;
    }
  }

  .section-filter_dashboard {
    display: flex;
    align-items: flex-start;

    @media (max-width: 912px) {
      display: block !important;
      justify-content: center !important;
      align-items: center !important;
      width: 100% !important;
    }
  }

  .select_filter_company_dashboard,
  .select_filter_interview_dashboard,
  .select_filter_risk_dashboard {
    width: 200px;

    @media (max-width: 912px) {
      display: block !important;
      justify-content: center !important;
      align-items: center !important;
      width: 100% !important;
    }
  }

  .column_filter {
    margin: 0;
    margin-left: 7px;

    @media (max-width: 912px) {
      margin-left: 0px;
      margin-bottom: 8px !important;
    }
  }

  .section_button_datapicker_dashboard {
    margin-right: 10px;
  }

  .Select--multi .Select-value {
    background-color: rgb(38 119 188);
    border-radius: 8px;
    border: 1px solid rgb(149 188 221);
    display: flex;
    align-items: center;
    font-size: 0.6em;
    margin-left: 5px;
    margin-top: 2px;
  }
  .button_datapicker_dashboard {
    font-size: 12px;
    width: 100%;
    font-weight: 400;
  }

  .FormGrid {
    margin: 0 -2px;
  }

  .FormGrid > * {
    margin: 0 3px;
  }

  .FormGrid .column-filter {
    margin: 0;
  }

  @media (max-width: 912px) {
    display: block;

    .Select {
      width: 100% !important;
    }
    .sc-bxivhb {
      margin-top: 0px;
      margin-left: 0px;
    }
    .button_filter_dashbord {
      margin-left: 0px !important;
    }
    .section_button_datapicker_dashboard {
      margin-right: 0px;
      margin-bottom: 10px;
    }
  }
`;
