import React, { Component } from 'react'
import { API_URL } from 'config/constants'
import CustomField from 'components/CustomField'
import Session from 'modules/Session'
import * as forms from "utils/forms";
import { getTokenKey } from 'store/api/Auth'
import { htmlAllowedTags } from 'admin/components/CustomEditor/htmlAllowedTags'
import { ComponentEditor } from './styles'
import { TextHTML } from "./styles";
import Resource from 'containers/Resource';
import $ from 'jquery';

window.$ = require('jquery')

const FroalaEditor = require('react-froala-wysiwyg').default

require('froala-editor/js/froala_editor.pkgd.min.js')
// 'froala_style.min.css' removido para evitar que o editor adicione regras indesejadas ao html dos emails
// require('froala-editor/css/froala_style.min.css')
require('froala-editor/css/froala_editor.pkgd.min.css')
require('font-awesome/css/font-awesome.css')

class CustomEditor extends Component {
  state = {
    mounted: false
  }

  componentDidMount () {
    this.toolbarButtons = [
      "fullscreen",
      "bold",
      "italic",
      "undeline",
      "strikeThrough",
      "subscript",
      "superscript",
      "|",
      "fontFamily",
      "fontSize",
      "color",
      "inlineClass",
      "inlineStyle",
      "paragraphStyle",
      "lineHeight",
      "|",
      "paragraphFormat",
      "align",
      "formatOL",
      "formatUL",
      "outdent",
      "indent",
      "quote",
      "|",
      "insertLink",
      "insertImage",
      "insertVideo",
      "uploadFile",
      "insertTable",
      "|",
      "emoticons",
      "specialCharacters",
      "insertHR",
      "selectAll",
      "clearFormatting",
      "|",
      "print",
      "getPDF",
      "help",
      "html",
      "|",
      "undo",
      "redo"      
    ];
    
    this.config = {
      key: '1F4D3C7C5eF5C4B3D4E2C2C4D6E4D3xutbI-7c1dncF5mh1A-7I1pa==',
      attribution: false,
      useClasses: true,
      htmlRemoveTags: ['script', 'base'],
      htmlAllowedTags: htmlAllowedTags,
      imageUploadURL: `${API_URL}/api/media?saveMediaInPublicFolder=1`,
      requestHeaders: {
        Authorization: `Bearer ${Session.get(getTokenKey('usersAdmWeb'))}`
        
      },
      toolbarButtons: [...this.toolbarButtons, '|','insertButton'],
      events: {
        'froalaEditor.image.uploaded': (e, editor, response) => {
          const { data } = JSON.parse(response)

          editor.image.insert(data.url, false, null, editor.image.get(), response)

          return false
        },

        'froalaEditor.image.removed':(e, editor, response) => {
          let medianame = response[0].src;
          console.log(medianame)
          console.log(this.props.remove(medianame));

        },

      }
    }

  $.FroalaEditor.DefineIcon('insertButton', {NAME: 'toggle-on', SVG_KEY: 'button'});
  $.FroalaEditor.RegisterCommand('insertButton', {
    title: 'insert Button',
    type: 'dropdown',
    options: {
      'btn-primary'   : "<b class='--froala button btn-primary'>Primary</b>",
      'btn-secondary' : "<b class='--froala button btn-secondary'>Secondary</b>",
      'btn-success'   : "<b class='--froala button btn-success'>Success</b>",
      'btn-warning'   : "<b class='--froala button btn-warning'>Warning</b>",
      'btn-danger'    : "<b class='--froala button btn-danger'>Danger</b>",
      'btn-info'      : "<b class='--froala button btn-info'>Info</b>",
      'btn-white'     : "<b class='--froala button btn-white'>White</b>",
      'btn-black'     : "<b class='--froala button btn-black'>Black</b>"
    },
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function (cmd, val, params) {
      this.html.insert(`<a class="--froala button ${val}" href="#" target="_blank"> New Button </a>`);
    }
  });

    this.setState({ mounted: true })
  }

  render () {
    if (!this.state.mounted) return null

    return (
      <ComponentEditor>
        {this.props.readOnly ? 
          (
            <TextHTML
                dangerouslySetInnerHTML={{ __html: this.props.value}}>
            </TextHTML>
          ) 
        : (
          <FroalaEditor
            tag='textarea'
            model={this.props.value}
            config={this.config}
            onModelChange={this.props.onChange}
            {...forms.textAreaFroalaRequired}
          />
        )}   
      </ComponentEditor>
    )
  }
}

export default props => (
  <Resource resource="Media">
    { (res) =>{
      props = {...res,...props}
      return (
      <CustomField component={CustomEditor} {...props} />
    )}}
  </Resource>
)
