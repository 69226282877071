import React from 'react';
import { Link } from 'react-router-dom';
import { Redirect, Switch, Route } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { injectIntl } from 'react-intl';
import Auth from 'admin/containers/Auth';
import Toggle from 'components/Toggle';
import Notifications from 'containers/Notifications';
import VerticalSpacer from 'components/VerticalSpacer';
import DashboardHeader from 'users/components/DashboardHeader';
import SidebarButton from 'admin/components/SidebarButton';
import * as Screens from 'users/screens';
import { NotFoundScreen } from 'screens';
import routes from 'routes';
import Questionnaire from 'users/questionnaire/containers/Questionnaire';
import DiagnosticsResultsScreen from 'users/screens/DiagnosticsResultsScreen';
import DiagnosticFewDetailsScreen from 'users/questionnaire/screens/DiagnosticFewDetailsScreen';
import BodyImage from 'users/components/BodyImage';
import Languages from 'components/Languages/Languages';
import { Container, Content, Sidebar, CustomHR, MenuItem } from './styles';

function Dashboard({ intl, location }) {
  return (
    <Languages>
      <Auth>
        {props => {
          if (!props.isAuthenticated) {
            const url = `${routes.users.login}?goto=${location.pathname}`;
            return <Redirect to={url} />;
          }
          return (
            <BodyImage gender={props.user.userData.genero_biologico}>
              <Toggle resetOnRouteChange>
                {({ toggle: toggleMenu, value: isMenuOpened }) => (
                  <Toggle defaultValue>
                    {({ toggle: toggleHorizontal, value: isNarrowMenu }) => (
                      <div>
                        <ReactTooltip
                          disable={!isNarrowMenu}
                          id="dashboard-tooltip"
                        />
                        <Container>
                          <Sidebar
                            narrow={isNarrowMenu}
                            isMenuOpened={isMenuOpened}
                          >
                            <div>
                              <SidebarButton
                                narrow={isNarrowMenu}
                                exact
                                to="/users"
                                title="Home"
                                icon={require('@fortawesome/fontawesome-free-solid/faHome')}
                                onClick={''}
                                className={`menu-sidebar`}
                              />
                            </div>
                            {/*  <SidebarButton
                              narrow={isNarrowMenu}
                              exact
                              to={routes.users.diagnosticResults.index}
                              title={intl.formatMessage({ id: 'users.results.title' })}
                              icon={require('@fortawesome/fontawesome-free-solid/faStethoscope')}
                            /> */}
                            {props.permited(props, 'userAdmin') && (
                              <React.Fragment>
                                <div>
                                  <SidebarButton
                                    narrow={isNarrowMenu}
                                    to={routes.users.admin.patients.index}
                                    title={intl.formatMessage({
                                      id: 'admin.patients.title.plural',
                                    })}
                                    icon={require('@fortawesome/fontawesome-free-solid/faUsers')}
                                    onClick={''}
                                  />
                                </div>

                                <div>
                                  <SidebarButton
                                    narrow={isNarrowMenu}
                                    to={routes.users.admin.companies.index}
                                    title={intl.formatMessage({
                                      id: 'admin.company.title.plural',
                                    })}
                                    icon={require('@fortawesome/fontawesome-free-solid/faBuilding')}
                                    onClick={''}
                                  />
                                </div>
                              </React.Fragment>
                            )}

                            {props.permited(props, 'access_dashboard') && (
                              <div>
                                <SidebarButton
                                  narrow={isNarrowMenu}
                                  to={routes.users.dashbord}
                                  title={intl.formatMessage({
                                    id: 'Dashbord',
                                  })}
                                  icon={require('@fortawesome/fontawesome-free-solid/faChartBar')}
                                  onClick={''}
                                />
                              </div>
                            )}

                            {props.permited(props, 'userAdmin') && (
                              <div>
                                <SidebarButton
                                  className="custom-botton"
                                  narrow={isNarrowMenu}
                                  to="#"
                                  title={intl.formatMessage({
                                    id: 'admin.common.reports',
                                  })}
                                  icon={require('@fortawesome/fontawesome-free-solid/faClipboardList')}
                                >
                                  <div className="section-sub-menu mobile-menu-style">
                                    <MenuItem>
                                      <Link
                                        to={routes.users.reportsCompany}
                                        className="item-submenu"
                                      >
                                        {intl.formatMessage({
                                          id: 'admin.report.personal_data',
                                        })}
                                      </Link>
                                    </MenuItem>
                                    <MenuItem>
                                      <Link
                                        to={routes.users.reportsRisk}
                                        className="item-submenu"
                                      >
                                        {intl.formatMessage({
                                          id: 'admin.report.risk',
                                        })}
                                      </Link>
                                    </MenuItem>
                                    <MenuItem>
                                      <Link
                                        to={routes.users.reportsMedicalHistory}
                                        className="item-submenu"
                                      >
                                        {intl.formatMessage({
                                          id: 'admin.report.medical.history',
                                        })}
                                      </Link>
                                    </MenuItem>
                                    <MenuItem>
                                      <Link
                                        to={routes.users.reportsMentalRisk}
                                        className="item-submenu"
                                      >
                                        {intl.formatMessage({
                                          id: 'admin.report.mental',
                                        })}
                                      </Link>
                                    </MenuItem>
                                    <MenuItem>
                                      <Link
                                        to={
                                          routes.users.reportsMentalHistorical
                                        }
                                        className="item-submenu"
                                      >
                                        {intl.formatMessage({
                                          id: 'admin.history.mental',
                                        })}
                                      </Link>
                                    </MenuItem>
                                  </div>
                                </SidebarButton>
                              </div>
                            )}

                            <div>
                              <SidebarButton
                                narrow={isNarrowMenu}
                                to={routes.users.myAccount}
                                title={intl.formatMessage({
                                  id: 'admin.common.myAccount',
                                })}
                                icon={require('@fortawesome/fontawesome-free-solid/faUserEdit')}
                                onClick={''}
                              />
                              <SidebarButton
                                narrow={isNarrowMenu}
                                to={routes.site.logout}
                                title={intl.formatMessage({
                                  id: 'admin.common.signOut',
                                })}
                                icon={require('@fortawesome/fontawesome-free-solid/faSignOutAlt')}
                                onClick={''}
                              />
                            </div>
                          </Sidebar>
                          <Content narrow={isNarrowMenu}>
                            <VerticalSpacer>
                              <DashboardHeader
                                intl={intl}
                                onMenuButtonClick={toggleMenu}
                                type={props.type}
                              />
                              <CustomHR />
                              <Notifications />
                              <Switch>
                                <Route
                                  exact
                                  path={routes.users.index}
                                  component={Screens.DashboardScreen}
                                />
                                <Route
                                  path={routes.users.admin.patients.index}
                                  component={Screens.PatientsScreen}
                                />
                                <Route
                                  path={routes.users.admin.companies.index}
                                  component={Screens.CompaniesScreen}
                                />
                                <Route
                                  path={routes.users.myAccount}
                                  component={Screens.MyAccountScreen}
                                />
                                <Route
                                  path={routes.users.reportsCompany}
                                  component={Screens.ReportsCompanyScreen}
                                />
                                <Route
                                  path={routes.users.reportsRisk}
                                  component={Screens.ReportsRiskScreen}
                                />
                                <Route
                                  path={routes.users.reportsMedicalHistory}
                                  component={
                                    Screens.ReportsMedicalHistoryScreen
                                  }
                                />
                                <Route
                                  path={routes.users.reportsMentalRisk}
                                  component={Screens.ReportsMentalRiskScreen}
                                />
                                <Route
                                  path={routes.users.reportsMentalHistorical}
                                  component={
                                    Screens.ReportsMentalHistoricalScreen
                                  }
                                />
                                <Route
                                  path={routes.users.dashbord}
                                  component={Screens.DashbordAnamneseScreen}
                                />
                                <Route
                                  path={routes.users.diagnostic.index}
                                  component={Questionnaire}
                                />
                                <Route
                                  exact
                                  path={
                                    routes.users.diagnosticResults.fewDetails
                                  }
                                  component={DiagnosticFewDetailsScreen}
                                />
                                <Route
                                  path={routes.users.diagnosticResults.index}
                                  component={DiagnosticsResultsScreen}
                                />
                                <Route component={NotFoundScreen} />
                              </Switch>
                            </VerticalSpacer>
                          </Content>
                        </Container>
                      </div>
                    )}
                  </Toggle>
                )}
              </Toggle>
            </BodyImage>
          );
        }}
      </Auth>
    </Languages>
  );
}

export default injectIntl(Dashboard);
