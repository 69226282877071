/**
 * Module dependencies.
 */

import createActionTypes from 'store/utils/createActionTypes'
import createReducer from 'store/utils/createReducer'
import Storage from 'modules/Storage'
// import Session from 'modules/Session'
import { actionTypes as actionTypesAuth } from './auth'
import { actionTypes as actionTypesQuestionnaire } from './questionnaire'

/**
 * Action Types.
 */

export const actionTypes = createActionTypes('localization', [
  'SET_LANG'
])

const defaultLanguage = Storage.get('lang') || 'pt'

/**
 * Initial State.
 */

export const initialState = {
  lang: Storage.set('lang', defaultLanguage)
}

/**
 * Reducer.
 */

export default createReducer(initialState, {
  [actionTypes.SET_LANG] (state, { payload }) {
    return { lang: payload.lang }
  },

  [actionTypesAuth.LOGIN_FULFILLED] (state, { payload }) {
    return { lang: Storage.set('lang', Storage.get('lang')) }
  },

  [actionTypesQuestionnaire.FETCH_DATA_FULFILLED] (state, { payload }) {
    return { lang: defaultLanguage }
  }
})

/**
 * Action Creators.
 */

export const setLang = lang =>  ({
  type: actionTypes.SET_LANG,
  payload: { lang: Storage.set('lang', lang) }
})
