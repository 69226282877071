import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

class FieldListenerInner extends Component {
  componentDidUpdate ({ value: oldValue }) {
    const { value: newValue } = this.props
    if (newValue !== oldValue) {
      this.props.onChange(
        newValue,
        oldValue
      )
    }
  }

  render () {
    return null
  }
}

export default function FieldListener ({ name, onChange }) {
  return (
    <Field
      name={name}
      subscription={{ value: true }}
      render={({ input: { value } }) => (
        <FieldListenerInner
          value={value}
          onChange={onChange}
        />
      )}
    />
  )
}

FieldListener.propTypes = {
  /** Field's name */
  name: PropTypes.string.isRequired,

  /** Change handler */
  onChange: PropTypes.func.isRequired
}
