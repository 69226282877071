/**
 * Module dependencies.
 */

import updateObj from 'immutability-helper'
import objectPath from 'object-path'
import createReducer from 'store/utils/createReducer'
import createActionTypes from 'store/utils/createActionTypes'
import { Companies } from 'store/api'

/**
 * Action Types.
 */

export const actionTypes = createActionTypes('companies', [
  'CREATE',
  'CREATE_PENDING',
  'CREATE_FULFILLED',
  'CREATE_REJECTED',

  'FETCH',
  'FETCH_PENDING',
  'FETCH_FULFILLED',
  'FETCH_REJECTED',

  'UPDATE',
  'UPDATE_PENDING',
  'UPDATE_FULFILLED',
  'UPDATE_REJECTED',

  'REMOVE',
  'REMOVE_PENDING',
  'REMOVE_FULFILLED',
  'REMOVE_REJECTED',

  'REGISTER_ROOT_NODE'
])

/**
 * Initial State.
 */

export const initialState = {
  nodes: {
    0: {
      id: 0,
      name: 'Root',
      children: [],
      loaded: false,
      loading: false,
      removing: false
    }
  },
  error: {},
  isSubmitting: false,
  isFetching: false,
  isRemoving: false
}

/**
 * Reducer.
 */

export default createReducer(initialState, {
  /**
   * Fetch Nodes.
   */

  [actionTypes.FETCH_PENDING] (state, { meta: { parentId } }) {
    return updateObj(state, {
      nodes: {
        [parentId]: {
          loading: { $set: true }
        }
      },
      isFetching: { $set: true }
    })
  },

  [actionTypes.FETCH_FULFILLED] (state, { payload, meta: { parentId } }) {
    return updateObj(state, {
      nodes: {
        [parentId]: {
          children: { $set: payload.map(n => n.id) },
          loading: { $set: false },
          loaded: { $set: true }
        },
        $merge: payload.reduce((memo, value) => {
          memo[value.id] = {
            ...value,
            loading: false,
            loaded: false,
            removing: false
          }
          return memo
        }, {})
      },
      isFetching: { $set: false },
      error: { $set: {} }
    })
  },

  [actionTypes.FETCH_REJECTED] (state, { payload, meta: { parentId } }) {
    return updateObj(state, {
      nodes: {
        [parentId]: {
          loading: { $set: false }
        },
        isFetching: { $set: false },
        error: { $set: payload }
      }
    })
  },

  /**
   * Create.
   */

  [actionTypes.CREATE_PENDING] (state) {
    return updateObj(state, {
      isSubmitting: { $set: true }
    })
  },

  [actionTypes.CREATE_FULFILLED] (state, { payload, meta: { parentId } }) {
    return updateObj(state, {
      nodes: {
        [parentId]: {
          children: { $push: [ payload.id ] }
        },
        [payload.id]: {
          $set: {
            ...payload,
            children: [],
            loading: false,
            loaded: true
          }
        }
      },
      isSubmitting: { $set: false },
      error: { $set: {} }
    })
  },

  [actionTypes.CREATE_REJECTED] (state, { payload }) {
    return updateObj(state, {
      isSubmitting: { $set: false },
      error: { $set: payload }
    })
  },

  /**
   * Update.
   */

  [actionTypes.UPDATE_PENDING] (state, { meta: { id } }) {
    return updateObj(state, {
      isSubmitting: { $set: true }
    })
  },

  [actionTypes.UPDATE_FULFILLED] (state, { payload, meta: { id } }) {
    return updateObj(state, {
      nodes: {
        [id]: {
          $merge: {
            name: payload.name,
            data: payload.data
          }
        }
      },
      isSubmitting: { $set: false },
      error: { $set: {} }
    })
  },

  [actionTypes.UPDATE_REJECTED] (state, { payload, meta: { id } }) {
    return updateObj(state, {
      isSubmitting: { $set: false },
      error: { $set: payload }
    })
  },

  /**
   * Remove.
   */

  [actionTypes.REMOVE_PENDING] (state, { meta: { id } }) {
    return updateObj(state, {
      nodes: {
        [id]: {
          removing: { $set: true }
        }
      },
      isRemoving: { $set: true }
    })
  },

  [actionTypes.REMOVE_FULFILLED] (state, { meta: { id, parentId } }) {
    return updateObj(state, {
      nodes: {
        [parentId]: {
          children: { $apply: values => values.filter(i => i !== id) }
        },
        [id]: { $set: undefined }
      },
      isRemoving: { $set: false },
      error: { $set: {} }
    })
  },

  [actionTypes.REMOVE_REJECTED] (state, { payload, meta: { id } }) {
    return updateObj(state, {
      nodes: {
        [id]: {
          removing: { $set: false }
        }
      },
      isRemoving: { $set: false },
      error: { $set: payload }
    })
  },

  /**
   * Register Root Node.
   */

  [actionTypes.REGISTER_ROOT_NODE] (state, { payload: { id, name } }) {
    return updateObj(state, {
      nodes: {
        [id]: {
          $set: {
            id,
            name,
            children: [],
            loaded: false,
            loading: false,
            removing: false
          }
        }
      }
    })
  }
})

/**
 * Action Creators.
 */

export const fetchNodes = parentId => ({
  type: actionTypes.FETCH,
  payload: Companies.fetchNodes(parentId),
  meta: {
    parentId,
    notifications: {
      errors: true
    }
  }
})

export const create = data => ({
  type: actionTypes.CREATE,
  payload: Companies.create(data),
  meta: {
    parentId: objectPath.get(data, 'parent.id', 0),
    notifications: {
      notificationsId: 'companies',
      errors: true,
      success: true
    }
  }
})

export const update = (id, data) => ({
  type: actionTypes.UPDATE,
  payload: Companies.update(id, data),
  meta: {
    id,
    notifications: {
      notificationsId: 'notifications',
      errors: true,
      success: true
    }
  }
})

export const remove = (id, parentId) => ({
  type: actionTypes.REMOVE,
  payload: Companies.remove(id),
  meta: {
    id,
    parentId,
    notifications: {
      errors: true,
      success: true
    }
  }
})

export const registerRootNode = (id, name) => ({
  type: actionTypes.REGISTER_ROOT_NODE,
  payload: { id, name }
})

/**
 * Selectors.
 */

export const getNodes = state =>
  state.companies.nodes

export const isSubmitting = state =>
  state.companies.isSubmitting

export const getError = state =>
  state.companies.error
