import styled from 'styled-components';

export const Context = styled.div`
  justify-content: center;
  align-items: center;
  display: block;

  .span-info {
    font-weight: 200;
  }
  .title_none_data {
    font-size: 18px;
  }
  .emoji_none_data {
    width: 70px;
  }

  @media (max-width: 912px) {
    padding: 20px;

    .title_none_data {
      font-size: 14px;
      padding-bottom: 15px !important;
    }
    .emoji_none_data {
      width: 80px;
    }
    .cards {
      margin-bottom: 10px !important;
    }
  }
`;

export const SectionTitleCard = styled.div`
  justify-content: flex-start;
  align-items: center;
  display: flex;
  padding-bottom: 10px ;

  .card-subtitle {
    color: '#34495E'
    font-weight: bold;
    justify-content: flex-start;
}

  @media (max-width: 912px) {
    display: block;
    margin-top: -13px;

    .card-subtitle{
      margin-top: 20px;
      margin-bottom: -1px;
      font-size: 15px;
    }

    .card-span {
      font-size: 12px;
      line-height: 0.1 !important;
    }
  }
`;

export const TitleCard = styled.h4`
  margin: 10px 0 -50px !important;
  @media (max-width: 912px) {
    margin-top: -4px;
  }
`;

export const BodyCard = styled.h4`
  font-weight: bold;
  font-size: 35px;
  color: '#34495E';
  margin-bottom: -3px;
`;

export const Image = styled.img`
  max-width: 100%;
  height: 50px;
  margin-top: 18px;
  margin-bottom: -18px;
`;
