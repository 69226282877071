import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import QuestionnaireScreen from 'users/questionnaire/components/QuestionnaireScreen'
import { Link } from 'react-router-dom'
import Text from 'components/Text'
import Button from 'components/Button'
import * as duck from 'store/ducks/userQuestionnaire'
import getRoute from 'utils/getRoute'
import routes from 'routes'

function AnamneseScreen ({ data, auth, intl }) {
  const to = getRoute(routes.users.diagnostic.categories, auth)

  return (
    <QuestionnaireScreen
      title={intl.formatMessage({ id: 'admin.anamnesis.title' })}
      personal
    >
      <Text
        size='25px'
        lineHeight={1}
        style={{ marginBottom: 15 }}
      >
        <strong>
          {intl.formatMessage({ id: 'q.helloPatient' }, { name: data.paciente.name })}
        </strong>
      </Text>
      <Text size='18px'>
        {intl.formatMessage({ id: 'q.patientIntroText' })}
      </Text>
      <Button
        to={to}
        color='personal'
        style={{width: '100%', marginTop: '50px'}}
        component={Link}
      >
        {intl.formatMessage({ id: 'q.startAvaliation' })}
      </Button>
    </QuestionnaireScreen>
  )
}

const mapStateToProps = state => ({
  data: duck.getData(state),
  auth: duck.getAuth(state)
})

export default connect(
  mapStateToProps
)(injectIntl(AnamneseScreen))
