import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import VerticalSpacer from 'components/VerticalSpacer'
import ButtonSubmit from 'components/ButtonSubmit'
import { injectIntl } from 'react-intl'
import Text from '../../../components/Text/Text'

function FormAnamneseConfirmation ({ intl, isSubmitting, readOnly, ...props }) {
  return (
    <Form {...props}>
      {({ handleSubmit, pristine }) => (
        <form onSubmit={handleSubmit}>
          <VerticalSpacer>
            <Text>
              {intl.formatMessage({ id: 'admin.common.confirmationNotFound' })}
            </Text>
            {!readOnly && (
              <ButtonSubmit
                isSubmitting={isSubmitting}
                disabled={false}
                block
                label={intl.formatMessage({ id: 'admin.common.reSendEmail' })}
              />
            )}
          </VerticalSpacer>
        </form>
      )}
    </Form>
  )
}

FormAnamneseConfirmation.propTypes = {
  /** Callback para o form submit */
  onSubmit: PropTypes.func.isRequired,

  /** Form sendo processado */
  isSubmitting: PropTypes.bool,

  /** Exibe o form em modo leitura */
  readOnly: PropTypes.bool
}

export default injectIntl(FormAnamneseConfirmation)
