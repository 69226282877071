import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { isEmpty } from 'ramda'
import objectPath from 'object-path'
import { Redirect } from 'react-router-dom'
import Wizard from 'questionnaire/components/Wizard'
import * as forms from './forms'
import theme from 'theme'

class PatientWizard extends Component {
  constructor (props) {
    super(props)

    this.state = {
      activeForm: 0,
      done: false,
      formData: {},
      formsWithErrors: [],
      disableBottom: false
    }

    this.forms = [
      forms.formPersonal,
      forms.formAddress,
      forms.formPhysical,
      forms.formEthnicity
    ]

    const formFields = this.getFormFields()

    if (formFields.form.length > 0) {
      this.forms.push(formFields)
    }
  }

  getFormFields () {
    const formFields = []
    const { scriptFormFields } = this.props
    
    if (scriptFormFields.matricula) {
      const Form = forms.formCode
      formFields.push(
        <Form
          key='form_id_number'
          intl={this.props.intl}
        />
      )
    }

    if (scriptFormFields.celular) {
      const Form = forms.formPhone
      formFields.push(
        <Form
          key='form_phone'
          intl={this.props.intl}
        />
      )
    }

    if (scriptFormFields.plano_saude) {
      const Form = forms.formHealthInsurance
      const companyId = this.props.companyId
      formFields.push(
        <Form
          key='form_health_insurance'
          companyId={companyId}
          intl={this.props.intl}
        />
      )
    }

    window.TACompanyId = (scriptFormFields.empresa.id === 137 ||
                          scriptFormFields.empresa.id === 138 ||
                          scriptFormFields.empresa.id === 139 ||
                          scriptFormFields.empresa.id === 152 ||
                          scriptFormFields.empresa.id === 154 ||
                          scriptFormFields.empresa.id === 140) ? true : false;
    return {
      form: formFields,
      fields: Object.keys(scriptFormFields)
    }
  }

  componentDidUpdate ({ isSubmitting }) {
    if (isSubmitting && !this.props.isSubmitting) {
      if (isEmpty(this.props.error)) {
        this.setState({ done: true })
      } else {
        this.handleValidationErrors()
      }
    }
  }

  handleValidationErrors () {
    const validationErrors = objectPath.get(this.props.error, 'res.body.errors')
    const fieldsWithErrors = validationErrors ? Object.keys(validationErrors) : {}
    const disableBottom = objectPath.get(this.props.error, 'res.body.send_email')

    disableBottom && this.setState({disableBottom: disableBottom})

    if (validationErrors) {
      const formsWithErrors = this.forms.reduce((memo, form, index) => {
        const fields = fieldsWithErrors.filter(field => form.fields.includes(field))
        if (fields.length > 0) {
          return memo.concat({
            index,
            fields
          })
        }
        return memo
      }, [])

      const step = formsWithErrors.length > 0
        ? formsWithErrors[0].index
        : 0

      this.setState({
        activeForm: step,
        disableBottom: false,
        formsWithErrors
      })
    }
  }

  getNextFormIndex = formsWithErrors => {
    if (this.state.formsWithErrors.length > 0) {
      return formsWithErrors.length > 0
        ? formsWithErrors[0].index
        : this.forms.length
    }

    return this.state.activeForm + 1
  }

  handleSubmit = data => {
    const formsWithErrors = this.state.formsWithErrors.filter(form =>
      form.index !== this.state.activeForm
    )

    const nextFormIndex = this.getNextFormIndex(formsWithErrors)

    if (nextFormIndex < this.forms.length) {
      return this.setState({
        activeForm: nextFormIndex,
        formsWithErrors
      })
    }

    this.submitData(data)
  }

  submitData (data) {
    this.setState({ formsWithErrors: [] }, () => {
      this.props.onSubmit(data)
    })
  }

  handleOnBackClick = () => {
    if (this.state.activeForm > 0) {
      this.setState({
        activeForm: this.state.activeForm - 1,
        formsWithErrors: []
      })
    }
  }

  render () {
    if (this.state.done && this.props.redirectTo) {
      return <Redirect to={this.props.redirectTo} />
    }

    return (
      <Wizard
        initialValues={{
          aceita_contato: true,
          ...this.props.initialValues
        }}
        onSubmit={this.handleSubmit}
        form={this.forms[this.state.activeForm].form}
        count={this.forms.length}
        progress={this.state.activeForm + 1}
        canGoBack={this.state.activeForm > 0}
        onBackClick={this.handleOnBackClick}
        isSubmitting={this.props.isSubmitting}
        color={theme.colors.personal}
        disableBottom={this.state.disableBottom}
      />
    )
  }

  static propTypes = {
    /** Dados iniciais */
    initialValues: PropTypes.object.isRequired,

    /** Form Fields */
    scriptFormFields: PropTypes.object,

    /** Submit handler */
    onSubmit: PropTypes.func.isRequired,

    /** Formulando sendo processado */
    isSubmitting: PropTypes.bool.isRequired,

    /** ID da empresa da qual pertence o paciente */
    companyId: PropTypes.number.isRequired,

    /** Possível error */
    error: PropTypes.object
  }
}

export default injectIntl(PatientWizard)
