import CustomFieldSelect from 'admin/components/CustomFieldSelect'
import LabelError from 'components/LabelError'
import OptionCheckbox from 'components/OptionCheckbox'
import Text from 'components/Text'
import VerticalSpacer from 'components/VerticalSpacer/VerticalSpacer'
import { NEGATIVE_ANSWER, QUESTIONS_FORMATS, SEXUALLY_ACTIVE_QUESTION, SURGICAL_HYSTERECTOMY, TYPE_QUESTIONS } from 'config/constants'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import React, { Component } from 'react'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import routes from 'routes'
import * as duck from 'store/ducks/userQuestionnaire'
import CustomQuestionField from 'users/questionnaire/components/CustomQuestionField'
import Wizard from 'users/questionnaire/components/Wizard'
import * as forms from 'utils/forms'
import getRoute from 'utils/getRoute'
import { integerBetween } from 'utils/validators'
import { Description, Question } from '../../components/QuestionField/styles'

class CategoryWizard extends Component {
  static propTypes = {
    /** Campos da anamnese */
    fields: PropTypes.array.isRequired
  }

  state = {
    questionaire_id: this.props.questionaire_id,
    currentQuestion: this.props.fields[0], 
    parent: { 
      children: this.props.fields, 
      parent: null 
    },
    childrenTitle: null,
    history: [],
    done: false,
    needStopWizard: false
  }

  componentDidUpdate (prevProps) {
    if (prevProps.isSubmitting && !this.props.isSubmitting && !this.props.anamneseCompleted && isEmpty(this.props.error) ) {
      this.nextQuestion()
    }
  }

  stopWizard(){
    this.setState({ 
      done: true 
    }, () => {
      this.props.completeAnamnese({
        anamneseID: this.props.match.params.anamneseID,
        questionaire_id: this.state.questionaire_id
      }) 
    })
  }

 

  nextQuestion () {
    const { lastAnswer: answer, currentQuestion: question, parent, needStopWizard } = this.state

    if(needStopWizard && question.object_id === SURGICAL_HYSTERECTOMY && answer.object_id === NEGATIVE_ANSWER){
      return this.stopWizard()
    }

    if (answer.children && answer.children.length > 0) {
      return this.setState({
        currentQuestion: answer.children[0],
        parent: {
          children: answer.children,
          parent: {
            ...question,
            parent
          }
        },
        history: this.state.history.concat({ question, parent })
      })
    }else if(typeof answer.value === 'string' && question.children[0].children && question.children[0].children.length > 0){
      return this.setState({
        currentQuestion: question.children[0].children[0],
        parent: {
          children: question.children[0].children,
          parent: {
            ...question,
            parent
          }
        },
        history: this.state.history.concat({ question, parent })
      })
    }

    const nextQuestion = this.getNextQuestion(question, parent)
    if (nextQuestion) {
      this.setState({
        currentQuestion: nextQuestion.question,
        parent: nextQuestion.parent,
        history: this.state.history.concat({ question, parent })
      })
    } else {
      this.stopWizard()
    } 
  }

  getNextQuestion (question, parent) { 
    const questionIndex = parent.children.findIndex(q => q.diagnosticlink_id === question.diagnosticlink_id)
    if (typeof questionIndex === 'number' && questionIndex > -1 && parent.children[questionIndex + 1] && parent.object_type !== 'question') {
      return {
        question: parent.children[questionIndex + 1],
        parent
      }
    }

    if (parent.parent) {
      return this.getNextQuestion(parent, parent.parent)
    }
  }

  previousQuestion () {
    if (this.state.history.length > 0) {
      const lastItem = this.state.history[this.state.history.length - 1]
      this.setState({
        currentQuestion: lastItem.question,
        parent: lastItem.parent,
        history: this.state.history.slice(0, this.state.history.length - 1)
      })
    }
  }

  getCurrentQuestionObj () {
    const { currentQuestion } = this.state

    return [ currentQuestion ].reduce((memo, question) => {
      memo[question.diagnosticlink_id] = question
      return memo
    }, {})
  }

  getAnsweredQuestionsObj () {
    const { perguntasRespondidas } = this.props
    const respostas = perguntasRespondidas

    return respostas.reduce((memo, answer) => {
      memo[answer.id] = answer
      return memo
    }, {})
  }

  handleSubmit = values => {
    const questionsObj = this.getCurrentQuestionObj()
    let data = Object.keys(questionsObj).map(id => {
      const question = questionsObj[id]
      const value = values[`question-${question.diagnosticlink_id}`]
  
      if(question.object_id === SEXUALLY_ACTIVE_QUESTION){
        this.setState({
          needStopWizard: question.children.find(r => r.diagnosticlink_id === value).object_id === NEGATIVE_ANSWER
        })
      }

      let choices = []
      if(question.object_type === TYPE_QUESTIONS.MULTIPLE_CHOICE.id){
        choices = value.map(row => {
          return {
            id,
            answer: row
          }
        })
      }

      if(question.object_type === TYPE_QUESTIONS.CHOICE_LIST.id){
        return {
          id,
          answer: question.children.find(a => a.object_id === value)
        }
      }

 
      return choices.length 
      ? choices
      : {
        id,
        answer: question.object_type === 'boolean' || question.object_type === TYPE_QUESTIONS.MULTIPLE_CHOICE.id
          ? question.children.find(a => a.diagnosticlink_id === value)
          : { value: value.toString(), object_id: question.children[0].object_id }
      }
    })

    const { currentQuestion } = this.state
    
    if(currentQuestion.object_type === TYPE_QUESTIONS.MULTIPLE_CHOICE.id){
      data = data[0]
    }

    const lastAnswer = data.find(choice => choice.answer.choice_force_next) || data[0]
    this.setState({
      lastAnswer: lastAnswer.answer
    }, () => {
      this.props.answerQuestion({
        ...this.props.auth,
        questions: data.map(q => 
          {
            return {
              original_question: currentQuestion.object_id,
              original_answer: q.answer.object_id,
              id: q.id, 
              questionaire_id: this.state.questionaire_id,
              answer: q.answer.diagnosticlink_id || q.answer.value,
              answer_type: currentQuestion.object_type
            }
        })
      }) 
    })
  }

  handleBackClick = () => {
    this.previousQuestion()
  }

  canGoBack () {
    return this.state.currentQuestion.diagnosticlink_id !== this.props.fields[0].diagnosticlink_id
  }

  getProgress () {
    const getNodeIndex = (node, parent) =>
      parent.children.findIndex(child => child.diagnosticlink_id === node.diagnosticlink_id) + 1

    const getPath = (node, parent, path = []) => {
      if (!parent) return path
      const index = getNodeIndex(node, parent)
      if (!index) {
        return getPath(parent, parent.parent, path)
      }
      return getPath(parent, parent.parent, [ index, ...path ])
    }

    return getPath(
      this.state.currentQuestion,
      this.state.parent
    ).join('.')
  }

  getInitialValues () {
   return {};
   /*  const perguntasRespondidas = this.getAnsweredQuestionsObj()
    const questionsObj = this.getCurrentQuestionObj()

    return Object.keys(questionsObj).reduce((memo, id) => {
      if (!perguntasRespondidas.hasOwnProperty(id)) {
        return memo
      }

      const question = perguntasRespondidas[id]
      const answer = question.resposta[0]
      memo[`question-${id}`] = question.tipo_resposta === TYPE_QUESTIONS.OBJETIVA.id
        ? answer.id
        : answer.resposta

      return memo
    }, {}) */
  }

  getInputByQuestion(question, options, formValidation){
    switch(question.object_type){
      case TYPE_QUESTIONS.MULTIPLE_CHOICE.id:
        return (
          <FieldArray 
            name={`question-${question.diagnosticlink_id}`}
            {...formValidation}
          >
            {({ fields, meta }) => {
              const toggle = (event, child, isChecked) => {
                const values = (fields.value && Object.values(fields.value)) || []

                if (event.target.checked || isChecked === undefined){
                  if(child.choice_cleaner){
                    fields.forEach((name, index) => {
                      fields.remove(0)
                    })
                  }else{
                    const questionCleanerIndex = values.findIndex(q => q.choice_cleaner)
                    if(questionCleanerIndex !== -1){
                      fields.remove(questionCleanerIndex)
                    }
                  }
                  
                  const find = values.find(q => q.object_id === child.object_id)
                  if(!find){
                    fields.push(child)
                  }
                } else {
                  const index = values.findIndex(q => q.object_id === child.object_id)
                  fields.remove(index)
                } 
              };

              return (
                <div>
                  <Question>{this.props.intl.formatMessage({id : question.object_description}) }</Question>
                  <VerticalSpacer space={10}>
                    {question.children.map(child => {
                      const isChecked = fields.value && fields.value.find(r => r.object_id === child.object_id)
                      return (
                        <Field
                          key={`question-${question.diagnosticlink_id}-${child.diagnosticlink_id}`}
                          type='checkbox'
                          children={this.props.intl.formatMessage({id : child.object_description}) }
                          value={child}
                          defaultChecked={isChecked}
                          component={OptionCheckbox}
                          description={question.object_subtitle ? this.props.intl.formatMessage({id :question.object_subtitle}) : ''}
                          onChange={event => toggle(event, child, isChecked)}
                        />
                      )
                    })}
                    { question.object_subtitle && (
                      <Description>{ this.props.intl.formatMessage({id :question.object_subtitle}) }</Description>
                    )}

                    {(meta.touched && meta.error) && (
                      <LabelError>
                        {typeof meta.error === 'string'
                          ? meta.error
                          : <FormattedMessage id={meta.error.id} values={meta.error} />
                        }
                      </LabelError>
                    )}

                  </VerticalSpacer>
                </div>
              )
            }}
          </FieldArray>
        )

      case TYPE_QUESTIONS.CHOICE_LIST.id:
        // ----------------------------  esse é o form do Cardio -----------------------------------------------
        return (
          <VerticalSpacer space={10}>
            <Field
              key={question.diagnosticlink_id}
              name={`question-${question.diagnosticlink_id}`}
              label={this.props.intl.formatMessage({id :question.object_description}) }
              description={question.object_subtitle ? this.props.intl.formatMessage({id :question.object_subtitle}) : '' }
              component={CustomFieldSelect}
              customSelectProps={{
                options: options
              }}
              {...formValidation}
            />

            { question.object_subtitle && (
              <Description>{ this.props.intl.formatMessage({id :question.object_subtitle}) }</Description>
            )}
          </VerticalSpacer>
        )

      default:
        return (
          <div key={question.diagnosticlink_id}>  
            <Field
              key={question.diagnosticlink_id}
              name={`question-${question.diagnosticlink_id}`}
              component={CustomQuestionField}
              question={this.props.intl.formatMessage({id : question.object_description})}
              description={question.object_subtitle ? this.props.intl.formatMessage({id : question.object_subtitle}) :''}
              options={options}
              color={this.props.color}
              readOnly={this.props.readOnly}
              {...formValidation}
            />
          </div>
        )

    }
  }

  renderCurrentForm (question) {
    if(question.subdiagnostic_id)
      this.props.setSubcategory(question.subdiagnostic_id)
    const options = () => {
      if(question.object_type !== 'question'){
        const childrenQuestions = question.children
        return Object.values(childrenQuestions).map(child => {
            return {
              label: this.props.intl.formatMessage({ id: child.object_description}),
              value: question.object_type === TYPE_QUESTIONS.CHOICE_LIST.id ? child.object_id : child.diagnosticlink_id
            }
        }) 
      }
    }

    const formValidation = question.object_format === QUESTIONS_FORMATS.integer.id
      ? {
        ...forms.integer,
        validate: integerBetween({ min: question.object_min_value, max: question.object_max_value })
      }
      : forms.required

    return this.getInputByQuestion(question, options(), formValidation)
  }

  render () {
    const { intl } = this.props   

    if(this.props.isCompletingAnamnese){
      return (
        <React.Fragment>
          <Text
            size='25px'
            lineHeight={1}
            style={{ marginBottom: 15 }}
            color={this.props.color}
          >
            <strong>
              {intl.formatMessage({ id: 'admin.common.loading' })}
            </strong>
          </Text>
          <Text size='18px' style={{ marginBottom: 50 }}>
            {intl.formatMessage({ id: 'admin.common.processingAnamnese' })}
          </Text>
        </React.Fragment>
      )
    }else if (this.state.done && this.props.anamneseCompleted) {
      const result = this.props.diagnosticResult

      if(result.id === -1){
        return <Redirect to={routes.users.index} />
      }

      return <Redirect to={getRoute(routes.users.diagnosticResults.fewDetails, { anamneseID: this.props.match.params.anamneseID })} />
    }

    return (
      <Wizard
        initialValues={this.getInitialValues()}
        form={this.renderCurrentForm(this.state.currentQuestion)}
        description={this.state.currentQuestion.object_subtitle}
        onSubmit={this.handleSubmit}
        canGoBack={this.canGoBack()}
        onBackClick={this.handleBackClick}
        isSubmitting={this.props.isSubmitting}
        count={this.props.fields.length}
        progress={this.getProgress()}
        color={this.props.color}
      />
    )
  }
}

const mapStateToProps = state => ({
  perguntasRespondidas: duck.getData(state).paciente,
  auth: duck.getAuth(state),
  isSubmitting: duck.isSubmitting(state),
  isCompletingAnamnese: duck.isCompletingAnamnese(state),
  anamneseCompleted: duck.anamneseCompleted(state),
  error: duck.getError(state),
  diagnosticResult: duck.getDiagnosticResult(state)
})

const mapDispatchToProps = {
  answerQuestion: duck.answerQuestion,
  completeAnamnese: duck.completeAnamnese
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CategoryWizard)))
