import Section from 'admin/components/Section'
import React from 'react'
import { injectIntl } from 'react-intl'

function Terms ({ intl }) {
  return (
    <Section title={window.TACompanyId?
                      (intl.formatMessage({ id: 'q.termsAndConditionsUnimed'})):
                      (intl.formatMessage({ id: 'q.termsAndConditions' }))} wrapped={false}>
      
    </Section>
  )
}
export default injectIntl(Terms)
