import { connect } from 'react-redux'
import { IntlProvider, addLocaleData } from 'react-intl'
import pt from 'react-intl/locale-data/pt'
import es from 'react-intl/locale-data/es'
import en from 'react-intl/locale-data/en'
import fr from 'react-intl/locale-data/fr'
import locales from 'config/locales'

addLocaleData([ ...pt, ...en, ...es, ...fr ])

export default connect(
  ({ localization: { lang } }) => ({
    locale: lang,
    messages: locales[lang]
  })
)(IntlProvider)
