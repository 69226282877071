import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'Poppins',sans-serif;
  background-color: #f9ebbe;
  border-radius: 30px;
  box-sizing: border-box;
  color: #0b4e71;
  font-size: 16px !important;
  max-width: 360px;
  min-width: 300px;
  padding: 10px 20px;
  text-align: center;
  position: relative;

  @media (max-width: 650px) {
    width: 125%;
    left: 50%;
    transform: translateX(-50%);

    #react-joyride-step-4 & {
      width: 150%;
    }
  }
`;

export const ContentContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
  padding: 0 10px;
`;

export const ButtonBoxCenter = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  justify-content: center;
`;

export const CenterButton = styled.button`
  background-color: #6d479a;
  border-radius: 5px;
  color: white;
  border: none;
  max-width: 150px;
  width: 100%;
  height: 35px;
  padding: 0;
  text-align: center;
  cursor: pointer;
`;

export const NextButton = styled.button`
  background-color: #6d479a;
  border-radius: 5px;
  color: white;
  border: none;
  width: 70px;
  height: 35px;
  padding: 0;
  text-align: center;
  cursor: pointer;
`;

export const ButtonBox = styled.div`
  display:flex;
  width: 100%;
  justify-content: space-between;
  align-item: center;
`;


export const TextField = styled.p`
  margin: 0;
  padding: 0;
`;


export const InitialImage = styled.img`
  max-height: 125px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
`;

export const TitleWithImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
