import React from 'react'
import styled from 'styled-components'
import { inputCss } from 'components/Input/styles'

const SelectStyled = styled.select`
  ${inputCss}
  padding: 0 5px;
  height: 40px;
  cursor: pointer;
`

export default function Select (props) {
  return <SelectStyled {...props} />
}
