import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'


function FormLogin ({ isSubmitting, intl, hideForgetPasswordLink, hideNotifications, patient, ...props }) {
 // const recaptchaRef = React.createRef();
  // function onChange(value) {
  //   window.recaptchaValue = value;// recaptchaRef.current.getValue();
  // }
  return (        
    null
  )
}

FormLogin.propTypes = {
  /** Callback para o form submit */
  onSubmit: PropTypes.func.isRequired,

  /** Form sendo processado */
  isSubmitting: PropTypes.bool
}

export default injectIntl(FormLogin)
