import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Section from 'admin/components/Section';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import FormAdditionalInformationRegister from '../FormAdditionalInformationRegister/FormAdditionalInformationRegister';
import FormInformationRegister from '../FormInformationRegister/FormInformationRegister';
import FormPersonalDataRegister from '../FormPersonalDataRegister/FormPersonalDataRegister';
import FormValidEmail from '../FormValidEmail/FormValidEmail';
import { Container } from './styles';

function FormValidCupom({
  intl,
  hideForgetPasswordLink,
  terms,
  company,
  ...props
}) {
  const [page, setPage] = useState(0);
  const [loginWithApi, setLoginWithApi] = useState(false);
  const companies = company.company_id;

  const steps = [
    intl.formatMessage({ id: 'admin.common.personalData' }),
    intl.formatMessage({ id: 'admin.common.information' }),
  ];

  if (companies === 139) {
    steps.push(
      intl.formatMessage({ id: 'admin.common.additional.information' }),
    );
  }

  const formDataDefault = {
    nome_company: '',
    email: '',
    password: '',
    password_confirmation: '',
    nome: '',
    telefone: '',
    cpf: '',
    genero_biologico: '',
    etnia: '',
    dt_nascimento: '',
    contato_telefonico: '',
    contato_previneo_whatsapp: '',
    termos_uso: '',
    cpf: '',
    cep: '',
    health_plan: '',
  };
  //state inicial register per page
  const [formData, setFormData] = useState(formDataDefault);

  const clearFormData = () => {
    setFormData(formDataDefault);
  };

  const handlePage = (props, company) => {
    props = {
      setPage,
      company,
      formData,
      setFormData,
      loginWithApi,
      setLoginWithApi,
      clearFormData,
      terms,
      ...props,
    };

    //condição de renderização de componentes por page
    if (page === 0) return <FormValidEmail {...props} />;
    else if (page === 1) return <FormPersonalDataRegister {...props} />;
    else if (page === 2) return <FormInformationRegister {...props} />;
    else if (companies !== 139) {
      return null;
    } else return <FormAdditionalInformationRegister {...props} />;
  };

  useEffect(() => {
    setPage(page);
  }, [page]);

  //função valida envio com sucesso
  return (
    <Container>
      <Section
        title={
          !page &&
          intl.formatMessage({
            id: 'pages.register.register',
          })
        }
      >
        {page ? (
          <Container>
            <Box sx={{ width: '100%', pb: 5 }}>
              <Stepper activeStep={page - 1} alternativeLabel>
                {steps.map(label => {
                  return (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
          </Container>
        ) : (
          <hr style={{ marginTop: '-12px', marginBottom: '32px' }} />
        )}
        <Container>{handlePage(props, company)}</Container>
      </Section>
    </Container>
  );
}

FormValidCupom.propTypes = {
  /** Callback para o form submit */
  onSubmit: PropTypes.func.isRequired,

  /** Form sendo processado */
  isSubmitting: PropTypes.bool,

  /** Exibe o form em modo leitura */
  readOnly: PropTypes.bool,
};

export default injectIntl(FormValidCupom);
