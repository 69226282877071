import styled from 'styled-components';
import media from 'utils/mediaQuery';

export const ModalContentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${props => props.width}px;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding: 20px;
  margin-top: 40px;
  max-height: 100vh;

  ${props => media.lessThan(`${props.width}px`)`
    width: 100%;
  `}
`;

export const ModalContent = styled.div`
  background-color: #fff;
  border-radius: 5px;
  ${props => props.padded && `padding: 20px;`};

  @media (max-width: 800px) {
    margin-top: 40px;
  }
`;

export const ButtonClose = styled.button`
  position: absolute;
  right: 20px;
  top: -10px;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  background: transparent;

  @media (max-width: 800px) {
    margin-top: 40px;
  }
`;
