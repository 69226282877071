import ResourceScreen from 'admin/containers/ResourceScreen'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import routes from 'routes'
import withAuth from 'store/utils/withAuth'
import DiagnosticResult from 'users/components/DiagnosticResult'

let Lang =  '';
function DiagnosticsResultsScreen({ intl }){
  if( Lang === '')
    Lang = intl.locale
  else if( Lang !==intl.locale ){
    Lang = intl.locale
    window.location.reload();
  }

  return (
    <ResourceScreen
      resource='ProcessedResults'
      autoFetch
      readableResourceName={[
        intl.formatMessage({ id: 'users.results.diagnostic.title' }),
        intl.formatMessage({ id: 'users.results.diagnostic.title.plural' })
      ]}
      routeBase={routes.users.diagnosticResults}
      permissions={{}}
      Form={props => <DiagnosticResult {...props} />}
      resourceUpdateProps={{
        wrapped: false,
        style: { overflow: 'visible' }
      }}
      resourceDetailsProps={{
        wrapped: false,
        style: { overflow: 'visible' }
      }}
      resourceNewProps={{style: { overflow: 'visible' }}}
      dataTableProps={{ filterable: true }}
      canAdd={false}
      canEdit={false}
      canRemove={false}
      columns={[
        {
          accessor: 'diagnostic.diagnostic_name',
          Header: intl.formatMessage({ id: 'users.results.diagnosticType' }),
          width: 300,
          filterable: true
        },
        {
          accessor: 'risk.risk_description',
          Header: intl.formatMessage({ id: 'users.results.risk' }),
          width: 300,
          filterable: true,
        },
        {
          accessor: 'justifications',
          Header: intl.formatMessage({ id: 'users.results.justificatives' }),
          width: 300,
          filterable: true,
          Cell: (row) => {
            return row.original.justifications ? row.original.justifications.join("\n") : ''
          }
        },
        {
          accessor: 'symptoms',
          Header: intl.formatMessage({ id: 'users.results.everHadSymptoms' }),
          width: 300,
          filterable: true,
          Cell: (row) => {
            return row.value 
            ? <FormattedMessage id="admin.common.contactMadeOk" />
            : <FormattedMessage id='admin.common.contactMadeNo' />;
          }
        }
      ]}
    />
  )
}

export default withAuth(
    injectIntl(DiagnosticsResultsScreen)
)
