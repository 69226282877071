import createPermissions from 'utils/createPermissions'

export default {
  anamnesis: createPermissions('anamneses'),
  users: createPermissions('users'),
  permissions: createPermissions('permissions'),
  roles: createPermissions('roles'),
  companies: createPermissions('empresas'),
  healthInsurances: createPermissions('planos_de_saude'),
  healthInsurancesCompany: createPermissions('planos_de_saude_empresa'),
  typesCancer: createPermissions('tipos_de_cancer'),
  patients: createPermissions('pacientes'),
  patientsHistory: createPermissions('prontuarios'),
  patientsHistoryContact: createPermissions('prontuarios'),
  patientsHistoryContactReason: createPermissions('motivos_contato'),
  testimonies: createPermissions('depoimentos'),
  specialities: createPermissions('especialidades'),
  team: createPermissions('equipes'),
  customers: createPermissions('clientes'),
  posts: createPermissions('posts'),
  tags: createPermissions('tags'),
  blogCategories: createPermissions('categorias_blog'),
  genders: createPermissions('generos'),
  risks: createPermissions('riscos'),
  languages: createPermissions('idiomas'),
  emails: createPermissions('emails'),
  layouts: createPermissions('layouts'),
  strategy: createPermissions('estrategias'),
  exams: createPermissions('exames_recomendados'),
  reports: createPermissions('relatorios'),
  reportsCompany: createPermissions('relatorios_company'),
  contactInfos: createPermissions('informacoes_contato'),
  scripts: createPermissions('roteiros'),
  categories: createPermissions('categorias'),
  justifications: createPermissions('categorias'),
  statuses: createPermissions('statuses'),

  userCompanies: createPermissions('userCompanies'),
}
