import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const sizes = {
  default: '1280px',
  medium: '1190px',
  blog: '1150px',
  small: '1000px',
  xsmall: '600px'
}

const ContainerStyled = styled.div`
  display: block;
  max-width: ${props => sizes[props.size]};
  margin: ${props => props.margin ? props.margin : '0 auto'};
  padding: 0 20px;
  box-sizing: border-box;
`

export default function Container (props) {
  return (
    <ContainerStyled
      {...props}
    />
  )
}

Container.propTypes = {
  /** Define o tamanho máximo do container */
  size: PropTypes.oneOf(Object.keys(sizes))
}

Container.defaultProps = {
  size: 'default'
}
