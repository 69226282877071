/**
 * Module dependencies.
 */

import updateObj from 'immutability-helper'
import createReducer from 'store/utils/createReducer'
import createActionTypes from 'store/utils/createActionTypes'
import { Questionnaire } from 'store/api'
import { TYPE_QUESTIONS } from 'config/constants'
import { isAuthenticated, getAuthType } from 'store/ducks/auth'

/**
 * Action Types.
 */

export const actionTypes = createActionTypes('questionnaire', [
  'FETCH_DATA',
  'FETCH_DATA_PENDING',
  'FETCH_DATA_REJECTED',
  'FETCH_DATA_FULFILLED',

  'UPDATE_PATIENT',
  'UPDATE_PATIENT_PENDING',
  'UPDATE_PATIENT_REJECTED',
  'UPDATE_PATIENT_FULFILLED',

  'ANSWER_QUESTION',
  'ANSWER_QUESTION_PENDING',
  'ANSWER_QUESTION_FULFILLED',
  'ANSWER_QUESTION_REJECTED',

  'FETCH_RESULT',
  'FETCH_RESULT_PENDING',
  'FETCH_RESULT_FULFILLED',
  'FETCH_RESULT_REJECTED'
])

/**
 * Initial State.
 */

const initialState = {
  isFetching: false,
  isSubmitting: false,
  data: {},
  result: {},
  error: {},
  auth: {
    patient: '', // identifica o paciente
    script: '', // hash que identifica o roteiro
    session: '' // hash que identifica a versão do roteiro
  }
}

/**
 * Reducer.
 */

export default createReducer(initialState, {
  /**
   * Fetch Data.
   */

  [actionTypes.FETCH_DATA_PENDING] (state, action) {
    return updateObj(state, {
      isFetching: { $set: true }
    })
  },

  [actionTypes.FETCH_DATA_FULFILLED] (state, { payload, meta }) {
    return updateObj(state, {
      isFetching: { $set: false },
      data: { $set: payload.data },
      auth: { $set: meta.auth },
      error: { $set: {} }
    })
  },

  [actionTypes.FETCH_DATA_REJECTED] (state, { payload }) {
    return updateObj(state, {
      isFetching: { $set: false },
      data: { $set: {} },
      error: { $set: payload }
    })
  },

  /**
   * Update Patient.
   */

  [actionTypes.UPDATE_PATIENT_PENDING] (state, action) {
    return updateObj(state, {
      isSubmitting: { $set: true }
    })
  },

  [actionTypes.UPDATE_PATIENT_FULFILLED] (state, { payload }) {
    return updateObj(state, {
      isSubmitting: { $set: false },
      data: {
        paciente: { $set: payload.data }
      },
      error: { $set: {} }
    })
  },

  [actionTypes.UPDATE_PATIENT_REJECTED] (state, { payload }) {
    return updateObj(state, {
      isSubmitting: { $set: false },
      error: { $set: payload }
    })
  },

  /**
   * Answer Question.
   */

  [actionTypes.ANSWER_QUESTION_PENDING] (state) {
    return updateObj(state, {
      isSubmitting: { $set: true }
    })
  },

  [actionTypes.ANSWER_QUESTION_FULFILLED] (state, { payload: { data } }) {
    const answers = data.reduce((memo, value) => {
      memo[value.id] = value
      return memo
    }, {})
    return updateObj(state, {
      isSubmitting: { $set: false },
      data: {
        paciente: {
          perguntas_respondidas: {
            $merge: answers
          }
        }
      }
    })
  },

  [actionTypes.ANSWER_QUESTION_REJECTED] (state, { payload }) {
    return updateObj(state, {
      isSubmitting: { $set: false },
      error: { $set: payload }
    })
  },

  /**
   * Fetch Result.
   */

  [actionTypes.FETCH_RESULT_PENDING] (state) {
    return updateObj(state, {
      isSubmitting: { $set: true }
    })
  },

  [actionTypes.FETCH_RESULT_FULFILLED] (state, { payload }) {
    return updateObj(state, {
      isSubmitting: { $set: false },
      result: { $set: payload.data },
      error: { $set: {} }
    })
  },

  [actionTypes.FETCH_RESULT_REJECTED] (state, { payload }) {
    return updateObj(state, {
      isSubmitting: { $set: false },
      error: { $set: payload }
    })
  }
})

/**
 * Action Creators.
 */

const isAuth = state =>
  isAuthenticated(state) && getAuthType(state) === 'patients'

export const fetchData = ({ script, session, patient }) => (dispatch, getState) => dispatch({
  type: actionTypes.FETCH_DATA,
  payload: Questionnaire.fetchData({ script, session, patient }, isAuth(getState())),
  meta: {
    auth: {
      script,
      session,
      patient
    },
    notifications: {
      success: false,
      errors: true
    }
  }
})

export const updatePatient = args => (dispatch, getState) => dispatch({
  type: actionTypes.UPDATE_PATIENT,
  payload: Questionnaire.updatePatient(args, isAuth(getState())),
  meta: {
    notifications: {
      success: false,
      errors: true
    }
  }
})

export const answerQuestion = args => (dispatch, getState) => dispatch({
  type: actionTypes.ANSWER_QUESTION,
  payload: Questionnaire.answerQuestion(args, isAuth(getState())),
  meta: {
    notifications: {
      errors: true
    }
  }
})

export const fetchResult = args => (dispatch, getState) => dispatch({
  type: actionTypes.FETCH_RESULT,
  payload: Questionnaire.fetchResult(args, isAuth(getState())),
  meta: {
    notifications: {
      errors: true
    }
  }
})

/**
 * Selectors.
 */

export const isFetching = state =>
  state.questionnaire.isFetching

export const isSubmitting = state =>
  state.questionnaire.isSubmitting

export const getData = state =>
  state.questionnaire.data

export const getCategories = state => {
  const data = getData(state)
  const categories = data.categorias
  const perguntasRespondidas = data.paciente.perguntas_respondidas
  const respostas = perguntasRespondidas.reduce((memo, answer) => {
    memo[answer.id] = answer
    return memo
  }, {})

  return categories.map(c => {
    c.progresso = c.script.reduce((memo, question) => {
      if (question.typeQuestion === TYPE_QUESTIONS.GROUP.id) {
        return !question.children.find(q => !respostas.hasOwnProperty(q.id))
          ? memo + 1
          : memo
      }

      return respostas.hasOwnProperty(question.id)
        ? memo + 1
        : memo
    }, 0) / c.script.length
    return c
  })
}

export const getCategory = (state, id) => {
  const data = getData(state)
  return data.categorias.find(c => c.id === id) || {}
}

export const getError = state =>
  state.questionnaire.error

export const getAuth = state =>
  state.questionnaire.auth

export const getResult = state =>
  state.questionnaire.result
