import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Base from 'components/Base'
import { componentType } from 'types'

const LabelStyled = styled(Base)`
  display: block;
  font-family: ${props => props.theme.fonts.tertiary};
  font-size: ${props => props.size};
  color: ${props => props.theme.colors[props.color]};
`

export default function Label (props) {
  return <LabelStyled {...props} />
}

Label.propTypes = {
  /** Componente */
  component: componentType,

  /** Cor da label */
  color: PropTypes.string,

  /** Tamanho da label */
  size: PropTypes.string
}

Label.defaultProps = {
  component: 'label',
  color: 'text',
  size: '16px'
}
