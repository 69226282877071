import React from 'react'
import PropTypes from 'prop-types'
import { diff } from 'deep-object-diff'
import ResourceDetails from 'admin/containers/ResourceDetails'
import handleFormSubmission from 'utils/handleFormSubmission'

const ReplaceBlank = ( _default , _input ) =>{
  const _diff = diff(_default, _input);
  Object.keys(_diff).forEach(k=>{
    if(_diff[k] === undefined || _diff[k] === '')
      _input[k] = null;
  });
  return _input;
}

export default function ResourceUpdate ({ Form, updateStatus, useDiff, title, ...props }) {
  return (
    <ResourceDetails
      {...props}
      title={title}
      Details={props => (
        <Form
          initialValues={props.detailedRecord}
          onSubmit={values => {


            const data = useDiff
              ? diff(props.detailedRecord, values)
              : ReplaceBlank(props.detailedRecord, values)

            return handleFormSubmission(
              props.update(props.id, data)
            )
          }}
          isSubmitting={props.isSubmitting}
          updateResource
          resource={props}
          title={title}
          // action={'update'}
        />
      )}
    />
  )
}

ResourceUpdate.propTypes = {
  /** Recurso */
  resource: PropTypes.string.isRequired,

  /** ID do recurso a ser alterado */
  id: PropTypes.number.isRequired,

  /** Rota base */
  routeBase: PropTypes.object.isRequired,

  /** Título */
  title: PropTypes.string.isRequired,

  /** Form */
  Form: PropTypes.func.isRequired,

  /** Atualiza apenas o status do registro */
  updateStatus: PropTypes.bool,

  /** Embala o Form com uma box e inclui o section header */
  wrapped: PropTypes.bool,

  /** Redireciona para o routeBase.index após uma ação de sucesso */
  redirectAfterSuccess: PropTypes.bool,

  /** Aplica o diff antes de atualizar os dados */
  useDiff: PropTypes.bool.isRequired
}

ResourceUpdate.defaultProps = {
  updateStatus: false,
  wrapped: true,
  redirectAfterSuccess: true,
  useDiff: false
}
