import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/mediaQuery'

const GridStyled = styled.div`
  ${props => media.greaterThan(props.breakpoint)`
    display: flex;
    margin: 0 -${props => props.spacing / 2}px;

    > * {
      width: 100%;
      margin: 0 ${props => props.spacing / 2}px;
    }
  `}

  ${props => media.lessThan(props.breakpoint)`
    > * + * {
      margin-top: ${props => props.spacing}px;
    }
  `}
`

export default function Grid (props) {
  return <GridStyled {...props} />
}

Grid.propTypes = {
  /** Breakpoint para desfazer o Grid */
  breakpoint: PropTypes.string.isRequired,

  /** Espaçamento do grid */
  spacing: PropTypes.number
}

Grid.defaultProps = {
  breakpoint: 'medium',
  spacing: 40
}
