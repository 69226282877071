import styled from "styled-components";

export const FlexBox = styled.div`
  @media (max-width: 912px) {
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;
export const Container = styled.div`
  position: relative;
  width: 40vw;
  width: 100%;

  @media (max-width: 912px) {
    position: none !important;
    width: 100% !important;
  }
`;
