import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { injectIntl } from 'react-intl';
import Icon from '@fortawesome/react-fontawesome';
import VerticalSpacer from 'components/VerticalSpacer';
import CustomField from 'components/CustomField';
import ButtonSubmit from 'components/ButtonSubmit';
import OptionCheckbox from 'components/OptionCheckbox/OptionCheckbox';
import ReCAPTCHA from 'react-google-recaptcha';
import routes from 'routes';
import Link from 'components/Link';
import Notifications from 'containers/Notifications';
import SubmissionContainer from 'admin/components/SubmissionContainer';
import { ContainerCaptcha } from './style';

function HLCaptcha({ text, defaultChecked, handleExecute }) {
  return (
    <ContainerCaptcha>
      <OptionCheckbox defaultChecked={defaultChecked} onClick={handleExecute}>
        {text}
      </OptionCheckbox>
    </ContainerCaptcha>
  );
}

function FormRegister({
  isSubmitting,
  intl,
  hideForgetPasswordLink,
  hideNotifications,
  patient,
  defaultValue,
  ...props
}) {
  const [captcha, setCaptcha] = useState(false);
  const [isChecked, setChecked] = useState(false);
  // const recaptchaRef = React.createRef();
  function onChange(value) {
    if (value) {
      setCaptcha(item => !item);
      setChecked(true);
    }
  }
  function handleExecute() {
    if (window.grecaptcha) window.grecaptcha.execute();
  }

  return (
    <Form {...props}>
      {({ handleSubmit, pristine }) => (
        <form onSubmit={handleSubmit}>
          <VerticalSpacer>
            {!hideNotifications && <Notifications />}
            <Field
              name="company_code"
              label={intl.formatMessage({ id: 'pages.register.code' })}
              id="form_register_company_code"
              component={CustomField}
              placeholder={defaultValue}
            />

            <ReCAPTCHA
              sitekey="6LdKaMEUAAAAANh-sTabaJT3xrAfUVPlWxL2YtwQ"
              onChange={onChange}
              size="invisible"
            />

            <HLCaptcha
              text={intl.formatMessage({ id: 'Não Sou um robô' })}
              defaultChecked={isChecked}
              handleExecute={handleExecute}
            />
            <SubmissionContainer>
              <Link to={routes.users.login} onClick={window.location.reload}>
                <Icon
                  className="icon_arrow"
                  style={{ marginRight: '10px', width: 15 }}
                  icon={require('@fortawesome/fontawesome-free-solid/faArrowLeft')}
                />
                {intl.formatMessage({ id: 'pages.register.code.return' })}
              </Link>

              <ButtonSubmit
                color="primary"
                textColor="white"
                isSubmitting={isSubmitting}
                disabled={!captcha && !isSubmitting}
                label={intl.formatMessage({
                  id: 'pages.register.code.continue',
                })}
              />
            </SubmissionContainer>
          </VerticalSpacer>
        </form>
      )}
    </Form>
  );
}

FormRegister.propTypes = {
  /** Callback para o form submit */
  onSubmit: PropTypes.func.isRequired,

  /** Form sendo processado */
  isSubmitting: PropTypes.bool,
};

export default injectIntl(FormRegister);
