import Image from 'components/Image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import mediaQuery from 'utils/mediaQuery';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 0;
  margin-top: 20px;

  ${mediaQuery.lessThan('small')`
    flex-direction: column;
  `}
`;

const Card = styled.div`
  font-family: ${props => props.theme.fonts.tertiary};
  font-size: 12px;
  font-weight: bold;
  color: ${props => props.theme.colors.textNew};

  ${mediaQuery.greaterThan('medium')`
    &:not(:last-child){
      margin-right: 15px;
    }
  `}

  ${mediaQuery.lessThan('medium')`
    margin: 15px auto
  `}
`;

const RiskInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 110px;
  width: 120px;
  background-color: ${props => props.theme.colors.whiteLight};
  border-radius: 10px 10px 0 0;
  margin-bottom: -2px;
  text-align: center;

  > div {
    width: 60%;
  }
`;

const RiskInfoBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 130px;
  height: 55px;
  background-color: ${props => props.riskColor};
  border-radius: 10px;
  margin: 0 auto;
`;

export default function FewDetailsCardInfo({
  title,
  riskColor,
  riskName,
  ...props
}) {
  return (
    <Card {...props}>
      <RiskInfo>
        <div>{title}</div>
      </RiskInfo>
      <RiskInfoBottom riskColor={riskColor}>{riskName}</RiskInfoBottom>
    </Card>
  );
}

export function FewDetailsCardIcon({ riskName, gender , ...props }) {

  const style ={
    maxWidth: 126,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: 10,
  }
  const ICONS = {
    Feminino : {
      "Médio Risco": "Fem_Medium.svg",
      "Alto Risco": "Fem_High.svg",
      "Baixo Risco": "Fem_Low.svg",
      "Sem Risco": "Fem_Low.svg",
    },
    Masculino: {
      "Médio Risco": "Male_Medium.svg",
      "Alto Risco": "Male_High.svg",
      "Baixo Risco": "Male_Low.svg",
      "Sem Risco": "Male_Low.svg",
    },
  }

  console.log(ICONS[gender][riskName])
  return (
    <Card {...props} style={style}>
      <Image
        src={require("images/avatar/"+ICONS[gender][riskName])}
        width={170}
        alt={`Avatar ${gender} ${riskName}`}
        style={{maxWidth:'initial'}}
      />
    </Card>
  );
}

export function FewDetailsCardResume({ text, ...props }) {
  const style = {
    flex: 1,
    maxWidth: 400,
    fontSize: 13,
    fontWeight: 'normal',
    textAlign: 'center',
    margin:'0 20px 0 5px',
  }
  return (
    <Card {...props} style={{...style, ...props.style}}>
      <div>{text}</div>
    </Card>
  );
}

FewDetailsCardInfo.propTypes = {
  /** Titulo do card */
  title: PropTypes.string.isRequired,

  /** Cor do risco */
  riskColor: PropTypes.string.isRequired,

  /** Nome do risco */
  riskName: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
};
