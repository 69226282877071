import styled, { css } from 'styled-components'

export const Container = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  user-select: none;
  
  ${props => props.readOnly && css`
    opacity: .6;
    cursor: not-allowed;
  `}
`

export const ContainerCheckbox = styled.div`
  display: flex;
`

export const Check = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  line-height: 0;
  width: 25px;
  height: 25px;
  border: 1px solid ${props => props.theme.colors.formBorder};
  border-radius: 3px;
  background-color: #fff;
  margin-right: 10px;
`

export const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 0;
  height: 0;
  cursor: pointer;

  ${props => props.defaultChecked && css`
    & + ${Check} {
      border-color: ${props => props.theme.colors.success};
      background-color: ${props => props.theme.colors.success};
    }
  `}
`
