import React from 'react'
import PropTypes from 'prop-types'
import Option from 'components/Option'
import VerticalSpacer from 'components/VerticalSpacer'
import Input from 'components/Input'
import LargeOption from './LargeOption'
import { Question, LargeOptionsContainer, Description } from './styles'

const LAYOUT_OPTIONS = [
  'vertical',
  'horizontal',
  'auto'
]

export default function QuestionField ({ question, options, description, value, onChange, layout, readOnly, ...props }) {
  const layoutMode = (layout === 'vertical' || (layout === 'auto' && options.length > 3))
    ? 'vertical'
    : 'horizontal'
    
  return (
    <div>
      <Question>{question}</Question>
      {options.length > 0 ? (
        layoutMode === 'vertical' ? (
          <VerticalSpacer space={10}>
            {options.map(option => (
              <Option
                key={option.value}
                type='radio'
                children={option.label}
                value={option.value}
                checked={value === option.value}
                onChange={() => onChange(option.value)}
                readOnly={readOnly}
              />
            ))}
          </VerticalSpacer>
        ) : (
          <LargeOptionsContainer>
            {options.map(option => (
              <LargeOption
                key={option.value}
                label={option.label}
                value={option.value}
                checked={value === option.value}
                onChange={onChange}
                color={props.color ? props.color : 'primary'}
                readOnly={readOnly}
              />
            ))}
          </LargeOptionsContainer>
        )
      ) : (
        <Input
          value={value}
          onChange={onChange}
          disabled={readOnly}
        />
      )}
      {description && (
        <Description>{description}</Description>
      )}
    </div>
  )
}

QuestionField.propTypes = {
  /** Pergunta */
  question: PropTypes.string.isRequired,

  /** Lista de opções */
  options: PropTypes.array,

  /** Descrição opcional */
  description: PropTypes.string,

  /** Modo do layout das respostas */
  layout: PropTypes.oneOf(LAYOUT_OPTIONS),

  /** Valor atual */
  value: PropTypes.any,

  /** Handler quando o valor for alterado */
  onChange: PropTypes.func.isRequired,

  /** Modo somente leitura */
  readOnly: PropTypes.bool
}

QuestionField.defaultProps = {
  layout: 'auto',
  options: [],
  readOnly: false
}
