import Icon from '@fortawesome/react-fontawesome'
import PermissionMatch from 'admin/containers/PermissionMatch'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ButtonContainer, CollapseArrow, IconWrapper, TitleWrapper } from './styles'

export default function SidebarButton({ title, icon, collapse = false, permission, narrow, tooltip, children, ...props }) {
  const [ _collapse, setCollapse] = useState(collapse);
  const [open, setOpen] = useState(false);

  function handleHover() {
    setCollapse(true);
  }

  function handleMouseOut() {
    setCollapse(false);
  }

  function mobileEvents(e) {
    e.preventDefault()
    if (!open) {
      handleHover() // abre
      setOpen(true)
    } else {
      handleMouseOut() // fecha
      setOpen(false)
    }        
  }

  return (
    <PermissionMatch permission={permission}>
      {() => (
        <ButtonContainer
          narrow={narrow}
          component={NavLink}
          data-tip={title}
          data-for='dashboard-tooltip'
          onMouseOver={handleHover}
          onMouseLeave={handleMouseOut}
          onClick = {mobileEvents}          
          {...props}
        >
          {icon && <IconWrapper><Icon icon={icon} /></IconWrapper>}
          <TitleWrapper narrow={narrow}>{title}</TitleWrapper>
          {children && _collapse === true && (
            <CollapseArrow>
              {/* {props && props.className && props.className === 'active' ? '▼' : '▶'} */}
              {children}
            </CollapseArrow>
          )}
        </ButtonContainer>
      )}
    </PermissionMatch>
  )
}

SidebarButton.propTypes = {
  /** Título do botão */
  title: PropTypes.string.isRequired,

  /** Ícone */
  icon: PropTypes.object.isRequired,

  /** Exibe tamanho pequeno */
  small: PropTypes.bool,

  /** Oculta o texto do Título */
  narrow: PropTypes.bool
}
