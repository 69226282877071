import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { lighten } from 'polished'

export const Container = styled.div`  
  font-family: ${props => props.theme.fonts.tertiary};
  font-weight: 400;
  padding: 15px 20px;
  color: ${props => props.theme.colors.whiteLight};
  border-radius: 10px;
  text-align: left;
  background-color: ${props => props.color};
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: 180px;
  background-position: 18px calc(100% - 40px);
  min-height: 300px;
  max-height: 450px;
  grid-area: a;
  
  &:hover {
    background-color: ${props => lighten(0.05, props.color)};
    cursor: pointer;
  }

  > a {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

export const DiagnosticTitle = styled.p`
  font-size: 36px;
  margin-top: 10px;
  max-width: 60%;
  margin-bottom: 10px;
  font-weight: 400;
  font-family: ${props => props.theme.fonts.tertiary}
`

export const DiagnosticTitleLink = styled.div`
  margin-top: 10px;  
  font-size: 14px;
`

export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: inherit
`

export const LinkDisabledStyled = styled(LinkStyled)`

`
