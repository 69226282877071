import React from 'react'
import styled, { css } from 'styled-components'
import Base from 'components/Base'

export const inputCss = css`
  display: block;
  font-family: ${props => props.theme.fonts.tertiary};
  font-size: 16px;
  width: 100%;
  height: auto;
  padding: 10px;
  border: 1px solid ${props => props.theme.colors.formBorder};
  border-radius: 3px;
  box-sizing: border-box;

  ${props => props.error && css`
    border-color: ${props.theme.colors.danger};
  `}

  &:focus {
    border-color: ${props => props.theme.colors.primary};
  }

  &[disabled] {
    background-color: ${props => props.theme.colors.background};
    cursor: not-allowed;
  }
`

export const Container = styled.div`
  position: relative;
`

export const Helper = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
  width: 30px;
  height: 36px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;

  > :first-child {
    line-height: 0;
    opacity: 0.2
  }
`

export const InputStyled = styled(({ error: a, ...rest }) => <Base {...rest} />)`
  ${inputCss}
  height: ${props => props.component === 'input' ? '40px' : 'auto'};
  padding: ${props => props.component === 'input' ? '0 10px' : '10px'};
`
