import React, { Fragment } from 'react';
import { Bar } from 'react-chartjs-2';
import { injectIntl } from 'react-intl';
import { Container, FlexBox } from './styles.js';

const CardMissingExams = ({ intl, dataset }) => {
  const handleFormatDataset = (dataset = []) => {
    return dataset.length
      ? dataset.map(item => ({
          label: intl.formatMessage({ id: item.name }),
          data: item.data,
          backgroundColor: item.color || '#9971c9A6',
          borderColor: item.border || '#9971c9',
          borderWidth: 1,
        }))
      : [];
  };
  return (
    <Fragment>
      <FlexBox>
        <Container>
          <Bar
            data={{
              labels: [
                //intl.formatMessage({ id: "admin.common.never" }),
                intl.formatMessage({ id: 'admin.common.late' }),
                intl.formatMessage({ id: 'admin.common.in.day' }),
              ],
              datasets: handleFormatDataset(dataset),
            }}
            height={300}
            width={'100%'}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
              plugins: {
                title: {
                  display: true,
                  text: intl.formatMessage({
                    id: 'admin.common.exams.missing',
                  }),
                  color: '#9971c9',
                  align: 'start',
                  width: '5px',
                  font: {
                    size: 15,
                    weight: 'bold',
                  },
                },
                subtitle: {
                  display: true,
                  text: [
                    intl.formatMessage({
                      id: 'admin.common.qtd.exams.missing.1',
                    }),
                    intl.formatMessage({
                      id: 'admin.common.qtd.exams.missing.2',
                    }),
                  ],

                  color: '#34495E',
                  align: 'start',
                  font: {
                    size: 13,
                    weight: 400,
                  },
                  padding: {
                    bottom: 28,
                    top: -4,
                  },
                },
                legend: {
                  display: false,
                  position: 'bottom',
                },
              },
            }}
          />
        </Container>
      </FlexBox>
    </Fragment>
  );
};

export default injectIntl(CardMissingExams);
