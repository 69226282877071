import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Section from 'admin/components/Section'
import Button from 'components/Button'
import ResourceDataTable from 'admin/containers/ResourceDataTable'
import { FormattedMessage } from 'react-intl'

export default function ResourceList ({ resource, title, routeBase, columns, dataTableProps, params, extraActionButton, ...props }) {
  return (
    <Section
      title={title}
      side={props.canAdd ? (
        <Button
          component={Link}
          to={routeBase.new}
          color='success'
          onClick={window.location.reload}
        >
          <FormattedMessage id='admin.common.registerNew' />
        </Button>
      ) : null}
      {...props.sectionHeaderProps}
    >
      <ResourceDataTable
        resource={resource}
        routeBase={routeBase}
        columns={columns}
        dataTableProps={dataTableProps}
        canEdit={props.canEdit}
        canRemove={props.canRemove}
        canShowDetails={props.canShowDetails}
        showActionColumns={props.showActionColumns}
        actionColumnWidth={props.actionColumnWidth}
        autoRefresh={props.autoRefresh}
        autoRefreshTime={props.autoRefreshTime}
        minRows={props.minRows}
        params={params}
        autoFetch={false}
        extraActionButton={extraActionButton}
      />
    </Section>
  )
}

ResourceList.propTypes = {
  /** Nome do recurso */
  resource: PropTypes.string.isRequired,

  /** Título */
  title: PropTypes.string.isRequired,

  /** Colunas do data table */
  columns: PropTypes.array,

  /** Props do DataTable */
  dataTableProps: PropTypes.object,

  /** Rota de base */
  routeBase: PropTypes.object.isRequired,

  /** Permite o cadastro de novos registros */
  canAdd: PropTypes.bool,

  /** Permite a edição de registros */
  canEdit: PropTypes.bool,

  /** Permite a exclusão de registros */
  canRemove: PropTypes.bool,

  /** Props para o section header */
  sectionHeaderProps: PropTypes.object,

  /** Exibe a coluna de ações */
  showActionColumns: PropTypes.bool,

  /** Automaticamente atualiza os dados da tabela */
  autoRefresh: PropTypes.bool,

  /** Intervalo de tempo para atualizar os dados da tabela */
  autoRefreshTime: PropTypes.number,

  /** Adicionar ações adicionais */
  extraActionButton: PropTypes.func
}

ResourceList.defaultProps = {
  canAdd: true,
  canEdit: true,
  canRemove: true,
  extraActionButton: () => null,
  showActionColumns: true,
  autoRefresh: false,
  autoRefreshTime: 10000
}
