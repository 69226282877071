import PageContent from 'components/PageContent'
import Spinner from 'components/Spinner'
import Text from 'components/Text'
import { isEmpty } from 'ramda'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import connect from 'react-redux/es/connect/connect'
import { Redirect } from 'react-router-dom'
import routes from 'routes'
import { setLang } from 'store/ducks/localization'
import withAuth from 'store/utils/withAuth'
import withResource from 'store/utils/withResource'
import PatientWizard from 'users/questionnaire/components/PatientWizard'
import QuestionnaireScreen from 'users/questionnaire/components/QuestionnaireScreen'
import getRoute from 'utils/getRoute'

class CompanyPatientScreen extends Component {
  state = {
    done: false,
    scriptHash: '',
    scriptSession: '',
    patientToken: '',
    roteiro: {}
  }

  componentDidMount () {
    this.props.resourceProps.fetchOne(
      parseInt(this.props.match.params.companyId, 10)
    ).then(data => {
      const roteiro = data.action.payload.data.roteiro

      this.setState({ roteiro })
      this.props.setLang(roteiro.idioma.slug)
    })
  }

  componentDidUpdate ({ auth, resourceProps: resource }) {
    if (!this.isAuthenticated(auth) && this.isAuthenticated(this.props.auth)) {
      const { hash } = this.props.match.params
      return this.props.resourceProps.create(hash)
    }

    if (resource.isSubmitting && !this.props.resourceProps.isSubmitting && isEmpty(this.props.resourceProps.error)) {
      const { data } = this.props.resourceProps.createdRecord
      return this.setState({
        done: true,
        scriptHash: data.hash,
        patientToken: data.token,
        scriptSession: data.sessao
      })
    }
  }

  isAuthenticated (auth) {
    return auth.isAuthenticated && auth.type === 'usersAdmWeb'
  }

  render () {
    if (this.state.done) {
      const to = getRoute(routes.users.diagnostic.categories, {
        script: this.state.scriptHash,
        patient: this.state.patientToken,
        session: this.state.scriptSession
      })
      return <Redirect to={to} />
    }

    const { resourceProps: resource, intl } = this.props

    if ((this.isAuthenticated(this.props.auth) && resource.isSubmitting) || resource.isFetching) {
      return <PageContent><Spinner /></PageContent>
    }

    if (isEmpty(this.state.roteiro)) {
      return (
        <PageContent>
          <Text align='center'>
            {intl.formatMessage({ id: 'admin.common.noResultsFound' })}
          </Text>
        </PageContent>
      )
    }

    return (
      <QuestionnaireScreen
        title={intl.formatMessage({ id: 'admin.common.personalData' })}
        personal
        guest
      >
        {this.renderContent()}
      </QuestionnaireScreen>
    )
  }

  renderContent () {
    const {
      resourceProps: resource,
    } = this.props

    const { roteiro } = this.state
    const { hash } = this.props.match.params
    const companyId = parseInt(this.props.match.params.companyId, 10)
    const scriptFormFields = isEmpty(roteiro)
      ? {}
      : roteiro

    return (
      <PatientWizard
        initialValues={{}}
        isSubmitting={resource.isSubmitting}
        onSubmit={data => (
          resource.create(hash, {
            ...data,
            empresa: {
              id: companyId
            }
          })
        )}
        error={resource.error}
        scriptFormFields={scriptFormFields}
        companyId={companyId}
      />
    )
  }
}

const WizardWithIntl = injectIntl(
  withResource(
    withAuth(CompanyPatientScreen),
    {
      resource: 'AnamneseCompany'
    }
  )
)

export default connect(
  ({ localization }) => ({ lang: localization.lang }),
  { setLang }
)(WizardWithIntl)
