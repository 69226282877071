import React from 'react'
import { injectIntl } from 'react-intl'
import AdminWrapper from 'admin/components/AdminWrapper'
import Screen from 'components/Screen'
import PasswordRecovery from 'users/containers/PasswordRecovery'

function PasswordRecoveryScreen ({ intl, location }) {
  return (
    <AdminWrapper>
      <Screen title={intl.formatMessage({ id: 'admin.forms.passwordRecovery.title' })}>
        <PasswordRecovery location={location} />
      </Screen>
    </AdminWrapper>
  )
}

export default injectIntl(PasswordRecoveryScreen)
