import CustomFieldSelect from 'admin/components/CustomFieldSelect';
import Section from 'admin/components/Section';
import FormAddress from 'admin/forms/FormAddress';
import ButtonSubmit from 'components/ButtonSubmit';
import CustomField from 'components/CustomField';
import FormGrid from 'components/FormGrid';
import Resource from 'containers/Resource';
import Image from 'components/Image';
import VerticalSpacer from 'components/VerticalSpacer';
import { PATIENTS_ETNIAS } from 'config/constants';
import { isObject } from 'core-js/core/object';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { injectIntl } from 'react-intl';
import * as forms from 'utils/forms';
import { countries } from '../../../mocks/countries';
import { ActiveDashboard, Container } from '../FormValidCupom/styles';
import withAuth from '../../../store/utils/withAuth';

function FormUserDetails({
  permissions = true,
  isSubmitting,
  readOnly,
  auth,
  intl,
  ...props
}) {
  const desactive = props.action && props.action === 'update' ? true : false;
  const company_code = props.initialValues.ticket
    ? { company_code: props.initialValues.ticket.ticket }
    : { company_id: auth.user.empresa.id };
  const [state, setState] = useState({});
  const [healthInsurance, setHealthInsurance] = useState({});

  const handleDDI = code => {
    let obj = countries[intl.locale][code];
    return obj.option;
  };

  if (!state.initialValues) {
    props.initialValues._cidade = !isObject(props.initialValues.cidade)
      ? props.initialValues.cidade
      : '';
    props.initialValues._estado = !isObject(props.initialValues.estado)
      ? props.initialValues.estado
      : '';
    props.initialValues._rua = !isObject(props.initialValues.rua)
      ? props.initialValues.rua
      : '';
    props.initialValues.ddi = props.initialValues.uf
      ? handleDDI(props.initialValues.uf)
      : '';

    // if(desactive && !props.initialValues.nationality)
    //   props.initialValues.nationality = (intl.locale === 'pt' ? 'BR' : 'US')
    // if(desactive && !props.initialValues.ddi)
    //   props.initialValues.ddi = handleDDI(intl.locale === 'pt' ? 'BR' : 'US')

    setState(props);
  }

  const formSubmit = (e, props, callback) => {
    e.preventDefault();
    let uf = props.values.ddi;
    if (uf)
      uf = uf.replace(uf.replace(/(\[\w{2}\])/g, ''), '').replace(/\[|\]/g, '');

    props.values.uf = uf ? countries['en'][uf] : '';
    props.values.createCompanyUser = true;
    if (!props.values.cidade && !props.values.estado && !props.values.pais) {
      if (props.values._cidade) props.values.cidade = props.values._cidade;
      if (props.values._estado) props.values.estado = props.values._estado;
      if (props.values._rua) props.values.rua = props.values._rua;
    }
    callback(props);
  };

  useEffect(() => {
    if (props.initialValues.plano_saude)
      setHealthInsurance(props.initialValues.plano_saude[0]);
  }, [intl]);

  return (
    <Section
      style={{ overflow: 'visible' }}
      title={intl.formatMessage({ id: 'admin.common.personalData' })}
      wrapped={false}
    >
      <Form
        {...state}
        initialValues={{
          contato_telefonico: 'Não',
          contato_previneo_whatsapp: 'Não',
          ...state.initialValues,
        }}
      >
        {({ handleSubmit, pristine, ...formProps }) => {
          return (
            <form onSubmit={e => formSubmit(e, formProps, handleSubmit)}>
              <VerticalSpacer>
                <FormGrid>
                  <Field
                    name="nome"
                    label={intl.formatMessage({ id: 'admin.common.name' })}
                    id="form_patient_name"
                    component={CustomField}
                    readOnly={readOnly ? readOnly : desactive}
                    disabled={readOnly ? readOnly : desactive}
                    maxLength={50}
                    showMandatory
                    {...forms.required}
                  />
                  <Field
                    name="sobrenome"
                    label={intl.formatMessage({ id: 'admin.common.surname' })}
                    id="form_patient_surname"
                    component={CustomField}
                    readOnly={readOnly ? readOnly : desactive}
                    disabled={readOnly ? readOnly : desactive}
                    maxLength={50}
                    showMandatory
                    {...forms.required}
                  />
                  <Field
                    name="genero_biologico"
                    label={intl.formatMessage({ id: 'admin.common.gender' })}
                    id="form_patient_gender"
                    component={CustomFieldSelect}
                    placeholder={intl.formatMessage({
                      id: 'admin.comon.select',
                    })}
                    readOnly={readOnly ? readOnly : desactive}
                    disabled={readOnly ? readOnly : desactive}
                    customSelectProps={{
                      options: [
                        {
                          label: intl.formatMessage({
                            id: 'admin.common.genderMale',
                          }),
                          value: 'Masculino',
                        },
                        {
                          label: intl.formatMessage({
                            id: 'admin.common.genderFemale',
                          }),
                          value: 'Feminino',
                        },
                      ],
                    }}
                    showMandatory
                    {...forms.required}
                  />
                </FormGrid>
                <FormGrid>
                  <Field
                    name="dt_nascimento"
                    label={intl.formatMessage({
                      id: 'admin.common.dateOfBirth',
                    })}
                    id="form_patient_birthday"
                    component={CustomField}
                    readOnly={readOnly ? readOnly : desactive}
                    disabled={readOnly ? readOnly : desactive}
                    placeholder={intl.formatMessage({
                      id: 'admin.common.birthDatePlaceHolder',
                    })}
                    showMandatory
                    {...forms.dateRequired}
                  />

                  <Field
                    name="nationality"
                    label={intl.formatMessage({
                      id: 'admin.common.nationality',
                    })}
                    id="form_patient_nationality"
                    component={CustomFieldSelect}
                    placeholder={intl.formatMessage({
                      id: 'admin.comon.select',
                    })}
                    customSelectProps={{
                      options: Object.values(countries[intl.locale]).map(i => ({
                        value: i.code,
                        label: i.country,
                      })),
                    }}
                    // readOnly={
                    //   formProps.initialValues.nationality ? desactive : false
                    // }
                    // disabled={
                    //   formProps.initialValues.nationality ? desactive : false
                    // }
                  />
                  {formProps.values.nationality === 'BR' ? (
                    <Field
                      name="cpf"
                      label={intl.formatMessage({ id: 'admin.common.cpf' })}
                      id="form_patient_cpf"
                      component={CustomField}
                      // readOnly={
                      //   readOnly
                      //     ? readOnly
                      //     : formProps.initialValues.cpf && desactive
                      // }
                      // disabled={
                      //   readOnly
                      //     ? readOnly
                      //     : formProps.initialValues.cpf && desactive
                      // }
                      // showMandatory
                      // {...forms.cpfRequired}
                    />
                  ) : (
                    <Field
                      name="cpf"
                      label={intl.formatMessage({
                        id: 'admin.common.document',
                      })}
                      id="form_patient_cpf"
                      component={CustomField}
                    />
                  )}
                </FormGrid>
                <FormGrid>
                  <Field
                    name="email"
                    label={intl.formatMessage({ id: 'admin.common.email' })}
                    id="form_patient_email"
                    readOnly={readOnly ? readOnly : desactive}
                    disabled={readOnly ? readOnly : desactive}
                    component={CustomField}
                    showMandatory
                    {...forms.emailRequired}
                  />
                  <Field
                    name="contato_previneo_whatsapp"
                    label={intl.formatMessage({
                      id: 'q.additional.information.whatsapp',
                    })}
                    id="form_patient_accepts_contact"
                    // readOnly={readOnly}
                    // disabled={readOnly}
                    component={CustomFieldSelect}
                    placeholder={intl.formatMessage({
                      id: 'admin.comon.select',
                    })}
                    customSelectProps={{
                      options: [
                        {
                          label: intl.formatMessage({ id: 'admin.common.yes' }),
                          value: 'Sim',
                        },
                        {
                          label: intl.formatMessage({ id: 'admin.common.no' }),
                          value: 'Não',
                        },
                      ],
                    }}
                    showMandatory
                    {...forms.required}
                  />
                </FormGrid>

                <FormGrid>
                  <Field
                    name="etnia"
                    label={intl.formatMessage({ id: 'q.ethnicity.question' })}
                    id="form_patient_etnia"
                    component={CustomFieldSelect}
                    placeholder={intl.formatMessage({
                      id: 'admin.comon.select',
                    })}
                    readOnly={readOnly ? readOnly : desactive}
                    disabled={readOnly ? readOnly : desactive}
                    customSelectProps={{
                      options: [
                        {
                          label: intl.formatMessage({
                            id: `admin.common.ethnicity.${PATIENTS_ETNIAS.white.label}`,
                          }),
                          value: PATIENTS_ETNIAS.white.value,
                        },
                        {
                          label: intl.formatMessage({
                            id: `admin.common.ethnicity.${PATIENTS_ETNIAS.black.label}`,
                          }),
                          value: PATIENTS_ETNIAS.black.value,
                        },
                        {
                          label: intl.formatMessage({
                            id: `admin.common.ethnicity.${PATIENTS_ETNIAS.brown.label}`,
                          }),
                          value: PATIENTS_ETNIAS.brown.value,
                        },
                        {
                          label: intl.formatMessage({
                            id: `admin.common.ethnicity.${PATIENTS_ETNIAS.yellow.label}`,
                          }),
                          value: PATIENTS_ETNIAS.yellow.value,
                        },
                        {
                          label: intl.formatMessage({
                            id: `admin.common.ethnicity.${PATIENTS_ETNIAS.indigenous.label}`,
                          }),
                          value: PATIENTS_ETNIAS.indigenous.value,
                        },
                      ],
                    }}
                    showMandatory
                    {...forms.required}
                  />
                  <FormGrid>
                    <Field
                      name="ddi"
                      label={'DDI'}
                      id="form_patient_phone_ddi"
                      component={CustomFieldSelect}
                      readOnly={readOnly}
                      disabled={readOnly}
                      // disabled={readOnly ? readOnly : desactive}
                      customSelectProps={{
                        options: Object.values(countries[intl.locale]).map(
                          i => ({
                            value: i.option,
                            label: (
                              <div
                                style={{ display: 'flex', overflow: 'hidden' }}
                              >
                                <Image
                                  src={require(`images/flags/${i.code}.svg`)}
                                  alt={i.label}
                                />
                                <span style={{ paddingLeft: 8 }}>
                                  {i.value}
                                </span>
                              </div>
                            ),
                          }),
                        ),
                      }}
                      showMandatory
                      {...forms.required}
                    />
                    <Field
                      name="telefone"
                      label={intl.formatMessage({ id: 'admin.common.phone' })}
                      id="form_patient_phone"
                      component={CustomField}
                      readOnly={readOnly}
                      disabled={readOnly}
                      showMandatory
                      {...forms.phone}
                      format={value => {
                        let uf = Object.values(countries[intl.locale]).filter(
                          item => item.option === formProps.values.ddi,
                        )[0];
                        return forms.phone.format(value, uf);
                      }}
                    />
                  </FormGrid>
                </FormGrid>

                <FormAddress
                  {...formProps}
                  loadByCep
                  readOnly={readOnly}
                  disabled={readOnly}
                />
                <FormGrid>
                  <Resource
                    resource="HealthInsurancesCompany"
                    params={company_code}
                    autoFetch
                  >
                    {props => (
                      <Container>
                        <Field
                          name="plano_saude"
                          label={intl.formatMessage({
                            id: 'admin.healthInsurance.title',
                          })}
                          id="form_patient_plano_de_saude"
                          component={CustomFieldSelect}
                          placeholder={intl.formatMessage({
                            id: 'admin.comon.select',
                          })}
                          customSelectProps={{
                            value: healthInsurance,
                            options: props.records,
                            labelKey: 'nome',
                            valueKey: 'id',
                            multi: false,
                            simpleValue: false,
                            isLoading: props.isFetching,
                            onChange: value => {
                              formProps.values.plano_saude = value ? value : [];
                              setHealthInsurance(value);
                            },
                          }}
                        />
                      </Container>
                    )}
                  </Resource>
                </FormGrid>

                {permissions && (
                  <React.Fragment>
                    <FormGrid>
                      <ActiveDashboard>
                        <Field
                          name="access_dashboard"
                          id="form_active_dashboard"
                          component="input"
                          type="checkbox"
                          readOnly={readOnly}
                          disabled={readOnly}
                        />
                        <label for="form_active_dashboard" class="switch">
                          <span class="slider"></span>
                        </label>
                        <label className="active-dashboard">
                          {intl.formatMessage({
                            id: 'admin.common.active.dash',
                          })}
                        </label>
                      </ActiveDashboard>
                    </FormGrid>
                  </React.Fragment>
                )}

                {readOnly !== true && (
                  <ButtonSubmit
                    isSubmitting={isSubmitting}
                    disabled={pristine}
                    label={intl.formatMessage({ id: 'admin.common.save' })}
                  />
                )}
              </VerticalSpacer>
            </form>
          );
        }}
      </Form>
    </Section>
  );
}

FormUserDetails.propTypes = {
  /** Callback para o form submit */
  onSubmit: PropTypes.func.isRequired,

  /** Form sendo processado */
  isSubmitting: PropTypes.bool,

  /** Exibe o form em modo leitura */
  readOnly: PropTypes.bool,
};

export default withAuth(injectIntl(FormUserDetails));
