import Cardio from 'images/cardio.png';
import Colon from 'images/colon.png';
import Mama from 'images/mama.png';
import Mental from 'images/mental.png';
import Prostate from 'images/prostata.png';
import Lung from 'images/pulmao.png';
import Uterine from 'images/utero.png';
import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import Card from '../Card/Card';
import ContainerDashbord from '../ContainerDashbord/ContainerDashbord';
import {
  BodyCard,
  Context,
  Image,
  SectionTitleCard,
  TitleCard
} from './styles.js';

const CardHighRisk = ({ intl, data }) => {
  const COLORS = {
    Unissex: '#96b4becc',
    Masculino: '#17a2b8CC',
    Feminino: '#d68485CC',
  };

  const IMAGES = {
    'Câncer de Pulmão': Lung,
    'Câncer de Cólon': Colon,
    'Câncer de Próstata': Prostate,
    Cardio: Cardio,
    'Câncer de Mama': Mama,
    Mental: Mental,
    'Câncer de Útero': Uterine,
  };

  return (
    <Fragment>
      <Context>
        <SectionTitleCard>
          <h3 className="card-subtitle">
            {intl.formatMessage({
              id: data.risk_name,
            })}
          </h3>
          <span className="card-span">
            &nbsp;
            {intl.formatMessage(
              {
                id: 'admin.high.qtd',
              },
              { risk: intl.formatMessage({ id: data.risk_name }) },
            )}
          </span>
        </SectionTitleCard>
        {Object.keys(data.cards).length ? (
          <ContainerDashbord>
            {data.cards.map((item, idx) => (
              <Card
                key={idx}
                container={1}
                box="none"
                background={COLORS[item.type]}
                color={'#fff'}
                className="cards"
                style={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  textAlign: 'center',
                  margin: '0 7px',
                  padding: 10,
                }}
              >
                <TitleCard
                  style={{
                    margin: '10px 0 0',
                    minHeight: 47,
                  }}
                >
                  {intl.formatMessage({
                    id: item.name,
                  })}
                </TitleCard>
                <Image
                  src={IMAGES[item.img]}
                  alt="icone"
                  style={{
                    width: 'fit-content',
                    margin: 0,
                    filter: 'opacity(1) drop-shadow(0 0 0 #000) brightness(10)',
                    height: 80,
                  }}
                />
                <div style={{ margin: 0 }}>
                  <BodyCard>{item.value}</BodyCard>
                  <span className="span-info">{item.percent} %</span>
                </div>
              </Card>
            ))}
          </ContainerDashbord>
        ) : (
          <Fragment>
            <ContainerDashbord>
              <center>
                <img
                  src={require('../../../images/ban-solid.svg')}
                  alt="Emoji"
                  className="emoji_none_data"
                />
                <h4 className="title_none_data">
                  {intl.formatMessage({
                    id: 'admin.common.data.found',
                  })}
                </h4>
              </center>
            </ContainerDashbord>
          </Fragment>
        )}
      </Context>
    </Fragment>
  );
};

export default injectIntl(CardHighRisk);
