import React from 'react'
import qs from 'querystringify'
import PageContent from 'components/PageContent'
import Container from 'components/Container'
import VerticalSpacer from 'components/VerticalSpacer'
import FormPasswordConfirm from 'users/forms/FormPasswordConfirm'
import Notifications from 'containers/Notifications'
import Resource from 'containers/Resource'

export default function PasswordConfirm ({ location }) {
  const query = qs.parse(location.search)
  return (
    <PageContent>
      <Container size='xsmall'>
        <VerticalSpacer>
          <Notifications />
          <Resource
            resource='PasswordToken'
            notifications={{
              update: {
                success: true,
                errors: true,
                defaultSuccessMessage: 'Sua senha foi cadastrada com sucesso!'
              }
            }}
          >
            {props => (
              <FormPasswordConfirm
                onSubmit={data => props.update(query.token, data)}
                isSubmitting={props.isSubmitting}
              />
            )}
          </Resource>
        </VerticalSpacer>
      </Container>
    </PageContent>
  )
}
