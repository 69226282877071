import React from 'react';
import Select, { Async } from 'react-select';
import 'react-select/dist/react-select.css';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';

const Container = styled.div`
  font-family: ${props => props.theme.fonts.tertiary};
  text-align: left;
  white-space: normal;

  .Select.is-focused .Select-control {
    border-color: ${props => props.theme.colors.primary};
  }

  .Select-control {
    height: 40px;
    border: 2px solid
      ${props => props.theme.colors[props.error ? 'danger' : 'formBorder']};
    border-radius: 3px;
  }

  .Select-placeholder,
  .Select--single > .Select-control .Select-value {
    line-height: 40px;
  }

  .Select.is-focused:not(.is-open) > .Select-control {
    box-shadow: none;
    border-color: ${props => props.theme.colors.primary};
  }
`;

function CustomSelect({ intl, async, error, ...props }) {
  const Control = async ? Async : Select;

  return (
    <Container error={error}>
      <Control
        noResultsText={intl.formatMessage({
          id: 'admin.common.noResultsFound',
        })}
        placeholder={intl.formatMessage({ id: 'admin.common.select' })}
        searchPromptText={intl.formatMessage({
          id: 'admin.common.typeSomethingToSearch',
        })}
        loadingPlaceholder={intl.formatMessage({ id: 'admin.common.loading' })}
        {...props}
      />
    </Container>
  );
}

export default injectIntl(CustomSelect);
