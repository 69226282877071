import React, { useEffect, useState } from 'react';
import Joyride from "react-joyride";
import withAuth from "store/utils/withAuth";
import Session from 'modules/Session'
import { injectIntl } from 'react-intl';
import theme from 'theme'
import Tooltip from '../Tooltip/Tooltip';
import styled from 'styled-components';


const DescriptionTime = styled.p`
  white-space: nowrap;
  font-size: 12px;
`;

const Tour = ({intl, auth, runTour}) => {
    const [run, setRun] = useState(runTour);

    let steps =([
        {
            target: 'body',
            disableBeacon: true,
            placement: "center",
            title: intl.formatMessage({id: "tour.greetings.title"}),
            gender: auth.user.userData.genero_biologico,
            content: (
              <>
                <p>{intl.formatMessage({id: "tour.greetings"})}</p>
                <p>{intl.formatMessage({id: "tour.greetings.message"})}</p>
              </>
            ),
            placementBeacon : "center",
        },
        {
            target: '.card-medical-historic',
            placement: "top",
            disableBeacon: true,
            content: (
              <>
                <p>{intl.formatMessage({id: "tour.first.step"})}</p>
                <DescriptionTime>{intl.formatMessage({id: "tour.estimated.time"})}</DescriptionTime>
              </>),
        },
        {
            target: '.card_diagnostics',
            disableBeacon: true,
            placement: "top",
            content: (
              <>
                <p>{intl.formatMessage({id: "tour.second.step"})}</p>
                <DescriptionTime>{intl.formatMessage({id: "tour.estimated.time"})}</DescriptionTime>
              </>),
        },
        {
            target: window.matchMedia(`(max-width: ${theme.breakpoints.large})`).matches ? ".menu-button" : ".menu-sidebar",
            placement: "auto",
            disableBeacon: true,
            content: (
              <>
                <p>{intl.formatMessage({id: "tour.third.step"})}</p>
              </>),
        },
        {
            target: 'body',
            placement: "center",
            disableBeacon: true,
            title: intl.formatMessage({id: "tour.last.step.title"}),
            content: (
              <>
                <p>{intl.formatMessage({id: "tour.last.step"})}</p>
              </>),
        },
    ]);


  const callback = (data) => {
    if(data.status === 'finished' || data.status === 'skipped'){
      Session.set('finishedTour', true);
    }
  }

  useEffect(() => {
    if(Session.get('finishedTour')){
      setRun(false);
    }
  }, [])

  return (
      <Joyride 
          steps= {steps}
          run={run}
          tooltipComponent={Tooltip}
          continuous= {true}
          loading= {false}
          callback={callback}
          showProgress = {true}
          hideBackButton= {true}
          styles= {{
            options: {
              arrowColor: '#f9ebbe',
            },
          }}
          locale={{ back: '', close: '', last: '', next: '', open: '', skip: '' }}
      />
  );
}

export default withAuth(injectIntl(Tour));
