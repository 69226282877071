import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import VerticalSpacer from 'components/VerticalSpacer'
import Image from 'components/Image'
import Text from 'components/Text'
import spinner from 'images/spinner.gif'

export default function Spinner ({ text }) {
  return (
    <VerticalSpacer space={10}>
      <Image src={spinner} alt={text} width={75} center />
      {text && (
        <FormattedMessage id={text}>
          {text => (
            <Text style={{ textAlign: 'center' }}>
              {text}
            </Text>
          )}
        </FormattedMessage>
      )}
    </VerticalSpacer>
  )
}

Spinner.propTypes = {
  /** Texto exibido em baixo do spinner */
  text: PropTypes.string
}

Spinner.defaultProps = {
  text: 'admin.common.loading'
}
