import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Wrapper from 'containers/Wrapper'
import routes from 'routes'
import Start from 'users/containers/Start'
import Logout from 'users/containers/Logout'
import {LoginScreen} from 'users/screens'

export default function App () {
  return (
    <Wrapper>
      <Switch>
        <Route path={routes.users.index} component={Start} />
        <Route exact path={routes.site.logout} component={Logout} />
        <Route exact path={routes.site.loginHome} component={LoginScreen} />
        <Route exact path={routes.site.loginCompany} component={LoginScreen} />
      </Switch>
    </Wrapper>
  )
}
