import React from "react";
import PageContent from "components/PageContent";
import Container from "components/Container";
import FormLogin from "users/forms/FormLogin";
import Auth from "admin/containers/Auth";
import { FormattedMessage } from "react-intl";
import VerticalSpacer from "components/VerticalSpacer";
import Logo from "../../../components/Logo/Logo";
import Text from "components/Text";
import Languages from 'components/Languages/Languages'

export default function Login() {
  return (
    <Languages>
      <PageContent>
        <Container size="xsmall" style={{marginTop: 40}}>
          <VerticalSpacer space={45}>
            <Logo small height="22px" />
            <Text size="45px" color="textNew" fontFamily="" lineHeight={1}>
              <FormattedMessage id="users.login.welcome" />
            </Text>
            <Auth>
              {props => (
                <FormLogin
                  onSubmit={data =>
                    props.login(
                      data.email,
                      data.password,
                      "usersAdmWeb",
                      window.recaptchaValue
                    )
                  }
                  isSubmitting={props.isSubmitting}
                  login={props.login}
                  isAuthenticated = {props.isAuthenticated}
                />
              )}
            </Auth>
          </VerticalSpacer>
        </Container>
        <br/><br/>
      </PageContent>
    </Languages>
  );
}
