import FieldListener from 'admin/components/FieldListener'
import Section from 'admin/components/Section'
import Alert from 'components/Alert/Alert'
import ButtonSubmit from 'components/ButtonSubmit'
import CustomField from 'components/CustomField'
import FormGrid from 'components/FormGrid'
import VerticalSpacer from 'components/VerticalSpacer'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import * as forms from 'utils/forms'
import { equalTo } from 'utils/validators'

export default function FormUserPassword ({ isSubmitting, readOnly, intl, ...props }) {
  const [userPassword, setUserPassword] = useState(null)
  const [passwordErrors, setPasswordErrors] = useState([])

  useEffect(() => {

     if(userPassword){
        const errors = []
        /* TODO: passar essas variaveis para o arquivo de tradução */
        if(userPassword.length < 8) errors.push('Quantidade mínima de caracteres: 8')
        if(!/[a-z]/gm.test(userPassword)) errors.push('Senha deve ter letras minusculas')
        if(!/[A-Z]/gm.test(userPassword)) errors.push('Senha deve ter letras maiusculas')
        if(!/[0-9]/gm.test(userPassword)) errors.push('Senha deve ter números')
        
        setPasswordErrors(errors)
    }
  }, [userPassword])

  return (
    <Section 
      title={intl.formatMessage({ id: 'admin.common.editPassword' })}
      wrapped={false}
    >
      <Form {...props}>
        {({ handleSubmit, pristine ,...formProps}) => (
          <form onSubmit={(e)=>{handleSubmit(e); setTimeout(formProps.form.reset, 1000)}}>
            <VerticalSpacer>
              <FormGrid>
                <Field
                  name='old_password'
                  type="password"
                  label={intl.formatMessage({ id: 'admin.common.oldPassword' })}
                  id='form_user_old_password'
                  component={CustomField}
                  showMandatory
                  {...forms.passwordRequired}
                />
                <Field
                  name='password'
                  type='password'
                  label={intl.formatMessage({ id: 'admin.common.password.new' })}
                  id='form_user_password'
                  component={CustomField}
                  showMandatory
                  {...forms.passwordRequired}
                />
                <Field
                  name='password_confirmation'
                  label={intl.formatMessage({ id: 'admin.common.confirmPassword' })}
                  id='form_user_password_confirmation'
                  component={CustomField}
                  showMandatory
                  {...forms.password}
                  validate={equalTo({
                    field: 'password',
                    message: intl.formatMessage({ id: 'validations.passwordConfirm' })
                  })}
                />
              </FormGrid>

              {passwordErrors.length ? (
                <Alert
                  type='error'
                  message="Corrija os itens para cadastrar-se:"
                  items={passwordErrors}
                />
              ) : ''}

              <FieldListener
                name='password'
                onChange={(newValue, oldValue) => {
                  setUserPassword(newValue)
                }}
              />

              {!readOnly && (
                <ButtonSubmit
                  isSubmitting={isSubmitting}
                  disabled={pristine || passwordErrors.length > 0}
                  label={intl.formatMessage({ id: 'admin.common.save' })}
                />
              )}
            </VerticalSpacer>
          </form>
        )}
      </Form>
    </Section>
  )
}

FormUserPassword.propTypes = {
  /** Callback para o form submit */
  onSubmit: PropTypes.func.isRequired,

  /** Form sendo processado */
  isSubmitting: PropTypes.bool,

  /** Exibe o form em modo leitura */
  readOnly: PropTypes.bool
}
