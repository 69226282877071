import ConfirmModal from 'admin/components/ConfirmModal'
import Section from 'admin/components/Section'
import WithModal from 'admin/components/WithModal'
import Auth from 'admin/containers/Auth'
import Button from 'components/Button'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

function FormUserDetailsRemoveAccount ({ isSubmitting, readOnly, intl, ...props }) {
  return (
    <Section 
      style={{ overflow: 'visible' }} 
      title={intl.formatMessage({ id: 'admin.common.personalData' })}
      wrapped={false}
    >
      <WithModal
        modal={({ closeModal }) => (
          <Auth>
            {authProps => (
              <ConfirmModal
                title={intl.formatMessage({ id: 'users.delete.data.cantrollback.title' })}
                text={intl.formatMessage({ id: 'users.delete.data.cantrollback.description' })}
                onConfirm={() => {
                  authProps.deleteData()
                  closeModal()
                }}
                onCancel={closeModal}
              />
            )}
          </Auth>
        )}
      >
        {({ toggleModal }) => (
          <WithModal
            modal={({ closeModal }) => (
              <Auth>
                {authProps => (
                  <ConfirmModal
                    title={intl.formatMessage({ id: 'users.delete.data.title' })}
                    text={intl.formatMessage({ id: 'users.delete.data.description' })}
                    onConfirm={() => {
                      toggleModal()
                      /* authProps.deleteData() */
                    /*  closeModal() */
                    }}
                    onCancel={closeModal}
                  />
                )}
              </Auth>
            )}
          >
            {({ toggleModal }) => (
              <Button
                onClick={() => toggleModal()}
                color='categories'
                icon={require('@fortawesome/fontawesome-free-solid/faTrash')}
              >
                &nbsp;&nbsp;{intl.formatMessage({ id: 'users.delete.data.button' })}
              </Button>
            )}
          </WithModal>
        )}
      </WithModal>
    </Section>
  )
}

FormUserDetailsRemoveAccount.propTypes = {
  /** Callback para o form submit */
  onSubmit: PropTypes.func.isRequired,

  /** Form sendo processado */
  isSubmitting: PropTypes.bool,

  /** Exibe o form em modo leitura */
  readOnly: PropTypes.bool
}

export default injectIntl(FormUserDetailsRemoveAccount)
