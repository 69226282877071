/**
 * Dependencies.
 */

import CNPJ from 'cnpj';
import { isValid, parse } from 'date-fns';
import isCPF from 'iscpf';
import PasswordValidator from 'password-validator';
import { isEmpty, memoize } from 'ramda';
import { limitDate } from 'utils/dateFunctions';
import youtubeUrl from 'youtube-url';
import validator from 'validator';

/**
 * Required.
 */

export const required = value => {
  if (value === 0) return;
  if (typeof value === 'boolean') return;
  return (
    typeof input !== 'number' &&
    (!value || isEmpty(value)) && { id: 'validations.required' }
  );
};

/**
 * Integer.
 */

export const integer = value =>
  value && parseInt(value, 10) !== value && { id: 'validations.integer' };

/**
 * Integer Between.
 */

export const integerBetween = memoize(({ max, min }) => value => {
  const parsedValue = parseInt(value, 10);

  if (
    typeof parsedValue !== 'number' ||
    !(parsedValue >= min && parsedValue <= max)
  ) {
    return { id: 'validations.integerBetween', max, min };
  }
});

/**
 * E-mail.
 */

export const email = value => {
  if (typeof value !== 'undefined' && !validator.isEmail(value)) {
    return { id: 'validations.email' };
  }
};

/**
 * login.
 */

export const login = value => {
  const _cpf = /^\d{1,11}/.test(value);
  if (_cpf) {
    return cpf(value);
  }
  return email(value);
};

/**
 * Phone.
 */

export const phone = value => {
  const _validTel = /\d{7,11}/.test(value);

  if (
    value === 0 ||
    (typeof input !== 'number' && (!value || isEmpty(value)))
  ) {
    return { id: 'validations.required' };
  } else if (!_validTel || !validator.isMobilePhone(value, 'any')) {
    return { id: 'validations.phone' };
  }
};

/**
 * CPF.
 */

export const cpf = value => {
  if (
    value === 0 ||
    (typeof input !== 'number' && (!value || isEmpty(value)))
  ) {
    return null;
  } else if (!isCPF(value)) {
    return { id: 'validations.cpf' };
  }
};

/**
 * CNPJ.
 */

export const cnpj = value =>
  value && !CNPJ.validate(value) && { id: 'validations.cnpj' };

/**
 * CEP.
 */

export const cep = value =>
  value && !/\d{8}/.test(value) && { id: 'validations.cep' };

export const code = value =>
  value && !/\d{5}/.test(value) && { id: 'validations.cep' };

export const zipcode = (value, code) => {
  let size = code.trim().length;
  return value && value.length < size && { id: 'validations.cep' };
};

/**
 * Link Youtube.
 */

export const link_youtube = value => {
  if (
    value === 0 ||
    (typeof input !== 'number' && (!value || isEmpty(value)))
  ) {
    return '';
  }
  if ((value = !youtubeUrl.valid(value))) {
    return { id: 'validations.link' };
  }
  return;
};
export const link_agendamento_exame = value => {
  const _link_agendamento_exame =
    /^[a-zA-Z0-9-_]+[:./\\]+([a-zA-Z0-9 -_./:=&"'?%+@#$!])+$/.test(value);

  if (
    value === 0 ||
    (typeof input !== 'number' && (!value || isEmpty(value)))
  ) {
    return '';
  }
  if ((value = !_link_agendamento_exame)) {
    return { id: 'validations.link' };
  }
  return;
};

/**
 * Data Tela de Cadastro
 **/

export const date = value => {
  const _date = /\d{4}-\d{2}-\d{2}/.test(value);

  if (
    value === 0 ||
    (typeof input !== 'number' && (!value || isEmpty(value)))
  ) {
    return { id: 'validations.required' };
  } else if (!_date) {
    return { id: 'validations.date' };
  }
};

/**
 * Data Tela de Relatórios
 **/

export const dateFilter = value => {
  const valid_date = /\d{4}-\d{2}-\d{2}/.test(value);

  if (
    value === 0 ||
    (typeof input !== 'number' && (!value || isEmpty(value)))
  ) {
    return { id: 'validations.required' };
  } else if (value && (!valid_date || !limitDate(value))) {
    return { id: 'validations.date' };
  } else {
    return;
  }
};

/**
/**
 * Full Name
 **/

export const fullName = value => {
  const _fullName = /^(.{3,}) (.{3,})$/.test(value);
  const _withCharacter =
    /^([^\-\_\d\@\.\?\!\^\~\=\+\"\{\}\[\]\;\:\.\,\<\>\(\)\*\$\#\|\\\/\%\£\¢]){0,}$/.test(
      value,
    );

  if (value === 0 || (typeof input !== 'text' && (!value || isEmpty(value)))) {
    return { id: 'validations.required' };
  } else if (!_withCharacter) {
    return { id: 'validations.enter.not.special.character' };
  } else if (value && !_fullName) {
    return { id: 'validations.enter.full.name' };
  } else {
    return;
  }
};

/**
 * Equal to.
 */

export const equalTo = memoize(
  ({ field, message }) =>
    (value, allValues) =>
      value !== allValues[field] && message,
);

/**
 * Password.
 */

const passwordSchema = new PasswordValidator();
passwordSchema.is().min(8).has().uppercase().has().lowercase().has().digits();

export const password = value =>
  value && !passwordSchema.validate(value) && { id: 'validations.password' };

/**
 * File Type.
 */

export const fileType = memoize(
  types => file =>
    file && !types.includes(file.type) && { id: 'validations.fileType' },
);

/**
 * Data nascimento.
 */
export const dtNascimento = value => {
  const _dateValidate = /\d{4}-\d{2}-\d{2}/.test(value);
  const arrDate = value.split('/');
  const dateString = `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`;
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  if (
    value === 0 ||
    (typeof input !== 'number' && (!value || isEmpty(value)))
  ) {
    return { id: 'validations.required' };
  } else if (!_dateValidate) {
    return { id: 'validations.date' };
  } else if (age < 18) {
    return { id: 'validations.required.age' };
  }
};
