import styled from 'styled-components';

export const ContainerCaptcha = styled.div`    
    label{
        padding: 20px 85px 20px 15px;
        background: #ffffff;
        border-radius: 4px;
        box-shadow: 0 0 4px -1px rgb(0 0 0 / 30%);
        position: relative;

        p{
            font-size:14px;
        }

        &:before{
            content: 'reCAPTCHA';
            background: url('https://www.gstatic.com/recaptcha/api2/logo_48.png');
            background-size: 32px;
            background-repeat: no-repeat;
            background-position: center top;
            position: absolute;
            width: 56px;
            display: flex;
            align-items: end;
            height: 46px;
            right: 10px;
            cursor: default;
            font-family: Roboto,helvetica,arial,sans-serif;
            font-size: 10px;
            color: #555;
        }
    }
`;
