import createApi from 'store/utils/createApi';
import Request from 'modules/Request';

export default createApi('/api/reports/reports-mental-risk', {
  fetchAll(params) {
    return Request.api({
      method: 'POST',
      url: '/api/reports/reports-mental-risk',
      body: params,
    }).then(res => res.body);
  },
});
