import Icon from '@fortawesome/react-fontawesome';
import CustomFieldSelect from 'admin/components/CustomFieldSelect';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Base from 'components/Base';
import Button from 'components/Button';
import FormGrid from 'components/FormGrid';
import Resource from 'containers/Resource';
import moment from 'moment';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Field, Form } from 'react-final-form';
import { injectIntl } from 'react-intl';
import { MultiSelect } from 'react-multi-select-component';
import styled from 'styled-components';
import Spinner from '../../../images/spinner.gif';
import { ContainerFilter } from './styles.js';

const FiltersDashbord = ({
  isSubmitting,
  intl,
  readOnly,
  isFetching,
  ...props
}) => {
  const [filterDate, setFilterDate] = useState({
    start: '',
    end: '',
    start_date: '',
    end_date: '',
    str: '',
  });

  const [companySelected, setCompanySelected] = useState([]);
  const [interviewSelected, setInterviewSelected] = useState([]);

  const defaultValues = useMemo(
    () => ({
      company_reports_deleted_users: 1,
      company_reports_risk: 0,
    }),
    [],
  );

  const handleSetDate = (e, p) => {
    let start, end;
    if (!p) {
      start = moment().subtract(7, 'days');
      end = moment();
    } else {
      start = moment(p.startDate);
      end = moment(p.endDate);
    }
    setFilterDate({
      start: start.format('DD/MM/YYYY'),
      end: end.format('DD/MM/YYYY'),
      start_date: start.format('YYYY-MM-DD'),
      end_date: end.format('YYYY-MM-DD'),
      str: `${start.format('DD/MM/YY')} - ${end.format('DD/MM/YY')}`,
    });
  };

  const handleFormSubmit = () => {
    document.getElementById('filter_apply').click();
  };
  const handleSubmit = (e, { fetchAll, values, ...props }) => {
    e.preventDefault();

    let data = {
      filterdate: filterDate,
      company: companySelected,
      interviews: interviewSelected,
      risk: values.company_reports_risk,
      with_deleted: values.company_reports_deleted_users,
    };
    fetchAll(data);
  };

  useEffect(() => {
    handleSetDate();
    setTimeout(handleFormSubmit, 500);
  }, [5]);

  return (
    <Fragment>
      <ContainerFilter className={isFetching ? 'isFetching' : ''}>
        <div className="section-filter_dashboard">
          {filterDate.start && (
            <DateRangePicker
              initialSettings={{
                locale: intl.formatMessage({
                  id: 'plugin.daterangepicker.settings',
                }),
                startDate: filterDate.start,
                endDate: filterDate.end,
              }}
              onApply={handleSetDate}
            >
              <div className="section_button_datapicker_dashboard">
                <Button
                  className="button_datapicker_dashboard"
                  color="#39acb5"
                  textColor="white"
                  style={{
                    height: '39px',
                  }}
                  blockAtBreakpoint="small"
                >
                  <Icon
                    style={{ paddingRight: '10px' }}
                    icon={require('@fortawesome/fontawesome-free-solid/faCalendarAlt')}
                  />
                  {filterDate.str}
                </Button>
              </div>
            </DateRangePicker>
          )}
          <Form {...props} initialValues={defaultValues}>
            {props => (
              <form onSubmit={e => handleSubmit(e, props)}>
                <FormGrid>
                  <div className="column_filter company">
                    <Resource resource="ReportsApiCompany" autoFetch>
                      {props => (
                        <Field
                          name="company_reports_company"
                          id="form_company_reports_company"
                          component={CustomComponentCompany}
                          className="select_filter_company_dashboard"
                          onChange={setCompanySelected}
                          labelledBy={intl.formatMessage({
                            id: 'admin.company.title.plural',
                          })}
                          customSelectProps={{
                            value: companySelected,
                            options: props.records,
                            labelKey: 'nome',
                            valueKey: 'id',
                            isLoading: props.isFetching,
                            multi: true,
                            simpleValue: false,
                          }}
                        />
                      )}
                    </Resource>
                  </div>

                  <div className="column_filter interview">
                    <Field
                      name="company_reports_interview"
                      id="form_company_reports_interview"
                      component={CustomComponentInterview}
                      className="select_filter_interview_dashboard"
                      onChange={setInterviewSelected}
                      labelledBy={intl.formatMessage({
                        id: 'interviews',
                      })}
                      customSelectProps={{
                        value: interviewSelected,
                        options: [
                          {
                            id: 2,
                            nome: intl.formatMessage({
                              id: 'admin.prostate',
                            }),
                          },
                          {
                            id: 6,
                            nome: intl.formatMessage({
                              id: 'admin.uterine',
                            }),
                          },
                          {
                            id: 7,
                            nome: intl.formatMessage({
                              id: 'admin.medical.male',
                            }),
                          },
                          {
                            id: 8,
                            nome: intl.formatMessage({
                              id: 'admin.medical.female',
                            }),
                          },
                          {
                            id: 10,
                            nome: intl.formatMessage({
                              id: 'admin.breast.cancer',
                            }),
                          },
                          {
                            id: 11,
                            nome: intl.formatMessage({
                              id: 'admin.lung.cancer',
                            }),
                          },
                          {
                            id: 12,
                            nome: intl.formatMessage({
                              id: 'admin.colon.cancer',
                            }),
                          },
                          // {
                          //   id: 13,
                          //   nome: intl.formatMessage({
                          //     id: 'admin.mental',
                          //   }),
                          // },
                          {
                            id: 14,
                            nome: intl.formatMessage({
                              id: 'admin.cardio',
                            }),
                          },
                        ],
                        labelKey: 'nome',
                        valueKey: 'id',
                        multi: true,
                        simpleValue: false,
                      }}
                    />
                  </div>

                  <div className="column_filter">
                    <Field
                      name="company_reports_risk"
                      id="form_company_reports_risk"
                      component={CustomComponentRisk}
                      className="select_filter_risk_dashboard"
                      customSelectProps={{
                        options: [
                          {
                            id: 0,
                            nome: intl.formatMessage({
                              id: 'admin.all.risks',
                            }),
                          },
                          {
                            id: 1,
                            nome: intl.formatMessage({
                              id: 'admin.high.risk',
                            }),
                          },
                          {
                            id: 2,
                            nome: intl.formatMessage({
                              id: 'admin.medium.risk',
                            }),
                          },
                          {
                            id: 3,
                            nome: intl.formatMessage({
                              id: 'admin.low.risk',
                            }),
                          },
                        ],
                        labelKey: 'nome',
                        valueKey: 'id',
                      }}
                    />
                  </div>

                  <div className="column_filter">
                    <Field
                      name="company_reports_deleted_users"
                      id="form_company_reports_deleted_users"
                      component={CustomComponentDeletedUsers}
                      className="select_filter"
                      customSelectProps={{
                        options: [
                          {
                            id: 0,
                            nome: intl.formatMessage({
                              id: 'users.not.deleted',
                            }),
                          },
                          {
                            id: 1,
                            nome: intl.formatMessage({
                              id: 'users.with.deleted',
                            }),
                          },
                        ],
                        labelKey: 'nome',
                        valueKey: 'id',
                      }}
                    />
                  </div>

                  <div className="column_filter">
                    <Button
                      id="filter_apply"
                      color="#39acb5"
                      textColor="white"
                      className="button_filter_dashbord"
                      disabled={isFetching}
                      style={{
                        height: '39px',
                        width: '20px',
                      }}
                    >
                      {!isFetching ? (
                        <Icon
                          style={{ width: 15 }}
                          icon={require('@fortawesome/fontawesome-free-solid/faFilter')}
                        />
                      ) : (
                        <img src={Spinner} alt="reload" style={{ width: 20 }} />
                      )}
                    </Button>
                  </div>
                </FormGrid>
              </form>
            )}
          </Form>
        </div>
      </ContainerFilter>
    </Fragment>
  );
};

const CustomComponentStyled = styled(props => <Base {...props} />)`
  .Select-control {
    background-color: ${props =>
      props.theme.colors[props.color] !== undefined
        ? props.theme.colors[props.color]
        : props.color} !important;
    & * {
      color: ${props => props.theme.colors[props.textColor]} !important;
      .Select-arrow {
        border-color: ${props => props.theme.colors[props.textColor]} none !important;
      }
    }
  }

  .dropdown-container {
    background-color: ${props =>
      props.theme.colors[props.color] !== undefined
        ? props.theme.colors[props.color]
        : props.color} !important;
    border: 1px solid
      ${props =>
        props.theme.colors[props.color] !== undefined
          ? props.theme.colors[props.color]
          : props.color} !important;
    .dropdown-heading {
      svg {
        transform: scale(0.6);
        color: #fff;
      }
      .dropdown-heading-value {
        color: #fff;
        span {
          color: #fff;
          &.gray {
            font-size: 0;
            &:before {
              font-size: 16px;
              content: '${props => props.label || 'Select'}...';
            }
          }
        }
      }
    }
    .item-renderer {
      display: flex;
      align-items: center;
      input {
        transform: scale(1.5);
      }
      span {
        padding-left: 10px;
        font-size: 14px;
      }
    }
  }
`;
const CustomComponent = injectIntl(({ intl, ...props }) => {
  let customSelectProps = { ...props.customSelectProps };
  let {
    labelKey = 'name',
    valueKey = 'id',
    options = [],
    multi = false,
    value,
  } = customSelectProps;
  options = options.map(item => {
    return { label: item[labelKey], value: item[valueKey] };
  });

  return (
    <CustomComponentStyled
      color="#39acb5"
      textColor="white"
      label={props.labelledBy}
    >
      {multi ? (
        <MultiSelect
          options={options}
          value={value}
          overrideStrings={intl.formatMessage({
            id: 'plugin.OverrideMultiSlect',
          })}
          {...props}
        />
      ) : (
        <CustomFieldSelect {...props} />
      )}
    </CustomComponentStyled>
  );
});

const CustomComponentCompany = injectIntl(({ intl, ...props }) => (
  <CustomComponent
    {...props}
    placeholder={intl.formatMessage({
      id: 'users.admin.companies.title',
    })}
  />
));
const CustomComponentInterview = injectIntl(({ intl, ...props }) => (
  <CustomComponent
    {...props}
    placeholder={intl.formatMessage({
      id: 'admin.interviews',
    })}
  />
));

const CustomComponentRisk = injectIntl(({ intl, ...props }) => (
  <CustomComponent
    {...props}
    placeholder={intl.formatMessage({
      id: 'admin.common.risks',
    })}
  />
));

const CustomComponentDeletedUsers = injectIntl(({ intl, ...props }) => (
  <CustomComponent
    {...props}
    placeholder={intl.formatMessage({
      id: 'admin.common.deleted.users',
    })}
  />
));

export default injectIntl(FiltersDashbord);
