import objectPath from 'object-path'
import expand from 'utils/expand'

export default async function handleFormSubmssion (promise) {
  try {
    await promise
  } catch (err) {
    return expand(objectPath.get(err, 'res.body.errors'))
  }
}
