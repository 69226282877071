import React from 'react'
import Text from 'components/Text'
import Icon from 'components/Icon'
import { Container, Input, Check, ContainerCheckbox } from './styles'

export default function OptionCheckbox ({ children, ...props }) {
  return (
    <ContainerCheckbox>
      <Container readOnly={props.readOnly}>
        <Input {...props} disabled={props.readOnly} />
        <Check>
          <Icon icon='check' color='white' />
        </Check>
        <Text color='textGray' size='18px'>
          {children}
        </Text>
      </Container>
    </ContainerCheckbox>
  )
}
