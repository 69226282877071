import { Component } from 'react'
import PropTypes from 'prop-types'

export default class Interval extends Component {
  static propTypes = {
    /** Desabilita o interval */
    disabled: PropTypes.bool,

    /** Timeout em milissegundos */
    timeout: PropTypes.number.isRequired,

    /** Callback */
    callback: PropTypes.func.isRequired
  }

  static defaultProps = {
    disabled: false
  }

  componentDidMount () {
    this.interval = setInterval(() => {
      if (this.interval && !this.props.disabled) {
        this.props.callback()
      }
    }, this.props.timeout)
  }

  componentWillUnmount () {
    this.interval = clearInterval(this.interval)
  }

  render () {
    return null
  }
}
