import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { isEmpty } from 'ramda'
import Resource from 'containers/Resource'
import Section from 'admin/components/Section'
import Text from 'components/Text'
import Spinner from 'components/Spinner'
import BackLink from 'components/BackLink'
import VerticalSpacer from 'components/VerticalSpacer'
import Logs from 'admin/components/Logs'

const state = {}
const CommonWrapper = props => <div children={props.children} />

export default class ResourceDetails extends Component {
  render () {
    const Wrapper = this.props.wrapped
      ? Section
      : CommonWrapper

    const { resource, routeBase, id, Details } = this.props

    if(!state.id)
      state.id = id

    return (
      <Resource
        resource={resource}
        redirectToAfterSuccess={this.props.redirectAfterSuccess ? routeBase.index : ''}
        id={id}
        autoFetch
        {...this.props}
      >
        {props => {
          if (props.isFetching) {
            return <Spinner />
          }

          if (isEmpty(props.detailedRecord)) {
            return (
              <FormattedMessage id='admin.common.noResultsFound'>
                {text => <Text>{text}</Text>}
              </FormattedMessage>
            )
          }

          return (
            <VerticalSpacer space={45}>
              <Wrapper  
                style={this.props.style}
                title={this.props.title}
                side={<BackLink to={this.props.routeBase.index} />}
              >
                <Details
                  {...props}
                  title={this.props.title}
                  data
                />
              </Wrapper>
              {props.logs.length > 0 && (
                <Logs logs={props.logs} />
              )}
            </VerticalSpacer>
          )
        }}
      </Resource>
    )
  }

  static propTypes = {
    /** Recurso */
    resource: PropTypes.string.isRequired,

    /** ID do recurso a ser exibido */
    id: PropTypes.number.isRequired,

    /** Rota base */
    routeBase: PropTypes.object.isRequired,

    /** Título */
    title: PropTypes.string.isRequired,

    /** Component */
    Details: PropTypes.func.isRequired,

    /** Embala o Form com uma box e inclui o section header */
    wrapped: PropTypes.bool
  }

  static defaultProps = {
    wrapped: true
  }
}
