const _countries = {
  en: {
    AD: {
      mask: 'AD999',
      code: 'AD',
      country: 'Andorra',
      label: 'Postal code',
      phone: '376',
      option: '(+376) Andorra [AD]',
      value: '(+376) Andorra',
    },
    AE: {
      mask: '',
      code: 'AE',
      country: 'United Arab Emirates',
      label: '',
      phone: '971',
      option: '(+971) United Arab Emirates [AE]',
      value: '(+971) United Arab Emirates',
    },
    AF: {
      mask: '9999',
      code: 'AF',
      country: 'Afghanistan',
      label: 'Postal code',
      phone: '93',
      option: '(+93) Afghanistan [AF]',
      value: '(+93) Afghanistan',
    },
    AG: {
      mask: '',
      code: 'AG',
      country: 'Antigua and Barbuda',
      label: '',
      phone: '1-268',
      option: '(+1-268) Antigua and Barbuda [AG]',
      value: '(+1-268) Antigua and Barbuda',
    },
    AI: {
      mask: 'AI-9999',
      code: 'AI',
      country: 'Anguilla',
      label: 'Postcode',
      phone: '1-264',
      option: '(+1-264) Anguilla [AI]',
      value: '(+1-264) Anguilla',
    },
    AL: {
      mask: '9999',
      code: 'AL',
      country: 'Albania',
      label: 'Postal code',
      phone: '355',
      option: '(+355) Albania [AL]',
      value: '(+355) Albania',
    },
    AM: {
      mask: '9999',
      code: 'AM',
      country: 'Armenia',
      label: 'Postal code',
      phone: '374',
      option: '(+374) Armenia [AM]',
      value: '(+374) Armenia',
    },
    AO: {
      mask: '',
      code: 'AO',
      country: 'Angola',
      label: '',
      phone: '244',
      option: '(+244) Angola [AO]',
      value: '(+244) Angola',
    },
    AQ: {
      mask: '',
      code: 'AQ',
      country: 'Antarctica',
      label: '',
      phone: '672',
      option: '(+672) Antarctica [AQ]',
      value: '(+672) Antarctica',
    },
    AR: {
      mask: 'A9999AAA',
      code: 'AR',
      country: 'Argentina',
      label: 'CPA',
      phone: '54',
      option: '(+54) Argentina [AR]',
      value: '(+54) Argentina',
    },
    AS: {
      mask: '99999',
      code: 'AS',
      country: 'American Samoa',
      label: 'ZIP codes',
      phone: '1-684',
      option: '(+1-684) American Samoa [AS]',
      value: '(+1-684) American Samoa',
    },
    AT: {
      mask: '9999',
      code: 'AT',
      country: 'Austria',
      label: 'PLZ',
      phone: '43',
      option: '(+43) Austria [AT]',
      value: '(+43) Austria',
    },
    AU: {
      mask: '9999',
      code: 'AU',
      country: 'Australia',
      label: 'Postal code',
      phone: '61',
      option: '(+61) Australia [AU]',
      value: '(+61) Australia',
    },
    AW: {
      mask: '',
      code: 'AW',
      country: 'Aruba',
      label: '',
      phone: '297',
      option: '(+297) Aruba [AW]',
      value: '(+297) Aruba',
    },
    AX: {
      mask: 'AX-99999',
      code: 'AX',
      country: 'Alland Islands',
      label: 'Postnummer',
      phone: '358',
      option: '(+358) Alland Islands [AX]',
      value: '(+358) Alland Islands',
    },
    AZ: {
      mask: 'AZ9999',
      code: 'AZ',
      country: 'Azerbaijan',
      label: 'Ä°ndeks',
      phone: '994',
      option: '(+994) Azerbaijan [AZ]',
      value: '(+994) Azerbaijan',
    },
    BA: {
      mask: '99999',
      code: 'BA',
      country: 'Bosnia and Herzegovina',
      label: 'Postal code',
      phone: '387',
      option: '(+387) Bosnia and Herzegovina [BA]',
      value: '(+387) Bosnia and Herzegovina',
    },
    BB: {
      mask: 'BB99999',
      code: 'BB',
      country: 'Barbados',
      label: 'Postal code',
      phone: '1-246',
      option: '(+1-246) Barbados [BB]',
      value: '(+1-246) Barbados',
    },
    BD: {
      mask: '9999',
      code: 'BD',
      country: 'Bangladesh',
      label: 'Postal code',
      phone: '880',
      option: '(+880) Bangladesh [BD]',
      value: '(+880) Bangladesh',
    },
    BE: {
      mask: '9999',
      code: 'BE',
      country: 'Belgium',
      label: 'Code postal / Postcode',
      phone: '32',
      option: '(+32) Belgium [BE]',
      value: '(+32) Belgium',
    },
    BF: {
      mask: '',
      code: 'BF',
      country: 'Burkina Faso',
      label: '',
      phone: '226',
      option: '(+226) Burkina Faso [BF]',
      value: '(+226) Burkina Faso',
    },
    BG: {
      mask: '9999',
      code: 'BG',
      country: 'Bulgaria',
      label: 'Postal code',
      phone: '359',
      option: '(+359) Bulgaria [BG]',
      value: '(+359) Bulgaria',
    },
    BH: {
      mask: '[9]999',
      code: 'BH',
      country: 'Bahrain',
      label: 'Postal code',
      phone: '973',
      option: '(+973) Bahrain [BH]',
      value: '(+973) Bahrain',
    },
    BI: {
      mask: '',
      code: 'BI',
      country: 'Burundi',
      label: '',
      phone: '257',
      option: '(+257) Burundi [BI]',
      value: '(+257) Burundi',
    },
    BJ: {
      mask: '',
      code: 'BJ',
      country: 'Benin',
      label: '',
      phone: '229',
      option: '(+229) Benin [BJ]',
      value: '(+229) Benin',
    },
    BL: {
      mask: '99999',
      code: 'BL',
      country: 'Saint Barthelemy',
      label: 'Code postal',
      phone: '590',
      option: '(+590) Saint Barthelemy [BL]',
      value: '(+590) Saint Barthelemy',
    },
    BM: {
      mask: 'AA99',
      code: 'BM',
      country: 'Bermuda',
      label: 'Postcode',
      phone: '1-441',
      option: '(+1-441) Bermuda [BM]',
      value: '(+1-441) Bermuda',
    },
    BN: {
      mask: 'AA9999',
      code: 'BN',
      country: 'Brunei Darussalam',
      label: 'Postal code',
      phone: '673',
      option: '(+673) Brunei Darussalam [BN]',
      value: '(+673) Brunei Darussalam',
    },
    BO: {
      mask: '9999',
      code: 'BO',
      country: 'Bolivia',
      label: 'Código postal',
      phone: '591',
      option: '(+591) Bolivia [BO]',
      value: '(+591) Bolivia',
    },
    BR: {
      mask: '99999-999',
      code: 'BR',
      country: 'Brazil',
      label: 'CEP',
      phone: '55',
      option: '(+55) Brazil [BR]',
      value: '(+55) Brazil',
    },
    BS: {
      mask: '',
      code: 'BS',
      country: 'Bahamas',
      label: '',
      phone: '1-242',
      option: '(+1-242) Bahamas [BS]',
      value: '(+1-242) Bahamas',
    },
    BT: {
      mask: '999',
      code: 'BT',
      country: 'Bhutan',
      label: 'Postal code',
      phone: '975',
      option: '(+975) Bhutan [BT]',
      value: '(+975) Bhutan',
    },
    BV: {
      mask: '',
      code: 'BV',
      country: 'Bouvet Island',
      label: '',
      phone: '47',
      option: '(+47) Bouvet Island [BV]',
      value: '(+47) Bouvet Island',
    },
    BW: {
      mask: '',
      code: 'BW',
      country: 'Botswana',
      label: '',
      phone: '267',
      option: '(+267) Botswana [BW]',
      value: '(+267) Botswana',
    },
    BY: {
      mask: '299999',
      code: 'BY',
      country: 'Belarus',
      label: 'Postal code',
      phone: '375',
      option: '(+375) Belarus [BY]',
      value: '(+375) Belarus',
    },
    BZ: {
      mask: '',
      code: 'BZ',
      country: 'Belize',
      label: '',
      phone: '501',
      option: '(+501) Belize [BZ]',
      value: '(+501) Belize',
    },
    CA: {
      mask: 'A9A9A9',
      code: 'CA',
      country: 'Canada',
      label: 'Postal code / Code postal',
      phone: '1',
      option: '(+1) Canada [CA]',
      value: '(+1) Canada',
    },
    CC: {
      mask: '9999',
      code: 'CC',
      country: 'Cocos (Keeling) Islands',
      label: 'Postal code',
      phone: '61',
      option: '(+61) Cocos (Keeling) Islands [CC]',
      value: '(+61) Cocos (Keeling) Islands',
    },
    CD: {
      mask: '',
      code: 'CD',
      country: 'Congo, Democratic Republic of the',
      label: '',
      phone: '243',
      option: '(+243) Congo, Democratic Republic of the [CD]',
      value: '(+243) Congo, Democratic Republic of the',
    },
    CF: {
      mask: '',
      code: 'CF',
      country: 'Central African Republic',
      label: '',
      phone: '236',
      option: '(+236) Central African Republic [CF]',
      value: '(+236) Central African Republic',
    },
    CG: {
      mask: '',
      code: 'CG',
      country: 'Congo, Republic of the',
      label: '',
      phone: '242',
      option: '(+242) Congo, Republic of the [CG]',
      value: '(+242) Congo, Republic of the',
    },
    CH: {
      mask: '9999',
      code: 'CH',
      country: 'Switzerland',
      label: 'NPA',
      phone: '41',
      option: '(+41) Switzerland [CH]',
      value: '(+41) Switzerland',
    },
    CI: {
      mask: '',
      code: 'CI',
      country: "Cote d'Ivoire",
      label: '',
      phone: '225',
      option: "(+225) Cote d'Ivoire [CI]",
      value: "(+225) Cote d'Ivoire",
    },
    CK: {
      mask: '',
      code: 'CK',
      country: 'Cook Islands',
      label: '',
      phone: '682',
      option: '(+682) Cook Islands [CK]',
      value: '(+682) Cook Islands',
    },
    CL: {
      mask: '999-9999',
      code: 'CL',
      country: 'Chile',
      label: 'Código postal',
      phone: '56',
      option: '(+56) Chile [CL]',
      value: '(+56) Chile',
    },
    CM: {
      mask: '',
      code: 'CM',
      country: 'Cameroon',
      label: '',
      phone: '237',
      option: '(+237) Cameroon [CM]',
      value: '(+237) Cameroon',
    },
    CN: {
      mask: '999999',
      code: 'CN',
      country: 'China',
      label: 'Postal code',
      phone: '86',
      option: '(+86) China [CN]',
      value: '(+86) China',
    },
    CO: {
      mask: '999999',
      code: 'CO',
      country: 'Colombia',
      label: 'Código postal',
      phone: '57',
      option: '(+57) Colombia [CO]',
      value: '(+57) Colombia',
    },
    CR: {
      mask: '99999',
      code: 'CR',
      country: 'Costa Rica',
      label: 'Código postal',
      phone: '506',
      option: '(+506) Costa Rica [CR]',
      value: '(+506) Costa Rica',
    },
    CU: {
      mask: '99999',
      code: 'CU',
      country: 'Cuba',
      label: 'Código postal',
      phone: '53',
      option: '(+53) Cuba [CU]',
      value: '(+53) Cuba',
    },
    CV: {
      mask: '9999',
      code: 'CV',
      country: 'Cape Verde',
      label: 'Postal code',
      phone: '238',
      option: '(+238) Cape Verde [CV]',
      value: '(+238) Cape Verde',
    },
    CW: {
      mask: '',
      code: 'CW',
      country: 'Curacao',
      label: '',
      phone: '599',
      option: '(+599) Curacao [CW]',
      value: '(+599) Curacao',
    },
    CX: {
      mask: '9999',
      code: 'CX',
      country: 'Christmas Island',
      label: 'Post Code',
      phone: '61',
      option: '(+61) Christmas Island [CX]',
      value: '(+61) Christmas Island',
    },
    CY: {
      mask: '9999',
      code: 'CY',
      country: 'Cyprus',
      label: 'Postal code',
      phone: '357',
      option: '(+357) Cyprus [CY]',
      value: '(+357) Cyprus',
    },
    CZ: {
      mask: '99999',
      code: 'CZ',
      country: 'Czech Republic',
      label: 'PSÄŒ',
      phone: '420',
      option: '(+420) Czech Republic [CZ]',
      value: '(+420) Czech Republic',
    },
    DE: {
      mask: '99999',
      code: 'DE',
      country: 'Germany',
      label: 'PLZ',
      phone: '49',
      option: '(+49) Germany [DE]',
      value: '(+49) Germany',
    },
    DJ: {
      mask: '',
      code: 'DJ',
      country: 'Djibouti',
      label: '',
      phone: '253',
      option: '(+253) Djibouti [DJ]',
      value: '(+253) Djibouti',
    },
    DK: {
      mask: '9999',
      code: 'DK',
      country: 'Denmark',
      label: 'Postal code',
      phone: '45',
      option: '(+45) Denmark [DK]',
      value: '(+45) Denmark',
    },
    DM: {
      mask: '',
      code: 'DM',
      country: 'Dominica',
      label: '',
      phone: '1-767',
      option: '(+1-767) Dominica [DM]',
      value: '(+1-767) Dominica',
    },
    DO: {
      mask: '99999',
      code: 'DO',
      country: 'Dominican Republic',
      label: 'Código postal',
      phone: '1-809',
      option: '(+1-809) Dominican Republic [DO]',
      value: '(+1-809) Dominican Republic',
    },
    DZ: {
      mask: '99999',
      code: 'DZ',
      country: 'Algeria',
      label: 'Code postal',
      phone: '213',
      option: '(+213) Algeria [DZ]',
      value: '(+213) Algeria',
    },
    EC: {
      mask: '999999',
      code: 'EC',
      country: 'Ecuador',
      label: 'Código postal',
      phone: '593',
      option: '(+593) Ecuador [EC]',
      value: '(+593) Ecuador',
    },
    EE: {
      mask: '99999',
      code: 'EE',
      country: 'Estonia',
      label: 'Postal code',
      phone: '372',
      option: '(+372) Estonia [EE]',
      value: '(+372) Estonia',
    },
    EG: {
      mask: '99999',
      code: 'EG',
      country: 'Egypt',
      label: 'Postal code',
      phone: '20',
      option: '(+20) Egypt [EG]',
      value: '(+20) Egypt',
    },
    EH: {
      mask: '99999',
      code: 'EH',
      country: 'Western Sahara',
      label: 'Postal code',
      phone: '212',
      option: '(+212) Western Sahara [EH]',
      value: '(+212) Western Sahara',
    },
    ER: {
      mask: '',
      code: 'ER',
      country: 'Eritrea',
      label: '',
      phone: '291',
      option: '(+291) Eritrea [ER]',
      value: '(+291) Eritrea',
    },
    ES: {
      mask: '99999',
      code: 'ES',
      country: 'Spain',
      label: 'Código postal',
      phone: '34',
      option: '(+34) Spain [ES]',
      value: '(+34) Spain',
    },
    ET: {
      mask: '9999',
      code: 'ET',
      country: 'Ethiopia',
      label: 'Postal code',
      phone: '251',
      option: '(+251) Ethiopia [ET]',
      value: '(+251) Ethiopia',
    },
    FI: {
      mask: '99999',
      code: 'FI',
      country: 'Finland',
      label: 'Postnummer',
      phone: '358',
      option: '(+358) Finland [FI]',
      value: '(+358) Finland',
    },
    FJ: {
      mask: '',
      code: 'FJ',
      country: 'Fiji',
      label: '',
      phone: '679',
      option: '(+679) Fiji [FJ]',
      value: '(+679) Fiji',
    },
    FK: {
      mask: 'FIQQ 1ZZ',
      code: 'FK',
      country: 'Falkland Islands (Malvinas)',
      label: 'Postcode',
      phone: '500',
      option: '(+500) Falkland Islands (Malvinas) [FK]',
      value: '(+500) Falkland Islands (Malvinas)',
    },
    FM: {
      mask: '99999',
      code: 'FM',
      country: 'Micronesia, Federated States of',
      label: 'ZIP codes',
      phone: '691',
      option: '(+691) Micronesia, Federated States of [FM]',
      value: '(+691) Micronesia, Federated States of',
    },
    FO: {
      mask: '999',
      code: 'FO',
      country: 'Faroe Islands',
      label: 'Postal code',
      phone: '298',
      option: '(+298) Faroe Islands [FO]',
      value: '(+298) Faroe Islands',
    },
    FR: {
      mask: '99999',
      code: 'FR',
      country: 'France',
      label: 'Code postal',
      phone: '33',
      option: '(+33) France [FR]',
      value: '(+33) France',
    },
    GA: {
      mask: '99 city99',
      code: 'GA',
      country: 'Gabon',
      label: 'Postal code',
      phone: '241',
      option: '(+241) Gabon [GA]',
      value: '(+241) Gabon',
    },
    GB: {
      mask: 'AA99AA',
      code: 'GB',
      country: 'United Kingdom',
      label: 'Postcode',
      phone: '44',
      option: '(+44) United Kingdom [GB]',
      value: '(+44) United Kingdom',
    },
    GD: {
      mask: '',
      code: 'GD',
      country: 'Grenada',
      label: '',
      phone: '1-473',
      option: '(+1-473) Grenada [GD]',
      value: '(+1-473) Grenada',
    },
    GE: {
      mask: '9999',
      code: 'GE',
      country: 'Georgia',
      label: 'Postal code',
      phone: '995',
      option: '(+995) Georgia [GE]',
      value: '(+995) Georgia',
    },
    GF: {
      mask: '99999',
      code: 'GF',
      country: 'French Guiana',
      label: 'Code postal',
      phone: '594',
      option: '(+594) French Guiana [GF]',
      value: '(+594) French Guiana',
    },
    GG: {
      mask: 'GY99AA',
      code: 'GG',
      country: 'Guernsey',
      label: 'Postcode',
      phone: '44',
      option: '(+44) Guernsey [GG]',
      value: '(+44) Guernsey',
    },
    GH: {
      mask: '',
      code: 'GH',
      country: 'Ghana',
      label: '',
      phone: '233',
      option: '(+233) Ghana [GH]',
      value: '(+233) Ghana',
    },
    GI: {
      mask: 'GX11 1AA',
      code: 'GI',
      country: 'Gibraltar',
      label: 'Postcode',
      phone: '350',
      option: '(+350) Gibraltar [GI]',
      value: '(+350) Gibraltar',
    },
    GL: {
      mask: '9999',
      code: 'GL',
      country: 'Greenland',
      label: 'Postal code',
      phone: '299',
      option: '(+299) Greenland [GL]',
      value: '(+299) Greenland',
    },
    GM: {
      mask: '',
      code: 'GM',
      country: 'Gambia',
      label: '',
      phone: '220',
      option: '(+220) Gambia [GM]',
      value: '(+220) Gambia',
    },
    GN: {
      mask: '',
      code: 'GN',
      country: 'Guinea',
      label: '',
      phone: '224',
      option: '(+224) Guinea [GN]',
      value: '(+224) Guinea',
    },
    GP: {
      mask: '99999',
      code: 'GP',
      country: 'Guadeloupe',
      label: 'Code postal',
      phone: '590',
      option: '(+590) Guadeloupe [GP]',
      value: '(+590) Guadeloupe',
    },
    GQ: {
      mask: '',
      code: 'GQ',
      country: 'Equatorial Guinea',
      label: '',
      phone: '240',
      option: '(+240) Equatorial Guinea [GQ]',
      value: '(+240) Equatorial Guinea',
    },
    GR: {
      mask: '99999',
      code: 'GR',
      country: 'Greece',
      label: 'Postal code',
      phone: '30',
      option: '(+30) Greece [GR]',
      value: '(+30) Greece',
    },
    GS: {
      mask: 'SIQQ 1ZZ',
      code: 'GS',
      country: 'South Georgia and the South Sandwich Islands',
      label: 'Postcode',
      phone: '500',
      option: '(+500) South Georgia and the South Sandwich Islands [GS]',
      value: '(+500) South Georgia and the South Sandwich Islands',
    },
    GT: {
      mask: '99999',
      code: 'GT',
      country: 'Guatemala',
      label: 'Código postal',
      phone: '502',
      option: '(+502) Guatemala [GT]',
      value: '(+502) Guatemala',
    },
    GU: {
      mask: '99999',
      code: 'GU',
      country: 'Guam',
      label: 'ZIP codes',
      phone: '1-671',
      option: '(+1-671) Guam [GU]',
      value: '(+1-671) Guam',
    },
    GW: {
      mask: '9999',
      code: 'GW',
      country: 'Guinea-Bissau',
      label: 'Postal code',
      phone: '245',
      option: '(+245) Guinea-Bissau [GW]',
      value: '(+245) Guinea-Bissau',
    },
    GY: {
      mask: '',
      code: 'GY',
      country: 'Guyana',
      label: '',
      phone: '592',
      option: '(+592) Guyana [GY]',
      value: '(+592) Guyana',
    },
    HK: {
      mask: '',
      code: 'HK',
      country: 'Hong Kong',
      label: '',
      phone: '852',
      option: '(+852) Hong Kong [HK]',
      value: '(+852) Hong Kong',
    },
    HM: {
      mask: '9999',
      code: 'HM',
      country: 'Heard Island and McDonald Islands',
      label: 'Postal code',
      phone: '672',
      option: '(+672) Heard Island and McDonald Islands [HM]',
      value: '(+672) Heard Island and McDonald Islands',
    },
    HN: {
      mask: '99999',
      code: 'HN',
      country: 'Honduras',
      label: 'Código postal',
      phone: '504',
      option: '(+504) Honduras [HN]',
      value: '(+504) Honduras',
    },
    HR: {
      mask: '99999',
      code: 'HR',
      country: 'Croatia',
      label: 'Postal code',
      phone: '385',
      option: '(+385) Croatia [HR]',
      value: '(+385) Croatia',
    },
    HT: {
      mask: '9999',
      code: 'HT',
      country: 'Haiti',
      label: 'Code postal',
      phone: '509',
      option: '(+509) Haiti [HT]',
      value: '(+509) Haiti',
    },
    HU: {
      mask: '9999',
      code: 'HU',
      country: 'Hungary',
      label: 'Postal code',
      phone: '36',
      option: '(+36) Hungary [HU]',
      value: '(+36) Hungary',
    },
    ID: {
      mask: '99999',
      code: 'ID',
      country: 'Indonesia',
      label: 'Postal code',
      phone: '62',
      option: '(+62) Indonesia [ID]',
      value: '(+62) Indonesia',
    },
    IE: {
      mask: 'D99',
      code: 'IE',
      country: 'Ireland',
      label: 'Postcode',
      phone: '353',
      option: '(+353) Ireland [IE]',
      value: '(+353) Ireland',
    },
    IL: {
      mask: '9999999',
      code: 'IL',
      country: 'Israel',
      label: 'Postal code',
      phone: '972',
      option: '(+972) Israel [IL]',
      value: '(+972) Israel',
    },
    IM: {
      mask: 'IM99AA',
      code: 'IM',
      country: 'Isle of Man',
      label: 'Postcode',
      phone: '44',
      option: '(+44) Isle of Man [IM]',
      value: '(+44) Isle of Man',
    },
    IN: {
      mask: '999999',
      code: 'IN',
      country: 'India',
      label: 'PIN code',
      phone: '91',
      option: '(+91) India [IN]',
      value: '(+91) India',
    },
    IO: {
      mask: 'BB9D 1ZZ',
      code: 'IO',
      country: 'British Indian Ocean Territory',
      label: 'Postcode',
      phone: '246',
      option: '(+246) British Indian Ocean Territory [IO]',
      value: '(+246) British Indian Ocean Territory',
    },
    IQ: {
      mask: '99999',
      code: 'IQ',
      country: 'Iraq',
      label: 'Postal code',
      phone: '964',
      option: '(+964) Iraq [IQ]',
      value: '(+964) Iraq',
    },
    IR: {
      mask: '99999-99999',
      code: 'IR',
      country: 'Iran, Islamic Republic of',
      label: 'Postal code',
      phone: '98',
      option: '(+98) Iran, Islamic Republic of [IR]',
      value: '(+98) Iran, Islamic Republic of',
    },
    IS: {
      mask: '999',
      code: 'IS',
      country: 'Iceland',
      label: 'Postal code',
      phone: '354',
      option: '(+354) Iceland [IS]',
      value: '(+354) Iceland',
    },
    IT: {
      mask: '99999',
      code: 'IT',
      country: 'Italy',
      label: 'CAP',
      phone: '39',
      option: '(+39) Italy [IT]',
      value: '(+39) Italy',
    },
    JE: {
      mask: 'JE99AA',
      code: 'JE',
      country: 'Jersey',
      label: 'Postcode',
      phone: '44',
      option: '(+44) Jersey [JE]',
      value: '(+44) Jersey',
    },
    JM: {
      mask: 'JMAAA99',
      code: 'JM',
      country: 'Jamaica',
      label: 'Postal code',
      phone: '1-876',
      option: '(+1-876) Jamaica [JM]',
      value: '(+1-876) Jamaica',
    },
    JO: {
      mask: '99999',
      code: 'JO',
      country: 'Jordan',
      label: 'Postal code',
      phone: '962',
      option: '(+962) Jordan [JO]',
      value: '(+962) Jordan',
    },
    JP: {
      mask: '999-9999',
      code: 'JP',
      country: 'Japan',
      label: 'éƒµä¾¿ç•ªå·',
      phone: '81',
      option: '(+81) Japan [JP]',
      value: '(+81) Japan',
    },
    KE: {
      mask: '99999',
      code: 'KE',
      country: 'Kenya',
      label: 'Postal code',
      phone: '254',
      option: '(+254) Kenya [KE]',
      value: '(+254) Kenya',
    },
    KG: {
      mask: '999999',
      code: 'KG',
      country: 'Kyrgyzstan',
      label: 'Postal code',
      phone: '996',
      option: '(+996) Kyrgyzstan [KG]',
      value: '(+996) Kyrgyzstan',
    },
    KH: {
      mask: '99999',
      code: 'KH',
      country: 'Cambodia',
      label: 'Postal code',
      phone: '855',
      option: '(+855) Cambodia [KH]',
      value: '(+855) Cambodia',
    },
    KI: {
      mask: '',
      code: 'KI',
      country: 'Kiribati',
      label: '',
      phone: '686',
      option: '(+686) Kiribati [KI]',
      value: '(+686) Kiribati',
    },
    KM: {
      mask: '',
      code: 'KM',
      country: 'Comoros',
      label: '',
      phone: '269',
      option: '(+269) Comoros [KM]',
      value: '(+269) Comoros',
    },
    KN: {
      mask: '',
      code: 'KN',
      country: 'Saint Kitts and Nevis',
      label: '',
      phone: '1-869',
      option: '(+1-869) Saint Kitts and Nevis [KN]',
      value: '(+1-869) Saint Kitts and Nevis',
    },
    KP: {
      mask: '',
      code: 'KP',
      country: "Korea, Democratic People's Republic of",
      label: '',
      phone: '850',
      option: "(+850) Korea, Democratic People's Republic of [KP]",
      value: "(+850) Korea, Democratic People's Republic of",
    },
    KR: {
      mask: '999-999',
      code: 'KR',
      country: 'Korea, Republic of',
      label: 'Postal code',
      phone: '82',
      option: '(+82) Korea, Republic of [KR]',
      value: '(+82) Korea, Republic of',
    },
    KW: {
      mask: '99999',
      code: 'KW',
      country: 'Kuwait',
      label: 'Postal code',
      phone: '965',
      option: '(+965) Kuwait [KW]',
      value: '(+965) Kuwait',
    },
    KY: {
      mask: 'KY9-9999',
      code: 'KY',
      country: 'Cayman Islands',
      label: 'Postal code',
      phone: '1-345',
      option: '(+1-345) Cayman Islands [KY]',
      value: '(+1-345) Cayman Islands',
    },
    KZ: {
      mask: '999999',
      code: 'KZ',
      country: 'Kazakhstan',
      label: 'Postal code',
      phone: '7',
      option: '(+7) Kazakhstan [KZ]',
      value: '(+7) Kazakhstan',
    },
    LA: {
      mask: '99999',
      code: 'LA',
      country: "Lao People's Democratic Republic",
      label: 'Postal code',
      phone: '856',
      option: "(+856) Lao People's Democratic Republic [LA]",
      value: "(+856) Lao People's Democratic Republic",
    },
    LB: {
      mask: '99999999',
      code: 'LB',
      country: 'Lebanon',
      label: 'Postal code',
      phone: '961',
      option: '(+961) Lebanon [LB]',
      value: '(+961) Lebanon',
    },
    LC: {
      mask: '',
      code: 'LC',
      country: 'Saint Lucia',
      label: '',
      phone: '1-758',
      option: '(+1-758) Saint Lucia [LC]',
      value: '(+1-758) Saint Lucia',
    },
    LI: {
      mask: '9999',
      code: 'LI',
      country: 'Liechtenstein',
      label: 'PLZ',
      phone: '423',
      option: '(+423) Liechtenstein [LI]',
      value: '(+423) Liechtenstein',
    },
    LK: {
      mask: '99999',
      code: 'LK',
      country: 'Sri Lanka',
      label: 'Postal code',
      phone: '94',
      option: '(+94) Sri Lanka [LK]',
      value: '(+94) Sri Lanka',
    },
    LR: {
      mask: '9999',
      code: 'LR',
      country: 'Liberia',
      label: 'Postal code',
      phone: '231',
      option: '(+231) Liberia [LR]',
      value: '(+231) Liberia',
    },
    LS: {
      mask: '999',
      code: 'LS',
      country: 'Lesotho',
      label: 'Postal code',
      phone: '266',
      option: '(+266) Lesotho [LS]',
      value: '(+266) Lesotho',
    },
    LT: {
      mask: '99999',
      code: 'LT',
      country: 'Lithuania',
      label: 'Postal code',
      phone: '370',
      option: '(+370) Lithuania [LT]',
      value: '(+370) Lithuania',
    },
    LU: {
      mask: '9999',
      code: 'LU',
      country: 'Luxembourg',
      label: 'Code postal',
      phone: '352',
      option: '(+352) Luxembourg [LU]',
      value: '(+352) Luxembourg',
    },
    LV: {
      mask: 'LV-9999',
      code: 'LV',
      country: 'Latvia',
      label: 'Postal code',
      phone: '371',
      option: '(+371) Latvia [LV]',
      value: '(+371) Latvia',
    },
    LY: {
      mask: '99999',
      code: 'LY',
      country: 'Libya',
      label: 'Postal code',
      phone: '218',
      option: '(+218) Libya [LY]',
      value: '(+218) Libya',
    },
    MA: {
      mask: '99999',
      code: 'MA',
      country: 'Morocco',
      label: 'Code postal',
      phone: '212',
      option: '(+212) Morocco [MA]',
      value: '(+212) Morocco',
    },
    MC: {
      mask: '98000',
      code: 'MC',
      country: 'Monaco',
      label: 'Code postal',
      phone: '377',
      option: '(+377) Monaco [MC]',
      value: '(+377) Monaco',
    },
    MD: {
      mask: '9999',
      code: 'MD',
      country: 'Moldova, Republic of',
      label: 'Postal code',
      phone: '373',
      option: '(+373) Moldova, Republic of [MD]',
      value: '(+373) Moldova, Republic of',
    },
    ME: {
      mask: '99999',
      code: 'ME',
      country: 'Montenegro',
      label: 'Postal code',
      phone: '382',
      option: '(+382) Montenegro [ME]',
      value: '(+382) Montenegro',
    },
    MF: {
      mask: '99999',
      code: 'MF',
      country: 'Saint Martin (French part)',
      label: 'Code postal',
      phone: '590',
      option: '(+590) Saint Martin (French part) [MF]',
      value: '(+590) Saint Martin (French part)',
    },
    MG: {
      mask: '999',
      code: 'MG',
      country: 'Madagascar',
      label: 'Code postal',
      phone: '261',
      option: '(+261) Madagascar [MG]',
      value: '(+261) Madagascar',
    },
    MH: {
      mask: '99999',
      code: 'MH',
      country: 'Marshall Islands',
      label: 'ZIP codes',
      phone: '692',
      option: '(+692) Marshall Islands [MH]',
      value: '(+692) Marshall Islands',
    },
    MK: {
      mask: '9999',
      code: 'MK',
      country: 'Macedonia, the Former Yugoslav Republic of',
      label: 'Postal code',
      phone: '389',
      option: '(+389) Macedonia, the Former Yugoslav Republic of [MK]',
      value: '(+389) Macedonia, the Former Yugoslav Republic of',
    },
    ML: {
      mask: '',
      code: 'ML',
      country: 'Mali',
      label: '',
      phone: '223',
      option: '(+223) Mali [ML]',
      value: '(+223) Mali',
    },
    MM: {
      mask: '99999',
      code: 'MM',
      country: 'Myanmar',
      label: 'Postal code',
      phone: '95',
      option: '(+95) Myanmar [MM]',
      value: '(+95) Myanmar',
    },
    MN: {
      mask: '999999',
      code: 'MN',
      country: 'Mongolia',
      label: 'Postal code',
      phone: '976',
      option: '(+976) Mongolia [MN]',
      value: '(+976) Mongolia',
    },
    MO: {
      mask: '',
      code: 'MO',
      country: 'Macao',
      label: '',
      phone: '853',
      option: '(+853) Macao [MO]',
      value: '(+853) Macao',
    },
    MP: {
      mask: '99999',
      code: 'MP',
      country: 'Northern Mariana Islands',
      label: 'ZIP codes',
      phone: '1-670',
      option: '(+1-670) Northern Mariana Islands [MP]',
      value: '(+1-670) Northern Mariana Islands',
    },
    MQ: {
      mask: '99999',
      code: 'MQ',
      country: 'Martinique',
      label: 'Code postal',
      phone: '596',
      option: '(+596) Martinique [MQ]',
      value: '(+596) Martinique',
    },
    MR: {
      mask: '',
      code: 'MR',
      country: 'Mauritania',
      label: '',
      phone: '222',
      option: '(+222) Mauritania [MR]',
      value: '(+222) Mauritania',
    },
    MS: {
      mask: '',
      code: 'MS',
      country: 'Montserrat',
      label: '',
      phone: '1-664',
      option: '(+1-664) Montserrat [MS]',
      value: '(+1-664) Montserrat',
    },
    MT: {
      mask: 'AAA9999',
      code: 'MT',
      country: 'Malta',
      label: 'Postal code',
      phone: '356',
      option: '(+356) Malta [MT]',
      value: '(+356) Malta',
    },
    MU: {
      mask: '',
      code: 'MU',
      country: 'Mauritius',
      label: '',
      phone: '230',
      option: '(+230) Mauritius [MU]',
      value: '(+230) Mauritius',
    },
    MV: {
      mask: '99-99',
      code: 'MV',
      country: 'Maldives',
      label: 'Postal code',
      phone: '960',
      option: '(+960) Maldives [MV]',
      value: '(+960) Maldives',
    },
    MW: {
      mask: '',
      code: 'MW',
      country: 'Malawi',
      label: '',
      phone: '265',
      option: '(+265) Malawi [MW]',
      value: '(+265) Malawi',
    },
    MX: {
      mask: '99999',
      code: 'MX',
      country: 'Mexico',
      label: 'Código postal',
      phone: '52',
      option: '(+52) Mexico [MX]',
      value: '(+52) Mexico',
    },
    MY: {
      mask: '99999',
      code: 'MY',
      country: 'Malaysia',
      label: 'Postal code',
      phone: '60',
      option: '(+60) Malaysia [MY]',
      value: '(+60) Malaysia',
    },
    MZ: {
      mask: '9999',
      code: 'MZ',
      country: 'Mozambique',
      label: 'Postal code',
      phone: '258',
      option: '(+258) Mozambique [MZ]',
      value: '(+258) Mozambique',
    },
    NA: {
      mask: '99999',
      code: 'NA',
      country: 'Namibia',
      label: 'Postal code',
      phone: '264',
      option: '(+264) Namibia [NA]',
      value: '(+264) Namibia',
    },
    NC: {
      mask: '99999',
      code: 'NC',
      country: 'New Caledonia',
      label: 'Code postal',
      phone: '687',
      option: '(+687) New Caledonia [NC]',
      value: '(+687) New Caledonia',
    },
    NE: {
      mask: '9999',
      code: 'NE',
      country: 'Niger',
      label: 'Code postal',
      phone: '227',
      option: '(+227) Niger [NE]',
      value: '(+227) Niger',
    },
    NF: {
      mask: '9999',
      code: 'NF',
      country: 'Norfolk Island',
      label: 'Postal code',
      phone: '672',
      option: '(+672) Norfolk Island [NF]',
      value: '(+672) Norfolk Island',
    },
    NG: {
      mask: '999999',
      code: 'NG',
      country: 'Nigeria',
      label: 'Postal code',
      phone: '234',
      option: '(+234) Nigeria [NG]',
      value: '(+234) Nigeria',
    },
    NI: {
      mask: '999-999-9',
      code: 'NI',
      country: 'Nicaragua',
      label: 'Código postal',
      phone: '505',
      option: '(+505) Nicaragua [NI]',
      value: '(+505) Nicaragua',
    },
    NL: {
      mask: '9999 AA',
      code: 'NL',
      country: 'Netherlands',
      label: 'Postal code',
      phone: '31',
      option: '(+31) Netherlands [NL]',
      value: '(+31) Netherlands',
    },
    NO: {
      mask: '9999',
      code: 'NO',
      country: 'Norway',
      label: 'Postal code',
      phone: '47',
      option: '(+47) Norway [NO]',
      value: '(+47) Norway',
    },
    NP: {
      mask: '99999',
      code: 'NP',
      country: 'Nepal',
      label: 'Postal code',
      phone: '977',
      option: '(+977) Nepal [NP]',
      value: '(+977) Nepal',
    },
    NR: {
      mask: '',
      code: 'NR',
      country: 'Nauru',
      label: '',
      phone: '674',
      option: '(+674) Nauru [NR]',
      value: '(+674) Nauru',
    },
    NU: {
      mask: '',
      code: 'NU',
      country: 'Niue',
      label: '',
      phone: '683',
      option: '(+683) Niue [NU]',
      value: '(+683) Niue',
    },
    NZ: {
      mask: '9999',
      code: 'NZ',
      country: 'New Zealand',
      label: 'Postal code',
      phone: '64',
      option: '(+64) New Zealand [NZ]',
      value: '(+64) New Zealand',
    },
    OM: {
      mask: '999',
      code: 'OM',
      country: 'Oman',
      label: 'Postal code',
      phone: '968',
      option: '(+968) Oman [OM]',
      value: '(+968) Oman',
    },
    PA: {
      mask: '999999',
      code: 'PA',
      country: 'Panama',
      label: 'Código postal',
      phone: '507',
      option: '(+507) Panama [PA]',
      value: '(+507) Panama',
    },
    PE: {
      mask: '99999',
      code: 'PE',
      country: 'Peru',
      label: 'Código postal',
      phone: '51',
      option: '(+51) Peru [PE]',
      value: '(+51) Peru',
    },
    PF: {
      mask: '99999',
      code: 'PF',
      country: 'French Polynesia',
      label: 'Code postal',
      phone: '689',
      option: '(+689) French Polynesia [PF]',
      value: '(+689) French Polynesia',
    },
    PG: {
      mask: '999',
      code: 'PG',
      country: 'Papua New Guinea',
      label: 'Postal code',
      phone: '675',
      option: '(+675) Papua New Guinea [PG]',
      value: '(+675) Papua New Guinea',
    },
    PH: {
      mask: '9999',
      code: 'PH',
      country: 'Philippines',
      label: 'Postal code',
      phone: '63',
      option: '(+63) Philippines [PH]',
      value: '(+63) Philippines',
    },
    PK: {
      mask: '99999',
      code: 'PK',
      country: 'Pakistan',
      label: 'Postal code',
      phone: '92',
      option: '(+92) Pakistan [PK]',
      value: '(+92) Pakistan',
    },
    PL: {
      mask: '99-999',
      code: 'PL',
      country: 'Poland',
      label: 'Postal code',
      phone: '48',
      option: '(+48) Poland [PL]',
      value: '(+48) Poland',
    },
    PM: {
      mask: '99999',
      code: 'PM',
      country: 'Saint Pierre and Miquelon',
      label: 'Code postal',
      phone: '508',
      option: '(+508) Saint Pierre and Miquelon [PM]',
      value: '(+508) Saint Pierre and Miquelon',
    },
    PN: {
      mask: 'PCR9 1ZZ',
      code: 'PN',
      country: 'Pitcairn',
      label: 'Postcode',
      phone: '870',
      option: '(+870) Pitcairn [PN]',
      value: '(+870) Pitcairn',
    },
    PR: {
      mask: '99999',
      code: 'PR',
      country: 'Puerto Rico',
      label: 'ZIP codes',
      phone: '1',
      option: '(+1) Puerto Rico [PR]',
      value: '(+1) Puerto Rico',
    },
    PS: {
      mask: '',
      code: 'PS',
      country: 'Palestine, State of',
      label: '',
      phone: '970',
      option: '(+970) Palestine, State of [PS]',
      value: '(+970) Palestine, State of',
    },
    PT: {
      mask: '9999-999',
      code: 'PT',
      country: 'Portugal',
      label: 'Postal code',
      phone: '351',
      option: '(+351) Portugal [PT]',
      value: '(+351) Portugal',
    },
    PW: {
      mask: '99999',
      code: 'PW',
      country: 'Palau',
      label: 'ZIP codes',
      phone: '680',
      option: '(+680) Palau [PW]',
      value: '(+680) Palau',
    },
    PY: {
      mask: '9999',
      code: 'PY',
      country: 'Paraguay',
      label: 'Código postal',
      phone: '595',
      option: '(+595) Paraguay [PY]',
      value: '(+595) Paraguay',
    },
    QA: {
      mask: '',
      code: 'QA',
      country: 'Qatar',
      label: '',
      phone: '974',
      option: '(+974) Qatar [QA]',
      value: '(+974) Qatar',
    },
    RE: {
      mask: '99999',
      code: 'RE',
      country: 'Reunion',
      label: 'Code postal',
      phone: '262',
      option: '(+262) Reunion [RE]',
      value: '(+262) Reunion',
    },
    RO: {
      mask: '999999',
      code: 'RO',
      country: 'Romania',
      label: 'Postal code',
      phone: '40',
      option: '(+40) Romania [RO]',
      value: '(+40) Romania',
    },
    RS: {
      mask: '99999',
      code: 'RS',
      country: 'Serbia',
      label: 'PoÅ¡tanski broj',
      phone: '381',
      option: '(+381) Serbia [RS]',
      value: '(+381) Serbia',
    },
    RU: {
      mask: '999999',
      code: 'RU',
      country: 'Russian Federation',
      label: 'Postal code',
      phone: '7',
      option: '(+7) Russian Federation [RU]',
      value: '(+7) Russian Federation',
    },
    RW: {
      mask: '',
      code: 'RW',
      country: 'Rwanda',
      label: '',
      phone: '250',
      option: '(+250) Rwanda [RW]',
      value: '(+250) Rwanda',
    },
    SA: {
      mask: '99999',
      code: 'SA',
      country: 'Saudi Arabia',
      label: 'Postal code',
      phone: '966',
      option: '(+966) Saudi Arabia [SA]',
      value: '(+966) Saudi Arabia',
    },
    SB: {
      mask: '',
      code: 'SB',
      country: 'Solomon Islands',
      label: '',
      phone: '677',
      option: '(+677) Solomon Islands [SB]',
      value: '(+677) Solomon Islands',
    },
    SC: {
      mask: '',
      code: 'SC',
      country: 'Seychelles',
      label: '',
      phone: '248',
      option: '(+248) Seychelles [SC]',
      value: '(+248) Seychelles',
    },
    SD: {
      mask: '99999',
      code: 'SD',
      country: 'Sudan',
      label: 'Postal code',
      phone: '249',
      option: '(+249) Sudan [SD]',
      value: '(+249) Sudan',
    },
    SE: {
      mask: '99999',
      code: 'SE',
      country: 'Sweden',
      label: 'Postal code',
      phone: '46',
      option: '(+46) Sweden [SE]',
      value: '(+46) Sweden',
    },
    SG: {
      mask: '999999',
      code: 'SG',
      country: 'Singapore',
      label: 'Postal code',
      phone: '65',
      option: '(+65) Singapore [SG]',
      value: '(+65) Singapore',
    },
    SH: {
      mask: 'TDCU 1ZZ',
      code: 'SH',
      country: 'Saint Helena',
      label: 'Postcode',
      phone: '290',
      option: '(+290) Saint Helena [SH]',
      value: '(+290) Saint Helena',
    },
    SI: {
      mask: '9999',
      code: 'SI',
      country: 'Slovenia',
      label: 'Postal code',
      phone: '386',
      option: '(+386) Slovenia [SI]',
      value: '(+386) Slovenia',
    },
    SJ: {
      mask: '9999',
      code: 'SJ',
      country: 'Svalbard and Jan Mayen',
      label: 'Postal code',
      phone: '47',
      option: '(+47) Svalbard and Jan Mayen [SJ]',
      value: '(+47) Svalbard and Jan Mayen',
    },
    SK: {
      mask: '99999',
      code: 'SK',
      country: 'Slovakia',
      label: 'PSÄŒ',
      phone: '421',
      option: '(+421) Slovakia [SK]',
      value: '(+421) Slovakia',
    },
    SL: {
      mask: '',
      code: 'SL',
      country: 'Sierra Leone',
      label: '',
      phone: '232',
      option: '(+232) Sierra Leone [SL]',
      value: '(+232) Sierra Leone',
    },
    SM: {
      mask: '99999',
      code: 'SM',
      country: 'San Marino',
      label: 'CPI',
      phone: '378',
      option: '(+378) San Marino [SM]',
      value: '(+378) San Marino',
    },
    SN: {
      mask: '99999',
      code: 'SN',
      country: 'Senegal',
      label: 'Code postal',
      phone: '221',
      option: '(+221) Senegal [SN]',
      value: '(+221) Senegal',
    },
    SO: {
      mask: '',
      code: 'SO',
      country: 'Somalia',
      label: '',
      phone: '252',
      option: '(+252) Somalia [SO]',
      value: '(+252) Somalia',
    },
    SR: {
      mask: '',
      code: 'SR',
      country: 'Suriname',
      label: '',
      phone: '597',
      option: '(+597) Suriname [SR]',
      value: '(+597) Suriname',
    },
    SS: {
      mask: '99999',
      code: 'SS',
      country: 'South Sudan',
      label: 'Postal code',
      phone: '211',
      option: '(+211) South Sudan [SS]',
      value: '(+211) South Sudan',
    },
    ST: {
      mask: '',
      code: 'ST',
      country: 'Sao Tome and Principe',
      label: '',
      phone: '239',
      option: '(+239) Sao Tome and Principe [ST]',
      value: '(+239) Sao Tome and Principe',
    },
    SV: {
      mask: '9999',
      code: 'SV',
      country: 'El Salvador',
      label: 'Código postal',
      phone: '503',
      option: '(+503) El Salvador [SV]',
      value: '(+503) El Salvador',
    },
    SX: {
      mask: '',
      code: 'SX',
      country: 'Sint Maarten (Dutch part)',
      label: '',
      phone: '1-721',
      option: '(+1-721) Sint Maarten (Dutch part) [SX]',
      value: '(+1-721) Sint Maarten (Dutch part)',
    },
    SY: {
      mask: '',
      code: 'SY',
      country: 'Syrian Arab Republic',
      label: '',
      phone: '963',
      option: '(+963) Syrian Arab Republic [SY]',
      value: '(+963) Syrian Arab Republic',
    },
    SZ: {
      mask: 'A999',
      code: 'SZ',
      country: 'Swaziland',
      label: 'Postal code',
      phone: '268',
      option: '(+268) Swaziland [SZ]',
      value: '(+268) Swaziland',
    },
    TC: {
      mask: 'TKCA 1ZZ',
      code: 'TC',
      country: 'Turks and Caicos Islands',
      label: 'Postcode',
      phone: '1-649',
      option: '(+1-649) Turks and Caicos Islands [TC]',
      value: '(+1-649) Turks and Caicos Islands',
    },
    TD: {
      mask: '99999',
      code: 'TD',
      country: 'Chad',
      label: 'Code postal',
      phone: '235',
      option: '(+235) Chad [TD]',
      value: '(+235) Chad',
    },
    TF: {
      mask: '',
      code: 'TF',
      country: 'French Southern Territories',
      label: '',
      phone: '262',
      option: '(+262) French Southern Territories [TF]',
      value: '(+262) French Southern Territories',
    },
    TG: {
      mask: '',
      code: 'TG',
      country: 'Togo',
      label: '',
      phone: '228',
      option: '(+228) Togo [TG]',
      value: '(+228) Togo',
    },
    TH: {
      mask: '99999',
      code: 'TH',
      country: 'Thailand',
      label: 'Postal code',
      phone: '66',
      option: '(+66) Thailand [TH]',
      value: '(+66) Thailand',
    },
    TJ: {
      mask: '999999',
      code: 'TJ',
      country: 'Tajikistan',
      label: 'Postal code',
      phone: '992',
      option: '(+992) Tajikistan [TJ]',
      value: '(+992) Tajikistan',
    },
    TK: {
      mask: '',
      code: 'TK',
      country: 'Tokelau',
      label: '',
      phone: '690',
      option: '(+690) Tokelau [TK]',
      value: '(+690) Tokelau',
    },
    TL: {
      mask: '',
      code: 'TL',
      country: 'Timor-Leste',
      label: '',
      phone: '670',
      option: '(+670) Timor-Leste [TL]',
      value: '(+670) Timor-Leste',
    },
    TM: {
      mask: '999999',
      code: 'TM',
      country: 'Turkmenistan',
      label: 'Postal code',
      phone: '993',
      option: '(+993) Turkmenistan [TM]',
      value: '(+993) Turkmenistan',
    },
    TN: {
      mask: '9999',
      code: 'TN',
      country: 'Tunisia',
      label: 'Code postal',
      phone: '216',
      option: '(+216) Tunisia [TN]',
      value: '(+216) Tunisia',
    },
    TO: {
      mask: '',
      code: 'TO',
      country: 'Tonga',
      label: '',
      phone: '676',
      option: '(+676) Tonga [TO]',
      value: '(+676) Tonga',
    },
    TR: {
      mask: '99999',
      code: 'TR',
      country: 'Turkey',
      label: 'Postal code',
      phone: '90',
      option: '(+90) Turkey [TR]',
      value: '(+90) Turkey',
    },
    TT: {
      mask: '999999',
      code: 'TT',
      country: 'Trinidad and Tobago',
      label: 'Postal code',
      phone: '1-868',
      option: '(+1-868) Trinidad and Tobago [TT]',
      value: '(+1-868) Trinidad and Tobago',
    },
    TV: {
      mask: '',
      code: 'TV',
      country: 'Tuvalu',
      label: '',
      phone: '688',
      option: '(+688) Tuvalu [TV]',
      value: '(+688) Tuvalu',
    },
    TW: {
      mask: '99999',
      code: 'TW',
      country: 'Taiwan, Province of China',
      label: 'Postal code',
      phone: '886',
      option: '(+886) Taiwan, Province of China [TW]',
      value: '(+886) Taiwan, Province of China',
    },
    TZ: {
      mask: '',
      code: 'TZ',
      country: 'United Republic of Tanzania',
      label: '',
      phone: '255',
      option: '(+255) United Republic of Tanzania [TZ]',
      value: '(+255) United Republic of Tanzania',
    },
    UA: {
      mask: '99999',
      code: 'UA',
      country: 'Ukraine',
      label: 'Postal code',
      phone: '380',
      option: '(+380) Ukraine [UA]',
      value: '(+380) Ukraine',
    },
    UG: {
      mask: '',
      code: 'UG',
      country: 'Uganda',
      label: '',
      phone: '256',
      option: '(+256) Uganda [UG]',
      value: '(+256) Uganda',
    },
    US: {
      mask: '99999',
      code: 'US',
      country: 'United States',
      label: 'ZIP codes',
      phone: '1',
      option: '(+1) United States [US]',
      value: '(+1) United States',
    },
    UY: {
      mask: '99999',
      code: 'UY',
      country: 'Uruguay',
      label: 'Código postal',
      phone: '598',
      option: '(+598) Uruguay [UY]',
      value: '(+598) Uruguay',
    },
    UZ: {
      mask: '999999',
      code: 'UZ',
      country: 'Uzbekistan',
      label: 'Postal code',
      phone: '998',
      option: '(+998) Uzbekistan [UZ]',
      value: '(+998) Uzbekistan',
    },
    VA: {
      mask: '00120',
      code: 'VA',
      country: 'Holy See (Vatican City State)',
      label: 'CAP',
      phone: '379',
      option: '(+379) Holy See (Vatican City State) [VA]',
      value: '(+379) Holy See (Vatican City State)',
    },
    VC: {
      mask: 'VC9999',
      code: 'VC',
      country: 'Saint Vincent and the Grenadines',
      label: 'Postal code',
      phone: '1-784',
      option: '(+1-784) Saint Vincent and the Grenadines [VC]',
      value: '(+1-784) Saint Vincent and the Grenadines',
    },
    VE: {
      mask: '9999',
      code: 'VE',
      country: 'Venezuela',
      label: 'Código postal',
      phone: '58',
      option: '(+58) Venezuela [VE]',
      value: '(+58) Venezuela',
    },
    VG: {
      mask: 'VG9999',
      code: 'VG',
      country: 'British Virgin Islands',
      label: 'Postal code',
      phone: '1-284',
      option: '(+1-284) British Virgin Islands [VG]',
      value: '(+1-284) British Virgin Islands',
    },
    VI: {
      mask: '99999',
      code: 'VI',
      country: 'US Virgin Islands',
      label: 'ZIP codes',
      phone: '1-340',
      option: '(+1-340) US Virgin Islands [VI]',
      value: '(+1-340) US Virgin Islands',
    },
    VN: {
      mask: '999999',
      code: 'VN',
      country: 'Vietnam',
      label: 'Postal code',
      phone: '84',
      option: '(+84) Vietnam [VN]',
      value: '(+84) Vietnam',
    },
    VU: {
      mask: '',
      code: 'VU',
      country: 'Vanuatu',
      label: '',
      phone: '678',
      option: '(+678) Vanuatu [VU]',
      value: '(+678) Vanuatu',
    },
    WF: {
      mask: '99999',
      code: 'WF',
      country: 'Wallis and Futuna',
      label: 'Code postal',
      phone: '681',
      option: '(+681) Wallis and Futuna [WF]',
      value: '(+681) Wallis and Futuna',
    },
    WS: {
      mask: '',
      code: 'WS',
      country: 'Samoa',
      label: '',
      phone: '685',
      option: '(+685) Samoa [WS]',
      value: '(+685) Samoa',
    },
    XK: {
      mask: '',
      code: 'XK',
      country: 'Kosovo',
      label: '',
      phone: '383',
      option: '(+383) Kosovo [XK]',
      value: '(+383) Kosovo',
    },
    YE: {
      mask: '',
      code: 'YE',
      country: 'Yemen',
      label: '',
      phone: '967',
      option: '(+967) Yemen [YE]',
      value: '(+967) Yemen',
    },
    // YT: {
    //   mask: '99999',
    //   code: 'YT',
    //   country: 'Mayotte',
    //   label: 'Code postal',
    //   phone: '262',
    //   option: '(+262) Mayotte [YT]',
    //   value: '(+262) Mayotte',
    // },
    ZA: {
      mask: '9999',
      code: 'ZA',
      country: 'South Africa',
      label: 'Postal code',
      phone: '27',
      option: '(+27) South Africa [ZA]',
      value: '(+27) South Africa',
    },
    ZM: {
      mask: '99999',
      code: 'ZM',
      country: 'Zambia',
      label: 'Postal code',
      phone: '260',
      option: '(+260) Zambia [ZM]',
      value: '(+260) Zambia',
    },
    ZW: {
      mask: '',
      code: 'ZW',
      country: 'Zimbabwe',
      label: '',
      phone: '263',
      option: '(+263) Zimbabwe [ZW]',
      value: '(+263) Zimbabwe',
    },
  },
  pt: {
    AD: {
      mask: 'AD999',
      code: 'AD',
      country: 'Andorra',
      label: 'Postal code',
      phone: '376',
      option: '(+376) Andorra [AD]',
      value: '(+376) Andorra',
    },
    AE: {
      mask: '',
      code: 'AE',
      country: 'Emirados Árabes Unidos',
      label: '',
      phone: '971',
      option: '(+971) Emirados Árabes Unidos [AE]',
      value: '(+971) Emirados Árabes Unidos',
    },
    AF: {
      mask: '9999',
      code: 'AF',
      country: 'Afeganistão',
      label: 'Postal code',
      phone: '93',
      option: '(+93) Afeganistão [AF]',
      value: '(+93) Afeganistão',
    },
    AG: {
      mask: '',
      code: 'AG',
      country: 'Antígua e Barbuda',
      label: '',
      phone: '1-268',
      option: '(+1-268) Antígua e Barbuda [AG]',
      value: '(+1-268) Antígua e Barbuda',
    },
    AI: {
      mask: 'AI-9999',
      code: 'AI',
      country: 'Anguila',
      label: 'Postcode',
      phone: '1-264',
      option: '(+1-264) Anguila [AI]',
      value: '(+1-264) Anguila',
    },
    AL: {
      mask: '9999',
      code: 'AL',
      country: 'Albânia',
      label: 'Postal code',
      phone: '355',
      option: '(+355) Albânia [AL]',
      value: '(+355) Albânia',
    },
    AM: {
      mask: '9999',
      code: 'AM',
      country: 'Armênia',
      label: 'Postal code',
      phone: '374',
      option: '(+374) Armênia [AM]',
      value: '(+374) Armênia',
    },
    AO: {
      mask: '',
      code: 'AO',
      country: 'Angola',
      label: '',
      phone: '244',
      option: '(+244) Angola [AO]',
      value: '(+244) Angola',
    },
    AQ: {
      mask: '',
      code: 'AQ',
      country: 'Antarctica',
      label: '',
      phone: '672',
      option: '(+672) Antarctica [AQ]',
      value: '(+672) Antarctica',
    },
    AR: {
      mask: 'A9999AAA',
      code: 'AR',
      country: 'Argentina',
      label: 'CPA',
      phone: '54',
      option: '(+54) Argentina [AR]',
      value: '(+54) Argentina',
    },
    AS: {
      mask: '99999',
      code: 'AS',
      country: 'Samoa Americana',
      label: 'ZIP codes',
      phone: '1-684',
      option: '(+1-684) Samoa Americana [AS]',
      value: '(+1-684) Samoa Americana',
    },
    AT: {
      mask: '9999',
      code: 'AT',
      country: 'Áustria',
      label: 'PLZ',
      phone: '43',
      option: '(+43) Áustria [AT]',
      value: '(+43) Áustria',
    },
    AU: {
      mask: '9999',
      code: 'AU',
      country: 'Austrália',
      label: 'Postal code',
      phone: '61',
      option: '(+61) Austrália [AU]',
      value: '(+61) Austrália',
    },
    AW: {
      mask: '',
      code: 'AW',
      country: 'Aruba',
      label: '',
      phone: '297',
      option: '(+297) Aruba [AW]',
      value: '(+297) Aruba',
    },
    AX: {
      mask: 'AX-99999',
      code: 'AX',
      country: 'Ilhas Allland',
      label: 'Postnummer',
      phone: '358',
      option: '(+358) Ilhas Allland [AX]',
      value: '(+358) Ilhas Allland',
    },
    AZ: {
      mask: 'AZ9999',
      code: 'AZ',
      country: 'Azerbaijão',
      label: 'Ä°ndeks',
      phone: '994',
      option: '(+994) Azerbaijão [AZ]',
      value: '(+994) Azerbaijão',
    },
    BA: {
      mask: '99999',
      code: 'BA',
      country: 'Bósnia e Herzegovina',
      label: 'Postal code',
      phone: '387',
      option: '(+387) Bósnia e Herzegovina [BA]',
      value: '(+387) Bósnia e Herzegovina',
    },
    BB: {
      mask: 'BB99999',
      code: 'BB',
      country: 'Barbados',
      label: 'Postal code',
      phone: '1-246',
      option: '(+1-246) Barbados [BB]',
      value: '(+1-246) Barbados',
    },
    BD: {
      mask: '9999',
      code: 'BD',
      country: 'Bangladesh',
      label: 'Postal code',
      phone: '880',
      option: '(+880) Bangladesh [BD]',
      value: '(+880) Bangladesh',
    },
    BE: {
      mask: '9999',
      code: 'BE',
      country: 'Bélgica',
      label: 'Code postal / Postcode',
      phone: '32',
      option: '(+32) Bélgica [BE]',
      value: '(+32) Bélgica',
    },
    BF: {
      mask: '',
      code: 'BF',
      country: 'Burkina Faso',
      label: '',
      phone: '226',
      option: '(+226) Burkina Faso [BF]',
      value: '(+226) Burkina Faso',
    },
    BG: {
      mask: '9999',
      code: 'BG',
      country: 'Bulgária',
      label: 'Postal code',
      phone: '359',
      option: '(+359) Bulgária [BG]',
      value: '(+359) Bulgária',
    },
    BH: {
      mask: '[9]999',
      code: 'BH',
      country: 'Bahrein',
      label: 'Postal code',
      phone: '973',
      option: '(+973) Bahrein [BH]',
      value: '(+973) Bahrein',
    },
    BI: {
      mask: '',
      code: 'BI',
      country: 'Burundi',
      label: '',
      phone: '257',
      option: '(+257) Burundi [BI]',
      value: '(+257) Burundi',
    },
    BJ: {
      mask: '',
      code: 'BJ',
      country: 'Benin',
      label: '',
      phone: '229',
      option: '(+229) Benin [BJ]',
      value: '(+229) Benin',
    },
    BL: {
      mask: '99999',
      code: 'BL',
      country: 'São Bartolomeu',
      label: 'Code postal',
      phone: '590',
      option: '(+590) São Bartolomeu [BL]',
      value: '(+590) São Bartolomeu',
    },
    BM: {
      mask: 'AA99',
      code: 'BM',
      country: 'Bermudas',
      label: 'Postcode',
      phone: '1-441',
      option: '(+1-441) Bermudas [BM]',
      value: '(+1-441) Bermudas',
    },
    BN: {
      mask: 'AA9999',
      code: 'BN',
      country: 'Brunei Darussalam',
      label: 'Postal code',
      phone: '673',
      option: '(+673) Brunei Darussalam [BN]',
      value: '(+673) Brunei Darussalam',
    },
    BO: {
      mask: '9999',
      code: 'BO',
      country: 'Bolívia',
      label: 'Código postal',
      phone: '591',
      option: '(+591) Bolívia [BO]',
      value: '(+591) Bolívia',
    },
    BR: {
      mask: '99999-999',
      code: 'BR',
      country: 'Brasil',
      label: 'CEP',
      phone: '55',
      option: '(+55) Brasil [BR]',
      value: '(+55) Brasil',
    },
    BS: {
      mask: '',
      code: 'BS',
      country: 'Bahamas',
      label: '',
      phone: '1-242',
      option: '(+1-242) Bahamas [BS]',
      value: '(+1-242) Bahamas',
    },
    BT: {
      mask: '999',
      code: 'BT',
      country: 'Butão',
      label: 'Postal code',
      phone: '975',
      option: '(+975) Butão [BT]',
      value: '(+975) Butão',
    },
    BV: {
      mask: '',
      code: 'BV',
      country: 'Ilha Bouvet',
      label: '',
      phone: '47',
      option: '(+47) Ilha Bouvet [BV]',
      value: '(+47) Ilha Bouvet',
    },
    BW: {
      mask: '',
      code: 'BW',
      country: 'Botsuana',
      label: '',
      phone: '267',
      option: '(+267) Botsuana [BW]',
      value: '(+267) Botsuana',
    },
    BY: {
      mask: '299999',
      code: 'BY',
      country: 'Bielorrússia',
      label: 'Postal code',
      phone: '375',
      option: '(+375) Bielorrússia [BY]',
      value: '(+375) Bielorrússia',
    },
    BZ: {
      mask: '',
      code: 'BZ',
      country: 'Belize',
      label: '',
      phone: '501',
      option: '(+501) Belize [BZ]',
      value: '(+501) Belize',
    },
    CA: {
      mask: 'A9A9A9',
      code: 'CA',
      country: 'Canadá',
      label: 'Postal code / Code postal',
      phone: '1',
      option: '(+1) Canadá [CA]',
      value: '(+1) Canadá',
    },
    CC: {
      mask: '9999',
      code: 'CC',
      country: 'Cocos (Keeling) Islands',
      label: 'Postal code',
      phone: '61',
      option: '(+61) Cocos (Keeling) Islands [CC]',
      value: '(+61) Cocos (Keeling) Islands',
    },
    CD: {
      mask: '',
      code: 'CD',
      country: 'Congo, República Democrática do',
      label: '',
      phone: '243',
      option: '(+243) Congo, República Democrática do [CD]',
      value: '(+243) Congo, República Democrática do',
    },
    CF: {
      mask: '',
      code: 'CF',
      country: 'República Centro-Africana',
      label: '',
      phone: '236',
      option: '(+236) República Centro-Africana [CF]',
      value: '(+236) República Centro-Africana',
    },
    CG: {
      mask: '',
      code: 'CG',
      country: 'Congo, República do',
      label: '',
      phone: '242',
      option: '(+242) Congo, República do [CG]',
      value: '(+242) Congo, República do',
    },
    CH: {
      mask: '9999',
      code: 'CH',
      country: 'Suíça',
      label: 'NPA',
      phone: '41',
      option: '(+41) Suíça [CH]',
      value: '(+41) Suíça',
    },
    CI: {
      mask: '',
      code: 'CI',
      country: "Cote d'Ivoire",
      label: '',
      phone: '225',
      option: "(+225) Cote d'Ivoire [CI]",
      value: "(+225) Cote d'Ivoire",
    },
    CK: {
      mask: '',
      code: 'CK',
      country: 'Ilhas Cook',
      label: '',
      phone: '682',
      option: '(+682) Ilhas Cook [CK]',
      value: '(+682) Ilhas Cook',
    },
    CL: {
      mask: '999-9999',
      code: 'CL',
      country: 'Chile',
      label: 'Código postal',
      phone: '56',
      option: '(+56) Chile [CL]',
      value: '(+56) Chile',
    },
    CM: {
      mask: '',
      code: 'CM',
      country: 'Camarões',
      label: '',
      phone: '237',
      option: '(+237) Camarões [CM]',
      value: '(+237) Camarões',
    },
    CN: {
      mask: '999999',
      code: 'CN',
      country: 'China',
      label: 'Postal code',
      phone: '86',
      option: '(+86) China [CN]',
      value: '(+86) China',
    },
    CO: {
      mask: '999999',
      code: 'CO',
      country: 'Colômbia',
      label: 'Código postal',
      phone: '57',
      option: '(+57) Colômbia [CO]',
      value: '(+57) Colômbia',
    },
    CR: {
      mask: '99999',
      code: 'CR',
      country: 'Costa Rica',
      label: 'Código postal',
      phone: '506',
      option: '(+506) Costa Rica [CR]',
      value: '(+506) Costa Rica',
    },
    CU: {
      mask: '99999',
      code: 'CU',
      country: 'Cuba',
      label: 'Código postal',
      phone: '53',
      option: '(+53) Cuba [CU]',
      value: '(+53) Cuba',
    },
    CV: {
      mask: '9999',
      code: 'CV',
      country: 'Cabo Verde',
      label: 'Postal code',
      phone: '238',
      option: '(+238) Cabo Verde [CV]',
      value: '(+238) Cabo Verde',
    },
    CW: {
      mask: '',
      code: 'CW',
      country: 'Curaçao',
      label: '',
      phone: '599',
      option: '(+599) Curaçao [CW]',
      value: '(+599) Curaçao',
    },
    CX: {
      mask: '9999',
      code: 'CX',
      country: 'Ilha de Natal',
      label: 'Post Code',
      phone: '61',
      option: '(+61) Ilha de Natal [CX]',
      value: '(+61) Ilha de Natal',
    },
    CY: {
      mask: '9999',
      code: 'CY',
      country: 'Chipre',
      label: 'Postal code',
      phone: '357',
      option: '(+357) Chipre [CY]',
      value: '(+357) Chipre',
    },
    CZ: {
      mask: '99999',
      code: 'CZ',
      country: 'República Tcheca',
      label: 'PSÄŒ',
      phone: '420',
      option: '(+420) República Tcheca [CZ]',
      value: '(+420) República Tcheca',
    },
    DE: {
      mask: '99999',
      code: 'DE',
      country: 'Alemanha',
      label: 'PLZ',
      phone: '49',
      option: '(+49) Alemanha [DE]',
      value: '(+49) Alemanha',
    },
    DJ: {
      mask: '',
      code: 'DJ',
      country: 'Djibuti',
      label: '',
      phone: '253',
      option: '(+253) Djibuti [DJ]',
      value: '(+253) Djibuti',
    },
    DK: {
      mask: '9999',
      code: 'DK',
      country: 'Dinamarca',
      label: 'Postal code',
      phone: '45',
      option: '(+45) Dinamarca [DK]',
      value: '(+45) Dinamarca',
    },
    DM: {
      mask: '',
      code: 'DM',
      country: 'Dominica',
      label: '',
      phone: '1-767',
      option: '(+1-767) Dominica [DM]',
      value: '(+1-767) Dominica',
    },
    DO: {
      mask: '99999',
      code: 'DO',
      country: 'República Dominicana',
      label: 'Código postal',
      phone: '1-809',
      option: '(+1-809) República Dominicana [DO]',
      value: '(+1-809) República Dominicana',
    },
    DZ: {
      mask: '99999',
      code: 'DZ',
      country: 'Argélia',
      label: 'Code postal',
      phone: '213',
      option: '(+213) Argélia [DZ]',
      value: '(+213) Argélia',
    },
    EC: {
      mask: '999999',
      code: 'EC',
      country: 'Equador',
      label: 'Código postal',
      phone: '593',
      option: '(+593) Equador [EC]',
      value: '(+593) Equador',
    },
    EE: {
      mask: '99999',
      code: 'EE',
      country: 'Estônia',
      label: 'Postal code',
      phone: '372',
      option: '(+372) Estônia [EE]',
      value: '(+372) Estônia',
    },
    EG: {
      mask: '99999',
      code: 'EG',
      country: 'Egito',
      label: 'Postal code',
      phone: '20',
      option: '(+20) Egito [EG]',
      value: '(+20) Egito',
    },
    EH: {
      mask: '99999',
      code: 'EH',
      country: 'Saara Ocidental',
      label: 'Postal code',
      phone: '212',
      option: '(+212) Saara Ocidental [EH]',
      value: '(+212) Saara Ocidental',
    },
    ER: {
      mask: '',
      code: 'ER',
      country: 'Eritreia',
      label: '',
      phone: '291',
      option: '(+291) Eritreia [ER]',
      value: '(+291) Eritreia',
    },
    ES: {
      mask: '99999',
      code: 'ES',
      country: 'Espanha',
      label: 'Código postal',
      phone: '34',
      option: '(+34) Espanha [ES]',
      value: '(+34) Espanha',
    },
    ET: {
      mask: '9999',
      code: 'ET',
      country: 'Etiópia',
      label: 'Postal code',
      phone: '251',
      option: '(+251) Etiópia [ET]',
      value: '(+251) Etiópia',
    },
    FI: {
      mask: '99999',
      code: 'FI',
      country: 'Finlândia',
      label: 'Postnummer',
      phone: '358',
      option: '(+358) Finlândia [FI]',
      value: '(+358) Finlândia',
    },
    FJ: {
      mask: '',
      code: 'FJ',
      country: 'Fiji',
      label: '',
      phone: '679',
      option: '(+679) Fiji [FJ]',
      value: '(+679) Fiji',
    },
    FK: {
      mask: 'FIQQ 1ZZ',
      code: 'FK',
      country: 'Ilhas Falkland (Malvinas)',
      label: 'Postcode',
      phone: '500',
      option: '(+500) Ilhas Falkland (Malvinas) [FK]',
      value: '(+500) Ilhas Falkland (Malvinas)',
    },
    FM: {
      mask: '99999',
      code: 'FM',
      country: 'Micronésia, Estados Federados de',
      label: 'ZIP codes',
      phone: '691',
      option: '(+691) Micronésia, Estados Federados de [FM]',
      value: '(+691) Micronésia, Estados Federados de',
    },
    FO: {
      mask: '999',
      code: 'FO',
      country: 'Ilhas Faroé',
      label: 'Postal code',
      phone: '298',
      option: '(+298) Ilhas Faroé [FO]',
      value: '(+298) Ilhas Faroé',
    },
    FR: {
      mask: '99999',
      code: 'FR',
      country: 'França',
      label: 'Code postal',
      phone: '33',
      option: '(+33) França [FR]',
      value: '(+33) França',
    },
    GA: {
      mask: '99 city99',
      code: 'GA',
      country: 'Gabão',
      label: 'Postal code',
      phone: '241',
      option: '(+241) Gabão [GA]',
      value: '(+241) Gabão',
    },
    GB: {
      mask: 'AA99AA',
      code: 'GB',
      country: 'Reino Unido',
      label: 'Postcode',
      phone: '44',
      option: '(+44) Reino Unido [GB]',
      value: '(+44) Reino Unido',
    },
    GD: {
      mask: '',
      code: 'GD',
      country: 'Granada',
      label: '',
      phone: '1-473',
      option: '(+1-473) Granada [GD]',
      value: '(+1-473) Granada',
    },
    GE: {
      mask: '9999',
      code: 'GE',
      country: 'Geórgia',
      label: 'Postal code',
      phone: '995',
      option: '(+995) Geórgia [GE]',
      value: '(+995) Geórgia',
    },
    GF: {
      mask: '99999',
      code: 'GF',
      country: 'Guiana Francesa',
      label: 'Code postal',
      phone: '594',
      option: '(+594) Guiana Francesa [GF]',
      value: '(+594) Guiana Francesa',
    },
    GG: {
      mask: 'GY99AA',
      code: 'GG',
      country: 'Guernsey',
      label: 'Postcode',
      phone: '44',
      option: '(+44) Guernsey [GG]',
      value: '(+44) Guernsey',
    },
    GH: {
      mask: '',
      code: 'GH',
      country: 'Gana',
      label: '',
      phone: '233',
      option: '(+233) Gana [GH]',
      value: '(+233) Gana',
    },
    GI: {
      mask: 'GX11 1AA',
      code: 'GI',
      country: 'Gibraltar',
      label: 'Postcode',
      phone: '350',
      option: '(+350) Gibraltar [GI]',
      value: '(+350) Gibraltar',
    },
    GL: {
      mask: '9999',
      code: 'GL',
      country: 'Gronelândia',
      label: 'Postal code',
      phone: '299',
      option: '(+299) Gronelândia [GL]',
      value: '(+299) Gronelândia',
    },
    GM: {
      mask: '',
      code: 'GM',
      country: 'Gâmbia',
      label: '',
      phone: '220',
      option: '(+220) Gâmbia [GM]',
      value: '(+220) Gâmbia',
    },
    GN: {
      mask: '',
      code: 'GN',
      country: 'Guiné',
      label: '',
      phone: '224',
      option: '(+224) Guiné [GN]',
      value: '(+224) Guiné',
    },
    GP: {
      mask: '99999',
      code: 'GP',
      country: 'Guadalupe',
      label: 'Code postal',
      phone: '590',
      option: '(+590) Guadalupe [GP]',
      value: '(+590) Guadalupe',
    },
    GQ: {
      mask: '',
      code: 'GQ',
      country: 'Guiné Equatorial',
      label: '',
      phone: '240',
      option: '(+240) Guiné Equatorial [GQ]',
      value: '(+240) Guiné Equatorial',
    },
    GR: {
      mask: '99999',
      code: 'GR',
      country: 'Grécia',
      label: 'Postal code',
      phone: '30',
      option: '(+30) Grécia [GR]',
      value: '(+30) Grécia',
    },
    GS: {
      mask: 'SIQQ 1ZZ',
      code: 'GS',
      country: 'Geórgia do Sul e Ilhas Sandwich do Sul',
      label: 'Postcode',
      phone: '500',
      option: '(+500) Geórgia do Sul e Ilhas Sandwich do Sul [GS]',
      value: '(+500) Geórgia do Sul e Ilhas Sandwich do Sul',
    },
    GT: {
      mask: '99999',
      code: 'GT',
      country: 'Guatemala',
      label: 'Código postal',
      phone: '502',
      option: '(+502) Guatemala [GT]',
      value: '(+502) Guatemala',
    },
    GU: {
      mask: '99999',
      code: 'GU',
      country: 'Guam',
      label: 'ZIP codes',
      phone: '1-671',
      option: '(+1-671) Guam [GU]',
      value: '(+1-671) Guam',
    },
    GW: {
      mask: '9999',
      code: 'GW',
      country: 'Guiné-Bissau',
      label: 'Postal code',
      phone: '245',
      option: '(+245) Guiné-Bissau [GW]',
      value: '(+245) Guiné-Bissau',
    },
    GY: {
      mask: '',
      code: 'GY',
      country: 'Guiana',
      label: '',
      phone: '592',
      option: '(+592) Guiana [GY]',
      value: '(+592) Guiana',
    },
    HK: {
      mask: '',
      code: 'HK',
      country: 'Hong Kong',
      label: '',
      phone: '852',
      option: '(+852) Hong Kong [HK]',
      value: '(+852) Hong Kong',
    },
    HM: {
      mask: '9999',
      code: 'HM',
      country: 'Heard Island e McDonald Islands',
      label: 'Postal code',
      phone: '672',
      option: '(+672) Heard Island e McDonald Islands [HM]',
      value: '(+672) Heard Island e McDonald Islands',
    },
    HN: {
      mask: '99999',
      code: 'HN',
      country: 'Honduras',
      label: 'Código postal',
      phone: '504',
      option: '(+504) Honduras [HN]',
      value: '(+504) Honduras',
    },
    HR: {
      mask: '99999',
      code: 'HR',
      country: 'Croácia',
      label: 'Postal code',
      phone: '385',
      option: '(+385) Croácia [HR]',
      value: '(+385) Croácia',
    },
    HT: {
      mask: '9999',
      code: 'HT',
      country: 'Haiti',
      label: 'Code postal',
      phone: '509',
      option: '(+509) Haiti [HT]',
      value: '(+509) Haiti',
    },
    HU: {
      mask: '9999',
      code: 'HU',
      country: 'Hungria',
      label: 'Postal code',
      phone: '36',
      option: '(+36) Hungria [HU]',
      value: '(+36) Hungria',
    },
    ID: {
      mask: '99999',
      code: 'ID',
      country: 'Indonésia',
      label: 'Postal code',
      phone: '62',
      option: '(+62) Indonésia [ID]',
      value: '(+62) Indonésia',
    },
    IE: {
      mask: 'D99',
      code: 'IE',
      country: 'Irlanda',
      label: 'Postcode',
      phone: '353',
      option: '(+353) Irlanda [IE]',
      value: '(+353) Irlanda',
    },
    IL: {
      mask: '9999999',
      code: 'IL',
      country: 'Israel',
      label: 'Postal code',
      phone: '972',
      option: '(+972) Israel [IL]',
      value: '(+972) Israel',
    },
    IM: {
      mask: 'IM99AA',
      code: 'IM',
      country: 'Ilha de Man',
      label: 'Postcode',
      phone: '44',
      option: '(+44) Ilha de Man [IM]',
      value: '(+44) Ilha de Man',
    },
    IN: {
      mask: '999999',
      code: 'IN',
      country: 'Índia',
      label: 'PIN code',
      phone: '91',
      option: '(+91) Índia [IN]',
      value: '(+91) Índia',
    },
    IO: {
      mask: 'BB9D 1ZZ',
      code: 'IO',
      country: 'Território Britânico do Oceano Índico',
      label: 'Postcode',
      phone: '246',
      option: '(+246) Território Britânico do Oceano Índico [IO]',
      value: '(+246) Território Britânico do Oceano Índico',
    },
    IQ: {
      mask: '99999',
      code: 'IQ',
      country: 'Iraque',
      label: 'Postal code',
      phone: '964',
      option: '(+964) Iraque [IQ]',
      value: '(+964) Iraque',
    },
    IR: {
      mask: '99999-99999',
      code: 'IR',
      country: 'Irã, República Islâmica do',
      label: 'Postal code',
      phone: '98',
      option: '(+98) Irã, República Islâmica do [IR]',
      value: '(+98) Irã, República Islâmica do',
    },
    IS: {
      mask: '999',
      code: 'IS',
      country: 'Islândia',
      label: 'Postal code',
      phone: '354',
      option: '(+354) Islândia [IS]',
      value: '(+354) Islândia',
    },
    IT: {
      mask: '99999',
      code: 'IT',
      country: 'Itália',
      label: 'CAP',
      phone: '39',
      option: '(+39) Itália [IT]',
      value: '(+39) Itália',
    },
    JE: {
      mask: 'JE99AA',
      code: 'JE',
      country: 'Jersey',
      label: 'Postcode',
      phone: '44',
      option: '(+44) Jersey [JE]',
      value: '(+44) Jersey',
    },
    JM: {
      mask: 'JMAAA99',
      code: 'JM',
      country: 'Jamaica',
      label: 'Postal code',
      phone: '1-876',
      option: '(+1-876) Jamaica [JM]',
      value: '(+1-876) Jamaica',
    },
    JO: {
      mask: '99999',
      code: 'JO',
      country: 'Jordão',
      label: 'Postal code',
      phone: '962',
      option: '(+962) Jordão [JO]',
      value: '(+962) Jordão',
    },
    JP: {
      mask: '999-9999',
      code: 'JP',
      country: 'Japão',
      label: 'éƒµä¾¿ç•ªå·',
      phone: '81',
      option: '(+81) Japão [JP]',
      value: '(+81) Japão',
    },
    KE: {
      mask: '99999',
      code: 'KE',
      country: 'Quênia',
      label: 'Postal code',
      phone: '254',
      option: '(+254) Quênia [KE]',
      value: '(+254) Quênia',
    },
    KG: {
      mask: '999999',
      code: 'KG',
      country: 'Quirguistão',
      label: 'Postal code',
      phone: '996',
      option: '(+996) Quirguistão [KG]',
      value: '(+996) Quirguistão',
    },
    KH: {
      mask: '99999',
      code: 'KH',
      country: 'Camboja',
      label: 'Postal code',
      phone: '855',
      option: '(+855) Camboja [KH]',
      value: '(+855) Camboja',
    },
    KI: {
      mask: '',
      code: 'KI',
      country: 'Kiribati',
      label: '',
      phone: '686',
      option: '(+686) Kiribati [KI]',
      value: '(+686) Kiribati',
    },
    KM: {
      mask: '',
      code: 'KM',
      country: 'Comores',
      label: '',
      phone: '269',
      option: '(+269) Comores [KM]',
      value: '(+269) Comores',
    },
    KN: {
      mask: '',
      code: 'KN',
      country: 'São Cristóvão e Nevis',
      label: '',
      phone: '1-869',
      option: '(+1-869) São Cristóvão e Nevis [KN]',
      value: '(+1-869) São Cristóvão e Nevis',
    },
    KP: {
      mask: '',
      code: 'KP',
      country: 'Coreia, República Popular Democrática da',
      label: '',
      phone: '850',
      option: '(+850) Coreia, República Popular Democrática da [KP]',
      value: '(+850) Coreia, República Popular Democrática da',
    },
    KR: {
      mask: '999-999',
      code: 'KR',
      country: 'Coreia, República de',
      label: 'Postal code',
      phone: '82',
      option: '(+82) Coreia, República de [KR]',
      value: '(+82) Coreia, República de',
    },
    KW: {
      mask: '99999',
      code: 'KW',
      country: 'Kuwait',
      label: 'Postal code',
      phone: '965',
      option: '(+965) Kuwait [KW]',
      value: '(+965) Kuwait',
    },
    KY: {
      mask: 'KY9-9999',
      code: 'KY',
      country: 'Ilhas Cayman',
      label: 'Postal code',
      phone: '1-345',
      option: '(+1-345) Ilhas Cayman [KY]',
      value: '(+1-345) Ilhas Cayman',
    },
    KZ: {
      mask: '999999',
      code: 'KZ',
      country: 'Cazaquistão',
      label: 'Postal code',
      phone: '7',
      option: '(+7) Cazaquistão [KZ]',
      value: '(+7) Cazaquistão',
    },
    LA: {
      mask: '99999',
      code: 'LA',
      country: 'República Democrática Popular do Laos',
      label: 'Postal code',
      phone: '856',
      option: '(+856) República Democrática Popular do Laos [LA]',
      value: '(+856) República Democrática Popular do Laos',
    },
    LB: {
      mask: '99999999',
      code: 'LB',
      country: 'Líbano',
      label: 'Postal code',
      phone: '961',
      option: '(+961) Líbano [LB]',
      value: '(+961) Líbano',
    },
    LC: {
      mask: '',
      code: 'LC',
      country: 'Santa Lúcia',
      label: '',
      phone: '1-758',
      option: '(+1-758) Santa Lúcia [LC]',
      value: '(+1-758) Santa Lúcia',
    },
    LI: {
      mask: '9999',
      code: 'LI',
      country: 'Liechtenstein',
      label: 'PLZ',
      phone: '423',
      option: '(+423) Liechtenstein [LI]',
      value: '(+423) Liechtenstein',
    },
    LK: {
      mask: '99999',
      code: 'LK',
      country: 'Sri Lanka',
      label: 'Postal code',
      phone: '94',
      option: '(+94) Sri Lanka [LK]',
      value: '(+94) Sri Lanka',
    },
    LR: {
      mask: '9999',
      code: 'LR',
      country: 'Libéria',
      label: 'Postal code',
      phone: '231',
      option: '(+231) Libéria [LR]',
      value: '(+231) Libéria',
    },
    LS: {
      mask: '999',
      code: 'LS',
      country: 'Lesoto',
      label: 'Postal code',
      phone: '266',
      option: '(+266) Lesoto [LS]',
      value: '(+266) Lesoto',
    },
    LT: {
      mask: '99999',
      code: 'LT',
      country: 'Lituânia',
      label: 'Postal code',
      phone: '370',
      option: '(+370) Lituânia [LT]',
      value: '(+370) Lituânia',
    },
    LU: {
      mask: '9999',
      code: 'LU',
      country: 'Luxemburgo',
      label: 'Code postal',
      phone: '352',
      option: '(+352) Luxemburgo [LU]',
      value: '(+352) Luxemburgo',
    },
    LV: {
      mask: 'LV-9999',
      code: 'LV',
      country: 'Letônia',
      label: 'Postal code',
      phone: '371',
      option: '(+371) Letônia [LV]',
      value: '(+371) Letônia',
    },
    LY: {
      mask: '99999',
      code: 'LY',
      country: 'Líbia',
      label: 'Postal code',
      phone: '218',
      option: '(+218) Líbia [LY]',
      value: '(+218) Líbia',
    },
    MA: {
      mask: '99999',
      code: 'MA',
      country: 'Marrocos',
      label: 'Code postal',
      phone: '212',
      option: '(+212) Marrocos [MA]',
      value: '(+212) Marrocos',
    },
    MC: {
      mask: '98000',
      code: 'MC',
      country: 'Mônaco',
      label: 'Code postal',
      phone: '377',
      option: '(+377) Mônaco [MC]',
      value: '(+377) Mônaco',
    },
    MD: {
      mask: '9999',
      code: 'MD',
      country: 'Moldávia, República da',
      label: 'Postal code',
      phone: '373',
      option: '(+373) Moldávia, República da [MD]',
      value: '(+373) Moldávia, República da',
    },
    ME: {
      mask: '99999',
      code: 'ME',
      country: 'Montenegro',
      label: 'Postal code',
      phone: '382',
      option: '(+382) Montenegro [ME]',
      value: '(+382) Montenegro',
    },
    MF: {
      mask: '99999',
      code: 'MF',
      country: 'Saint Martin (parte francesa)',
      label: 'Code postal',
      phone: '590',
      option: '(+590) Saint Martin (parte francesa) [MF]',
      value: '(+590) Saint Martin (parte francesa)',
    },
    MG: {
      mask: '999',
      code: 'MG',
      country: 'Madagascar',
      label: 'Code postal',
      phone: '261',
      option: '(+261) Madagascar [MG]',
      value: '(+261) Madagascar',
    },
    MH: {
      mask: '99999',
      code: 'MH',
      country: 'Ilhas Marshall',
      label: 'ZIP codes',
      phone: '692',
      option: '(+692) Ilhas Marshall [MH]',
      value: '(+692) Ilhas Marshall',
    },
    MK: {
      mask: '9999',
      code: 'MK',
      country: 'Macedónia, Antiga República Jugoslava da',
      label: 'Postal code',
      phone: '389',
      option: '(+389) Macedónia, Antiga República Jugoslava da [MK]',
      value: '(+389) Macedónia, Antiga República Jugoslava da',
    },
    ML: {
      mask: '',
      code: 'ML',
      country: 'Mali',
      label: '',
      phone: '223',
      option: '(+223) Mali [ML]',
      value: '(+223) Mali',
    },
    MM: {
      mask: '99999',
      code: 'MM',
      country: 'Myanmar',
      label: 'Postal code',
      phone: '95',
      option: '(+95) Myanmar [MM]',
      value: '(+95) Myanmar',
    },
    MN: {
      mask: '999999',
      code: 'MN',
      country: 'Mongólia',
      label: 'Postal code',
      phone: '976',
      option: '(+976) Mongólia [MN]',
      value: '(+976) Mongólia',
    },
    MO: {
      mask: '',
      code: 'MO',
      country: 'Macau',
      label: '',
      phone: '853',
      option: '(+853) Macau [MO]',
      value: '(+853) Macau',
    },
    MP: {
      mask: '99999',
      code: 'MP',
      country: 'Ilhas Marianas do Norte',
      label: 'ZIP codes',
      phone: '1-670',
      option: '(+1-670) Ilhas Marianas do Norte [MP]',
      value: '(+1-670) Ilhas Marianas do Norte',
    },
    MQ: {
      mask: '99999',
      code: 'MQ',
      country: 'Martinica',
      label: 'Code postal',
      phone: '596',
      option: '(+596) Martinica [MQ]',
      value: '(+596) Martinica',
    },
    MR: {
      mask: '',
      code: 'MR',
      country: 'Mauritânia',
      label: '',
      phone: '222',
      option: '(+222) Mauritânia [MR]',
      value: '(+222) Mauritânia',
    },
    MS: {
      mask: '',
      code: 'MS',
      country: 'Montserrat',
      label: '',
      phone: '1-664',
      option: '(+1-664) Montserrat [MS]',
      value: '(+1-664) Montserrat',
    },
    MT: {
      mask: 'AAA9999',
      code: 'MT',
      country: 'Malta',
      label: 'Postal code',
      phone: '356',
      option: '(+356) Malta [MT]',
      value: '(+356) Malta',
    },
    MU: {
      mask: '',
      code: 'MU',
      country: 'Maurício',
      label: '',
      phone: '230',
      option: '(+230) Maurício [MU]',
      value: '(+230) Maurício',
    },
    MV: {
      mask: '99-99',
      code: 'MV',
      country: 'Maldivas',
      label: 'Postal code',
      phone: '960',
      option: '(+960) Maldivas [MV]',
      value: '(+960) Maldivas',
    },
    MW: {
      mask: '',
      code: 'MW',
      country: 'Malawi',
      label: '',
      phone: '265',
      option: '(+265) Malawi [MW]',
      value: '(+265) Malawi',
    },
    MX: {
      mask: '99999',
      code: 'MX',
      country: 'México',
      label: 'Código postal',
      phone: '52',
      option: '(+52) México [MX]',
      value: '(+52) México',
    },
    MY: {
      mask: '99999',
      code: 'MY',
      country: 'Malásia',
      label: 'Postal code',
      phone: '60',
      option: '(+60) Malásia [MY]',
      value: '(+60) Malásia',
    },
    MZ: {
      mask: '9999',
      code: 'MZ',
      country: 'Moçambique',
      label: 'Postal code',
      phone: '258',
      option: '(+258) Moçambique [MZ]',
      value: '(+258) Moçambique',
    },
    NA: {
      mask: '99999',
      code: 'NA',
      country: 'Namíbia',
      label: 'Postal code',
      phone: '264',
      option: '(+264) Namíbia [NA]',
      value: '(+264) Namíbia',
    },
    NC: {
      mask: '99999',
      code: 'NC',
      country: 'Nova Caledônia',
      label: 'Code postal',
      phone: '687',
      option: '(+687) Nova Caledônia [NC]',
      value: '(+687) Nova Caledônia',
    },
    NE: {
      mask: '9999',
      code: 'NE',
      country: 'Níger',
      label: 'Code postal',
      phone: '227',
      option: '(+227) Níger [NE]',
      value: '(+227) Níger',
    },
    NF: {
      mask: '9999',
      code: 'NF',
      country: 'Norfolk Island',
      label: 'Postal code',
      phone: '672',
      option: '(+672) Norfolk Island [NF]',
      value: '(+672) Norfolk Island',
    },
    NG: {
      mask: '999999',
      code: 'NG',
      country: 'Nigéria',
      label: 'Postal code',
      phone: '234',
      option: '(+234) Nigéria [NG]',
      value: '(+234) Nigéria',
    },
    NI: {
      mask: '999-999-9',
      code: 'NI',
      country: 'Nicarágua',
      label: 'Código postal',
      phone: '505',
      option: '(+505) Nicarágua [NI]',
      value: '(+505) Nicarágua',
    },
    NL: {
      mask: '9999 AA',
      code: 'NL',
      country: 'Holanda',
      label: 'Postal code',
      phone: '31',
      option: '(+31) Holanda [NL]',
      value: '(+31) Holanda',
    },
    NO: {
      mask: '9999',
      code: 'NO',
      country: 'Noruega',
      label: 'Postal code',
      phone: '47',
      option: '(+47) Noruega [NO]',
      value: '(+47) Noruega',
    },
    NP: {
      mask: '99999',
      code: 'NP',
      country: 'Nepal',
      label: 'Postal code',
      phone: '977',
      option: '(+977) Nepal [NP]',
      value: '(+977) Nepal',
    },
    NR: {
      mask: '',
      code: 'NR',
      country: 'Nauru',
      label: '',
      phone: '674',
      option: '(+674) Nauru [NR]',
      value: '(+674) Nauru',
    },
    NU: {
      mask: '',
      code: 'NU',
      country: 'Niue',
      label: '',
      phone: '683',
      option: '(+683) Niue [NU]',
      value: '(+683) Niue',
    },
    NZ: {
      mask: '9999',
      code: 'NZ',
      country: 'Nova Zelândia',
      label: 'Postal code',
      phone: '64',
      option: '(+64) Nova Zelândia [NZ]',
      value: '(+64) Nova Zelândia',
    },
    OM: {
      mask: '999',
      code: 'OM',
      country: 'Omã',
      label: 'Postal code',
      phone: '968',
      option: '(+968) Omã [OM]',
      value: '(+968) Omã',
    },
    PA: {
      mask: '999999',
      code: 'PA',
      country: 'Panamá',
      label: 'Código postal',
      phone: '507',
      option: '(+507) Panamá [PA]',
      value: '(+507) Panamá',
    },
    PE: {
      mask: '99999',
      code: 'PE',
      country: 'Peru',
      label: 'Código postal',
      phone: '51',
      option: '(+51) Peru [PE]',
      value: '(+51) Peru',
    },
    PF: {
      mask: '99999',
      code: 'PF',
      country: 'Polinésia Francesa',
      label: 'Code postal',
      phone: '689',
      option: '(+689) Polinésia Francesa [PF]',
      value: '(+689) Polinésia Francesa',
    },
    PG: {
      mask: '999',
      code: 'PG',
      country: 'Papua Nova Guiné',
      label: 'Postal code',
      phone: '675',
      option: '(+675) Papua Nova Guiné [PG]',
      value: '(+675) Papua Nova Guiné',
    },
    PH: {
      mask: '9999',
      code: 'PH',
      country: 'Filipinas',
      label: 'Postal code',
      phone: '63',
      option: '(+63) Filipinas [PH]',
      value: '(+63) Filipinas',
    },
    PK: {
      mask: '99999',
      code: 'PK',
      country: 'Paquistão',
      label: 'Postal code',
      phone: '92',
      option: '(+92) Paquistão [PK]',
      value: '(+92) Paquistão',
    },
    PL: {
      mask: '99-999',
      code: 'PL',
      country: 'Polônia',
      label: 'Postal code',
      phone: '48',
      option: '(+48) Polônia [PL]',
      value: '(+48) Polônia',
    },
    PM: {
      mask: '99999',
      code: 'PM',
      country: 'São Pedro e Miquelon',
      label: 'Code postal',
      phone: '508',
      option: '(+508) São Pedro e Miquelon [PM]',
      value: '(+508) São Pedro e Miquelon',
    },
    PN: {
      mask: 'PCR9 1ZZ',
      code: 'PN',
      country: 'Pitcairn',
      label: 'Postcode',
      phone: '870',
      option: '(+870) Pitcairn [PN]',
      value: '(+870) Pitcairn',
    },
    PR: {
      mask: '99999',
      code: 'PR',
      country: 'Porto Rico',
      label: 'ZIP codes',
      phone: '1',
      option: '(+1) Porto Rico [PR]',
      value: '(+1) Porto Rico',
    },
    PS: {
      mask: '',
      code: 'PS',
      country: 'Palestina, Estado de',
      label: '',
      phone: '970',
      option: '(+970) Palestina, Estado de [PS]',
      value: '(+970) Palestina, Estado de',
    },
    PT: {
      mask: '9999-999',
      code: 'PT',
      country: 'Portugal',
      label: 'Postal code',
      phone: '351',
      option: '(+351) Portugal [PT]',
      value: '(+351) Portugal',
    },
    PW: {
      mask: '99999',
      code: 'PW',
      country: 'Palau',
      label: 'ZIP codes',
      phone: '680',
      option: '(+680) Palau [PW]',
      value: '(+680) Palau',
    },
    PY: {
      mask: '9999',
      code: 'PY',
      country: 'Paraguai',
      label: 'Código postal',
      phone: '595',
      option: '(+595) Paraguai [PY]',
      value: '(+595) Paraguai',
    },
    QA: {
      mask: '',
      code: 'QA',
      country: 'Catar',
      label: '',
      phone: '974',
      option: '(+974) Catar [QA]',
      value: '(+974) Catar',
    },
    RE: {
      mask: '99999',
      code: 'RE',
      country: 'Reunião',
      label: 'Code postal',
      phone: '262',
      option: '(+262) Reunião [RE]',
      value: '(+262) Reunião',
    },
    RO: {
      mask: '999999',
      code: 'RO',
      country: 'Roménia',
      label: 'Postal code',
      phone: '40',
      option: '(+40) Roménia [RO]',
      value: '(+40) Roménia',
    },
    RS: {
      mask: '99999',
      code: 'RS',
      country: 'Sérvia',
      label: 'PoÅ¡tanski broj',
      phone: '381',
      option: '(+381) Sérvia [RS]',
      value: '(+381) Sérvia',
    },
    RU: {
      mask: '999999',
      code: 'RU',
      country: 'Federação Russa',
      label: 'Postal code',
      phone: '7',
      option: '(+7) Federação Russa [RU]',
      value: '(+7) Federação Russa',
    },
    RW: {
      mask: '',
      code: 'RW',
      country: 'Ruanda',
      label: '',
      phone: '250',
      option: '(+250) Ruanda [RW]',
      value: '(+250) Ruanda',
    },
    SA: {
      mask: '99999',
      code: 'SA',
      country: 'Arábia Saudita',
      label: 'Postal code',
      phone: '966',
      option: '(+966) Arábia Saudita [SA]',
      value: '(+966) Arábia Saudita',
    },
    SB: {
      mask: '',
      code: 'SB',
      country: 'Ilhas Salomão',
      label: '',
      phone: '677',
      option: '(+677) Ilhas Salomão [SB]',
      value: '(+677) Ilhas Salomão',
    },
    SC: {
      mask: '',
      code: 'SC',
      country: 'Seychelles',
      label: '',
      phone: '248',
      option: '(+248) Seychelles [SC]',
      value: '(+248) Seychelles',
    },
    SD: {
      mask: '99999',
      code: 'SD',
      country: 'Sudão',
      label: 'Postal code',
      phone: '249',
      option: '(+249) Sudão [SD]',
      value: '(+249) Sudão',
    },
    SE: {
      mask: '99999',
      code: 'SE',
      country: 'Suécia',
      label: 'Postal code',
      phone: '46',
      option: '(+46) Suécia [SE]',
      value: '(+46) Suécia',
    },
    SG: {
      mask: '999999',
      code: 'SG',
      country: 'Singapura',
      label: 'Postal code',
      phone: '65',
      option: '(+65) Singapura [SG]',
      value: '(+65) Singapura',
    },
    SH: {
      mask: 'TDCU 1ZZ',
      code: 'SH',
      country: 'Santa Helena',
      label: 'Postcode',
      phone: '290',
      option: '(+290) Santa Helena [SH]',
      value: '(+290) Santa Helena',
    },
    SI: {
      mask: '9999',
      code: 'SI',
      country: 'Eslovênia',
      label: 'Postal code',
      phone: '386',
      option: '(+386) Eslovênia [SI]',
      value: '(+386) Eslovênia',
    },
    SJ: {
      mask: '9999',
      code: 'SJ',
      country: 'Svalbard e Jan Mayen',
      label: 'Postal code',
      phone: '47',
      option: '(+47) Svalbard e Jan Mayen [SJ]',
      value: '(+47) Svalbard e Jan Mayen',
    },
    SK: {
      mask: '99999',
      code: 'SK',
      country: 'Eslováquia',
      label: 'PSÄŒ',
      phone: '421',
      option: '(+421) Eslováquia [SK]',
      value: '(+421) Eslováquia',
    },
    SL: {
      mask: '',
      code: 'SL',
      country: 'Serra Leoa',
      label: '',
      phone: '232',
      option: '(+232) Serra Leoa [SL]',
      value: '(+232) Serra Leoa',
    },
    SM: {
      mask: '99999',
      code: 'SM',
      country: 'San Marino',
      label: 'CPI',
      phone: '378',
      option: '(+378) San Marino [SM]',
      value: '(+378) San Marino',
    },
    SN: {
      mask: '99999',
      code: 'SN',
      country: 'Senegal',
      label: 'Code postal',
      phone: '221',
      option: '(+221) Senegal [SN]',
      value: '(+221) Senegal',
    },
    SO: {
      mask: '',
      code: 'SO',
      country: 'Somália',
      label: '',
      phone: '252',
      option: '(+252) Somália [SO]',
      value: '(+252) Somália',
    },
    SR: {
      mask: '',
      code: 'SR',
      country: 'Suriname',
      label: '',
      phone: '597',
      option: '(+597) Suriname [SR]',
      value: '(+597) Suriname',
    },
    SS: {
      mask: '99999',
      code: 'SS',
      country: 'Sudão do Sul',
      label: 'Postal code',
      phone: '211',
      option: '(+211) Sudão do Sul [SS]',
      value: '(+211) Sudão do Sul',
    },
    ST: {
      mask: '',
      code: 'ST',
      country: 'São Tomé e Príncipe',
      label: '',
      phone: '239',
      option: '(+239) São Tomé e Príncipe [ST]',
      value: '(+239) São Tomé e Príncipe',
    },
    SV: {
      mask: '9999',
      code: 'SV',
      country: 'El Salvador',
      label: 'Código postal',
      phone: '503',
      option: '(+503) El Salvador [SV]',
      value: '(+503) El Salvador',
    },
    SX: {
      mask: '',
      code: 'SX',
      country: 'Sint Maarten (parte holandesa)',
      label: '',
      phone: '1-721',
      option: '(+1-721) Sint Maarten (parte holandesa) [SX]',
      value: '(+1-721) Sint Maarten (parte holandesa)',
    },
    SY: {
      mask: '',
      code: 'SY',
      country: 'República Árabe Síria',
      label: '',
      phone: '963',
      option: '(+963) República Árabe Síria [SY]',
      value: '(+963) República Árabe Síria',
    },
    SZ: {
      mask: 'A999',
      code: 'SZ',
      country: 'Suazilândia',
      label: 'Postal code',
      phone: '268',
      option: '(+268) Suazilândia [SZ]',
      value: '(+268) Suazilândia',
    },
    TC: {
      mask: 'TKCA 1ZZ',
      code: 'TC',
      country: 'Ilhas Turks e Caicos',
      label: 'Postcode',
      phone: '1-649',
      option: '(+1-649) Ilhas Turks e Caicos [TC]',
      value: '(+1-649) Ilhas Turks e Caicos',
    },
    TD: {
      mask: '99999',
      code: 'TD',
      country: 'Chad',
      label: 'Code postal',
      phone: '235',
      option: '(+235) Chad [TD]',
      value: '(+235) Chad',
    },
    TF: {
      mask: '',
      code: 'TF',
      country: 'Territórios do Sul da França',
      label: '',
      phone: '262',
      option: '(+262) Territórios do Sul da França [TF]',
      value: '(+262) Territórios do Sul da França',
    },
    TG: {
      mask: '',
      code: 'TG',
      country: 'Togo',
      label: '',
      phone: '228',
      option: '(+228) Togo [TG]',
      value: '(+228) Togo',
    },
    TH: {
      mask: '99999',
      code: 'TH',
      country: 'Tailândia',
      label: 'Postal code',
      phone: '66',
      option: '(+66) Tailândia [TH]',
      value: '(+66) Tailândia',
    },
    TJ: {
      mask: '999999',
      code: 'TJ',
      country: 'Tajiquistão',
      label: 'Postal code',
      phone: '992',
      option: '(+992) Tajiquistão [TJ]',
      value: '(+992) Tajiquistão',
    },
    TK: {
      mask: '',
      code: 'TK',
      country: 'Tokelau',
      label: '',
      phone: '690',
      option: '(+690) Tokelau [TK]',
      value: '(+690) Tokelau',
    },
    TL: {
      mask: '',
      code: 'TL',
      country: 'Timor-Leste',
      label: '',
      phone: '670',
      option: '(+670) Timor-Leste [TL]',
      value: '(+670) Timor-Leste',
    },
    TM: {
      mask: '999999',
      code: 'TM',
      country: 'Turquemenistão',
      label: 'Postal code',
      phone: '993',
      option: '(+993) Turquemenistão [TM]',
      value: '(+993) Turquemenistão',
    },
    TN: {
      mask: '9999',
      code: 'TN',
      country: 'Tunísia',
      label: 'Code postal',
      phone: '216',
      option: '(+216) Tunísia [TN]',
      value: '(+216) Tunísia',
    },
    TO: {
      mask: '',
      code: 'TO',
      country: 'Tonga',
      label: '',
      phone: '676',
      option: '(+676) Tonga [TO]',
      value: '(+676) Tonga',
    },
    TR: {
      mask: '99999',
      code: 'TR',
      country: 'Turquia',
      label: 'Postal code',
      phone: '90',
      option: '(+90) Turquia [TR]',
      value: '(+90) Turquia',
    },
    TT: {
      mask: '999999',
      code: 'TT',
      country: 'Trinidad e Tobago',
      label: 'Postal code',
      phone: '1-868',
      option: '(+1-868) Trinidad e Tobago [TT]',
      value: '(+1-868) Trinidad e Tobago',
    },
    TV: {
      mask: '',
      code: 'TV',
      country: 'Tuvalu',
      label: '',
      phone: '688',
      option: '(+688) Tuvalu [TV]',
      value: '(+688) Tuvalu',
    },
    TW: {
      mask: '99999',
      code: 'TW',
      country: 'Taiwan, Província da China',
      label: 'Postal code',
      phone: '886',
      option: '(+886) Taiwan, Província da China [TW]',
      value: '(+886) Taiwan, Província da China',
    },
    TZ: {
      mask: '',
      code: 'TZ',
      country: 'República Unida da Tanzânia',
      label: '',
      phone: '255',
      option: '(+255) República Unida da Tanzânia [TZ]',
      value: '(+255) República Unida da Tanzânia',
    },
    UA: {
      mask: '99999',
      code: 'UA',
      country: 'Ucrânia',
      label: 'Postal code',
      phone: '380',
      option: '(+380) Ucrânia [UA]',
      value: '(+380) Ucrânia',
    },
    UG: {
      mask: '',
      code: 'UG',
      country: 'Uganda',
      label: '',
      phone: '256',
      option: '(+256) Uganda [UG]',
      value: '(+256) Uganda',
    },
    US: {
      mask: '99999',
      code: 'US',
      country: 'Estados Unidos',
      label: 'ZIP codes',
      phone: '1',
      option: '(+1) Estados Unidos [US]',
      value: '(+1) Estados Unidos',
    },
    UY: {
      mask: '99999',
      code: 'UY',
      country: 'Uruguai',
      label: 'Código postal',
      phone: '598',
      option: '(+598) Uruguai [UY]',
      value: '(+598) Uruguai',
    },
    UZ: {
      mask: '999999',
      code: 'UZ',
      country: 'Uzbequistão',
      label: 'Postal code',
      phone: '998',
      option: '(+998) Uzbequistão [UZ]',
      value: '(+998) Uzbequistão',
    },
    VA: {
      mask: '00120',
      code: 'VA',
      country: 'Santa Sé (Estado da Cidade do Vaticano)',
      label: 'CAP',
      phone: '379',
      option: '(+379) Santa Sé (Estado da Cidade do Vaticano) [VA]',
      value: '(+379) Santa Sé (Estado da Cidade do Vaticano)',
    },
    VC: {
      mask: 'VC9999',
      code: 'VC',
      country: 'São Vicente e Granadinas',
      label: 'Postal code',
      phone: '1-784',
      option: '(+1-784) São Vicente e Granadinas [VC]',
      value: '(+1-784) São Vicente e Granadinas',
    },
    VE: {
      mask: '9999',
      code: 'VE',
      country: 'Venezuela',
      label: 'Código postal',
      phone: '58',
      option: '(+58) Venezuela [VE]',
      value: '(+58) Venezuela',
    },
    VG: {
      mask: 'VG9999',
      code: 'VG',
      country: 'Ilhas Virgens Britânicas',
      label: 'Postal code',
      phone: '1-284',
      option: '(+1-284) Ilhas Virgens Britânicas [VG]',
      value: '(+1-284) Ilhas Virgens Britânicas',
    },
    VI: {
      mask: '99999',
      code: 'VI',
      country: 'Ilhas Virgens Americanas',
      label: 'ZIP codes',
      phone: '1-340',
      option: '(+1-340) Ilhas Virgens Americanas [VI]',
      value: '(+1-340) Ilhas Virgens Americanas',
    },
    VN: {
      mask: '999999',
      code: 'VN',
      country: 'Vietnã',
      label: 'Postal code',
      phone: '84',
      option: '(+84) Vietnã [VN]',
      value: '(+84) Vietnã',
    },
    VU: {
      mask: '',
      code: 'VU',
      country: 'Vanuatu',
      label: '',
      phone: '678',
      option: '(+678) Vanuatu [VU]',
      value: '(+678) Vanuatu',
    },
    WF: {
      mask: '99999',
      code: 'WF',
      country: 'Wallis e Futuna',
      label: 'Code postal',
      phone: '681',
      option: '(+681) Wallis e Futuna [WF]',
      value: '(+681) Wallis e Futuna',
    },
    WS: {
      mask: '',
      code: 'WS',
      country: 'Samoa',
      label: '',
      phone: '685',
      option: '(+685) Samoa [WS]',
      value: '(+685) Samoa',
    },
    XK: {
      mask: '',
      code: 'XK',
      country: 'Kosovo',
      label: '',
      phone: '383',
      option: '(+383) Kosovo [XK]',
      value: '(+383) Kosovo',
    },
    YE: {
      mask: '',
      code: 'YE',
      country: 'Iêmen',
      label: '',
      phone: '967',
      option: '(+967) Iêmen [YE]',
      value: '(+967) Iêmen',
    },
    // YT: {
    //   mask: '99999',
    //   code: 'YT',
    //   country: 'Mayotte',
    //   label: 'Code postal',
    //   phone: '262',
    //   option: '(+262) Mayotte [YT]',
    //   value: '(+262) Mayotte',
    // },
    ZA: {
      mask: '9999',
      code: 'ZA',
      country: 'África do Sul',
      label: 'Postal code',
      phone: '27',
      option: '(+27) África do Sul [ZA]',
      value: '(+27) África do Sul',
    },
    ZM: {
      mask: '99999',
      code: 'ZM',
      country: 'Zâmbia',
      label: 'Postal code',
      phone: '260',
      option: '(+260) Zâmbia [ZM]',
      value: '(+260) Zâmbia',
    },
    ZW: {
      mask: '',
      code: 'ZW',
      country: 'Zimbabwe',
      label: '',
      phone: '263',
      option: '(+263) Zimbabwe [ZW]',
      value: '(+263) Zimbabwe',
    },
  },
  es: {},
  fr: {},
};


const countries = {
  ..._countries,
  es: _countries.en,
  fr: _countries.en,
};

export { countries };

