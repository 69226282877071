import createApi from 'store/utils/createApi'

export default createApi('/api/user/company', methods => ({
  create: data => methods.create(data)
    .then(({ data }) => {
      return {
        id: data.id,
        name: data.nome,
        data
      }
    }),

  update: (id, data) => methods.update(id, data)
    .then(({ data }) => {
      return {
        id: data.id,
        name: data.nome,
        data
      }
    })
}))
