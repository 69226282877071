import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  AlertStyled, CloseButtonStyled, List
} from './styles'

const getMessage = item => {
  if (typeof item === 'string') {
    return item
  }

  if (item.id) {
    return <FormattedMessage id={item.id} />
  }

  if (Array.isArray(item)) {
    return getMessage(item[0])
  }

  return item.toString()
}

export default function Alert ({ type, message, timer, items, onRemoveClick, ...props }) {
  
  useEffect(()=>{
    if(type === 'success')
    setTimeout(()=>{
      if(document.querySelector('.--alert'))
      document.querySelector('.--alert').parentNode.setAttribute('style',`
        transition:1s all;
        opacity:0;
      `)
      setTimeout( onRemoveClick, 1000 )
    },timer ? timer : 5000)
  })

  return (
    <AlertStyled type={type} {...props}>
      <div className={"--alert"}>
        {getMessage(message)}
        {items && items.length > 0 && (
          <List>
            {items.map((item, index) => (
              <li key={index}>
                {getMessage(item)}
              </li>
            ))}
          </List>
        )}
      </div>
      {onRemoveClick && (
        <CloseButtonStyled type='button' onClick={onRemoveClick}>
          ×
        </CloseButtonStyled>
      )}
    </AlertStyled>
  )
}

Alert.propTypes = {
  /** Tipo do alert */
  type: PropTypes.oneOf(['success', 'error']).isRequired,

  /** Conteúdo do alert */
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.object,
    PropTypes.array
  ]).isRequired,

  /** Lista de items */
  items: PropTypes.array,

  /** Callback para o botão de fechar */
  onRemoveClick: PropTypes.func
}
