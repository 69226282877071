import React from 'react'
import styled from 'styled-components'
import bodyMale from 'images/body-male.png'
import bodyFemale from 'images/body-female.png'
import { GENDER } from 'config/constants'
import mediaQuery from 'utils/mediaQuery'

const BodyImageStyled = styled.div`
  background: url(${props => props.gender === GENDER.male.id ? bodyMale : bodyFemale}) no-repeat right
  background-size: contain;
  height: 60vh;

  ${mediaQuery.lessThan('medium')`
    height: 0;
  `}
`

export default function BodyImage({ gender, ...props}){
  return <BodyImageStyled gender={gender} {...props} />
}
