import React from 'react'
import Resource from 'containers/Resource'
import resolve from 'utils/resolve'

export default (WrappedComponent, props) =>
  class EnhancedComponent extends React.Component {
    render () {
      const propsForResource = resolve(props, this.props)
      return (
        <Resource {...propsForResource}>
          {receivedProps => (
            <WrappedComponent
              {...this.props}
              resourceProps={receivedProps}
            />
          )}
        </Resource>
      )
    }
  }
