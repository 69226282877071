import createResourceRoute from "utils/createResourceRoute";

export default {
  site: {
    loginHome: "/",
    loginCompany: "/login/company/:ticket",
    logout: "/logout"
  },

  users: {
    index: "/users/",
    login: "/users/login",
    passwordRecovery: "/users/password-recovery",
    passwordConfirm: "/users/password-confirm",
    register: "/users/register",
    myAccount: "/users/myaccount",
    reportsCompany: "/users/admin/reports-personal-data",
    reportsRisk: "/users/admin/reports-risk",
    reportsMedicalHistory: "/users/admin/reports-medical-history",
    reportsMentalRisk: "/users/admin/reports-mental-risk",
    reportsMentalHistorical: "/users/admin/reports-mental-historical",
    dashbord: "/users/admin/dashbord",

    diagnosticResults: {
      ...createResourceRoute("/users/diagnostics/results"),
      fewDetails: "/users/diagnostics/results/:anamneseID/few-details"
    },

    admin: {
      patients: createResourceRoute("/users/admin/patients"),
      companies: createResourceRoute("/users/admin/companies")
    },

    diagnostic: {
      index: "/users/anamnese",

      homeAnamnese: "/users/anamnese/fill/:anamneseID",
      startAnamnese: "/users/anamnese/fill/:anamneseID/start",
      beforeStartAnamnese: "/users/anamnese/fill/:anamneseID/before",

      anamnese: "/users/anamnese/:script/:patient/:session/anamnese",
      login: "/users/anamnese/:script/:patient/:session/login",
      logout: "/users/anamnese/:script/:patient/:session/logout",
      personalData: "/users/anamnese/:script/:patient/:session/personal",
      categories: "/users/anamnese/:script/:patient/:session/categories",
      category: "/users/anamnese/:script/:patient/:session/categories/:id",
      result: "/users/anamnese/:script/:patient/:session/result",
      company: "/users/anamnese/company/:slug",
      companyPatient: "/users/anamnese/company/:companyId/patient/:hash",
      confirmation: "/users/anamnese/confirmation/:script/:patient/:session"
    }
  },

  questionnaire: {
    index: "/anamnese"
  },

  admin: {
    index: "/admin",
  },

  email: {
    result: "/result/:hash"
  }
};
