import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  isSubmitting,
  validateCupom,
  isValidCode,
  register
} from 'store/ducks/register'

class Cupom extends Component {
  render () {
    const { children, ...props } = this.props
    return children(props)
  }

  static propTypes = {
    /** Render function com os parâmetros do Auth */
    children: PropTypes.func.isRequired,
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting(state),
  isValidCode: isValidCode(state),
  type: state.register.type,
  registerSuccess: state.register.registerSuccess,
  termsOfService: state.register.termsOfService,
  company: state.register.company
})

export default connect(
  mapStateToProps,
  { validateCupom, register }
)(Cupom)
