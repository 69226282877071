import Section from 'admin/components/Section'
import SubmissionContainer from 'admin/components/SubmissionContainer'
import ButtonSubmit from 'components/ButtonSubmit'
import CustomField from 'components/CustomField'
import Link from 'components/Link'
import Text from 'components/Text'
import VerticalSpacer from 'components/VerticalSpacer'
import PropTypes from 'prop-types'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { injectIntl } from 'react-intl'
import routes from 'routes'
import * as forms from 'utils/forms'
import { equalTo } from 'utils/validators'

function FormPasswordReset ({ isSubmitting, intl, ...props }) {    
  
  const handleRedirect = (route)=>{
    if( route.success ){
      route.success = false;
      setTimeout(()=> document.getElementById('route_login').click(), 3000)
    }
  }
  
  return (
    <Section 
      title={intl.formatMessage({ id: 'site.authenticated.password.redefine' })} 
      style={{
        top:30
      }}
    >      
      <Form {...props}>
        {({ handleSubmit, pristine }) => (
          <form onSubmit={handleSubmit}>
            <VerticalSpacer>
              <Text>
              {intl.formatMessage({ id: 'site.authenticated.password.redefine.text' })}
              </Text>
              <Field
                name='password'
                label={intl.formatMessage({ id: 'site.authenticated.password' })}
                id='form_user_password'
                component={CustomField}
                {...forms.passwordRequired}
              />
              <Field
                name='password_confirmation'
                label={intl.formatMessage({ id: 'site.authenticated.password.confirm' })}
                id='form_user_password_confirmation'
                component={CustomField}
                {...forms.password}
                validate={equalTo({ field: 'password', message: intl.formatMessage({ id:'site.authenticated.password.validate'}) })}
              />
              <SubmissionContainer>
                <ButtonSubmit
                  label={intl.formatMessage({ id: 'site.authenticated.password.redefine' })}
                  isSubmitting={isSubmitting}
                  disabled={pristine}
                />
                <Link id={'route_login'} to={routes.users.login}>
                  {intl.formatMessage({ id: 'site.authenticated.login' })}
                </Link>
                {handleRedirect(props.detailedRecords)}
              </SubmissionContainer>
            </VerticalSpacer>
          </form>
        )}
      </Form>
    </Section>
  )
}

FormPasswordReset.propTypes = {
  /** Callback para o form submit */
  onSubmit: PropTypes.func.isRequired,

  /** Form sendo processado */
  isSubmitting: PropTypes.bool
}

export default injectIntl(FormPasswordReset)
