/**
 * Dependencies.
 */

import { format, parse } from 'date-fns';
import { fit } from 'modules/msk';
import {
  AsYouType,
  formatIncompletePhoneNumber,
  isSupportedCountry,
  parsePhoneNumber,
  validatePhoneNumberLength,
} from 'libphonenumber-js';
import { empty } from 'ramda';

/**
 * Number
 */

export const number = (value = '') => fit(value, '999999999');

/**
 * Phone.
 */

export const phone = (value = '', uf = '') => {
  if (
    (!empty(uf) || !empty(uf.code) || !isSupportedCountry(uf.code)) &&
    validatePhoneNumberLength(value, uf.code) === undefined
  ) {
    let phone = parsePhoneNumber(value, uf.code).format('NATIONAL');
    return fit(value, phone.replace(/\d/g, '9'));
  }

  return value.length === 11
    ? fit(value, '(99) 99999-9999')
    : fit(value, '(99) 9999-9999');
};

/**
 * CPF.
 */

export const cpf = (value = '') => fit(value, '999.999.999-99');

/**
 * login.
 */

export const login = (value = '') => {
  const cpf = /^[0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}/.test(value);
  if (!cpf) return value;
  return fit(value, '999.999.999-99');
};

/**
 * CNPJ.
 */

export const cnpj = (value = '') => fit(value, '99999999999999');

export const maskCnpj = (value = '') => fit(value, '99.999.999/9999-99');

/**
 * CEP.
 */

export const cep = (value = '') => fit(value, '99999-999');

export const code = (value = '') => fit(value, '99999');

export const zipcode = (mask, value = '') => {
  if (mask && value) return fit(value.trim(), mask.trim());
};

/**
 * Date.
 */

export const date = (value = '') => {
  const date = /\d{4}-\d{2}-\d{2}/.test(value)
    ? format(parse(value), 'DD/MM/YYYY')
    : value;

  return fit(date, '99/99/9999');
};

/**
 * Data Nascimento.
 */

export const dtNascimento = (value = '') => {
  const date = /\d{4}-\d{2}-\d{2}/.test(value)
    ? format(parse(value), 'DD/MM/YYYY')
    : value;

  return fit(date, '99/99/9999');
};

/**
 * TextAreaFroala.
 */

export const textArea = value => {
  if (value) {
    return value.replace('Powered by ', '').replaceAll('Froala Editor', '');
  }
  return value;
};
