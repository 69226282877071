export default {
  fonts: {
    primary: `'Athelas Regular', serif`,
    secondary: `'Barlow Condensed', sans-serif`,
    tertiary: `'Poppins', sans-serif`,
  },

  colors: {
    hm: '#6d479a',
    text: '#1a2325',
    textGray: '#636363',
    primary: '#39acb5',
    blue: '#478fcc',
    secondary: '#ffbf2c',
    // danger: '#b72120',
    dangerDark: '#a81d1c',
    white: '#fff',
    black: '#000',
    border: '#e0e0e0',
    background: '#f4f4f4',
    dark: '#1a2325',
    success: '#159155',
    warning: '#e49f00',
    formBorder: '#c1c1c1',
    categories: '#6b6b6b',
    personal: '#004879',
    highRisk: '#d68485',
    specialities: {
      prostata: '#39acb5',
      colon: '#bfa66c',
      utero: '#b72221',
      mama: '#ea5580',
      pulmao: '#5f293d',
    },

    backgroundNew: '#fff',
    secondaryYellow: '#f9ebbe',
    primaryGreen: '#1fab22',
    secondaryGreen: '#a5e3e0',
    textNew: '#0b4e71',
    textHover: '#1c8cc7',
    danger: '#f48576',
    infoSection: '#fdba85',
    whiteLight: '#eaedf4',
    defaultHM: '#13407a',
  },

  breakpoints: {
    large: '1000px',
    medium: '800px',
    small: '600px',
    xsmall: '400px',
  },

  dashboard: {
    colors: {
      primary: '#2C3E50',
      grayLight: '#f4f4f4',
      gray: '#e0e0e0',
    },
  },
};
