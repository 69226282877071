import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Box from 'admin/components/Box'
import VerticalSpacer from 'components/VerticalSpacer'
import SectionTitle from 'components/SectionTitle'
import mediaQuery from 'utils/mediaQuery'

const Header = styled.header`
  ${props => props.side && mediaQuery.greaterThan('small')`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}

  ${props => props.side && mediaQuery.lessThan('small')`
    > * + * {
      margin-top: 15px;
    }
  `}
`

const CommonWrapper = props => <div children={props.children} />

export default function Section ({ title, titleSize, children, side, wrapped, ...props }) {
  const Wrapper = wrapped
    ? Box
    : CommonWrapper

  const { smallTitle,...rest} = props
  
  return (
    <Wrapper component='section' {...rest}>
      <VerticalSpacer>
        <Header side={side}>
          <SectionTitle size={titleSize}>{title}</SectionTitle>
          {props.smallTitle && (
            <SectionTitle color="infoSection" size="16px">{props.smallTitle}</SectionTitle>
          )}
          {side && (
            <div>
              {side}
            </div>
          )}
        </Header>
        <div>
          {children}
        </div>
      </VerticalSpacer>
    </Wrapper>
  )
}

Section.propTypes = {
  /** Título */
  title: PropTypes.string.isRequired,

  /** Tamanho da fonte do Título (em px) */
  titleSize: PropTypes.string,

  /** Conteúdo da section */
  children: PropTypes.any.isRequired,

  /** Elemento opcional que ficará ao lado do título */
  side: PropTypes.element,

  /** Embala a section numa Box */
  wrapped: PropTypes.bool
}

Section.defaultProps = {
  wrapped: true,
  titleSize: '40px',
  title: '',
}
