import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Switch, Route } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { isEmpty } from 'ramda'
import PageContent from 'components/PageContent'
import Spinner from 'components/Spinner'
import Container from 'components/Container'
import Text from 'components/Text'
import { NotFoundScreen } from 'screens'
import * as Screens from 'users/questionnaire/screens'
import * as duck from 'store/ducks/userQuestionnaire'
import routes from 'routes'
import { isAuthenticated } from 'store/ducks/auth'

class PatientAnamnese extends Component {
  fetchData () {
    const { anamneseID } = this.props.match.params
    this.props.fetchData({ anamneseID })
  }

  componentDidMount () {
    this.fetchData()
  }

  componentDidUpdate ({ isAuth }) {
    if (!isAuth && this.props.isAuth) {
      this.fetchData()
    }
  }

  render () {
    if (this.props.isFetching) {
      return (
        <PageContent>
          <Spinner />
        </PageContent>
      )
    }

    if (isEmpty(this.props.data)) {
      return (
        <PageContent>
          <Container size='small'>
            <Text align='center'>
              <FormattedMessage id='admin.common.noResultsFound' />
            </Text>
          </Container>
        </PageContent>
      )
    }

    return (
      <div>
        <Switch>
          <Route
            exact
            path={routes.users.diagnostic.personalData}
            component={Screens.PersonalDataScreen}
          />
          <Route
            exact
            path={routes.users.diagnostic.anamnese}
            component={Screens.AnamneseScreen}
          />
          <Route
            exact
            path={routes.users.diagnostic.startAnamnese}
            component={Screens.CategoryScreen}
          />
          <Route component={NotFoundScreen} />
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isFetching: duck.isFetching(state),
  data: duck.getData(state),
  error: duck.getError(state),
  isAuth: isAuthenticated(state)
})

const mapDispatchToProps = {
  fetchData: duck.fetchData
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientAnamnese))
