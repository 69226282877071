import React from 'react'
import { connect } from 'react-redux'
import Auth from 'admin/containers/Auth'
import Screen from 'components/Screen'
import PageContent from 'components/PageContent'
import Container from 'components/Container'
import VerticalSpacer from 'components/VerticalSpacer'
import Header from 'users/questionnaire/components/Header'
import { getData, getAuth } from 'store/ducks/questionnaire'
import { anamneseCompleted } from 'store/ducks/userQuestionnaire'

function QuestionnaireScreen ({ personal, children, icon, guest, data, auth, anamneseCompleted, ...props }) {
  let size = anamneseCompleted ? 'blog' : 'xsmall'
  if(props.size){
    size = props.size
  }

  return (
    <Auth>
      {authProps => {
        return (
          <Screen {...props}>
            <Header
              title={props.title}
              subtitle = {props.subcategory ? props.subcategory: ''}
              color={props.color}
              personal={personal}
              icon={icon}
            />
            <PageContent>
              <Container size={size}>
                <VerticalSpacer>
                  <div>
                    {children}
                  </div>
                </VerticalSpacer>
              </Container>
            </PageContent>
          </Screen>
        )
      }}
    </Auth>
  )
}

const mapStateToProps = state => ({
  data: getData(state),
  auth: getAuth(state),
  anamneseCompleted: anamneseCompleted(state)
})

export default connect(
  mapStateToProps
)(QuestionnaireScreen)
