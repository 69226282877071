import Base from 'components/Base'
import { lighten } from 'polished'
import React from 'react'
import styled, { css } from 'styled-components'
import media from 'utils/mediaQuery'

export const lightenNormal = lighten(0.15)
export const lightenSmall = lighten(0.05)

export const IconWrapper = styled.span`
  display: block;
  @media(max-width:800px){
    display:inline-block;
  }
  width: 45px;
  text-align: center;
`

export const TitleWrapper = styled.span`
  display: block;
  @media(max-width:800px){
    display:inline-block;
  }
  ${media.greaterThan('large')`
    display: ${props => props.narrow ? 'none' : 'block'};
  `}
`

export const ButtonContainer = styled(({ small, narrow, ...rest }) => <Base {...rest} />)`
  display: flex;
  @media(max-width:800px){
    display:inline-block;
  }
  align-items: center;
  width: 100%;
  padding: 15px;
  padding-left: 5px;
  z-index: 2;
    
  ${media.greaterThan('large')`
    padding-left: ${props => props.narrow ? '15px' : '5px'};
  `}
  
  font-size: 16px;
  color: #fff;
  background-color: transparent;
  border: none;
  border-left: 5px solid transparent;
  text-decoration: none;
  font-family: ${props => props.theme.fonts.tertiary};
  cursor: pointer;
  box-sizing: border-box;

  ${props => props.small && css`
    padding: 10px 15px;
    padding-left: 15px;
    font-size: 14px;
  `}

  &:hover {
    background-color: ${props => props.theme.colors.whiteLight};
    @media(max-width:800px){
      background-color:${props => props.theme.colors.primary}; ;
    }
    color: #fff;
  }

  &.active {
    border-left-color: ${props => props.theme.colors.primary};
  }

  > ${IconWrapper} {
    color: ${props => props.theme.colors.textNew};
  }

  .section-sub-menu {
    background: #EAEDF4;
    position: fixed;
    border-radius: 0px 5px 5px 5px; 
    font-size: 15px;
    min-width: 300px;
    margin-top: -26.900px;
    margin-left: 14px;
}

@media (max-width:800px){
    #dashboard-tooltip{
        display: none!important;
    }
    .section-sub-menu {
      top: 15px !important;
      margin-bottom: 2px !important;
      border-radius: 0px !important;
    }
}
`
export const CollapseArrow = styled.span`
  margin-left: auto;
  font-size: 12px;

   #dashboard-tooltip{
        overflow: hidden;
    }
  & .mobile-menu-style{
    @media(max-width: 800px){
      left: 0;
      transition:0.4s all;
      max-width: initial!important;
      margin: 0 -15px -15px;
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1;
      top: 0;

    #dashboard-tooltip{
        display: none!important;
    }
  }
`
