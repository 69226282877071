import isObject from 'is-object'

export default function queryToObject (values) {
  return values.reduce((memo, item) => {
    if (isObject(item.value)) {
      return {
        ...memo,
        ...item.value
      }
    }
    memo[item.id] = item.value
    return memo
  }, {})
}
