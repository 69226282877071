import styled from 'styled-components';
import mediaQuery from 'utils/mediaQuery'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  max-width: 1110px;

  ${mediaQuery.lessThan('small')`
    width: 600px;
  `}

  ${mediaQuery.lessThan('xsmall')`
    width: 300px;
  `}
`;

export const FlexDiv = styled.div`
  display: flex;
  width:100%;
  a{
    margin-right:10px;
  }
`;

export const FlexModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.2);
`;
