import styled from 'styled-components';

export const Container = styled.div`    
    width: 40%;
    min-width:300px;
    padding: 15px 0;
    padding-left: 30px;
    @media(max-width: 800px) {
        display:none;
    }

    &.mobile{  
        display:none;   
        padding-left: 0;   
        @media(max-width: 800px) {
            display:block;
            min-width: 280px;
        }
    }
`;

export const ContainerVideo = styled.div`
    position: relative;
    padding-bottom: 56.25%; /* 16:9, padrão dos vídeos */
    height: 0;
    overflow: hidden;
`;

export const Iframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 99.5%;
    height: 100%;
    border:none;

`
