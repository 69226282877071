import Card from 'admin/components/Card';
import CardFollowUp from 'admin/components/CardFollowUp/CardFollowUp';
import CardHighRisk from 'admin/components/CardHightRisk/CardHighRisk';
import CardsUsers from 'admin/components/CardUsers/CardsUsers';
import ChartAvailableRisk from 'admin/components/ChartAvailableRisk/ChartAvailableRisk';
import ChartFem from 'admin/components/ChartFemale/ChartFemale';
import ChartFunnel from 'admin/components/ChartFunnel/ChartFunnel';
import ChartInterviews from 'admin/components/ChartInterviews/ChartInterviews';
import ChartMen from 'admin/components/ChartMale/ChartMale';
import ChartMissingExams from 'admin/components/ChartMissingExams/ChartMissingExams';
import ContainerDashbord from 'admin/components/ContainerDashbord/ContainerDashbord';
import DoughnutChart from 'admin/components/DoughnutChart/DoughnutChart';
import FiltersDashbord from 'admin/components/FiltersDashbord/FiltersDashbord';
import Section from 'admin/components/Section';
import "chart.js/auto";
import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
//import dashboard from 'mocks/dashboard';
import Spinner from 'components/Spinner/Spinner';
import { Dashboard } from './styles';

const DashboardCompanies = ({ intl, ...props }) => {
  const dashboard = props.records;
    
  return (
    <Fragment>
      <Section
        style={{ overflow: 'visible' }}
        title={intl.formatMessage({ id: 'admin.users.title.plural' })}
        wrapped={false}
      >
        <FiltersDashbord {...props} />
      </Section>
      {Object.keys(dashboard).length ? (
        <Dashboard className={props.isFetching? 'isFetching':''}>
          <ContainerDashbord>
            <CardsUsers data={dashboard.CardsUsers} />
          </ContainerDashbord>

          <ContainerDashbord>
            <Card container={0.98}>
              <ChartFunnel data={dashboard.ChartFunnel} />
            </Card>
            <Card container={2}>
              <ChartInterviews dataset={dashboard.ChartInterviews} colors={dashboard.Colors} />
            </Card>
          </ContainerDashbord>

          <ContainerDashbord>
            <Card container={1}>
              <ChartMen dataset={dashboard.ChartMen} />
            </Card>
            <Card container={1}>
              <ChartFem dataset={dashboard.ChartFem} />
            </Card>
            <Card container={1}>
              <DoughnutChart serie={dashboard.DoughnutChart.series} />
            </Card>
          </ContainerDashbord>

          <ContainerDashbord>
            <Card container={2}>
              <ChartAvailableRisk dataset={dashboard.ChartAvailableRisk} {...dashboard.Filters}/>
            </Card>
            <Card container={0.98}>
              <ChartMissingExams dataset={dashboard.ChartMissingExams} />
            </Card>
          </ContainerDashbord>

          <ContainerDashbord>
            <Card container={1}>
              <CardFollowUp data={dashboard.CardFollowUp} />
            </Card>
          </ContainerDashbord>

          <ContainerDashbord>
            <Card container={1}>
              <CardHighRisk data={dashboard.CardHighRisk} />
            </Card>
          </ContainerDashbord>

          {props.isFetching && (
            <div style={{paddingTop:150, position:'fixed', top:'40%', left:0, right:0}}>
              <Spinner />
            </div>
          )}
        </Dashboard>
      ) : (
        <div style={{paddingTop:150}}>
          <Spinner />
        </div>
      )}
    </Fragment>
  );
};

DashboardCompanies.propTypes = {};

export default injectIntl(DashboardCompanies);
