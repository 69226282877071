import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { omit } from 'ramda'
import { componentType } from 'types'
import Base from 'components/Base'

const filterProps = omit([
  'size',
  'color',
  'hoverColor'
])

const LinkStyled = styled(props => <Base {...filterProps(props)} />)`
  font-family: ${props => props.theme.fonts.tertiary};
  font-size: ${props => props.size};
  color: ${props => props.theme.colors[props.color]};
  text-decoration: none;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors[props.hoverColor]};
  }
`

export default function Link (props) {
  return <LinkStyled {...props} />
}

Link.propTypes = {
  /** Componente */
  component: componentType,

  /** Tamanho da fonte */
  size: PropTypes.string,

  /** Cor */
  color: PropTypes.string,

  /** Cor do hover */
  hoverColor: PropTypes.string
}

Link.defaultProps = {
  component: RouterLink,
  size: 'inherit',
  color: 'text',
  hoverColor: 'textHover'
}
