import Auth from 'admin/containers/Auth'
import Screen from 'components/Screen'
import PropTypes from 'prop-types'
import React from 'react'
import { Redirect } from 'react-router-dom'
import routes from 'routes'

export default function ProtectedScreen ({ permission, title, ...props }) {
  return (
    <Auth>
      {({ isAuthenticated, permissions, type }) => {
        if (!isAuthenticated) {
          const url = `${(type === 'usersAdmWeb' ? routes.users.index : routes.admin.index)}?goto=${props.location.pathname}`
          return <Redirect to={url} />
        }

        if (permission && (type !=='usersAdmWeb' && !permissions.includes(permission))) {
          return <Redirect to={(type === 'usersAdmWeb' ? routes.users.index : routes.admin.index)} />
        }

        return <Screen title={`${title} - PreviNEO`} {...props} />
      }}
    </Auth>
  )
}

ProtectedScreen.propTypes = {
  /** Permissão exigida para acessar a screen */
  permission: PropTypes.string
}
