import Request from 'modules/Request'
import resolve from 'utils/resolve'

export default function createApi (route, customMethods, publicRequest) {
  const request = publicRequest ? Request.api : Request.auth
  const methods = {
    fetchAll (params) {
      return request({
        method: 'GET',
        url: route,
        query: params
      }).then(res => res.body)
    },

    fetchOne (id, params) {
      return request({
        method: 'GET',
        url: `${route}/${id}`,
        query: params
      }).then(res => res.body)
    },

    fetchApi (params) {
      return request({
        method: 'POST',
        url: route,
        body: params
      }).then(res => res.body)
    },

    create (data, params) {
      return request({
        method: 'POST',
        url: `${route}`,
        body: data,
        query: params
      }).then(res => res.body)
    },

    update (id, data, params) {
      return request({
        method: 'PUT',
        url: `${route}/${id}`,
        body: data,
        query: params
      }).then(res => res.body)
    },

    remove (id, params) {
      return request({
        method: 'DELETE',
        url: `${route}/${id}`,
        query: params
      }).then(res => res.body)
    }
  }

  return {
    ...methods,
    ...resolve(customMethods, methods)
  }
}
