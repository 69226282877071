import React from "react";
import { Container, ContainerVideo, Iframe } from './styles';
  
export default ({ className, link }) => {
    let videoId = '';
    let strLink = '';

    if (link) {
        if (link.indexOf('?') > -1) {
            if(link.indexOf('v=') > -1){
                videoId = link.split('v=')[1];
                if(videoId.indexOf('&') > -1){
                    videoId = videoId.split('&')[0];                    
                }
            }
            strLink = link.split('?')[0] 
        } else {
            strLink = link;
        }
        if(videoId === '')
            videoId = strLink.split('/').pop()
    }
    return(
        <Container className={className}>
            <ContainerVideo>
                <Iframe width="560" height="315" src={`https://www.youtube.com/embed/${videoId}?rel=0&loop=1&playlist=${videoId}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></Iframe>
            </ContainerVideo>
        </Container>
    );
}
