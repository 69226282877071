import Auth from 'admin/containers/Auth'
import Screen from 'components/Screen'
import React from 'react'
import { Redirect } from 'react-router-dom'
import routes from 'routes'

export default function GuestScreen (props) {
  return (
    <Auth>
      {({ isAuthenticated, type }) => !isAuthenticated
        ? <Screen {...props} />
        : <Redirect to={(type === 'usersAdmWeb' ? routes.users.index : routes.admin.index)} />
      }
    </Auth>
  )
}
