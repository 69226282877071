import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { NotFoundScreen } from 'screens'
import * as Screens from 'users/questionnaire/screens'
import PatientAnamnese from 'users/questionnaire/containers/PatientAnamnese'
import AuthLogin from 'components/AuthLogin'
import withAuth from 'store/utils/withAuth'
import routes from 'routes'

class Questionnaire extends Component {
  render () {
    return (
      <AuthLogin type='usersAdmWeb'>
        <div>
          <Switch>
            <Route
              exact
              path={routes.users.diagnostic.confirmation}
              component={Screens.ConfirmationScreen}
            />
            <Route
              exact
              path={routes.users.diagnostic.companyPatient}
              component={Screens.CompanyPatientScreen}
            />
            <Route
              exact
              path={routes.users.diagnostic.beforeStartAnamnese}
              component={Screens.SeeMedicalHistory}
            />
            <Route
              path={routes.users.diagnostic.homeAnamnese}
              component={PatientAnamnese}
            />

            <Route
              path={routes.users.diagnostic.confirmation}
              component={Screens.CompanyPatientScreen}
            />
            <Route component={NotFoundScreen} />
          </Switch>
        </div>
      </AuthLogin>
    )
  }
}

export default withAuth(Questionnaire)
