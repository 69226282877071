import styled from 'styled-components'
import mediaQuery from 'utils/mediaQuery'

export default styled.div`
  ${mediaQuery.greaterThan('small')`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}

  ${mediaQuery.lessThan('small')`
    > * + * {
      display: block;
      margin-top: 25px;
    }
  `}
`
