import React, { Fragment } from 'react';
import { Bar } from 'react-chartjs-2';
import { injectIntl } from 'react-intl';
import { Container, FlexBox } from './styles.js';

const ChartMen = ({ intl, dataset }) => {
  const handleFormatDataset = (dataset = []) => {
    return dataset.length
      ? dataset.map(item => ({
          label: intl.formatMessage({ id: item.name }),
          data: item.data,
          borderColor: item.color || '#17a2b8A6',
          backgroundColor: item.border || '#17a2b8',
          borderWidth: 1,
        }))
      : [];
  };

  return (
    <Fragment>
      <FlexBox>
        <Container>
          <Bar
            data={{
              labels: ['< 35', '35-55', '> 55'],
              datasets: handleFormatDataset(dataset),
            }}
            height={300}
            width={'100%'}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              onResize: 200,
              aspectRatio: 2,
              resizeDelay: 5,
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
              plugins: {
                title: {
                  display: true,
                  text: intl.formatMessage({ id: 'admin.male.age.group' }),
                  color: '#17a2b8',
                  align: 'start',
                  font: {
                    size: 15,
                  },
                },
                subtitle: {
                  display: true,
                  align: 'start',
                  text: intl.formatMessage({
                    id: 'admin.common.qtd.age',
                  }),
                  padding: {
                    bottom: 28,
                    top: -4,
                  },
                },
                legend: {
                  display: true,
                  position: 'bottom',
                },
              },
            }}
          />
        </Container>
      </FlexBox>
    </Fragment>
  );
};

export default injectIntl(ChartMen);
