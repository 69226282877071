export default {
  'pages.home.title': 'PreviNEO - Révolution dans la prévention et la lutte contre le cancer',
  'pages.home.description': 'PreviNEO rassemble la technologie et les connaissances scientifiques dans le diagnostic précoce et la prévention des principaux types de cancer rencontrés au Brésil.',
  'pages.about.title': 'Qui sommes-nous ? - PreviNEO',
  'pages.about.description': "Nous travaillons pour prévenir, diagnostiquer et réduire les risques d'incidence des principaux types de cancer présents au Brésil, grâce à un programme d'oncologie et à une technologie de pointe.",
  'pages.specialities.title': 'Spécialités - PreviNEO',
  'pages.specialities.description': "Afin de sensibiliser à la prévention du cancer, vous trouverez plus d'informations concernant ce type de cancer ci-dessous.<br />",
  'pages.specialities.titleDescription': "Afin de sensibiliser à la prévention du cancer, vous trouverez plus d'informations concernant ce type de cancer ci-dessous.<br />",
  'pages.customers.title': 'Clients - PreviNEO',
  'pages.customers.description': 'Chez PreviNEO, nous comptons sur des entreprises partenaires qui mettent nos avantages à la disposition des employés et de leurs familles. Accédez et rencontrez qui est avec nous dans ce combat !',
  'pages.blog.title': 'Blogue - PreviNEO',
  'pages.blog.description': "L'information est un facteur décisif dans le diagnostic précoce et la prévention efficace du cancer. Notre Blog met à disposition des données et des actualités sur notre cause, visitez-le !",
  'pages.contact.title': 'Coordonnées - PreviNEO',
  'pages.contact.description': 'Pour lever des doutes, envoyer des suggestions ou nous rejoindre et devenir partenaire, envoyez-nous un message. Notre équipe se fera un plaisir de vous répondre !',
  'pages.register.register': "S'inscrire",
  'pages.register.first.access': 'Ma première connexion',
  'pages.register.code.continue': 'Continuer',
  'pages.finalize.registration' : "Finaliser l'inscription",
  'pages.register.code.return': 'Retour',
  'pages.login.title': 'Se connecter - PreviNEO',
  'pages.login.description': 'Tela de Connexion',
  'pages.register.code': "Nom de l'entreprise",
  
  /* Títulos dos links do menu */
  'site.menu.home': 'Home',
  'site.menu.about': 'Qui nous sommes',
  'site.menu.specialities': 'Spécialités',
  'site.menu.customers': 'Clients',
  'site.menu.blog': 'Blog',
  'site.menu.contact': 'Données de contact',


  /* Autenticação e cadastro de pacientes */
  'site.authenticated.login': 'Identifiant',
  'site.authenticated.password': 'Mot de passe',
  'site.authenticated.password.redefine' : 'Redéfinir le mot de passe',
  'site.authenticated.password.redefine.text': 'Réinitialisez votre mot de passe dans les champs ci-dessous :',
  'site.authenticated.password.confirm' : 'Confirmez le mot de passe',
  'site.authenticated.password.validate' : 'Les mots de passe doivent être identiques',
  'site.authenticated.password.redefine.create' : 'Un email avec le lien pour réinitialiser votre mot de passe vous a été envoyé !',
  'site.authenticated.password.redefine.success' : 'Votre mot de passe a été mis à jour avec succès!',

  /* 404 */
  'site.notFound.title': 'Page introuvable',
  'site.notFound.message': '404: Page introuvable !',

  /* Login */
  'admin.forms.login.title': 'Identifiant',
  'admin.forms.login': 'E-mail ou CNIs',
  'admin.forms.login.password': 'Mot de passe',
  'admin.forms.login.button': 'Identifiant',
  'admin.forms.login.passwordRecovery': "J'ai oublié mon mot de passe",

  /* Password recovery */
  'admin.forms.passwordRecovery.title': 'Récupérer mon mot de passe',
  'admin.forms.passwordRecovery.button': 'Récupérer',

  /* Admin Usuarios */
  'admin.users.title': 'Utilisateur',
  'admin.users.title.plural': 'Utilisateurs',
  'admin.users.form.sendPasswordEmail': "Envoyer le mot de passe par e-mails",
  'admin.users.searchForUsers': 'Recherche par utilisateur',
  'admin.users.deletedUsers': 'Utilisateurs supprimés',
  'admin.common.restore': 'Récupérer',
  'admin.common.restoreSelected': 'Récupérer la sélection',

  /* Names comuns admin */
  'admin.common.name': 'Prénom',
  'admin.common.full.name': 'Nom et prénom',
  'admin.common.email': 'E-mail',
  'admin.common.emails': 'Emails',
  'admin.common.emailLayout': 'Disposition des e-mails',
  'admin.common.emailLayouts': 'Mises en page des e-mails',
  'admin.common.emailContent': 'Contenu des e-mails',
  'admin.common.emailContents': 'Contenu des e-mails',
  'admin.common.password': 'Mot de passe',
  'admin.common.confirmPassword': 'Confirmer le mot de passe',
  'admin.common.oldPassword': 'Ancien mot de passe',
  'admin.common.company': 'Entreprise',
  'admin.common.accessLevels': "Niveaux d'accès",
  'admin.common.save': 'Enregistrer',
  'admin.common.send': 'Envoyer',
  'admin.common.listOf': 'Liste de',
  'admin.common.register': "S'inscrire",
  'admin.common.edit': 'Éditer',
  'admin.common.details': 'Détails',
  'admin.common.remove': 'Supprimer',
  'admin.common.removePermanently': 'Supprimer définitivement',
  'admin.common.back': 'Retour',
  'admin.common.actions': 'Actions',
  'admin.common.warningMessage': 'Souhaitez-vous vraiment supprimer ce registre ?',
  'admin.common.AreYouSureABoutIt': 'Vous êtes sûr ?',
  'admin.common.confirm': 'Confirmer',
  'admin.common.cancel': 'Annuler',
  'admin.common.processing': 'Traitement en cours…',
  'admin.common.previous': 'Précédent',
  'admin.common.next': 'Suivant',
  'admin.common.loading': 'En cours de chargement…',
  'admin.common.loadMore': 'Charger plus',
  'admin.common.noResultsFound': 'Aucun résultat trouvé',
  'admin.common.page': 'Page',
  'admin.common.Of': 'sur',
  'admin.common.results': 'Résultats',
  'admin.common.idNumber': "Numéro d'identification",
  'admin.common.gender': 'Genre',
  'admin.common.genders': 'Genres',
  'admin.common.gender.male': 'Masculin',
  'admin.common.gender.female': 'Féminin',
  'admin.common.gender.unisex': 'Unisexe',
  'admin.common.genderMale': 'Masculin',
  'admin.common.genderFemale': 'Féminin',
  'admin.common.dateOfBirth': 'Date de naissance',
  'admin.common.companyPlaceHolder': 'Rechercher une entreprise',
  'admin.common.birthDatePlaceHolder': 'DD/MM/YYYY',
  'admin.common.filterData': 'Filtrer les données...',
  'admin.common.mostRecent': 'Plus récents',
  'admin.common.history': 'Historique complet',
  'admin.common.cpf': 'CNI',
  'admin.common.weight': 'Poids (kg)',
  'admin.common.height': 'Hauteur (cm)',
  'admin.common.phone': 'Téléphone',
  'admin.common.cellphone': 'Téléphone portable',
  'admin.common.acceptContact': 'Accepter de recevoir des informations complémentaires',
  'admin.common.yes': 'Oui',
  'admin.common.no': 'Non',
  'admin.common.cep': 'Code Postal',
  'admin.common.cepTooltip': 'Insérez votre Code Postal (Ex: 73015-132)',
  'admin.common.codeTooltip': 'Entrez votre Code Postal (Ex: 73015)',
  'admin.common.country': 'Pays',
  'admin.common.state': 'État',
  'admin.common.city': 'Ville',
  'admin.common.address': 'Adresse',
  'admin.common.addressNumber': 'Numéro',
  'admin.common.addressComplement': 'Complément',
  'admin.common.editCompany': "Modifier l’entreprise",
  'admin.common.registerCompany': 'Inscrire l’entreprise',
  'admin.common.removeMessage': 'Voulez-vous vraiment supprimer cette société ?',
  'admin.common.rootNohNotFound': 'Nœud racine introuvable !',
  'admin.common.companyName': "Nom de l'entreprise",
  'admin.common.cnpj': "ID de l'entreprise",
  'admin.common.parentCompany': 'Maison mère',
  'admin.common.expirationDate': "Date d'expiration",
  'admin.common.logo': 'Logo',
  'admin.common.logoTopRight': 'Logo en haut à droite',
  'admin.common.logoTopLeft': 'Logo en haut à gauche',
  'admin.common.logoBottomRight': 'Logo en bas à droite',
  'admin.common.logoBottomLeft': 'Logo en bas à gauche',
  'admin.common.openURL': 'URL ouverte',
  'admin.common.restrictedURL': 'URL avec connexion',
  'admin.common.slugURL': 'Slug (nom après URL)',
  'admin.common.select': 'Sélectionnez',
  'admin.common.typeSomethingToSearch': 'Saisissez un élément à rechercher',
  'admin.common.dateInsert': 'Insérer une date',
  'admin.common.createdAt': 'Créé le',
  'admin.common.fileImport': 'Importer le fichier',
  'admin.common.downloadModel': 'Télécharger le modèle',
  'admin.common.import': 'Importer',
  'admin.common.export': 'Exporter',
  'admin.common.reportQueue': "File d'attente de rapports",
  'admin.common.title': 'Prénom',
  'admin.common.date': 'Date',
  'admin.common.hour': 'Heure',
  'admin.common.record_type': 'Type_enregistrement',
  'admin.common.e-mail': 'E-mail',
  'admin.common.telephone': 'Téléphone',
  'admin.common.surname': 'Nom de famille',
  'admin.common.social_name': 'Nom social',
  'admin.common.road': 'Route',
  'admin.common.number': 'Numéro',
  'admin.common.complement': 'Complément',
  'admin.common.birth_date': 'Date de naissance',
  'admin.common.biological_genus': 'Genre biologique',
  'admin.common.social_gender': 'Genre social',
  'admin.common.ethnicity': 'Ethnicité',
  'admin.common.contact_via_phone': 'Contacter par téléphone',
  'admin.common.health_insurance': 'Plan de la santé',
  'admin.common.search': 'Rechercher',
  'admin.common.searchForCompany': 'Rechercher une entreprise',
  'admin.common.men': 'Hommes',
  'admin.common.women' : 'Femmes',
  'admin.common.script' : 'Script',
  'admin.common.version' : 'Version',
  'admin.common.risk': 'Risque',
  'admin.common.risks': 'Risques',
  'admin.common.question' : 'Question',
  'admin.common.answer' : 'Répondre',
  'admin.common.calculation' : 'Calcul',
  'admin.common.interval' : 'Intervalle',
  'admin.common.start': 'Commencer',
  'admin.common.end': 'Finaliser',
  'admin.common.selectPatient' : 'Sélectionner les patients manuellement',
  'admin.common.high' : 'Élevé',
  'admin.common.medium' : 'Moyen',
  'admin.common.low' : 'Faible',
  'admin.common.description' : 'Description',
  'admin.common.patientID' : 'Identifiant du patient',
  'admin.common.userID' : 'ID utilisateur',
  'admin.common.anamnese' : 'anamnais',
  'admin.common.attention_level' : "Niveau d'attention",
  'admin.common.exams_up_to_date' : 'Examens à jour',
  'admin.common.symptoms' : 'Symptômes',
  'admin.common.justifications': 'Motifs',
  'admin.common.assessment_date' : "Date d'évaluation",
  'admin.common.id' : 'identifiant',
  'admin.common.accept_Contact': 'Acceptez-vous Contact ?',
  'admin.common.age' : 'Âge',
  'admin.common.genre' : 'Genre',
  'admin.common.unit' : 'Unité',
  'admin.common.registration_date' : "Date d'inscription",
  'admin.common.response_date' : "Date de réponse",
  'admin.common.weigh': 'Combien pesez-vous ? (en kg)',
  'admin.common.use_of_medications' : 'Utilisation de médicaments',
  'admin.common.what_remedies': 'Quels remèdes ?',
  'admin.common.specify_the_remedys': 'Spécifier le remède',
  'admin.common.smoke_cigars': 'Fumez-vous ou avez-vous déjà fumé des cigares ?',
  'admin.common.how_many_cigars': 'Durant le temps où tu fumais, combien de cigares par jour ?',
  'admin.common.currently_smoke_cigars': 'Fumez-vous actuellement des cigares ?',
  'admin.common.smoke_how_old': "Si vous avez arrêté de fumer des cigares, il y a combien d'années ?",
  'admin.common.ever_smoked_cigarette': 'Fumez-vous ou avez-vous déjà fumé des cigarettes ?',
  'admin.common.years_smoked_cigarettes': "Depuis combien d'années fumez-vous des cigarettes ?",
  'admin.common.cigarettes_daily': 'Durant le temps où tu fumais, combien de cigarettes par jour ?',
  'admin.common.currently_smoke_cigarettes': 'Fumez-vous actuellement des cigarettes ?',
  'admin.common.stopped_smoking_cigarettes': "Si vous avez arrêté de fumer, il y a combien d'années ?",
  'admin.common.practice_physical_activity': 'Pratiquez-vous une activité physique ?',
  'admin.common.minutes_week': 'Combien de minutes par semaine en moyenne ?',
  'admin.common.performed_genetic_exam': 'A déjà effectué BRCA1 ou BRCA2 ?',
  'admin.common.mutation_search': 'Sa réponse au test de mutation BRCA a été positive. Est correct?',
  'admin.common.already_had_cancer': 'Avez-vous déjà eu un cancer ?',
  'admin.common.type_of_cancer': 'Quel type de cancer ?',
  'admin.common.specify_type_of_cancer': 'Précisez le type de cancer',
  'admin.common.family_cancer_report': 'Sélectionnez des alternatives concernant vos antécédents familiaux de cancer',
  'admin.common.alcohol_consumption': "Quelle est votre consommation quotidienne d'alcool ?",
  'admin.common.had_heart_problem': 'Avez-vous déjà eu un problème cardiaque ?',
  'admin.common.like_heart_trouble': 'Quel type de problème cardiaque ?',
  'admin.common.specify_heart_problem': 'Spécifiez le problème cardiaque',
  'admin.common.history_emotional_problems': 'Avez-vous des antécédents de problèmes émotionnels ?',
  'admin.common.like_mental_problem': 'Quel genre de problème émotionnel ?',
  'admin.common.specify_emotional_problem': 'Précisez le problème émotionnel',
  'admin.common.chest x-ray': 'Avez-vous subi une radiothérapie thoracique pour un lymphome ?',
  'admin.common.radiotherapy_affirmation': 'Vous dites que vous avez subi une radiothérapie ? Est-ce correct ?',
  'admin.common.first_menstruation_age': 'Quel âge aviez-vous lorsque vous avez eu vos premières règles ?',
  'admin.common.age_first_child': 'À quel âge avez-vous eu le premier enfant (biologique) vivant ?',
  'admin.common.degree_relatives_breast_cancer': 'Combien de parents au 1er degré ont eu un cancer du sein ?',
  'admin.common.confirms_relative_breast_cancer': 'Vous avez répondu qu\'un parent au 1er degré a déjà eu un cancer du sein. Corriger?',
  'admin.common.did_breast_biopsy': 'Avez-vous subi une biopsie mammaire ?',
  'admin.common.how_many_biopsies_done': 'Combien de biopsies ont été faites ?',
  'admin.common.atypical_alteration_biopsies': "Est-ce qu'une biopsie a changé avec l'atypie ?",
  'admin.common.last_mammogram': 'Quand avez-vous passé votre dernière mammographie ?',
  'admin.common.observe_palpable_lesions': 'Remarquez-vous des lésions palpables ?',
  'admin.common.spontaneous_papilla_secretion': 'A-t-il une sécrétion spontanée dans les papilles ?',
  'admin.common.change_shape_breast': 'Avez-vous remarqué un changement de forme dans le sein ?',
  'admin.common.had_sexual_intercourse': 'As-tu déjà eu des rapports sexuels ?',
  'admin.common.uterus_removal_surgery': "Avez-vous déjà subi une intervention chirurgicale pour l'ablation totale de l'utérus (hystérectomie) ?",
  'admin.common.cervical_preventive_exam': 'Avez-vous eu un examen préventif du col utérin (pap) ?',
  'admin.common.changes_pap_smear': 'Avez-vous déjà changé NIC I, NIC II, NIC III HSIL ou LSIL ?',
  'admin.common.doses_vacina_hpv': 'Avez-vous reçu 3 doses de vaccin contre le VPH ?',
  'admin.common.regular_use_condoms': 'Au cours de votre vie, avez-vous utilisé régulièrement et constamment des préservatifs ou des diaphragmes ?',
  'admin.common.pain_during_intercourse': 'Ressentez-vous des douleurs intenses pendant les rapports sexuels ?',
  'admin.common.bleeding_during_intercourse': 'Avez-vous déjà remarqué des saignements pendant ou après un rapport sexuel ?',
  'admin.common.menstrual_period_bleedinge': 'Avez-vous des saignements en dehors des règles ou après la ménopause ?',
  'admin.common.lived_with_smokers': 'Avez-vous vécu avec des fumeurs plus de la moitié de votre vie ?',
  'admin.common.worked_asbestos_protection': "Avez-vous déjà travaillé avec de l'amiante sans protection adéquate ?",
  'admin.common.work_without_protection': 'Activités liées auxquelles vous avez travaillé sans protection adéquate',
  'admin.common.has_difficulty_breathing': 'Avez-vous le souffle court ou des difficultés à respirer ?',
  'admin.common.presents_continuous_cough': 'Avez-vous une toux continue ou progressive ?',
  'admin.common.relative_bowel_cancer': "Est-ce que des parents au 1er degré ont déjà eu un cancer de l'intestin ?",
  'admin.common.had_bowel_disease': "Avez-vous déjà eu une maladie intestinale nécessitant l'ablation d'un polype (par coloscopie) ?",
  'admin.common.performed_exams': 'Avez-vous déjà subi un test de recherche de sang occulte dans les selles, une rectosigmoïdoscopie ou une coloscopie ?',
  'admin.common.suffer_from_constipation': 'Souffrez-vous de constipation (intestin bouché) ou de diarrhées fréquentes ?',
  'admin.common.presence_blood_feces': 'Avez-vous déjà remarqué la présence de sang dans vos selles ?',
  'admin.common.relative_prostate_cancer': 'Est-ce que des parents au 1er degré ont déjà eu un cancer de la prostate ?',
  'admin.common.had_bowel_cancer': 'Vous avez répondu qu\'un parent au 1er degré a eu un cancer de l\'intestin. Droit?',
  'admin.common.renal_touch_exam': 'Avez-vous déjà passé un toucher rectal ou PSA ?',
  'admin.common.difficulty_urinating': "Avez-vous des difficultés à uriner ou remarquez que le jet d'urine est plus faible",
  'admin.common.blood_output_urine': 'Avez-vous remarqué du sang dans les urines ?',
  'admin.common.empty_bladder_complete': 'En urinant, avez-vous le sentiment de ne pas pouvoir vider complètement votre vessie ?',
  'admin.common.copy': 'Copier',
  'admin.common.status' : 'Statut',
  'admin.common.update' : 'Dernière mise à jour',
  'admin.common.download' : 'Télécharger',
  'admin.common.riskSelect' : 'Sélectionner un risque',
  'admin.common.observations' : 'Observations',
  'admin.common.all' : 'Tous',
  'admin.common.icon' : 'Icône',
  'admin.common.categoryColor' : 'Couleur de la catégorie',
  'admin.common.tuss' : 'TUSS',
  'admin.common.createIn' : 'Créer dans',
  'admin.common.today': "Aujourd'hui",
  'admin.common.visible' : 'Visible',
  'admin.common.subtitle' : 'Légende',
  'admin.common.specialtyColor' : 'Couleur de la spécialité',
  'admin.common.image' : 'Image',
  'admin.common.requiredFields' : 'Champs obligatoires',
  'admin.common.optionalFields' : 'Champs optionnels',
  'admin.common.minimumAge' : 'Âge minimum',
  'admin.common.maximumAge' : 'Âge maximal',
  'admin.common.active' : 'Actif',
  'admin.common.inactive' : 'Inactif',
  'admin.common.draft' : 'Version brouillon',
  'admin.common.language' : 'Langue',
  'admin.common.link' : 'Lien',
  'admin.common.subject' : 'Sujet',
  'admin.common.content' : 'Contenu',
  'admin.common.staff' : 'Équipe',
  'admin.common.customer' : 'Client',
  'admin.common.customers' : 'Clients',
  'admin.common.value' : 'Valeur',
  'admin.common.contactInfo' : 'Informations sur les données de contact',
  'admin.common.contactInfos' : 'Informations sur les données de contact',
  'admin.common.site' : 'Site',
  'admin.common.blog' : 'Blog',
  'admin.common.post' : 'Publier',
  'admin.common.posts' : 'Messages',
  'admin.common.filter': 'Filtres',
  'admin.common.continueReading' : 'En savoir plus',
  'admin.common.permalink' : 'Lien permanent',
  'admin.common.category' : 'Catégorie',
  'admin.common.categories' : 'Catégories',
  'admin.common.blogCategory' : 'Catégorie de blog',
  'admin.common.blogCategories' : 'Catégories de blogs',
  'admin.common.tag' : 'Balise',
  'admin.common.tags' : 'Balises',
  'admin.common.myAccount': 'Mon comptes',
  'admin.common.signOut': 'Sortir',
  'admin.common.personalData': 'Données personnelles',
  'admin.filter': 'Filtre de patients',
  'admin.filterTable': 'Filtres',
  'admin.filterReportsTable': 'Filtres',
  'admin.filterReports': 'Filtres',
  'admin.common.editPassword': 'Changer le mot de passe',
  'admin.common.report': 'Rapport',
  'admin.common.reports': 'Rapports',
  'admin.common.report.medicalHistory': 'Rapport des antécédents médicaux',
  'admin.common.report.risk': 'Rapport de risque',
  'admin.common.report.personalData': 'Rapport des données personnelles',
  'admin.common.report.mentalHistorical': 'Rapport des antécédents mentaux',
  'admin.common.report.mentalRisk': 'Rapport de risque mental',
  'admin.common.reportsCompany': 'Rapports entreprise',
  'admin.report.personal_data': 'Rapport (données personnelles)',
  'admin.report.risk': 'Rapport (risque)',
  'admin.report.medical.history': 'Rapport (antécédents médicaux)',
  'admin.report.mental': 'Rapport (mental)',
  'admin.history.mental' : 'Rapport (antécédents mentaux)',
  'admin.common.patientsFilter': 'Filtre de patients',
  'admin.common.unavailable': 'Indisponible',
  'admin.common.queue': "File d'attente",
  'admin.common.personalInformations': 'Informations personnelles',
  'admin.common.continue': 'Continuer',
  'admin.common.dontKnow' : 'Je ne sais pas',
  'admin.common.didNotDoIt': "Je ne l'ai pas fait",
  'admin.common.ethnicity.white' : 'Blanc',
  'admin.common.ethnicity.black' : 'descendant africain',
  'admin.common.ethnicity.brown' : 'Mixte',
  'admin.common.ethnicity.yellow' : 'Oriental',
  'admin.common.ethnicity.indigenous' : "Indien d'Amérique",
  'admin.common.others' : 'Autres',
  'admin.common.clickHere': 'Cliquez ici',
  'admin.common.result': 'Résultat',
  'admin.common.recommendations': 'Recommandations',
  'admin.common.recommendedExams': 'Examens recommandés',
  'admin.common.max' : 'Maximum',
  'admin.common.min' : 'Minimum',
  'admin.common.format' : 'Formater',
  'admin.common.integer': 'Nombre entiers',
  'admin.common.none': 'Aucun',
  'admin.common.displayBeforeAnamnesis': "Affichage avant l'anamnèse",
  'admin.common.group': 'Groupe',
  'admin.common.author': 'Auteur',
  'admin.common.selectRiskForEmails' : "Sélectionnez le niveau de risque souhaité pour déclencher l'alerte",
  'admin.common.requiredHistoryMedicalCategories': 'Les catégories que vous avez sélectionnées nécessitent toutes les catégories de dossiers médicaux',
  'admin.common.emailSent' : 'E-mail envoyé',
  'admin.common.menstrual_period_bleeding': 'Avez-vous des saignements en dehors de vos règles ou après la ménopause ?',
  'admin.common.permission.page': "Vous n'avez pas les autorisations nécessaires pour accéder à cette page.",
  'admin.common.nationality' : 'Nationalité',
  'admin.common.document' : "Document d'identification",
  'admin.filter.company.title' : 'Filtrer l’entreprise',

  /* Dashboard */
  'admin.dashboard.charts.total' : 'Total',
  'admin.dashboard.charts.ofTotal' : 'du total',
  'admin.dashboard.charts.historyCancer' : 'Utilisateurs ayant un historique des événements liés au cancer',
  'admin.dashboard.charts.patientsNeedContact' : 'Utilisateurs nécessitant des données de contact',
  'admin.dashboard.charts.anamnesisFulfillments' : "Nombre d'anamnèses terminées",
  'admin.dashboard.charts.answersByAgeAndGender' : 'Volume de répondants par âge et sexe',
  'admin.dashboard.charts.highRiskByCancerType' : 'Volume de risque élevé par type de cancer',
  'admin.dashboard.charts.bmi' : 'IMC',
  'admin.dashboard.charts.examsPending' : 'Examens manquants',
  'admin.dashboard.charts.riskAssessment' : 'Évaluation des risques',
  'admin.dashboard.charts.riskAssessmentSufix': 'Nombre de personnes identifiées à haut risque',
  'admin.dashboard.charts.highRiskSummary' : 'Nombre de risques élevés identifiés',
  'admin.dashboard.charts.highRisk' : 'Risque élevé',
  'admin.dashboard.charts.normalRisk' : 'Risque normal',
  'admin.dashboard.charts.summary' : 'Résumé',
  'admin.dashboard.charts.totalFilled' : "Nombre d'anamnèses terminées",
  'admin.dashboard.charts.totalFilledByGender': "Nombre d'anamnèses complétées par sexe",
  'admin.dashboard.charts.totalFilledByAge': "Nombre d'exécutions par tranche d'âge",
  'admin.dashboard.charts.totalDone' : 'Nombre terminé',
  'admin.dashboard.charts.totalForeseen' : 'prévu',
  'admin.dashboard.charts.highRiskChart' : 'RISQUE ÉLEVÉ',
  'admin.dashboard.charts.normalRiskChart' : 'RISQUE NORMAL',
  'admin.dashboard.charts.smoker': 'Fumeur',

  /* Companys */
  'admin.company.title': 'Entreprise',
  'admin.company.title.plural': 'Entreprises',

  /* Plano de Saúde */
  'admin.healthInsurance.title': "Plan d'assurance maladie",
  'admin.healthInsurance.title.plural': "Plans d'assurance maladie",

  /* Indicação de Empresa*/
   'admin.recommendation.title': 'Recommandation',

  /* Perfil de Acesso */
  'admin.roles.title' : "Profil d'accès",
  'admin.roles.title.plural' : 'Accéder aux profils',
  'admin.formRole.permissions' : 'Autorisations',

  /* Idiomas */
  'admin.languages.title' : 'Langue',
  'admin.languages.title.plural' : 'Langues',
  'admin.languages.slug' : 'Slug',

  /* Patients */
  'admin.patients.title' : 'Patient',
  'admin.patients.title.plural' : 'Patients',
  'admin.patients.listOfPatients' : 'Liste des patients',
  'admin.patients.importPatients' : 'Importer des patients',
  'admin.patients.exportPatients' : 'Exporter des patients',
  'admin.patients.exportText' : "Sélectionnez les filtres de votre choix et cliquez sur \'Exporter\' pour demander les rapports des patients. Le temps nécessaire pour que le document soit prêt dépend de la file d'attente dans le système et des critères d'exportation appliqués.",
  'admin.patients.fields.gender' : 'M ou F',
  'admin.patients.fields.cpf' : 'Uniquement les chiffres (05376483649) ou formatés (768.884.253-04)',
  'admin.patients.fields.phone' : 'Seuls les chiffres (41993241556) ou formatés ((41) 99324-1556)',
  'admin.patients.fields.birthday' : 'AAAA-MM-JJ (Exemple 1990-06-20)',
  'admin.patients.fields.cep' : 'Uniquement les chiffres (93530280) ou formatés (93530-280)',

  /* Prontuário */
  'admin.medicalRecords.title' : 'Dossiers médicaux',
  'admin.medicalRecords.title.plural' : 'Dossiers médicaux',
  'admin.medicalRecords.open' : 'Ouvrir les dossiers des patients',
  'admin.medicalRecords.close' : 'Fermer les dossiers des patients',
  'admin.medicalRecords.noInformation' : 'Aucune information disponible',
  'admin.medicalRecords.gail.risk1': 'Estimation du risque que la patiente développe un cancer du sein au cours des 5 prochaines années.',
  'admin.medicalRecords.gail.risk2' : 'Risque de la population générale de développer un cancer du sein dans les 5 prochaines années',
  'admin.medicalRecords.gail.risk3' : "Risque moyen pour une patiente de développer un cancer du sein avant l'âge de 90 ans",
  'admin.medicalRecords.gail.risk4': "Risque moyen de la population de développer un cancer du sein avant l'âge de 90 ans",
  'admin.medicalRecords.madeContact' : 'Contact établi avec succès',
  'admin.medicalRecords.noMoreContact' : 'Ne nécessite pas de nouveau contact',
  'admin.medicalRecords.noMoreContactQuestion': 'Nécessite un nouveau contact ?',
  'admin.medicalRecords.threeContactsMessage' : '3 tentatives de contact',

  /* Tipos de Câncer */
  'admin.cancerTypes.title' : 'Type de cancer',
  'admin.cancerTypes.title.plural' : 'Types de cancer',

  /* Estratégias */
  'admin.strategies.title' : 'Stratégie',
  'admin.strategies.title.plural' : 'Stratégies',

  /* Exames Recomendados */
  'admin.exams.title' : 'Examen',
  'admin.exams.title.plural' : 'Examens recommandés',

  /* Site */
  'admin.site.title' : 'Site',
  'admin.testimonies.title' : 'Témoignage',
  'admin.testimonies.title.plural' : 'Témoignages',
  'admin.testimonies.author' : 'Auteur du témoignage',
  'admin.specialities.title' : 'Spécialité',
  'admin.specialities.title.plural' : 'Spécialités',

  /* Anamnesis, */
  'admin.anamnesis.title': 'Anamnèse',
  'admin.anamnesis.title.plural': 'Anamnèses',

  /* Categories */
  'admin.categories.title' : 'Catégorie',
  'admin.categories.title.plural' : 'Catégories',

  /* Script */
  'admin.script.title' : 'Script',
  'admin.script.title.pluriel' : 'Scripts',
  'admin.script.categoryAlreadyAdded' : 'Catégorie déjà enregistrée',
  'admin.script.addCategory' : 'Ajouter une catégorie',
  'admin.script.addAnswer' : 'Ajouter une réponse',
  'admin.script.addQuestion' : 'Ajouter une question',
  'admin.script.editQuestion' : 'Modifier la question',
  'admin.script.editAnswer' : 'Modifier la réponse',
  'admin.script.valueAnswer' : 'Valeur de la réponse',
  'admin.script.questionType' : 'Type de question',
  'admin.script.selectTheQuestionType' : 'Sélectionner le type de question',
  'admin.script.questionTitle' : 'Nom de la question',
  'admin.script.questionDescription' : 'Description de la question',
  'admin.script.questionTypes.objective' : 'Objectif',
  'admin.script.questionTypes.discursive' : 'Discursif',
  'admin.script.answer' : 'Répondre',
  'admin.script.question': 'Question',
  'admin.script.category' : 'Catégorie',
  'admin.script.status' : 'Statut du script',
  'admin.script.requiredRegistration' : "Numéro d'enregistrement obligatoire",
  'admin.script.requiredPhone': 'Numéro de téléphone obligatoire',
  'admin.script.requiredCellphone' : 'Numéro de mobile obligatoire',
  'admin.script.requiredHealthcareInsurance' : "Informations obligatoires sur l'assurance maladie",
  'admin.script.minimumPeriod': 'Période minimum pour répondre à nouveau à ce script (en mois)',
  'admin.script.displayResultPageWithRisks': 'Afficher la page avec les résultats de risque',
  'admin.script.sendResultForEmail': 'Envoyer le résultat par email',
  'admin.script.sendResultForEmailPeriod': "Délai d'envoi des résultats (en jours)",
  'admin.script.sendReminderEmail' : 'Envoyer un rappel par e-mail',
  'admin.script.sendReminderEmailPeriod' : "Délai d'envoi (en jours)",
  'admin.script.sendReminderEmailQuantity' : "Nombre d'envois",
  'admin.script.resultPageContent': 'Contenu de la page de résultats',
  'admin.script.initialPageContent' : 'Contenu de la page de destination',
  'admin.script.displayFooter' : 'Afficher les notes de bas de page',
  'admin.script.questionGroupError': "Un groupe ne peut pas être enregistré dans d'autres groupes",
  'admin.script.additionalInfoPageContent' : 'Informations supplémentaires',
  'admin.script.displayAdditionalInfoPageContent' : 'Afficher des informations supplémentaires',
  'admin.anamnesis.coming_soon': 'Diagnostic disponible sous peu',

  /* Disparo de Script */
  'admin.scriptSend.title' : 'Script de déclenchement',
  'admin.scriptSend.exportCsvFile' : 'Télécharger le fichier CSV',
  'admin.scriptSend.sendEmail' : 'Envoyer un e-mail',
  'admin.scriptSend.sentScripts' : 'Scripts déclenchés',

  /* Membros da Equipe */
  'admin.team.title' : "Membre de l'équipe",
  'admin.team.title.plural' : "Membres de l'équipe",

   /* Link Youtube*/
  'admin.common.linkVideo' : 'Lien YouTube',

  /* Link Agendamento Exame*/
  'admin.common.link.agendamento.exame': 'Lien vers la planification des examens',

  /* ValidActions */
  'validations.required': 'Champ obligatoire',
  'validations.integer': 'Le champ doit contenir un nombre enter',
  'validations.integerBetween': 'Insérez un nombre entre {min} et {max}',
  'validations.email': 'E-mail invalide',
  'validations.link': 'Lien invalide',
  'validations.phone': 'Téléphone invalide',
  'validations.cpf': 'CNI invalide',
  'validations.cnpj': 'CNPJ',
  'validations.cep': 'Code Postal invalide',
  'validations.date': 'Date invalide',
  'validations.password': 'Veuillez saisir au moins 8 caractères avec des chiffres, des lettres majuscules et minuscules',
  'validations.passwordConfirm': 'Les mots de passe doivent être identiquess',
  'validations.fileType': 'Type de fichier non pris en charges',
  'validations.enter.full.name': 'Entrez votre prénom et votre nom de famille',
  'validations.enter.not.special.character': 'Le nom ne peut pas contenir de caractères spéciaux',
  'validations.required.age': 'Pour poursuivre l\'inscription, vous devez avoir plus de 18 ans.',

  /* Feedback */
  'feedback.success': 'Action réalisée avec succès !',
  'feedback.error': 'Oups, un problème est survenu !',

  /* Field Hints */
  'hints.cpf': 'Insérez votre CNI avec ou sans ponctuation',
  'hints.cpfNumberOnly': "Insérez votre CPF (numéro d'identification fiscal brésilien) sans ponctuation (chiffres uniquement)",
  'hints.number': 'Seulement des chiffres',
  'hints.email': 'Insérez votre e-mail (Ex : example@example.com)',
  'hints.height': 'Renseignez votre taille en centimètres (cm)',
  'hints.weight': 'Insérez votre poids en kilogrammes (kg)',
  'hints.dateOfBirth': 'La date doit être au format JJ/MM/AAAA (exemple : 25/10/1950)',

  /* Anamnesis, */
  'q.startPrevention': 'Commencer à remplir',
  'q.backToAnamnese': "Retourner à l'anamnèse",
  'q.initialText' : 'Texte initial du client',
  'q.wizardStep': 'Étape {progress} sur {count}',
  'q.acceptContact': "J'accepte d'être contacté par téléphone ou par e-mail",
  'q.termsAccept': "J'accepte les",
  'q.termsOfService': 'Termes de consentement',
  'q.accept.receive': "J'accepte de recevoir",
  'q.additional.information.whatsapp' : 'informations complémentaires de Previneo via WhatsApp',
  'q.termsAndConditions': 'Termes et conditions',
  'q.termsAndConditionsUnimed': 'TERME DE CONSENTEMENT ET D’AUTORISATION',
  'q.acceptRequired': 'Vous devez accepter les termes',
  'q.brca.question': "Avez-vous déjà fait un examen génétique (connu sous le nom de BRCA1 ou BRCA) pour savoir s'il existe un changement/mutation qui pourrait augmenter votre risque de développer un cancer et, si oui, le résultat était-il positif ?",
  'q.brca.description': "La mutation des gènes BRCA1 ou BRCA2 augmente significativement le risque de certains types de cancer (sein, ovaire, prostate, côlon ou pancréas). Cet examen génétique peut être réalisé à partir d'échantillons de sang ou de salive pour savoir si la personne est porteuse de cette mutation. Cette mutation survient généralement chez les personnes ayant des antécédents de cancer chez les membres de la famille proche. On estime qu'environ 5 % à 10 % de tous les cas de cancer du sein et de l'ovaire sont causés par cette mutation",
  'q.ethnicity.question': 'Course',
  'q.ethnicity.description': 'On sait que les personnes afro-descendantes présentent des risques plus élevés de développer un cancer de la prostate',
  'q.cancerHistory.question': 'Avez-vous des antécédents personnels de cancer ?',
  'q.cancerHistory.description': "On sait que les personnes ayant déjà eu des événements cancéreux ont un risque élevé de rechute ou éventuellement de nouvelle tumeur du même type. Pour cette raison, afin de calculer votre risque, il est important d'établir si vous avez déjà eu cette maladie dans votre vie.",
  'q.cancerHistory.whatCancer': 'Quel type de cancer ?',
  'q.cancerHistory.specifyCancer' : 'Spécifier le type de cancer',
  'q.helloPatient': 'Bonjour, {name}',
  'q.patientIntroText': "C'est le moment de faire plus ample connaissance et de connaître un peu vos antécédents médicaux. Remplissez le formulaire de manière précise pour que votre évaluation de risque soit la meilleure possible. C'est parti ?",
  'q.startAvaliation': "Commencer l'évaluation",
  'q.profileUnmatch': "Les anamnèses de cette campagne ne sont pas en adéquation avec votre profil. Merci de nous avoir aidé jusqu'ici !",
  'q.finishAvaliation': "Finaliser l'évaluation",
  'q.toUpdateYourData': 'pour actualiser vos données personnelles',
  'q.stepFinished': 'Encore une étape terminée !',
  'q.youAreDoingRight': "Vous remplissez correctement les données d'anamnèse. Continuez comme ça!",
  'q.continueAvaliation' : "Continuer l'évaluation",
  'q.toAnswerTheQuestions': 'pour répondre aux questionss',
  'q.thanks': "Merci d'avoir réalisé cette évaluation !",
  'q.sentForEmail': 'Le résultat a été envoyé à votre adresse e-mail.',
  'q.welcomeUser': 'Bienvenu, {name}',

  'admin.dashboard.chart.foreseen' : 'prévu',
  'admin.dashboard.chart.fulfilled' : 'Terminé',
  'termsUnimed' : `<p>Consinto e autorizo ​​expressamente a coleta dos meus dados pessoais através de questionário de saúde a ser disponibilizado via link pela empresa
  <b>PREVINEO PROGRAMAS DE SAÚDE S.A.</b>
  , que gira sangloter une denominação fantasia de
  <b>PREVINEO</b>
  , pessoa jurídica de direito privado, inscrita no CNPJ/MF sob no 22.177.298/0001-42, com sede na Rua Petit Carneiro, no 1122, sala 405, Bairro Água Verde, Curitiba/PR, CEP 80240-050, responsável pela coleta, tratamento e armazenamento dos referidos dados, bem como aceito, após o recebimento do resultado da minha classificação de risco, o contato, em horário comercial, de profissional da saúde da empresa
  <b>PREVINEO</b>
  que fornecerá orientações sobre prevenção das doenças objeto do estudo. Ainda, consinto e autorizo ​​expressamente o compartilhamento dos meus dados pessoais coletados com a
  <b>UNIMED CURITIBA – SOCIEDADE COOPERATIVA DE MÉDICOS</b>
  , cooperativa de 1o grau devidamente constituída, registrada na OCEPAR - Organização das Cooperativas do Estado do Paraná sob no 80 - 29/12/72, atuando como Operadora de Planos Privados de Assistência à Saúde, registrada na ANS - Agência Nacional de Saúde Suplementar sob no 30470-1, enregistrée à la Junta Comercial do Paraná sob no 4140000865-7 et cadastrada no CNPJ/MF sob no 75.055.772/0001-20, com sede na Avenida Affonso Penna, no 297, Bairro Tarumã, Curitiba/PR, CEP 82530-280, telefone (41) 3021-9100, a fim de que esta possa promover estudos relacionados à estratificação de riscos oncológicos.</p>
   <p>Assim, em total observância à Lei Geral de Proteção de Dados (Lei no 13.709/2018), manifesto-me livre, informada e inequivocadamente no sentido de autorizar a
   <b>UNIMED CURITIBA</b> e un
   <b>PREVINEO</b>
   un :</p>
  <p>a) tratar todos os meus dados pessoais e dados pessoais sensíveis como sigilosos e confidenciais, protegendo-os e restringindo a sua utilização aos fins estabelecidos no presente Termo, prevenindo e evitando que tais dados sejam usados ​​para quaisquer outros fins ou divulgados a terceiros não envolvidos no objeto do estudo ;</p>
   <p>b) compartilhar as informações sobre o meu estado de saúde, durante o desenvolvimento do estudo, a terceiros contratados envolvidos no processo ;</p>
   <p>c) Disponibilizar os dados clínicos de meu acompanhamento, com a garantia da preservação de minha identificação, para fins estatísticos e de pesquisas em saúde com o objetivo de contribuir para o desenvolvimento de melhores práticas em saúde, ainda qua minha condição de beneficiário(a) da <b>UNIMED CURITIBA</b> venha a não mais existir futuramente.</p>`,
  'terms': '' +
    '<p>' +
    "   <strong>Politique de confidentialité et autorisation d'utilisation</strong>" +
    '</p>' +
    '<p>' +
    " Nous sommes une entreprise dédiée à la promotion de l'information médicale, ciblée sur la prévention de certains types de cancer au Brésil. Nous le faisons parce que nous pensons que plus il y a d'informations, plus grandes sont les chances d'un diagnostic précoce, ce qui est fondamental pour la prévention du cancer.  " +
    '</p>' +
    '<p>' +
    "  Le formulaire dans lequel nous divulguons les informations est un formulaire préformaté, basé sur la littérature médicale et des méthodes reconnues au niveau national et international pour vérifier la présence d'un ou plusieurs facteurs de risque qui augmentent le risque estimé de développer une pathologie. A cette fin, nous avons besoin de certaines informations personnelles vous concernant, notamment vos données d'identification, vos antécédents familiaux de pathologies ainsi que des données en lien avec votre vie quotidienne." +
    '</p>' +
    '<p>' +
    "   Par exemple, nous aurons besoin de savoir si vous fumez ou avez déjà fumé, si vous avez eu des relations sexuelles et si vous avez déjà subi un test préventif. Ces informations et d'autres sont essentielles pour obtenir une estimation correcte du risque de développer les types de cancer applicables aux formulaires." +
    '</p>' +
    '<p>' +
    '   <strong>Comment vos données seront-elles utilisées ?</strong>' +
    '</p>' +
    '<p>' +
    "   Toutes les données personnelles que vous fournissez seront conservées dans le secret absolu et ne seront utilisées que dans le but d'obtenir le résultat concernant votre risque de développer les pathologies testées. Cela s'applique aussi bien aux données d'identification (telles que votre nom, votre adresse e-mail, votre âge, etc.) qu'aux réponses aux formulaires, c'est-à-dire votre dossier médical ou vos données de vie quotidienne. Nous utiliserons votre e-mail exclusivement dans le but d'envoyer les résultats et ne transmettrons pas ces informations à des tiers ni ne les utiliserons à d'autres fins." +
    '</p>' +
    '<p>' +
    ' <strong>Qui a accès à mes données ?</strong>' +
    '</p>' +
    '<p>' +
    " Afin d'obtenir les résultats, vos réponses seront analysées automatiquement et vérifiées manuellement par les collaborateurs de Previneo, en lien avec le domaine de la santé. L'outil a été développé par des médecins, mais l'analyse des données n'est pas faite par eux, puisque nous ne fournissons pas de services de santé, mais seulement la divulgation d'informations. Nos employés s'engagent à respecter la confidentialité des données et notre politique de confidentialité. " +
    '</p>' +
    '<p>' +
    ' Si vous participez à cette évaluation dans le cadre du programme de santé de votre employeur, les médecins responsables (et uniquement les médecins) de votre employeur pourront avoir accès aux résultats dans le respect total et absolu du Code de déontologie médicale.' +
    '</p>' +
    '<p>' +
    " <strong>Et si j'autorise l'utilisation des données sous forme de statistiques ?</strong>" +
    '</p>' +
    '<p>' +
    " En remplissant notre formulaire, nous demandons qu'en plus d'accepter nos conditions d'utilisation, vous acceptiez également que les résultats respectifs puissent constituer une base de données pour l'établissement de politiques de prévention. Après tout, en plus de fournir des informations adéquates, notre outil peut également servir à identifier les défauts ou les corrections nécessaires dans les méthodes de diagnostic du cancer au Brésil." +
    '</p>' +
    '<p>' +
    " Dans ce cas, si vous êtes d'accord, les résultats obtenus en remplissant votre formulaire peuvent être utilisés pour constituer des statistiques sans identification personnelle ni données sensibles. Nous garderons un secret absolu sur ces données.</p>" +
    '<p>' +
    " Par exemple, si vous avez 20 ans, ne fumez pas et vivez à Curitiba-PR, votre risque de développer un cancer du poumon sera considéré comme normal pour votre âge et votre état. Dans ce cas, votre résultat composera la statistique en tant que personne à risque normal pour le développement de cette pathologie, sans aucune forme d'identification, uniquement liée à la région où vous résidez." +
    '</p>' +
    '<p>' +
    ' Tout doute ou clarification peut être réglé au préalable via notre e-mail <strong>contato@previneo.com.br</strong>. ' +
    '</p>',
  
  'admin.dashboard.chart.not.fulfilled' : 'Pas fait',
  'admin.patientsHistory.currentSituation' : 'Situation actuelle',
  'admin.common.nextContact' : 'Prochain contact',
  'admin.common.contactReason' : 'Raison du contact',
  'admin.common.typesCancer' : 'Types de cancer',
  'admin.common.patientHistoryExams' : 'Examens effectués',
  'admin.common.saveContact' : 'Enregistrer le contact',
  'admin.patientsHistory.status.pending': 'Commencer',
  'admin.patientsHistory.status.inProgress': 'Mettre en pause',
  'admin.patientsHistory.status.done': 'Finaliser',
  'admin.common.contactMade': 'Contacté ?',
  'admin.common.contactMadeOk': 'Oui',
  'admin.common.contactMadeNo' : 'Non',
  'admin.common.contactStatus' : 'Statut',
  'admin.common.contactStatuses' : 'Statut',
  'admin.common.managePatientHistory' : 'Contact',
  'admin.common.patientInfo' : 'Données du patient',
  'admin.patients.deletedPatients' : 'Patients supprimés',
  'admin.common.motivo' : 'Raison',
  'admin.common.hidePatientInfo' : 'Masquer les informations de contact',
  'admin.common.displayPatientInfo' : 'Afficher les informations de contact',
  'admin.common.noPermission': 'Vous n\'êtes pas autorisé à accéder à cette page',
  'admin.patientsHistory.titlePlural' : 'Dossiers médicaux',
  'admin.patientsHistory.contact' : 'Contact',
  'admin.patientsHistory.contactReasonPlural' : 'Motifs du contact',
  'admin.common.registerNew': "S'inscrire",
  'admin.common.logoRight' : 'Logo à droite',
  'admin.common.logoLeft' : 'Logo à gauche',
  'admin.common.justifications.title' : 'Justification',
  'admin.common.justifications.title.plural': 'Motifs',
  'admin.common.file' : 'Fichier',
  'admin.common.fileFront' : 'Fichier avant',
  'admin.common.roles' : "Profils d'accès",
  'admin.common.ageRange' : "tranche d'âge",
  'admin.permissions.title.plural' : 'Autorisations',
  'admin.permissions.title' : 'Autorisation',
  'admin.justifications.title' : 'Justification',
  'admin.justifications.title.plural': 'Motifs',
  'admin.faixasimc.abaixo' : 'En dessous du poids',
  'admin.faixasimc.normal' : 'Poids normal',
  'admin.faixasimc.acima' : 'Au-dessus du poids',
  'admin.faixasimc.obesidadeI' : 'Obésité I',
  'admin.faixasimc.obesidadeII' : 'Obésité II',
  'admin.faixasimc.obesidadeIII' : 'Obésité III',
  'admin.common.lastAnamnesis' : "Date de l'anamnèse",
  'admin.common.reSendEmail': "Renvoyer l'e-mail",
  'admin.common.normal' : 'Normal',
  'admin.common.entity' : 'Entité',
  'admin.common.entities' : 'Entités',
  'admin.common.numberOfEmployees': 'Quantité de collaborateurs',
  'admin.common.confirmation' : 'Confirmation',
  'admin.common.confirmationNotFound': 'Registre introuvable, cliquez sur le bouton ci-dessous pour recevoir un nouveau lien.',
  'admin.common.editUser': "Modifier l'utilisateur",
  'admin.common.processingAnamnese': 'Traitement des résultats en cours',
  'admin.script.questionTypes.multipleChoice': 'Choix multiple',
  'admin.script.questionTypes.choiceList': "Liste d'options",
  'admin.dataTable.label.report': "Rapport",
  'admin.common.report.Mental_Risk': 'Risque mental',
  'admin.common.report.Mental_Historical': 'Antécédents mentaux',
  'admin.common.report.Personal_Data': 'Données personnelless',
  'admin.common.report.Medical_History' : 'Antécédents médicaux',
  'admin.anamnesis.medicalHistory': 'Données des antécédents médicaux',
  'admin.common.report.Medical_Risk' : 'Risque',
  'admin.common.gender.biologic': 'Genre biologique',
  'admin.anamnesis.medicalHistory.button.update': 'Actualiser les données', 
  'admin.anamnesis.medicalHistory.button.correct': "C'est correct",
  'admin.common.share': 'Partager',
  'admin.common.Share_Diagnosis': 'Partager le résultat',
  'admin.common.put address' : 'Renseignez une adresse e-mail ci-dessous :',
  'admin.common.initialDate': 'Date de début',
  'admin.common.password.new': 'Nouveau mot de passe',
  'admin.common.gender.social': 'Genre social',
  'admin.common.qtd_colaboradores': 'Quantité de collaborateurs',
  'admin.anamnesis.medicalHistory.description': 'Vous avez complété les données ci-dessous concernant les antécédents médicaux',
  'admin.comon.select' : 'Sélectionnez...',
  'admin.enter.company.name' : 'Entrez le nom de votre entreprise',
  'admin.login.with' : 'Connectez-vous avec',
  'admin.or.register' : 'ou',
  'admin.sign.in.microsoft' : 'Connectez-vous avec Microsoft',
  'admin.sign.in.google' : 'Connectez-vous avec Google',
  'admin.write.with.microsoft': "S'inscrire auprès de Microsoft",
  'admin.write.with.google': "S'inscrire auprès de Google",
   'admin.common.whatsApp.receptive': 'WhatsApp - Réceptif',
  'admin.common.whatsApp.active': 'Whatsapp - Actif',

  'users.admin.companies.title' : 'Entreprise',
  'users.forms.passwordConfirm.title': 'Créer un mot de passe',
  'users.delete.data.cantrollback.title': 'Vous êtes sûr ?',
  'users.admin.companies.title.plural' : 'Entreprises',
  'users.delete.data.button': 'Sortir du système',
  'users.anamneses.notfound': 'Anamnèse non localisée ou indisponible',
  'users.delete.data.title': 'Sortir du système',
  'users.dashboard.title.one': "Prévenir, c'est Vivre Mieux et Plus Longtemps.",
  'users.dashboard.welcome.female': 'Bienvenue, {name}!',
  'users.dashboard.welcome.male': 'Bienvenue, {name}!',
  'users.dashboard.card.type': 'Entretien digital',
  'users.login.welcome': 'Bienvenue ! Vous venez de faire le premier pas pour vivre mieux et plus longtemps !',
  'users.dashboard.card.typeWithDiagnosticName': 'Entretien digital: {diagnosticName}',
  'users.results.refillWarning': 'Nous avons constaté que vos antécédents médicaux ont été actualisées.',
  'users.results.refillWarningRec': "Nous vous recommandons de refaire l'entretien digital.",
  'users.results.smallHeaderMessage': '{name}, Voilà votre résumé :',
  'users.results.hello.mental': 'Bonjour {name},',
  'users.results.smallHeaderMessage.mental': "Tout d'abord, félicitations ! Si vous lisez ceci, cela signifie que vous avez répondu à toutes les questions de notre dépistage de la santé mentale. Vous essayez activement d'en savoir plus sur vous-même.",
  'users.results.card.assessedRisk': 'Risque évalué',
  'users.results.card.levelOfAttention': "Niveau d'attention",
  'users.results.card.examsPendents': 'Examens ou consultations en attente',
  'users.results.openDetails': "Afficher les détails de l'entretien digital",
  'users.results.expertRecommendations': "N'oubliez pas de consulter les recommandations de nos spécialistes",
  'users.results.expertRecommendations.mental': "Les conditions de santé comportementale peuvent toujours être améliorées et la plupart des gens pensent qu'ils peuvent apporter des changements positifs dans leur vie avec l'aide d'un professionnel et le soutien de leur famille et de leurs amis.",
  'users.results.othersDiagnosticsAvailable': 'Autres entretiens digitaux disponibles',
  'users.results.fillAgain': 'Remplir à nouveau',
  'users.login.header.message': 'Connectez-vous avec votre identifiant et votre mot de passe',
  'users.results.card.seeResult': 'Réviser les résultats',
  'users.results.card.fillText': 'Commencer à remplirs',
  'users.with.deleted': 'Avec supprimé',
  'users.not.deleted' : 'Non supprimé',
  'users.login.button.enter': 'Connecter',
  'users.results.diagnostic.title': 'Résultat du diagnostic',
  'users.results.diagnostic.title.plural': 'Résultat du diagnostics',
  'users.results.everHadSymptoms': 'Présente des symptômes',
  'users.results.diagnosticType': 'Type de diagnostic',
  'users.delete.data.description': 'Cela a provoqué la suppression permanente de vos données personnelles (y compris nom, e-mail, téléphone et autres) de notre base de données',
  'users.delete.data.cantrollback.description': 'Cette action ne pourra pas être annulée.',
  'users.dashboard.roche.longtext': "Si vous êtes collaborateur de la Roche et avez des questions après avoir rempli le formulaire, vous pourrez contacter le médicin du travail via l'e-mail : {e-mail}. Si non, vous pourrez consulter le médecin de votre choix",

  /*New Screen Dashbord */
  'admin.common.registered' : 'Enregistré',
  'admin.common.total' : 'Total',
  'admin.common.historic' : 'Antécédents médicaux',
  'admin.common.accomplished' : 'Accompli',
  'admin.common.no-accomplished' : 'Non réalisé',
  'admin.age' : "Plage d'âge",
  'admin.female.age.group' : "Groupe d'âge féminin",
  'admin.male.age.group' : "Groupe d'âge masculin",
  'admin.common.masc' : "Masculin",
  'admin.common.fem' : 'Féminin',
  'admin.common.summary' : 'Résumé',
  'admin.common.risk.assessment' : 'Évaluation des risques',
  'admin.common.number.of.people' : 'Nombre de personnes identifiées comme à haut risque.',
  'admin.common.low.risk' : '(Risque faible)',
  'admin.common.smoker' : 'Symptômes',
  'admin.common.exams.are.missing' : 'Examens manquants',
  'admin.common.high.risk' : '(Risque élevé)',
  'admin.common.exams.missing' : 'Examens en Attente',
  'admin.common.one.year' : '+1 an',
  'admin.common.late' : 'En retard',
  'admin.common.in.day' : 'Dans la journée',
  'admin.common.never' : "Je ne l'ai jamais fait.",
  'admin.common.qtd' : 'Nombre de personnes identifiées dans {risk}.',
  'admin.common.mama' : 'Sein',
  'admin.common.uterine' : 'Utérin',
  'admin.common.lung' : 'Poumon',
  'admin.common.colon' : '2-points',
  'admin.common.prostate' : 'Prostate',
  'admin.high.qtd' : '- Nombre de {risk} identifiés par spécialité.',
  'admin.common.consolidated' : 'Consolidé',
  'admin.common.qtd.age' : 'Nombre de personnes identifiées par âge.',
  'admin.common.qtd.exams.missing.1': 'Nombre de Personnes identifiées aux Tests',
  'admin.common.qtd.exams.missing.2': 'En attente (suivi retardé).',
  'admin.common.amount' : 'Quantité',
  'admin.common.cardio' : 'Cardio',
  'admin.common.mental' : 'Mental',
  'admin.interviews' : 'Entretiens',
  'admin.common.monday' : 'Lundi',
  'admin.common.tuesday' : 'mardi',
  'admin.common.wednesday' : 'mercredi',
  'admin.common.thursday' : 'jeudi',
  'admin.common.friday' : 'vendredi',
  'admin.filling.interviews' : 'Remplir les entretiens',
  'admin.common.qtd.filling.interviews' : "Nombre d'entretiens réalisés dans la période.",
  'admin.common.qtd.users' : "Nombre d'utilisateurs",
  'admin.common.funnel' : "Utiliser l'entonnoir",
  'admin.common.data.found' : 'Aucune donnée trouvée !',
  'admin.common.only.air' : 'Only Air',
  'admin.common.exams.only' : 'Examens uniquement',
  'admin.common.only.symptom' : 'Symptôme uniquement',
  'admin.common.exam.air' : 'Air + Examen',
  'admin.common.air.symptom' : 'Air + Symptôme',
  'admin.common.exam.symptom' : 'Examen + Symptôme',
  'admin.common.air.exam.symptom' : 'Air + Examen + Symptôme',
  'admin.common.deleted.users' : 'Utilisateurs supprimés',
  'admin.high.qtd.segment': '- Montant de {risk} recensés par le suivi.',
  'admin.common.information' : 'Information complémentaire',

   /*Dashboard Api*/
   'registered' : 'Inscrit',
   'medical history' : 'Antécédents médicaux',
   'interviews' : 'Entrevues',
   'male' : 'Masculin',
   'female' : 'Féminin',
   'all interviews' : 'Tous les entretiens',
   'mental' : 'Mental',
   'cardio' : 'Cardio',
   'mama' : 'Sein',
   'prostate' : 'Prostate',
   'lung' : 'Poumon',
   'uterine' : 'Utérin',
   'colon' : 'Côlon',
   'only air' : "Seul l'air",
   'exams only' : 'Examens uniquement',
   'only symptom' : 'Seul symptôme',
   'exam air' : 'Air + examen',
   'air symptom' : 'Air + Symptôme',
   'exam symptom' : 'Examen + Symptôme',
   'air exam symptom' : 'Air + Examen + Symptôme',
   'normal risk' : 'Risque normal',
   'admin.prostate' : 'Cancer de la prostate',
   'admin.uterine' : "Cancer de l'utérus",
   'admin.medical.male' : 'Antécédents médicaux (Homme)',
   'admin.medical.female' : 'Antécédents médicaux (Femme)',
   'admin.breast.cancer': 'Cancer du sein',
   'admin.lung.cancer': 'Cancer du poumon',
   'admin.colon.cancer' : 'Cancer du côlon',
   'admin.mental' : 'mental',
   'admin.cardio' : 'Cardio',
   'admin.all.risks' : 'Tous les risques',
   'admin.high.risk' : 'Risque élevé',
   'admin.medium.risk' : 'Risque moyen',
   'admin.low.risk' : 'Risque faible',
   'admin.common.contact_us' : 'Contactez-nous',
   'admin.common.how_about' : 'Que diriez-vous de prendre rendez-vous pour parler de vos résultats ou mettre à jour vos examens',
  
  /* Interviews BD*/
  'Você já fez exame de toque retal ou PSA?': 'Avez-vous déjà eu un toucher rectal ou un examen PSA ?',
  'Câncer de Útero': "Cancer du col de l'utéruss",
  'Histórico Médico' : 'Antécédents médicaux',
  'Utilizaremos esse dado para o cálculo do IMC, que interfere na avaliação de risco de alguns tipos de câncer.' : "Nous utiliserons ces données pour calculer l'IMC, qui interfère dans l'évaluation du risque de certains types de cancer.",
  'Qual é a sua altura ? (em CM)' : 'Quelle est votre taille ?',
  'Qual é o seu peso ? (em KG)' : 'Quel est votre poids ?',
  'Você faz uso de algum medicamento regular?' : 'Prenez-vous un traitement régulier ?',
  'Sim' : 'Oui',
  'Não' : 'Non',
  'Você fuma ou já fumou charutos?' : 'Fumez-vous ou avez-vous déjà fumé, Cigares ?',
  'O tabagismo (cigarro ou charuto) está diretamente ligado ao risco de desenvolvimento de vários tipos de câncer. Utilizaremos esses dados para avaliar qual estratégia é recomendada para a redução do seu risco de desenvolver câncer de pulmão.' : "Le tabagisme (cigarettes ou cigares) est directement lié au risque de développer plusieurs types de cancer. Nous utiliserons ces données pour évaluer quelle stratégie est recommandée pour réduire votre risque de développer un cancer du poumon.",
  'Você fuma ou já fumou cigarros?' : 'Fumez-vous ou avez-vous déjà fumé des cigarettes ?',
  'Pratica alguma atividade física?' : "Faites-vous régulièrement de l'exercice ou pratiquez-vous une activité physique?",
  'Atividade física, juntamente com outros hábitos de vida saudáveis, reduz a chance de desenvolvimento de vários tipos de câncer.' : "L'activité physique, ainsi que d'autres habitudes de vie saines, réduisent le risque de développer plusieurs types de cancer.",
  'Você já realizou um exame genético (conhecido como BRCA1 ou BRCA2) para saber se possui uma alteração/mutação que aumenta seu risco de desenvolver câncer, e o resultado foi positivo?' : "Avez-vous déjà subi un test génétique (appelé BRCA1 ou BRCA2) pour savoir si vous présentez une altération/mutation qui augmente votre risque de développer un cancer, et le résultat s'est avéré positif ?",
  'A mutação nos genes BRCA1 ou BRCA2 aumenta significativamente o risco para alguns tipos de câncer (mama, ovário, próstata, cólon e pâncreas). Este é um exame genético que pode ser realizado através do sangue ou da saliva para que a pessoa saiba se possui a mutação.' : "La mutation des gènes BRCA1 ou BRCA2 augmente significativement le risque de certains types de cancer (du sein, des ovaires, de la prostate, du côlon et du pancréas). Il s’agit d’un test génétique qui peut être effectué avec du sang ou de la salive pour permettre à la personne de savoir si elle est porteuse de la mutation.",
  'Você respondeu que o teste para pesquisa de mutação BRCA foi positivo. Está correto?' : 'Vous avez répondu que le test de mutation BRCA était positif. Est-ce exact ?',
  'Você já teve câncer?' : 'Avez-vous déjà eu un cancer?',
  'Sabe-se que quem já foi acometido por câncer apresenta um risco elevado de recidiva ou até mesmo de desenvolver um novo tumor do mesmo tipo. Por isso, é importante, para o cálculo de seu risco, saber se você já foi afetado por essa doença em sua vida.' : "On sait que toute personne ayant déjà été touchée par un cancer présente un risque élevé de récidive, voire de développer une nouvelle tumeur du même type. Il est donc important, pour calculer votre risque, de savoir si vous avez déjà été touché par cette maladie dans votre vie.",
  'Selecione todas as alternativas sobre o histórico de câncer em sua família:' : "Veuillez sélectionner toutes les alternatives concernant vos antécédents familiaux de cancer :",
  'Avaliaremos essa informação para determinar o seu risco de portar alguma mutação genética familiar que aumenta o risco de desenvolver alguns tipos de câncer.' : "Nous évaluerons ces informations pour déterminer votre risque d’être porteur d’une mutation génétique familiale qui augmente le risque de développer certains types de cancer.",
  'Qual o seu consumo diário de bebida alcoólica?' : "Quelle est votre consommation quotidienne d'alcool ?",
  '1 drink = 350ml de cerveja/150ml de vinho/45ml de bebida destilada. O consumo de bebida alcoólica está associado ao aumento de risco de alguns tipos de câncer.' : '1 verre = 350ml de bière/150ml de vin/45ml de spiritueux. La consommation de boissons alcoolisées est associée à un risque accru de certains types de cancer.',
  'Você já teve algum problema cardíaco?' : 'Avez-vous déjà eu un problème cardiaque ?',
  'Você tem histórico de problemas emocionais?' : 'Avez-vous des antécédents de problèmes émotionnels?',
  'Resultado Positivo' : 'Résultat positif',
  'Resultado Negativo' : 'Résultat négatif',
  'Não sei se fiz o exame' : "Je ne sais pas si j'ai fait le test",
  'Não fiz o exame' : "Je n'ai pas fait le tests",
  'Sem histórico familiar' : "Pas d'antécédents familiaux",
  'Algum dos seus parentes de 1 grau (mãe, pai, irmã, irmão, filha ou filho) tiveram câncer de mama ou ovário' : "L'un de vos parents au premier degré (mère, père, sœur, frère, fille ou fils) a eu un cancer du sein ou des ovaires",
  'Qualquer parente seu já teve câncer de mama bilateral' : 'Un de vos proches a déjà eu un cancer du sein bilatéral',
  'Algum homem da sua família teve câncer de mama' : 'Un homme de votre famille a eu un cancer du seins',
  'Alguma mulher em sua família teve câncer de mama e de ovário' : 'Une femme de votre famille a eu un cancer du sein ou des ovaires',
  'Alguma mulher em sua família teve câncer de mama antes dos 50 anos de idade' : "Une femme de votre famille a eu un cancer du sein avant l'âge de 50 ans.",
  'Você tem 2 ou mais parentes com câncer de mama e/ou ovário' : "Vous avez au moins deux parents atteints d'un cancer du sein et/ou des ovaires.",
  'Você tem 2 ou mais parentes com câncer de mama e/ou intestino' : "Vous avez au moins deux parents atteints d'un cancer du sein et/ou de l'intestin.",
  'Não tenho hábito de ingerir bebidas' : "Je n’ai pas l’habitude de boire",
  'Menos de um drink por dia' : "Moins d'un verre par jour",
  'Entre 1 e 2 drinks por dia' : "Entre 1 et 2 verres par jours",
  'Entre 2 e 3 drinks por dia' : 'Entre 2 et 3 verres par jour',
  'Entre 3 e 4 drinks por dia' : 'Entre 2 et 3 verres par jour',
  'Mais de 4 drinks por dia': 'Plus de 4 verres par jour',
  'After completing the form below, you will receive an email link to reset your password.': 'Après avoir rempli le formulaire ci-dessous, vous recevrez un courriel contenant un lien pour réinitialiser votre mot de passe.',
  'Quais remédios?': 'Lesquels prenez-vous actuellement ?',
  'CONTROLE DA PRESSAO ARTERIAL': 'CONTRÔLE DE LA PRESSION ARTERIELLE',
  'CONTROLE DO COLESTEROL E/OU TRIGLICERIDEOS' : 'CONTRÔLE DU CHOLESTÉROL ET/OU DES TRIGLYCÉRIDES',
  'TRATAMENTO DO DIABETES' : 'TRAITEMENT DU DIABÈTE',
  'ANTIALERGICOS' : 'ANTIALERGIQUES',
  'CORTICOIDES' : 'CORTICOIDES',
  'MEDICAMENTOS PARA ASMA E/OU BRONQUITE' : "MÉDICAMENTS POUR L'ASTHME ET/OU LA BRONCHITE",
  'ASPIRINA' : 'ASPIRINE',
  'CONTROLE DO COLESTEROL E / OU TRIGLICERIDEOS' : 'CONTRÔLE DU CHOLESTÉROL ET/OU DES TRIGLYCÉRIDES',
  'ANALGESICOS' : 'ANALGESIQUES',
  'MEDICAMENTOS PARA DORES DE CABECA' : 'MÉDICAMENTS POUR LES MAUX DE TÊTE',
  'ANTI INFLAMATORIOS' : 'ANTINFLAMMATOIRES',
  'TRATAMENTO DE DOENCAS DA TIREOIDE' : 'TRAITEMENT DES MALADIES THYROÏDIENNES',
  'TRATAMENTO DE GASTRITE, ÚLCERA OU REFLUXO' : 'TRAITEMENT DE LA GASTRITE, DES ULCÈRES OU DU REFLUX',
  'DOENCAS REUMATOLOGICAS OU DAS ARTICULACOES ' : 'MALADIES RHUMATOLOGIQUES OU ARTICULAIRES',
  'COLIRIOS' : 'COLLYRES',
  'DOENCAS DO CORACAO - RITMO CARDIACO' : 'MALADIES CARDIAQUES - RYTHME CARDIAQUE',
  'DOENCAS DO CORACAO - INSUFICIENCIA CARDIACA' : 'MALADIES CARDIAQUES - INSUFFISANCE CARDIAQUE',
  'ANTICONCEPCIONAIS' : 'PILULES CONTRACEPTIVES',
  'MEDICAMENTO PARA IMPOTENCIA SEXUAL' : "MÉDICAMENT POUR L'IMPUISSANCE SEXUELLE",
  'OUTROS TRATAMENTOS HORMONAIS' : 'AUTRES TRAITEMENTS HORMONAUX',
  'DOENCAS DA PROSTATA' : 'MALADIES DE LA PROSTATE',
  'DOENCAS INFLAMATORIAS DO INTESTINO - DOENCA DE CROHN OU RETOCOLITE' : "MALADIES INFLAMMATOIRES DE L'INTESTIN - MALADIE DE CROHN OU RECTOCOLITE",
  'ANTIBIOTICOS' : 'ANTIBIOTIQUES',
  'DOENCAS DA BEXIGA E DO APARELHO URINARIO' : 'MALADIES DE LA VESSIE ET DU SYSTÈME URINAIRE',
  'Outros' : 'Autres',
  'Durante quantos anos você fumou charutos?' : "Depuis combien d'années fumez-vous des cigares ?",
  'ano ' : 'année',
  'anos ' : 'années',
  'No tempo que fumou, quantos charutos por dia?' : "Pendant la période où vous fumiez, combien de cigares par jour ?",
  'Fuma charutos atualmente?' : 'Fumez-vous actuellement des cigares ?',
  'Durante quantos anos você fumou cigarros?' : "Pendant combien d'années avez-vous fumé des cigarettes ?",
  'No tempo que fumou, quantos cigarros por dia?' : 'Pendant la période où vous avez fumé, combien de cigarettes par jour ?',
  'Fuma cigarros atualmente?' : 'Fumez-vous actuellement des cigarettes ?',
  'Quantos minutos por semana em média?' : 'Combien de minutes par semaine en moyenne ?',
  'De 10 a 30 minutos por semana' : 'De 10 à 30 minutes par semaine',
  'De 30 a 150 minutos por semana' : 'De 30 à 150 minutes par semaine',
  'De 150 a 500 minutos por semana' : 'De 150 à 500 minutes par semaine',
  'Mais de 500 minutos por semana' : 'Plus de 500 minutes par semaine',
  'Qual tipo de câncer?' : 'Quel type de cancer ?',
  'Cólon' : 'Côlon',
  'Pulmão' : 'Poumon',
  'Prostata' : 'Prostate',
  'Útero' : 'Utérus',
  'Mama' : 'Sein',
  'Qual tipo de problema cardíaco?' : 'Quel type de problème cardiaque ?',
  'Infarto' : 'Crise cardiaque',
  'Angina por obstrução das artérias do coração' : "Angine due à une obstruction des artères cardiaques",
  'Derrame' : 'Fuite',
  'Entupimento de artérias da perna' : 'Blocage des artères des jambes.',
  'Dilatação (aneurisma) de Aorta' : "Dilatation (anévrisme) de l'aorte",
  'Qual tipo de problema emocional?' : 'Quel genre de problème émotionnel?',
  'Ansiedade' : 'Anxiétés',
  'Estresse' : 'Stress',
  'Depressão' : 'Dépression',
  'Burnout' : 'Burnout',
  'Com qual idade veio sua primeira menstruação?' : 'A quel âge avez-vous eu vos premières règles ?',
  'Câncer de Mama' : 'Cancer du sein',
  'Você já fez radioterapia no tórax para tratar linfoma?': 'Avez-vous eu une radiothérapie pulmonaire pour traiter un lymphome ?',
  'Mulheres que realizaram esse tipo de tratamento antes dos 30 anos de idade apresentam grande risco de desenvolver câncer de mama durante a vida.' : "Les femmes qui ont subi ce type de traitement avant l'âge de 30 ans ont un risque élevé de développer un cancer du sein au cours de leur vie.",
  'Você afirmou que já fez radioterapia? Está correto?' : 'Vous avez répondu que vous aviez eu une radiothérapie pulmonaire pour traiter un lymphome. Est-ce correct?',
  'Sabe-se que quanto mais cedo uma mulher tem sua primeira menstruação, maior é o seu risco de desenvolver câncer de mama.' : 'On sait que plus une femme a ses premières règles tôt, plus son risque de développer un cancer du sein est grand.',
  'De 7 a 11' : 'De 7 à 11 ans',
  'De 12 a 13' : 'De 12 à 13 ans',
  '14 ou mais' : '14 ans ou plus',
  'Não Sei' : 'Je ne sais pas',
  'Com qual idade teve o primeiro filho (biológico) vivo?' : 'À quel âge avez-vous eu votre premier enfant (biologique) vivant ?',
  'Mulheres que tem o primeiro filho após os 30 anos de idade ou não tem filhos apresentam um risco maior de desenvolver câncer de mama quando comparadas com mulheres que tem filho antes dos 30 anos de idade.' : "Les femmes qui ont leur premier enfant après l'âge de 30 ans ou qui n'ont pas d'enfant ont un risque accru de développer un cancer du sein par rapport aux femmes qui ont un enfant avant l'âge de 30 ans.",
  'Não sei' : 'je ne sais pas',
  'Não tenho filhos' : "Je n'ai pas d'enfantss",
  'Menos de 20' : 'Moins de 20 ans',
  'Entre 20 e 24' : 'Entre 20 et 24 ans',
  'Entre 25 e 29' : 'Entre 25 et 29 ans',
  'Mais de 30' : 'Plus de 30 ans',
  'Quantos parentes de 1° grau (mãe, pai, irmã, irmão, filha ou filho) tiveram câncer de mama?' : 'Combien de parents au premier degré (mère, père, sœur, frère, fille ou fils) ont eu un cancer du sein ?',
  'Pessoas que apresentam câncer de mama em parentes de 1º grau e principalmente se esse parente teve o câncer antes dos 60 anos de idade, apresentam um risco maior de desenvolver câncer de mama durante a vida.' : "Les personnes dont un parent au premier degré a eu un cancer du sein, et surtout si ce parent a eu le cancer avant l'âge de 60 ans, ont un risque plus élevé de développer un cancer du sein au cours de leur vie.",
  'Nenhum' : 'Aucun',
  'Mais de 1': 'Plus de 1',
  'Você já realizou uma cirurgia para retirada total do útero (histerectomia)?' : 'Avez-vous déjà subi une intervention chirurgicale pour retirer complètement votre utérus (hystérectomie)',
  'Você respondeu que um parente de 1º grau (mãe, pai, irmã, irmão, filha ou filho) já teve câncer de mama. Está correto?' : "Vous avez répondu qu'un parent au premier degré (mère, père, sœur, frère, fille ou fils) a eu un cancer du sein. Est-ce correct ",
  'Você já tomou as 3 doses de vacina para o HPV?' : 'Avez-vous reçu les 3 doses du vaccin contre le VPH ?',
  'Já fez alguma biópsia de mama?': 'Avez-vous déjà subi une biopsie du sein ?',
  'Pessoas que já tiveram certos tipos de doenças na mama têm um risco maior de desenvolver câncer de mama, especialmente se essas doenças apresentaram uma alteração chamada atipia.' : 'Les personnes qui ont eu certains types de maladies du sein ont un risque plus élevé de développer un cancer du sein, surtout si ces maladies présentent une modification appelée atypie.',
  'Quantas biópsias foram feitas?' : 'Combien de biopsies ont été effectuées ?',
  'Alguma biópsia deu alteração com atipias?' : 'Une biopsie a-t-elle révélé une altération avec des atypies ?',
  'Atipias são anormalidades nas células ou tecidos que não parecem saudáveis sob um microscópio. Elas podem indicar um risco aumentado de câncer, mas nem sempre se transformam em câncer. O acompanhamento médico é essencial para determinar a necessidade de tratamento ou monitoramento.' : "L'atypie est une anomalie dans des cellules ou des tissus qui semblent malsains au microscope. Ils peuvent indiquer un risque accru de cancer, mais ils ne se transforment pas toujours en cancer. Le suivi médical est essentiel pour déterminer la nécessité d’un traitement ou d’une surveillance.",
  'Quando você realizou sua última mamografia?': 'Quand avez-vous passé votre mammographie la plus récente ?',
  'Menos de 1 ano' : "Moins d'un an",
  'Mais de 1 ano' : "Plus d'un an",
  'Nunca realizei' : "je ne l'ai jamais fait",
  'Você observa alguma lesão palpável?' : 'Remarquez-vous des lésions palpables ?',
  'Sente nódulos ou "caroços" na mama.' : 'Sentez-vous des nodules ou des "bosses" dans le sein ?',
  'Possui secreção nas papilas de forma espontânea?' : 'Y a-t-il un écoulement spontané des papilles ?',
  'Saída de secreção pelos mamilos.' : 'Écoulement de sécrétion au niveau des mamelons.',
  'Observou alguma mudança de forma na mama?' : 'Avez-vous remarqué un changement dans la forme du sein ?',
  'Câncer de Pulmão' : 'Cancer du poumon',
  'Você viveu com fumantes por mais da metade da sua vida?' : 'Avez-vous vécu avec des fumeurs pendant plus de la moitié de votre vie ?',
  'O fumo passivo também aumenta o risco para desenvolvimento de câncer de pulmão.' : 'Le tabagisme passif augmente également le risque de développer un cancer du poumon.',
  'Você já trabalhou com amianto, sem a devida proteção?' : "Avez-vous déjà travaillé avec de l'amiante sans protection ?",
  'Existe uma associação entre o trabalho com amianto e um risco maior de desenvolvimento de câncer do pulmão.' : "Il existe un lien entre le fait de travailler avec de l'amiante et un risque accru de développer un cancer du poumon.",
  'Selecione todas as atividades abaixo, com as quais você tenha trabalhado sem a devida proteção:' : 'Sélectionnez toutes les activités ci-dessous avec lesquelles vous avez travaillé sans protection adéquate.',
  'Exaustão de motores a diesel' : "Gaz d'échappement des moteurs diesel",
  'Pó ou cristal de sílica' : 'Poussière ou cristal de silice',
  'Compostos de níquel' : 'Composés du nickel',
  'Compostos de cromo (VI)' : 'Composés du chrome (VI)',
  'Arsênio e compostos inorgânicos de arsênio' : "Arsenic et composés inorganiques de l'arsenic",
  'Radiações X e Gama' : 'Rayonnement X et gamma',
  'Compostos de cádmio' : 'Composés du cadmium',
  'Compostos de berílio' : 'Composés du béryllium',
  'Essas atividades quando feitas por longo períodos e sem a devida proteção, podem aumentar o risco de desenvolvimento de alguns tipos de câncer.' : "Ces activités, lorsqu'elles sont pratiquées pendant de longues périodes et sans protection adéquate, peuvent augmenter le risque de développer certains types de cancer.",
  'Pintura' : 'Peinture',
  'Fundição de alumínio' : "Fonderie d'aluminium",
  'Fundição de ferro ou aço' : 'Fonderie d’acier',
  'Fabricação de borracha' : 'Fabrication de caoutchouc',
  'Fornos de coque' : 'Fours à coke',
  'Gaseificação de carvão mineral' : 'Gazéification de charbon minéral',
  'Você sente falta de ar ou dificuldade para respirar?' : "Souffrez-vous d'essoufflement ou de difficultés à respirer ?",
  'Tem percebido isso de maneira progressiva nos últimos tempos?' : "L'avez-vous remarqué de manière progressive ces derniers temps ?",
  'Apresenta tosse contínua ou progressiva?' : "Souffrez-vous d'une toux continue ou progressive ?",
  'A tosse te acompanha por umArsênio e compostos inorgânicos de arsênio período de tempo e está aumentando em frequência?' : 'La toux vous accompagne-t-elle pendant un certain temps et sa fréquence augmente-t-elle ?',
  'Não trabalhei' : "Je n'ai pas travaillés",
  'Nunca trabalhei' : "Je n'ai jamais travaillé",
  'Câncer de Cólon' : 'Cancer du côlon',
  'Algum parente de 1° grau (mãe, pai, irmã, irmão, filha ou filho) já teve câncer de intestino?' : 'Un parent au premier degré (mère, père, sœur, frère, fille ou fils) a-t-il déjà eu un cancer de l’intestin ?',
  'Pacientes que apresentam um familiar de 1º grau ou mais familiares com câncer de intestino apresentam maior risco de desenvolver câncer de intestino durante a vida.' : "Les patients qui ont un parent au premier degré, ou plus d’un parent, atteints d'un cancer de l'intestin ont un risque plus élevé de développer un cancer de l'intestin au cours de leur vie.",
  'Você respondeu que um parente de 1º grau (mãe, pai, irmã, irmão, filha ou filho) já teve câncer de intestino. Está correto?' : "Vous avez répondu qu'un parent au premier degré (mère, père, sœur, frère, fille ou fils) a eu un cancer de l'intestin. Est-ce correct ?",
  'Essas lesões são conhecidas como lesões pré-cancerosas e pessoas que apresentam isso correm mais risco de desenvolver câncer de intestino durante a vida.' : "Ces lésions sont connues sous le nom de lésions précancéreuses et les personnes qui en sont atteintes courent un plus grand risque de développer un cancer de l'intestin au cours de leur vie.",
  'Você teve uma condição conhecida como doença inflamatória intestinal crônica (ou DII) por 10 anos ou mais? Isso inclui a doença de Crohn e a colite ulcerosa, mas não a síndrome do intestino irritável (SII).' : "Êtes-vous atteint d'une maladie inflammatoire chronique de l'intestin (ou MICI) depuis 10 ans ou plus ? Cela inclut la maladie de Crohn et la colite ulcéreuse, mais pas le syndrome de l’intestin irritable (SII).",
  'Já realizou alguma vez os seguintes exames: pesquisa de sangue oculto nas fezes, retossigmoidoscopia ou conoloscopia?' : "Avez-vous déjà subi l'un des examens suivants : recherche de sang caché dans les selles, recto-sigmoïdoscopie ou coloscopie ?",
  'Exame recomendado para pessoas sadias e de risco normal após os 45 anos de idade.' : "Cet examen est recommandé aux personnes en bonne santé et à risque normal après l'âge de 45 ans.",
  'Quando foi a última vez?': 'Quand était-ce la dernière fois ?',
  'Sente dor intensa durante a relação sexual?': 'Ressentez-vous de fortes douleurs lors des rapports sexuels ?',
  'Alguma vez notou sangramento durante ou após a relação sexual?': 'Avez-vous déjà remarqué des saignements pendant ou après un rapport sexuel ?',
  'Apresenta algum sangramento fora do período menstrual ou após a menopausa?' : 'Avez-vous des saignements en dehors de la période menstruelle ou après la ménopause ?',
  'Mais de 2 anos' : 'Plus de 2 ans',
  'Mais de 3 anos' : 'Plus de 3 ans',
  'Você sofre de obstipação intestinal (intestino preso) ou diarreia frequente?' : 'Souffrez-vous de constipation ou de diarrhées fréquentes ?',
  'Alterações progressivas e persistentes do hábito intestinal demandam uma avaliação médica mais aprofundada.' : 'Les modifications progressives et persistantes des habitudes intestinales exigent une évaluation médicale plus poussée.',
  'Já observou presença de sangue nas fezes?' : 'Avez-vous déjà remarqué du sang sur vos matières fécales ?',
  'Isso pode ser um sinal de doenças do trato gastrointestinal.' : 'Cela peut être un signe de maladie du tractus gastro-intestinal.',
  'Você já teve alguma doença intestinal em que teve que retirar um pólipo (por colonoscopia), e, seu médico lhe falou que este pólipo era de alto risco e poderia virar câncer? ' : "Avez-vous eu un trouble intestinal nécessitant l'ablation de polypes (par coloscopie) et votre médecin traitant vous a dit qu'il s'agissait d'un polype à haut risque et qu'il pouvait devenir un cancer ?",
  'Há menos de um ano ' : "Il y a moins d'un an",
  'Há mais de um ano' : "Il y a plus d'un an",
  'Nunca' : "Je n’en ai jamais fait",
  'Poucas vezes' : 'Quelques fois',
  'Às vezes' : 'Parfois',
  'Frequentemente' : 'Souvent',
  'Sempre' : 'Toujours',
  'Cardio' : 'Cardio',
  'Qual a sua pressão arterial sistólica?' : 'Quelle est votre tension artérielle systolique ?',
  'Você tem diabetes?' : 'Vous avez du diabète ?',
  'Sua diabetes é do tipo 1 ou 2?' : 'Votre diabète est-il de type 1 ou 2 ?',
  'tipo 1' : 'type 1',
  'tipo 2' : 'type 2',
  'Há quantos anos tem diabetes?' : "Depuis combien d'années avez-vous du diabète ?",
  'Há quantos anos toma insulina?' : "Depuis combien d'années prenez-vous de l'insuline ?",
  'Você tem os resultados do seu último exame de colesterol?' : 'Avez-vous les résultats de votre dernier examen de cholestérol ?',
  'Quanto tempo faz que fez os exames?' : "Combien de temps s'est écoulé depuis que vous avez fait les examens ?",
  '6  meses' : '6 mois',
  'Menos de 6 meses' : 'Moins de 6 mois',
  'Entre 6 meses e 1 ano' : 'Moins de 6 mois',
  'Entre 1 e 2 anos' : 'Entre 1 et 2 ans',
  '1 ano' : '1 an',
  '2 anos' : '2 ans',
  '3 anos' : '3 ans',
  '4 anos' : '4 ans',
  '5 anos' : '5 ans',
  '6 anos' : '6 ans',
  '7 anos' : '7 ans',
  '8 anos' : '8 ans',
  '9 anos' : '9 ans',
  '10 anos' : '10 ans',
  '11 anos' : '11 ans',
  '12 anos' : '12 ans',
  '13 anos' : '13 ans',
  '14 anos' : '14 ans',
  '15 anos' : '15 ans',
  '16 anos' : '16 ans',
  '17 anos' : '17 ans',
  '18 anos' : '18 ans',
  '19 anos' : '19 ans',
  '20 anos' : '20 ans',
  '21 anos' : '21 ans',
  '22 anos' : '22 ans',
  '23 anos' : '23 ans',
  '24 anos' : '24 ans',
  '25 anos' : '25 ans',
  '26 anos' : '26 ans',
  '27 anos' : '27 ans',
  '28 anos' : '28 ans',
  '29 anos' : '29 ans',
  '30 anos' : '30 ans',
  '31 anos' : '31 ans',
  '32 anos' : '32 ans',
  '33 anos' : '33 ans',
  '34 anos' : '34 ans',
  '35 anos' : '35 ans',
  '36 anos' : '36 ans',
  '37 anos' : '37 ans',
  '38 anos' : '38 ans',
  '39 anos' : '39 ans',
  '40 anos' : '40 ans',
  '41 anos' : '41 ans',
  '42 anos' : '42 ans',
  '43 anos' : '43 ans',
  '44 anos' : '44 ans',
  '45 anos' : '45 ans',
  '46 anos' : '46 ans',
  '47 anos' : '47 ans',
  '48 anos' : '48 ans',
  '49 anos' : '49 ans',
  '50 anos' : '50 ans',
  '51 anos' : '51 ans',
  '52 anos' : '52 ans',
  '53 anos' : '53 ans',
  '54 anos' : '54 ans',
  '55 anos' : '55 ans',
  '56 anos' : '56 ans',
  '57 anos' : '57 ans',
  '58 anos' : '58 ans',
  '59 anos' : '59 ans',
  '60 anos' : '60 ans',
  '61 anos' : '61 ans',
  '62 anos' : '62 ans',
  '63 anos' : '63 ans',
  '64 anos' : '64 ans',
  '65 anos' : '65 ans',
  '66 anos' : '66 ans',
  '67 anos' : '67 ans',
  '68 anos' : '68 ans',
  '69 anos' : '69 ans',
  '70 anos' : '70 ans',
  '71 anos' : '71 ans',
  '72 anos' : '72 ans',
  '73 anos' : '73 ans',
  '74 anos' : '74 ans',
  '75 anos' : '75 ans',
  '76 anos' : '76 ans',
  '77 anos' : '77 ans',
  '78 anos' : '78 ans',
  '79 anos' : '79 ans',
  '80 anos' : '80 ans',
  '81 anos' : '81 ans',
  '82 anos' : '82 ans',
  '83 anos' : '83 ans',
  '84 anos' : '84 ans',
  '85 anos' : '85 ans',
  '86 anos' : '86 ans',
  '87 anos' : '87 ans',
  '88 anos' : '88 ans',
  '89 anos' : '89 ans',
  '90 anos' : '90 ans',
  '91 anos' : '91 ans',
  '92 anos' : '92 ans',
  '93 anos' : '93 ans',
  '94 anos' : '94 ans',
  '95 anos' : '95 ans',
  '96 anos' : '96 ans',
  '97 anos' : '97 ans',
  '98 anos' : '98 ans',
  '99 anos' : '99 ans',
  '100 anos' : '100 ans',
  '+ de 2 anos' : 'Plus de 2 ans',
  'Qual o valor do coleterol Total' : 'Quel est votre taux de cholestérol total ?',
  'Qual o valor do coleterol LDL' : 'Quel est votre taux de cholestérol LDL ?',
  'Qual o valor do coleterol HDL' : 'Quelle est la valeur du cholestérol HDL ?',
  'Qual o valor de triglicerídeos?' : 'Quelle est la valeur du cholestérol triglycéride ?',
  '1 charuto' : '1 cigare',
  '2 charutos' : '2 cigares',
  '3 charutos' : '3 cigares',
  '4 charutos' : '4 cigares',
  '5 charutos' : '5 cigares',
  '6 charutos' : '6 cigares',
  '7 charutos' : '7 cigares',
  '8 charutos' : '8 cigares',
  '9 charutos' : '9 cigares',
  '10 charutos' : '10 cigares',
  '11 charutos' : '11 cigares',
  '12 charutos' : '12 cigares',
  '13 charutos' : '13 cigares',
  '14 charutos' : '14 cigares',
  '15 charutos' : '15 cigares',
  '16 charutos' : '16 cigares',
  '17 charutos' : '17 cigares',
  '18 charutos' : '18 cigares',
  '19 charutos' : '19 cigares',
  '20 charutos' : '20 cigares',
  '21 charutos' : '21 cigares',
  '22 charutos' : '22 cigares',
  '23 charutos' : '23 cigares',
  '24 charutos' : '24 cigares',
  '25 charutos' : '25 cigares',
  '26 charutos' : '26 cigares',
  '27 charutos' : '27 cigares',
  '28 charutos' : '28 cigares',
  '29 charutos' : '29 cigares',
  '30 charutos' : '30 cigares',
  '31 charutos' : '31 cigares',
  '32 charutos' : '32 cigares',
  '33 charutos' : '33 cigares',
  '34 charutos' : '34 cigares',
  '35 charutos' : '35 cigares',
  '36 charutos' : '36 cigares',
  '37 charutos' : '37 cigares',
  '38 charutos' : '38 cigares',
  '39 charutos' : '39 cigares',
  '40 charutos' : '40 cigares',
  '41 charutos' : '41 cigares',
  '42 charutos' : '42 cigares',
  '43 charutos' : '43 cigares',
  '44 charutos' : '44 cigares',
  '45 charutos' : '45 cigares',
  '46 charutos' : '46 cigares',
  '47 charutos' : '47 cigares',
  '48 charutos' : '48 cigares',
  '49 charutos' : '49 cigares',
  '50 charutos' : '50 cigares',
  '51 charutos' : '51 cigares',
  '52 charutos' : '52 cigares',
  '53 charutos' : '53 cigares',
  '54 charutos' : '54 cigares',
  '55 charutos' : '55 cigares',
  '56 charutos' : '56 cigares',
  '57 charutos' : '57 cigares',
  '58 charutos' : '58 cigares',
  '59 charutos' : '59 cigares',
  '60 charutos' : '60 cigares',
  '61 charutos' : '61 cigares',
  '62 charutos' : '62 cigares',
  '63 charutos' : '63 cigares',
  '64 charutos' : '64 cigares',
  '65 charutos' : '65 cigares',
  '66 charutos' : '66 cigares',
  '67 charutos' : '67 cigares',
  '68 charutos' : '68 cigares',
  '69 charutos' : '69 cigares',
  '70 charutos' : '70 cigares',
  '71 charutos' : '71 cigares',
  '72 charutos' : '72 cigares',
  '73 charutos' : '73 cigares',
  '74 charutos' : '74 cigares',
  '75 charutos' : '75 cigares',
  '76 charutos' : '76 cigares',
  '77 charutos' : '77 cigares',
  '78 charutos' : '78 cigares',
  '79 charutos' : '79 cigares',
  '80 charutos' : '80 cigares',
  '81 charutos' : '81 cigares',
  '82 charutos' : '82 cigares',
  '83 charutos' : '83 cigares',
  '84 charutos' : '84 cigares',
  '85 charutos' : '85 cigares',
  '86 charutos' : '86 cigares',
  '87 charutos' : '87 cigares',
  '88 charutos' : '88 cigares',
  '89 charutos' : '89 cigares',
  '90 charutos' : '90 cigares',
  '91 charutos' : '91 cigares',
  '92 charutos' : '92 cigares',
  '93 charutos' : '93 cigares',
  '94 charutos' : '94 cigares',
  '95 charutos' : '95 cigares',
  '96 charutos' : '96 cigares',
  '97 charutos' : '97 cigares',
  '98 charutos' : '98 cigares',
  '99 charutos' : '99 cigares',
  '100 charutos': '100 cigares',
  'Mental' : 'Mental',
  'Eu penso em mudar minhas condições atuais de trabalho (por exemplo, meu turno, o local de trabalho, etc)' : "J'envisage de modifier mes conditions de travail actuelles (par exemple, mes horaires de travail, le lieu de travail, etc.).",
  'Nos últimos 6 meses,me senti muito ansioso e/ou preocupado com o trabalho.' : 'Au cours des 6 derniers mois, je me suis senti très anxieux et/ou inquiet au sujet du travail.',
  'Minha empresa me oferece um ambiente de trabalho seguro e acolhedor' : "Mon entreprise m'offre un environnement de travail sûr et accueillant.",
  'Sinto-me reconhecido no trabalho.' : 'Je me sens reconnu au travail.',
  'Meu estresse atual no local de trabalho afeta negativamente meu humor, pensamento e comportamento' : 'Mon stress actuel au travail a un effet négatif sur mon humeur, mes pensées et mon comportement.',
  'Sinto-me distraído ou tenho dificuldade em me concentrar devido ao meu ambiente de trabalho.' : "Je me sens distrait(e) ou j'ai des difficultés à me concentrer en raison de mon environnement de travail.",
  'Sinto-me exausto quando estou no trabalho.' : 'Je me sens épuisé quand je suis au travail.',
  'Sinto-me emocionalmente esgotado pelo meu trabalho.' : 'Je me sens épuisé émotionnellement par mon travail.',
  'Meu ambiente de trabalho me faz sentir inútil ou culpado.' : 'Mon environnement de travail me fait me sentir incapable ou coupable.',
  'Meu humor e meus sentimentos em relação ao meu trabalho facilitam meu desempenho em minha vida pessoal, social ou profissional.' : "Mon humeur et mes sentiments à l'égard de mon travail me permettent d'être plus performant dans ma vie personnelle, sociale ou professionnelle.",
  'Nos últimos 6 meses, senti tontura ou minha cabeça estava girando' : "Au cours des 6 derniers mois, j'ai eu des vertiges ou la tête qui tournait.",
  'Nos últimos 6 meses, tive dores no peito, quase como se estivesse tendo um ataque cardíaco.' : "Au cours des 6 derniers mois, j'ai eu des douleurs dans la poitrine, presque comme si j'avais une crise cardiaque.",
  'Nos últimos 6 meses, senti-me mal do estômago, quase como se fosse vomitar.' : "Au cours des 6 derniers mois, j’ai eu des nausées, comme si j'allais vomir.",
  '1 cigarro' : '1 cigarette',
  '2 cigarros' : '2 cigarettes',
  '3 cigarros' : '3 cigarettes',
  '4 cigarros' : '4 cigarettes',
  '5 cigarros' : '5 cigarettes',
  '6 cigarros' : '6 cigarettes',
  '7 cigarros' : '7 cigarettes',
  '8 cigarros' : '8 cigarettes',
  '9 cigarros' : '9 cigarettes',
  '10 cigarros' : '10 cigarettes',
  '11 cigarros' : '11 cigarettes',
  '12 cigarros' : '12 cigarettes',
  '13 cigarros' : '13 cigarettes',
  '14 cigarros' : '14 cigarettes',
  '15 cigarros' : '15 cigarettes',
  '16 cigarros' : '16 cigarettes',
  '17 cigarros' : '17 cigarettes',
  '18 cigarros' : '18 cigarettes',
  '19 cigarros' : '19 cigarettes',
  '20 cigarros' : '20 cigarettes',
  '21 cigarros' : '21 cigarettes',
  '22 cigarros' : '22 cigarettes',
  '23 cigarros' : '23 cigarettes',
  '24 cigarros' : '24 cigarettes',
  '25 cigarros' : '25 cigarettes',
  '26 cigarros' : '26 cigarettes',
  '27 cigarros' : '27 cigarettes',
  '28 cigarros' : '28 cigarettes',
  '29 cigarros' : '29 cigarettes',
  '30 cigarros' : '30 cigarettes',
  '31 cigarros' : '31 cigarettes',
  '32 cigarros' : '32 cigarettes',
  '33 cigarros' : '33 cigarettes',
  '34 cigarros' : '34 cigarettes',
  '35 cigarros' : '35 cigarettes',
  '36 cigarros' : '36 cigarettes',
  '37 cigarros' : '37 cigarettes',
  '38 cigarros' : '38 cigarettes',
  '39 cigarros' : '39 cigarettes',
  '40 cigarros' : '40 cigarettes',
  '41 cigarros' : '41 cigarettes',
  '42 cigarros' : '42 cigarettes',
  '43 cigarros' : '43 cigarettes',
  '44 cigarros' : '44 cigarettes',
  '45 cigarros' : '45 cigarettes',
  '46 cigarros' : '46 cigarettes',
  '47 cigarros' : '47 cigarettes',
  '48 cigarros' : '48 cigarettes',
  '49 cigarros' : '49 cigarettes',
  '50 cigarros' : '50 cigarettes',
  '51 cigarros' : '51 cigarettes',
  '52 cigarros' : '52 cigarettes',
  '53 cigarros' : '53 cigarettes',
  '54 cigarros' : '54 cigarettes',
  '55 cigarros' : '55 cigarettes',
  '56 cigarros' : '56 cigarettes',
  '57 cigarros' : '57 cigarettes',
  '58 cigarros' : '58 cigarettes',
  '59 cigarros' : '59 cigarettes',
  '60 cigarros' : '60 cigarettes',
  '61 cigarros' : '61 cigarettes',
  '62 cigarros' : '62 cigarettes',
  '63 cigarros' : '63 cigarettes',
  '64 cigarros' : '64 cigarettes',
  '65 cigarros' : '65 cigarettes',
  '66 cigarros' : '66 cigarettes',
  '67 cigarros' : '67 cigarettes',
  '68 cigarros' : '68 cigarettes',
  '69 cigarros' : '69 cigarettes',
  '70 cigarros' : '70 cigarettes',
  '71 cigarros' : '71 cigarettes',
  '72 cigarros' : '72 cigarettes',
  '73 cigarros' : '73 cigarettes',
  '74 cigarros' : '74 cigarettes',
  '75 cigarros' : '75 cigarettes',
  '76 cigarros' : '76 cigarettes',
  '77 cigarros' : '77 cigarettes',
  '78 cigarros' : '78 cigarettes',
  '79 cigarros' : '79 cigarettes',
  '80 cigarros' : '80 cigarettes',
  '81 cigarros' : '81 cigarettes',
  '82 cigarros' : '82 cigarettes',
  '83 cigarros' : '83 cigarettes',
  '84 cigarros' : '84 cigarettes',
  '85 cigarros' : '85 cigarettes',
  '86 cigarros' : '86 cigarettes',
  '87 cigarros' : '87 cigarettes',
  '88 cigarros' : '88 cigarettes',
  '89 cigarros' : '89 cigarettes',
  '90 cigarros' : '90 cigarettes',
  '91 cigarros' : '91 cigarettes',
  '92 cigarros' : '92 cigarettes',
  '93 cigarros' : '93 cigarettes',
  '94 cigarros' : '94 cigarettes',
  '95 cigarros' : '95 cigarettes',
  '96 cigarros' : '96 cigarettes',
  '97 cigarros' : '97 cigarettes',
  '98 cigarros' : '98 cigarettes',
  '99 cigarros' : '99 cigarettes',
  '100 cigarros': '100 cigarettes',
  'Eu sei quais recursos posso usar se sentir que preciso de apoio (por exemplo, benefícios de saúde mental, programas de assistência aos funcionários, suporte virtual ou no local).' : "Je sais quelles ressources je peux utiliser si je sens que j'ai besoin de soutien (par exemple, les aides de santé mentale, les programmes d'assistance aux employés, le soutien virtuel ou présentiel).",
  'Sinto-me contente, alegre e consigo me conectar facilmente com as pessoas no trabalho.' : 'Je me sens heureux, de bonne humeur et je peux facilement communiquer avec les gens au travail.',
  'Interajo regularmente com meus colegas de trabalho, fazendo-me sentir que pertenço a um grupo, que estou seguro e protegido.': "J'interagis régulièrement avec mes collègues de travail, j'ai le sentiment d'appartenir à un groupe, d'être en sécurité et protégé.",
  'Se já parou de fumar charutos, há quantos anos?': "Si vous avez arrêté de fumer le cigare, c'était depuis combien d'années ?",
  'Durante quanto anos você fumou cigarros?': "Depuis combien d'années fumez-vous des cigarettes ?",
  'Se já parou de fumar cigarros, há quantos anos?': "Si vous avez arrêté de fumer des cigarettes, depuis combien d'années ?",
  'Você já realizou um exame genético (conhecido como BRCA1 ou BRCA) para saber se tem uma alteração/mutação que aumenta seu risco para desenvolver câncer e este resultado foi positivo?': "Avez-vous déjà passé l'examen de génotype (connu sous le nom de BRCA1 ou BRCA) pour savoir si vous avez une altération/mutation qui augmente votre risque de développer un cancer et ce résultat était positif ?",
  'Vc respondeu que o teste para pesquisa de mutação BRCA e ele foi positivo . Está correto?': "Vous avez répondu que le résultat de l'examen BRCA était positif. Est-ce exact?",
  'Voce já teve Câncer?': 'Avez-vous déjà eu un cancer ?',
  'Selecione todas alternativas sobre o histórico de câncer em sua família': "Sélectionnez toutes les alternatives concernant les antécédents de cancer de votre famille",
  'Qual o seu consumo diário de bebida alcólica?': 'Quelle est votre consommation quotidienne de boissons alcoolisées ?',
  'Digite acima o tipo de problema cardíaco.': 'Tapez ci-dessus votre problème cardiaque.',
  'Você tem histórico de problemas emocionais ?': 'Avez-vous des antécédents de problèmes émotionnels ?',
  'Qual tipo de problema emoncional?': 'Quel genre de problème émotionnel?',
  'Com qual idade veio sua primeira Menstruação?': 'Quel âge aviez-vous lorsque vous avez eu vos premières règles ?',
  'Quantos parentes de 1 grau (mãe, pai, irmã, irmão, filha ou filho) tiveram câncer de Mama?': 'Des parents au premier degré (père, mère, frère, sœur, fils et/ou fille) ont-ils eu un cancer du sein ?',
  'Vc respondeu que um parente de 1º grau (mãe, pai, irmã, iramão, filha ou filho) tiveram cancer de mama. Está correto?': "Vous avez répondu qu'un parent au premier degré (père, mère, frère, sœur, fils et/ou fille) avait un cancer du sein. Est-ce correct?",
  'Já fez alguma biopsia de mama?': 'Avez-vous déjà subi une biopsie mammaire ?',
  'Você já teve relação sexual?': 'Avez-vous déjà eu des rapports sexuels ?',
  'O câncer de colo de útero é ocasionado por um vírus chamado HPV que, na maioria das vezes, é transmitido pela relação sexual. As pessoas que já tiveram relações apresentam um risco de ter adquirido o vírus e, consequentemente, têm mais chance.': "Le cancer du col de l'utérus est causé par un virus appelé VPH, qui est le plus souvent transmis lors de rapports sexuels. Les personnes qui ont déjà eu des rapports sexuels risquent de contracter le virus et ont donc plus de chances de développer ce type de cancer.",
  'Você já realizou uma cirurgia para retirada total do útero (histerectomia)': 'Avez-vous déjà subi une intervention chirurgicale pour retirer complètement votre utérus (hystérectomie)',
  'Pacientes que retiraram o útero conjuntamente com o colo uterino não apresentam mais risco de desenvolver câncer de colo uterino.': "Les patientes qui ont subi une ablation de l'utérus et du col de l'utérus ne courent plus le risque de développer un cancer du col de l'utérus.",
  'Já realizou algum exame preventivo de colo uterino (papanicolau)?': "Avez-vous déjà subi un test de dépistage du cancer du col de l'utérus (frottis) ?",
  'Exame recomendado após os 21 anos de idade para todas as mulheres sadias e de risco normal que já tiveram relação sexual.': "Ce test est recommandé après l'âge de 21 ans pour toutes les femmes en bonne santé et à risque normal qui ont eu des rapports sexuels.",
  'Quando foi a ultima vez?': 'Quand était-ce la dernière fois ?',
  'Alguma vez deu alteração dos tipos NIC I, NIC II , NIC III HSIL ou LSIL?': 'Une altération du type CIN I, CIN II, CIN III, HSIL ou LSIL est-elle déjà apparue lors d’un frottis ?',
  'São alterações identificadas no exame preventivo (Papanicolaou) ou na colposcopia que podem estar associados a um risco aumentado de desenvolver câncer de colo uterino.': "Il s'agit d’altérations identifiées lors d'un examen préventif (frottis) ou d'une colposcopie qui peuvent être associées à un risque accru de développer un cancer du col de l'utérus.",
  'Você já tomou as 3 doses de vacina para o HPV': 'Avez-vous pris les 3 doses du vaccin HPV ?',
  'HPV é um vírus, geralmente de transmissão sexual, que pode fazer lesões imperceptíveis no colo de útero e posteriormente gerar o câncer desse órgão.': "Le VPH est un virus, généralement transmis par voie sexuelle, qui peut provoquer des lésions imperceptibles sur le col de l'utérus et développer ultérieurement un cancer de cet organe.",
  'Durante sua vida, fez uso regular e constante de preservativos ou diafrágmas?': 'Au cours de votre vie, avez-vous fait un usage régulier et constant de préservatifs ou de diaphragmes ?',
  'A utilização desses métodos de barreira diminuem o risco de transmissão de um vírus que tem o potencial de ocasionar o câncer de colo uterino.': "L'utilisation de ces méthodes barrière réduit le risque de transmission d'un virus susceptible de provoquer un cancer du col de l'utérus.",
  'Sente dor intensa durante a relação sexual': 'Ressentez-vous une douleur intense pendant les rapports sexuels ?',
  'Não é considerado normal sentir dor durante as relações sexuais.': "Il n'est pas considéré normal de ressentir de la douleur pendant les rapports sexuels.",
  'Sangramentos vaginais irregulares ou após a menopausa necessitam ser melhor avaliados.': "Les saignements vaginaux irréguliers ou les saignements après la ménopause doivent faire l'objet d'une évaluation plus approfondie.",
  'Selecione todas as atividades abaixo com as quais você tenha trabalhado sem a devida proteção': "Avez-vous travaillé avec l'une de ces substances sans protection adéquate ? Veuillez sélectionner tout ceux qui s'appliquent.",
  'Você sente falta de ar ou dificulade para respirar?': 'Vous sentez-vous essoufflé ou avez-vous de la difficulté à respirer?',
  'Algum parentes de 1 grau (mãe, pai, irmã, irmão, filha ou filho) já teve câncer de intestino?': "Des parents au premier degré (père, mère, frère, sœur, fils et/ou fille) ont-ils eu un cancer de l'intestin ?",
  'Vc respondeu que um parente de 1º grau (mãe, pai, irmã, iramão, filha ou filho) já teve câncer de intestino. Está correto?': "Vous avez répondu qu'un parent au premier degré (père, mère, frère, sœur, fils et/ou fille) avait un cancer de l'intestin. Est-ce correct?",
  'Você já teve alguma doença intestinal em que teve que retirar um pólipo (por colonoscopia) e seu médico lhe falou que este pólipo era de alto risco epoderia virar câncer? ': "Avez-vous déjà eu une maladie intestinale au cours de laquelle un polype a dû être retiré (par coloscopie) et votre médecin vous a dit que ce polype était à haut risque qui pouvait se transformer en cancer ?",
  'Quando foi a última vez ?': 'De quand date la plus récente ?',
  'Algum parentes de 1 grau (pai, irmão ou filho) já teve câncer de próstata?': "Un parent au premier degré (père, frère ou fils) a-t-il déjà eu un cancer de la prostate ?",
  'Pessoas que apresentam um parente de 1º grau ou mais de 1 parente com câncer de próstata apresentam risco aumentado para desenvolvimento do câncer de próstata.': "Les personnes qui ont un parent au premier degré, ou plus d'un parent, atteint d’un cancer de la prostate ont un risque accru de développer un cancer de la prostate.",
  'Vc respondeu que um parente de 1º grau (pai, irmão ou filho) já teve câncer de Próstata. Está correto?': "Vous avez répondu qu'un parent au premier degré (père, mère, frère, sœur, fils et/ou fille) avait un cancer de la prostate. Est-ce correct?",
  'O esforço para urinar está mais intenso do que habitualmente?': "L'effort pour uriner est-il plus intense que d'habitude ?",
  'A perda de sangue pela urina não é normal e deve ser melhor avaliada em uma consulta médica.': "La perte de sang par les urines n'est pas normale et doit être évaluée de manière plus approfondie lors d'une consultation médicale.",
  'O não esvaziamento completo da bexiga após urinar requer uma avaliação mais aprofundada desse sintoma.': "Le fait de ne pas vider complètement la vessie après avoir uriné nécessite une évaluation plus approfondie de ce symptôme.",
  'Estou satisfeito com minhas atuais condições de trabalho': 'Je suis satisfait(e) de mes conditions de travail actuelles (environnement physique, conditions de travail à domicile, mes affectations et mon équipe de travail).',
  'Os meus colegas me dão apoio emocional para me ajudar-mea gerir o meu stress no trabalho.': "Mes collègues m'apportent un soutien émotionnel pour m'aider à gérer mon stress au travail.",
  'Os meus colegas perguntam como eu estou frequentemente.': 'Mes collègues me demandent souvent comment je vais.',
  'Eu realmente me importo com o que acontece com meus colegas ou clientes no meu trabalho.': "Je me soucie vraiment de ce qui arrive à mes collègues ou aux clients dans mon travail.",
  'Sinto-me mais insensível com as pessoas desde que aceitei este trabalho': "Je me sens plus insensible envers les gens depuis que j'ai accepté ce travail.",
  'O meu supervisor fornece o apoio de que necessito para gerir o meu trabalho': "Mon superviseur m'apporte le soutien dont j'ai besoin pour gérer mon travail.",
  'O meu supervisor dá-me apoio emocional para me ajudar a gerir o meu stress.': "Mon superviseur me fournit un soutien émotionnel pour m'aider à gérer mon stress.",
  'O meu supervisor pergunta frequentemente como estou, fazendo-me sentir confiante e seguro no trabalho.': 'Mon superviseur me demande souvent comment je vais, ce qui me donne confiance et me sécurise au travail.',
  'Sinto-me à vontade para conversar com meu supervisor para mudar coisas estressantes sobre meu trabalho (por exemplo, carga de trabalho, folgas, mudança de tarefas/responsabilidades).': "Je me sens à l'aise de parler avec mon superviseur pour modifier des aspects stressants de mon travail (par exemple, la charge de travail, les congés, les changements de tâches/responsabilités).",
  'Sinto que realizei muitas coisas valiosas no meu trabalho.': "J'ai le sentiment d'avoir accompli beaucoup de choses précieuses dans mon travail.",
  'Sinto que tenho um papel e responsabilidades claramente definidos e que garantem que meu dia corra bem.': "J'ai le sentiment d'avoir un rôle et des responsabilités clairement définis qui assurent le bon déroulement de ma journée.",
  'Sinto orgulho de que as pessoas entendam o valor do meu papel e da minha contribuição para a organização.': "Je suis fière que les gens comprennent la valeur de mon rôle et ma contribution à l'organisation.",
  'Sinto que estou no controle de minhas prioridades, definindo o faço e quando faço.': "J'ai le sentiment de contrôler mes priorités, de définir ce que je fais et quand je le fais.",
  'Minha carga de trabalho aumentou, distraindo meu foco da tarefa pela qual sou responsável.': "Ma charge de travail a augmenté, ce qui a détourné mon attention de la tâche dont je suis responsable.",
  'Sinto que minha carga de trabalho está aumentando muito além do que posso lidar.': "J'ai l'impression que ma charge de travail augmente bien au-delà de ce que je peux supporter.",
  'Eu gasto tempo procurando uma nova posição no trabalho ou em outra empresa.': 'Je passe du temps à chercher un nouveau poste dans mon travail ou dans une autre entreprise.',
  'Sinto que meu equilíbrio entre trabalho e vida pessoal está sob controle.': "J'ai le sentiment de maîtriser l'équilibre entre ma vie professionnelle et ma vie privée.",
  'Nos últimos 6 meses, sinto que alcancei resultados no meu trabalho e minhas relações pessoais estão ótimas.': "Au cours des 6 derniers mois, j'ai le sentiment d'avoir atteint des résultats dans mon travail et mes relations personnelles sont excellentes.",
  'Durante os últimos 30 dias, com que frequência você se sentiu cansado sem uma boa razão para isso?': 'Au cours des 30 derniers jours, combien de fois vous êtes-vous senti fatigué sans raison valable ?',
  'Durante os últimos 30 dias, com que frequência você se sentiu nervoso?': 'Au cours des 30 derniers jours, à quelle fréquence vous êtes-vous senti(e) nerveux(se) ?',
  'Durante os últimos 30 dias, com que frequência você se sentiu tão nervoso que nada conseguiu acalmá-lo?': 'Au cours des 30 derniers jours, combien de fois vous êtes-vous senti si nerveux que rien ne pouvait vous calmer ?',
  'Durante os últimos 30 dias, com que frequência você se sentiu sem esperança?': 'Au cours des 30 derniers jours, combien de fois vous êtes-vous senti désespéré ?',
  'Durante os últimos 30 dias, com que frequência você se sentiu inquieto?': 'Au cours des 30 derniers jours, à quelle fréquence vous êtes-vous senti(e) agité(e) ?',
  'Durante os últimos 30 dias, com que frequência você se sentiu tão inquieto que não conseguia ficar parado?': 'Au cours des 30 derniers jours, combien de fois vous êtes-vous senti si agité que vous ne pouviez pas rester sans bouger ?',
  'Durante os últimos 30 dias, com que frequência você se sentiu mais triste do que o normal?': '',
  'Durante os últimos 30 dias, com que frequência você sentiu que qualquer coisa era um esforço?': "Au cours des 30 derniers jours, combien de fois avez-vous eu l'impression que tout était un effort ?",
  'Durante os últimos 30 dias, com que frequência você se sentiu tão triste que nada poderia animá-lo?': 'Au cours des 30 derniers jours, combien de fois vous êtes-vous senti si triste que rien ne pouvait vous remonter le moral ?',
  'Durante os últimos 30 dias, com que frequência você parou de fazer coisas que gosta, como esportes ou reuniões sociais?': 'Au cours des 30 derniers jours, combien de fois avez-vous cessé de faire des choses que vous aimez, comme le sport ou les rencontres sociales ?',
  'During the past 30 days, how often have you stopped doing things you enjoy, such as sports or social gatherings? ': "Au cours des 6 derniers mois, j'ai perdu ou pris du poids sans essayer, ou mon appétit a changé.s",
  'Nos últimos 6 meses, parei de me divertir fazendo coisas que costumava gostar.': "Au cours des six derniers mois, j'ai cessé de m'amuser en faisant des choses que j'aimais auparavant.",
  'Nos últimos 6 meses, você evitou propositalmente entrar em situações nas quais pudesse sentir estresse ou ataque de pânico?': "Au cours des 6 derniers mois, avez-vous évité délibérément de vous mettre dans des situations dans lesquelles vous auriez pu ressentir du stress ou une attaque de panique ?",
  'Eu ganho o suficiente para que a minha renda não seja uma preocupação na minha vida pessoal.': 'Je gagne suffisamment pour que mon revenu ne soit pas une préoccupation dans ma vie personnelle.',
  'Eu ganho o suficiente para economizar 3 meses de despesas para uma emergência.': "Je gagne suffisamment pour économiser 3 mois de dépenses en cas d'urgence.",
  'Eu me preocupo em não ter dinheiro suficiente para pagar minhas despesas pessoais.': "Je m'inquiète de ne pas avoir assez d'argent pour payer mes dépenses personnelles.",
  'Nos últimos 6 meses, perdi ou ganhei peso sem tentar, ou meu apetite mudou.': "Au cours des 6 derniers mois, j'ai perdu ou pris du poids sans essayer, ou mon appétit a changé.",
  'Câncer de Próstata': 'Cancer de la prostate',
  'Algum parente de 1° grau (pai, irmão ou filho) já teve câncer de próstata?': 'Un parent au premier degré (père, frère ou fils) a-t-il déjà eu un cancer de la prostate ?',
  'Você respondeu que um parente de 1º grau (pai, irmão ou filho) já teve câncer de próstata. Está correto?' : "Vous avez répondu qu'un parent au premier degré (père, frère ou fils) a déjà eu un cancer de la prostate. Est-ce correct ?",
  'Sente dificuldade para urinar ou percebeu que o jato de urina está mais fraco?': "Avez-vous des difficultés à uriner ou avez-vous remarqué que votre jet d'urine est plus faible ?", 
  'Percebeu saída de sangue na urina?': 'Avez-vous remarqué du sang dans vos urines ?',
  'Ao urinar, tem a sensação que não consegue esvaziar a bexiga por completo?': "Lorsque vous urinez, avez-vous la sensation que vous ne pouvez pas vider complètement votre vessie ?",
  'Ás vezes': 'Quelquefois',
  'Compartilhar Diagnóstico': 'Partager le diagnostic',
  'Especifique o problema cardíaco:' : 'Précisez le problème cardiaque :',
  'Em geral, indivíduos com Diabetes tipo 1 manifestam o quadro nas duas primeiras décadas de vida, e precisam fazer uso de insulina. O Diabetes tipo 2 surge mais tarde, normalmente associado ao excesso de peso. Embora possa ser necessário uso de insulina, o' : "En général, les personnes atteintes de diabète de type 1 manifestent la maladie au cours des deux premières décennies de leur vie et doivent utiliser de l'insuline. Le diabète de type 2 survient plus tard, généralement associé à un surpoids. Bien que l'utilisation d'insuline puisse être nécessaire, le contrôle se fait généralement avec des médicaments oraux.",
  'Sistólica é a mais alta. Exemplo: se a sua pressão é 12/8, então é a 12. Nesse caso, preencha 120. Se for 12,5, preencha 125.' : "La systolique est la plus élevée. Exemple : si votre pression est de 12/8, alors c'est 12. Dans ce cas, remplissez 120. Si c'est 12,5, remplissez 125",

    /* SUBDIAGNOSTICS */
  'Control': 'Contrôle',
  'Demands': 'Demandes',
  'Support': 'Supports',
  'Relationship': 'Relation',
  'Role': 'Règles',
  'Change': 'Changements',
  'K10': 'K10',

  'Não Sou um robô': 'Je ne suis pas un robot',
  'Alto risco' : 'Risque élevé',
  'Baixo risco' : 'Risque faible',
  'Baixo Risco' : 'Risque faible',
  'Médio risco': 'Risque moyen',
  'Médio Risco': 'Risque moyen',
  'Sem Risco': 'Sans risque',
  'Risco normal' : "Risque normal",
  'Alto Risco': 'Risque élevé',
  'Risco abaixo da média': 'Risque inférieur à la moyenne',
  'Copiado para a área de transferência' : 'Copié dans la zone de téléchargement',

  'Câncer de Próstata (Masculino)' : 'Cancer de la prostate (masculin)',
  'Câncer de Útero (Feminino)' : "Cancer de l'utérus (féminin)",
  'Histórico Médico (Masculino)' : 'Antécédents médicaux (masculin)',
  'Histórico Médico (Feminino)' : 'Antécédents médicaux (féminin)',
  'Câncer de Mama (Feminino)' : 'Cancer du sein (féminin)',
  'Câncer de Pulmão (Unissex)' : 'Cancer du poumon (unisexe)',
  'Câncer de Cólon (Unissex)' : 'Cancer du côlon (unisexe)',
  'Mental (Unissex)' : 'Mental (unisexe)',
  'Cardio (Unissex)': 'Cardio (unisexe)', 

  "variable.WORK.Well.INDIVIDUAL.Well" : "Vos réponses indiquent que vous ne présentez pas de symptômes liés à l'anxiété ou au stress au travail. Au niveau personnel, vos réponses révèlent que vous êtes en bonne santé, sans symptômes de stress ou de stress général. anxiété.",
  "variable.WORK.Well.INDIVIDUAL.Low" : "Vos réponses indiquent que vous ne présentez pas de symptômes liés à l'anxiété ou au stress au travail. Au niveau personnel, vos réponses révèlent que vous êtes en bonne santé, avec très peu de symptômes de stress ou anxiété générale.",
  "variable.WORK.Low.INDIVIDUAL.Well" : "Vos réponses indiquent que vous ne présentez pas de symptômes importants liés à l'anxiété ou au stress au travail. Au niveau personnel, vos réponses révèlent que vous êtes en bonne santé, sans symptômes de stress ou de anxiété.",
  "variable.WORK.Well.INDIVIDUAL.Medium" : "Vos réponses indiquent que vous ne présentez pas de symptômes liés à l'anxiété ou au stress au travail. Au niveau personnel, vos résultats indiquent que vous présentez des symptômes modérés liés au stress ou au anxiété générale.",
  "variable.WORK.Low.INDIVIDUAL.Low" : "Vos réponses indiquent que vous ne présentez pas de symptômes importants liés à l'anxiété ou au stress au travail. Au niveau personnel, vos réponses révèlent que vous êtes en bonne santé, avec très peu de symptômes de stress ou anxiété générale.",
  "variable.WORK.Medium.INDIVIDUAL.Well" : "Vos réponses indiquent que vous êtes inquiet et que vous pensez avoir besoin d'un soutien au travail, nous vous recommandons donc de parler à votre employeur ou de consulter un professionnel de la santé mentale pour une évaluation complète. niveau, vos réponses révèlent que vous êtes en bonne santé, sans symptômes de stress ou d'anxiété générale.",
  "variable.WORK.Well.INDIVIDUAL.High" : "Vos réponses indiquent que vous ne présentez pas de symptômes liés à l'anxiété ou au stress au travail. Personnellement, il semble que le stress affecte votre vie. La plupart des gens se sentent stressés à un moment donné et vos symptômes sont des expériences courantes pour la majorité de la population.",
  "variable.WORK.Low.INDIVIDUAL.Medium" : "Vos réponses indiquent que vous ne présentez pas de symptômes importants liés à l'anxiété ou au stress au travail. Au niveau personnel, vos résultats indiquent que vous présentez des symptômes modérés liés au stress ou au anxiété générale.",
  "variable.WORK.Medium.INDIVIDUAL.Low" : "Vos réponses indiquent que vous êtes inquiet et que vous pensez avoir besoin d'un soutien au travail, nous vous recommandons donc de parler à votre employeur ou de consulter un professionnel de la santé mentale pour une évaluation complète. niveau, vos réponses révèlent que vous êtes en bonne santé, avec très peu de symptômes de stress ou d'anxiété générale.",
  "variable.WORK.High.INDIVIDUAL.Well" : "Le lieu de travail peut être très difficile. Vos réponses indiquent que vous pourriez avoir besoin de soutien. La bonne nouvelle est qu'il y a toujours un moyen. Vous êtes plus fort que tout sur votre chemin, et il existe certaines stratégies que vous pouvez utiliser pour vous aider à gérer votre anxiété au travail. Au niveau personnel, vos réponses révèlent que vous êtes en bonne santé, sans symptômes de stress ou d'anxiété générale.",
  "variable.WORK.Low.INDIVIDUAL.High" : "Vos réponses indiquent que vous ne présentez pas de symptômes importants liés à l'anxiété ou au stress au travail. Personnellement, il semble que le stress affecte votre vie. La plupart des gens se sentent stressés à un moment donné et vos symptômes sont des expériences courantes pour la majorité de la population.",
  "variable.WORK.Medium.INDIVIDUAL.Medium" : "Vos réponses indiquent que vous êtes inquiet et que vous pensez avoir besoin d'un soutien au travail, nous vous recommandons donc de parler à votre employeur ou de consulter un professionnel de la santé mentale pour une évaluation complète. niveau, vos résultats indiquent que vous éprouvez des symptômes modérés liés au stress ou à l'anxiété générale.",
  "variable.WORK.High.INDIVIDUAL.Low" : "Le lieu de travail peut être très difficile. Vos réponses indiquent que vous pourriez avoir besoin de soutien. La bonne nouvelle est qu'il y a toujours un moyen. Vous êtes plus fort que tout sur votre chemin, et il existe certaines stratégies que vous pouvez utiliser pour vous aider à gérer votre anxiété au travail. Au niveau personnel, vos réponses révèlent que vous êtes en bonne santé, avec très peu de symptômes de stress ou d'anxiété générale.",
  "variable.WORK.Medium.INDIVIDUAL.High" : "Vos réponses indiquent que vous êtes inquiet et pensez avoir besoin d'un soutien au travail, nous vous recommandons donc d'en parler à votre employeur ou de consulter un professionnel de la santé mentale pour une évaluation complète. Personnellement, il semble que le stress affecte votre vie. La plupart des gens se sentent stressés à un moment donné et vos symptômes sont des expériences courantes pour la majorité de la population.",
  "variable.WORK.High.INDIVIDUAL.Medium" : "Le lieu de travail peut être très difficile. Vos réponses indiquent que vous pourriez avoir besoin de soutien. La bonne nouvelle est qu'il y a toujours un moyen. Vous êtes plus fort que tout sur votre chemin, et il existe certaines stratégies que vous pouvez utiliser pour vous aider à gérer votre anxiété au travail. Au niveau personnel, vos résultats indiquent que vous éprouvez des symptômes modérés liés au stress ou à l'anxiété générale.",
  "variable.WORK.High.INDIVIDUAL.High" : "Le lieu de travail peut être très difficile. Vos réponses indiquent que vous pourriez avoir besoin de soutien. La bonne nouvelle est qu'il y a toujours un moyen. Vous êtes plus fort que tout sur votre chemin, et il existe certaines stratégies que vous pouvez utiliser pour vous aider à gérer votre anxiété au travail. Personnellement, il semble que le stress affecte votre vie. La plupart des gens se sentent stressés à un moment donné et vos symptômes sont des expériences courantes pour la majorité de la population.",

  'Todos os Riscos' : 'Tous les risques' ,
  'Masculino' : 'Masculin',
  'Feminino' : 'Féminin',
  'Somente Exames':'Examens uniquement',
  'Somente Sintoma':'Seuls les symptômes',
  'Alto Risco + Exame':'Risque élevé + examen',
  'Alto Risco + Sintoma':'Risque élevé + Symptôme',
  'Exame + Sintoma':'Examen + Symptôme',
  'Alto Risco + Exame + Sintoma': 'Risque élevé + Examen + Symptôme',
  'plugin.OverrideMultiSlect':{
    "allItemsAreSelected": "Tout",
    "clearSearch": "Effacer la recherche",
    "clearSelected": "Effacer la sélection",
    "noOptions": "Aucune option",
    "search": "Rechercher",
    "selectAll": "Tout sélectionner",
    "selectAllFiltered": "Tout sélectionner (filtré)",
    "selectSomeItems": "Sélectionnez...",
    "create": "Créer",
  },

  //Tour
  "tour.greetings.title": "On prévient ensemble?",
  "tour.greetings": "Nous sommes ravis de vous avoir ici!",
  "tour.greetings.message": "Le système Previneo vous aide à faire le premier pas vers la prévention rapidement et sans sortir de chez vous !",
  "tour.first.step": "La première étape consiste à remplir les antécédents médicaux. Cette information sera importante pour votre résultat.",
  "tour.estimated.time": "Heure d'arrivée estimée: 1 à 2 min",
  "tour.second.step": "Il vous suffit maintenant de remplir votre entretien numérique. Si vous avez d'autres entretiens, suivez les mêmes étapes.",
  "tour.third.step": "Dans le menu, vous trouverez les options : revenir à l'écran d'accueil, vous déconnecter du système et accéder à votre compte.",
  "tour.last.step.title": "faire le plein?",
  "tour.last.step": "N'oubliez pas que vos résultats se trouvent dans chaque entretien numérique, et vous pouvez accéder à plus d'informations dans \"Détails de l'entretien numérique\".",
  "tour.finished": "Démarrer",
  "tour.start": "Commençons",
  "tour.skip": "Ignorer la visite",
  "tour.next.step": "Suivant",

}
