import ButtonXls from "admin/components/ButtonXls";
import DataTable from "admin/components/DataTable";
import InputTableFilter from "admin/components/InputTableFilter";
import FormGrid from "components/FormGrid";
import debounce from "lodash.debounce";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { injectIntl } from "react-intl";
import withResource from "store/utils/withResource";
import queryToObject from "utils/queryToObject";

class ResourceTableFilter extends Component {
  static propTypes = {
    /** Recurso */
    resource: PropTypes.string.isRequired,

    /** Colunas da tabela */
    columns: PropTypes.array,

    /** Props para o DataTable */
    dataTableProps: PropTypes.object,

    /** Rota base */
    routeBase: PropTypes.object,

    /** Automaticamente atualizada os dados em certo intervalo de tempo */
    autoRefresh: PropTypes.bool,

    /** Intervalo de tempo (em milisegundos) para o autoRefresh */
    autoRefreshTime: PropTypes.number,

    /** Exibe a coluna com os botões de ações 'Detalhes', 'Editar' e 'Remover' */
    showActionColumns: PropTypes.bool,

    /** Largura da coluna de Ações */
    actionColumnWidth: PropTypes.number,

    /** Exibe o botão 'Detalhes' */
    canShowDetails: PropTypes.bool,

    /** Exibe o botão 'Editar' */
    canEdit: PropTypes.bool,

    /** Exibe o botão 'Remover' */
    canRemove: PropTypes.bool,

    /** Adicionar ações adicionais */
    extraActions: PropTypes.func
  };

  static defaultProps = {
    autoRefreshTime: 10000,
    actionColumnWidth: 230
  };

  constructor(props) {
    super(props);

    this.state = {
      lastUsedParams: {},
      filter:[],
      columns:[],
      values:this.props.resourceProps.records.response || [],

    };

    this.setFilter = this.setFilter.bind(this);
    this.setColumns = this.setColumns.bind( this )
    this.setValues = this.setValues.bind( this )
    this.update = this.update.bind( this )
  } 

  setFilter( filter, filtered ){   
    this.setState({filter:filter})
    this.setValues( filtered );
  }

  setColumns(){
    if(!this.props.resourceProps.records.columns || this.state.columns.length)
      return setTimeout(()=> this.setColumns(), 100 );
    
    let dt_columns = []
    this.props.resourceProps.records.columns.forEach((item, idx )=> {
      dt_columns.push({ 
        accessor: this.props.resourceProps.records.acessor ? idx+'a': item , 
        Header: item , 
        width: (item.length > 15 ? item.length * 10 : 150 ), 
        Cell: row => <div><span title={row.value}>{row.value}</span></div>
      });
    });
    this.setState({columns:dt_columns})
    this.setValues(this.props.resourceProps.records.response);
    

  }

  setValues(values){
    this.setState({
      values:values
    })
  }

  update(){
    setTimeout(()=> {
      this.setState({columns:[]})
      this.setColumns()
    }, 100 );
  }

  unmounted = false;

  fetchData(params) {
    !this.unmounted &&
      this.setState({ lastUsedParams: params }, () => {
        this.props.resourceProps.fetchAll(params);
      });
  }

  handleFetchData = debounce(({ page, pageSize, sorted, filtered }) => {
    const filterParams = queryToObject(filtered);

    this.fetchData({
      page: page + 1,
      limit: pageSize,
      ...filterParams,
      ...this.props.params
    });
  }, 800);

  componentDidMount(){
     this.setColumns() 
  }
  componentWillUnmount() {
    this.unmounted = true;
  }

  
  render() {
    if( this.props.resourceProps.records.response && this.state.values.length !==this.props.resourceProps.records.response.length && (!this.state.filter ||  !this.state.filter.length ))
      this.setValues(this.props.resourceProps.records.response);
    const currentDate = moment().format('DD_MM_YYYY');
    const {
      //columns = [],
      routeBase,
      dataTableProps,
      resourceProps,
      extraActionButton,
      actionColumnWidth,
      autoRefreshTime,
      intl,
      logs,
      report,
      ...rest
    } = this.props;

    return (
      <React.Fragment>
        <FormGrid {...rest}>
          {this.state.values.length > 0 ? (
          <ButtonXls
                data={{ data: (this.props.resourceProps.records.csv || this.state.values) }}
                columns={this.props.resourceProps.records.columns}
                book={intl.formatMessage({ id: 'admin.dataTable.label.report' }) + ` ${(typeof report === 'undefined') ?'' :report} - ${currentDate}.xls`}
            />
          ) : (
              <div></div>
          )}
          {this.state.columns.length > 0 && (
            <InputTableFilter data={resourceProps.records} setFilter={this.setFilter}/> 
          )}
                  
        </FormGrid>
        <DataTable
          filterable={false}
          showPaginationBottom={true}
          loading={resourceProps.isFetching}
          data={this.state.values}
          columns={this.state.columns}    
          update={this.update}
          {...this.props}      
        />

      </React.Fragment>
    );
  }
}

export default injectIntl(
  withResource(
    ResourceTableFilter,
    ({ resource, params, namespace, autoFetch }) => ({
      resource,
      params,
      namespace,
      autoFetch
    })
  )
);
