import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import {
  deleteData,
  getAuthenticatedUser,
  isAuthenticated,
  isSubmitting,
  login,
  logout,
} from 'store/ducks/auth';

class Auth extends Component {
  render() {
    const { children, ...props } = this.props;
    return children(props);
  }

  static propTypes = {
    /** Render function com os parâmetros do Auth */
    children: PropTypes.func.isRequired,

    /** Dados do usuário que está autenticado */
    user: PropTypes.object.isRequired,

    /** Booleano para verificar se o usuário está logado */
    isAuthenticated: PropTypes.bool.isRequired,

    /** Função para iniciar a autenticação com usuário e senha */
    login: PropTypes.func.isRequired,

    /** Função para deslogar o usuário e remover o token da localStorage */
    logout: PropTypes.func.isRequired,
  };
}

const permited = (props, type = '') => {
  const isPrevineo = props.user.empresa.id === 1;

  switch (type) {
    case 'access_dashboard':
      return props.user.access_dashboard;
    default:
      return isPrevineo;
  }
};

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticated(state),
  isSubmitting: isSubmitting(state),
  user: getAuthenticatedUser(state),
  type: state.auth.type,
  permissions: state.auth.permissions,
  isSuperAdmin: state.auth.isSuperAdmin,
  permited: permited,
});

export default connect(mapStateToProps, { login, logout, deleteData })(Auth);
