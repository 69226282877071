import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import VerticalSpacer from 'components/VerticalSpacer'
import Button from 'components/Button'
import PatientWizard from 'questionnaire/components/PatientWizard'
import * as duck from 'store/ducks/questionnaire'
import getRoute from 'utils/getRoute'
import routes from 'routes'

function PatientPersonalData ({ auth, intl, ...props }) {
  const to = getRoute(routes.users.diagnostic.anamnese, auth)

  return (
    <VerticalSpacer>
      {props.data.paciente.cadastro_completo && (
        <Button to={to} component={Link} size='small' color='personal'>
          {intl.formatMessage({ id: 'q.backToAnamnese' })}
        </Button>
      )}
      <PatientWizard
        initialValues={props.data.paciente}
        scriptFormFields={props.data.roteiro.form_fields}
        onSubmit={async data => {
          const oldGender = props.data.paciente.sexo

          await props.updatePatient({
            ...auth,
            patientId: data.id,
            data
          })

          if (oldGender !== data.sexo) {
            props.fetchData(auth)
          }
        }}
        isSubmitting={props.isSubmitting}
        redirectTo={to}
        error={props.error}
        companyId={props.data.paciente.empresa.id}
      />
    </VerticalSpacer>
  )
}

const mapStateToProps = state => ({
  data: duck.getData(state),
  isSubmitting: duck.isSubmitting(state),
  auth: duck.getAuth(state),
  error: duck.getError(state)
})

const mapDispatchToProps = {
  updatePatient: duck.updatePatient,
  fetchData: duck.fetchData
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PatientPersonalData))
