import React from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { injectIntl } from 'react-intl';
import CustomFieldSelect from 'admin/components/CustomFieldSelect';
import Base from 'components/Base';
import styled from 'styled-components';
import { Strech } from '../Responsive/Responsive';

export const CustomComponentStyled = styled(props => <Base {...props} />)`
  .Select-control {
    background-color: ${props =>
      props.theme.colors[props.color] !== undefined
        ? props.theme.colors[props.color]
        : props.color} !important;
    & * {
      color: ${props => props.theme.colors[props.textColor]} !important;
      .Select-arrow {
        border-color: ${props => props.theme.colors[props.textColor]} none !important;
      }
    }
  }

  .dropdown-container {
    background-color: ${props =>
      props.theme.colors[props.color] !== undefined
        ? props.theme.colors[props.color]
        : props.color} !important;
    border: 1px solid #c1c1c1;
    .dropdown-heading {
      svg {
        transform: scale(0.6);
        color: #333;
      }
      .dropdown-heading-value {
        color: #333;
        span {
          color: #333;
          &.gray {
            font-size: 0;
            &:before {
              font-size: 16px;
              content: '${props => props.label || 'Select'}...';
            }
          }
        }
      }
    }
    .item-renderer {
      display: flex;
      align-items: center;
      input {
        transform: scale(1.5);
      }
      span {
        padding-left: 10px;
        font-size: 14px;
      }
    }
  }
`;
const CustomComponent = injectIntl(({ intl, title, ...props }) => {
  let customSelectProps = { ...props.customSelectProps };
  let {
    labelKey = 'name',
    valueKey = 'id',
    options = [],
    multi = false,
    value,
  } = customSelectProps;
  options = options.map(item => {
    return { label: item[labelKey], value: item[valueKey] };
  });

  return (
    <Strech {...props}>
      <CustomComponentStyled
        color="white"
        textColor="white"
        label={props.labelledBy}
        title={title}
      >
        {multi ? (
          <MultiSelect
            options={options}
            value={value}
            overrideStrings={intl.formatMessage({
              id: 'plugin.OverrideMultiSlect',
            })}
            {...props}
          />
        ) : (
          <CustomFieldSelect {...props} />
        )}
      </CustomComponentStyled>
    </Strech>
  );
});

export const CustomComponentCompany = injectIntl(({ intl, ...props }) => (
  <CustomComponent
    {...props}
    placeholder={intl.formatMessage({
      id: 'users.admin.companies.title',
    })}
  />
));
