import React from 'react';
import styled from 'styled-components';

const OnClose = styled.div`    
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 14px;
    background: white;
    line-height: 0.8;
    border-radius: 30px;
    box-shadow: 0 0 6px rgb(0 0 0 / 30%);
    cursor:pointer;
`;

export const Close = (props)=>{
    return(
        <OnClose onClick={props.callback}>
            <div>✖</div>
        </OnClose>        
    )
}
