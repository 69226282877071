import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import Dropzone from 'react-dropzone'
import Spinner from 'components/Spinner'
import Text from 'components/Text'
import { Container, RemoveButton } from './styles'

const IMAGE_REGEX = /((\.(png|jpe?g|gif|webp|svg))|(\d+x\d+))(\?.*)?$/

export default function Uploader ({ file = {}, disabled, inputProps, ...rest }) {
  const { onUpload, onRemove, accept, isLoading, ...props } = rest
  const isDisabled = disabled || !isEmpty(file) || isLoading
  const isImage = !!(file.url && IMAGE_REGEX.test(file.url))
  return (
    <div style={{ maxWidth: 100 }}>
      <Dropzone
        accept={accept}
        style={{ }}
        activeStyle={{ }}
        disableClick={isDisabled}
        disablePreview
        disabled={isDisabled}
        onDropAccepted={files => onUpload(files[0])}
        inputProps={inputProps}
        {...props}
      >
        {({ isDragActive }) => (
          <Container isDragActive={isDragActive} preview={isImage ? file.url : null}>
            {!isLoading && !isEmpty(file) && (
              <RemoveButton onClick={() => onRemove(file)} />
            )}
            {isLoading && (
              <Spinner text='' />
            )}
            {!isLoading && isEmpty(file) && (
              <Text size='12px' align='center'>Upload</Text>
            )}
            {!isEmpty(file) && !isImage && (
              <Text size='12px' align='center'>{file.nome}</Text>
            )}
          </Container>
        )}
      </Dropzone>
    </div>
  )
}

Uploader.propTypes = {
  /** Props que serão repassadas ao <input /> */
  inputProps: PropTypes.object,

  /** Arquivos que serão aceitos pelo uploader (Ex: 'image/*', 'application/pdf') */
  accept: PropTypes.string,

  /** Uploader desabilitado */
  disabled: PropTypes.bool,

  /** Arquivo */
  file: PropTypes.object,

  /** Callback para realizar o upload */
  onUpload: PropTypes.func.isRequired,

  /** Callback para remover o arquivo */
  onRemove: PropTypes.func.isRequired,

  /** Arquivo sendo enviado */
  isLoading: PropTypes.bool
}
