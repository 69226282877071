import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import DataTable from "admin/components/DataTable";
import Grid from "admin/components/Grid";
import Button from "components/Button";
import getRoute from "utils/getRoute";
import queryToObject from "utils/queryToObject";
import ConfirmModal from "admin/components/ConfirmModal";
import WithModal from "admin/components/WithModal";
import Interval from "admin/components/Interval";
import debounce from "lodash.debounce";
import withResource from "store/utils/withResource";
import { FormattedMessage, injectIntl } from "react-intl";

class ResourceDataTable extends Component {
  static propTypes = {
    /** Recurso */
    resource: PropTypes.string.isRequired,

    /** Colunas da tabela */
    columns: PropTypes.array,

    /** Props para o DataTable */
    dataTableProps: PropTypes.object,

    /** Rota base */
    routeBase: PropTypes.object,

    /** Automaticamente atualizada os dados em certo intervalo de tempo */
    autoRefresh: PropTypes.bool,

    /** Intervalo de tempo (em milisegundos) para o autoRefresh */
    autoRefreshTime: PropTypes.number,

    /** Exibe a coluna com os botões de ações 'Detalhes', 'Editar' e 'Remover' */
    showActionColumns: PropTypes.bool,

    /** Largura da coluna de Ações */
    actionColumnWidth: PropTypes.number,

    /** Exibe o botão 'Detalhes' */
    canShowDetails: PropTypes.bool,

    /** Exibe o botão 'Editar' */
    canEdit: PropTypes.bool,

    /** Exibe o botão 'Remover' */
    canRemove: PropTypes.bool,

    /** Adicionar ações adicionais */
    extraActions: PropTypes.func
  };

  static defaultProps = {
    autoRefreshTime: 10000,
    actionColumnWidth: 230
  };

  state = {
    lastUsedParams: {}
  };

  unmounted = false;

  fetchData(params) {
    !this.unmounted &&
      this.setState({ lastUsedParams: params }, () => {
        this.props.resourceProps.fetchAll(params);
      });
  }

  handleFetchData = debounce(({ page, pageSize, sorted, filtered }) => {
    const filterParams = queryToObject(filtered);

    this.fetchData({
      page: page + 1,
      limit: pageSize,
      ...filterParams,
      ...this.props.params
    });
  }, 800);

  componentWillUnmount() {
    this.unmounted = true;
  }

  render() {
    const {
      columns = [],
      routeBase,
      dataTableProps,
      resourceProps,
      extraActionButton,
      actionColumnWidth,
      autoRefreshTime,
      ...rest
    } = this.props;

    return (
      <React.Fragment>
        {this.props.autoRefresh && (
          <Interval
            disabled={resourceProps.isFetching}
            timeout={this.props.autoRefreshTime}
            callback={() => this.fetchData(this.state.lastUsedParams)}
          />
        )}
        
        <DataTable
          manual={false}
          loading={resourceProps.isFetching}
          data={resourceProps.records}
          pages={resourceProps.pagination.last_page}
          filterable={true}
          sortable={false}
          onFetchData={this.handleFetchData}
          minRows={rest.minRows}
          columns={[...columns].concat(
            rest.showActionColumns &&
              (rest.canRemove ||
                rest.canEdit ||
                rest.canShowDetails ||
                extraActionButton)
              ? {
                  Header: <FormattedMessage id="admin.common.actions" />,
                  filterable: false,
                  width: actionColumnWidth,
                  Cell: ({ original: { id, internal, ...original } }) => (
                    <Grid spacing={5}>
                      {rest.canShowDetails && (
                        <div>
                          <Button
                            component={Link}
                            to={getRoute(routeBase.show, { id })}
                            color="success"
                            block
                            size="small"
                            disabled={resourceProps.removingRecords.includes(
                              id
                            )}
                          >
                            <FormattedMessage id="admin.common.details" />
                          </Button>
                        </div>
                      )}
                      {rest.canEdit && (
                        <div>
                          <Button
                            component={Link}
                            to={getRoute(routeBase.edit, { id })}
                            block
                            size="small"
                            color="warning"
                            disabled={resourceProps.removingRecords.includes(
                              id
                            )}
                          >
                            <FormattedMessage id="admin.common.edit" />
                          </Button>
                        </div>
                      )}
                      {rest.canRemove && (
                        <div>
                          <WithModal
                            modal={({ closeModal }) => (
                              <ConfirmModal
                                title={this.props.intl.formatMessage({
                                  id: "admin.common.warningMessage"
                                })}
                                text={this.props.intl.formatMessage({
                                  id: "admin.common.AreYouSureABoutIt"
                                })}
                                onConfirm={() => {
                                  resourceProps.remove(id);
                                  closeModal();
                                }}
                                onCancel={closeModal}
                              />
                            )}
                          >
                            {({ toggleModal }) => (
                              <Button
                                onClick={toggleModal}
                                block
                                size="small"
                                color="danger"
                                disabled={
                                  resourceProps.removingRecords.includes(id) ||
                                  internal
                                }
                              >
                                <FormattedMessage id="admin.common.remove" />
                              </Button>
                            )}
                          </WithModal>
                        </div>
                      )}
                      {extraActionButton({ ...original, id, internal })}
                    </Grid>
                  )
                }
              : []
          )}
          {...dataTableProps}
        />
      </React.Fragment>
    );
  }
}

export default injectIntl(
  withResource(
    ResourceDataTable,
    ({ resource, params, namespace, autoFetch }) => ({
      resource,
      params,
      namespace,
      autoFetch
    })
  )
);
