import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { injectIntl } from 'react-intl';
import { GoogleLogin } from 'react-google-login';
import { gapi } from "gapi-script"; // Nao remova esse import ou login com google nao ira funcionar
import Icon from '@fortawesome/react-fontawesome';
import FieldListener from 'admin/components/FieldListener';
import SubmissionContainer from 'admin/components/SubmissionContainer';
import ButtonSubmit from 'components/ButtonSubmit';
import CustomField from 'components/CustomField';
import FormGrid from 'components/FormGrid';
import Link from 'components/Link';
import VerticalSpacer from 'components/VerticalSpacer';
import Notifications from 'containers/Notifications';
import MicrosoftLogin from 'react-microsoft-login';
import routes from 'routes';
import * as forms from 'utils/forms';
import { equalTo } from 'utils/validators';
import HrCustom from '../../../admin/components/HrCustom/HrCustom';
import Alert from '../../../components/Alert/Alert';
import IconMicrosoft from '../../../images/microsoft.png';
import { CLIENT_ID_GOOGLE, CLIENT_ID_MICROSOFT } from '../../../config/constants';
import { Container, ContainerLoginAccout, ButtonMicrosoft } from './styles';

const FormValidEmail = ({
  intl,
  company,
  setPage,
  formData: { telefone, ...formData },
  setFormData,
  isSubmitting,
  isFetching,
  setLoginWithApi,
  loginWithApi,
  ...props
}) => {
  const [userPassword, setUserPassword] = useState(null);
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isPasswordConfirmationShown, setIsPasswordConfirmationShown] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState([]);

  //função de mostrar e ocultar senha
  const tooglePasswordVisibility = () => {
    setIsPasswordShown(!isPasswordShown);
  };
  const tooglePasswordConfirmationVisibility = () => {
    setIsPasswordConfirmationShown(!isPasswordConfirmationShown);
  };

  //Função que verifica se email eh válido
  const formSubmit = (e, props) => {
    e.preventDefault();
    props.values.checkEmail = true;
    delete props.values.g_token;
    delete props.values.ms_token;
    props.values.method = 'password';
    props.fetchApi(props.values);
  };
  
  //Função register do google
  const googleSuccess = async response => {
    let userObject = response.profileObj;
    setLoginWithApi(true);

    const {password, password_confirmation, ...formDataRest} = formData;
    let data = {...formDataRest, 
      nome: userObject.name,
      email: userObject.email,
      g_token: userObject.googleId,
      method: "Gtoken",
      checkEmail: true,
    }

    setFormData({
      ...formData,
      ...data
    });

    props.fetchApi(data);
  };

  const googleFailure = error => {
    console.log('Google Sing In ha fracasado intentelo denuevo mas tarde');
  };

  //Função register do microsoft
  const microsoftSucces = (err, data, msal) => {
    if (!err && data) {
      setLoginWithApi(true);
      sessionStorage.clear();

      const {password, password_confirmation, ...formDataRest} = formData;
      let data = {
        ...formDataRest, 
        nome: msal.account.name,
        email: msal.account.userName,
        ms_token: msal.account.accountIdentifier,
        method: "MStoken",
        checkEmail: true,
      }

      setFormData({
        ...formData,
        ...data
      });

      props.fetchApi(data);
    }
  };

  //validação da senha
  useEffect(() => {
    if (userPassword) {
      const errors = [];
      if (userPassword.length < 8)
        errors.push('Quantidade mínima de caracteres: 8');
      if (!/[a-z]/gm.test(userPassword))
        errors.push('Senha deve ter letras minusculas');
      if (!/[A-Z]/gm.test(userPassword))
        errors.push('Senha deve ter letras maiusculas');
      if (!/[0-9]/gm.test(userPassword)) errors.push('Senha deve ter números');
      setPasswordErrors(errors);
    }
  }, [userPassword]);

  //state e setState e mudança das pages
  useEffect(() => {
    if (props.data.success) {
      delete props.data.success;
      setFormData({
        ...formData,
        ...props.data,
      });
      setPage(currPage => currPage + 1);
    }
  }, [props.data]);

  
  return (
    <Container>
      <Form
        {...props}
        initialValues={{
          ...formData,
          nome_company: company.nome,
          step: 'email_validate',
          index: 0,
        }}
      >
        {({ handleSubmit, hasValidationErrors, ...formProps }) => (
          <form onSubmit={e => formSubmit(e, formProps)}>
            <VerticalSpacer>
              <Notifications />
              <FormGrid>
                <Field
                  name="nome_company"
                  type="hidden"
                  // label={intl.formatMessage({
                  //   id: 'admin.common.companyName',
                  // })}
                  placeholder={intl.formatMessage({
                    id: 'admin.enter.company.name',
                  })}
                  readOnly={!!company.nome}
                  disabled={!!company.nome}
                  id="form_patient_name"
                  component={CustomField}
                  maxLength={255}
                />
              </FormGrid>
              <FormGrid>
                <Field
                  name="email"
                  label={intl.formatMessage({ id: 'admin.common.email' })}
                  id="form_patient_email"
                  placeholder={intl.formatMessage({
                    id: 'exemple@exemple.com',
                  })}
                  component={CustomField}
                  showMandatory
                  {...forms.emailRequired}
                />
              </FormGrid>
              <FormGrid>
                <div className="section_input_password">
                  <Field
                    name="password"
                    type={isPasswordShown ? 'text' : 'password'}
                    label={intl.formatMessage({ id: 'admin.common.password' })}
                    placeholder={'••••••••'}
                    id="form_patient_password"
                    className="input_password"
                    component={CustomField}
                    maxLength={255}
                    autoComplete={'new-password'}
                    showMandatory
                    {...forms.passwordRequired}
                  />
                  <Icon
                    id="open_eye"
                    className="icon_password"
                    color="#A6ACAF"
                    icon={
                      isPasswordShown
                        ? require('@fortawesome/fontawesome-free-solid/faEyeSlash')
                        : require('@fortawesome/fontawesome-free-solid/faEye')
                    }
                    onClick={tooglePasswordVisibility}
                  />
                </div>
              </FormGrid>
              <FormGrid>
                <div className="section_input_password">
                  <Field
                    name="password_confirmation"
                    type={isPasswordConfirmationShown ? 'text' : 'password'}
                    label={intl.formatMessage({
                      id: 'admin.common.confirmPassword',
                    })}
                    className="input_password"
                    placeholder={'••••••••'}
                    id="form_patient_confirm_password"
                    component={CustomField}
                    maxLength={255}
                    autoComplete={'new-password'}
                    showMandatory
                    {...forms.password}
                    validate={equalTo({
                      field: 'password',
                      message: 'As senhas precisam ser iguais',
                    })}
                  />
                  <Icon
                    id="open_eye"
                    color="#A6ACAF"
                    className="icon_password_confirmationd"
                    icon={
                      isPasswordConfirmationShown
                        ? require('@fortawesome/fontawesome-free-solid/faEyeSlash')
                        : require('@fortawesome/fontawesome-free-solid/faEye')
                    }
                    onClick={tooglePasswordConfirmationVisibility}
                  />
                </div>
              </FormGrid>
              {passwordErrors.length ? (
                <Alert
                  type="error"
                  message="Corrija os itens para cadastrar-se:"
                  items={passwordErrors}
                />
              ) : (
                ''
              )}
              <FieldListener
                name="password"
                onChange={(newValue, oldValue) => {
                  setUserPassword(newValue);
                }}
              />

              <Container>
                <HrCustom
                  content={intl.formatMessage({ id: 'admin.or.register' })}
                />
              </Container>

              <ContainerLoginAccout>
                <center>
                  <MicrosoftLogin
                    clientId={CLIENT_ID_MICROSOFT}
                    authCallback={microsoftSucces}
                    buttonTheme={'dark'}
                    prompt={'select_account'}
                  >
                    {' '}
                    <ButtonMicrosoft type="button">
                      <img
                        src={IconMicrosoft}
                        className="image_button_microsoft"
                        alt="icon_microsoft"
                      />
                      {intl.formatMessage({ id: 'admin.write.with.microsoft' })}
                    </ButtonMicrosoft>
                  </MicrosoftLogin>

                  <GoogleLogin
                    clientId={CLIENT_ID_GOOGLE}
                    buttonText={intl.formatMessage({ id: 'admin.write.with.google' })}
                    className="button_register_google"
                    onSuccess={googleSuccess}
                    onFailure={googleFailure}
                    cookiePolicy="single_host_origin"
                    prompt='select_account'
                  ></GoogleLogin>
                </center>
              </ContainerLoginAccout>

              <SubmissionContainer>
                <Link
                  to={routes.users.register}
                  onClick={window.location.reload}
                >
                  <Icon
                    className="icon_arrow"
                    style={{ marginRight: '10px', width: 15 }}
                    icon={require('@fortawesome/fontawesome-free-solid/faArrowLeft')}
                  />
                  {intl.formatMessage({ id: 'pages.register.code.return' })}
                </Link>
                <ButtonSubmit
                  color="primary"
                  textColor="white"
                  isSubmitting={isFetching}
                  disabled={isFetching || hasValidationErrors}
                  label={intl.formatMessage({
                    id: 'pages.register.code.continue',
                  })}
                />
              </SubmissionContainer>
            </VerticalSpacer>
          </form>
        )}
      </Form>
    </Container>
  );
};
export default injectIntl(FormValidEmail);
