import React from 'react'
import { injectIntl } from 'react-intl'
import { isEmpty } from 'ramda'
import Resource from 'containers/Resource'
import getRoute from 'utils/getRoute'
import PageContent from 'components/PageContent'
import Spinner from 'components/Spinner'
import FormAnamneseConfirmation from 'admin/forms/FormAnamneseConfirmation'
import routes from 'routes'
import Container from 'components/Container'
import QuestionnaireScreen from 'users/questionnaire/components/QuestionnaireScreen'
import { Redirect } from 'react-router-dom'

function ConfirmationScreen ({ intl, ...props }) {
  const { script, patient, session } = props.match.params
  return (
    <QuestionnaireScreen
      title={intl.formatMessage({ id: 'admin.common.confirmation' })}
      guest
      color='#004879'
      {...props}
    >
      <Resource
        resource='AnamneseConfirmation'
        autoFetch
        params={{ script, patient, session }}
      >
        {({ records, isFetching, ...props }) => {
          const requestErrors = !isEmpty(props.error)

          if (isFetching) {
            return <PageContent><Spinner /></PageContent>
          }

          if (!isFetching && !requestErrors && !isEmpty(records)) {
            const to = getRoute(routes.questionnaire.categories, {
              script: script,
              patient: patient,
              session: records[0].sessao
            })

            return <Redirect to={to} />
          }

          if (props.error && requestErrors) {
            return (
              <PageContent>
                <Container size='small'>
                  <FormAnamneseConfirmation
                    onSubmit={() => props.create({ script, patient, session })}
                    isSubmitting={props.isSubmitting}
                    patient
                  />
                </Container>
              </PageContent>
            )
          }

          return null
        }}
      </Resource>
    </QuestionnaireScreen>
  )
}

export default injectIntl(ConfirmationScreen)
