import styled from 'styled-components';

export const FlexBox = styled.div`
  @media (max-width: 912px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;
export const Container = styled.div`
  font-size: 9pt;
  color: #34495e;
  font-weight: bold;

  .title_none_data {
    font-size: 14px;
  }
  .emoji_none_data {
    width: 180px;
  }

  @media (max-width: 912px) {
    position: none !important;
    width: 100% !important;

    .title_none_data {
      margin-left: 20px;
    }
    .emoji_none_data {
      width: 80px;
    }
  }
`;
