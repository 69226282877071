import React from 'react'
import PropTypes from 'prop-types'
import Auth from 'admin/containers/Auth'

export default function PermissionMatch ({ permission, children }) {
  return (
    <Auth>
      {props => {
        if (!permission) return children(props)
        return props.permissions.includes(permission) ? children(props) : null
      }}
    </Auth>
  )
}

PermissionMatch.propTypes = {
  /** Permissão exigida */
  permission: PropTypes.string,

  /** Children function */
  children: PropTypes.func.isRequired
}
