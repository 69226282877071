import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import media from "utils/mediaQuery";

const ContainerDashbordStyled = styled.div`
  ${props => media.greaterThan(props.breakpoint)`
    display: ${props => props.direction};
    max-width: ${props => props.width}

    > * {
      width: 100%;
    }
  `}
  @media (max-width: 912px) {
    display: block;
  }
`;

export default function ContainerDashbord(props) {
  return <ContainerDashbordStyled {...props} />;
}

ContainerDashbord.propTypes = {
  /** Breakpoint para desfazer o Card */
  breakpoint: PropTypes.string.isRequired,

  /** Background do Card */
  width: PropTypes.string,

  /** Direction do Card */
  direction: PropTypes.string,
};

ContainerDashbord.defaultProps = {
  breakpoint: "large",
  direction: "flex",
  width: "100%"
};
