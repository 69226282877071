import styled from "styled-components";
import media from "utils/mediaQuery";

export const Container = styled.div`
  background-color: transparent;
`;

export const Content = styled.div`
  padding: 0 22px 35px;
  width: 100%;
  box-sizing: border-box;
  font-family: ${props => props.theme.fonts.tertiary};

  ${media.greaterThan("large")`
    padding-left: ${props => (props.narrow ? "110px" : "310px")};
  `}

  @media (max-width: 912px) {
    padding-top: 20px;
  }
`;

export const MenuItem = styled.div`
  translation: 2s;
  border-radius: solid 10px red;

  .item-submenu {
    position: relative;
    margin-left: 20px;
    color: #0b4e71;
    text-decoration: none;
    line-height: 2rem;
    font-family: "Poppins", sans-serif;

    :hover {
      color: #ffff;
    }
  }

  @media (max-width: 800px) {
    background: #a5e3e0 !important;
    padding-left: 40px;

    .item-submenu {
      border: none;
      color: #ffff;
      font-size: 16px;
    }
  }

  :hover {
    background-color: #39acb5 !important;
    opacity: 2px;
  }
`;

export const Sidebar = styled.aside`
  z-index: 1;
  ::-webkit-scrollbar {
    width: 0.4em;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }

  @media (max-width: 800px) {
    .custom-botton {
      background: none !important;
      margin-botton: 20px !important;
    }
  }

  ${media.greaterThan("large")`
    display: flex;
    flex-direction: column;
    justify-content: start;
    position: fixed;
    top: 140px;
    width: ${props => (props.narrow ? "80px" : "280px")};
    height: calc(100vh - 90px);
    overflow: auto;
  `}

  ${media.lessThan("large")`
    display: ${props => (props.isMenuOpened ? "block" : "none")};
    background: ${props => props.theme.colors.secondaryGreen}
  `}
`;
export const HorizontalCollapseArrow = styled.a`
  margin-left: ${props => (props.narrow ? "80px" : "280px")};
  margin-top: 2px;
  position: fixed;
  z-index: 998;
  background-color: #f4f4f4;
  height: 30px;
  line-height: 30px;
  color: #2c3e50;
  text-align: center;
  width: 20px;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2);
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  cursor: pointer;
  font-size: 20px;

  color: ${props => props.theme.dashboard.colors.primary};

  :hover {
    color: #39acb5;
  }

  ${media.lessThan("large")`
      display: none;
    `}
`;

export const CustomHR = styled.div`
  width: 50px;
  margin-top: 0px !important;
  margin-left: 25px;
  margin-bottom: 50px;
  border-bottom: 4px solid ${props => props.theme.colors.textNew};
  
  @media (max-width: 912px) {
    margin-left: 5px;
  }
`;
