import React from 'react'
import styled, { css } from 'styled-components'

const ImageStyled = styled.img`
  display: block;
  max-width: 100%;
  ${props => props.height ? css`height: ${props.height}` : 'height: auto'}
  ${props => props.center && `margin-left: auto; margin-right: auto;`}
`

export default function Image (props) {
  return (
    <ImageStyled {...props} />
  )
}
