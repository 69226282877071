import Request from 'modules/Request'

export default {
  create (data, params) {
    return Request.api({
      method: 'POST',
      url: `/api/password`,
      body: data,
      query: params
    }).then(res => ({ data: [] }))
  },

  update (token, data, params) {
    return Request.api({
      method: 'PUT',
      url: `/api/password/${token}/reset`,
      body: data,
      query: params
    }).then(res => res.body)
  }
}
