import React from 'react';
import Icon from '@fortawesome/react-fontawesome';
import SubmissionContainer from 'admin/components/SubmissionContainer';
import ButtonSubmit from 'components/ButtonSubmit';
import CustomField from 'components/CustomField';
import FormGrid from 'components/FormGrid';
import Link from 'components/Link';
import VerticalSpacer from 'components/VerticalSpacer';
import Notifications from 'containers/Notifications';
import { Field, Form } from 'react-final-form';
import { injectIntl } from 'react-intl';
import * as forms from 'utils/forms';
import { countries } from '../../../mocks/countries';
import Resource from 'containers/Resource';
import { Container } from '../FormValidCupom/styles';
import CustomFieldSelect from 'admin/components/CustomFieldSelect';

function FormAdditionalInformationRegister({
  intl,
  hideForgetPasswordLink,
  company,
  formData,
  setFormData,
  setPage,
  isSubmitting,
  isFetching,
  auth,
  ...props
}) {
  const lang = {
    pt: 'BR',
    'pt-BR': 'BR',
    en: 'US',
    fr: 'FR',
    es: 'ES',
  };

  const formSubmit = (e, props, callback) => {
    e.preventDefault();

    // Remover atributos
    let uf = props.values.ddi;
    props.values.uf = uf ? countries['en'][handleCodeByOption(uf)] : '';
    props.values.nationality = handleCodeByOption(props.values.nationality);

    //state e setState e mudança das pages
    setFormData({ ...props.values });
    sessionStorage.clear();

    callback();
  };

  const handleOptionByCode = code => {
    let obj = countries[intl.locale][code];
    return obj.option;
  };

  const handleCodeByOption = option => {
    if (!option) return;
    let code = option
      .replace(option.replace(/(\[\w{2}\])/g, ''), '')
      .replace(/\[|\]/g, '')
      .trim();
    code = code ? countries['en'][code].code : '';
    return code;
  };

  //função que volta as pages
  const handleBackPage = e => {
    e.preventDefault();

    sessionStorage.clear();
    setPage(currPage => currPage - 1);
  };

  return (
    <Form
      {...props}
      initialValues={{
        ...formData,
        nationality: handleOptionByCode(lang[intl.locale]),
        step: 'form_additional_info_validate',
        index: 3,
      }}
    >
      {({ handleSubmit, hasValidationErrors, ...formProps }) => (
        <form onSubmit={e => formSubmit(e, formProps, handleSubmit)}>
          <VerticalSpacer>
            <Notifications />
            <FormGrid>
              <Field
                name="cpf"
                label={
                  handleCodeByOption(formProps.values.nationality) === 'BR'
                    ? intl.formatMessage({ id: 'admin.common.cpf' })
                    : intl.formatMessage({ id: 'admin.common.document' })
                }
                id="form_patient_cpf"
                component={CustomField}
                placeholder="720.149.070-21"
                showMandatory
                {...forms.cpfRequired}
              />
            </FormGrid>

            <FormGrid>
              {handleCodeByOption(formProps.values.nationality) === 'BR' ? (
                <Field
                  name="cep"
                  id="form_address_cep"
                  label={intl.formatMessage({ id: 'admin.common.cep' })}
                  tooltip={intl.formatMessage({
                    id: 'admin.common.cepTooltip',
                  })}
                  component={CustomField}
                  placeholder="45604-690"
                  showMandatory
                  {...forms.cepRequired}
                />
              ) : (
                <Field
                  name="cep"
                  id="form_address_cep"
                  label={intl.formatMessage({ id: 'admin.common.cep' })}
                  tooltip={intl.formatMessage({
                    id: 'admin.common.codeTooltip',
                  })}
                  component={CustomField}
                  placeholder="19633"
                  showMandatory
                  {...forms.codeRequired}
                />
              )}
            </FormGrid>

            <FormGrid>
              <Resource resource="HealthInsurances" autoFetch>
                {props => (
                  <Container>
                    <Field
                      name="plano_saude"
                      label={intl.formatMessage({
                        id: 'admin.healthInsurance.title',
                      })}
                      id="form_company_plano_de_saude"
                      component={CustomFieldSelect}
                      placeholder={intl.formatMessage({
                        id: 'admin.comon.select',
                      })}
                      customSelectProps={{
                        options: props.records,
                        labelKey: 'nome',
                        valueKey: 'id',
                        multi: false,
                        simpleValue: false,
                        isLoading: props.isFetching,
                      }}
                      showMandatory
                      {...forms.required}
                    />
                  </Container>
                )}
              </Resource>
            </FormGrid>

            <SubmissionContainer>
              <Link to={''} onClick={e => handleBackPage(e)}>
                <Icon
                  className="icon_arrow"
                  style={{ marginRight: '10px', width: 15 }}
                  icon={require('@fortawesome/fontawesome-free-solid/faArrowLeft')}
                />
                {intl.formatMessage({ id: 'pages.register.code.return' })}
              </Link>

              <ButtonSubmit
                color="primary"
                textColor="white"
                isSubmitting={isSubmitting}
                disabled={isSubmitting || hasValidationErrors}
                label={intl.formatMessage({
                  id: 'pages.finalize.registration',
                })}
              />
            </SubmissionContainer>
          </VerticalSpacer>
        </form>
      )}
    </Form>
  );
}

export default injectIntl(FormAdditionalInformationRegister);
