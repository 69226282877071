import React from 'react'
import styled from 'styled-components'
import mediaQuery from 'utils/mediaQuery'

const AccountContainerStyled = styled.div`  
  padding-bottom: 30px;
  
  ${mediaQuery.greaterThan('small')`
    width: 600px;
  `}
  ${mediaQuery.greaterThan('large')`
    width: 800px;
  `}
`

export default function AccountContainer(props){
  return <AccountContainerStyled {...props} />
}
