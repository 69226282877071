import Base from 'components/Base'
import PropTypes from 'prop-types'
import { omit } from 'ramda'
import React from 'react'
import styled, { css } from 'styled-components'
import { componentType } from 'types'
import mediaQuery from 'utils/mediaQuery'

const filterProps = omit([
  'color',
  'size',
  'align',
  'border'
])

export const SectionTitleStyled = styled(props => <Base {...filterProps(props)} />)`
  display: block;
  font-family: ${props => props.font ? props.font : props.theme.fonts.secondary};
  font-weight: ${props => props.color === 'infoSection' ? 'bold': 'normal'};
  font-size: 35px;
  color: ${props => console.log(43243, props.theme.colors[props.color] || props.color) && (props.theme.colors[props.color] || props.color)};
  text-align: ${props => props.align}; 
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  ${mediaQuery.greaterThan('large')`
    font-size: ${props => props.size};
  `}

  ${props => props.border && css`
    &::after {
      content: ' ';
      display: block;
      background-color: #000;
      width: 45px;
      height: 3px;
      ${props => props.align === 'center' && 'margin: 0 auto;'}
      margin-top: 15px;
    }
  `}
`

export default function SectionTitle (props) {
  return <SectionTitleStyled {...props} />
}

SectionTitle.propTypes = {
  /** Título */
  children: PropTypes.any.isRequired,

  /** Cor */
  color: PropTypes.string,

  /** Componente */
  component: componentType,

  /** Tamanho da fonte */
  size: PropTypes.string,

  /** Alinhamento */
  align: PropTypes.string,

  /** Adiciona uma pequena borda em baixo do título */
  border: PropTypes.bool
}

SectionTitle.defaultProps = {
  component: 'h2',
  color: 'text',
  size: '50px',
  align: 'left',
  border: false
}
