import { combineReducers } from 'redux'
import localization from 'store/ducks/localization'
import auth from 'store/ducks/auth'
import notifications from 'store/ducks/notifications'
import resources from 'store/ducks/resources'
import companies from 'store/ducks/companies'
import questionnaire from 'store/ducks/questionnaire'
import register from 'store/ducks/register'
import userQuestionnaire from 'store/ducks/userQuestionnaire'

export default combineReducers({
  localization,
  auth,
  notifications,
  resources,
  companies,
  questionnaire,
  register,
  userQuestionnaire
})
