import React, { Fragment } from 'react';
import { FunnelChart } from 'react-funnel-pipeline';
import 'react-funnel-pipeline/dist/index.css';
import { injectIntl } from 'react-intl';
import { Container, FlexBox } from './styles.js';

const ChartFunnel = ({ intl, data }) => {
  const handleFormatDataset = (data = []) => {
    return data.length
      ? data.map(item => ({
          name: intl.formatMessage({
            id: item.name,
          }),
          value: item.value,
        }))
      : [];
  };
  return (
    <FlexBox>
      <Container>
        {Object.keys(data).length ? (
          <Fragment>
            <FunnelChart
              chartHeight={300}
              chartWidth={600}
              data={handleFormatDataset(data)}
              pallette={['#4f8699', '#8854c4', '#68ad76']}
              getRowNameStyle={() => {
                return { fontSize: '12px', color: '#ffffff' };
              }}
              getRowValueStyle={() => {
                return { fontSize: '18px', color: '#ffffff' };
              }}
              getToolTip={row => {
                const { name, value } = row;
                return `${name} : ${value}`;
              }}
              showValues={true}
              showNames={true}
              style={{ marginTop: '-5px' }}
              title={intl.formatMessage({
                id: 'admin.common.funnel',
              })}
            />
          </Fragment>
        ) : (
          <Fragment>
            <Container>
              <h2 className="title_none_data">
                {intl.formatMessage({
                  id: 'admin.common.funnel',
                })}
              </h2>
              <center>
                <img
                  src={require('../../../images/ban-solid.svg')}
                  alt="Emoji"
                  className="emoji_none_data"
                />
                <h3 className="title_none_data">
                  {intl.formatMessage({
                    id: 'admin.common.data.found',
                  })}
                </h3>
              </center>
            </Container>
          </Fragment>
        )}
      </Container>
    </FlexBox>
  );
};

export default injectIntl(ChartFunnel);
