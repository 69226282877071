import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

  const HrStyled = styled.hr`
    padding-top: 0;
    color: ${props => props.color};
    display: block;
    unicode-bidi: isolate;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: auto;
    margin-inline-end: auto;
    overflow: hidden;
    border-style: inset;
    font-family: 'Poppins',sans-serif;
    border-width: 1px;
    margin-top: 30px;
    margin-bottom: 31px;

    &::after {
      content: "${props => props.content}";
      color: #7F8C8D;
      display: inline-block;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%) rotate(0deg);
      transform-origin: 50% 50%;
      padding: 1rem;
      background-color: #f9ebbe;
      border: none;
    }
  `;
export default function HrCustom(props) {

  return <HrStyled {...props} />;
}

HrCustom.propTypes = {
  /** color do hr */
  color: PropTypes.string,

  /** Texto do hr */
  content: PropTypes.string,
};

HrCustom.defaultProps = {
  content: '',
  color: 'transparent',
};
