import React from "react";
import { injectIntl } from "react-intl";
import ButtonSubmit from "components/ButtonSubmit";
import FormGrid from "components/FormGrid";
import ButtonCopy from "../ButtonCopy/ButtonCopy";
import { useExcelDownloder } from 'react-xls';
import { SectionButton } from "./styles";
import "./style.css";


  let csvValues = [];
  let headers = [];
  let copy = [];

const ButtonXls = ({ columns, book, data }) => {
  const { ExcelDownloder, Type , setData} = useExcelDownloder(); 

  if (columns) {
    columns.forEach(item => {
      headers.push({ label: item, key: item });
    });
  }
  if (data) {
    if (data !==csvValues) {
      setData(data)
      csvValues = data
    }
    copy = data.data
    
  }

  const handleCopy = () => {
    return copy;
  };

  return (
    <SectionButton>
      <FormGrid>
        <ButtonCopy copyTable={handleCopy()} />
        <ExcelDownloder
          data={data}
          headers={headers}
          filename={book}
          type={Type.Link}
          style={{textDecoration: 'none'}}
        >
          <ButtonSubmit className="button_xls" label={"Excel"} />
        </ExcelDownloder>
      </FormGrid>
    </SectionButton>
  );
};

export default injectIntl(ButtonXls);