import React from 'react'
import { componentFromProp } from 'recompose'
import { componentType } from 'types'

const BaseFromProp = componentFromProp('component')

export default function Base (props) {
  return <BaseFromProp {...props} />
}

Base.propTypes = {
  component: componentType.isRequired
}

Base.defaultProps = {
  component: 'div'
}
