import React from 'react'
import CustomField from 'components/CustomField'
import Uploader from 'admin/components/Uploader'
import Resource from 'containers/Resource'

function CustomUploader ({ onChange, value, id, accept }) {
  return (
    <Resource
      resource='Media'
      namespace={id}
      onSuccess={(payload, action) => {
        if (action === 'create') {
          return onChange(payload.data)
        }
        return onChange({})
      }}
      notifications={{
        create: {
          success: false,
          errors: true
        }
      }}
    >
      {props => (
        <Uploader
          isLoading={props.isSubmitting || props.isRemoving}
          onUpload={props.create}
          onRemove={image => props.remove(image.nome)}
          file={value || {}}
          accept={accept}
        />
      )}
    </Resource>
  )
}

export default props =>
  <CustomField component={CustomUploader} {...props} />
