import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { injectIntl } from 'react-intl';
import { GoogleLogin } from 'react-google-login';
import MicrosoftLogin from 'react-microsoft-login';
import Icon from '@fortawesome/react-fontawesome';
import Section from 'admin/components/Section';
import SubmissionContainer from 'admin/components/SubmissionContainer';
import ButtonSubmit from 'components/ButtonSubmit';
import CustomField from 'components/CustomField';
import FormGrid from 'components/FormGrid';
import Link from 'components/Link';
import OptionCheckbox from 'components/OptionCheckbox/OptionCheckbox';
import VerticalSpacer from 'components/VerticalSpacer';
import Notifications from 'containers/Notifications';
import { gapi } from "gapi-script";
import PropTypes from 'prop-types';
import routes from 'routes';
import * as forms from 'utils/forms';
import HrCustom from '../../../admin/components/HrCustom/HrCustom';
import { CLIENT_ID_GOOGLE, CLIENT_ID_MICROSOFT } from '../../../config/constants';
import IconMicrosoft from '../../../images/microsoft.png';
import {
  ButtonMicrosoft, Container,
  ContainerCaptcha,
  ContainerLoginAccout
} from './style';

function HLCaptcha({ text, defaultChecked, handleExecute }) {
  return (
    <ContainerCaptcha>
      <OptionCheckbox defaultChecked={defaultChecked} onClick={handleExecute}>
        {' '}
        {text}
      </OptionCheckbox>
    </ContainerCaptcha>
  );
}
function FormLogin({
  isSubmitting,
  intl,
  hideForgetPasswordLink,
  hideNotifications,
  patient,
  setLoginWithApi,
  ...props
}) {
  // const recaptchaRef = React.createRef();
  const [isPasswordLoginShown, setIsPasswordLoginShown] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [isChecked, setChecked] = useState(false);

  //função do captchar
  function onChange(value) {
    if (value) {
      setCaptcha(item => !item);
      setChecked(true);
    }
  }
  function handleExecute() {
    if (window.grecaptcha) window.grecaptcha.execute();
  }

  //função de mostrar e ocultar senha
  const tooglePasswordLoginVisibility = () => {
    setIsPasswordLoginShown(!isPasswordLoginShown);
  };

  //Função register do google
  const googleSuccess = response => {
    let userObject = response;

    props.login(
      userObject.profileObj.email,
      userObject.profileObj.googleId,
      'usersAdmWeb',
      window.recaptchaValue,
      'Gtoken',
    );

    if (props.isAuthenticated) {
      window.href = routes.users.index;
    }
  };

  const googleFailure = error => {
    console.log(error);
    console.log('Google Sing In ha fracasado intentelo denuevo mas tarde');
  };

  //Função register do microsoft
  const microsoftSucces = (err, authData, msalInstance) => {
    if (authData) {
      props.login(
        msalInstance.account.userName,
        msalInstance.account.accountIdentifier,
        'usersAdmWeb',
        window.recaptchaValue,
        'MStoken',
      );
    }

    sessionStorage.clear();

    if (props.isAuthenticated) {
      window.href = routes.users.index;
    }
  };

  return (
    <Section
      smallTitle={intl.formatMessage({ id: 'users.login.header.message' })}
    >
      <Container>
        <Form {...props}>
          {({ handleSubmit, pristine }) => (
            //this.onSubmit
            <form onSubmit={handleSubmit}>
              <VerticalSpacer>
                {!hideNotifications && <Notifications />}
                {!patient ? (
                  <FormGrid>
                    <Field
                      name="email"
                      label={intl.formatMessage({
                        id: 'admin.forms.login.title',
                      })}
                      id="form_login_email"
                      component={CustomField}
                      {...forms.loginRequired}
                    />
                  </FormGrid>
                ) : (
                  ''
                )}
                <FormGrid>
                  <div className="section_input_password">
                    <Field
                      name="password"
                      type={isPasswordLoginShown ? 'text' : 'password'}
                      label={intl.formatMessage({
                        id: 'admin.forms.login.password',
                      })}
                      id="form_login_password"
                      className="input_password"
                      tooltip={
                        patient
                          ? intl.formatMessage({ id: 'hints.number' })
                          : ''
                      }
                      component={CustomField}
                      {...forms.passwordRequired}
                    />
                    <Icon
                      id="open_eye"
                      color="#A6ACAF"
                      className="icon_password"
                      icon={
                        isPasswordLoginShown
                          ? require('@fortawesome/fontawesome-free-solid/faEyeSlash')
                          : require('@fortawesome/fontawesome-free-solid/faEye')
                      }
                      onClick={tooglePasswordLoginVisibility}
                    />
                  </div>
                </FormGrid>

                <ReCAPTCHA
                  sitekey="6LdKaMEUAAAAANh-sTabaJT3xrAfUVPlWxL2YtwQ"
                  onChange={onChange}
                  size="invisible"
                />

                <HLCaptcha
                  text={intl.formatMessage({ id: 'Não Sou um robô' })}
                  defaultChecked={isChecked}
                  handleExecute={handleExecute}
                />

                <Container>
                  <HrCustom
                    content={intl.formatMessage({ id: 'admin.login.with' })}
                  />
                </Container>

                <ContainerLoginAccout>
                  <center>
                    <MicrosoftLogin
                      clientId={CLIENT_ID_MICROSOFT}
                      authCallback={microsoftSucces}
                      prompt={'select_account'}
                    >
                      <ButtonMicrosoft type="button">
                        <img
                          src={IconMicrosoft}
                          className="image_button_microsoft"
                          alt="icon_microsoft"
                        />
                        {intl.formatMessage({ id: 'admin.sign.in.microsoft' })}
                      </ButtonMicrosoft>
                    </MicrosoftLogin>

                    <GoogleLogin
                      clientId={CLIENT_ID_GOOGLE}
                      buttonText={intl.formatMessage({
                        id: 'admin.sign.in.google',
                      })}
                      className="button_register_google"
                      onSuccess={googleSuccess}
                      onFailure={googleFailure}
                      cookiePolicy="single_host_origin"
                      prompt='select_account'
                    ></GoogleLogin>
                  </center>
                </ContainerLoginAccout>

                <SubmissionContainer>
                  <ButtonSubmit
                    label={intl.formatMessage({
                      id: 'users.login.button.enter',
                    })}
                    isSubmitting={isSubmitting}
                    disabled={!captcha}
                  />

                  <Link
                    color="textNew"
                    to={routes.users.register}
                    onClick={window.location.reload}
                  >
                    {intl.formatMessage({ id: 'pages.register.first.access' })}
                  </Link>
                  {!hideForgetPasswordLink && (
                    <Link color="textNew" to={routes.users.passwordRecovery}>
                      {intl.formatMessage({
                        id: 'admin.forms.login.passwordRecovery',
                      })}
                    </Link>
                  )}
                </SubmissionContainer>
              </VerticalSpacer>
            </form>
          )}
        </Form>
      </Container>
    </Section>
  );
}

FormLogin.propTypes = {
  /** Callback para o form submit */
  onSubmit: PropTypes.func.isRequired,

  /** Form sendo processado */
  isSubmitting: PropTypes.bool,
};

export default injectIntl(FormLogin);
