import React from 'react'
import { injectIntl } from 'react-intl'
import styled, { withTheme } from 'styled-components'
import Text from 'components/Text'

const Container = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-top: 20px;
`

function FewDetailsRefillWarningMessage({ theme, intl, ...props}){
  return (
    <Container>
      <Text size="36px" weight="bold" color="#fdba84" fontFamily={theme.fonts.secondary}>{intl.formatMessage({ id: 'users.results.refillWarning' })}</Text>
      <Text size="28px" weight="bold" color="#fdba84" fontFamily={theme.fonts.secondary}>{intl.formatMessage({ id: 'users.results.refillWarningRec' })}</Text>
    </Container>
  )
}

export default withTheme(
  injectIntl(FewDetailsRefillWarningMessage)
)
