import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@fortawesome/react-fontawesome';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { Field, Form } from 'react-final-form';
import { injectIntl } from 'react-intl';
import CustomFieldSelect from 'admin/components/CustomFieldSelect';
import Section from 'admin/components/Section';
import VerticalSpacer from 'components/VerticalSpacer';
import Button from 'components/Button';
import Resource from 'containers/Resource';
import DataTableReports from '../../components/DataTableReports';
import Spinner from '../../../images/spinner.gif';
import { Flex, Strech } from '../../../admin/components/Responsive/Responsive';
import { CustomComponentCompany } from '../../../admin/components/CustomComponentCompany/CustomComponetCompany';
import '../../../assets/css/reports.css';

const FormReportMentalRisk = ({
  isSubmitting,
  intl,
  readOnly,
  isFetching,
  ...props
}) => {
  const [companySelected, setCompanySelected] = useState([]);
  const [filterDate, setFilterDate] = useState({
    start: '',
    end: '',
    start_date: '',
    end_date: '',
    str: '',
  });

  //limpa as datas quando não tem nada selecionado no campo
  const handleCancel = (event, picker) => {
    picker.element.val('');
    setFilterDate({
      start: '',
      end: '',
      start_date: '',
      end_date: '',
      str: '',
    });
  };

  //seta a data selecionada
  const handleSetDate = (event, picker) => {
    let start, end;
    start = moment(picker.startDate);
    end = moment(picker.endDate);

    setFilterDate({
      start: start.format('DD/MM/YYYY'),
      end: end.format('DD/MM/YYYY'),
      start_date: start.format('YYYY-MM-DD'),
      end_date: end.format('YYYY-MM-DD'),
      str: `${start.format('DD/MM/YY')} - ${end.format('DD/MM/YY')}`,
    });
  };

  const handleFormSubmit = (e, { fetchAll, values, isFetching, ...props }) => {
    e.preventDefault();

    let data = {
      filterdate: filterDate,
      company: companySelected,
      v_ishistory: values.v_ishistory,
    };
    fetchAll(data);
  };

  return (
    <div>
      <Section
        style={{
          overflow: 'visible',
          marginBottom: 40,
          marginTop: -10,
          background: 'none',
          border: 'none',
          padding: 0,
        }}
        title={intl.formatMessage({ id: 'admin.filterReportsTable' })}
      >
        <Form {...props}>
          {({ handleSubmit, pristine, ...props }) => (
            <form onSubmit={e => handleFormSubmit(e, props)}>
              <VerticalSpacer>
                <Flex space={10} direction={{ sm: 'column', md: 'row' }}>
                  <DateRangePicker
                    initialSettings={{
                      autoUpdateInput: false,
                      locale: intl.formatMessage({
                        id: 'plugin.daterangepicker.settings',
                      }),
                      // startDate: filterDate.start,
                      // endDate: filterDate.end,
                    }}
                    onApply={handleSetDate}
                    onCancel={handleCancel}
                  >
                    <div>
                      <Strech
                        className="section_button_datapicker_reports"
                        sm={`max-width: 100%;`}
                        md={`width: 250px; max-width: 250px;`}
                      >
                        <button
                          className="button_datapicker_reports"
                          onClick={e => e.preventDefault()}
                        >
                          {filterDate.str
                            ? filterDate.str
                            : 'DD/MM/AA - DD/MM/AA'}
                          <Icon
                            className="icon_button_datapicker_reports"
                            icon={require('@fortawesome/fontawesome-free-solid/faCalendarAlt')}
                          />
                        </button>
                      </Strech>
                    </div>
                  </DateRangePicker>

                  <Resource resource="ReportsApiCompany" autoFetch>
                    {props => (
                      <Field
                        sm={`max-width: 100%`}
                        md={`max-width: 250px`}
                        name="company_reports_company"
                        id="form_company_reports_company"
                        component={CustomComponentCompany}
                        className="select_filter_company_reports"
                        onChange={setCompanySelected}
                        labelledBy={intl.formatMessage({
                          id: 'admin.filter.company.title',
                        })}
                        title={companySelected.map(e => e.label).join(', ')}
                        customSelectProps={{
                          value: companySelected,
                          options: props.records,
                          labelKey: 'nome',
                          valueKey: 'id',
                          isLoading: props.isFetching,
                          multi: true,
                          simpleValue: false,
                        }}
                        // showMandatory
                        // {...forms.required}
                      />
                    )}
                  </Resource>

                  <Strech
                    className="section_button_datapicker_reports"
                    sm={`max-width: 100%;`}
                    md={`width: 250px; max-width: 250px;`}
                  >
                    <Field
                      name="v_ishistory"
                      id="form_v_ishistory"
                      component={CustomFieldSelect}
                      className="input_filter_data_reports"
                      placeholder={intl.formatMessage({
                        id: 'admin.common.filterData',
                      })}
                      customSelectProps={{
                        options: [
                          {
                            label: intl.formatMessage({
                              id: 'admin.common.mostRecent',
                            }),
                            labelKey: 'nome',
                            value: 1,
                            isLoading: props.isFetching,
                          },
                          {
                            label: intl.formatMessage({
                              id: 'admin.common.history',
                            }),
                            labelKey: 'nome',
                            value: 0,
                            isLoading: props.isFetching,
                          },
                        ],
                      }}
                    />
                  </Strech>

                  <Button
                    className="button_filter_reports"
                    label={intl.formatMessage({ id: 'admin.common.send' })}
                    disabled={isFetching}
                  >
                    {!isFetching ? (
                      <Icon
                        style={{ width: 15 }}
                        icon={require('@fortawesome/fontawesome-free-solid/faFilter')}
                      />
                    ) : (
                      <img src={Spinner} alt="reload" style={{ width: 20 }} />
                    )}
                  </Button>
                </Flex>
              </VerticalSpacer>
            </form>
          )}
        </Form>
      </Section>

      <Section
        style={{ overflow: 'visible' }}
        title={intl.formatMessage({ id: 'admin.common.report.mentalRisk' })}
      >
        <DataTableReports
          autoFetch={false}
          resource={'ReportsMentalRisk'}
          report={intl.formatMessage({ id: 'admin.common.report.Mental_Risk' })}
        />
      </Section>
    </div>
  );
};

FormReportMentalRisk.propTypes = {
  /** Callback para o form submit */
  onSubmit: PropTypes.func.isRequired,

  /** Form sendo processado */
  isSubmitting: PropTypes.bool,
};

export default injectIntl(FormReportMentalRisk);
