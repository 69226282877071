import React from 'react'
import styled from 'styled-components'

const PageContentStyled = styled.div`
  padding: 0;
`

export default function PageContent (props) {
  return (
    <PageContentStyled {...props} />
  )
}
