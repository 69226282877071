import Container from 'components/Container'
import PageContent from 'components/PageContent'
import React from 'react'
import withAuth from 'store/utils/withAuth'
import AvailableDiagnostics from 'users/components/AvailableDiagnostics'
import Video from 'users/components/Video'
import WelcomeDashboardHeader from 'users/components/WelcomeDashboardHeader'

class DashboardScreen extends React.Component {
  render() {
    const empresa = this.props.auth.user.empresa;
    return (
      <PageContent blog>
        <Container size='small' margin="0 0">
          <WelcomeDashboardHeader />
          <AvailableDiagnostics />

        
          {empresa.link_video ? (<Video className={'mobile'} link={empresa.link_video} />) : ''}
      
          
        </Container>
      </PageContent>
    )
  }
}

export default withAuth(DashboardScreen);
