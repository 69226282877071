import React from 'react'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import QuestionnaireScreen from 'users/questionnaire/components/QuestionnaireScreen'
import { Link, withRouter } from 'react-router-dom'
import Text from 'components/Text'
import Button from 'components/Button'
import getRoute from 'utils/getRoute'
import routes from 'routes'
import theme from 'theme'
import withAuth from 'store/utils/withAuth'
import { GENDER } from 'config/constants'
import { UserQuestionnaire } from 'store/api'
import PageContent from 'components/PageContent'
import Spinner from 'components/Spinner'
import { WEB_ANSWER_ID } from 'config/constants'
import mediaQuery from 'utils/mediaQuery'
import { MEDICAL_HISTORY_IDS} from 'config/constants'

const ButtonContainer = styled.div`
display: flex;
flex-direction: row;
gap: 10px
`

const AnsweredQuestionContainer = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
grid-column-gap: 15px;

${mediaQuery.lessThan('large')`
  grid-template-columns: 1fr;
`}
`

const AnsweredMedicalHistoryQuestions = styled.div`
display: block;
border: 1px solid ${theme => theme.theme.colors.border};
background-color: white;
border-radius: 10px;
padding: 15px;
margin-top: 15px;
`

const AnsweredQuestion = styled(Text)`
font-size: 22px
font-family: ${theme => theme.theme.fonts.tertiary}

& > strong {
  color: ${theme => theme.theme.colors.personal}
}
`

const AnsweredQuestionAnswer = styled(AnsweredQuestion)`
font-size: 15px
`

class SeeMedicalHistory extends React.Component{
  state = {
    loading: true,
    response: undefined
  }

  async getMedicalHistory(){
    try {
      const response = await UserQuestionnaire.loadMedicalHistory()
      if (response && !this.unmounted) {
        this.setState({ response, loading: false })
      }
    } catch (err) {
      if(!this.unmounted){
        this.setState({ loading: false })
      }
    }
  }

  componentDidMount() {
    this.getMedicalHistory()
  }

  componentWillUnmount () {
    this.unmounted = true
  }

  render (){
    const { intl, auth, match } = this.props 
    const { loading, response } = this.state 

    const okMedicalHistory = () => {
      const { match } = this.props 
      if(MEDICAL_HISTORY_IDS.includes(parseInt(match.params.anamneseID))){
        UserQuestionnaire.okMedicalHistory()
      }
    }

    const fill = MEDICAL_HISTORY_IDS.includes(parseInt(match.params.anamneseID)) ? getRoute(routes.users.index) : getRoute(routes.users.diagnostic.startAnamnese, { anamneseID: match.params.anamneseID })
    const update = getRoute(routes.users.diagnostic.startAnamnese, { anamneseID: auth.user.userData.genero_biologico === GENDER.male.id ? 7 : 8 })
    
    if(loading || !response){
      return (
        <PageContent>
          <Spinner />
        </PageContent>
      )
    }

    const questions = JSON.parse(response.answer_json)

    return (
      <QuestionnaireScreen
      title={intl.formatMessage({ id: 'admin.anamnesis.medicalHistory' })}
      personal
      size="small"
    >
      <Text
        size='25px'
        lineHeight={1}
        style={{ marginBottom: 15 }}
        fontFamily={theme.fonts.tertiary}
      >
        <strong>
          {intl.formatMessage({ id: 'admin.anamnesis.medicalHistory.description' })}
        </strong>
      </Text>

      <AnsweredQuestionContainer>
        {questions.map(question => (
          <AnsweredMedicalHistoryQuestions key={question.questionanswer_id}>
            <AnsweredQuestion>
              <strong>
                { intl.formatMessage({ id: question.question_name }) }
              </strong>
            </AnsweredQuestion>
            <AnsweredQuestionAnswer>
              { question.original_answer_id === WEB_ANSWER_ID
              ? intl.formatMessage({ id: question.web_answer })
              : intl.formatMessage({ id: question.answer_name }) }
            </AnsweredQuestionAnswer>
          </AnsweredMedicalHistoryQuestions>
        ))}
      </AnsweredQuestionContainer>
      <ButtonContainer>
        <Button
          to={update}
          color='primary'
          style={{width: '100%', marginTop: '50px'}}
          component={Link}
        >
          {intl.formatMessage({ id: 'admin.anamnesis.medicalHistory.button.update' })}
        </Button>

        <Button
          to={fill}
          onClick={() => okMedicalHistory()}
          color='personal'
          style={{width: '100%', marginTop: '50px'}}
          component={Link}
        >
          {intl.formatMessage({ id: 'admin.anamnesis.medicalHistory.button.correct' })}
        </Button>
      </ButtonContainer>
    </QuestionnaireScreen>
    )
  }
}

export default withAuth(
  withRouter(
    injectIntl(SeeMedicalHistory)
  )
)
