import Image from 'components/Image';
import { LANGUAGES } from 'config/constants';
import Localization from 'containers/Localization/Localization';
import Resource from 'containers/Resource';
import br from 'images/br.svg';
import eng from 'images/eng.svg';
import es from 'images/es.svg';
import fr from 'images/france.svg';
import React from 'react';
import { Button, Container } from './styles';

const SelectLanguage = () => {
  return (
    <Resource resource="UserPatients">
      {props => (
        <Localization {...props}>
          {props => {
            return (
              <Container>
                <Button
                  type="button"
                  aria-label="Português brasileiro"
                  onClick={() => props.setLang(LANGUAGES['pt'])}
                >
                  <Image src={br} alt="Português brasileiro" />
                </Button>
                <Button
                  type="button"
                  aria-label="Inglês"
                  onClick={() => props.setLang(LANGUAGES['en'])}
                >
                  <Image src={eng} alt="Inglês" />
                </Button>
                <Button
                  type="button"
                  aria-label="Espanhol"
                  onClick={() => props.setLang(LANGUAGES['es'])}
                >
                  <Image src={es} alt="Espanhol" />
                </Button>
                <Button
                  type="button"
                  aria-label="Francês"
                  onClick={() => props.setLang(LANGUAGES['fr'])}
                >
                  <Image src={fr} alt="Francês" />
                </Button>
              </Container>
            );
          }}
        </Localization>
      )}
    </Resource>
  );
};
export default function Languages(props) {
  return (
    <div>
      <SelectLanguage />
      {props.children}
    </div>
  );
}
