import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 20px;
  display: inline-flex;
  background-color: #fff;
  padding: 6px 8px;
  border-radius: 0 0 3px 3px;
  border: 1px solid ${props => props.theme.colors.border};
  border-top: none;
`

export const Button = styled.button`
  display: block;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;

  & + & {
    margin-left: 7px;
  }

  &:hover {
    opacity: 0.7;
  }
`