import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'admin/components/Modal'

export default class WithModal extends Component {
  static propTypes = {
    /** Conteúdo do modal */
    modal: PropTypes.any.isRequired,

    /** Render function com a função para togglear o modal */
    children: PropTypes.func
  }

  state = {
    isOpened: !!this.props.isOpened,
    props: {}
  }

  toggleModal = (props = {}) => {
    this.setState({
      isOpened: !this.state.isOpened,
      props
    })
  }

  closeModal = () => {
    this.setState({
      isOpened: false
    })
  }

  render () {
    const { children, modal, ...props } = this.props
    const { toggleModal, closeModal } = this
    return (
      <div>
        {children({
          toggleModal,
          closeModal,
          isOpened: this.state.isOpened
        })}
        <Modal
          isOpened={this.state.isOpened}
          onRequestClose={() => this.toggleModal()}
          {...props}
        >
          {
            typeof modal === 'function'
              ? React.createElement(modal, {
                ...this.state.props,
                toggleModal,
                closeModal
              })
              : modal
          }
        </Modal>
      </div>
    )
  }
}
