import Request from 'modules/Request'
import { CLIENT_ID, CLIENT_SECRET, GRANT_TYPE } from 'config/constants'
import Session from 'modules/Session'

export const getTokenKey = type => `token.${type}`

async function requestToken (username, password, type, captcha, method) {
  const response = await Request.api({
    method: 'POST',
    url: 'api/oauth/token',
    body: {
      username,
      password,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      grant_type: GRANT_TYPE,
      provider: type, 
      vrecaptcha: captcha,
      method: method
    }
  })

  const { access_token: token } = response.body

  return Session.set(getTokenKey(type), token)
}

export default {
  async login (email, password, type, captcha, method) {
    const key = getTokenKey(type)

    if (!Session.get(key)) {
      await requestToken(email, password, type, captcha, method)
    }

    Session.set('authType', type)

    return Request.auth({
      url: type === 'usersAdmWeb' ? '/api/user' :'/api/usuario'
    })
      .then(res => res.body)
      .catch(err => {
        Session.remove(key)
        throw err
      })
  },

  deleteData(type){
    return Request.auth({
      method: 'DELETE',
      url: 'api/user/patient',
    }).then( res => {
      return res.body
    }).catch(err => {
      Session.remove(type)
      throw err
    })
  }
}
