import ProtectedScreen from 'admin/components/ProtectedScreen';
import Auth from 'admin/containers/Auth';
import FormUserPassword from 'admin/forms/FormUserPassword';
import VerticalSpacer from 'components/VerticalSpacer';
import Localization from 'containers/Localization/Localization';
import Resource from 'containers/Resource';
import React from 'react';
import { injectIntl } from 'react-intl';
import AccountContainer from 'users/components/AccountContainer';
import FormUserDetails from 'users/forms/FormUserDetails';
import FormUserDetailsRemoveAccount from '../../forms/FormUserDetailsRemoveAccount';

function MyAccountScreen({ intl }) {
  return (
    <ProtectedScreen
      title={intl.formatMessage({ id: 'admin.common.myAccount' })}
    >
      <Auth>
        {({ user: { id } }) => (
          <Resource resource="UserPatients" id={id} autoFetch blog spinner>
            {props => (
              <AccountContainer>
                <VerticalSpacer space={45}>
                  <Localization {...props}>
                    {props => {
                      return (
                        <FormUserDetails
                          initialValues={props.detailedRecord}
                          action={'update'}
                          permissions={false}
                          onSubmit={async values => {
                            await props.update(id, values, { myAccount: true });
                            //props.setLang(LANGUAGES[data.value.data.language])
                            //setTimeout(()=> window.location.reload() , 1000 )
                          }}
                          isSubmitting={props.isSubmitting}
                          intl={intl}
                        />
                      );
                    }}
                  </Localization>
                  <hr />
                  <FormUserPassword
                    onSubmit={async data => {
                      await props.updatePassword(data);
                      //setTimeout(()=> window.location.reload() , 1000 )
                    }}
                    isSubmitting={props.isSubmitting}
                    intl={intl}
                  />
                  <hr />
                  <FormUserDetailsRemoveAccount
                    onSubmit={() => {}}
                    isSubmitting={props.isSubmitting}
                    intl={intl}
                  />
                </VerticalSpacer>
              </AccountContainer>
            )}
          </Resource>
        )}
      </Auth>
    </ProtectedScreen>
  );
}

export default injectIntl(MyAccountScreen);
