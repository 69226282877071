import styled from 'styled-components'

export const Question = styled.label`
  display: block;
  margin: 0;
  margin-bottom: 15px;
  font-family: ${props => props.theme.fonts.tertiary};
  font-weight: bold;
  font-size: 20px;
  line-height: 1.3;
  color: #1a2325;
`

export const LargeOptionsContainer = styled.div`
  display: flex;
  width: 100%;
`

export const Description = styled.p`
  font-family: ${props => props.theme.fonts.tertiary};
  font-size: 14px;
  font-style: italic;
  color: #6b6b6b;
  line-height: 1.7;
  margin: 0;
  margin-top: 25px;
`
