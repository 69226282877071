import React from 'react'
import { Field } from 'react-final-form'
import * as forms from 'utils/forms'
import CustomField from 'components/CustomField'
import FormGrid from 'components/FormGrid'
import WithModal from 'admin/components/WithModal'
import Link from 'components/Link'
import Terms from 'questionnaire/components/Terms'
import CustomQuestionField from 'questionnaire/components/CustomQuestionField'
import CustomFieldSelect from 'admin/components/CustomFieldSelect'
import Resource from 'containers/Resource'
import FormAddress from 'admin/forms/FormAddress'
import { GENDERS } from 'config/constants'
import { PATIENTS_ETNIAS } from '../../../config/constants'

export const formPersonal = {
  form: ({ intl }) => (
    <React.Fragment>
      <Field
        name='aceita_contato'
        component={CustomField.Option}
        type='checkbox'
      >
        {intl.formatMessage({ id: 'q.acceptContact' })}
      </Field>
      <WithModal modal={Terms}>
        {({ toggleModal }) => (
          <Field
            name='termos_uso'
            component={CustomField.Option}
            type='checkbox'
            validate={v => !v && intl.formatMessage({ id: 'q.acceptRequired' })}
          >
            {intl.formatMessage({ id: 'q.termsAccept' })}
            {' '}
            <Link
              component='button'
              onClick={e => {
                e.preventDefault()
                toggleModal()
              }}
            >
              {intl.formatMessage({ id: 'q.termsOfService' })}
            </Link>
          </Field>
        )}
      </WithModal>
      <Field
        name='name'
        component={CustomField}
        label={intl.formatMessage({ id: 'admin.common.name' })}
        autoFocus
        {...forms.required}
      />
      <Field
        name='cpf'
        component={CustomField}
        label={intl.formatMessage({ id: 'admin.common.cpf' })}
        tooltip={intl.formatMessage({ id: 'hints.cpf' })}
        {...forms.cpfRequired}
      />
      <Field
        name='email'
        component={CustomField}
        label={intl.formatMessage({ id: 'admin.common.email' })}
        tooltip={intl.formatMessage({ id: 'hints.email' })}
        {...forms.emailRequired}
      />
    </React.Fragment>
  ),
  fields: ['name', 'cpf', 'email', 'aceita_contato', 'termos_uso']
}

export const formAddress = {
  form: props => <FormAddress autoFocus {...props} />,
  fields: [
    'cep',
    'pais',
    'estado',
    'cidade',
    'rua',
    'numero',
    'complemento'
  ]
}

export const formPhysical = {
  form: ({ intl }) => (
    <React.Fragment>
      <FormGrid>
        <Field
          name='data_nascimento'
          component={CustomField}
          label={intl.formatMessage({ id: 'admin.common.dateOfBirth' })}
          tooltip={intl.formatMessage({ id: 'hints.dateOfBirth' })}
          {...forms.dateRequired}
        />
        <Field
          name='sexo'
          component={CustomField.Select}
          label={intl.formatMessage({ id: 'admin.common.gender' })}
          {...forms.required}
        >
          <option value='' disabled>
            {intl.formatMessage({ id: 'admin.common.select' })}
          </option>
          {['male', 'female'].map(gender => (
            <option key={gender} value={GENDERS[gender].id}>
              {intl.formatMessage({ id: GENDERS[gender].name })}
            </option>
          ))}
        </Field>
      </FormGrid>
    </React.Fragment>
  ),
  fields: [
    'data_nascimento',
    'sexo'
  ]
}

export const formEthnicity = {
  form: ({ values, intl }) => (
    <React.Fragment>
      <Field
        name='etnia'
        component={CustomQuestionField}
        question={intl.formatMessage({ id: 'q.ethnicity.question' })}
        description={values.sexo !== 'F'
          ? intl.formatMessage({ id: 'q.ethnicity.description' })
          : ''
        }
        options={[
          {
            label: intl.formatMessage({ id: `admin.common.ethnicity.${PATIENTS_ETNIAS.white.label}` }),
            value: `${PATIENTS_ETNIAS.white.value}`
          },
          {
            label: intl.formatMessage({ id: `admin.common.ethnicity.${PATIENTS_ETNIAS.black.label}` }),
            value: `${PATIENTS_ETNIAS.black.value}`
          },
          {
            label: intl.formatMessage({ id: `admin.common.ethnicity.${PATIENTS_ETNIAS.brown.label}` }),
            value: `${PATIENTS_ETNIAS.brown.value}`
          },
          {
            label: intl.formatMessage({ id: `admin.common.ethnicity.${PATIENTS_ETNIAS.yellow.label}` }),
            value: `${PATIENTS_ETNIAS.yellow.value}`
          },
          {
            label: intl.formatMessage({ id: `admin.common.ethnicity.${PATIENTS_ETNIAS.indigenous.label}` }),
            value: `${PATIENTS_ETNIAS.indigenous.value}`
          }
        ]}
        autoFocus
        {...forms.required}
      />
    </React.Fragment>
  ),
  fields: ['etnia']
}

export const formPhone = ({ intl }) => (
  <Field
    key='form_telefone'
    name='celular'
    label={intl.formatMessage({ id: 'admin.common.cellphone' })}
    id='form_patient_form_fields_phone'
    component={CustomField}
    {...forms.phoneRequired}
  />
)

export const formCode = ({ intl }) => (
  <Field
    key='form_matricula'
    name='matricula'
    label={intl.formatMessage({ id: 'admin.common.idNumber' })}
    id='form_patient_form_fields_code'
    component={CustomField}
    {...forms.required}
  />
)

export const formHealthInsurance = ({ companyId, intl }) => (
  <Resource
    key='form_plano_saude'
    resource='HealthInsurances'
    params={{ empresa: companyId }}
    autoFetch
  >
    {props => (
      <Field
        name='plano_saude.id'
        label={intl.formatMessage({ id: 'admin.healthInsurance.title' })}
        id='form_patient_plano_de_saude'
        component={CustomFieldSelect}
        placeholder={intl.formatMessage({ id: 'admin.comon.select' })}
        customSelectProps={{
          options: props.records,
          labelKey: 'nome',
          valueKey: 'id',
          isLoading: props.isFetching
        }}
        // {...forms.required}
      />
    )}
  </Resource>
)
