import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import VerticalSpacer from 'components/VerticalSpacer'
import Label from 'components/Label'
import LabelError from 'components/LabelError'
import Input from 'components/Input'
import Select from 'components/Select'
import Option from 'components/Option'
import { componentType } from 'types'

export default function CustomField ({ component: Component, meta, input, info, ...rest }) {
  const { label, id, showMandatory, ...props } = rest
  const hasSubmitError = meta.submitError && meta.submitError.length > 0

  return (
    <VerticalSpacer space={5}>
      {label && (
        <Label
          htmlFor={id}
          color={hasSubmitError ? 'danger' : 'textNew'}
        >
          {label}
          {showMandatory && <span style={{ color: 'red' }}> *</span>}
        </Label>
      )}
      <Component
        id={id}
        {...input}
        {...props}
        error={hasSubmitError}
        meta={meta}
      />
      {(meta.touched && meta.error) && (
        <LabelError>
          {typeof meta.error === 'string'
            ? meta.error
            : <FormattedMessage id={meta.error.id} values={meta.error} />
          }
        </LabelError>
      )}
      {meta.submitError && meta.submitError.map((err, index) => (
        <LabelError key={index}>
          {err}
        </LabelError>
      ))}
      {info && (
        <Label htmlFor={id} color='textGray' size='14px'>
          <em>{info}</em>
        </Label>
      )}
    </VerticalSpacer>
  )
}

CustomField.propTypes = {
  /** Componente que será colocado no lugar do Input */
  component: componentType,

  /** Objeto meta do Final Form */
  meta: PropTypes.object.isRequired,

  /** Objeto input do Final Form */
  input: PropTypes.object.isRequired,

  /** Label opcional */
  label: PropTypes.string,

  /** ID que será inserido no `htmlFor` do label e no próprio Input (ou outro componente que for passado) como id */
  id: PropTypes.string
}

CustomField.defaultProps = {
  component: Input
}

/**
 * Custom Field for `Textarea`.
 */

CustomField.Textarea = props =>
  <CustomField component={Input.Textarea} {...props} />

/**
 * Custom Field for `Select`.
 */

CustomField.Select = props =>
  <CustomField component={Select} {...props} />

/**
 * CustomField for `Option`.
 */

CustomField.Option = props =>
  <CustomField component={Option} {...props} />
