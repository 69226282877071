import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import media from "utils/mediaQuery";

const CardStyled = styled.div`
  background: ${props => props.background};
  color: ${props => props.color};
  flex: ${props => props.container};
  box-shadow: ${props => props.box};
  border: ${props => props.border};
  margin-bottom: 23px;
  padding-top: 0;
;
  ${props => media.greaterThan(props.breakpoint)`
    display: ${props => props.direction};
    margin-right: ${props => props.spacing}px;
    border-radius: 10px;

    > * {
      width: 100%;
      margin: ${props => props.spacing}px;
    }
  `}
  
  @media (max-width: 912px) {
    margin-bottom: ${props => props.spacing}px;
    margin-right: 0;
    border-radius: 10px;
  }
`;

export default function Card(props) {
  return <CardStyled {...props} />;
}

Card.propTypes = {
  /** Breakpoint para desfazer o Card */
  breakpoint: PropTypes.string.isRequired,

  /** Espaçamento do Card */
  spacing: PropTypes.number,

  /** Background do Card */
  background: PropTypes.string,

  /** color do Card */
  color: PropTypes.string,

  /** Direction do Card */
  direction: PropTypes.string,

  /** flex do Card */
  container: PropTypes.number,

  /** border do Card */
  border: PropTypes.string,

  /** box-shadow do Card */
  box: PropTypes.string
};

Card.defaultProps = {
  breakpoint: "medium",
  spacing: 20,
  background: "#FBFCFC",
  color: "#34495E",
  direction: "flex",
  container: 2,
  box: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  border: "none"
};
