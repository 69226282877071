import Icon from '@fortawesome/react-fontawesome';
import CustomFieldSelect from 'admin/components/CustomFieldSelect';
import SubmissionContainer from 'admin/components/SubmissionContainer';
import ButtonSubmit from 'components/ButtonSubmit';
import CustomField from 'components/CustomField';
import FormGrid from 'components/FormGrid';
import Image from 'components/Image';
import Link from 'components/Link';
import VerticalSpacer from 'components/VerticalSpacer';
import Notifications from 'containers/Notifications';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { injectIntl } from 'react-intl';
import * as forms from 'utils/forms';
import { countries } from '../../../mocks/countries';
import { SelectStyles, Container } from './styles';

function FormPersonalDataRegister({
  intl,
  company,
  setPage,
  formData,
  setFormData,
  isFetching,
  loginWithApi,
  setLoginWithApi,
  ...props
}) {
  const lang = {
    pt: 'BR',
    'pt-BR': 'BR',
    en: 'US',
    fr: 'FR',
    es: 'ES',
  };
  
  //função que válida antes de enviar os dados do formulário
  const formSubmit = (e, props) => {
    e.preventDefault();

    // Remover atributos
    let uf = props.values.ddi;
    props.values.uf = uf ? countries['en'][handleCodeByOption(uf)] : '';
    props.values.nationality = handleCodeByOption(props.values.nationality);

    //state e setState e mudança das pages
    setFormData({
      ...formData,
      ...props.values,
    });
    setPage(currPage => currPage + 1);
  };

  const handleOptionByCode = code => {
    let obj = countries[intl.locale][code];
    return obj.option;
  };

  const handleCodeByOption = option => {
    if (!option) return;
    let code = option
      .replace(option.replace(/(\[\w{2}\])/g, ''), '')
      .replace(/\[|\]/g, '')
      .trim();
    code = code ? countries['en'][code].code : '';
    return code;
  };

  //função que volta das pages
  const handleBackPage = e => {
    e.preventDefault();

    if (loginWithApi) {
      setLoginWithApi(false);
      sessionStorage.clear();
      props.clearFormData();
    }
    setPage(currPage => currPage - 1);
  };

  return (
    <Form
      {...props}
      initialValues={{
        ...formData,
        nationality: handleOptionByCode(lang[intl.locale]),
        ddi: handleOptionByCode(lang[intl.locale]),
        step: 'form_data_validate',
        index: 1,
      }}
    >
      {({ handleSubmit, hasValidationErrors, ...formProps }) => (
        <form onSubmit={e => formSubmit(e, formProps)}>
          <VerticalSpacer>
            <Notifications />
            <FormGrid>
              <Field
                name="nome"
                label={intl.formatMessage({ id: 'admin.common.full.name' })}
                id="form_patient_name"
                component={CustomField}
                placeholder="José da Silva"
                maxLength={255}
                showMandatory
                {...forms.fullNameRequired}
              />
            </FormGrid>

            <Container>
              <FormGrid>
                <div className="section_ddi_telefone">
                  <SelectStyles>
                    <Field
                      name="ddi"
                      label={'DDI'}
                      id="form_patient_phone_ddi"
                      component={CustomFieldSelect}
                      customSelectProps={{
                        options: Object.values(countries[intl.locale]).map(
                          i => ({
                            value: i.option,
                            label: (
                              <div
                                style={{ display: 'flex', overflow: 'hidden' }}
                              >
                                <Image
                                  src={require(`images/flags/${i.code}.svg`)}
                                  alt={i.label}
                                />
                                <span style={{ paddingLeft: 8 }}>
                                  {i.phone}
                                </span>
                              </div>
                            ),
                          }),
                        ),
                      }}
                      showMandatory
                      {...forms.required}
                    />
                  </SelectStyles>
                  <Field
                    name="telefone"
                    label={intl.formatMessage({ id: 'admin.common.phone' })}
                    id="form_patient_phone"
                    className="input_telefone"
                    component={CustomField}
                    placeholder="(00) 0000-0000"
                    showMandatory
                    {...forms.phoneRequired}
                    format={value => {
                      let uf = Object.values(countries[intl.locale]).filter(item => item.option === formProps.values.ddi)[0];
                      return forms.phone.format(value, uf)
                    }}
                  />
                </div>
              </FormGrid>
            </Container>

            {/* <FormGrid>
              <SelectStyles style={{ width: 98, marginRight: -5 }}>
                <Field
                  name="nationality"
                  label={'Pais'}
                  id="form_patient_pais"
                  component={CustomFieldSelect}
                  customSelectProps={{
                    options: Object.values(countries[intl.locale]).map(i => ({
                      value: i.option,
                      label: (
                        <div style={{ display: 'flex', overflow: 'hidden' }}>
                          <Image
                            src={require(`images/flags/${i.code}.svg`)}
                            alt={i.label}
                          />
                          <span style={{ paddingLeft: 8 }}>{i.country}</span>
                        </div>
                      ),
                    })),
                  }}
                  showMandatory
                  {...forms.required}
                />
              </SelectStyles>
            </FormGrid> */}

            <SubmissionContainer>
              <Link to={''} onClick={e => handleBackPage(e)}>
                <Icon
                  className="icon_arrow"
                  style={{ marginRight: '10px', width: 15 }}
                  icon={require('@fortawesome/fontawesome-free-solid/faArrowLeft')}
                />
                {intl.formatMessage({ id: 'pages.register.code.return' })}
              </Link>

              <ButtonSubmit
                color="primary"
                textColor="white"
                isSubmitting={isFetching}
                disabled={isFetching || hasValidationErrors}
                label={intl.formatMessage({
                  id: 'pages.register.code.continue',
                })}
              />
            </SubmissionContainer>
          </VerticalSpacer>
        </form>
      )}
    </Form>
  );
}

export default injectIntl(FormPersonalDataRegister);
