import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { omit } from 'ramda'
import Base from 'components/Base'
import { componentType } from 'types'
import mediaQuery from 'utils/mediaQuery'

const filterProps = omit([
  'size',
  'sizeMobile',
  'color',
  'lineHeight',
  'align',
  'alignMobile',
  'weight',
  'fontFamily'
])

const TextStyled = styled(props => <Base {...filterProps(props)} />)`
  display: block;
  font-family: ${props => props.fontFamily ? props.fontFamily : props.theme.fonts.tertiary};
  font-size: ${props => props.size};
  font-weight: ${props => props.weight};
  color: ${props => props.color in props.theme.colors ? props.theme.colors[props.color] : props.color};
  margin: 0;
  line-height: ${props => props.lineHeight};
  text-align: ${props => props.align};

  ${props => props.letterSpacing ? css`
    letter-spacing: ${props.letterSpacing}
  ` : ''}

  ${mediaQuery.lessThan('large')`
    font-size: ${props => props.sizeMobile};
  `}
  
  ${mediaQuery.lessThan('large')`
    text-align: ${props => props.alignMobile};
  `}
`

export default function Text (props) {
  return (
    <TextStyled {...props} />
  )
}

Text.propTypes = {
  /** Componente */
  component: componentType,

  /** Tamanho da fonte */
  size: PropTypes.string,

  /** Tamanho da fonte no mobile */
  sizeMobile: PropTypes.string,

  /** Cor */
  color: PropTypes.string,

  /** Line Height */
  lineHeight: PropTypes.number,

  /** Font Weight */
  weight: PropTypes.string
}

Text.defaultProps = {
  component: 'p',
  color: 'text',
  size: '16px',
  sizeMobile: '16px',
  lineHeight: 1.7,
  align: 'left',
  weight: 'normal'
}
