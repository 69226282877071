import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import { FormattedHTMLMessage } from 'react-intl'

export default function ButtonSubmit ({ isSubmitting, label, submittingLabel, disabled, ...props }) {
  return (
    <Button
      type='submit'
      color='secondaryGreen'
      textColor='text'
      blockAtBreakpoint='small'
      disabled={isSubmitting || disabled}
      {...props}
    >
      {isSubmitting
        ? submittingLabel
        : label
      }
    </Button>
  )
}

ButtonSubmit.propTypes = {
  /** Form sendo processado */
  isSubmitting: PropTypes.bool,

  /** Label do botão */
  label: PropTypes.any,

  /** Label do botão quando o form estiver sendo processado */
  submittingLabel: PropTypes.any,

  /** Desabilita o botão */
  disabled: PropTypes.bool
}

ButtonSubmit.defaultProps = {
  label: <FormattedHTMLMessage id='admin.common.save' />,
  submittingLabel: <FormattedHTMLMessage id='admin.common.loading' />
}
