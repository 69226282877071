import React from 'react'
import Icon from 'components/Icon'
import Link from 'components/Link'
import { FormattedMessage } from 'react-intl'

export default function BackLink (props) {
  return (
    <Link
      color='black'
      hoverColor='primary'
      {...props}
    >
      <Icon
        icon='arrow-left'
        style={{
          position: 'relative',
          top: 1,
          fontSize: 19
        }}
      />
      <span style={{ marginLeft: 10 }}>
        <FormattedMessage id='admin.common.back' />
      </span>
    </Link>
  )
}
