import styled from 'styled-components';

export const Container = styled.div`
  a,
  b {
    &.button.--froala {
      background: #0b4e71 !important;
      color: #fff !important;
      -webkit-text-decoration: none;
      text-decoration: none;
      padding: 3px 10px;
      cursor: pointer;
      border: groove;
      border-radius: 10px;
      margin: 5px;
      white-space: nowrap;

      &:hover {
        filter: brightness(0.8);
      }

      &.btn-primary {
        background: #0b4e71 !important;
        color: #fff !important;
      }
      &.btn-secondary {
        background: #5a6057 !important;
        color: #fff !important;
      }
      &.btn-success {
        background: #00984b !important;
        color: #fff !important;
      }
      &.btn-warning {
        background: #f49d00 !important;
        color: #fff !important;
      }
      &.btn-danger {
        background: #a40026 !important;
        color: #fff !important;
      }
      &.btn-info {
        background: #0e91d6 !important;
        color: #fff !important;
      }
      &.btn-white {
        background: #fff !important;
        color: #0b4e71 !important;
      }
      &.btn-black {
        background: #000 !important;
        color: #fff !important;
      }
    }
  }
`;
