import React from 'react';
import bodyMale from 'images/body-male.png'
import bodyFemale from 'images/body-female.png'
import { GENDER } from 'config/constants'
import {
  ButtonBox,
  ButtonBoxCenter,
  CenterButton,
  Container,
  ContentContainer,
  InitialImage,
  NextButton,
  TextField,
  TitleWithImage,
} from './styles';
import { injectIntl } from 'react-intl';

const Tooltip = ({
  intl,
  continuous,
  step,
  closeProps,
  primaryProps,
  tooltipProps,
  isLastStep,
  skipProps,
  size,
  index,
}) => (
  <div {...tooltipProps}>
    <Container>
      {index !== 0 ? (
        step.title && <h2>{step.title}</h2>
      ): (
      <TitleWithImage>
          {step.title && <h2 style={{marginRight: '10px'}}>{step.title}</h2>}
        <InitialImage src={step.gender === GENDER.male.id ? bodyMale : bodyFemale}/>
      </TitleWithImage>)}

      <ContentContainer>{step.content}</ContentContainer>
      <div style={{padding: "0 20px"}}>
        {!isLastStep && continuous && index == 0 && (
          <ButtonBoxCenter style={{flexDirection: "column"}}>
            <CenterButton {...primaryProps} style={{marginBottom: "10px"}}>
              <TextField>{intl.formatMessage({id: "tour.start"})}</TextField>
            </CenterButton>
            <small {...skipProps} style={{cursor: "pointer"}}>{intl.formatMessage({id: "tour.skip"})}</small>
          </ButtonBoxCenter>
        )}
        {!isLastStep && continuous && index > 0 && (
          <ButtonBox>
            <small>{index} / {size - 2} </small>
            <NextButton {...primaryProps}>
              <TextField>{intl.formatMessage({id: "tour.next.step"})}</TextField>
            </NextButton>
          </ButtonBox>
        )}
        {isLastStep && (
          <ButtonBoxCenter>
            <CenterButton {...closeProps}>
              <TextField>{intl.formatMessage({id: "tour.finished"})}</TextField>
            </CenterButton>
          </ButtonBoxCenter>
        )}
      </div>
    </Container>
  </div>
);

export default injectIntl(Tooltip);
