import React from 'react'
import { injectIntl } from 'react-intl'
import QuestionnaireScreen from 'users/questionnaire/components/QuestionnaireScreen'
import PatientPersonalData from 'users/questionnaire/containers/PatientPersonalData'

function PersonalDataScreen ({ intl }) {
  return (
    <QuestionnaireScreen
      title={intl.formatMessage({ id: 'admin.common.personalInformations' })}
      personal
    >
      <PatientPersonalData />
    </QuestionnaireScreen>
  )
}

export default injectIntl(PersonalDataScreen)
