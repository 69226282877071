import React from 'react'
import CustomField from 'components/CustomField'
import CustomSelect from 'admin/components/CustomSelect'

function CustomFieldCustomSelect ({ customSelectProps, onChangeOption, value, onChange, error, style, placeholder, ...props }) {
  return (
    <CustomSelect
      simpleValue
      value={value}
      style={style}
      placeholder={placeholder}
      onChange={value => {
        onChange(value)
        if (onChangeOption) {
          onChangeOption(value)
        }
      }}
      CustomFieldSelect={true}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      disabled={props.disabled}
      error={error}
      {...customSelectProps}
    />
  )
}

export default props =>
  <CustomField {...props} component={CustomFieldCustomSelect} />
