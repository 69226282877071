import React from 'react';
import { TextHTML } from "./styles";

export const HtmlRender = ({ content }) => {
    // content = content.replace('Powered by ', '').replaceAll('Froala Editor', '' )
    return (
        <TextHTML
            dangerouslySetInnerHTML={{ __html: content}}>
        </TextHTML>
    );
}