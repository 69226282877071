import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Form } from 'react-final-form'
import VerticalSpacer from 'components/VerticalSpacer'
import Button from 'components/Button'
import BackLink from 'components/BackLink'
import Text from 'components/Text'
import { FieldsContainer, Bottom } from './styles'
import resolve from 'utils/resolve'

function Wizard ({ form: currentForm, count, progress, canGoBack, onBackClick, isSubmitting, disableBottom = false, intl, ...props }) {
  return (
    <div>
      <Form {...props}>
        {({ handleSubmit, values, form, ...formProps }) => (
          <form onSubmit={handleSubmit}>
            <VerticalSpacer>
              <FieldsContainer>
                <VerticalSpacer>
                  {resolve(currentForm, { values, form, intl, ...formProps })}
                </VerticalSpacer>
              </FieldsContainer>
              <Text>
                {intl.formatMessage({ id: 'q.wizardStep' }, { progress, count })}
              </Text>
              {!disableBottom ? (
                <Bottom>
                  <Button type='submit' arrow blockAtBreakpoint='small' disabled={isSubmitting} color={props.color}>
                    {isSubmitting
                      ? intl.formatMessage({ id: 'admin.common.processing' })
                      : intl.formatMessage({ id: 'admin.common.continue' })
                    }
                  </Button>
                  {canGoBack ? (
                    <BackLink
                      component='button'
                      type='button'
                      onClick={onBackClick}
                      disabled={isSubmitting}
                    />
                  ) : <div />}
                </Bottom>
              ) : null}
            </VerticalSpacer>
          </form>
        )}
      </Form>
    </div>
  )
}

Wizard.propTypes = {
  /** Handler para cada submissão do form */
  onSubmit: PropTypes.func.isRequired,

  /** Formulário a ser exibido */
  form: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.array,
    PropTypes.element
  ]).isRequired,

  /** Fração para o progress bar */
  progress: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),

  /** O usuário pode retroceder o formulário atual? */
  canGoBack: PropTypes.bool,

  /** Callback para quando o usuário clicar no botão de Voltar */
  onBackClick: PropTypes.func,

  /** Form está sendo processado */
  isSubmitting: PropTypes.bool
}

export default injectIntl(Wizard)
