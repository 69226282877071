import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import Auth from 'admin/containers/Auth'
import Screen from 'components/Screen'
import PageContent from 'components/PageContent'
import Container from 'components/Container'
import VerticalSpacer from 'components/VerticalSpacer'
import Text from 'components/Text'
import Link from 'components/Link'
import Notifications from 'containers/Notifications'
import { getData, getAuth } from 'store/ducks/questionnaire'
import getRoute from 'utils/getRoute'
import routes from 'routes'

function QuestionnaireScreen ({ personal, children, icon, guest, data, auth, ...props }) {
  return (
    <Auth>
      {authProps => {
        const isAuthenticated = authProps.isAuthenticated && authProps.type === 'patients'

        if (!guest && data.roteiro.url_restrita && !isAuthenticated) {
          const to = getRoute(routes.questionnaire.login, auth)
          return <Redirect to={to} />
        }

        return (
          <Screen {...props}>
          
            {isAuthenticated && (
              <Container size='xsmall' style={{ marginTop: 15 }}>
                <Text align='right' size='14px'>
                  <FormattedMessage id='q.welcomeUser' values={{ name: authProps.user.name }} />
                  {' - '}
                  <Link to={getRoute(routes.questionnaire.logout, auth)}>
                    <FormattedMessage id='admin.common.signOut' />
                  </Link>
                </Text>
              </Container>
            )}
            <PageContent>
              <Container size='xsmall'>
                <VerticalSpacer>
                  <Notifications />
                  <div>
                    {children}
                  </div>
                </VerticalSpacer>
              </Container>
            </PageContent>
          </Screen>
        )
      }}
    </Auth>
  )
}

const mapStateToProps = state => ({
  data: getData(state),
  auth: getAuth(state)
})

export default connect(
  mapStateToProps
)(QuestionnaireScreen)
