import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import Card from '../Card/Card';
import { Context, Container } from './styles';

const CardsUsers = ({ intl, data }) => {
  return (
    <Fragment>
      <Card container={1} background="#6e99a9" color="#fff">
        <Container>
          <Context>
            <h3 className="label-box">
              {intl.formatMessage({
                id: 'registered',
              })}
            </h3>
            {/* <span>{data.card_company.company}</span> */}
          </Context>
          <Context>
            <h3 className="label-two">
              {intl.formatMessage({
                id: 'admin.common.total',
              })}
            </h3>
            <span className="span-box">
              {data.card_company ? data.card_company.value : 0}
            </span>
          </Context>
        </Container>
      </Card>

      <Card container={1} background="#8854c4" color="#fff">
        <Context>
          <div className="box-row">
            <h3 className="label-two-box">
              {intl.formatMessage({
                id: 'medical history',
              })}
            </h3>
          </div>
          <div className="box-footer">
            <span className="span-box">
              {data.medical_history ? data.medical_history.value : 0}
            </span>
            <span className="span">
              {data.medical_history ? data.medical_history.percent : 0}%
            </span>
          </div>
        </Context>
      </Card>

      <Card container={1} background="#68ad76" color="#fff">
        <Context>
          <div className="box-row">
            <h3 className="label-two-box">
              {intl.formatMessage({
                id: 'interviews',
              })}
            </h3>
          </div>
          <div className="box-footer">
            <span className="span-box">
              {data.interviews ? data.interviews.value : 0}
            </span>
            <span className="span">
              {data.interviews ? data.interviews.percent : 0}%
            </span>
          </div>
        </Context>
      </Card>
    </Fragment>
  );
};

export default injectIntl(CardsUsers);
