import Request from 'modules/Request'

const request = (isAuthenticated, config) =>
  Request[isAuthenticated ? 'auth' : 'api'](config)

export default {
  fetchData ({ script, patient, session }, isAuthenticated) {
    return request(isAuthenticated, {
      method: 'GET',
      url: `/api/roteiros/${script}/pacientes/${patient}/${session}`
    }).then(res => res.body)
  },

  updatePatient ({ script, session, patientId, data }, isAuthenticated) {
    return request(isAuthenticated, {
      method: 'PUT',
      url: `/api/roteiros/${script}/pacientes-anamneses/${patientId}/${session}`,
      body: data
    }).then(res => res.body)
  },

  answerQuestion ({ patient, script, session, questions }, isAuthenticated) {
    return request(isAuthenticated, {
      method: 'POST',
      url: `/api/roteiros/${script}/pacientes/${patient}/${session}`,
      body: { questions }
    }).then(res => res.body)
  },

  fetchResult ({ patient, script, session }, isAuthenticated) {
    return request(isAuthenticated, {
      method: 'POST',
      url: `/api/roteiros/${script}/pacientes/${patient}/resultado/${session}`
    }).then(res => res.body)
  }
}
