// import Request from 'modules/Request'

// export default {
//   create (data, params) {
//     return Request.auth({
//       method: 'POST',
//       url: '/api/pacientes/exportCompany',
//       body: data,
//       query: params
//     }).then(res => res.body)
//   },

//   fetchAll (params) {
//     return Request.auth({
//       method: 'GET',
//       url: '/api/pacientes/exportCompany',
//       query: params
//     }).then(res => res.body)
//   },

//   remove (id) {
//     return Request.auth({
//       method: 'DELETE',
//       url: `/api/pacientes/exportCompany/${id}`
//     }).then(res => res.body)
//   }
// }
import createApi from 'store/utils/createApi'

export default createApi('/api/planos-de-saude')
