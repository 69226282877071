import en from './en'
import es from './es'
import pt from './pt'
import fr from './fr'

export default {
  'en': en,
  'es': es,
  'pt': pt,
  'fr': fr,
}
