import ProtectedScreen from 'admin/components/ProtectedScreen'
import Auth from 'admin/containers/Auth'
import ResourceDetails from 'admin/containers/ResourceDetails'
import ResourceList from 'admin/containers/ResourceList'
import ResourceNew from 'admin/containers/ResourceNew'
import ResourceUpdate from 'admin/containers/ResourceUpdate'
import Text from 'components/Text'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { Route, Switch } from 'react-router-dom'

function ResourceScreen ({ intl, resource, readableResourceName, routeBase, extraActionButton, ...props }) {

  const restrict = [
    '/app/users/admin/companies',
    '/app/users/admin/patients',
  ]

  const handleRestrict = user =>{
    let resp = false;
    if(user && user.empresa.id !==1)
      restrict.forEach( route =>{
        if(window.location.pathname.indexOf(route) > -1)
          return resp = true;
      });
    
    return resp;
  }

  return (
    <Auth>
      {({ permissions, type , user}) => {

        if ( handleRestrict(user) || ( type !=='usersAdmWeb' && !permissions.includes(props.permissions.viewAll) ) ) {
          return <Text>{intl.formatMessage({ id: 'admin.common.permission.page' })}</Text>
        }

        return (
          <Switch>
            <Route
              exact
              path={routeBase.index}
              render={() => (
                <ProtectedScreen title={`${intl.formatMessage({ id: 'admin.common.listOf' })} ${readableResourceName[1]}`}>
                  <ResourceList
                    resource={resource}
                    title={`${intl.formatMessage({ id: 'admin.common.listOf' })} ${readableResourceName[1]}`}
                    routeBase={routeBase}
                    columns={props.columns}
                    dataTableProps={props.dataTableProps}
                    canAdd={(type === 'usersAdmWeb' && props.canAdd) || (props.canAdd && permissions.includes(props.permissions.create))}
                    canEdit={(type === 'usersAdmWeb' && props.canEdit ) || (props.canEdit && permissions.includes(props.permissions.update))}
                    canRemove={(type === 'usersAdmWeb' && props.canRemove) ||  (props.canRemove && permissions.includes(props.permissions.delete))}
                    canShowDetails={(type === 'usersAdmWeb' && props.canShowDetails) || (props.canShowDetails && permissions.includes(props.permissions.view))}
                    extraActionButton={extraActionButton}
                    actionColumnWidth={props.actionColumnWidth}
                    {...props.resourceListProps}
                  />
                </ProtectedScreen>
              )}
            />
            {props.canAdd && (
              <Route
                path={routeBase.new}
                render={() => (
                  <ProtectedScreen title={`${intl.formatMessage({ id: 'admin.common.register' })} ${readableResourceName[0]}`}>
                    <ResourceNew
                      resource={resource}
                      title={`${intl.formatMessage({ id: 'admin.common.register' })} ${readableResourceName[0]}`}
                      routeBase={routeBase}
                      Form={props.Form}
                      NotEditable={{indication: false}}
                      {...props.resourceNewProps}
                    />
                  </ProtectedScreen>
                )}
              />
            )}
            {props.canEdit && (
              <Route
                exact
                path={routeBase.edit}
                render={({ match: { params: { id } } }) => (
                  <ProtectedScreen title={`${intl.formatMessage({ id: 'admin.common.edit' })} ${readableResourceName[0]}`}>
                    <ResourceUpdate
                      resource={resource}
                      title={`${intl.formatMessage({ id: 'admin.common.edit' })} ${readableResourceName[0]}`}
                      routeBase={routeBase}
                      Form={props.Form}
                      NotEditable={{indication: true}}
                      id={parseInt(id, 10)}
                      {...props.resourceUpdateProps}
                    />
                  </ProtectedScreen>
                )}
              />
            )}
            {props.canShowDetails && (
              <Route
                exact
                path={routeBase.show}
                render={({ match: { params: { id } } }) => (
                  <ProtectedScreen title={`${intl.formatMessage({ id: 'admin.common.details' })} - ${readableResourceName[0]}`}>
                    <ResourceDetails
                      resource={resource}
                      title={`${intl.formatMessage({ id: 'admin.common.details' })} - ${readableResourceName[0]}`}
                      routeBase={routeBase}
                      Details={props.Details || (p => <props.Form onSubmit={v => {}} isSubmitting={false} initialValues={p.detailedRecord} title={p.title} readOnly {...props} />)}
                      id={parseInt(id, 10)}
                      NotEditable={{indication: true}}
                      {...props.resourceDetailsProps}
                    />
                  </ProtectedScreen>
                )}
              />
            )}
          </Switch>
        )
      }}
    </Auth>
  )
}

ResourceScreen.propTypes = {
  /** Recurso */
  resource: PropTypes.string.isRequired,

  /** Nome legível do recurso */
  readableResourceName: PropTypes.array.isRequired,

  /** Rota base */
  routeBase: PropTypes.object.isRequired,

  /** Formulário que será usado para cadastro e edição do recurso */
  Form: PropTypes.func,

  /** Componente que será utilizado para exibir o registro detalhado */
  Details: PropTypes.func,

  /** Lista de colunas para a listagem de recursos */
  columns: PropTypes.array,

  /** Props para o DataTable */
  dataTableProps: PropTypes.object,

  /** Props para Resource List */
  resourceListProps: PropTypes.object,

  /** Props para Resource Create */
  resourceNewProps: PropTypes.object,

  /** Props para Resource Update */
  resourceUpdateProps: PropTypes.object,

  /** Props para o Resource Details */
  resourceDetailsProps: PropTypes.object,

  /** Permite o cadastro de novos registros */
  canAdd: PropTypes.bool,

  /** Permite a edição de registros */
  canEdit: PropTypes.bool,

  /** Permite a exclusão de registros */
  canRemove: PropTypes.bool,

  /** Permite a visualização do registro detalhado */
  canShowDetails: PropTypes.bool,

  /** Objeto de permissões do recurso */
  permissions: PropTypes.object.isRequired,

  /** Adicionar ações adicionais */
  extraActionButton: PropTypes.func
}

ResourceScreen.defaultProps = {
  canAdd: true,
  canEdit: true,
  canRemove: true,
  canShowDetails: true
}

export default injectIntl(ResourceScreen)
