import React from "react";
import ReactTable from "react-table";
import styled, { injectGlobal } from "styled-components";
import "./DataTable.css";
import Input from "components/Input";
import { injectIntl } from "react-intl";

injectGlobal`
  .ReactTable .rt-thead.-filters .rt-th {
    overflow: visible;
  }
  .ReactTable .rt-noData {
    z-index: 0 !important;
  }
`;
const Container = styled.div`
  background-color: #fff;
  font-family: ${props => props.theme.fonts.tertiary};
`;

const Filters = {
  data: [],
  undef: 0,
  columns: [],
  setColumns(columns) {
    if (!this.columns.length)
      columns.forEach(item => {
        if (item.filterable) this.columns.push(item.accessor);
      });
  }
};

const FilterData = ({ filter, dataDefault }) => {
  if (typeof filter === "undefined") Filters.undef++;
  if (filter && filter.value.length > 0) {
    Filters.undef = 0;
    let rest = [];

    let dataFiterable = Filters.data.length ? Filters.data : dataDefault;

    dataFiterable.forEach(item => {
      if (
        item[filter.id] &&
        item[filter.id]
          .toString()
          .toLowerCase()
          .indexOf(filter.value.toString().toLowerCase()) > -1
      )
        rest.push(item);
    });
    Filters.data = rest;
  }
};

function DataTable({ intl, minRows = 6, data, ...props }) {
  if (
    props.resourceProps &&
    props.resourceProps.records.update &&
    props.update
  ) {
    props.resourceProps.records.update = false;
    props.update();
  }

  if(data.length)
  props.loading= false;

  const dataDefault = data;
  if (props.columns) Filters.setColumns(props.columns);

  if (Filters.columns.length) {
    if (Filters.undef >= Filters.columns.length) Filters.data = data;
  } else {
    Filters.data = data;
  }

  function filterCaseInsensitive(filter, row) {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
      : true;
  }

  return (
    <Container>
      <ReactTable
        showPageJump={false}
        minRows={minRows}
        filterable
        previousText={intl.formatMessage({ id: "admin.common.previous" })}
        nextText={intl.formatMessage({ id: "admin.common.next" })}
        loadingText={intl.formatMessage({ id: "admin.common.loading" })}
        noDataText={intl.formatMessage({ id: "admin.common.noResultsFound" })}
        pageText={intl.formatMessage({ id: "admin.common.page" })}
        ofText={intl.formatMessage({ id: "admin.common.Of" })}
        rowsText={intl.formatMessage({ id: "admin.common.results" })}
        className={"-striped -highlight"}
        data={Filters.data}
        defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row) }
        FilterComponent={({ onChange, filter }) => {
          FilterData({ filter, dataDefault, props });

          return (
            <Input
              className="custom-filter-input"
              value={filter ? filter.value : ""}
              onChange={e => onChange(e.target.value)}
              placeholder={intl.formatMessage({ id: "admin.common.filter" })}
            />
          );
        }}
        {...props}
      />
    </Container>
  );
}

export default injectIntl(DataTable);
