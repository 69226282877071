import React from "react";
import { Container } from './styles'
  
export default ({className, children}) =>{
    return(
        <Container className={className}>
            {children} 
        </Container> 
    );
}
