import Image from 'components/Image'
import viewDetailsIcon from 'images/eyes.svg'
import React from 'react'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import mediaQuery from 'utils/mediaQuery'

const FewDetailsMoreCardInfoStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 165px;
  width: 120px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%2329a5a2' stroke-width='6' stroke-dasharray='6%2c 13' stroke-dashoffset='61' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 12px;
  background-color: #a5e3e0;

  ${mediaQuery.lessThan('medium')`
    margin: 0 auto;
  `}

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 61%
    height: 85%;
    text-align: center;

    > img {
      height: 38px;
      width: 38px;
      margin: 0 auto;
      padding-bottom: 10px;
    }
  }
`

const CardText = styled.p`
  font-family: ${props => props.theme.fonts.tertiary};
  font-size: 12px;
  font-weight: bold;
  color: ${props => props.theme.colors.textNew};
`
function FewDetailsMoreCardInfo({ intl, match, ...props }){
  return (
    <FewDetailsMoreCardInfoStyled>
        <div>
          <CardText>{intl.formatMessage({ id: 'users.results.openDetails' })}</CardText>
          <Image src={viewDetailsIcon} alt={intl.formatMessage({ id: 'users.results.openDetails' })} />
        </div>
    </FewDetailsMoreCardInfoStyled>
  )
}

export default withRouter(injectIntl(FewDetailsMoreCardInfo))
