import React from 'react'
import styled from 'styled-components'

const LabelErrorStyled = styled.label`
  display: block;
  font-family: ${props => props.theme.fonts.tertiary};
  font-size: 12px;
  color: ${props => props.theme.colors.danger};
`

export default function LabelError (props) {
  return <LabelErrorStyled {...props} />
}
