/**
 * Dependencies.
 */

import * as formatters from 'utils/formatters';
import * as parsers from 'utils/parsers';
import * as validators from 'utils/validators';
import { countries } from '../mocks/countries';

/**
 * createRequired helper.
 */

const createRequired = ({ parse, format, validate, ...props }) => ({
  parse,
  format,
  validate: value =>
    validate
      ? validators.required(value) || validate(value)
      : validators.required(value),
  ...props,
});

/**
 * Required.
 */

export const required = {
  validate: validators.required,
};

/**
 * Integer.
 */

export const integer = {
  type: 'tel',
  format: formatters.number,
  validate: validators.integer,
  parse: v => parseInt(v || 0, 10),
};

export const integerRequired = createRequired(integer);

/**
 * Weight.
 */

export const weight = {
  type: 'tel',
  min: 20,
  max: 300,
  validate: validators.integerBetween({ min: 20, max: 300 }),
  parse: v => parseInt(v || 0, 10),
};

export const weightRequired = createRequired(weight);

/**
 * Height
 */

export const height = {
  type: 'tel',
  min: 50,
  max: 300,
  validate: validators.integerBetween({ min: 50, max: 300 }),
  parse: v => parseInt(v || 0, 10),
};

export const heightRequired = createRequired(height);

/**
 * Password.
 */

export const password = {};

export const passwordRequired = createRequired(password);

/**
 * E-mail.
 */

export const email = {
  type: 'email',
  validate: validators.email,
};

export const emailRequired = createRequired(email);

/**
 * login.
 */

export const login = {
  type: 'text',
  format: formatters.login,
  validate: validators.login,
};

export const loginRequired = createRequired(login);

/**
 * Full Name.
 */

export const fullName = {
  type: 'text',
  validate: validators.fullName,
};

export const fullNameRequired = createRequired(fullName);

/**
 * URL.
 */

export const url = {
  type: 'url',
};

export const urlRequired = createRequired(url);

/**
 * Links
 */

export const link_YouTube = {
  type: 'url',
  validate: validators.link_youtube,
};

export const urlLink_Youtube = createRequired(link_YouTube);

export const link_Agendamento_Exame = {
  type: 'url',
  validate: validators.link_agendamento_exame,
};

export const urlLink_Agendamento_Exame = createRequired(link_Agendamento_Exame);

/**
 * Phone.
 */

export const phone = {
  type: 'tel',
  format: formatters.phone,
  parse: parsers.phone,
  validate: validators.phone,
};

export const phoneRequired = createRequired(phone);

/**
 * CPF.
 */

export const cpf = {
  type: 'tel',
  format: formatters.cpf,
  validate: validators.cpf,
  parse: parsers.cpf,
};

export const cpfRequired = createRequired(cpf);

/**
 * CNPJ
 */

export const cnpj = {
  type: 'tel',
  format: formatters.cnpj,
  parse: parsers.cnpj,
  validate: validators.cnpj,
};

export const cnpjRequired = createRequired(cnpj);

/**
 * CEP.
 */

export const cep = {
  type: 'tel',
  format: formatters.cep,
  parse: parsers.cep,
  validate: validators.cep,
};

export const cepRequired = createRequired(cep);

export const code = {
  type: 'tel',
  format: formatters.code,
  validate: validators.code,
};

export const codeRequired = createRequired(code);

export const zipcode = props => {
  let mask = countries.en[props.code].mask;
  return {
    type: 'tel',
    format: value =>
      formatters.zipcode(mask, value ? value.toUpperCase() : value),
    validate: value =>
      validators.zipcode(value ? value.toUpperCase() : value, mask),
  };
};

export const zipcodeRequired = createRequired(props => zipcode(props));

/**
 * Date.
 */

export const date = {
  type: 'tel',
  format: formatters.date,
  parse: parsers.date,
  validate: validators.date,
};

export const dateRequired = createRequired(date)

/**
 * Date nascimento.
 */

export const dtNascimento = {
  type: 'tel',
  format: formatters.dtNascimento,
  parse: parsers.dtNascimento,
  validate: validators.dtNascimento,
};

export const dtNascimentoRequired = createRequired(dtNascimento);

/**
 * DateFilter.
 */

export const dateFilterReport = {
  type: 'date',
  validate: validators.dateFilter,
};

export const dateFilterRequired = createRequired(dateFilterReport);

/**
 * TextAreaFroala.
 */

export const textAreaFroala = {
  type: 'text',
  format: formatters.textArea,
};

export const textAreaFroalaRequired = createRequired(textAreaFroala);
