import React from 'react'
import { injectIntl } from 'react-intl'
import styled, { withTheme } from 'styled-components'
import HeartIcon from 'images/HeartIcon.png'
import Image from 'components/Image'
import Text from 'components/Text'

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 20px;

  > p {
    margin-left: 10px
  }
`

function FewDetailsRecMessage({ theme, intl, ...props}){
  return (
    <Container style={{maxWidth: 700}}>
      <Image src={HeartIcon} height={20} alt="Heart Icon"/>
      <Text size="12px" weight="bold" color="#34c3bc" fontFamily={theme.fonts.tertiary}>{intl.formatMessage({ id: props.textReview })}</Text>
    </Container>
  )
}

export default withTheme(
  injectIntl(FewDetailsRecMessage)
)
