import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import Section from 'admin/components/Section'
import { Form, Field } from 'react-final-form'
import VerticalSpacer from 'components/VerticalSpacer'
import Text from 'components/Text'
import CustomField from 'components/CustomField'
import SubmissionContainer from 'admin/components/SubmissionContainer'
import ButtonSubmit from 'components/ButtonSubmit'
import * as forms from 'utils/forms'
import { Close } from './styles'

function FormModalEmail ({ isSubmitting, intl, handleDestructModal, ...props }) {
  return (
    <Section title={intl.formatMessage({ id: 'admin.common.Share_Diagnosis' })}>
      <Close callback={handleDestructModal} />
      <Form {...props}>
        {({ handleSubmit, pristine }) =>{
          return(
            <form onSubmit={handleSubmit}>
              <VerticalSpacer>
                <Text>
                  {intl.formatMessage({ id: 'admin.common.put address' })}
                </Text>
                <Field
                  name='email'
                  label='Email'
                  id='form_user_email'
                  component={CustomField}
                  {...forms.email}
                />

                <SubmissionContainer>
                  <ButtonSubmit
                    label={intl.formatMessage({ id: 'admin.common.send' })}
                    isSubmitting={isSubmitting}
                    disabled={pristine}
                    color={'#4890e2'}
                    style={{
                      color:"white",
                      margin: "0 auto"
                    }}
                  />

                </SubmissionContainer>
              </VerticalSpacer>
            </form>
            )
          }          
        }
      </Form>
    </Section>
  )
}

FormModalEmail.propTypes = {
  /** Callback para o form submit */
  onSubmit: PropTypes.func.isRequired,

  /** Form sendo processado */
  isSubmitting: PropTypes.bool
}

export default injectIntl(FormModalEmail)
