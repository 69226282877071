import Container from 'components/Container'
import PageContent from 'components/PageContent'
import Spinner from 'components/Spinner'
import Text from 'components/Text'
import VerticalSpacer from 'components/VerticalSpacer'
import { ANAMNESES } from 'config/constants'
import React from 'react'
import { injectIntl } from 'react-intl'
import { Link, withRouter } from 'react-router-dom'
import routes from 'routes'
import { ProcessedResults } from 'store/api'
import withAuth from 'store/utils/withAuth'
import styled, { withTheme } from 'styled-components'
import getRoute from 'utils/getRoute'
import mediaQuery from 'utils/mediaQuery'
import AvailableDiagnostics from '../../../components/AvailableDiagnostics/AvailableDiagnostics'
import FewDetailsMoreCardInfo from '../../../questionnaire/components/FewDetailsMoreCardInfo'
import FewDetailsCardInfo, { Container as FewDetailsContainer, FewDetailsCardIcon, FewDetailsCardResume } from '../../components/FewDetailsCardInfo/FewDetailsCardInfo'
import FewDetailsRecMessage from '../../components/FewDetailsRecMessage/FewDetailsRecMessage'
import FewDetailsRefillWarningMessage from '../../components/FewDetailsRefillWarningMessage/FewDetailsRefillWarningMessage'

const LinkStyled = styled(Link)`
  text-decoration: none;
`
const DivDashed = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: 600px;
  padding: 10px 0;
  > *{
    color: #0b4e71;
  }

  > *:not(button){
    padding:10px;
  }

  > button{
    border-radius: 6px;
    border: 1.5px solid;
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    min-width: 220px;
    min-height: 46px;
    padding: 0 20px;
    position: relative;
    font-size: 16px;
    line-height: 0;

    &[disabled]{
      opacity: 0.4;
      cursor: not-allowed;
    }

    ${mediaQuery.lessThan('medium')`
      width: 100%;
    `}
    &.shadow{
      box-shadow: 0 5px 6px -2px rgba(0, 0, 0, 0.4);
    }

    &:hover:not([disabled]){
      filter: brightness(0.9); 
    }
    &:active:not([disabled]){
      top: 1px;
      filter: brightness(0.8); 
    }
  }

  `;

class DiagnosticFewDetailsScreen extends React.Component{
  state = {
    loading: true,
    response: undefined,
  }

  handleRedirectZap = () =>{
    let user = this.props.auth.user;
    let anamneses = user.empresa.anamneses.filter(i=> parseInt(i.value) === parseInt(this.props.match.params.anamneseID));
    let type = anamneses[0].originalTitle;
    window.open(`https://api.whatsapp.com/send?phone=554192442425&text=Olá, meu nome é ${user.name}, acabei de preencher uma entrevista de ${type}`,'_blank')
  }
  async getResult(){
    try {
      const { match } = this.props
      const response = await ProcessedResults.fetchOne(match.params.anamneseID, {})
      if (response && !this.unmounted) {
        this.setState({ response: response.data, loading: false })
      }
    } catch (err) {
      if(!this.unmounted){
        this.setState({ loading: false })
      }
    }
  }

  componentDidMount() {
    this.getResult()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.anamneseID !== this.props.match.params.anamneseID) {
      this.setState({ loading: true }, function(){
        this.getResult()
      })
    }
  }

  componentWillUnmount () {
    this.unmounted = true
  }

  render (){
    const { intl, auth, match } = this.props 
    const { loading, response } = this.state 
    const fill = getRoute(routes.users.diagnostic.startAnamnese, { anamneseID: match.params.anamneseID })
   
    if(loading){
      return (
        <PageContent>
          <Spinner />
        </PageContent>
      )
    }

    if(!response){
      return (
        <Text size="22px" color="textNew" weight="bold" fontFamily={this.props.theme.fonts.tertiary}>{intl.formatMessage({ id: 'admin.common.noResultsFound'})}</Text>
      )
    }

    console.log( auth.user.userData.genero_biologico);

    return (
      <PageContent blog>
        <Container size='small' margin="0 0">
          <VerticalSpacer space={25}>
            <Text size="42px" sizeMobile="33px" color="textNew" lineHeight={1}>
                {intl.formatMessage(
                  { id: 'users.dashboard.card.typeWithDiagnosticName'}, 
                  { diagnosticName: intl.formatMessage( response.id !== ANAMNESES.Mental ? { id: response.risk.risk_description } : { id: 'Mental' }) }
                )}
            </Text>

            {response.mrHasUpdated ? <FewDetailsRefillWarningMessage /> : ''}
            <Text 
              size={response.id === ANAMNESES.Mental ? '16px' : '22px'} 
              color="textNew" 
              weight="bold" 
              fontFamily={this.props.theme.fonts.tertiary}
              style={{maxWidth: 700}}
            >
                {response.id === ANAMNESES.Mental && (
                  <React.Fragment>
                    {intl.formatMessage({ id: 'users.results.hello.mental' }, { name: auth.user.userData.nome })}
                    <br></br>
                  </React.Fragment>
                )}
                {intl.formatMessage({ id: response.titleResume }, { name: auth.user.userData.nome })}
            </Text>
          </VerticalSpacer>
       
          <VerticalSpacer space={25}>
            <LinkStyled to={getRoute(routes.users.diagnosticResults.show, { id: match.params.anamneseID })}>
              <FewDetailsContainer>
                { response.id !== ANAMNESES.Mental ? (
                  <React.Fragment>
                    <FewDetailsCardInfo title={intl.formatMessage({ id: 'users.results.card.assessedRisk'})} riskColor={response.risk.risk_color} riskName={ intl.formatMessage({ id: response.risk.risk_description }) } />
                    <FewDetailsCardInfo title={intl.formatMessage({ id: 'users.results.card.levelOfAttention'})} riskColor={response.attention.attention_color} riskName={response.attention.attention_level} />
                    <FewDetailsCardInfo title={intl.formatMessage({ id: 'users.results.card.examsPendents'})} riskColor={!response.examsUpToDate ? '#f9ebbe' : '#a5e3e0'} riskName={!response.examsUpToDate ? intl.formatMessage({ id: 'admin.common.contactMadeOk'}) : intl.formatMessage({ id: 'admin.common.contactMadeNo'})} />
                  </React.Fragment>
                ):(
                  <React.Fragment>
                    <FewDetailsCardIcon riskName={response.risk.risk_description} gender={auth.user.userData.genero_biologico} />
                    <FewDetailsCardResume text={intl.formatMessage({ id:response.textResume})} />
                  </React.Fragment>
                )}                
                <FewDetailsMoreCardInfo />
              </FewDetailsContainer>
            </LinkStyled>

            <FewDetailsRecMessage {...response} />

            <div>
              <Link to={fill}>
                {intl.formatMessage({ id: 'users.results.fillAgain'})}
              </Link>
            </div>

            {this.props.auth && ( !!this.props.auth.user.empresa.whatsapp_receptive ) && (
              <DivDashed>
                <div>
                  {intl.formatMessage({ id: 'admin.common.how_about'})}
                </div>
                <button className='shadow' onClick={this.handleRedirectZap}>
                  <img src='https://cdn-icons-png.flaticon.com/512/134/134937.png' width={27} alt=''/> 

                  <p>
                    {intl.formatMessage({ id: 'admin.common.contact_us'})}
                  </p>
                </button>
              </DivDashed>
            )}

            <Text size="22px" color="textNew" weight="bold" fontFamily={this.props.theme.fonts.tertiary}>{intl.formatMessage({ id: 'users.results.othersDiagnosticsAvailable'})}</Text>
            <AvailableDiagnostics quantity={2} selectedDiagnostic={match.params.anamneseID}/>
            <div>&nbsp;</div>
          </VerticalSpacer>
        </Container>
      </PageContent>
    )
  }
}

export default withAuth(
  withRouter(
    withTheme(
      injectIntl(DiagnosticFewDetailsScreen)
    )
  )
)
