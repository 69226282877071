import React from 'react'
import PERMISSIONS from 'config/permissions'
import { injectIntl } from 'react-intl'
import routes from 'routes'
import Form from 'users/forms/FormCompany'
import ResourceScreen from 'admin/containers/ResourceScreen'
import ProtectedScreen from 'admin/components/ProtectedScreen'
import Auth from 'admin/containers/Auth'

function CompaniesScreen ({ intl }) {
  return (
    <ProtectedScreen title={intl.formatMessage({ id: 'admin.common.myAccount' })}>
      <Auth>
        {({ user }) => (
          <ResourceScreen
            resource='UserCompanies'
            permissions={PERMISSIONS.userCompanies}
            readableResourceName={[
              intl.formatMessage({ id: 'users.admin.companies.title' }),
              intl.formatMessage({ id: 'users.admin.companies.title.plural' })
            ]}
            routeBase={routes.users.admin.companies}
            resourceDetailsProps={{ wrapped: false }}
            resourceListProps={{ 
              wrapped: false,
              sectionHeaderProps: {
              width: '100%'
              }
            }}
            resourceUpdateProps={{
              wrapped: false,
              style: { overflow: 'visible' },
            }}
            Form={Form}
            user={user}
            showPaginationBottom={true}
            columns={[
              {
                accessor: 'nome',
                Header: intl.formatMessage({ id: 'admin.common.name' }),
                id: 'nome',
                filterable: true,
                find: 'like'
              },
              {
                accessor: 'cnpj',
                Header: intl.formatMessage({ id: 'admin.common.cnpj' }),
                filterable: true,
                find: 'like'
              },
              {
                accessor: 'qtd_colaboradores',
                Header: intl.formatMessage({ id: 'admin.common.qtd_colaboradores' }),
                filterable: true,
                find: 'equals'
              },
            ]}
          />
        )}
      </Auth>
    </ProtectedScreen>
  )
}

export default injectIntl(CompaniesScreen)
