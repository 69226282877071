import React, { Component } from 'react'
import { isEmpty, filter } from 'ramda'
import shallowEqual from 'shallow-equal/objects'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import * as resourcesDuck from 'store/ducks/resources'
import * as Api from 'store/api'
import Spinner from 'components/Spinner'

const getResourceNamespace = (resource, namespace) => namespace
  ? `${namespace}_${resource}`
  : resource

export const filterParams = filter(v => v !== null && v !== undefined)

class Resource extends Component {
  state = {
    redirect: false
  }

  fetchRequest () {
    const params = this.props.params ? filterParams(this.props.params) : {}
    !this.props.id
      ? this.props.fetchAll(params)
      : this.props.fetchOne(this.props.id, params)
  }

  redirectAfterSuccess () {
    if (this.props.redirectToAfterSuccess) {
      this.setState({ redirect: true })
    }
  }

  componentDidMount () {
    if(this.props.onLoad)
      this.props.onLoad();
    this.props.registerResource(getResourceNamespace(this.props.resource, this.props.namespace))
    if (this.props.autoFetch) {
      this.fetchRequest()
    }
  }

  componentDidUpdate (prevProps) {
    if (isEmpty(this.props.error)) {
      if (!this.props.isSubmitting && prevProps.isSubmitting) {
        this.props.onSuccess(this.props.createdRecord, 'create')
        this.redirectAfterSuccess()
      }

      if (!this.props.isFetching && prevProps.isFetching) {
        this.props.onFetchSuccess({
          detailedRecord: this.props.detailedRecord
        })
      }

      if (!this.props.isRemoving && prevProps.isRemoving) {
        this.props.onSuccess(this.props.removedRecord, 'remove')
        this.redirectAfterSuccess()
      }
    }

    if (prevProps.autoFetch && !shallowEqual(prevProps.params, this.props.params)) {
      this.fetchRequest()
    }

    if (prevProps.autoFetch && prevProps.id !== this.props.id) {
      this.fetchRequest()
    }

    if (!prevProps.autoFetch && this.props.autoFetch) {
      this.fetchRequest()
    }
  }

  render () {
    if (!this.props.isResourceRegistered) {
      return null
    }

    if (this.state.redirect) {
      return <Redirect to={this.props.redirectToAfterSuccess} />
    }

    if (this.props.spinner && this.props.isFetching) {
      return <Spinner />
    }

    return this.props.children(this.props)
  }

  static propTypes = {
    /** Nome do resource */
    resource: PropTypes.string.isRequired,

    /** Namespace opcional para evitar conflito com outros resources. */
    namespace: PropTypes.string,

    /** ID para requisitar um recurso detalhado */
    id: PropTypes.any,

    /** Inicia a requisição automaticamente */
    autoFetch: PropTypes.bool,

    /** Parâmetros default */
    params: PropTypes.object,

    /** Recurso paginado */
    paginated: PropTypes.bool,

    /** Lista de logs */
    logs: PropTypes.array.isRequired,

    /** Rota para qual o app será redirecionado após uma ação de sucesso */
    redirectToAfterSuccess: PropTypes.string,

    /** Callback que é executado quando uma ação for bem sucedida */
    onSuccess: PropTypes.func,

    /** Callback executado quando um fetch é realizado */
    onFetchSuccess: PropTypes.func,

    /** ID do notifications */
    notificationsId: PropTypes.string,

    /** Record criado pela action create */
    createdRecord: PropTypes.object,

    /** Mostra o spinner quando estiver carregando algo */
    spinner: PropTypes.bool
  }

  static defaultProps = {
    autoFetch: false,
    paginated: false,
    spinner: false,
    onSuccess: () => {},
    onFetchSuccess: () => {},
    children: () => null
  }
}

const mapStateToProps = (state, { resource, id, namespace }) => {
  const resourceNamespace = getResourceNamespace(resource, namespace)
  const detailedRecord = id ? resourcesDuck.getDetailedRecord(resourceNamespace, id, state) : undefined
  return {
    isResourceRegistered: state.resources.hasOwnProperty(resourceNamespace),
    records: !id ? resourcesDuck.getRecords(resourceNamespace, state) : undefined,
    data: resourcesDuck.getData(resourceNamespace, state),
    detailedRecord,
    detailedRecords: resourcesDuck.getDetailedRecords(resourceNamespace, state),
    hasDetailedRecord: !isEmpty(detailedRecord),
    isFetching: resourcesDuck.isFetching(resourceNamespace, state),
    isSubmitting: resourcesDuck.isSubmitting(resourceNamespace, state),
    isRemoving: resourcesDuck.isRemoving(resourceNamespace, state),
    removingRecords: resourcesDuck.getRemovingRecords(resourceNamespace, state),
    error: resourcesDuck.getError(resourceNamespace, state),
    pagination: resourcesDuck.getPagination(resourceNamespace, state),
    createdRecord: resourcesDuck.getCreatedRecord(resourceNamespace, state),
    removedRecord: resourcesDuck.getRemovedRecord(resourceNamespace, state),
    logs: resourcesDuck.getLogs(resourceNamespace, state)
  }
}

const defaultNotifications = {
  fetchAll: {
    success: false,
    errors: false
  },
  fetchOne: {
    success: false,
    errors: true
  },
  fetchApi: {
    success: false,
    errors: true
  },
  create: {
    success: true,
    errors: true
  },
  update: {
    success: true,
    errors: true
  },
  updateStatus: {
    success: true,
    errors: true
  },
  updatePassword: {
    success: true,
    errors: true
  },
  remove: {
    success: false,
    errors: true
  }
}

const mapDispatchToProps = (dispatch, { resource, namespace, paginated, notifications = {}, notificationsId }) => {
  const resourceNamespace = getResourceNamespace(resource, namespace)
  const actions = [
    'fetchAll',
    'fetchOne',
    'fetchApi',
    'create',
    'update',
    'updateStatus',
    'updatePassword',
    'updateLang',
    'remove',
  ].reduce((prevValue, currentValue) => {
    prevValue[currentValue] = (...args) =>
      resourcesDuck[currentValue](
        {
          resource: resourceNamespace,
          api: Api[resource][currentValue],
          paginated,
          notifications: {
            notificationsId,
            ...(notifications[currentValue] || defaultNotifications[currentValue])
          }
        },
        ...args
      )
    return prevValue
  }, {})

  return bindActionCreators({
    registerResource: resourcesDuck.registerResource,
    ...actions
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Resource)
