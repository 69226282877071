import styled, { css } from 'styled-components'
import { rgba } from 'polished'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: ${props => props.theme.colors.background};
  padding: 10px;
  max-width: 100px;
  min-height: 100px;
  position: relative;
  user-select: none;
  box-sizing: border-box;

  ${props => props.isDragActive && css`
    opacity: 0.7;
    border: 2px dashed ${props.theme.colors.formBorder};
  `}

  ${props => props.preview && css`
    background: #fff url(${props => props.preview}) center no-repeat;
    background-size: cover;
  `}

  ${props => !props.preview && css`
    cursor: pointer;
  `}
`

export const RemoveButton = styled.button.attrs({ type: 'button', title: 'Remove image' })`
  position: absolute;
  top: 5px;
  right: 5px;
  background: url(${require('images/close.svg')}) center no-repeat;
  background-color: ${props => rgba(props.theme.colors.danger, 0.8)};
  background-size: 15px 15px;
  width: 25px;
  height: 25px;
  border: none;
  border-radius: 100%;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.danger};
  }
`
