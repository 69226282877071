import styled from "styled-components";

export const ComponentEditor = styled.div`
  a[href="https://www.froala.com/wysiwyg-editor?k=e"] {
    display: none !important;
  }
     [data-f-id="pbf"] {
    display: none !important;
  }
`;

export const TextHTML = styled.div`
      font-size: 16px;
      color: #0b4e71;
      font-weight: bold;
      overflow-wrap: anywhere;
      padding: 10px;
      border: 1px solid #c1c1c1;
      min-height: 16px;
      border-radius: 3px;
      background: #f4f4f4;

      & *{
            font-family: 'Poppins',sans-serif !important;
            font-size:14px !important;
            color: #000!important;
            font-weight:normal!important;
      }

      & a{
            text-decoration:none;
            &:hover{
                  text-decoration:underline;
            }
      }
      [data-f-id=pbf]{
            display: none;
      }
`;
