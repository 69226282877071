import Request from '../../modules/Request'

export default {
  fetchAll (params) {
    return Request.auth({
      method: 'GET',
      url: `/api/user/anamneses/mine`,
      query: params
    }).then(res => res.body)
  },
}
