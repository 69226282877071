import CustomEditor from 'admin/components/CustomEditor';
import CustomFieldSelect from 'admin/components/CustomFieldSelect';
import CustomImageUploader from 'admin/components/CustomUploader';
import ButtonSubmit from 'components/ButtonSubmit';
import CustomField from 'components/CustomField';
import FormGrid from 'components/FormGrid';
import Text from 'components/Text';
import VerticalSpacer from 'components/VerticalSpacer';
import Resource from 'containers/Resource';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { injectIntl } from 'react-intl';
import * as forms from 'utils/forms';
import {
  Container,
  ContainerInd,
  WhatsAppInputReceptive,
  WhatsAppInputActive,
  ContainerDisplayFlex,
} from './styles';

function FormCompany({ isSubmitting, readOnly, intl, ...props }) {
  const NotEditable =
    props.resource && props.resource.NotEditable
      ? props.resource.NotEditable
      : false;

  return (
    <Form mutators={{ ...arrayMutators }} {...props}>
      {({ handleSubmit, pristine, values, ...formProps }) => {
        values['modified_fields'] = formProps.modified;

        return (
          <form onSubmit={handleSubmit}>
            <VerticalSpacer>
              <FormGrid>
                <Field
                  name="nome"
                  label={intl.formatMessage({ id: 'admin.common.name' })}
                  id="form_company_name"
                  component={CustomField}
                  disabled={readOnly}
                  maxLength={255}
                  showMandatory
                  {...forms.required}
                />
                <Field
                  name="cnpj"
                  label={intl.formatMessage({ id: 'admin.common.cnpj' })}
                  id="form_company_cnpj"
                  component={CustomField}
                  disabled={readOnly}
                  maxLength={255}
                  {...forms.cnpj}
                />

                <Resource resource="HealthInsurances" autoFetch>
                  {props => (
                    <Container>
                      <Field
                        name="planos_saude"
                        label={intl.formatMessage({
                          id: 'admin.healthInsurance.title',
                        })}
                        id="form_company_plano_de_saude"
                        component={CustomFieldSelect}
                        placeholder={intl.formatMessage({
                          id: 'admin.comon.select',
                        })}
                        disabled={readOnly}
                        customSelectProps={{
                          options: props.records,
                          labelKey: 'nome',
                          valueKey: 'id',
                          multi: true,
                          simpleValue: false,
                          isLoading: props.isFetching,
                        }}
                        // showMandatory
                        // {...forms.required}
                      />
                    </Container>
                  )}
                </Resource>
              </FormGrid>
              <FormGrid>
                <Field
                  name="qtd_colaboradores"
                  label={intl.formatMessage({
                    id: 'admin.common.qtd_colaboradores',
                  })}
                  id="form_company_qtd_colaboradores"
                  disabled={readOnly}
                  component={CustomField}
                  showMandatory
                  {...forms.required}
                />
                <Field
                  name="cupom.ticket_id"
                  label={intl.formatMessage({ id: 'pages.register.code' })}
                  id="form_company_ticket_id"
                  disabled={readOnly}
                  component={CustomField}
                />
                <Field
                  name="dt_expiracao.date"
                  label={intl.formatMessage({
                    id: 'admin.common.expirationDate',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'admin.common.birthDatePlaceHolder',
                  })}
                  id="form_company_dt_expiracao_cupom"
                  disabled={readOnly}
                  component={CustomField}
                  showMandatory
                  {...forms.dateRequired}
                />

                <Resource
                  resource="IndicationCompany"
                  autoFetch={
                    (NotEditable && NotEditable.indication) || readOnly
                      ? false
                      : true
                  }
                >
                  {props => (
                    <ContainerInd>
                      <Field
                        name="indicacao_empresa"
                        label={intl.formatMessage({
                          id: 'admin.recommendation.title',
                        })}
                        id="form_indicacao_empresa"
                        component={CustomFieldSelect}
                        placeholder={intl.formatMessage({
                          id: 'admin.comon.select',
                        })}
                        disabled={
                          NotEditable && NotEditable.indication
                            ? NotEditable.indication
                            : readOnly
                        }
                        customSelectProps={{
                          options: props.records,
                          labelKey: 'nome',
                          valueKey: 'id',
                          multi: false,
                          simpleValue: true,
                          isLoading: props.isFetching,
                        }}
                        showMandatory
                        {...forms.required}
                      />
                    </ContainerInd>
                  )}
                </Resource>
              </FormGrid>

              <Field
                name="termos_uso"
                label={intl.formatMessage({ id: 'q.termsOfService' })}
                id="form_termos_uso"
                component={CustomEditor}
                readOnly={readOnly}
                disabled={readOnly}
              />
              <Field
                name="text_initial_client"
                label={intl.formatMessage({ id: 'q.initialText' })}
                id="form_texto_inicial_cliente"
                component={CustomEditor}
                maxLength={150}
                readOnly={readOnly}
              />

              <Field
                name="link_video"
                label={intl.formatMessage({ id: 'admin.common.linkVideo' })}
                id="form_link_video"
                component={CustomField}
                disabled={readOnly}
                maxLength={255}
                {...forms.link_YouTube}
              />

              <Field
                name="link_agendamento_exame"
                label={intl.formatMessage({
                  id: 'admin.common.link.agendamento.exame',
                })}
                id="form_link_agendamento_exame"
                component={CustomField}
                disabled={readOnly}
                maxLength={255}
                {...forms.link_Agendamento_Exame}
              />

              <ContainerDisplayFlex>
                <WhatsAppInputReceptive>
                  <Field
                    name="whatsapp_receptive"
                    id="chk_receptive"
                    component="input"
                    type="checkbox"
                    disabled={readOnly}
                  />
                  <label className="whatsapp_receptive">
                    {intl.formatMessage({
                      id: 'admin.common.whatsApp.receptive',
                    })}
                  </label>

                  <label for="chk_receptive" class="switch_receptive">
                    <span class="slider_receptive"></span>
                  </label>
                </WhatsAppInputReceptive>

                <WhatsAppInputActive>
                  <Field
                    name="whatsapp_activo"
                    id="chk_activo"
                    component="input"
                    type="checkbox"
                    disabled={readOnly}
                  />
                  <label className="whatsapp_active">
                    {intl.formatMessage({
                      id: 'admin.common.whatsApp.active',
                    })}
                  </label>

                  <label for="chk_activo" class="switch_activo">
                    <span class="slider_activo"></span>
                  </label>
                </WhatsAppInputActive>
              </ContainerDisplayFlex>

              <Field
                name="logo"
                label={intl.formatMessage({ id: 'admin.common.logo' })}
                id="form_company_logo"
                component={CustomImageUploader}
                accept="image/*"
                readOnly={readOnly}
              />

              <FieldArray name="anamneseConfig">
                {({ fields }) => {
                  return (
                    <div>
                      {values.availableAnamneses ? (
                        (() => {
                          values.anamneseConfig.map(i => {
                            i.title = intl.formatMessage({ id: i.title });
                            return i;
                          });
                          values.anamneses.map(i => {
                            i.title = intl.formatMessage({ id: i.title });
                            return i;
                          });

                          return (
                            <Field
                              name="anamneses"
                              label={intl.formatMessage({
                                id: 'admin.anamnesis.title.plural',
                              })}
                              id="form_category_anamneses"
                              component={CustomFieldSelect}
                              placeholder={intl.formatMessage({
                                id: 'admin.comon.select',
                              })}
                              readOnly={readOnly}
                              disabled={readOnly}
                              customSelectProps={{
                                options: values.availableAnamneses.map(i => {
                                  i.title = intl.formatMessage({ id: i.title });
                                  return i;
                                }),
                                multi: true,
                                simpleValue: false,
                                labelKey: 'title',
                                valueKey: 'value',
                              }}
                              onChangeOption={value => {
                                if (value) {
                                  if (!values.anamneseConfig) {
                                    formProps.form.mutators.push(
                                      'anamneseConfig',
                                      {
                                        title: value[0].title,
                                        value: value[0].value,
                                      },
                                    );
                                    return value;
                                  }
                                  value
                                    .filter(x => {
                                      return (
                                        values &&
                                        values.anamneseConfig &&
                                        !values.anamneseConfig.filter(r => {
                                          return r.value === x.value;
                                        }).length > 0
                                      );
                                    })
                                    .every(r =>
                                      formProps.form.mutators.concat(
                                        'anamneseConfig',
                                        r,
                                      ),
                                    );

                                  values.anamnese &&
                                    values.anamneseConfig
                                      .filter(x => {
                                        return (
                                          !value.filter(
                                            r => r.value && r.value === x.value,
                                          ).length > 0
                                        );
                                      })
                                      .every(diff =>
                                        fields.remove(
                                          'anamneseConfig',
                                          values.anamnese.indexOf(diff),
                                        ),
                                      );
                                }
                                return value;
                              }}
                              {...forms.required}
                            />
                          );
                        })()
                      ) : (
                        <Resource resource="Anamneses" autoFetch>
                          {props => {
                            return (
                              <Field
                                name="anamneses"
                                label={intl.formatMessage({
                                  id: 'admin.anamnesis.title.plural',
                                })}
                                id="form_category_anamneses"
                                component={CustomFieldSelect}
                                placeholder={intl.formatMessage({
                                  id: 'admin.comon.select',
                                })}
                                readOnly={readOnly}
                                disabled={readOnly}
                                customSelectProps={{
                                  options: props.records.map(i => {
                                    i.title = intl.formatMessage({
                                      id: i.title,
                                    });
                                    return i;
                                  }),
                                  multi: true,
                                  simpleValue: false,
                                  labelKey: 'title',
                                  valueKey: 'value',
                                }}
                                onChangeOption={value => {
                                  if (value) {
                                    if (!values.anamneseConfig) {
                                      formProps.form.mutators.push(
                                        'anamneseConfig',
                                        {
                                          title: value[0].title,
                                          value: value[0].value,
                                        },
                                      );
                                      return value;
                                    }
                                    value
                                      .filter(x => {
                                        return (
                                          values &&
                                          values.anamneseConfig &&
                                          !values.anamneseConfig.filter(r => {
                                            return r.value === x.value;
                                          }).length > 0
                                        );
                                      })
                                      .every(r =>
                                        formProps.form.mutators.concat(
                                          'anamneseConfig',
                                          r,
                                        ),
                                      );

                                    values.anamneseConfig &&
                                      values.anamneseConfig
                                        .filter(x => {
                                          return (
                                            !value.filter(
                                              r =>
                                                r.value && r.value === x.value,
                                            ).length > 0
                                          );
                                        })
                                        .every(diff =>
                                          fields.remove(
                                            'anamneseConfig',
                                            values.anamneseConfig.indexOf(diff),
                                          ),
                                        );
                                  }
                                  return value;
                                }}
                                showMandatory
                                {...forms.required}
                              />
                            );
                          }}
                        </Resource>
                      )}

                      {fields.map((name, index) => {
                        return values.anamneses[index] ? (
                          <div key={index} style={{ marginTop: 25 }}>
                            <FormGrid>
                              <Text
                                size="18px"
                                color="textGray"
                                style={{ marginTop: 25 }}
                              >
                                {values.anamneses[index] &&
                                values.anamneses[index].title
                                  ? intl.formatMessage({
                                      id: values.anamneses[index].title,
                                    })
                                  : ''}
                              </Text>

                              <Field
                                name={`${name}.dateIni`}
                                label={intl.formatMessage({
                                  id: 'admin.common.initialDate',
                                })}
                                id={`${name}.dateIni`}
                                disabled={readOnly}
                                component={CustomField}
                                placeholder={intl.formatMessage({
                                  id: 'admin.common.birthDatePlaceHolder',
                                })}
                                showMandatory
                                {...forms.dateRequired}
                              />
                              <Field
                                name={`${name}.dateEnd`}
                                label={intl.formatMessage({
                                  id: 'admin.common.expirationDate',
                                })}
                                id={`${name}.dateEnd`}
                                disabled={readOnly}
                                component={CustomField}
                                placeholder={intl.formatMessage({
                                  id: 'admin.common.birthDatePlaceHolder',
                                })}
                                showMandatory
                                {...forms.dateRequired}
                              />
                            </FormGrid>
                          </div>
                        ) : (
                          ''
                        );
                      })}
                    </div>
                  );
                }}
              </FieldArray>

              <Field
                name={'modified_fields'}
                component={CustomField}
                type="hidden"
              />

              <ButtonSubmit
                isSubmitting={isSubmitting}
                disabled={pristine}
                label={intl.formatMessage({ id: 'admin.common.save' })}
              />
            </VerticalSpacer>
          </form>
        );
      }}
    </Form>
  );
}

FormCompany.propTypes = {
  /** Callback para o form submit */
  onSubmit: PropTypes.func.isRequired,

  /** Form sendo processado */
  isSubmitting: PropTypes.bool,

  /** Exibe o form em modo leitura */
  readOnly: PropTypes.bool,
};

export default injectIntl(FormCompany);
