import React from 'react'
import { injectIntl } from 'react-intl'
import Login from 'users/containers/Login'
import AdminWrapper from 'admin/components/AdminWrapper'
import GuestScreen from 'admin/components/GuestScreen'
import Session from 'modules/Session'

function LoginScreen ({ intl, ...props }) {

  const { ticket } = props.match.params;
  if(ticket){
    Session.set("ticket",ticket)
  }

  return (
    <AdminWrapper>
      <GuestScreen
        title={`${intl.formatMessage({ id: 'admin.forms.login.title' })} - PreviNEO`}
      >
        <Login />
      </GuestScreen>
    </AdminWrapper>
  )
}

export default injectIntl(LoginScreen)
