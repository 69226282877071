import Spinner from 'components/Spinner'
import Session from 'modules/Session'
import PropTypes from 'prop-types'
import qs from 'querystringify'
import React, { Component } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { getTokenKey } from 'store/api/Auth'
import withAuth from 'store/utils/withAuth'

class AuthLogin extends Component {
  state = {
    authChecked: false
  }

  componentDidMount () {
    const { type, auth } = this.props
    const key = getTokenKey(type)

    if (auth.isAuthenticated && auth.type !== type) {
      auth.logout(auth.type)
    }

    if ((auth.isAuthenticated && auth.type === type) || !Session.get(key)) {
      return this.setState({
        authChecked: true
      })
    }

    this.props.auth.login(null, null, type, null)
  }

  componentDidUpdate ({ auth }) {
    if (this.state.authChecked) return
    if (auth.isSubmitting && !this.props.auth.isSubmitting) {
      this.setState({
        authChecked: true
      })
    }
  }

  render () {
    if (!this.state.authChecked) {
      return (
        <div style={{ padding: 40 }}>
          <Spinner />
        </div>
      )
    }

    const query = qs.parse(this.props.location.search)

    if (this.props.auth.isAuthenticated && query.goto) {
      return <Redirect to={query.goto} />
    }

    return this.props.children
  }

  static propTypes = {
    type: PropTypes.string.isRequired
  }
}

export default withAuth(
  withRouter(AuthLogin)
)
