import Request from 'modules/Request'

export default {
  fetchOne (id, params) {
    return Request.api({
      method: 'GET',
      url: `/api/endereco/${id}`,
      query: params
    }).then(res => res.body)
  }
}
